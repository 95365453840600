export default {
  "banner": {
    "viewDetails": "查看详情",
    "requiredCourses": "您的课程将于下述日期到期：",
    "days": "天"
  },
  "header": {
    "plan": "我的计划",
    "manager": "经理",
    "explore": "发掘学习",
    "reporting": "报告",
    "myTeam": "我的团队",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "我的计划",
    "manager": "经理",
    "explore": "发掘学习",
    "tagline": "轻松学习。一切由您开始。"
  },
  "welcome": {
    "hi": "您好",
    "intro1": "欢迎来到 Digital Learning Zone！DLZ 是个性化的学习平台，是汇聚所有万豪学习内容的集中式访问点。找到您的职务所对应的培训课程，探索有利于您的个人和职业发展的学习机会。开启您的学习之旅，开始提升您的技能水平。",
    "intro2": "在您开始之前，我们需要了解一些有关您的信息。",
    "letsGo": "开始吧",
    "preferredLanguage": "您优先使用什么语言？",
    "step": "步骤",
    "of": "关于",
    "previous": "上一页",
    "next": "下一页",
    "startDate": "您是从什么时候开始起在 Marriott International 或 Starwood 工作的？",
    "selectYear": "选择年份",
    "selectMonth": "选择月份",
    "selectDay": "选择日期",
    "selectFunction": "选择职能",
    "selectInterests": "选择兴趣",
    "chooseYear": "选择年份",
    "chooseMonth": "选择月份",
    "chooseDay": "选择日期",
    "whatDo": "您的工作职能是",
    "selected": "已选定",
    "searchFunctions": "选择全部职能",
    "searchInterests": "选择全部兴趣",
    "additionalJobFunctionsLabel": "详细介绍一下您的工作",
    "managementLevelLabel": "您的职务级别为？",
    "preferredBrandLabel": "如果您为多个品牌工作，请选择所有适用于您的选项。",
    "additionalJobFunctionsLabelExtra": "请查看以下内容，并且至少选择一项额外的工作职责。",
    "searchAdditionalFunctions": "选择全部额外的工作责任",
    "highGuestContactLabel": "您是否经常与客人联系？",
    "yes": "是",
    "no": "否 "
  },
  "landing": {
    "started": "我们开始吧",
    "welcomeBack": "欢迎回来！",
    "greatWork": "很棒",
    "requiredCourses": "您需要学习的课程",
    "requiredCoursesDesc": "请在截止日期之前完成课程。",
    "myPlan": "我的计划",
    "courses": "课程",
    "courseComplete": "课程完成",
    "viewMore": "查看更多需要学习的课程",
    "viewMoreBtn": "查看更多课程",
    "showing": "显示",
    "of": "关于",
    "heroTitle": "学习新知识。充实自己。获得适用于您所在领域的技能。",
    "heroParagraph1": "轻松记录您需要学习的全部课程，获取提示您完成课程的实时通知。",
    "heroParagraph2": "获取基于您的岗位和兴趣定制的学习体验。",
    "recommendedCoursesTitle": "推荐课程",
    "recommendedCoursesDescNoContent": "您目前没有任何推荐课程。请查看以下信息发掘其他学习机会。",
    "recommendedCoursesDesc": "查看您的经理或人事专员为您选择了什么。",
    "recommendedCoursesBtn": "查看推荐课程",
    "completedCoursesBtn": "查看已完成课程",
    "exploreTitle": "保持好奇心。",
    "exploreDesc": "选择您感兴趣的话题。",
    "jobFunctions": "部门",
    "growthSkills": "成长技能",
    "leadershipSkills": "领导力技能",
    "brands": "品牌",
    "languageLearning": "语言学习",
    "exploreBtn": "查看课程",
    "managerRecommendation": "管理推荐",
    "learningRecommendation": "学习推荐",
    "marriottRecommendation": "万豪推荐",
    "completedCourses": "您已完成您需要学习的全部课程！",
    "investTitle": "开启您的学习之旅",
    "investParagraph1": "我们意识到当前的情况在许多方面具有挑战性 - DLZ 是您资源。无论您是想保持技能最新、学习新事物、管理压力还是学会在虚拟团队中工作。我们已收集资源来帮助为您提供支持。",
    "updatedTopics": "更新的主题",
    "workingRemotely": "虚拟团队资源",
    "relatedCoursesNew": "点击下方更新您的兴趣，因为我们添加了新的主题和课程。",
    "relatedCourses": "请点击下方选择您的兴趣。",
    "notificationText": "新版 mHUB 培训平台现已开放。请在 Explore Learning 中选择 mHUB。",
    "mHubHeroTitle1": "简单、智能、安全。隆重推出 mHUB。",
    "mHubHeroManagedText1": "mHUB 集学习、职业发展工具、Explore 折扣以及更多功能于一身，是一款易于使用的平台。",
    "mHubHeroFranchisedText1": "mHUB 集学习工具、Explore 折扣以及更多功能于一身，是一款易于使用的平台。",
    "mHubHeroVisitLink": "点击此处查看更多优惠详情或附件。",
    "myGateway": "My Gateway",
    "gatewayReview": "请查看并完成。",
    "gatewayProgress": "Gateway 进程",
    "complete": "完成",
    "reviewContent": "查看资源",
    "gatewayCardTitle": "Gateway 资源",
    "gatewayCardDesc": "获取第一年及以后的基础知识。",
    "gatewayCardDescGms": "获取第一年及以后的基础知识、行动项目、品牌资源和部门资源。",
    "gatewayCardDescNew": "获取您在新岗位第一年的基础知识。",
    "gatewayCardDescGmsNew": "获取您在新岗位第一年及以后的基础知识、行动项目、品牌资源和部门资源。"
  },
  "courseBundle": {
    "completeBy": "完成人员",
    "remainingCourses": "剩余课程",
    "completedCourses": "已完成课程",
    "remainingLabel": "本学习通道中有 {courseCount} 课程。",
    "recommendButton": "团队推荐",
    "startCourse": "开始课程",
    "durationMins": "分钟",
    "completed": "完成",
    "back": "返回",
    "additionalLangs1": "除了英语之外，本课程目前还提供以下语言版本",
    "additionalLangs2": "启动课程后，请从下拉菜单中选择您优先使用的语言。",
    "targetAudience": "目标受众",
    "all": "全部",
    "globally": "全球",
    "source": "来源",
    "courseType": "课程类型",
    "course": "课程"
  },
  "profile": {
    "save": "保存",
    "edit": "编辑",
    "brand": "品牌",
    "brands": "品牌",
    "additionalWork": "全部其他工作责任",
    "startDateLabel": "您是从什么时候开始起在 Marriott International 或 Starwood 工作的？",
    "preferredLanguageLabel": "您优先使用什么语言？",
    "jobFunctionsLabel": "您的职责是什么？",
    "interestsLabel": "您希望学习什么？",
    "brandsLabel": "您效力于什么品牌？",
    "additionalJobFunctionsLabel": "您还承担哪些其他职责？",
    "managementLevelLabel": "您的职务级别是",
    "highGuestContactLabel": "您是否经常与客人联系？"
  },
  "courseDetails": {
    "completeBy": "待填写",
    "recommendTitlte": "您团队的推荐课程",
    "courseName": "课程名称",
    "sendRecommendation": "发送推荐"
  },
  "explore": {
    "relatedCourses": "这里有一些与您感兴趣的领域相关的课程。",
    "chooseInterests": "选择您的兴趣",
    "title": "发掘学习",
    "chooseInterestsModal": "选择兴趣",
    "oppsTitle": "发掘学习机会",
    "growWithUsTitle": "Grow With Us",
    "growWithUsDesc": "万豪国际秉持公司与员工共荣的价值观。我们还认为，广泛的工作经验往往能让工作经验更丰富。",
    "growWithUsBtn": "查看 GROW WITH US",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "万豪认可员工的成就和贡献。认可并提倡员工的引领能力和卓越服务。我们还奖励内部人才推荐。",
    "mdaBtn": "查看 MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "告诉我们您对什么话题感兴趣。",
    "otherRecommended": "其他推荐课程",
    "searchByName": "搜索您感兴趣的主题"
  },
  "plan": {
    "completedRequired": "您已完成您需要学习的全部课程！",
    "requiredCourses": "需要学习的课程",
    "reqDesc": "请在截止日期前完成课程。",
    "complete": "完成",
    "of": "关于",
    "courses": "课程",
    "completedCourses": "已完成课程",
    "completedDesc": "恭喜！您最近完成了以下课程。",
    "remaining": "剩余",
    "myLearningTitle": "我的补充学习 ",
    "myLearningSubTitle": "我已添加到“我的计划”中的项目",
    "history": "历史",
    "myLearningHistoryLinkText": "查看我的完整历史",
    "jf": "您的工作职能",
    "awr": "您的其他工作职责 (AWR)",
    "jfAwrDesc": "重要提示：工作职能和 AWR 决定了“我的计划”中分配给您的所需培训。",
    "jfAwrEdit": "编辑并更新您的个人资料",
    "jfAwrEnd": "。",
    "tourPlan": "“我的计划”包括您在截止日期前需要完成的所有必修和推荐课程",
    "tourBasics": "Marriott Basics 包括帮助您开启万豪学习之旅的资源，您可以在整个职业生涯中随时访问这些资源。Marriott Basics 是对“我的计划”中学习内容的补充。",
    "noCourses": "现在，你们都已在截止日期前完成了必修课程的学习，请再接再厉，准备开始进入下一阶段的必修课程"
  },
  "team": {
    "title": "管理您的团队",
    "subtitle": "发展他们的技能和职业。",
    "functions": "职能",
    "viewTeam": "查看团队",
    "allCourses": "所有课程",
    "downloadExcel": "下载 Excel 报告"
  },
  "feedbackButton": {
    "havingTrouble": "建议"
  },
  "recommendation": {
    "dismiss": "您确定要拒绝接受此建议吗？"
  },
  "table": {
    "filterTable": "篩選表"
  },
  "notifications": {
    "addAssignment2": "一个关于 %{assignment_name} 的%{assignment_type}任务已添加到您的 %{page location}，该任务将于 %{due} 到期。",
    "comingDueAssignment": "您关于 %{assignment_name} 的%{assignment_type}任务即将于 %{due} 到期。",
    "overdueAssignment": "您关于 %{assignment_name} 的%{assignment_type}任务现已逾期。",
    "addAssignment": "一个关于 %{assignment_name} 的%{assignment_type}任务已添加到您的 %{page location}。",
    "profile": "检测到您的职位或工作地点有更新。请查看您的个人资料以便针对“品牌”、“职责”或“其他工作责任”进行任何相应的更新。准确的 DLZ 资料信息有助于确保您收到正确的学习任务。",
    "helptip": "所有通知都会在 30 天后过期。",
    "markRead": "标记为已读",
    "delete": "删除",
    "markAllRead": "标记所有为已读",
    "settings": "设置",
    "tooltip": "已读通知将在 30 天后删除",
    "reportComplete": "您的 DLZ 报告已完成。点击下载",
    "dontShowThisAgain": "不再显示此项",
    "warningEnabled": "删除警告已启用。",
    "manageEmailNotifications": "管理电子邮件通知",
    "warningTooltip": "删除时，您将在每次删除通知的时候看到一条警告。",
    "changeEmailManagedTooltip": "如需更改您的电子邮箱，请访问 mHUB > HR Direct > 我 > 我的公开信息。按照说明更新您的联系信息。可能需要 1-2 个工作日才能显示更新后的电子邮箱。",
    "changeEmailFranchisedTooltip": "如需更改您的电子邮箱，请联系您的经理和/或您所在特许经营管理公司的 EID Tool 管理员。可能需要 1-2 个工作日才能显示更新后的电子邮箱。",
    "deleteAllNotifications": "删除所有通知",
    "deleteIndividualHeader": "删除通知",
    "deleteAllConfirmation": "您确定要删除所有通知吗？ 这些通知将被移至通知中心的“已删除”部分，直至过期。",
    "deleteIndividualConfirmation": "确定要删除此通知吗？ 删除的通知已移至通知中心的“已删除”部分，直至过期。",
    "permanentDeleteIndividiualConfirmation": "确定要永久性删除此通知吗？ 此操作不可撤消。",
    "permanentDeleteAllConfirmation": "您确定要永久性删除所有通知吗？ 此操作不可撤消。",
    "notificationDeleted": "通知已删除。",
    "notificationsDeleted": "所有通知均已删除。",
    "notificationRestored": "通知已恢复。",
    "notificationsRestored": "所有通知均已恢复。",
    "enableDeletionWarning": "删除警告启用",
    "warningDisabled": "删除警告已禁用。",
    "viewAll": "查看全部",
    "deleteAll": "删除全部",
    "notificationCenterTitle": "通知中心",
    "markAsRead": "标记为已读",
    "noDeletedNotifications": "您的信息现在是最新状态！",
    "restore": "恢复",
    "restoreAll": "恢复所有",
    "viewCourse": "查看课程",
    "title": "通知",
    "noNewNotifications": "没有新通知。",
    "cancel": "取消",
    "manageNotificationsCopy": "管理电子邮件通知\n\n选择是否每周接收电子邮件，以获取逾期未完成学习项目汇总信息。选择“管理”以修改您的首选项。"
  },
  "transcript": {
    "history": "我的历史",
    "completed": "已完成的任务",
    "assignmentName": "任务名称",
    "type": "类型",
    "viewAssignment": "查看任务",
    "assignmentSource": "任务来源",
    "requiredAssignments": "必修任务",
    "recommendedAssignments": "推荐任务",
    "voluntary": "选修任务",
    "trasscript": "成绩单",
    "retraining": "重修",
    "completionDate": "完成日期",
    "assignmentType": "任务类型",
    "contentType": "内容类型",
    "contentId": "内容 ID"
  },
  "contentLaunch": {
    "review": "学习回顾",
    "start": "开始学习"
  },
  "survey": {
    "weValue": "您的意见对我们非常重要。",
    "weValueDescription": "请回答五个关于您在酒店内工作体验的简短问题。",
    "weValueButtonText": "参加调查",
    "weValueG1Q1A1": "是",
    "weValueG1Q1A2": "否",
    "strongAgree": "强烈赞同",
    "agree": "赞同",
    "slightAgree": "略微赞同",
    "slightDisagree": "略微反对",
    "disagree": "反对",
    "strongDisagree": "强烈反对",
    "weValueG1Q1Title": "在您上一次当值时，您是否参加了每日例会？ （Line-Up、What’s Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments 或 Daily EDITION）",
    "weValueG1Q2Title": "每日例会（Line-Up、What’s Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments 或 Daily EDITION）每天都激励我打造奢华服务。",
    "weValueG1Q3Title": "我所在部门的工作重点是减少缺失，包括不是由客人报告的问题。",
    "weValueG1Q4Title": "我被授权可以在无需事先征得同意的情况下解决客人问题。",
    "weValueG1Q5Title": "我被授权可以在无需事先征得同意的情况下取悦客人。",
    "weValueG2Q1Title": "我经常看见我所在酒店内的团队成员超越期望，通过服务给客人留下深刻印象。",
    "weValueG2Q2Title": "我在本部门中的学习经历让我能够为客人提供奢华服务。",
    "weValueG2Q3Title": "我对我在提供奢华服务方面的知识和技能感到自信。",
    "weValueG2Q4Title": "在我的部门中，我们提供奢华的环境——干净整洁，完美无缺。",
    "weValueG2Q5Title": "即使我遇到了与我的部门无关的问题，我也被授权可以参与并协助解决客人的问题。",
    "weValueG3Q1Title": "我所在酒店的团队成员享受着与客人一样的照顾水平。",
    "weValueG3Q2Title": "我的部门践行品牌的价值观。",
    "weValueG3Q3Title": "当我超越期望打造奢华的客人体验时，我的工作被认可和赏识。",
    "weValueG3Q4Title": "我积极参与到我所在部门提升工作质量方面的活动中。",
    "weValueG3Q5Title": "在我的部门中，我们总是将客人放在第一位。",
    "submitButton": "提交",
    "phasetwo": {
      "weValueG1Q1Title": "在您上一次当值时，您是否参加了每日例会？ （Line-Up、What’s Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments 或 Daily EDITION）？",
      "weValueG1Q2Title": "每日例会（Line-Up、What’s Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments 或 Daily EDITION）每天都激励团队成员打造奢华的服务体验。",
      "weValueG1Q3Title": "在我们的酒店，我们的工作重点是减少缺失，包括不是由客人报告的问题。",
      "weValueG1Q4Title": "在我们的酒店，我们被授权可以在无需事先征得同意的情况下解决客人问题。",
      "weValueG1Q5Title": "在我们的酒店，我们被授权可以在无需事先征得同意的情况下取悦客人。",
      "weValueG2Q1Title": "在我们的酒店，我们提供的服务经常超出客人期望，给客人留下深刻印象。",
      "weValueG2Q2Title": "酒店内部的学习机会培养了我们提供奢华服务的能力",
      "weValueG2Q3Title": "在我们的酒店，我们相信可以通过我们的知识和技能为客人提供奢华服务。",
      "weValueG2Q4Title": "在我们的酒店，我们提供奢华的环境——干净整洁，完美无缺。",
      "weValueG2Q5Title": "即使我们遇到了与本部门无关的问题，我们也被授权可以参与并协助解决客人的问题。",
      "weValueG3Q1Title": "在我们的酒店，我们对彼此和对客人给予同等水平的照顾。",
      "weValueG3Q2Title": "我们酒店认真践行品牌的价值观。",
      "weValueG3Q3Title": "在我们的酒店，当我们超越预期打造奢华的宾客体验时，我们的工作会被认可和赏识。",
      "weValueG3Q4Title": "在我们的酒店，我们有机会参与本部门在提升工作表现方面的事务。",
      "weValueG3Q5Title": "在我们的酒店，我们始终把客人放在第一位。"
    }
  },
  "gateway": {
    "planProgressComplete": "%{label}完成",
    "gatewayProgressComplete": "GATEWAY进展完成",
    "heroMobileGatewayVideoText": "播放Gateway视频",
    "basics": "基本要素",
    "brandResources": "品牌资源",
    "disciplineResources": "部门资源",
    "immersionResourcesHeader": "沉浸资源",
    "brandCommunicationsHeaader": "品牌宣传",
    "brandContactsHeader": "品牌联系人",
    "franchisedActionItemsText": "行动项目有助于您井井有条地稳步开展工作，并为您提供重要的入职培训信息。这些项目不是必须完成的，并且是对您可能已经从您的特许经营管理公司收到的信息的进一步补充。",
    "global": "全球",
    "continent": "所在洲",
    "viewed": "已查看",
    "completed": "已完成",
    "heading": "Gateway",
    "basicsTab": "基础知识",
    "onboardingTab": "行动项目",
    "brandTab": "品牌资源",
    "disciplinesTab": "部门资源",
    "basicsHeader": "欢迎加入万豪国际",
    "basicsHeaderNewBrandOrRole": "欢迎您！",
    "basicsText": "我们知道，您在职业生涯之初有很多知识需要学习。我们为您选择了一些基础知识以供您在就职前几天学习。其中的部分项目可能会在入职培训或品牌培训的时候进行深入详细地介绍，但此处内容可帮助您顺利入门。",
    "basicsTextGms": "我们知道，您在职业生涯之初有很多知识需要学习。我们为您选择了一些基础知识以供您在就职前几天学习。其中的部分项目可能会在入职培训或品牌培训的时候进行深入详细地介绍，但此处内容可帮助您顺利入门。完成该部分后，请转到其他 Gateway 页面，包括行动项目、品牌资源和部门资源。",
    "basicsTextFranchise": "我们知道您有很多知识需要学习，我们为您选择了一些基础知识以供您在就职的前几天进行学习。这些项目可能进一步补充您的入职培训、品牌培训或特许经营管理公司提供的信息。",
    "basicsTextFranchiseGms": "我们知道您有很多知识需要学习，我们为您选择了一些基础知识以供您在就职的前几天进行学习。这些项目可能进一步补充您的入职培训、品牌培训或特许经营管理公司提供的信息。完成该部分后，请转到其他 Gateway 页面，包括行动项目、品牌资源和部门资源。",
    "basicsTextNewToRole": "我们知道，在您开启全新职业生涯的初期有很多知识需要学习。我们为您选择了一些基础知识以供您在就职前几天学习。其中的部分项目可能会在分配给您的指定培训中进行深入详细地介绍，但此处内容可帮助您顺利入门。",
    "basicsTextNewToRoleGms": "我们知道，在您开启全新职业生涯的初期有很多知识需要学习。我们为您选择了一些基础知识以供您在就职前几天学习。其中的部分项目可能会在分配给您的指定培训中进行深入详细地介绍，但此处内容可帮助您顺利入门。完成该部分后，请转到其他 Gateway 页面，包括行动项目、品牌资源和部门资源。",
    "basicsTextNewToRoleFranchise": "我们知道您有很多知识需要学习，我们为您选择了一些基础知识以供您在新岗位的前几天进行学习。这些项目可能进一步补充您的特许经营管理公司所提供的信息。",
    "basicsTextNewToRoleFranchiseGms": "我们知道您有很多知识需要学习，我们为您选择了一些基础知识以供您在新岗位的前几天进行学习。这些项目可能进一步补充您的特许经营管理公司所提供的信息。完成该部分后，请转到其他 Gateway 页面，包括行动项目、品牌资源和部门资源。",
    "basicsTextNewToBrand": "我们知道，在您开启 %{brandName} 新岗位的初期，您有很多知识需要学习。我们为您选择了一些基础知识以供您在就职前几天学习。其中的部分项目可能会在入职培训或品牌培训的时候进行深入详细地介绍，但此处内容可帮助您顺利入门。",
    "basicsTextNewToBrandGms": "我们知道，在您开启 %{brandName} 新岗位的初期，您有很多知识需要学习。我们为您选择了一些基础知识以供您在就职前几天学习。其中的部分项目可能会在入职培训或品牌培训的时候进行深入详细地介绍，但此处内容可帮助您顺利入门。完成该部分后，请转到其他 Gateway 页面，包括行动项目、品牌资源和部门资源。",
    "basicsTextNewToBrandFranchise": "我们知道您有很多知识需要学习，我们为您选择了一些基础知识以供您在新岗位的前几天进行学习。这些项目可能进一步补充您的入职培训、品牌培训或特许经营管理公司提供的信息。",
    "basicsTextNewToBrandFranchiseGms": "我们知道您有很多知识需要学习，我们为您选择了一些基础知识以供您在 %{brandName} 新岗位的前几天进行学习。这些项目可能进一步补充您的入职培训、品牌培训或特许经营管理公司提供的信息。完成该部分后，请转到其他 Gateway 页面，包括行动项目、品牌资源和部门资源。",
    "disciplineHeader": "部门资源",
    "toDo": "应修",
    "optional": "选修",
    "overallComplete": "全部完成",
    "keyResourcesDesc": "深入了解支持酒店日常运营、宾客体验和业务需求的主要部门。",
    "franchiseFooter": "本资料包含可能加强品牌标准遵从性的推荐和建议做法。作为特许经营授权方，万豪集团不参与特许经营人的酒店或业务运营中任何方面的管理或运营工作。特许经营人享有对其酒店日常运营的控制权，并对自己的员工和雇佣行为负责。",
    "landingHeroTitle1": "欢迎加入万豪国际。",
    "landingHeroTitle2": "在此开启您的学习之旅。",
    "landingHeroText": "The Gateway 是一个指引您在第一年顺利适应新岗位的工具。它将为您提供取得成功所需的信息和资源。",
    "landingHeroTitle1NewToRole": "欢迎访问 The Gateway",
    "landingHeroTitle1NewToBrand": "欢迎访问 The Gateway",
    "landingButtonText": "立即开始",
    "twoMonthslandingHeroTitle1": "欢迎回来！",
    "twoMonthslandingHeroText": "如果您尚未完成，请先完成 The Gateway 中的所有基础知识！",
    "twoMonthslandingButtonText": "进入 Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "欢迎回来！",
    "twoMonthslandingHeroTextNewToBrand": "希望您在 %{brandName} 的前几天过得愉快。不要忘记继续您的学习之旅，获取 The Gateway 中您需要的所有资源",
    "twoMonthslandingButtonTextNewToBrand": "继续前进",
    "threeMonthslandingHeroTitle1": "您的旅程已经开始！",
    "threeMonthslandingHeroText": "The Gateway 涵盖您需要了解的关于万豪国际的所有内容。",
    "threeMonthslandingButtonText": "进入 Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "您的旅程已经开始！",
    "threeMonthslandingHeroTextNewToRole": "The Gateway 涵盖您在新岗位上取得成功所需了解的所有内容。",
    "threeMonthslandingButtonTextNewToRole": "进入 Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "欢迎回来！",
    "threeMonthslandingHeroTextNewToBrand": "恭喜您顺利完成您在新品牌前 %{numDays} 天的工作。您可在任何需要的时候获取您的全部行动项目、品牌和部门资源。",
    "threeMonthslandingButtonTextNewToBrand": "进入 Gateway",
    "fourMonthslandingHeroTitle1": "干得漂亮！您已完成基础知识的学习！",
    "fourMonthslandingHeroText": "获取 The Gateway 中您需要了解的关于万豪国际的所有内容。",
    "fourMonthslandingButtonText": "进入 Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "干得漂亮！您已完成基础知识的学习！",
    "fourMonthslandingHeroTextNewToRole": "获取 The Gateway 中您在新岗位上取得成功所需了解的所有内容。",
    "fourMonthslandingButtonTextNewToRole": "进入 Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway——签到",
    "fourMonthslandingHeroTextNewToBrand": "在您即将结束 %{brandName} 第一年学习之前，请完成您所有的行动项目（如果您尚未完成），并充分利用 Digital Learning Zone 中的开发资源。点击“继续”以继续您的旅程。",
    "fourMonthslandingButtonTextNewToBrand": "继续",
    "gmSecondVisitlandingHeroTitle1": "欢迎回来！",
    "gmSecondVisitlandingHeroText": "希望您的前几天过得愉快。不要忘记继续您的学习之旅，以便帮助您在新岗位中快速成长。",
    "gmSecondVisitlandingButtonText": "继续前进",
    "gmSecondVisitlandingHeroTextNewToRole": "希望您在新岗位的前几天过得愉快。不要忘记继续您的学习之旅，获取 The Gateway 中您需要的所有资源。",
    "gmSecondVisitlandingHeroTextNewToBrand": "希望您在 %{brandName} 的前几天过得愉快。不要忘记继续您的学习之旅，获取 The Gateway 中您需要的所有资源",
    "gmThirdVisitlandingHeroTitle1": "欢迎回来！",
    "gmThirdVisitlandingHeroText": "恭喜您完成了新入职员工培训前 %{numDays} 天的学习。您可以根据需要随时获取您的所有行动项目、品牌和部门资源。",
    "gmThirdVisitlandingButtonText": "进入 Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "恭喜您完成了您在新岗位的前 %{numDays} 天的学习。您可以根据需要随时获取您的所有行动项目、品牌和部门资源。",
    "gmThirdVisitlandingHeroTextNewToBrand": "恭喜您完成了您在新品牌的前 %{numDays} 天的学习。您可以根据需要随时获取您的所有行动项目、品牌和部门资源。",
    "gmSevenMonthslandingHeroTitle1": "您似乎还有很多行动项目有待完成。",
    "gmSevenMonthslandingHeroText": "在您即将结束第一年学习之前，不要忘记完成您所有的行动项目，并充分利用 Digital Learning Zone 中的开发资源。点击“继续”即可继续您的学习之旅",
    "gmSevenMonthslandingButtonText": "继续",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway——签到",
    "gmSevenMonthslandingHeroTextNewToRole": "在您即将结束新岗位第一年学习之前，请完成您所有的行动项目（如果您尚未完成），并充分利用 Digital Learning Zone 中的开发资源。点击“继续”即可继续您的学习之旅",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway——签到",
    "gmSevenMonthslandingHeroTextNewToBrand": "在您即将结束 %{brandName} 第一年学习之前，请完成您所有的行动项目（如果您尚未完成），并充分利用 Digital Learning Zone 中的开发资源。点击“继续”以继续您的旅程。",
    "gmOneYearlandingHeroTitle1": "恭喜您完成 %{brandName} 第一年的学习！",
    "gmOneYearlandingHeroTitle1NewToRole": "恭喜您已完成有关新岗位的第一年学习！",
    "gmOneYearlandingHeroTitle1NewToBrand": "恭喜您完成 %{brandName} 新岗位第一年的学习！",
    "gmOneYearlandingHeroText": "The Gateway 学习之旅已经结束，更多精彩内容敬请期待。您可以随时访问 The Gateway 页面上的资源。",
    "gmOneYearlandingButtonText": "查看 Gateway"
  },
  "resources": {
    "iptTooltip": "使用逗号隔开多个地点",
    "iptAllFunctionTooltip": "取消选中以选择特定的“工作职能”。保持选中以查看所选地点所有职能的培训",
    "iptAllAWRTooltip": "取消选中以选择特定的“其他工作职责” 保持选中以查看所选地点所有“其他工作职责”的培训",
    "editJFTooltip": "使用逗号隔开多个条目"
  },
  "Notification Center: ": "标记为未读",
  "Notification Center: Empty": "您的信息现在是最新状态！",
  "Notification Center: Individual Delete": "确定要删除此通知吗？ 删除的通知已移至“通知中心”的“已删除”部分，直至过期。",
  "Notification Center: Delete All": "您确定要删除所有通知吗？ 这些通知将被移至“通知中心”的“已删除”部分，直至过期。",
  "Notification Center: Mark All As Read": "标记所有为已读",
  "Notification Center: Restore All": "恢复所有",
  "Notification Center: Delete Confirmation": "删除",
  "Warning Message Text: Remove Job Function or AWR": "移除“工作职能”或“额外工作职责” (AWR)？\r\n提交即代表，您将要移除 %{count} 位学员的 %{Function and/or AWR}。",
  "Warning Message Text: Add Job Function or AWR": "新增“工作职能”或“额外工作职责” (AWR)？\r\n提交即代表，您将要为 %{count} 位学员新增 %{Function and/or AWR}。",
  "Button": "提交",
  "Success Message Text: Add Job Function and/or AWR": "成功\r\n您已经为位于 %{location} 的申请员工新增了 %{Job Function and/or AWR}。\r\n\r\n您是否想要为 %{location} 编辑额外的“工作职能”或“额外工作职责”？",
  "Message Text:AWR": "成功\r\n您已经为位于 %{ __} 的申请员工新增了“额外工作职责”%{ __}。\r\n\r\n您是否想要为 %{location, property, learner(s)} 编辑额外的“工作职能”或“额外工作职责”？",
  "Success Message Text: Remove Job Function": "成功\r\n您已经为位于 %{location} 的申请员工移除了 %{Job Function and/or AWR}。\r\n\r\n您是否想要为 %{location} 编辑额外的“工作职能”或“额外工作职责”？",
  "Success Message Text: Remove AWR": "成功\r\n您已经为位于 %{ __} 的申请员工移除了“额外工作职责”%{ __}。\r\n\r\n您是否想要为 %{location, property, learner(s)} 编辑额外的“工作职能”或“额外工作职责”？",
  "Tool Tip - Managed Change Email": "如需更改您的电子邮箱，请访问 mHUB > HR Direct > 我 > 我的公开信息 按照指示更新您的联系信息。可能需要 1-2 个工作日才能显示更新后的电子邮箱。",
  "Tool Tip - Franchised  Change Email": "如需更改您的电子邮箱，请联系您的经理和/或您所在特许经营管理公司的 EID Tool 管理员。可能需要 1-2 个工作日才能显示更新后的电子邮箱。",
  "User Profile: Email Opt-in": "不用，谢谢",
  "User Profile: Email Frequency": "每周一次概览",
  "Generic Notification Email Subject Line:": "DLZ 中有您的新通知",
  "Generic Body Text (<150 Characters)": "请访问 DLZ 以查看详情。",
  "All Emails: Signature": "Digital Learning Zone - 轻松学习。一切由您开始。",
  "All Emails: Footer": "您可以随时取消订阅/选择退出电子邮件通知。访问 Digital Learning Zone/个人资料以更新您的首选项。",
  "Overdue Emails: Subject Line: ": "DLZ 需要您注意的学习任务\r\n需要您注意的 DLZ 学习任务",
  "Overdue Emails: Body Test": "您有过期的学习任务。请登录 DLZ 以查看您的学习任务。",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "增强您的技能",
    "sharpenYourSkillsSubTitle": "掌握知识和技能，以提升现在和未来的职业生涯。",
    "growYourCareerTitle": "发展您的事业",
    "growYourCareerSubTitle": "通过支持您的兴趣和目标的专业技能或交叉培训，助力您的下一步职业规划。",
    "additionalResourcesTitle": "其他资源",
    "additionalResourcesSubTitle": "探索 getAbstract® 和 CultureWizard™，了解 DLZ 如何持续帮助您成长。",
    "tourHeader": "欢迎来到更新版 DLZ。现在，您可以在需要时进行搜索，找到所需的任何内容。此外，您还会发现更新后的“发掘学习”和更加个性化的“我的计划”。尽情体验！",
    "tourBtnSkip": "取消",
    "tourBtnNext": "关闭 (6/6)",
    "tourSearchBar": "使用更强大的新搜索功能，在您需要的时候找到您需要的东西。",
    "tourBtnBack": "返回",
    "tourMyPlan": "“我的计划”现在包含您从“搜索”和“发掘”中添加的项目，因此您可以完全拥有自己的学习旅程！",
    "tourExploreLearningLink": "您仍然可以从这里进入“发掘学习”！",
    "tourExploreLearningProfile": "充分利用您的学习经验并定期核对您的个人资料，尤其是工作职能，以使您的“我的计划”保持最新。",
    "mhubTraining": "mHUB 培训",
    "growthSkills": "成长技能",
    "leadershipSkills": "领导力技能",
    "virtualTeamResources": "虚拟团队资源",
    "jobFunctions": "工作职能",
    "brands": "品牌",
    "languageLearning": "语言学习",
    "become": "成就",
    "emeaMentoringProgram": "EMEA 指导计划",
    "learningPathways": "学习途径",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare Community Hub",
    "mhubTrainingDesc": "查找有关如何使用 mHUB 的基于角色的学习路径和特定主题的课程。",
    "growthSkillsDesc": "获得跨各种功能和主题的实用知识。",
    "leadershipSkillsDesc": "了解如何管理和指导团队并成为更有效的领导者。",
    "virtualTeamResourcesDesc": "学习在虚拟团队中培养协作和包容文化的技巧。",
    "jobFunctionsDesc": "拓展您当前工作职能的知识，并了解其他人。",
    "brandsDesc": "详细了解万豪国际集团的多元化品牌。",
    "languageLearningDesc": "与全球观众进行有效沟通。",
    "becomeDesc": "探索职业成长和发展的整体方法：反思、规划、行动、改进。",
    "emeaMentoringProgramDesc": "通过 EMEA 指导计划释放您的潜力。",
    "learningPathwaysDesc": "发掘为您的领导水平量身制定的特定学习机会。",
    "mdaDesc": "通过 Marriott Development Academy 培养领导技能和职能技能。",
    "takeCareGrowDesc": "找到帮助建立具有包容性和启发性的社区的资源。",
    "getAbstractDesc": "每天通过书籍摘要学习新知识，帮助您做出更明智的决定。",
    "cultureWizardDesc": "收集有价值的知识，以便在不同文化之间进行有效沟通。",
    "takeCareCommunityHubDesc": "探索 TakeCare 团队建设活动、学习计划和工具。",
    "searchAllLearning": "搜索全部学习内容",
    "searchBarPlaceHolder": "学无止境。",
    "clearFilters": "清除筛选条件",
    "noResults": "无结果",
    "hasBeenUnpinned": "{Variable-tile_Name} 已被移除固定。",
    "hasBeenPinned": "{Variable-tile_Name} 已被固定。",
    "lpRemovedFromPlan": "学习途径已从您的计划中移出。",
    "courseRemovedFromPlan": "课程已从您的计划中移出。",
    "lpAddedToPlan": "学习途径已添加至您的计划。",
    "courseAddedToPlan": "课程已添加至您的计划。",
    "clearSelection": "清除选择",
    "removeFromMyPlan": "从我的计划中移出",
    "addToMyPlan": "添加至我的计划",
    "loadingResults": "正在加载",
    "viewDetails": "查看详情",
    "clearAll": "清除所有",
    "noOptions": "无可选项",
    "viewLess": "查看更少",
    "viewMore": "查看更多",
    "recentSearch": "近期搜索",
    "exploreLearningTitle": "发掘学习",
    "exploreLearningHelpText": "不确定您在寻找的内容？",
    "exploreMore": "探索更多。",
    "viewAllResults": "查看所有 <#> 条结果",
    "searchSubTitle": "使用关键词、标题或内容 ID 搜索课程",
    "pinnedLearningTitle": "我固定的学习内容",
    "landingSubTitle": "开启您的学习之旅。探索您的兴趣——不断添加新的话题和课程。",
    "columnTitle": "标题",
    "columnDesc": "描述",
    "columnType": "类型",
    "columnAddedToPlan": "添加至计划",
    "columnComplete": "已完成",
    "columnDetails": "详细信息",
    "additionalSearchFilters": "额外的搜索筛选条件",
    "tourBtnClose": "关闭",
    "tourBtnLast": "关闭",
    "resultsFor": "的结果",
    "resultsFortheFilter": "筛选条件{Filter Name}的结果",
    "no": "无",
    "results": "结果",
    "courseTooltipTitle": "可能包含一个或多个活动的单个学习项目。<br />课程可能采取在线（例如视频或在线学习）或课堂授课的方式。",
    "gatewayToolTipTitle": "供新员工、新晋管理人员以及品牌新晋总经理使用的数字资源。",
    "speciallizationOrPathwayTooltipTitle": "专门针对特定主题的一组课程。",
    "taskTooltipTitle": "有关完成活动（通常指在职活动）及随后标记您已完成活动的说明。",
    "webSiteTooltipTitle": "任何网站，包括万豪官网或外部网站。"
  },
  "dontShowAgain": "不再显示此项",
  "deletionWarningEnabled": "删除警告已启用",
  "deletionSelected": "删除时，您将在每次删除通知的时候看到一条警告。",
  "manageEmailNotifications": "管理电子邮件通知",
  "seeHowDLZContinue": "了解 DLZ 如何持续帮助您成长。",
  "Notification Center:Overdue": "您有一项新的培训，该项培训将于 {Variable date ddmmyy}到期",
  "accesDenied": {
    "title": "访问您的“学习之旅”正在进行中！",
    "subtitle": "正在创建您的访问权限，您的 EID 激活后 1-2 天内便可使用。如果超过 2 天，请联系 mHUB 服务中心。"
  },
  "jfTour": {
    "title": "您现在可以直接从“我的计划”中查看和编辑您所选的工作职能和其他工作职责 (AWR)。"
  },
  "onboardingPlan": {
    "requiredDescription": "以下是您在“我的计划”中所选的时间内需完成的必修课。与您的领导协商安排出一定时间，在您适应新角色的同时完成必修课的学习。您将学习各种主题的内容，在此过程中如有任何问题，请务必与您的领导和同事联系。   ",
    "day1": "第 14 天到期",
    "day2": "第 30 天到期",
    "day3": "第 60 天到期",
    "day4": "第 90 天到期",
    "day5": "90 天后到期 "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone 是您的专业学习和发展的起点。下面您会发现帮助您入门的内容。您所有的学习内容都可以在“我的计划”中找到。  ",
    "overallLearningProgress": "总体学习进度",
    "learningProgressLabel": "学习进度",
    "learningProgressDescription": "此处显示您需要参加的学习课程的进度。此进度条包含您需要参加的所有学习课程的状态。再接再厉！"
  },
  "Recommended Courses ": "90 天后，您会看到您的经理推荐的其他课程。您也可以自己探索感兴趣的主题。现在，请专注于您的基础学习。",
  "survey": {
    "v2": {
      "weValueButtonText": "参加调查",
      "lbiIntro": "欢迎参加豪华服务行为 (Luxury Behavior) 调查！",
      "lbiDescription": `<p>下面将要求您阅读一些您可能在酒店遇到的简短场景。请阅读每个场景，并选择您对每种情况的回应。回答时，请牢记以下几点：</p>
      <ul>
          <li>
              <p>您可能会发现似乎有多个正确答案可供选择。选择您在工作中最有可能做出的回应。</p>
          </li>
          <li>
              <p>假设没有沟通障碍。您说的语言与场景中客人或同事的语言相同。
              </p>
          </li>
          <li>
              <p>场景可能会要求您想象自己所担任的工作职责与您当前的职责不同。根据您作为豪华酒店专业人士的经验，尽您所知回答问题。
              </p>
          </li>
      </ul>
      <p><br></p>
      <p>请注意：您参加此次调查纯属自愿（您可以选择不参加），您的回答将严格保密。将以组群（而非特定个人）为单位报告此次调查的结果。个人回答将由调查团队进行评估，并且不会与其他人的经理或领导分享。
      </p>
      <p>完成此次调查即表示您同意收集和处理您的个人回答和数据（例如服务年限、性别、职衔），以及将您的个人数据跨境传输给万豪国际集团。这种做法符合万豪员工数据隐私声明，您还可以参阅有关万豪数据隐私政策的更多信息和联系方式。
      </p>
      `,
      "checkin": {
        q1Title: "您走过大堂时，注意到一个有三个小孩的家庭。这家人带了很多行李，经过一天的旅途劳顿后显得非常疲累，小孩也在吵闹。您会怎么做",
        q2Title: "您刚开始在一个陌生地方的酒店工作。一位客人要求推荐在该地区的一些活动项目。客人已经阅读了一些旅行指南，但想找一些更具个性化的东西。您会怎么做？",
        q3Title: "您走过大堂时听到一位客人在打电话，得知对方去机场的车晚点了。您的酒店没有配备内部用车或车辆没空。您会怎么做？",
        q4Title: "您穿过大堂去完成经理交给您的一项紧急任务。您看到一位同事正在设法完成一项通常由两个人才能完成的工作。您会怎么做？",
        q5Title: "您是酒店餐厅的服务员。一位客人在等待预定的餐桌时要求看菜单。看完菜单后，客人感到非常懊恼，因为酒店餐厅是另一位同事推荐的，但菜单上没有任何他们喜欢的菜品。您会怎么做？",
        q6Title: "泳池区人非常多，大群客人没有座位。因此，他们将毛巾和个人物品铺开坐在主通道上，给其他客人和员工进出泳池区域造成阻碍和危险。需要使该区域恢复畅通状态，但您又不想令这群客人感到不愉快。您会怎么做？",
        q1R1Title: "帮这家人提行李，告诉他们登记入住服务台的位置，并建议他们到旁边一些适合家庭活动的场所休息。介绍酒店的家庭设施。（",
        q1R2Title: "主动帮他们提行李，并引导这家人办理入住手续。然后，为父母安排放松或护理服务，并在客房内为孩子们安排有趣的活动。",
        q1R3Title: "引导父母办理入住手续，并在这期间亲自带孩子到有零食的座位区等候。请前台告知家长酒店的游乐区和儿童活动等舒适设施的相关信息。",
        q1R4Title: "引导这家人到登记入住服务台快速办理入住手续。向父母介绍适合儿童的区域和服务，以便孩子们入住愉快。",
        q2R1Title: "询问客人的喜好，然后与同事一起为他们制作一份有趣活动清单。此外，还可以推荐附近较好的餐厅。",
        q2R2Title: "询问客人的喜好。与同事一起为他们制作一份有趣活动特别清单。此外，查看客人的旅享家个人资料，并给他们的客房送去一些特色小吃。",
        q2R3Title: "分享目前为止您自己在该地区最喜欢做的一些事情，为客人进行个性化推荐。通过分享有关您体验的更多详情，使您的建议新颖独特。然后，给客人的房间送去一些当地小吃。",
        q2R4Title: "告诉客人您刚到这个地方不久，还不太了解情况。表达歉意并建议他们咨询更了解情况的同事。然后，询问客人是否需要将一些当地小吃送到他们的房间。",
        q3R1Title: "在客人打电话期间，将您自己或同事的私家车开到酒店大堂门口，告诉该客人您将亲自开车将其送往机场。",
        q3R2Title: "在客人打电话期间，整理一份酒店认可的汽车服务和电话号码清单。在客人打完电话后，将清单给他们，并提出可以帮忙安排交通。",
        q3R3Title: "等客人打完电话后，告诉对方您的酒店不提供交通服务，但您很乐意帮忙协调安排其他交通工具。",
        q3R4Title: "在客人打电话期间，为对方寻找其他交通工具。在客人打完电话后，告诉对方您已安排车辆将其送往机场，车子很快就到了。",
        q4R1Title: "走上前去，告诉同事您可以帮忙，但必须先完成紧急任务。承诺会尽快回来帮忙。务必将该情况告知您的经理。",
        q4R2Title: "首先，迅速找另一位同事来替您完成分配给您的任务。然后，立即帮助需要帮忙的同事。结束后，告知您的经理您找了其他人来完成您自己的任务，以便您可以帮助同事。",
        q4R3Title: "立即停下自己的工作去帮忙同事。结束后，再回过头来完成您自己的任务，然后告知您的经理您暂时停下手头的工作是为了帮忙同事。",
        q4R4Title: "走上前去，告诉需要帮忙的同事您正在处理一项紧急任务。各自停下手头的工作，商讨办法共同解决双方的任务，然后一起完成这两项任务。",
        q5R1Title: "向客人道歉，告诉对方您会请经理过来。务必解释清楚情况，以便经理可以帮客人解决食物需求。",
        q5R2Title: "向客人道歉并询问对方的饮食喜好。与厨房团队商量，专门为客人烹制一道特别的菜肴。此外，给客人赠送免费的开胃品或饮料来弥补错误。",
        q5R3Title: "表达歉意，并告诉客人您理解他们的感受。主动与厨师商量（而不是取消预订），看看是否可以专门为客人烹制特别的菜品。给客人免费赠送菜品或打折作为弥补。",
        q5R4Title: "向客人道歉并告诉对方，您可以帮忙预订附近供应他们喜欢的菜品的餐厅。可根据需要提供额外信息或帮助。",
        q6R1Title: "为空间不足向客人表示歉意，并告诉对方他们可以坐在原地，但需要留意路过的其他人。",
        q6R2Title: "委婉告诉客人他们不能阻挡通道，需要另找地方等待或等到有位置后再过来。同时，请同事从外面的宴会厅搬来座位供他们使用。",
        q6R3Title: "告诉客人不能留在当前位置，必须暂时离开直到您找到其他座位。同时领他们到酒吧/餐厅就坐。 ",
        q6R4Title: "就座位问题向客人表示歉意。询问他们是否可以将自己的物品集中放在一起，为其他人留出小通道。主动帮忙，确保每个人都感到满意。给客人赠送饮品/菜品，以弥补给他们带来的不便。"
      }
    }
  },
};