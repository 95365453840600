// @flow
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faBook,
  faCheck,
  faVideo,
  faTags,
  faArrowRight,
  faNewspaper,
  faGraduationCap,
  faVolumeUp,
  faMobileAlt,
  faLaptop,
  faFileAlt,
  faPlayCircle,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faExclamationCircle,
  faClock,
  faLifeRing,
  faThumbtack as faThumb
} from '@fortawesome/free-solid-svg-icons';

import { 
  faPlus,
  faMinus,
  faTimes,
  faBars,
  faPrint,
  faEnvelope,
  faEnvelopeOpen,
  faArrowToBottom,
  faBullseyeArrow,
  faMapSigns,
  faFilter,
  faShare,
  faPlay,
  faArrowDown,
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faArrowLeft,
  faSearch,
  faExternalLink,
  faQuestionCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faDownload,
  faSlidersH,
  faUsdCircle,
  faThumbtack,
  faCheckSquare,
  faSquare,
  faEdit,
  faCheck as faCheckLight,
} from '@fortawesome/pro-light-svg-icons';

import {
  faUserCircle,
  faFlag,
  faEye,
  faEyeSlash,
  faThumbsDown
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faPlayCircle,
  faBullseyeArrow,
  faBook,
  faCheck,
  faVideo,
  faTags,
  faArrowRight,
  faNewspaper,
  faGraduationCap,
  faVolumeUp,
  faMobileAlt,
  faLaptop,
  faFileAlt,
  faPlay,
  faArrowLeft,
  faArrowToBottom,
  faPrint,
  faUserCircle,
  faBell,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faChevronCircleLeft,
  faChevronCircleRight,
  faBullseyeArrow,
  faMapSigns,
  faBars,
  faTimes,
  faAngleLeft,
  faAngleRight,
  faPlus,
  faMinus,
  faEnvelope,
  faEnvelopeOpen,
  faClock,
  faFilter,
  faShare,
  faArrowDown,
  faArrowUp,
  faLifeRing,
  faSearch,
  faExternalLink,
  faQuestionCircle,
  faExclamationCircle,
  faSlidersH,
  faEyeSlash,
  faEye,
  faFlag,
  faEyeSlash,
  faUsdCircle,
  faThumbtack,
  faThumbsDown,
  faThumb,
  faDownload,
  faCheckSquare,
  faSquare,
  faEdit,
  faCheckLight,
);
