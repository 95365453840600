export default {
  "banner": {
    "viewDetails": "Afficher les détails",
    "requiredCourses": "Vos cours obligatoires doivent être terminés dans",
    "days": "jours"
  },
  "header": {
    "plan": "Mon plan",
    "manager": "RESPONSABLE",
    "explore": "DÉCOUVRIR LES FORMATIONS",
    "reporting": "RAPPORTS",
    "myTeam": "MON ÉQUIPE",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "MON PLAN",
    "manager": "RESPONSABLE",
    "explore": "DÉCOUVRIR LES FORMATIONS",
    "tagline": "Apprenez en toute simplicité. Tout commence avec vous."
  },
  "welcome": {
    "hi": "Bonjour",
    "intro1": "Bienvenue sur la Digital Learning Zone (plateforme de formation numérique) ! La DLZ est une plateforme de formation personnalisée qui représente un point d’accès central aux formations de Marriott. Recherchez la formation requise pour votre rôle et découvrez les possibilités de formation pour votre perfectionnement personnel et professionnel. Assumez votre parcours de formation et lancez-vous sur la voie du perfectionnement de vos compétences.",
    "intro2": "Avant de commencer, nous souhaitons en savoir plus sur vous.",
    "letsGo": "C’EST PARTI",
    "preferredLanguage": "Quelle est votre langue préférée ?",
    "step": "ÉTAPE",
    "of": "DE",
    "previous": "PRÉCÉDENT",
    "next": "SUIVANT",
    "startDate": "Quand avez-vous commencé à travailler pour Marriott International ou pour Starwood ?",
    "selectYear": "SÉLECTIONNER L’ANNÉE",
    "selectMonth": "SÉLECTIONNER LE MOIS",
    "selectDay": "SÉLECTIONNER LA DATE",
    "selectFunction": "SÉLECTIONNER LA FONCTION",
    "selectInterests": "SÉLECTIONNER LES CENTRES D’INTÉRÊT",
    "chooseYear": "CHOISIR UNE ANNÉE",
    "chooseMonth": "CHOISIR UN MOIS",
    "chooseDay": "CHOISIR UNE DATE",
    "whatDo": "Quelle est votre fonction professionnelle",
    "selected": "SÉLECTIONNÉ",
    "searchFunctions": "Rechercher dans toutes les fonctions",
    "searchInterests": "Rechercher dans tous les centres d’intérêt",
    "additionalJobFunctionsLabel": "Dites-nous en plus à propos de votre fonction",
    "managementLevelLabel": "Quel est votre niveau d’employé ?",
    "preferredBrandLabel": "Si vous travaillez pour plus d’une enseigne, sélectionnez toutes celles qui s’appliquent à vous.",
    "additionalJobFunctionsLabelExtra": "Consultez les responsabilités professionnelles suivantes et SÉLECTIONNEZ-EN AU MOINS UNE de plus.",
    "searchAdditionalFunctions": "Rechercher dans toutes les responsabilités professionnelles supplémentaires",
    "highGuestContactLabel": "Avez-vous un contact élevé avec les clients ?",
    "yes": "Oui",
    "no": "Non "
  },
  "landing": {
    "started": "Commençons",
    "welcomeBack": "Nous sommes ravis de vous revoir,",
    "greatWork": "Excellent travail",
    "requiredCourses": "Vos cours obligatoires",
    "requiredCoursesDesc": "Terminez les cours avant la date d’échéance.",
    "myPlan": "Mon plan",
    "courses": "Cours",
    "courseComplete": "COURS TERMINÉ",
    "viewMore": "AFFICHER PLUS DE COURS OBLIGATOIRES",
    "viewMoreBtn": "AFFICHER PLUS DE COURS",
    "showing": "AFFICHAGE DE",
    "of": "SUR",
    "heroTitle": "Apprenez quelque chose de nouveau. Donnez-vous les moyens. Acquérez des compétences qui s’appliquent à votre monde.",
    "heroParagraph1": "Suivez facilement tous vos cours obligatoires et recevez des notifications en temps réel pour vous rappeler de les terminer.",
    "heroParagraph2": "Profitez d’expériences d’apprentissage personnalisées qui se basent sur votre métier et vos centres d’intérêt.",
    "recommendedCoursesTitle": "Cours recommandés",
    "recommendedCoursesDescNoContent": "Aucun cours ne vous est recommandé pour le moment. Consultez les renseignements ci-dessous pour découvrir d’autres opportunités d’apprentissage.",
    "recommendedCoursesDesc": "Découvrez ce que votre responsable ou votre spécialiste en ressources humaines a sélectionné pour vous.",
    "recommendedCoursesBtn": "AFFICHER LES COURS RECOMMANDÉS",
    "completedCoursesBtn": "AFFICHER LES COURS TERMINÉS",
    "exploreTitle": "Restez curieux.",
    "exploreDesc": "Sélectionnez les rubriques qui vous intéressent.",
    "jobFunctions": "Discipline",
    "growthSkills": "Compétences de développement",
    "leadershipSkills": "Compétences de leadership",
    "brands": "Enseignes",
    "languageLearning": "Apprentissage des langues",
    "exploreBtn": "AFFICHER LES COURS",
    "managerRecommendation": "RECOMMANDATIONS DU RESPONSABLE",
    "learningRecommendation": "RECOMMANDATIONS DE FORMATIONS",
    "marriottRecommendation": "RECOMMANDATIONS DE MARRIOTT",
    "completedCourses": "Vous avez terminé tous vos cours obligatoires !",
    "investTitle": "Assumez votre parcours de formation",
    "investParagraph1": "Nous nous rendons compte que les circonstances actuelles sont difficiles à bien des égards - le DLZ est une ressource pour vous. Que vous souhaitiez mettre vos compétences à jour, apprendre quelque chose de nouveau, gérer votre stress ou apprendre à travailler au sein d’une équipe virtuelle. Nous avons rassemblé des ressources pour vous soutenir.",
    "updatedTopics": "Sujets mis à jour",
    "workingRemotely": "Ressources d’équipe virtuelles",
    "relatedCoursesNew": "Cliquez ci-dessous pour mettre à jour vos intérêts, car nous avons ajouté de nouveaux sujets et des cours.",
    "relatedCourses": "Veuillez cliquer ci-dessous pour sélectionner vos intérêts.",
    "notificationText": "Nouvelle formation mHUB disponible. Choisissez mHUB dans Explore Learning.",
    "mHubHeroTitle1": "Simple. Astucieux. Sûr. Introduction à mHUB.",
    "mHubHeroManagedText1": "mHUB intègre des outils pour l’apprentissage, le développement de carrière, les remises Explore et plus dans une seule plateforme facile à utiliser.",
    "mHubHeroFranchisedText1": "mHUB intègre des outils pour l’apprentissage, les remises Explore et plus dans une seule plateforme facile à utiliser.",
    "mHubHeroVisitLink": "Cliquez ici pour voir d’autres détails ou pièces jointes de l’offre.",
    "myGateway": "My Gateway",
    "gatewayReview": "Veuillez examiner et terminer.",
    "gatewayProgress": "Progression sur Gateway",
    "complete": "TERMINER",
    "reviewContent": "Examiner les ressources",
    "gatewayCardTitle": "Ressources Gateway",
    "gatewayCardDesc": "Accédez à vos fondements pour la première année et au-delà.",
    "gatewayCardDescGms": "Accédez à vos fondements, Éléments d’action, Ressources de la marque et Ressources par Département pour la première année et au-delà.",
    "gatewayCardDescNew": "Accédez à vos fondements pour la première année dans votre nouveau rôle.",
    "gatewayCardDescGmsNew": "Accédez à vos fondements, Éléments d’action, Ressources de la marque et Ressources par Département pour la première année et au-delà dans votre nouveau rôle."
  },
  "courseBundle": {
    "completeBy": "À TERMINER AVANT",
    "remainingCourses": "Cours restants",
    "completedCourses": "Cours terminés",
    "remainingLabel": "Il y a {courseCount} cours dans ce parcours de formation.",
    "recommendButton": "Recommandé à l’équipe",
    "startCourse": "Commencer le cours",
    "durationMins": "MINUTES",
    "completed": "Terminé",
    "back": "Retour",
    "additionalLangs1": "En plus de l’anglais, ce cours est maintenant disponible en",
    "additionalLangs2": "Sélectionnez votre langue préférée dans le menu déroulant après avoir lancé le cours.",
    "targetAudience": "Public cible",
    "all": "Tout",
    "globally": "dans le monde entier",
    "source": "Source",
    "courseType": "Type de cours",
    "course": "Cours"
  },
  "profile": {
    "save": "Enregistrer",
    "edit": "Modifier",
    "brand": "Enseigne",
    "brands": "Enseignes",
    "additionalWork": "Toutes les responsabilités professionnelles supplémentaires",
    "startDateLabel": "Quand avez-vous commencé à travailler pour Marriott International ou pour Starwood ?",
    "preferredLanguageLabel": "Quelle est votre langue préférée ?",
    "jobFunctionsLabel": "Quel est votre rôle professionnel ?",
    "interestsLabel": "Que voulez-vous apprendre ?",
    "brandsLabel": "Pour quelle(s) enseigne(s) travaillez-vous ?",
    "additionalJobFunctionsLabel": "Quelles fonctions professionnelles supplémentaires assumez-vous ?",
    "managementLevelLabel": "Quel est votre niveau d’employé",
    "highGuestContactLabel": "Avez-vous un contact élevé avec les clients ?"
  },
  "courseDetails": {
    "completeBy": "À finaliser",
    "recommendTitlte": "Cours recommandés à votre équipe",
    "courseName": "Nom du cours",
    "sendRecommendation": "Envoyer une recommandation"
  },
  "explore": {
    "relatedCourses": "Voici les cours liés à vos centres d’intérêt.",
    "chooseInterests": "Choisissez vos centres d’intérêt",
    "title": "Découvrir les formations",
    "chooseInterestsModal": "Choisir ses centres d’intérêt",
    "oppsTitle": "Découvrir les opportunités d’apprentissage",
    "growWithUsTitle": "Grandissez avec nous",
    "growWithUsDesc": "Chez Marriott, nous pensons que votre carrière professionnelle doit évoluer pour vous permettre de vous épanouir. Nous croyons également que le fait d’élargir ses horizons permet souvent d’enrichir son expérience professionnelle.",
    "growWithUsBtn": "AFFICHER GRANDISSEZ AVEC NOUS",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "Marriott valorise la réussite et l’engagement. Nous honorons et encourageons le leadership et un service exceptionnel. Nous offrons également des bonus pour les recommandations fructueuses.",
    "mdaBtn": "AFFICHER MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "Dites-nous quelles rubriques vous intéressent.",
    "otherRecommended": "Autres cours recommandés",
    "searchByName": "Recherchez vos intérêts"
  },
  "plan": {
    "completedRequired": "Vous avez terminé tous vos cours obligatoires !",
    "requiredCourses": "Cours obligatoires",
    "reqDesc": "Terminez-les avant leurs dates d’échéance.",
    "complete": "TERMINER",
    "of": "DE",
    "courses": "COURS",
    "completedCourses": "Cours terminés",
    "completedDesc": "Félicitations ! Vous avez récemment terminé les cours ci-dessous.",
    "remaining": "restants",
    "myLearningTitle": "Mon apprentissage complémentaire ",
    "myLearningSubTitle": "Éléments que j’ai ajoutés à Mon plan",
    "history": "Historique",
    "myLearningHistoryLinkText": "Afficher tout mon historique",
    "jf": "Votre / Vos fonction(s) professionnelle(s)",
    "awr": "Vos responsabilités professionnelles supplémentaires (AWR)",
    "jfAwrDesc": "Important : fonction professionnelle et AWR déterminent les formations nécessaires qui vous sont affectées sur votre espace Mon plan. Pour mettre à jour votre profil, veuillez ",
    "jfAwrEdit": "sélectionner MODIFIER.",
    "jfAwrEnd": "",
    "tourPlan": "Mon programme regroupe toutes les formations obligatoires et recommandées que vous devez achever en fonction de leurs dates d’échéance.",
    "tourBasics": "Marriott Basics comprend toutes les ressources nécessaires pour vous permettre de débuter votre parcours Marriott et vous offre la possibilité d’y accéder à tout moment durant votre carrière. Marriott Basics est un complément à votre formation dans Mon programme.",
    "noCourses": "Vous êtes à jour en matière de formations requises en ce moment. N’hésitez pas à anticiper et à débuter les formations obligatoires à venir."
  },
  "team": {
    "title": "Gérez votre équipe",
    "subtitle": "Développez leurs compétences et leurs carrières.",
    "functions": "Fonctions",
    "viewTeam": "Afficher l’équipe",
    "allCourses": "Tous les cours",
    "downloadExcel": "Télécharger le rapport au format Excel"
  },
  "feedbackButton": {
    "havingTrouble": "Suggestions"
  },
  "recommendation": {
    "dismiss": "Êtes-vous sûr de vouloir rejeter cette recommandation ?"
  },
  "table": {
    "filterTable": "Filtertabelle"
  },
  "notifications": {
    "addAssignment2": "Une tâche %{assignment_type} de %{assignment_name} a été ajoutée à votre %{page location}, avec pour échéance le %{due}.",
    "comingDueAssignment": "L’échéance pour votre tâche %{assignment_type} de %{assignment_name} approche %{due}.",
    "overdueAssignment": "L’échéance pour votre tâche %{assignment_type} de %{assignment_name} est maintenant dépassée.",
    "addAssignment": "Une tâche %{assignment_type} de %{assignment_name} a été ajoutée à votre %{page location}.",
    "profile": "Une mise à jour du titre de votre poste ou de votre lieu de travail a été détectée. Veuillez examiner votre profil pour effectuer toute mise à jour applicable à l’enseigne, à la fonction ou à la responsabilité professionnelle supplémentaire. Les information précises du profil DLZ permettent de garantir que vous recevez les tâches de formation correctes.",
    "helptip": "Toutes les notifications expirent après 30 jours.",
    "markRead": "Marquer comme lu",
    "delete": "Supprimer",
    "markAllRead": "Marqué tout comme lu",
    "settings": "Paramètres",
    "tooltip": "Les notifications lues seront supprimées dans 30 jours",
    "reportComplete": "Votre rapport DLZ est terminé. Cliquez pour télécharger",
    "dontShowThisAgain": "Ne plus afficher ce message",
    "warningEnabled": "L’alerte de suppression a été activée.",
    "manageEmailNotifications": "Gérer les notifications par courriel",
    "warningTooltip": "Lors de la sélection, une alerte sera affichée à chaque fois que vous supprimez une notification.",
    "changeEmailManagedTooltip": "Pour modifier votre adresse e-mail, accédez à mHUB > HR Direct > Moi > Mes informations publiques. Suivez les instructions pour mettre à jour vos coordonnées. L’affichage de l’adresse e-mail mise à jour peut prendre un à deux jours ouvrables.",
    "changeEmailFranchisedTooltip": "Pour modifier votre adresse e-mail, contactez votre responsable et/ou l’administrateur EID Tool de votre franchise. L’affichage de l’adresse e-mail mise à jour peut prendre un à deux jours ouvrables.",
    "deleteAllNotifications": "Supprimer toutes les notifications",
    "deleteIndividualHeader": "Supprimer la notification",
    "deleteAllConfirmation": "Voulez-vous vraiment supprimer toutes les notifications ? Elles seront déplacées vers la section « Supprimé » du centre de notification jusqu’à ce qu’elles expirent.",
    "deleteIndividualConfirmation": "Voulez-vous vraiment supprimer cette notification ? Les notifications supprimées sont déplacées vers la section « Supprimé » du centre de notification jusqu’à ce qu’elles expirent.",
    "permanentDeleteIndividiualConfirmation": "Voulez-vous vraiment supprimer définitivement cette notification ? Cette action est irréversible.",
    "permanentDeleteAllConfirmation": "Voulez-vous vraiment supprimer définitivement toutes les notifications ? Cette action est irréversible.",
    "notificationDeleted": "La notification a été supprimée.",
    "notificationsDeleted": "Les notifications ont été supprimées.",
    "notificationRestored": "La notification a été restaurée.",
    "notificationsRestored": "Les notifications ont été restaurées.",
    "enableDeletionWarning": "Alerte de suppression activée",
    "warningDisabled": "L’alerte de suppression a été désactivée.",
    "viewAll": "Tout afficher",
    "deleteAll": "Tout supprimer",
    "notificationCenterTitle": "Centre de notification",
    "markAsRead": "Marquer comme lu",
    "noDeletedNotifications": "Vous êtes à jour !",
    "restore": "Restaurer",
    "restoreAll": "Restaurer tout",
    "viewCourse": "Afficher le cours",
    "title": "Notifications",
    "noNewNotifications": "Vous n’avez aucune notification.",
    "cancel": "Annuler",
    "manageNotificationsCopy": "Gérer les notifications par courriel\n\nChoisissez si vous souhaitez recevoir des e-mails hebdomadaires résumant vos éléments de formation en retard. Sélectionnez « Gérer » pour modifier vos préférences."
  },
  "transcript": {
    "history": "Mon historique",
    "completed": "Tâches terminées",
    "assignmentName": "Nom de la tâche",
    "type": "Type",
    "viewAssignment": "Afficher la tâche",
    "assignmentSource": "Source de la tâche",
    "requiredAssignments": "Tâches requises",
    "recommendedAssignments": "Tâches recommandées",
    "voluntary": "Volontaires",
    "trasscript": "Transcription",
    "retraining": "Reconversion",
    "completionDate": "Date d’achèvement",
    "assignmentType": "Type de tâche",
    "contentType": "Type de contenu",
    "contentId": "Identifiant du contenu"
  },
  "contentLaunch": {
    "review": "Examiner la formation",
    "start": "Commencer la formation"
  },
  "survey": {
    "weValue": "Nous aimerions avoir votre avis",
    "weValueDescription": "Veuillez répondre à cinq questions rapides sur votre expérience de travail dans votre établissement.",
    "weValueButtonText": "Répondre à l’enquête",
    "weValueG1Q1A1": "Oui",
    "weValueG1Q1A2": "Non",
    "strongAgree": "Tout à fait d’accord",
    "agree": "D’accord",
    "slightAgree": "Légèrement d'accord",
    "slightDisagree": "Pas tout à fait d'accord",
    "disagree": "Pas d’accord",
    "strongDisagree": "Pas du tout d’accord",
    "weValueG1Q1Title": "Avez-vous participé à une réunion quotidienne lors de votre dernière prise de service ? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments ou Daily EDITION)",
    "weValueG1Q2Title": "Les réunions quotidiennes (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments ou Daily EDITION) m’inspirent à créer un service de luxe chaque jour.",
    "weValueG1Q3Title": "Mon département s’efforce de réduire les défauts, même ceux qui ne sont pas signalés par les clients.",
    "weValueG1Q4Title": "Je suis habilité(e) à résoudre les problèmes des clients sans devoir demander une autorisation au préalable.",
    "weValueG1Q5Title": "J’ai le pouvoir de faire plaisir aux clients sans avoir à demander une autorisation au préalable.",
    "weValueG2Q1Title": "Je vois régulièrement les membres de l’équipe de mon établissement se surpasser pour impressionner les clients par les services qu’ils dispensent.",
    "weValueG2Q2Title": "Mon apprentissage dans ce service m’a permis de fournir un service de luxe.",
    "weValueG2Q3Title": "J’ai confiance en mes connaissances et mes compétences pour fournir un service de luxe.",
    "weValueG2Q4Title": "Dans mon département, nous fournissons un environnement de luxe, propre et sans défaut.",
    "weValueG2Q5Title": "Même lorsque je rencontre un problème qui ne concerne pas directement mon département, je suis habilité(e) à intervenir et à prendre en charge le problème du client.",
    "weValueG3Q1Title": "Dans mon établissement, les membres de l’équipe reçoivent le même niveau d’attention que les clients.",
    "weValueG3Q2Title": "Mon département représente les valeurs de la marque.",
    "weValueG3Q3Title": "Lorsque je me surpasse afin de créer une expérience de luxe pour les clients, mon travail est reconnu et apprécié.",
    "weValueG3Q4Title": "Je participe aux efforts de notre département pour améliorer notre travail.",
    "weValueG3Q5Title": "Dans mon département, nous accordons toujours la priorité aux clients.",
    "submitButton": "Envoyer",
    "phasetwo": {
      "weValueG1Q1Title": "Avez-vous participé à une réunion quotidienne lors de votre dernière prise de service ? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments  ou Daily EDITION) ?",
      "weValueG1Q2Title": "Les réunions quotidiennes (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments ou Daily EDITION) inspirent les membres de l’équipe à créer un service de luxe chaque jour.",
      "weValueG1Q3Title": "Dans mon établissement, nous nous efforçons de réduire les défauts, même ceux qui ne sont pas signalés par les clients.",
      "weValueG1Q4Title": "Dans mon établissement, nous sommes habilités à résoudre les problèmes des clients sans devoir demander une autorisation préalable.",
      "weValueG1Q5Title": "Dans mon établissement, nous sommes habilités à offrir une attention supplémentaire aux clients sans avoir à demander une autorisation préalable.",
      "weValueG2Q1Title": "Dans mon établissement, nous nous surpassons régulièrement pour impressionner les clients par les services que nous dispensons.",
      "weValueG2Q2Title": "Les opportunités d'apprentissage dans mon établissement nous ont permis de fournir un service de luxe.",
      "weValueG2Q3Title": "Dans mon établissement, nous avons confiance en nos connaissances et nos compétences pour fournir un service de luxe.",
      "weValueG2Q4Title": "Dans mon établissement, nous fournissons un environnement de luxe, propre et sans défaut.",
      "weValueG2Q5Title": "Même lorsque nous rencontrons un problème qui ne concerne pas directement notre département, nous sommes habilités à intervenir et à prendre en charge la requête du client.",
      "weValueG3Q1Title": "Dans mon établissement, nous bénéficions de la même bienveillance les uns envers les autres que celle que nous fournissons aux clients.",
      "weValueG3Q2Title": "Mon établissement représente les valeurs de la marque.",
      "weValueG3Q3Title": "Dans mon établissement, lorsque nous nous surpassons afin de créer une expérience de luxe pour les clients, notre travail est reconnu et apprécié.",
      "weValueG3Q4Title": "Dans mon établissement, nous avons la possibilité de participer aux démarches de notre département pour améliorer notre propre travail.",
      "weValueG3Q5Title": "Dans mon établissement, le client est toujours notre priorité absolue."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} TERMINÉ",
    "gatewayProgressComplete": "PROGRESSION GATEWAY TERMINÉE",
    "heroMobileGatewayVideoText": "Lire la vidéo Gateway",
    "basics": "FONDEMENTS",
    "brandResources": "RESSOURCES DE LA MARQUE",
    "disciplineResources": "RESSOURCES PAR DÉPARTEMENT",
    "immersionResourcesHeader": "Ressources d’immersion",
    "brandCommunicationsHeaader": "Communications de la marque",
    "brandContactsHeader": "Contacts de la marque",
    "franchisedActionItemsText": "Les éléments d’action vous aident à rester organisé et vous donnent des informations clés sur le processus d’accueil. Ces éléments ne sont pas obligatoires et viennent en complément de ce que vous pouvez recevoir de votre société de gestion de franchise.",
    "global": "MONDIAL",
    "continent": "CONTINENT",
    "viewed": "VU",
    "completed": "ACHEVÉ",
    "heading": "Gateway",
    "basicsTab": "LES FONDEMENTS",
    "onboardingTab": "Éléments d’action",
    "brandTab": "Ressources de la marque",
    "disciplinesTab": "RESSOURCES PAR DÉPARTEMENT",
    "basicsHeader": "Bonjour et bienvenue chez Marriott International",
    "basicsHeaderNewBrandOrRole": "Bienvenue,",
    "basicsText": "Au début de votre carrière, nous savons que vous avez beaucoup à apprendre. Nous avons sélectionné quelques fondements pour vos premiers jours de travail. Vous pourrez examiner certains de ces éléments plus en détail lors des orientations ou du processus d’accueil de la marque, mais c’est un bon point de départ.",
    "basicsTextGms": "Au début de votre carrière, nous savons que vous avez beaucoup à apprendre. Nous avons sélectionné quelques fondements pour vos premiers jours de travail. Vous pourrez examiner certains de ces éléments plus en détail lors des orientations ou du processus d’accueil de la marque, mais c’est un bon point de départ. Lorsque vous aurez terminé, passez aux autres pages de Gateway, notamment les Éléments d’action, les Ressources de la marque et les Ressources par département.",
    "basicsTextFranchise": "Nous savons que vous avez beaucoup à apprendre, nous avons donc sélectionné quelques fondements pour vos premiers jours de travail. Ces éléments peuvent venir compléter vos orientations, le processus d’accueil de la marque ou les informations fournies par votre société de gestion de franchise.",
    "basicsTextFranchiseGms": "Nous savons que vous avez beaucoup à apprendre, nous avons donc sélectionné quelques fondements pour vos premiers jours de travail. Ces éléments peuvent venir compléter vos orientations, le processus d’accueil de la marque ou les informations fournies par votre société de gestion de franchise. Lorsque vous aurez terminé, passez aux autres pages de Gateway, notamment les Éléments d’action, les Ressources de la marque et les Ressources par département.",
    "basicsTextNewToRole": "Lorsque vous débutez votre nouveau rôle, nous savons que vous avez beaucoup à apprendre. Nous avons sélectionné quelques fondements pour vos premiers jours de travail. Vous pourrez examiner certains de ces éléments plus en détail lors de votre formation, mais c’est un bon point de départ.",
    "basicsTextNewToRoleGms": "Lorsque vous débutez votre nouveau rôle, nous savons que vous avez beaucoup à apprendre. Nous avons sélectionné quelques fondements pour vos premiers jours de travail. Vous pourrez examiner certains de ces éléments plus en détail lors de votre formation, mais c’est un bon point de départ. Lorsque vous aurez terminé, passez aux autres pages de Gateway, notamment les Éléments d’action, les Ressources de la marque et les Ressources par département.",
    "basicsTextNewToRoleFranchise": "Nous savons que vous avez beaucoup à apprendre, nous avons donc sélectionné quelques fondements pour vos premiers jours de travail. Ces éléments peuvent venir compléter les informations fournies par votre société de gestion de franchise.",
    "basicsTextNewToRoleFranchiseGms": "Nous savons que vous avez beaucoup à apprendre, nous avons donc sélectionné quelques fondements pour vos premiers jours de travail. Ces éléments peuvent venir compléter les informations fournies par votre société de gestion de franchise. Lorsque vous aurez terminé, passez aux autres pages de Gateway, notamment les Éléments d’action, les Ressources de la marque et les Ressources par département.",
    "basicsTextNewToBrand": "Lorsque vous débutez votre nouveau rôle chez %{brandName}, nous savons qu’il y a beaucoup à apprendre. Nous avons sélectionné quelques fondements pour vos premiers jours de travail. Vous pourrez examiner certains de ces éléments plus en détail lors des orientations ou du processus d’accueil de la marque, mais c’est un bon point de départ.",
    "basicsTextNewToBrandGms": "Lorsque vous débutez votre nouveau rôle chez %{brandName}, nous savons qu’il y a beaucoup à apprendre. Nous avons sélectionné quelques fondements pour vos premiers jours de travail. Vous pourrez examiner certains de ces éléments plus en détail lors des orientations ou du processus d’accueil de la marque, mais c’est un bon point de départ. Lorsque vous aurez terminé, passez aux autres pages de Gateway, notamment les Éléments d’action, les Ressources de la marque et les Ressources par département.",
    "basicsTextNewToBrandFranchise": "Nous savons que vous avez beaucoup à apprendre, nous avons donc sélectionné quelques fondements pour vos premiers jours de travail. Ces éléments peuvent venir compléter vos orientations, le processus d’accueil de la marque ou les informations fournies par votre société de gestion de franchise.",
    "basicsTextNewToBrandFranchiseGms": "Nous savons que vous avez beaucoup à apprendre, nous avons donc sélectionné quelques fondements pour vos premiers jours de travail dans votre nouveau rôle chez %{brandName}. Ces éléments peuvent venir compléter vos orientations, le processus d’accueil de la marque ou les informations fournies par votre société de gestion de franchise. Lorsque vous aurez terminé, passez aux autres pages de Gateway, notamment les Éléments d’action, les Ressources de la marque et les Ressources par département.",
    "disciplineHeader": "Ressources par département",
    "toDo": "À FAIRE",
    "optional": "FACULTATIF",
    "overallComplete": "TERMINÉ DANS L’ENSEMBLE",
    "keyResourcesDesc": "Apprenez-en davantage sur les départements clés qui soutiennent les opérations quotidiennes de l’établissement, l’expérience des clients et les besoins commerciaux.",
    "franchiseFooter": "Cette documentation comporte des recommandations et des pratiques suggérées qui peuvent améliorer la probabilité de conformité aux normes de la marque. En tant que franchiseur, Marriott n’intervient pas dans la gestion ou l’exploitation d’un quelconque aspect de l’hôtel ou de l’activité commerciale d’un franchisé. Les franchisés conservent le contrôle de l’exploitation quotidienne de leurs hôtels et sont responsables de leurs propres employés et de leurs pratiques en matière d’emploi.",
    "landingHeroTitle1": "Bienvenue chez Marriott International.",
    "landingHeroTitle2": "Commencez votre parcours ici.",
    "landingHeroText": "The Gateway est un outil qui vous guidera tout au long de votre première année dans votre nouveau rôle. Il vous fournira les informations et les ressources dont vous avez besoin pour réussir.",
    "landingHeroTitle1NewToRole": "Bienvenue sur The Gateway.",
    "landingHeroTitle1NewToBrand": "Bienvenue sur The Gateway.",
    "landingButtonText": "Commencez",
    "twoMonthslandingHeroTitle1": "Bienvenue !",
    "twoMonthslandingHeroText": "Si vous ne l’avez pas encore fait, accédez à The Gateway pour connaître tous les fondements !",
    "twoMonthslandingButtonText": "Accéder à Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "Bienvenue !",
    "twoMonthslandingHeroTextNewToBrand": "Nous espérons que vos premiers jours chez %{brandName} se sont formidablement bien passés. N’oubliez pas de poursuivre votre parcours et d’accéder à The Gateway où vous trouverez tout ce dont vous avez besoin.",
    "twoMonthslandingButtonTextNewToBrand": "Continuez sur votre lancée",
    "threeMonthslandingHeroTitle1": "Vous êtes sur la bonne voie !",
    "threeMonthslandingHeroText": "Vous trouverez sur The Gateway tout ce que vous devez savoir à propos de Marriott International.",
    "threeMonthslandingButtonText": "Accéder à Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "Vous êtes sur la bonne voie !",
    "threeMonthslandingHeroTextNewToRole": "Vous trouverez sur The Gateway tout ce que vous devez savoir pour réussir dans votre nouveau rôle.",
    "threeMonthslandingButtonTextNewToRole": "Accéder à Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "Bienvenue !",
    "threeMonthslandingHeroTextNewToBrand": "Félicitations pour avoir passé les premiers %{numDays} jours avec votre nouvelle marque Vous pouvez accéder à tous vos Éléments d’action, à votre marque et à vos ressources par département lorsque vous le souhaitez.",
    "threeMonthslandingButtonTextNewToBrand": "Accédez à Gateway",
    "fourMonthslandingHeroTitle1": "Bravo ! Vous connaissez maintenant tous les fondements !",
    "fourMonthslandingHeroText": "Accédez à tout ce que vous devez savoir sur Marriott International grâce à The Gateway.",
    "fourMonthslandingButtonText": "Accéder à Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "Bravo ! Vous connaissez maintenant tous les fondements !",
    "fourMonthslandingHeroTextNewToRole": "The Gateway vous permet d’accéder à tout ce que vous devez savoir pour réussir dans votre nouveau rôle.",
    "fourMonthslandingButtonTextNewToRole": "Accéder Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway – Formalités d’arrivée",
    "fourMonthslandingHeroTextNewToBrand": "À l'approche de la fin de votre première année chez  %{brandName}, si vous ne l’avez pas encore fait, terminez tous vos Éléments d’action et utilisez les ressources de développement disponibles ici dans la Digital Learning Zone. Cliquez sur « Continuer » pour poursuivre votre parcours.",
    "fourMonthslandingButtonTextNewToBrand": "Continuer",
    "gmSecondVisitlandingHeroTitle1": "Bienvenue !",
    "gmSecondVisitlandingHeroText": "Nous espérons que vos premiers jours se sont formidablement bien passés. N’oubliez pas de poursuivre votre parcours pour vous aider à évoluer dans votre nouveau rôle.",
    "gmSecondVisitlandingButtonText": "Continuez sur votre lancée",
    "gmSecondVisitlandingHeroTextNewToRole": "Nous espérons que vos premiers jours dans votre nouveau rôle se sont formidablement bien passés. N’oubliez pas de poursuivre votre parcours et d’accéder à The Gateway où vous trouverez tout ce dont vous avez besoin.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Nous espérons que vos premiers jours chez %{brandName} se sont formidablement bien passés. N’oubliez pas de poursuivre votre parcours et d’accéder à The Gateway où vous trouverez tout ce dont vous avez besoin.",
    "gmThirdVisitlandingHeroTitle1": "Bienvenue !",
    "gmThirdVisitlandingHeroText": "Félicitations pour avoir franchi les premiers %{numDays} jours de votre parcours de nouvel embauché. Vous pouvez accéder à toutes vos ressources Éléments d’action, Marque et Département lorsque vous le souhaitez.",
    "gmThirdVisitlandingButtonText": "Accéder à Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "Félicitations pour avoir passé les premiers %{numDays} jours dans votre nouveau rôle. Vous pouvez accéder à toutes vos ressources Éléments d’action, Marque et Département lorsque vous le souhaitez.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Félicitations pour avoir passé les premiers %{numDays} jours chez votre nouvelle marque. Vous pouvez accéder à toutes vos ressources Éléments d’action, Marque et Département lorsque vous le souhaitez.",
    "gmSevenMonthslandingHeroTitle1": "Il semble que vous avez d'autres Éléments d’action à terminer.",
    "gmSevenMonthslandingHeroText": "À l'approche de la fin de votre première année, n’oubliez pas de terminer tous vos Éléments d’action et d’utiliser les ressources de développement disponibles ici dans la Digital Learning Zone. Cliquez sur « Continuer » pour poursuivre votre parcours.",
    "gmSevenMonthslandingButtonText": "Continuer",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway – Formalités d’arrivée",
    "gmSevenMonthslandingHeroTextNewToRole": "À l'approche de la fin de votre première année, si vous ne l’avez pas encore fait, terminez tous vos Éléments d’action et utilisez les ressources de développement disponibles ici dans la Digital Learning Zone. Cliquez sur « Continuer » pour poursuivre votre parcours.",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway – Formalités d’arrivée",
    "gmSevenMonthslandingHeroTextNewToBrand": "À l'approche de la fin de votre première année chez %{brandName}, si vous ne l’avez pas encore fait, terminez tous vos Éléments d’action et utilisez les ressources de développement disponibles ici dans la Digital Learning Zone. Cliquez sur « Continuer » pour poursuivre votre parcours.",
    "gmOneYearlandingHeroTitle1": "Félicitations pour votre première année chez %{brandName} !",
    "gmOneYearlandingHeroTitle1NewToRole": "Félicitations pour votre première année dans votre nouveau rôle !",
    "gmOneYearlandingHeroTitle1NewToBrand": "Félicitations pour votre première année dans votre nouveau rôle chez %{brandName} !",
    "gmOneYearlandingHeroText": "Le parcours The Gateway est peut-être terminé, mais le meilleur reste à venir. Vous pouvez accéder aux ressources à partir de l’onglet The Gateway à tout moment.",
    "gmOneYearlandingButtonText": "Afficher Gateway"
  },
  "resources": {
    "iptTooltip": "Utilisez des virgules pour séparer plusieurs établissements",
    "iptAllFunctionTooltip": "Décochez cette case pour sélectionner une ou plusieurs fonctions particulières. Laissez cette case cochée pour afficher la formation de toutes les fonctions de l’établissement sélectionné.",
    "iptAllAWRTooltip": "Décochez cette case pour sélectionner des responsabilités professionnelles supplémentaires particulières. Laissez cette case cochée pour afficher la formation de toutes les responsabilités professionnelles supplémentaires pour l’établissement sélectionné.",
    "editJFTooltip": "Utilisez des virgules pour séparer plusieurs éléments."
  },
  "Notification Center: ": "Marquer comme non lu",
  "Notification Center: Empty": "Vous êtes à jour !",
  "Notification Center: Individual Delete": "Voulez-vous vraiment supprimer cette notification ? Les notifications supprimées sont déplacées vers la section « Supprimé » du centre de notification jusqu’à ce qu’elles expirent.",
  "Notification Center: Delete All": "Voulez-vous vraiment supprimer toutes les notifications ? Elles seront déplacées vers la section « Supprimé » du centre de notification jusqu’à ce qu’elles expirent.",
  "Notification Center: Mark All As Read": "Marquer tout comme lu",
  "Notification Center: Restore All": "Restaurer tout",
  "Notification Center: Delete Confirmation": "Supprimer",
  "Warning Message Text: Remove Job Function or AWR": "Supprimer une fonction ou une responsabilité professionnelle supplémentaire (AWR) ?\r\nEn validant, vous supprimerez %{Function and/or AWR} de %{count} apprenants.",
  "Warning Message Text: Add Job Function or AWR": "Ajouter une fonction ou une responsabilité professionnelle supplémentaire (AWR) ?\r\nEn validant, vous ajouterez %{Function and/or AWR} à %{count} apprenants.",
  "Button": "Envoyer",
  "Success Message Text: Add Job Function and/or AWR": "Réussite\r\nVous avez ajouté %{Job Function and/or AWR} au(x) collaborateur(s) requis de %{location}. \r\n\r\nVoulez-vous modifier des fonctions ou des responsabilités professionnelles supplémentaires pour %{location} ?",
  "Message Text:AWR": "Réussite\r\nVous avez ajouté les responsabilités professionnelles supplémentaires %{ __} au(x) collaborateur(s) requis de %{ __}.\r\n\r\nVoulez-vous modifier des fonctions ou des responsabilités professionnelles supplémentaires pour %{location, property, learner(s)} ?",
  "Success Message Text: Remove Job Function": "Réussite\r\nVous avez supprimé %{Job Function and/or AWR} du (des) collaborateur(s) requis de %{location}. \r\n\r\nVoulez-vous modifier des fonctions ou des responsabilités professionnelles supplémentaires pour %{location} ?",
  "Success Message Text: Remove AWR": "Réussite\r\nVous avez supprimé les responsabilités professionnelles supplémentaires %{ __} du (des) collaborateur(s) requis de %{ __} .\r\n\r\nVoulez-vous modifier des fonctions ou des responsabilités professionnelles supplémentaires pour %{location, property, learner(s)} ?",
  "Tool Tip - Managed Change Email": "Pour modifier votre adresse e-mail, accédez à mHUB > HR Direct > Moi > Mes informations publiques. Suivez les instructions pour mettre à jour vos coordonnées. L’affichage de l’adresse e-mail mise à jour peut prendre un à deux jours ouvrables.",
  "Tool Tip - Franchised  Change Email": "Pour modifier votre adresse e-mail, contactez votre responsable et/ou l’administrateur EID Tool de votre franchise. L’affichage de l’adresse e-mail mise à jour peut prendre un à deux jours ouvrables.",
  "User Profile: Email Opt-in": "Non, merci",
  "User Profile: Email Frequency": "Synthèse hebdomadaire",
  "Generic Notification Email Subject Line:": "Vous avez de nouvelles notifications dans DLZ",
  "Generic Body Text (<150 Characters)": "Veuillez accéder à DLZ pour consulter les détails.",
  "All Emails: Signature": "Digital Learning Zone – La formation en toute simplicité. Cela commence par vous.",
  "All Emails: Footer": "Vous pouvez vous désabonner / refuser les notifications par e-mail à tout moment. Accédez à Digital Learning Zone / Profil pour mettre à jour vos préférences.",
  "Overdue Emails: Subject Line: ": "Des tâches requièrent votre attention dans DLZ",
  "Overdue Emails: Body Test": "Vous avez des tâches en retard. Veuillez vous connecter à DLZ pour prendre connaissance de vos tâches.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Affinez vos compétences",
    "sharpenYourSkillsSubTitle": "Renforcez vos connaissances et compétences afin de faire évoluer votre carrière, maintenant et dans l’avenir.",
    "growYourCareerTitle": "Développez votre carrière",
    "growYourCareerSubTitle": "Planifiez votre prochain progrès au moyen de compétences spécialisées ou de formations polyvalentes qui soutiennent vos intérêts et objectifs personnels.",
    "additionalResourcesTitle": "Ressources supplémentaires",
    "additionalResourcesSubTitle": "Explorez getAbstract® et CultureWizard™. Découvrez comment le DLZ peut vous aider à progresser.",
    "tourHeader": "Bienvenue au DLZ mis à jour. Maintenant, vous pouvez rechercher et trouver tout ce dont vous avez besoin, au moment où vous en avez besoin. Bien plus, vous trouverez une page « Découvrir les formations » mise à jour et une page « Mon plan » plus personnalisée. Suivez la visite guidée !",
    "tourBtnSkip": "Ignorer",
    "tourBtnNext": "Suivant (6/6)",
    "tourSearchBar": "Trouvez ce dont vous avez besoin, lorsque vous en avez besoin, avec une fonction de Recherche plus robuste.",
    "tourBtnBack": "Précédent",
    "tourMyPlan": "La page Mon plan comprend maintenant les éléments que vous avez ajoutés depuis Recherche et Exploration, afin que vous maîtrisiez vraiment votre parcours d’apprentissage !",
    "tourExploreLearningLink": "Vous pouvez toujours accéder à Découvrir les formations ici aussi !",
    "tourExploreLearningProfile": "Tirez le maximum de votre expérience d’apprentissage et vérifiez votre profil, notamment les fonctions de votre poste, de façon régulière pour maintenir Mon plan à jour.",
    "mhubTraining": "Formation mHUB",
    "growthSkills": "Compétences de développement",
    "leadershipSkills": "Compétences de leadership",
    "virtualTeamResources": "Ressources virtuelles de l’équipe",
    "jobFunctions": "Fonctions professionnelles",
    "brands": "Marques",
    "languageLearning": "Apprentissage d’une langue",
    "become": "Devenir",
    "emeaMentoringProgram": "Programme de mentorat, EMEA",
    "learningPathways": "Parcours de formation",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "Centre communautaire TakeCare",
    "mhubTrainingDesc": "Trouvez des itinéraires d’apprentissage basés sur les rôles et des cours sur des thèmes spécifiques sur la façon d’utiliser mHUB.",
    "growthSkillsDesc": "Acquérez des connaissances pratiques sur des fonctions et thèmes divers.",
    "leadershipSkillsDesc": "Découvrez comment gérer et encadrer une équipe, et devenir un leader plus efficace.",
    "virtualTeamResourcesDesc": "Apprenez des techniques permettant de susciter une culture collaborative et inclusive au sein des équipes virtuelles.",
    "jobFunctionsDesc": "Développez vos connaissances sur les fonctions de votre poste actuel et découvrez d’autres fonctions.",
    "brandsDesc": "Apprenez-en davantage sur les diverses marques de Marriott International.",
    "languageLearningDesc": "Communiquez efficacement avec une audience globale.",
    "becomeDesc": "Découvrez une approche complète de la croissance et du développement de la carrière : réfléchir, planifier, agir, affiner.",
    "emeaMentoringProgramDesc": "Libérez votre potentiel avec le Programme de mentorat EMEA.",
    "learningPathwaysDesc": "Explorez des opportunités d’apprentissage spécifiques adaptées à votre niveau de leadership.",
    "mdaDesc": "Développez des compétences de leadership et des compétences fonctionnelles avec Marriott Development Academy.",
    "takeCareGrowDesc": "Identifiez les ressources conçues pour développer une communauté inclusive et motivée.",
    "getAbstractDesc": "Apprenez quelque chose de nouveau chaque jour avec des résumés de livres afin de prendre de meilleures décisions.",
    "cultureWizardDesc": "Rassemblez de précieuses connaissances pour communiquer effectivement avec diverses cultures.",
    "takeCareCommunityHubDesc": "Découvrez les activités de renforcement d’équipe, les programmes d’apprentissage et les outils de TakeCare.",
    "searchAllLearning": "Rechercher toutes les formations",
    "searchBarPlaceHolder": "Il y a toujours quelque chose à apprendre.",
    "clearFilters": "Supprimer les filtres",
    "noResults": "Aucun résultat",
    "hasBeenUnpinned": "{Variable-tile_Name} a été désépinglé.",
    "hasBeenPinned": "{Variable-tile_Name} a été épinglé.",
    "lpRemovedFromPlan": "Le parcours de formation a été retiré de votre programme.",
    "courseRemovedFromPlan": "Le cours a été retiré de votre programme.",
    "lpAddedToPlan": "Le parcours de formation a été ajouté à votre programme.",
    "courseAddedToPlan": "Le cours a été ajouté à votre programme.",
    "clearSelection": "Supprimer la sélection",
    "removeFromMyPlan": "Retirer de Mon programme",
    "addToMyPlan": "Ajouter à Mon programme",
    "loadingResults": "Chargement",
    "viewDetails": "Afficher les détails",
    "clearAll": "Effacer tout",
    "noOptions": "Aucune option disponible",
    "viewLess": "Réduire",
    "viewMore": "Afficher plus",
    "recentSearch": "Recherches récentes",
    "exploreLearningTitle": "Découvrir les formations",
    "exploreLearningHelpText": "Vous n'avez pas d'idée précise ?",
    "exploreMore": "Découvrez plus.",
    "viewAllResults": "Afficher tous les <#> résultats",
    "searchSubTitle": "Rechercher des cours en utilisant des mots-clés, des titres ou l'ID de contenu.",
    "pinnedLearningTitle": "Mes formations épinglées",
    "landingSubTitle": "Valider votre parcours de formation. Explorez vos centres d'intérêt – de nouveaux thèmes et cours sont ajoutés à tout moment.",
    "columnTitle": "Titre",
    "columnDesc": "Description",
    "columnType": "Type",
    "columnAddedToPlan": "Ajouter / Ajouté au programme",
    "columnComplete": "Terminé",
    "columnDetails": "Détails",
    "additionalSearchFilters": "Filtres de recherche supplémentaires",
    "tourBtnClose": "Fermer",
    "tourBtnLast": "Fermer",
    "resultsFor": "Résultats pour ",
    "resultsFortheFilter": "Résultats pour le filtre {Filter Name}",
    "no": "Non",
    "results": "Résultats",
    "courseTooltipTitle": "Un élément de formation individuelle pouvant comporter une ou plusieurs activités.<br />Un cours peut être suivi sur l’Internet, comme une vidéo ou une formation en ligne, ou dans une salle de cours.",
    "gatewayToolTipTitle": "Ressources numériques pour les nouveaux employés, les nouveaux cadres supérieurs et les directeurs généraux ayant récemment pris leurs fonctions dans la marque.",
    "speciallizationOrPathwayTooltipTitle": "Ensemble de cours orientés sur un sujet particulier.",
    "taskTooltipTitle": "Instructions pour l’accomplissement d’une activité (généralement sur le terrain) et pour signaler ensuite que vous l’avez achevée.",
    "webSiteTooltipTitle": "Tous les sites Web, notamment les sites Marriott ou les sites externes."
  },
  "dontShowAgain": "Ne plus afficher ce message",
  "deletionWarningEnabled": "Alerte de suppression activée",
  "deletionSelected": "Lors de la sélection, une alerte sera affichée à chaque fois que vous supprimez une notification.",
  "manageEmailNotifications": "Gérer les notifications par courriel",
  "seeHowDLZContinue": "Découvrez comment le DLZ peut vous aider à progresser.",
  "Notification Center:Overdue": "Vous avez une nouvelle formation qui était due le {Variable date ddmmyy}",
  "accesDenied": {
    "title": "Accès à votre parcours de formation en cours ! ",
    "subtitle": "Votre accès est en cours de création et devrait être disponible 1-2 jours après l’activation de votre EID. Si le délai de 2 jours est dépassé, veuillez contacter le Centre de Services mHUB."
  },
  "jfTour": {
    "title": "Vous êtes maintenant en mesure de consulter et de modifier vos fonctions professionnelles sélectionnées et vos responsabilités professionnelles supplémentaires (AWR) directement à partir de Mon plan."
  },
  "onboardingPlan": {
    "requiredDescription": "Vous trouverez ci-dessous les cours à suivre pendant la période que vous avez sélectionnée dans la rubrique Mon programme Avec votre responsable, réservez-vous du temps pour suivre votre formation obligatoire pendant que vous découvrez votre nouveau poste. La formation portera sur un éventail de sujets. N’hésitez pas à contacter votre responsable et vos collègues si vous avez des questions.   ",
    "day1": "À faire avant le 14e jour",
    "day2": "À faire avant le 30e jour",
    "day3": "À faire avant le 60e jour",
    "day4": "À faire avant le 90e jour",
    "day5": "À faire dans 90 jours"
  },
  "onboardingLanding": {
    "gettingStartedDescription": "La Digital Learning Zone est votre point de départ pour l’apprentissage et le développement professionnels. Le contenu fourni ci-dessous devrait faciliter vos premiers pas. Vous pourrez toujours retrouver l’ensemble de vos formations dans la rubrique Mon programme.  ",
    "overallLearningProgress": "Progression globale dans la formation",
    "learningProgressLabel": "Progression dans la formation",
    "learningProgressDescription": "Voici votre progression dans votre formation obligatoire. Cette barre de progression tient compte du statut de toutes vos formations obligatoires. Continuez votre excellent travail !"
  },
  "Recommended Courses ": "Après vos 90 premiers jours en poste, des formations supplémentaires recommandées par votre responsable y seront ajoutées. Vous pouvez aussi explorer par vous-même les sujets qui vous intéressent. Pour l’instant, concentrez-vous sur votre formation de base. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Participer à l'enquête",
      "lbiIntro": "Bienvenue au test sur les comportements de luxe !",
      "lbiDescription": `<p>Nous vous demandons de lire les brefs scénarios ci-dessous que vous pourriez rencontrer dans votre établissement. Veuillez lire chaque scénario et choisir comment vous réagiriez face à chaque situation. Gardez les points suivants à l’esprit lorsque vous répondez :</p>
      <ul>
          <li>
              <p>Plusieurs réponses pourront vous sembler correctes. Choisissez la réponse que vous seriez le plus susceptible d’adopter au travail.</p>
          </li>
          <li>
              <p>Supposons qu’il n’y ait aucun obstacle à la communication. Vous parlez la même langue que le client ou le collaborateur dans le scénario.</p>
          </li>
          <li>
              <p>Dans le cadre du scénario, vous pouvez être amené à imaginer que vous occupez un autre poste que celui que vous occupez actuellement. Répondez à la question au mieux de vos connaissances, en vous basant sur votre expérience en tant que professionnel de l’hôtellerie de luxe.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Remarque : Votre participation à ce test est entièrement volontaire (vous pouvez choisir de ne pas y participer) et vos réponses seront strictement confidentielles. Les rapports dressés à partir des résultats de ce test représenteront des groupes de personnes et non des personnes spécifiques. Les réponses individuelles seront évaluées par l’équipe de recherche et ne seront pas partagées avec les responsables ou les dirigeants.</p>
      <p>En effectuant ce test, vous acceptez la collecte et le traitement tant de vos réponses que de vos données personnelles (par ex., ancienneté, sexe, intitulé de poste), ainsi que le transfert international de vos données personnelles au groupe Marriott. Cette condition est conforme à la Déclaration de confidentialité des données des collaborateurs de Marriott, qui contient également des informations supplémentaires sur les Politiques de confidentialité des données de Marriott et contacts de référence.</p>
      `,
      "checkin": {
        q1Title: "En traversant le hall, vous remarquez une famille avec trois enfants en bas âge. La famille a de nombreux bagages, semble stressée après une longue journée de voyage, et les enfants pleurent et sont visiblement agités. Que faites-vous dans ce cas ?",
        q2Title: "Vous venez de commencer dans un établissement qui se trouve dans une région que vous ne connaissez pas. Un client vous demande ce qu’il y a à faire dans la région. Le client a lu plusieurs guides, mais recherche quelque chose de plus personnalisé. Que faites-vous dans ce cas ? ",
        q3Title: "En traversant le hall, vous entendez un client parler au téléphone et comprenez que son service de voiture à l’aéroport est en retard. Votre établissement ne propose pas de service de voiture ou ce service n’est pas disponible. Que faites-vous dans ce cas ? ",
        q4Title: "Vous traversez le hall en chemin pour effectuer une tâche urgente que votre responsable vous a confiée. Vous remarquez qu’un collègue a du mal à accomplir une tâche qui nécessite généralement deux personnes. Que faites-vous dans ce cas ?",
        q5Title: "Vous êtes hôte au restaurant de l'hôtel. Un client demande à voir le menu pendant qu’il attend la table qu’il a réservée. Après avoir consulté le menu, le client affiche sa frustration, car le restaurant de l’hôtel lui avait été suggéré par un autre collègue, mais rien dans le menu ne répond à ses besoins alimentaires. Que faites-vous dans ce cas ?",
        q6Title: "La piscine étant bondée, un grand groupe ne trouve pas de transats où s’asseoir tous ensemble. Par conséquent, ils ont disposé leurs serviettes et leurs effets personnels de telle sorte qu’ils bloquent une allée principale, créant un danger et perturbant aussi bien les clients que les collaborateurs qui tentent d’accéder à la piscine. La zone doit être dégagée, mais vous ne voulez pas contrarier le groupe. Que faites-vous dans ce cas ? ",
        q1R1Title: "Vous aidez la famille avec ses bagages, lui indiquez où se trouve le comptoir d’enregistrement et suggérez plusieurs lieux de détente à proximité proposant des activités familiales. Vous mentionnez les installations familiales de l’établissement.",
        q1R2Title: "Vous offrez à la famille de l’aider avec ses bagages et la conduisez au comptoir d’enregistrement. Ensuite, vous organisez un service de détente ou une autre attention pour les parents et des activités amusantes pour les enfants dans leur chambre. ",
        q1R3Title: "Vous guidez les parents vers le comptoir d’enregistrement, puis emmenez personnellement les enfants dans un espace salon avec des collations pendant que les parents s’enregistrent. Vous demandez à la réception d’informer les parents des aires de jeux et des activités pour enfants dans l’établissement pour leur confort.",
        q1R4Title: "Vous orientez la famille vers le comptoir d’enregistrement pour un enregistrement rapide. Vous mentionnez les espaces adaptés aux enfants et les services offerts aux parents afin que les enfants puissent profiter d’un agréable séjour.",
        q2R1Title: "Vous demandez au client ce qu’il aime, puis faites équipe avec un collègue pour dresser une liste d’activités amusantes. De plus, vous suggérez un bon restaurant à proximité.",
        q2R2Title: "Vous discutez avec le client de ce qu’il aime. Vous collaborez avec un collègue pour dresser une liste spéciale d’activités amusantes rien que pour lui. En outre, vous jetez un œil au profil Bonvoy du client et envoyez un cadeau personnalisé dans sa chambre.",
        q2R3Title: "Vous misez sur l’approche personnelle en partageant certaines de vos activités préférées dans la région jusqu’à présent. Vous appuyez vos suggestions en donnant des détails sur vos expériences. Ensuite, vous envoyez une délicieuse gourmandise locale dans sa chambre.",
        q2R4Title: "Vous faites savoir au client que vous êtes nouveau dans la région et que vous n’avez pas beaucoup d’idées. Vous présentez vos excuses et lui proposez de s’entretenir avec votre collègue qui en sait plus. Ensuite, vous lui demandez s’il souhaite recevoir une gourmandise locale dans sa chambre.",
        q3R1Title: "Tant que le client est toujours au téléphone, vous garez votre propre véhicule ou celui de votre collègue devant l’entrée de l’établissement, puis lui faites savoir que vous l’emmènerez personnellement à l’aéroport.",
        q3R2Title: "Tant que le client est toujours au téléphone, dressez une liste des services de voiture approuvés par l’établissement avec leurs numéros de téléphone. Une fois que le client a raccroché, vous lui présentez la liste et lui proposez d’organiser le transport.",
        q3R3Title: "Vous attendez que le client raccroche et lui dites que votre établissement ne propose pas de services de transport, mais que vous seriez heureux de l’aider à organiser d’autres options de transport. ",
        q3R4Title: "Tant que le client est toujours au téléphone, vous trouvez un autre moyen de transport pour le client. Une fois qu’il a raccroché, vous informez le client que vous avez pris des dispositions de transport jusqu’à l’aéroport et que la voiture arrivera bientôt.",
        q4R1Title: "Vous vous approchez de votre collègue et lui dites que vous l’aiderez dès que vous aurez terminé votre tâche urgente. Vous promettez de revenir rapidement pour l’aider. Vous veillez à informer votre responsable de la situation.",
        q4R2Title: "Tout d’abord, vous trouvez rapidement un autre collègue à qui déléguer la tâche qui vous a été assignée. Ensuite, vous rejoignez immédiatement votre collègue qui a besoin d’aide. Une fois libre, vous informez votre responsable que vous avez trouvé quelqu’un d’autre pour effectuer votre tâche afin de pouvoir aider votre collègue. ",
        q4R3Title: "Vous vous arrêtez et aidez votre collègue immédiatement. Après l’avoir aidé, vous vous consacrez à la tâche qui vous a été assignée, puis informez votre responsable que vous avez mis votre tâche en attente pour aider votre collègue.",
        q4R4Title: "Vous vous approchez de votre collègue qui a besoin d’aide et expliquez que vous êtes au milieu d’une tâche urgente. Vous arrêtez les deux tâches, réfléchissez ensemble à un moyen d’accomplir vos deux tâches, puis effectuez les deux tâches ensemble.",
        q5R1Title: "Vous présentez vos excuses au client et lui faites savoir que vous allez contacter le responsable. Vous veillez à expliquer la situation afin que le responsable puisse répondre aux besoins alimentaires du client.",
        q5R2Title: "Vous présentez vos excuses au client avant de lui demander ses préférences alimentaires. Vous vous entretenez avec l’équipe en cuisine pour préparer un plat spécial rien que pour lui. De plus, vous lui offrez un rafraîchissement ou une collation gratuitement pour rattraper l’erreur.",
        q5R3Title: "Vous présentez vos excuses au client et lui dites que vous comprenez. Au lieu d’annuler la réservation, vous proposez de parler au chef pour voir s’il peut préparer quelque chose de spécial au client. Vous lui offrez un rafraîchissement ou une collation gratuitement, ou une réduction pour compenser le désagrément.",
        q5R4Title: "Vous présentez vos excuses au client et lui dites que vous pouvez réserver une table dans un restaurant à proximité qui propose la nourriture qu’il aime. Vous proposez des informations supplémentaires ou l’aidez à rejoindre cet autre restaurant si nécessaire.",
        q6R1Title: "Vous présentez vos excuses aux clients pour le manque d’espace et leur dites qu’ils peuvent rester là où ils sont tant qu’ils font attention aux autres personnes qui passent à côté d’eux. ",
        q6R2Title: "Vous informez aimablement les clients qu’ils ne peuvent pas bloquer l’allée et qu’ils doivent trouver un autre endroit ou attendre que de la place se libère. Pendant ce temps, vous demandez à vos collègues d’apporter des chaises supplémentaires depuis la salle de réception pour qu’ils puissent s’asseoir. ",
        q6R3Title: "Vous informez les clients qu’ils ne peuvent pas rester là où ils sont et qu’ils doivent se déplacer temporairement pendant que vous cherchez des sièges supplémentaires. En attendant, vous leur proposez de s’installer au bar/restaurant. ",
        q6R4Title: "Vous présentez vos excuses aux clients pour le manque de places assises. Vous leur demandez s’ils peuvent rassembler leurs affaires de manière à laisser les gens passer. Vous leur proposez de les aider, en veillant à ce que tout le monde soit à l’aise. Vous leur envoyez une petite attention du bar/restaurant pour leur désagrément."
      }
    }
  },
};