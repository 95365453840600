export default {
  "banner": {
    "viewDetails": "ดูรายละเอียด",
    "requiredCourses": "หลักสูตรที่คุณต้องเรียนจะครบกำหนดใน",
    "days": "วัน"
  },
  "header": {
    "plan": "แผนของข้าพเจ้า",
    "manager": "ผู้จัดการ",
    "explore": "สำรวจการเรียนรู้",
    "reporting": "การรายงาน",
    "myTeam": "ทีมของข้าพเจ้า",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "แผนของข้าพเจ้า",
    "manager": "ผู้จัดการ",
    "explore": "สำรวจการเรียนรู้",
    "tagline": "เรียนได้ ง่ายนิดเดียว สิ่งนี้เริ่มที่คุณ"
  },
  "welcome": {
    "hi": "สวัสดี",
    "intro1": "ขอต้อนรับสู่ Digital Learning Zone! DLZ คือแพลตฟอร์มการเรียนรู้ที่ปรับเปลี่ยนให้เหมาะกับแต่ละคนโดยเป็นศูนย์กลางการเข้าถึงการเรียนรู้ของแมริออท ค้นหาการฝึกอบรมที่จำเป็นสำหรับบทบาทของคุณและสำรวจโอกาสในการเรียนรู้เพื่อพัฒนาตนเองและพัฒนาอาชีพ รับเส้นทางการเรียนรู้ของตนแล้วเริ่มต้นหนทางสู่การพัฒนาทักษะ",
    "intro2": "ก่อนที่คุณจะเริ่ม เราจำเป็นต้องรู้เกี่ยวกับตัวคุณสักเล็กน้อย",
    "letsGo": "ไปกันเลย",
    "preferredLanguage": "ภาษาที่คุณต้องการใช้คืออะไร",
    "step": "ขั้นตอน",
    "of": "จาก",
    "previous": "ก่อนหน้า",
    "next": "ถัดไป",
    "startDate": "คุณเริ่มทำงานที่แมริออท อินเตอร์เนชั่นแนลหรือสตาร์วูดเมื่อใด",
    "selectYear": "เลือกปี",
    "selectMonth": "เลือกเดือน",
    "selectDay": "เลือกวันที่",
    "selectFunction": "เลือกหน้าที่",
    "selectInterests": "เลือกความสนใจ",
    "chooseYear": "เลือกหนึ่งปี",
    "chooseMonth": "เลือกเดือน",
    "chooseDay": "เลือกหนึ่งวัน",
    "whatDo": "หน้าที่ในงานของคุณคืออะไร",
    "selected": "ที่เลือก",
    "searchFunctions": "ค้นหาหน้าที่ทั้งหมด",
    "searchInterests": "ค้นหาความสนใจทั้งหมด",
    "additionalJobFunctionsLabel": "บอกเราเพิ่มเติมว่าคุณทำอะไร",
    "managementLevelLabel": "คุณเป็นพนักงานระดับใด",
    "preferredBrandLabel": "หากคุณทำงานให้มากกว่าหนึ่งแบรนด์ โปรดเลือกทุกข้อที่ตรงกับคุณ",
    "additionalJobFunctionsLabelExtra": "กรุณาทบทวนข้อมูลต่อไปนี้และเลือกอย่างน้อยหนึ่งความรับผิดชอบในงานเพิ่มเติม",
    "searchAdditionalFunctions": "ค้นหาความรับผิดชอบในงานเพิ่มเติมทั้งหมด",
    "highGuestContactLabel": "คุณติดต่อกับแขกบ่อยหรือไม่",
    "yes": "ใช่",
    "no": "ไม่ใช่ "
  },
  "landing": {
    "started": "มาเริ่มกันเลย",
    "welcomeBack": "ยินดีต้อนรับอีกครั้ง",
    "greatWork": "เยี่ยม",
    "requiredCourses": "หลักสูตรที่คุณต้องเรียน",
    "requiredCoursesDesc": "โปรดเสร็จสิ้นหลักสูตรก่อนถึงวันครบกำหนด",
    "myPlan": "แผนของข้าพเจ้า",
    "courses": "หลักสูตร",
    "courseComplete": "เสร็จสิ้นหลักสูตร",
    "viewMore": "ดูหลักสูตรที่ต้องเรียนเพิ่มเติม",
    "viewMoreBtn": "ดูหลักสูตรเพิ่มเติม",
    "showing": "การแสดง",
    "of": "จาก",
    "heroTitle": "เรียนรู้สิ่งใหม่ๆ ปลุกพลังให้ตัวเอง สร้างทักษะที่ใช้กับโลกของคุณได้",
    "heroParagraph1": "ติดตามหลักสูตรที่คุณต้องเรียนได้ง่ายดายและรับการแจ้งเตือนแบบเรียลไทม์เพื่อให้เสร็จสิ้นหลักสูตร",
    "heroParagraph2": "เพลิดเพลินไปกับประสบการณ์การเรียนรู้ที่ปรับแต่งตามงานและความสนใจของคุณ",
    "recommendedCoursesTitle": "หลักสูตรที่แนะนำ",
    "recommendedCoursesDescNoContent": "คุณไม่มีหลักสูตรที่แนะนำในขณะนี้ โปรดดูด้านล่างเพื่อสำรวจโอกาสการเรียนรู้อื่นๆ",
    "recommendedCoursesDesc": "ดูว่าผู้จัดการหรือผู้เชี่ยวชาญด้าน HR เลือกอะไรไว้ให้คุณ",
    "recommendedCoursesBtn": "หลักสูตรที่แนะนำ",
    "completedCoursesBtn": "ดูหลักสูตรที่เสร็จสิ้นแล้ว",
    "exploreTitle": "อยากรู้อยากเห็นอยู่เสมอ",
    "exploreDesc": "เลือกหัวข้อที่คุณสนใจ",
    "jobFunctions": "การลงโทษตักเตือน",
    "growthSkills": "ทักษะการเติบโต",
    "leadershipSkills": "ทักษะภาวะผู้นำ",
    "brands": "แบรนด์",
    "languageLearning": "การเรียนภาษา",
    "exploreBtn": "ดูหลักสูตร",
    "managerRecommendation": "คำแนะนำจากผู้จัดการ",
    "learningRecommendation": "คำแนะนำการเรียนรู้",
    "marriottRecommendation": "คำแนะนำแมริออท",
    "completedCourses": "คุณเสร็จสิ้นหลักสูตรที่ต้องเรียนทั้งหมดแล้ว!",
    "investTitle": "รับเส้นทางการเรียนรู้ของคุณ",
    "investParagraph1": "เราตระหนักถึงสถานการณ์ปัจจุบันมีความท้าทายในหลาย ๆ -- DLZ อยู่ที่นี่เป็นทรัพยากรสําหรับคุณ ไม่ว่าคุณจะต้องการเก็บทักษะของคุณในปัจจุบัน เรียนรู้สิ่งใหม่ๆ จัดการความเครียดของคุณ หรือเรียนรู้ที่จะทํางานกับทีมเสมือน เราได้รวบรวมทรัพยากรเพื่อช่วยสนับสนุนคุณ",
    "updatedTopics": "หัวข้อที่อัปเดตแล้ว",
    "workingRemotely": "ทรัพยากรทีมเสมือน",
    "relatedCoursesNew": "คลิกด้านล่างเพื่ออัปเดตความสนใจของคุณ เนื่องจากเราได้เพิ่มหัวข้อและหลักสูตรใหม่",
    "relatedCourses": "กรุณาคลิกด้านล่างเพื่อเลือกความสนใจของคุณ",
    "notificationText": "การฝึกอบรม mHUB ใหม่พร้อมให้บริการแล้ว เลือก mHUB ใน Explore Learning",
    "mHubHeroTitle1": "ปลอดภัย, ชาญฉลาด, และง่ายดาย ขอแนะนำ mHUB",
    "mHubHeroManagedText1": "mHUB รวมเครื่องมือสำหรับการเรียนรู้, การพัฒนาอาชีพ, ส่วนลด Explore และอีกมากมายไว้ในแพลตฟอร์มเดียวที่ใช้งานง่าย",
    "mHubHeroFranchisedText1": "mHUB รวมเครื่องมือสำหรับการเรียนรู้, ส่วนลด Explore และอีกมากมายไว้ในแพลตฟอร์มเดียวที่ใช้งานง่าย",
    "mHubHeroVisitLink": "คลิกที่นี่เพื่อดูไฟล์แนบหรือรายละเอียดข้อเสนอเพิ่มเติม",
    "myGateway": "My Gateway",
    "gatewayReview": "โปรดตรวจทานและดำเนินการให้เสร็จสมบูรณ์",
    "gatewayProgress": "Gateway ความคืบหน้า",
    "complete": "เสร็จสมบูรณ์",
    "reviewContent": "ดูทรัพยากร",
    "gatewayCardTitle": "Gateway ทรัพยากร",
    "gatewayCardDesc": "เข้าสู่เนื้อหาในส่วนพื้นฐานสำหรับปีแรกและปีต่อๆ ไป",
    "gatewayCardDescGms": "เข้าสู่เนื้อหาในส่วนพื้นฐาน, รายการปฏิบัติการ, ทรัพยากรเกี่ยวกับแบรนด์ และทรัพยากรตามแผนกสำหรับปีแรกและปีต่อๆ ไป",
    "gatewayCardDescNew": "เข้าสู่เนื้อหาในส่วนพื้นฐานสำหรับปีแรกในบทบาทใหม่ของคุณ",
    "gatewayCardDescGmsNew": "เข้าสู่เนื้อหาในส่วนพื้นฐาน, รายการปฏิบัติการ, ทรัพยากรเกี่ยวกับแบรนด์ และทรัพยากรตามแผนกสำหรับปีแรกและปีต่อๆ ไปในบทบาทใหม่ของคุณ"
  },
  "courseBundle": {
    "completeBy": "เสร็จเมื่อ",
    "remainingCourses": "หลักสูตรที่เหลือ",
    "completedCourses": "หลักสูตรที่เสร็จสิ้นแล้ว",
    "remainingLabel": "มีหลักสูตร {courseCount} รายการในเส้นทางการเรียนรู้นี้",
    "recommendButton": "แนะนำให้ทีม",
    "startCourse": "เริ่มหลักสูตร",
    "durationMins": "นาที",
    "completed": "เสร็จสิ้นแล้ว",
    "back": "ย้อนกลับ",
    "additionalLangs1": "นอกจากจะนำเสนอเป็นภาษาอังกฤษแล้ว หลักสูตรนี้ยังมีให้บริการเป็นภาษา",
    "additionalLangs2": "โปรดเลือกภาษาที่คุณต้องการใช้จากเมนูดรอปดาวน์หลังจากเปิดหลักสูตร",
    "targetAudience": "กลุ่มเป้าหมาย",
    "all": "ทั้งหมด",
    "globally": "ทั่วโลก",
    "source": "แหล่งข้อมูล",
    "courseType": "ประเภทหลักสูตร",
    "course": "หลักสูตร"
  },
  "profile": {
    "save": "บันทึก",
    "edit": "แก้ไข",
    "brand": "แบรนด์",
    "brands": "แบรนด์",
    "additionalWork": "ความรับผิดชอบในงานเพิ่มเติมทั้งหมด",
    "startDateLabel": "คุณเริ่มทำงานที่แมริออท อินเตอร์เนชั่นแนลหรือสตาร์วูดเมื่อใด",
    "preferredLanguageLabel": "ภาษาที่คุณต้องการใช้คืออะไร",
    "jobFunctionsLabel": "บทบาทหน้าที่ของคุณคืออะไร",
    "interestsLabel": "คุณต้องการเรียนรู้อะไร",
    "brandsLabel": "คุณทำงานให้แบรนด์อะไร",
    "additionalJobFunctionsLabel": "คุณทำหน้าที่อะไรเพิ่มเติมในงาน",
    "managementLevelLabel": "คุณเป็นพนักงานระดับใด",
    "highGuestContactLabel": "คุณติดต่อกับแขกบ่อยหรือไม่"
  },
  "courseDetails": {
    "completeBy": "ต้องทำให้เสร็จสมบูรณ์",
    "recommendTitlte": "แนะนำหลักสูตรให้กับทีมของคุณ",
    "courseName": "ชื่อหลักสูตร",
    "sendRecommendation": "ส่งคำแนะนำ"
  },
  "explore": {
    "relatedCourses": "นี่คือหลักสูตรที่เกี่ยวข้องกับสาขาที่คุณสนใจ",
    "chooseInterests": "เลือกความสนใจของคุณ",
    "title": "สำรวจการเรียนรู้",
    "chooseInterestsModal": "เลือกความสนใจ",
    "oppsTitle": "สำรวจโอกาสการเรียนรู้",
    "growWithUsTitle": "เติบโตไปพร้อมกับเรา",
    "growWithUsDesc": "Marriott เชื่อในอาชีพที่รุ่งโรจน์ไปพร้อมกับคุณ เรายังเชื่อว่าประสบการณ์ที่หลากหลายมักจะให้ประสบการณ์ที่จะงอกเงยได้ต่อไป",
    "growWithUsBtn": "ดูเติบโตไปพร้อมกับเรา",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "Marriott ชื่นชมความสำเร็จและความทุ่มเท เราเชิดชูเกียรติและส่งเสริมความเป็นผู้นำและการบริการที่เป็นเลิศ นอกจากนี้ เรายังให้โบนัสสำหรับการอ้างอิงที่ประสบความสำเร็จอีกด้วย",
    "mdaBtn": "ดู MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "บอกเราว่าคุณสนใจหัวข้ออะไร",
    "otherRecommended": "หลักสูตรที่แนะนำอื่นๆ",
    "searchByName": "ค้นหาสิ่งที่คุณสนใจ"
  },
  "plan": {
    "completedRequired": "คุณเสร็จสิ้นหลักสูตรที่ต้องเรียนทั้งหมดแล้ว!",
    "requiredCourses": "หลักสูตรที่ต้องเรียน",
    "reqDesc": "โปรดเสร็จสิ้นหลักสูตรก่อนวันครบกำหนด",
    "complete": "เสร็จสมบูรณ์",
    "of": "จาก",
    "courses": "หลักสูตร",
    "completedCourses": "หลักสูตรที่เสร็จสิ้นแล้ว",
    "completedDesc": "ยินดีด้วย! คุณเพิ่งเสร็จสิ้นหลักสูตรด้านล่างนี้",
    "remaining": "ที่เหลือ",
    "myLearningTitle": "การเรียนรู้เพิ่มเติมของฉัน ",
    "myLearningSubTitle": "รายการที่ฉันได้เพิ่มลงใน \"แผนของฉัน\"",
    "history": "ประวัติ",
    "myLearningHistoryLinkText": "ดูประวัติแบบเต็มของฉัน",
    "jf": "หน้าที่ในงานของคุณ",
    "awr": "ความรับผิดชอบในงานเพิ่มเติม (AWR) ของคุณ",
    "jfAwrDesc": "สำคัญ: หน้าที่ในงานและ AWR จะระบุการฝึกอบรมที่จำเป็นซึ่งมอบหมายให้คุณใน \"แผนของฉัน\" ",
    "jfAwrEdit": "แก้ไขโปรไฟล์ของคุณ",
    "jfAwrEnd": "เพื่ออัปเดต",
    "tourPlan": "\"แผนของฉัน\" ประกอบด้วยหลักสูตรที่จำเป็นและหลักสูตรที่แนะนำทั้งหมดที่คุณต้องศึกษาให้เสร็จสมบูรณ์ภายในวันครบกำหนด",
    "tourBasics": "Marriott Basics ประกอบด้วยทรัพยากรที่จะช่วยให้คุณเริ่มต้นการเดินทางกับแมริออทและสามารถเข้าถึงได้ทุกเมื่อตลอดอาชีพการทำงานของคุณ Marriott Basics เป็นส่วนเสริมการเรียนรู้ของคุณใน \"แผนของฉัน\"",
    "noCourses": "คุณดำเนินการเรียนรู้ที่จำเป็นตามที่ครบกำหนดในขณะนี้เรียบร้อยแล้ว คุณสามารถดูและเริ่มต้นการเรียนรู้ที่จำเป็นซึ่งกำลังจะมาถึงล่วงหน้าได้"
  },
  "team": {
    "title": "จัดการทีมของคุณ",
    "subtitle": "พัฒนาทักษะและอาชีพของคนในทีม",
    "functions": "หน้าที่",
    "viewTeam": "ดูทีม",
    "allCourses": "หลักสูตรทั้งหมด",
    "downloadExcel": "ดาวน์โหลดรายงาน Excel"
  },
  "feedbackButton": {
    "havingTrouble": "คำแนะนำ"
  },
  "recommendation": {
    "dismiss": "คุณแน่ใจหรือไม่ว่าต้องการปิดคำแนะนำนี้"
  },
  "table": {
    "filterTable": "Filtre Tablosu"
  },
  "notifications": {
    "addAssignment2": "เพิ่มงาน %{assignment_type} สำหรับหลักสูตร %{assignment_name} ในหน้า %{page location} ของคุณแล้ว และจะครบกำหนดในวันที่ %{due}",
    "comingDueAssignment": "งาน %{assignment_type} ของคุณสำหรับหลักสูตร %{assignment_name} จะครบกำหนดเร็วๆ นี้ในวันที่ %{due}",
    "overdueAssignment": "ตอนนี้งาน %{assignment_type} ของคุณสำหรับหลักสูตร %{assignment_name} พ้นกำหนดแล้ว",
    "addAssignment": "เพิ่มงาน %{assignment_type} สำหรับหลักสูตร %{assignment_name} ในหน้า %{page location} ของคุณแล้ว",
    "profile": "ระบบตรวจพบการอัปเดตตำแหน่งงานหรือตำแหน่งที่ตั้งของคุณ โปรดตรวจสอบโปรไฟล์ของคุณเพื่อดำเนินการอัปเดตแบรนด์ หน้าที่ หรือความรับผิดชอบในงานเพิ่มเติมที่เกี่ยวข้อง ข้อมูลโปรไฟล์ DLZ ที่ถูกต้องช่วยให้มั่นใจว่าคุณได้รับมอบหมายงานด้านการเรียนรู้ที่ถูกต้อง",
    "helptip": "การแจ้งเตือนทั้งหมดจะหมดอายุหลังจากผ่านไป 30 วัน",
    "markRead": "ทำเครื่องหมายเป็นอ่านแล้ว",
    "delete": "ลบ",
    "markAllRead": "ทำเครื่องหมายทั้งหมดเป็นอ่านแล้ว",
    "settings": "การตั้งค่า",
    "tooltip": "ระบบจะลบการแจ้งเตือนที่อ่านแล้วใน 30 วัน",
    "reportComplete": "รายงาน DLZ ของคุณเสร็จเรียบร้อยแล้ว คลิกเพื่อดาวน์โหลด",
    "dontShowThisAgain": "ไม่ต้องแสดงข้อความนี้อีก",
    "warningEnabled": "คำเตือนการลบถูกเปิดใช้งานแล้ว",
    "manageEmailNotifications": "จัดการกับการแจ้งเตือนทางอีเมล",
    "warningTooltip": "เมื่อเลือกไว้ คุณจะเห็นคำเตือนในแต่ละครั้งที่ลบการแจ้งเตือน",
    "changeEmailManagedTooltip": "หากต้องการเปลี่ยนอีเมลของคุณ ให้ไปที่ mHUB > HR Direct > ฉัน > ข้อมูลสาธารณะของฉัน ทำตามคำแนะนำเพื่ออัปเดตรายละเอียดการติดต่อของคุณ ระบบอาจใช้เวลา 1-2 วันทำการเพื่อแสดงข้อมูลอีเมลที่อัปเดตแล้ว",
    "changeEmailFranchisedTooltip": "หากต้องการเปลี่ยนอีเมลของคุณ โปรดติดต่อผู้จัดการและ/หรือผู้ดูแลระบบ EID Tool ของแฟรนไชส์ของคุณ ระบบอาจใช้เวลา 1-2 วันทำการเพื่อแสดงข้อมูลอีเมลที่อัปเดตแล้ว",
    "deleteAllNotifications": "ลบการแจ้งเตือนทั้งหมด",
    "deleteIndividualHeader": "ลบการแจ้งเตือน",
    "deleteAllConfirmation": "คุณแน่ใจหรือไม่ว่าต้องการลบการแจ้งเตือนทั้งหมด การแจ้งเตือนที่คุณลบจะย้ายไปอยู่ในส่วน \"ลบแล้ว\" ของศูนย์การแจ้งเตือนจนกว่าจะหมดอายุ",
    "deleteIndividualConfirmation": "คุณแน่ใจหรือไม่ว่าต้องการลบการแจ้งเตือนนี้ การแจ้งเตือนที่คุณลบจะย้ายไปอยู่ในส่วน \"ลบแล้ว\" ของศูนย์การแจ้งเตือนจนกว่าจะหมดอายุ",
    "permanentDeleteIndividiualConfirmation": "คุณแน่ใจหรือไม่ว่าต้องการลบการแจ้งเตือนนี้อย่างถาวร การดำเนินการนี้ไม่สามารถยกเลิกได้",
    "permanentDeleteAllConfirmation": "คุณแน่ใจหรือไม่ว่าต้องการลบการแจ้งเตือนทั้งหมดอย่างถาวร การดำเนินการนี้ไม่สามารถยกเลิกได้",
    "notificationDeleted": "ลบการแจ้งเตือนแล้ว",
    "notificationsDeleted": "ลบการแจ้งเตือนแล้ว",
    "notificationRestored": "คืนค่าการแจ้งเตือนแล้ว",
    "notificationsRestored": "คืนค่าการแจ้งเตือนแล้ว",
    "enableDeletionWarning": "คำเตือนการลบเปิดใช้งานอยู่",
    "warningDisabled": "คำเตือนการลบถูกปิดใช้งานแล้ว",
    "viewAll": "ดูทั้งหมด",
    "deleteAll": "ลบทั้งหมด",
    "notificationCenterTitle": "ศูนย์การแจ้งเตือน",
    "markAsRead": "ทำเครื่องหมายเป็นอ่านแล้ว",
    "noDeletedNotifications": "คุณมีข้อมูลล่าสุดแล้ว!",
    "restore": "คืนค่า",
    "restoreAll": "คืนค่าทั้งหมด",
    "viewCourse": "ดูหลักสูตร",
    "title": "การแจ้งเตือน",
    "noNewNotifications": "คุณไม่มีการแจ้งเตือน",
    "cancel": "ยกเลิก",
    "manageNotificationsCopy": "จัดการกับการแจ้งเตือนทางอีเมล\n\nเลือกว่าจะรับอีเมลรายสัปดาห์ซึ่งให้ข้อมูลสรุปเกี่ยวกับรายการเรียนรู้ที่พ้นกำหนดของคุณหรือไม่ เลือก \"จัดการ\" เพื่อแก้ไขการกำหนดลักษณะของคุณ"
  },
  "transcript": {
    "history": "ประวัติของฉัน",
    "completed": "งานที่เสร็จแล้ว",
    "assignmentName": "ชื่องาน",
    "type": "ประเภท",
    "viewAssignment": "ดูงาน",
    "assignmentSource": "ที่มาของงาน",
    "requiredAssignments": "งานที่ต้องดำเนินการ",
    "recommendedAssignments": "งานที่แนะนำ",
    "voluntary": "ตามความสมัครใจ",
    "trasscript": "สำเนาบทสนทนา",
    "retraining": "การฝึกทักษะใหม่",
    "completionDate": "วันที่ทำเสร็จ",
    "assignmentType": "ประเภทงาน",
    "contentType": "ประเภทเนื้อหา",
    "contentId": "ID เนื้อหา"
  },
  "contentLaunch": {
    "review": "ทบทวนการเรียนรู้",
    "start": "เริ่มต้นการเรียนรู้"
  },
  "survey": {
    "weValue": "เราอยากฟังความคิดเห็นของคุณ",
    "weValueDescription": "โปรดตอบคำถามสั้นๆ ห้าข้อเกี่ยวกับประสบการณ์ของคุณในการทำงานที่โรงแรม",
    "weValueButtonText": "ตอบแบบสำรวจ",
    "weValueG1Q1A1": "ใช่",
    "weValueG1Q1A2": "ไม่ใช่",
    "strongAgree": "เห็นด้วยอย่างยิ่ง",
    "agree": "เห็นด้วย",
    "slightAgree": "เห็นด้วยเพียงส่วนหนึ่ง",
    "slightDisagree": "ไม่เห็นด้วยนัก",
    "disagree": "อย่างยิ่ง",
    "strongDisagree": "ไม่เห็นด้วยอย่างยิ่ง",
    "weValueG1Q1Title": "คุณได้เข้าร่วมการประชุมประจำวันในกะสุดท้ายที่คุณทำงานหรือไม่ (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments หรือ Daily EDITION)",
    "weValueG1Q2Title": "การประชุมประจำวัน (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments หรือ Daily EDITION) กระตุ้นให้ฉันสร้างบริการระดับลักชูรีในแต่ละวัน",
    "weValueG1Q3Title": "แผนกของฉันมุ่งเน้นการลดข้อบกพร่อง แม้จะเป็นข้อบกพร่องที่ผู้เข้าพักไม่ได้รายงานก็ตาม",
    "weValueG1Q4Title": "ฉันได้รับอำนาจในการแก้ไขปัญหาของผู้เข้าพักโดยไม่ต้องขออนุญาตก่อน",
    "weValueG1Q5Title": "ฉันได้รับอำนาจในการสร้างความพึงพอใจให้ผู้เข้าพักโดยไม่ต้องขออนุญาตก่อน",
    "weValueG2Q1Title": "ฉันมักจะเห็นสมาชิกในทีมที่โรงแรมของฉันทำงานอย่างสุดความสามารถเพื่อสร้างความประทับใจให้กับผู้เข้าพักด้วยการดูแลที่มอบให้",
    "weValueG2Q2Title": "การเรียนรู้ของฉันในแผนกนี้ทำให้ฉันสามารถให้บริการระดับลักชูรี",
    "weValueG2Q3Title": "ฉันรู้สึกมั่นใจกับความรู้และทักษะของฉันในการให้บริการระดับลักชูรี",
    "weValueG2Q4Title": "ในแผนกของฉัน เราให้บริการในบรรยากาศระดับลักชูรีที่สมบูรณ์และปราศจากข้อบกพร่อง",
    "weValueG2Q5Title": "แม้กระทั่งเวลาที่พบปัญหาที่ไม่เกี่ยวข้องกับแผนกของฉัน ฉันก็ได้รับอำนาจให้เข้ามาทำหน้าที่และดูแลข้อกังวลของผู้เข้าพักได้",
    "weValueG3Q1Title": "ที่โรงแรมของฉัน สมาชิกในทีมได้รับการดูแลในระดับเดียวกับผู้เข้าพัก",
    "weValueG3Q2Title": "แผนกของฉันรับเอาค่านิยมของแบรนด์มาดำเนินการอย่างเป็นรูปธรรม",
    "weValueG3Q3Title": "เมื่อฉันทำงานอย่างสุดความสามารถเพื่อสร้างประสบการณ์ระดับลักชูรีให้กับผู้เข้าพัก ผลงานของฉันได้รับการยอมรับและชื่นชม",
    "weValueG3Q4Title": "ฉันมีส่วนเกี่ยวข้องกับความพยายามของแผนกในการปรับปรุงการทำงานของเรา",
    "weValueG3Q5Title": "ในแผนกของฉัน เราให้ความสำคัญกับผู้เข้าพักเป็นอันดับแรกเสมอ",
    "submitButton": "ส่ง",
    "phasetwo": {
      "weValueG1Q1Title": "คุณได้เข้าร่วมการประชุมประจำวันในกะสุดท้ายที่คุณทำงานหรือไม่ (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments หรือ Daily EDITION)",
      "weValueG1Q2Title": "การประชุมประจำวัน (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments หรือ Daily EDITION) กระตุ้นให้สมาชิกในทีมสร้างบริการระดับลักชูรีในแต่ละวัน",
      "weValueG1Q3Title": "ที่โรงแรมของฉัน เรามุ่งเน้นการลดข้อบกพร่อง แม้จะเป็นข้อบกพร่องที่ผู้เข้าพักไม่ได้รายงานก็ตาม",
      "weValueG1Q4Title": "ที่โรงแรมของฉัน เราได้รับอำนาจในการแก้ไขปัญหาของผู้เข้าพักโดยไม่ต้องขออนุญาตก่อน",
      "weValueG1Q5Title": "ที่โรงแรมของฉัน เราได้รับอำนาจในการสร้างความพึงพอใจให้ผู้เข้าพักโดยไม่ต้องขออนุญาตก่อน",
      "weValueG2Q1Title": "ที่โรงแรมของฉัน เรามักจะทำงานอย่างสุดความสามารถเพื่อสร้างความประทับใจให้กับผู้เข้าพักด้วยการดูแลที่เรามอบให้",
      "weValueG2Q2Title": "โอกาสในการเรียนรู้ที่โรงแรมได้ช่วยให้เราสามารถให้บริการระดับลักชูรีได้",
      "weValueG2Q3Title": "ที่โรงแรมของฉัน เรารู้สึกมั่นใจกับความรู้และทักษะของเราในการให้บริการระดับลักชูรี",
      "weValueG2Q4Title": "ที่โรงแรมของฉัน เราให้บริการในบรรยากาศระดับลักชูรีที่สมบูรณ์และปราศจากข้อบกพร่อง",
      "weValueG2Q5Title": "แม้กระทั่งเวลาที่พบปัญหาที่ไม่เกี่ยวข้องกับแผนกของเรา เราก็ได้รับอำนาจให้เข้ามาทำหน้าที่และดูแลข้อกังวลของผู้เข้าพักได้",
      "weValueG3Q1Title": "ที่โรงแรมของฉัน เราได้รับการดูแลเอาใจใส่จากกันและกันในระดับเดียวกับที่เรามอบให้กับผู้เข้าพัก",
      "weValueG3Q2Title": "โรงแรมของฉันรับเอาค่านิยมของแบรนด์มาดำเนินการอย่างเป็นรูปธรรม",
      "weValueG3Q3Title": "ที่โรงแรมของฉัน เมื่อเราทำงานอย่างสุดความสามารถเพื่อสร้างประสบการณ์ระดับลักชูรีให้กับผู้เข้าพัก ผลงานของเราได้รับการยอมรับและชื่นชม",
      "weValueG3Q4Title": "ที่โรงแรมของฉัน เรามีโอกาสมีส่วนเกี่ยวข้องกับความพยายามของแผนกในการปรับปรุงการทำงานของเรา",
      "weValueG3Q5Title": "ที่โรงแรมของฉัน เราให้ความสำคัญกับผู้เข้าพักเป็นอันดับแรกเสมอ"
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} เสร็จสิ้น",
    "gatewayProgressComplete": "ความคืบหน้าของ GATEWAY ที่เสร็จสิ้น",
    "heroMobileGatewayVideoText": "เล่นวิดีโอ Gateway",
    "basics": "ส่วนพื้นฐาน",
    "brandResources": "ทรัพยากรเกี่ยวกับแบรนด์",
    "disciplineResources": "ทรัพยากรตามแผนก",
    "immersionResourcesHeader": "ทรัพยากรเกี่ยวกับการมีส่วนร่วมในระดับลึก",
    "brandCommunicationsHeaader": "การสื่อสารแบรนด์",
    "brandContactsHeader": "การติดต่อแบรนด์",
    "franchisedActionItemsText": "รายการปฏิบัติการช่วยให้คุณรักษาความเป็นระบบ และให้ข้อมูลที่สำคัญในการเตรียมความพร้อมเพื่อเริ่มทำงาน รายการเหล่านี้ไม่ใช่การดำเนินการภาคบังคับ แต่เป็นส่วนเสริมจากข้อมูลที่คุณอาจได้รับจากบริษัทที่บริหารจัดการระบบแฟรนไชส์ของคุณ",
    "global": "ทั่วโลก",
    "continent": "ภาคพื้นทวีป",
    "viewed": "ดูแล้ว",
    "completed": "เสร็จสิ้นแล้ว",
    "heading": "Gateway",
    "basicsTab": "พื้นฐาน",
    "onboardingTab": "รายการปฏิบัติการ",
    "brandTab": "ทรัพยากรเกี่ยวกับแบรนด์",
    "disciplinesTab": "ทรัพยากรตามแผนก",
    "basicsHeader": "ยินดีต้อนรับสู่แมริออท อินเตอร์เนชั่นแนล",
    "basicsHeaderNewBrandOrRole": "ยินดีต้อนรับ",
    "basicsText": "เมื่อคุณเริ่มต้นเส้นทางการทำงานนี้ เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงสองสามวันแรก เนื้อหาเหล่านี้บางรายการจะมีการทบทวนกันแบบเจาะลึก ณ การปฐมนิเทศหรือการเตรียมความพร้อมในการเข้าสู่แบรนด์ แต่ข้อมูลนี้จะเป็นประโยชน์สำหรับการเริ่มต้น",
    "basicsTextGms": "เมื่อคุณเริ่มต้นเส้นทางการทำงานนี้ เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงสองสามวันแรก เนื้อหาเหล่านี้บางรายการจะมีการทบทวนกันแบบเจาะลึก ณ การปฐมนิเทศหรือการเตรียมความพร้อมในการเข้าสู่แบรนด์ แต่ข้อมูลนี้จะเป็นประโยชน์สำหรับการเริ่มต้น เมื่ออ่านจบแล้ว โปรดศึกษาข้อมูลในหน้าอื่นๆ ของ Gateway ต่อ ซึ่งประกอบด้วยรายการปฏิบัติการ, ทรัพยากรเกี่ยวกับแบรนด์ และทรัพยากรตามแผนก",
    "basicsTextFranchise": "เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงแรกเริ่ม เนื้อหาเหล่านี้สามารถใช้เสริมการปฐมนิเทศ การเตรียมความพร้อมในการเข้าสู่แบรนด์ หรือข้อมูลจากบริษัทที่บริหารจัดการระบบแฟรนไชส์ของคุณได้",
    "basicsTextFranchiseGms": "เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงแรกเริ่ม เนื้อหาเหล่านี้สามารถใช้เสริมการปฐมนิเทศ การเตรียมความพร้อมในการเข้าสู่แบรนด์ หรือข้อมูลจากบริษัทที่บริหารจัดการระบบแฟรนไชส์ของคุณได้ เมื่ออ่านจบแล้ว โปรดศึกษาข้อมูลในหน้าอื่นๆ ของ Gateway ต่อ ซึ่งประกอบด้วยรายการปฏิบัติการ, ทรัพยากรเกี่ยวกับแบรนด์ และทรัพยากรตามแผนก",
    "basicsTextNewToRole": "เมื่อคุณเริ่มต้นการทำงานในบทบาทใหม่ เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงสองสามวันแรก เนื้อหาเหล่านี้บางรายการจะมีการทบทวนกันแบบเจาะลึก ณ การฝึกอบรมที่คุณได้รับมอบหมายให้เข้าร่วม แต่ข้อมูลนี้จะเป็นประโยชน์สำหรับการเริ่มต้น",
    "basicsTextNewToRoleGms": "เมื่อคุณเริ่มต้นการทำงานในบทบาทใหม่ เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงสองสามวันแรก เนื้อหาเหล่านี้บางรายการจะมีการทบทวนกันแบบเจาะลึก ณ การฝึกอบรมที่คุณได้รับมอบหมายให้เข้าร่วม แต่ข้อมูลนี้จะเป็นประโยชน์สำหรับการเริ่มต้น เมื่ออ่านจบแล้ว โปรดศึกษาข้อมูลในหน้าอื่นๆ ของ Gateway ต่อ ซึ่งประกอบด้วยรายการปฏิบัติการ, ทรัพยากรเกี่ยวกับแบรนด์ และทรัพยากรตามแผนก",
    "basicsTextNewToRoleFranchise": "เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในบทบาทใหม่ในช่วงแรกเริ่ม เนื้อหาเหล่านี้สามารถใช้เสริมข้อมูลจากบริษัทที่บริหารจัดการระบบแฟรนไชส์ของคุณได้",
    "basicsTextNewToRoleFranchiseGms": "เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในบทบาทใหม่ในช่วงแรกเริ่ม เนื้อหาเหล่านี้สามารถใช้เสริมข้อมูลจากบริษัทที่บริหารจัดการระบบแฟรนไชส์ของคุณได้ เมื่ออ่านจบแล้ว โปรดศึกษาข้อมูลในหน้าอื่นๆ ของ Gateway ต่อ ซึ่งประกอบด้วยรายการปฏิบัติการ, ทรัพยากรเกี่ยวกับแบรนด์ และทรัพยากรตามแผนก",
    "basicsTextNewToBrand": "เมื่อคุณเริ่มต้นการทำงานในบทบาทใหม่กับ %{brandName} เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงสองสามวันแรก เนื้อหาเหล่านี้บางรายการจะมีการทบทวนกันแบบเจาะลึก ณ การปฐมนิเทศหรือการเตรียมความพร้อมในการเข้าสู่แบรนด์ แต่ข้อมูลนี้จะเป็นประโยชน์สำหรับการเริ่มต้น",
    "basicsTextNewToBrandGms": "เมื่อคุณเริ่มต้นการทำงานในบทบาทใหม่กับ %{brandName} เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงสองสามวันแรก เนื้อหาเหล่านี้บางรายการจะมีการทบทวนกันแบบเจาะลึก ณ การปฐมนิเทศหรือการเตรียมความพร้อมในการเข้าสู่แบรนด์ แต่ข้อมูลนี้จะเป็นประโยชน์สำหรับการเริ่มต้น เมื่ออ่านจบแล้ว โปรดศึกษาข้อมูลในหน้าอื่นๆ ของ Gateway ต่อ ซึ่งประกอบด้วยรายการปฏิบัติการ, ทรัพยากรเกี่ยวกับแบรนด์ และทรัพยากรตามแผนก",
    "basicsTextNewToBrandFranchise": "เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในบทบาทใหม่ในช่วงแรกเริ่ม เนื้อหาเหล่านี้สามารถใช้เสริมการปฐมนิเทศ การเตรียมความพร้อมในการเข้าสู่แบรนด์ หรือข้อมูลจากบริษัทที่บริหารจัดการระบบแฟรนไชส์ของคุณได้",
    "basicsTextNewToBrandFranchiseGms": "เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในบทบาทใหม่ในช่วงแรกเริ่มกับ %{brandName} เนื้อหาเหล่านี้สามารถใช้เสริมการปฐมนิเทศ การเตรียมความพร้อมในการเข้าสู่แบรนด์ หรือข้อมูลจากบริษัทที่บริหารจัดการระบบแฟรนไชส์ของคุณได้ เมื่ออ่านจบแล้ว โปรดศึกษาข้อมูลในหน้าอื่นๆ ของ Gateway ต่อ ซึ่งประกอบด้วยรายการปฏิบัติการ, ทรัพยากรเกี่ยวกับแบรนด์ และทรัพยากรตามแผนก",
    "disciplineHeader": "ทรัพยากรตามแผนก",
    "toDo": "ภาคบังคับ",
    "optional": "ตัวเลือกเสริม",
    "overallComplete": "เสร็จสมบูรณ์ทั้งหมด",
    "keyResourcesDesc": "เรียนรู้เพิ่มเติมเกี่ยวกับแผนกหลักที่สนับสนุนการปฏิบัติงานประจำวันของโรงแรม ประสบการณ์ของผู้เข้าพัก และความต้องการของธุรกิจ",
    "franchiseFooter": "เนื้อหานี้ประกอบด้วยคำแนะนำและแนวทางปฏิบัติที่เสนอแนะ ซึ่งอาจช่วยเพิ่มความเป็นไปได้ในการปฏิบัติตามมาตรฐานของแบรนด์ ในฐานะเจ้าของสิทธิ์ แมริออทไม่มีส่วนร่วมในการบริหารหรือดำเนินการไม่ว่าแง่มุมใดๆ ของการดำเนินธุรกิจหรือโรงแรมของผู้รับสิทธิ์ โดยผู้รับสิทธิ์จะผูกขาดสิทธิ์ในการควบคุมการปฏิบัติงานประจำวันของโรงแรมของตน และมีหน้าที่รับผิดชอบต่อพนักงานและแนวทางปฏิบัติในการจ้างงานของตนเอง",
    "landingHeroTitle1": "ยินดีต้อนรับสู่แมริออท อินเตอร์เนชั่นแนล",
    "landingHeroTitle2": "เริ่มต้นเส้นทางของคุณที่นี่",
    "landingHeroText": "The Gateway คือเครื่องมือที่จะช่วยชี้แนะแนวทางให้คุณตลอดปีแรกของการรับหน้าที่ในบทบาทใหม่ โดยจะให้ข้อมูลและทรัพยากรต่างๆ ที่จำเป็นเพื่อช่วยให้คุณประสบความสำเร็จในการทำงาน",
    "landingHeroTitle1NewToRole": "ยินดีต้อนรับสู่ The Gateway",
    "landingHeroTitle1NewToBrand": "ยินดีต้อนรับสู่ The Gateway",
    "landingButtonText": "เริ่มต้นใช้งาน",
    "twoMonthslandingHeroTitle1": "ขอต้อนรับกลับเข้าสู่การฝึกอบรม!",
    "twoMonthslandingHeroText": "หากคุณยังไม่ได้ดำเนินการ โปรดเรียนรู้ข้อมูลทั้งหมดในส่วนพื้นฐานของ The Gateway ให้เสร็จสมบูรณ์",
    "twoMonthslandingButtonText": "เข้าสู่ Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "ขอต้อนรับกลับเข้าสู่การฝึกอบรม!",
    "twoMonthslandingHeroTextNewToBrand": "เราหวังว่าวันแรกของคุณที่ %{brandName} จะผ่านไปด้วยดี แล้วอย่าลืมมาเรียนรู้กันต่อในเส้นทางของคุณ และเข้าถึงข้อมูลทุกอย่างที่คุณจำเป็นต้องทราบได้ใน The Gateway",
    "twoMonthslandingButtonTextNewToBrand": "ไปต่อ",
    "threeMonthslandingHeroTitle1": "คุณใกล้เข้ามาอีกขั้นแล้ว!",
    "threeMonthslandingHeroText": "ทุกสิ่งที่คุณจำเป็นต้องทราบเกี่ยวกับแมริออท อินเตอร์เนชั่นแนลมีพร้อมอยู่ใน The Gateway",
    "threeMonthslandingButtonText": "เข้าสู่ Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "คุณใกล้เข้ามาอีกขั้นแล้ว!",
    "threeMonthslandingHeroTextNewToRole": "ทุกสิ่งที่คุณจำเป็นต้องทราบเพื่อประสบความสำเร็จกับบทบาทใหม่มีพร้อมอยู่ใน The Gateway",
    "threeMonthslandingButtonTextNewToRole": "เข้าสู่ Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "ขอต้อนรับกลับเข้าสู่การฝึกอบรม!",
    "threeMonthslandingHeroTextNewToBrand": "ยินดีด้วยสำหรับการร่วมงานกับแบรนด์ใหม่ครบ %{numDays} วันแรก คุณสามารถเข้าสู่รายการปฏิบัติการ, แบรนด์ และทรัพยากรตามแผนกทั้งหมดได้ทุกเมื่อที่ต้องการ",
    "threeMonthslandingButtonTextNewToBrand": "เข้าสู่ Gateway",
    "fourMonthslandingHeroTitle1": "เยี่ยม! คุณเรียนรู้ในส่วนพื้นฐานเสร็จเรียบร้อยแล้ว",
    "fourMonthslandingHeroText": "เข้าถึงข้อมูลทุกอย่างที่คุณจำเป็นต้องทราบเกี่ยวกับแมริออท อินเตอร์เนชั่นแนลได้ใน The Gateway",
    "fourMonthslandingButtonText": "เข้าสู่ Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "เยี่ยม! คุณเรียนรู้ในส่วนพื้นฐานเสร็จเรียบร้อยแล้ว",
    "fourMonthslandingHeroTextNewToRole": "เข้าถึงข้อมูลทุกอย่างที่คุณจำเป็นต้องทราบเพื่อประสบความสำเร็จกับบทบาทใหม่ได้ใน The Gateway",
    "fourMonthslandingButtonTextNewToRole": "เข้าสู่ Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - กำลังเช็คอิน",
    "fourMonthslandingHeroTextNewToBrand": "เมื่อการเดินทางหนึ่งปีแรกของคุณกับ %{brandName} ใกล้จะสำเร็จลุล่วง หากคุณยังไม่ได้ดำเนินการ โปรดดำเนินการตามรายการปฏิบัติการทั้งหมดของคุณให้เสร็จสมบูรณ์ และใช้ทรัพยากรการพัฒนาซึ่งมีอยู่ใน Digital Learning Zone ให้เป็นประโยชน์ คลิก 'ดำเนินการต่อ' เพื่อเดินหน้าต่อไปในเส้นทางของคุณ",
    "fourMonthslandingButtonTextNewToBrand": "ดำเนินการต่อ",
    "gmSecondVisitlandingHeroTitle1": "ขอต้อนรับกลับเข้าสู่การฝึกอบรม!",
    "gmSecondVisitlandingHeroText": "เราหวังว่าวันแรกของคุณจะผ่านไปด้วยดี แล้วอย่าลืมมาเรียนรู้กันต่อในเส้นทางของคุณเพื่อช่วยให้คุณเติบโตในบทบาทใหม่นี้",
    "gmSecondVisitlandingButtonText": "ไปต่อ",
    "gmSecondVisitlandingHeroTextNewToRole": "เราหวังว่าวันแรกในบทบาทใหม่ของคุณจะผ่านไปด้วยดี แล้วอย่าลืมมาเรียนรู้กันต่อในเส้นทางของคุณ และเข้าถึงข้อมูลทุกอย่างที่คุณจำเป็นต้องทราบได้ใน The Gateway",
    "gmSecondVisitlandingHeroTextNewToBrand": "เราหวังว่าวันแรกของคุณที่ %{brandName} จะผ่านไปด้วยดี แล้วอย่าลืมมาเรียนรู้กันต่อในเส้นทางของคุณ และเข้าถึงข้อมูลทุกอย่างที่คุณจำเป็นต้องทราบได้ใน The Gateway",
    "gmThirdVisitlandingHeroTitle1": "ขอต้อนรับกลับเข้าสู่การฝึกอบรม!",
    "gmThirdVisitlandingHeroText": "ยินดีด้วยสำหรับการเรียนรู้ในเส้นทางของพนักงานใหม่ครบ %{numDays} วันแรก คุณสามารถเข้าสู่รายการปฏิบัติการ, แบรนด์ และทรัพยากรตามแผนกทั้งหมดได้ทุกเมื่อที่ต้องการ",
    "gmThirdVisitlandingButtonText": "เข้าสู่ Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "ยินดีด้วยสำหรับการเรียนรู้ในบทบาทใหม่ของคุณครบ %{numDays} วันแรก คุณสามารถเข้าสู่รายการปฏิบัติการ, แบรนด์ และทรัพยากรตามแผนกทั้งหมดได้ทุกเมื่อที่ต้องการ",
    "gmThirdVisitlandingHeroTextNewToBrand": "ยินดีด้วยสำหรับการร่วมงานกับแบรนด์ใหม่ครบ %{numDays} วันแรก คุณสามารถเข้าสู่รายการปฏิบัติการ, แบรนด์ และทรัพยากรตามแผนกทั้งหมดได้ทุกเมื่อที่ต้องการ",
    "gmSevenMonthslandingHeroTitle1": "ดูเหมือนว่าคุณจะมีรายการปฏิบัติการเพิ่มเติมที่ต้องดำเนินการ",
    "gmSevenMonthslandingHeroText": "เมื่อการเดินทางหนึ่งปีแรกของคุณใกล้จะสำเร็จลุล่วง โปรดอย่าลืมดำเนินการตามรายการปฏิบัติการทั้งหมดของคุณให้เสร็จสมบูรณ์ และใช้ทรัพยากรการพัฒนาซึ่งมีอยู่ใน Digital Learning Zone ให้เป็นประโยชน์ คลิก 'ดำเนินการต่อ' เพื่อเดินหน้าต่อไปในเส้นทางของคุณ",
    "gmSevenMonthslandingButtonText": "ดำเนินการต่อ",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - กำลังเช็คอิน",
    "gmSevenMonthslandingHeroTextNewToRole": "เมื่อการเดินทางหนึ่งปีแรกของคุณในบทบาทใหม่ใกล้จะสำเร็จลุล่วง หากคุณยังไม่ได้จัดการให้เรียบร้อย โปรดดำเนินการตามรายการปฏิบัติการทั้งหมดของคุณให้เสร็จสมบูรณ์ และใช้ทรัพยากรการพัฒนาซึ่งมีอยู่ใน Digital Learning Zone ให้เป็นประโยชน์ คลิก 'ดำเนินการต่อ' เพื่อเดินหน้าต่อไปในเส้นทางของคุณ",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - กำลังเช็คอิน",
    "gmSevenMonthslandingHeroTextNewToBrand": "เมื่อการเดินทางหนึ่งปีแรกของคุณกับ %{brandName} ใกล้จะสำเร็จลุล่วง หากคุณยังไม่ได้ดำเนินการ โปรดดำเนินการตามรายการปฏิบัติการทั้งหมดของคุณให้เสร็จสมบูรณ์ และใช้ทรัพยากรการพัฒนาซึ่งมีอยู่ใน Digital Learning Zone ให้เป็นประโยชน์ คลิก 'ดำเนินการต่อ' เพื่อเดินหน้าต่อไปในเส้นทางของคุณ",
    "gmOneYearlandingHeroTitle1": "ขอแสดงความยินดีที่คุณร่วมงานกับ %{brandName} มาครบ 1 ปีแล้ว!",
    "gmOneYearlandingHeroTitle1NewToRole": "ขอแสดงความยินดีกับการทำงานครบ 1 ปีในบทบาทใหม่ของคุณ!",
    "gmOneYearlandingHeroTitle1NewToBrand": "ขอแสดงความยินดีกับการทำงานครบ 1 ปีในบทบาทใหม่ของคุณที่ %{brandName}!",
    "gmOneYearlandingHeroText": "เส้นทางของ The Gateway อาจจะสิ้นสุดลง แต่สิ่งที่ดียิ่งกว่ายังคงรออยู่เบื้องหน้า คุณสามารถเข้าสู่ทรัพยากรต่างๆ จากแท็บ The Gateway ได้ทุกเมื่อ",
    "gmOneYearlandingButtonText": "ดู Gateway"
  },
  "resources": {
    "iptTooltip": "ใช้เครื่องหมายจุลภาคเพื่อแยกตำแหน่งที่ตั้งต่างๆ",
    "iptAllFunctionTooltip": "ยกเลิกการทำเครื่องหมายเพื่อเลือกฟังก์ชันงานที่เฉพาะเจาะจง ทำเครื่องหมายไว้ต่อไปเพื่อดูการฝึกอบรมสำหรับฟังก์ชันทั้งหมด ณ ตำแหน่งที่ตั้งที่เลือก",
    "iptAllAWRTooltip": "ยกเลิกการทำเครื่องหมายเพื่อเลือกความรับผิดชอบในงานเพิ่มเติมแบบเฉพาะเจาะจง ทำเครื่องหมายไว้ต่อไปเพื่อดูการฝึกอบรมสำหรับความรับผิดชอบในงานเพิ่มเติมทั้งหมด ณ ตำแหน่งที่ตั้งที่เลือก",
    "editJFTooltip": "ใช้เครื่องหมายจุลภาคเพื่อแยกรายการต่างๆ"
  },
  "Notification Center: ": "ทำเครื่องหมายเป็นยังไม่ได้อ่าน",
  "Notification Center: Empty": "คุณมีข้อมูลล่าสุดแล้ว!",
  "Notification Center: Individual Delete": "คุณแน่ใจหรือไม่ว่าต้องการลบการแจ้งเตือนนี้ การแจ้งเตือนที่ถูกลบจะย้ายไปอยู่ในส่วน \"ลบแล้ว\" ของศูนย์การแจ้งเตือนจนกว่าจะหมดอายุ",
  "Notification Center: Delete All": "คุณแน่ใจหรือไม่ว่าต้องการลบการแจ้งเตือนทั้งหมด การแจ้งเตือนที่คุณลบจะย้ายไปอยู่ในส่วน \"ลบแล้ว\" ของศูนย์การแจ้งเตือนจนกว่าจะหมดอายุ",
  "Notification Center: Mark All As Read": "ทำเครื่องหมายทั้งหมดเป็นอ่านแล้ว",
  "Notification Center: Restore All": "คืนค่าทั้งหมด",
  "Notification Center: Delete Confirmation": "ลบ",
  "Warning Message Text: Remove Job Function or AWR": "ลบฟังก์ชันงานหรือความรับผิดชอบในงานเพิ่มเติม (AWR) ออกหรือไม่\r\nการส่งข้อมูลนี้หมายความว่าคุณกำลังจะลบ %{Function and/or AWR} ออกจากผู้เรียน %{count} ราย",
  "Warning Message Text: Add Job Function or AWR": "เพิ่มฟังก์ชันงานหรือความรับผิดชอบในงานเพิ่มเติม (AWR) หรือไม่\r\nการส่งข้อมูลนี้หมายความว่าคุณกำลังจะเพิ่ม %{Function and/or AWR} ให้กับผู้เรียน %{count} ราย",
  "Button": "ส่ง",
  "Success Message Text: Add Job Function and/or AWR": "สำเร็จ\r\nคุณได้เพิ่ม %{Job Function and/or AWR} ให้กับพนักงานที่ขอที่ %{location} \r\n\r\nคุณต้องการแก้ไขฟังก์ชันงานเพิ่มเติมหรือความรับผิดชอบในงานเพิ่มเติมสำหรับ %{location} หรือไม่",
  "Message Text:AWR": "สำเร็จ\r\nคุณได้เพิ่มความรับผิดชอบในงานเพิ่มเติม %{ __} ให้กับพนักงานที่ขอที่ %{ __}\r\n\r\nคุณต้องการแก้ไขฟังก์ชันงานเพิ่มเติมหรือความรับผิดชอบในงานเพิ่มเติมสำหรับ %{location, property, learner(s)} หรือไม่",
  "Success Message Text: Remove Job Function": "สำเร็จ\r\nคุณได้ลบ %{Job Function and/or AWR} ออกจากพนักงานที่ขอที่ %{location} \r\n\r\nคุณต้องการแก้ไขฟังก์ชันงานเพิ่มเติมหรือความรับผิดชอบในงานเพิ่มเติมสำหรับ %{location} หรือไม่",
  "Success Message Text: Remove AWR": "สำเร็จ\r\nคุณได้ลบความรับผิดชอบในงานเพิ่มเติม %{ __} ออกจากพนักงานที่ขอที่ %{ __} \r\n\r\nคุณต้องการแก้ไขฟังก์ชันงานเพิ่มเติมหรือความรับผิดชอบในงานเพิ่มเติมสำหรับ %{location, property, learner(s)} หรือไม่",
  "Tool Tip - Managed Change Email": "หากต้องการเปลี่ยนอีเมลของคุณ ให้ไปที่ mHUB > HR Direct > ฉัน > ข้อมูลสาธารณะของฉัน ทำตามคำแนะนำเพื่ออัปเดตรายละเอียดการติดต่อของคุณ ระบบอาจใช้เวลา 1-2 วันทำการเพื่อแสดงข้อมูลอีเมลที่อัปเดตแล้ว",
  "Tool Tip - Franchised  Change Email": "หากต้องการเปลี่ยนอีเมลของคุณ โปรดติดต่อผู้จัดการและ/หรือผู้ดูแลระบบ EID Tool ของแฟรนไชส์ของคุณ ระบบอาจใช้เวลา 1-2 วันทำการเพื่อแสดงข้อมูลอีเมลที่อัปเดตแล้ว",
  "User Profile: Email Opt-in": "ไม่ ขอบคุณ",
  "User Profile: Email Frequency": "สรุปข้อมูลรายสัปดาห์",
  "Generic Notification Email Subject Line:": "คุณมีการแจ้งเตือนใหม่ใน DLZ",
  "Generic Body Text (<150 Characters)": "โปรดไปที่ DLZ เพื่อดูรายละเอียด",
  "All Emails: Signature": "Digital Learning Zone - เรียนได้ ง่ายนิดเดียว สิ่งนี้เริ่มที่คุณ",
  "All Emails: Footer": "คุณสามารถยกเลิกการสมัคร/เลือกไม่รับการแจ้งเตือนทางอีเมลได้ทุกเมื่อ ไปที่ Digital Learning Zone/โปรไฟล์ เพื่ออัปเดตการตั้งค่าของคุณ",
  "Overdue Emails: Subject Line: ": "งาน DLZ ต้องการให้คุณดำเนินการ",
  "Overdue Emails: Body Test": "คุณมีงานที่พ้นกำหนด โปรดเข้าสู่ระบบ DLZ เพื่อตรวจสอบงานของคุณ",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "ฝึกฝนทักษะของคุณ",
    "sharpenYourSkillsSubTitle": "สร้างความรู้และทักษะเพื่อยกระดับอาชีพของคุณ ทั้งวันนี้และวันหน้า",
    "growYourCareerTitle": "เติบโตก้าวหน้าในอาชีพ",
    "growYourCareerSubTitle": "วางแผนการดำเนินการถัดไปของคุณด้วยทักษะเฉพาะด้านหรือการฝึกอบรมข้ามสายงานที่สนับสนุนความสนใจและเป้าหมายในแบบของคุณเอง",
    "additionalResourcesTitle": "แหล่งข้อมูลเพิ่มเติม",
    "additionalResourcesSubTitle": "สำรวจ getAbstract® และ CultureWizard™ พบกับวิธีที่ DLZ ยังคงช่วยให้คุณเติบโตได้อย่างต่อเนื่อง",
    "tourHeader": "ยินดีต้อนรับสู่ DLZ เวอร์ชันปรับปรุงใหม่ ตอนนี้คุณสามารถค้นหาทุกสิ่งที่คุณต้องการได้ทุกเมื่อ นอกจากนี้ คุณยังจะพบกับส่วน \"สำรวจการเรียนรู้\" ที่ได้รับการปรับปรุงใหม่ และ \"แผนของฉัน\" ที่ปรับให้เป็นแบบส่วนตัวมากยิ่งขึ้น ไปชมกันเลย!",
    "tourBtnSkip": "ปิด",
    "tourBtnNext": "ปิด (6/6)",
    "tourSearchBar": "ค้นหาสิ่งที่คุณต้องการ ในเวลาที่ต้องการ ด้วยฟีเจอร์ \"ค้นหา\" แบบใหม่ที่มีประสิทธิภาพยิ่งขึ้น",
    "tourBtnBack": "ย้อนกลับ",
    "tourMyPlan": "ตอนนี้ \"แผนของฉัน\" มีรายการที่คุณเพิ่มจากฟีเจอร์ \"ค้นหา\" และ \"สำรวจ\" เพื่อให้คุณเป็นเจ้าของเส้นทางการเรียนรู้ของคุณเองได้อย่างเต็มรูปแบบ!",
    "tourExploreLearningLink": "คุณยังคงสามารถไปที่ \"สำรวจการเรียนรู้\" จากที่นี่ได้ด้วย!",
    "tourExploreLearningProfile": "ใช้ประโยชน์จากประสบการณ์ในการเรียนรู้ของคุณให้มากที่สุด แล้วตรวจสอบโปรไฟล์ของคุณเป็นประจำเพื่อดูแลข้อมูลใน \"แผนของฉัน\" ให้เป็นปัจจุบัน โดยเฉพาะอย่างยิ่งหน้าที่ในงาน",
    "mhubTraining": "การฝึกอบรม mHUB",
    "growthSkills": "ทักษะการเติบโต",
    "leadershipSkills": "ทักษะภาวะผู้นำ",
    "virtualTeamResources": "แหล่งข้อมูลสำหรับทีมเสมือนจริง",
    "jobFunctions": "หน้าที่ในงาน",
    "brands": "แบรนด์",
    "languageLearning": "การเรียนภาษา",
    "become": "พัฒนาและเติบโต",
    "emeaMentoringProgram": "โปรแกรมการฝึกสอน EMEA",
    "learningPathways": "เส้นทางการเรียนรู้",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare Community Hub",
    "mhubTrainingDesc": "ค้นหาเส้นทางการเรียนรู้ตามบทบาทและหลักสูตรแบบเจาะจงหัวข้อเกี่ยวกับวิธีการใช้งาน mHUB",
    "growthSkillsDesc": "รับความรู้ที่นำไปปฏิบัติได้จริงเกี่ยวกับฟังก์ชันและหัวข้อที่หลากหลาย",
    "leadershipSkillsDesc": "ค้นพบวิธีบริหารจัดการและสอนทีมงาน และการเป็นผู้นำที่มีประสิทธิภาพยิ่งขึ้น",
    "virtualTeamResourcesDesc": "เรียนรู้เทคนิคในการเสริมสร้างวัฒนธรรมแห่งความร่วมมือและการยอมรับความแตกต่างภายในทีมเสมือนจริง",
    "jobFunctionsDesc": "ขยายขอบเขตความรู้ของหน้าที่ในงานปัจจุบันของคุณ และเรียนรู้เกี่ยวกับหน้าที่ในงานอื่นๆ",
    "brandsDesc": "เรียนรู้เพิ่มเติมเกี่ยวกับแบรนด์ที่หลากหลายของแมริออท อินเตอร์เนชั่นแนล",
    "languageLearningDesc": "สื่อสารอย่างมีประสิทธิภาพกับกลุ่มเป้าหมายทั่วโลก",
    "becomeDesc": "ค้นพบแนวทางที่สมบูรณ์สู่การเติบโตและการพัฒนาด้านอาชีพ ซึ่งได้แก่ สะท้อนความคิด วางแผน ดำเนินการ และขัดเกลา",
    "emeaMentoringProgramDesc": "ปลดล็อกศักยภาพของคุณด้วยโปรแกรมการฝึกสอน EMEA",
    "learningPathwaysDesc": "สำรวจโอกาสในการเรียนรู้ที่เฉพาะเจาะจงซึ่งคัดสรรมาเพื่อระดับความเป็นผู้นำของคุณ",
    "mdaDesc": "สร้างทักษะภาวะผู้นำและทักษะตามหน้าที่กับ Marriott Development Academy",
    "takeCareGrowDesc": "ค้นพบทรัพยากรที่ออกแบบมาเพื่อสร้างชุมชนที่ยอมรับความแตกต่างและปลุกแรงบันดาลใจ",
    "getAbstractDesc": "เรียนรู้สิ่งใหม่ๆ ในทุกวันด้วยสรุปหนังสือที่ช่วยให้คุณตัดสินใจได้ดีขึ้น",
    "cultureWizardDesc": "รวบรวมความรู้ที่มีค่าเพื่อสื่อสารระหว่างวัฒนธรรมต่างๆ อย่างมีประสิทธิภาพ",
    "takeCareCommunityHubDesc": "พบกับกิจกรรมการสร้างทีม โปรแกรมการเรียนรู้ และเครื่องมือต่างๆ ของ TakeCare",
    "searchAllLearning": "ค้นหาการเรียนรู้ทั้งหมด",
    "searchBarPlaceHolder": "มีสิ่งต่างๆ ให้เรียนรู้เพิ่มเติมอยู่เสมอ",
    "clearFilters": "ล้างตัวกรอง",
    "noResults": "ไม่พบผลลัพธ์",
    "hasBeenUnpinned": "ถอนหมุด {Variable-tile_Name} แล้ว",
    "hasBeenPinned": "ปักหมุด {Variable-tile_Name} แล้ว",
    "lpRemovedFromPlan": "ลบเส้นทางการเรียนรู้ออกจากแผนของคุณแล้ว",
    "courseRemovedFromPlan": "ลบหลักสูตรออกจากแผนของคุณแล้ว",
    "lpAddedToPlan": "เพิ่มเส้นทางการเรียนรู้ลงในแผนของคุณแล้ว",
    "courseAddedToPlan": "เพิ่มหลักสูตรลงในแผนของคุณแล้ว",
    "clearSelection": "ล้างรายการที่เลือก",
    "removeFromMyPlan": "ลบออกจากแผนของฉัน",
    "addToMyPlan": "เพิ่มลงในแผนของฉัน",
    "loadingResults": "กำลังโหลด",
    "viewDetails": "ดูรายละเอียด",
    "clearAll": "ล้างทั้งหมด",
    "noOptions": "ไม่มีตัวเลือกที่พร้อมใช้งาน",
    "viewLess": "ดูน้อยลง",
    "viewMore": "ดูเพิ่มเติม",
    "recentSearch": "การค้นหาล่าสุด",
    "exploreLearningTitle": "สำรวจการเรียนรู้",
    "exploreLearningHelpText": "ไม่แน่ใจว่าคุณกำลังมองหาอะไรใช่หรือไม่",
    "exploreMore": "สำรวจเพิ่มเติม",
    "viewAllResults": "ดูผลลัพธ์ทั้ง <#> รายการ",
    "searchSubTitle": "ค้นหาหลักสูตรโดยใช้คำสำคัญ ชื่อ หรือ ID เนื้อหา",
    "pinnedLearningTitle": "การเรียนรู้ที่ปักหมุดไว้ของฉัน",
    "landingSubTitle": "กำหนดเส้นทางการเรียนรู้ของคุณเอง สำรวจสิ่งที่คุณสนใจ โดยเราเพิ่มหัวข้อและหลักสูตรใหม่ๆ อยู่เสมอ",
    "columnTitle": "ชื่อหลักสูตร",
    "columnDesc": "คำอธิบาย",
    "columnType": "ประเภท",
    "columnAddedToPlan": "เพิ่มลงในแผน (แล้ว)",
    "columnComplete": "เสร็จสมบูรณ์",
    "columnDetails": "รายละเอียด",
    "additionalSearchFilters": "ตัวกรองการค้นหาเพิ่มเติม",
    "tourBtnClose": "ปิด",
    "tourBtnLast": "ปิด",
    "resultsFor": "ผลลัพธ์สำหรับ ",
    "resultsFortheFilter": "ผลลัพธ์สำหรับตัวกรอง {Filter Name}",
    "no": "ไม่",
    "results": "ผลลัพธ์",
    "courseTooltipTitle": "รายการเรียนรู้แบบเดี่ยวที่อาจประกอบด้วยกิจกรรมหนึ่งอย่างขึ้นไป<br />หลักสูตรอาจอยู่ในรูปแบบออนไลน์ เช่น วิดีโอหรืออีเลิร์นนิง หรืออาจเกิดขึ้นในห้องเรียน",
    "gatewayToolTipTitle": "ทรัพยากรดิจิทัลสำหรับพนักงานใหม่ บุคลากรที่เพิ่งรับบทบาทด้านการบริหาร และผู้จัดการทั่วไปที่เพิ่งเริ่มงานกับแบรนด์",
    "speciallizationOrPathwayTooltipTitle": "ชุดของหลักสูตรที่มุ่งเน้นในหัวข้อที่เฉพาะเจาะจง",
    "taskTooltipTitle": "คำแนะนำในการทำกิจกรรม (โดยทั่วไปคือขณะทำงานจริง) จากนั้นจึงทำเครื่องหมายว่าเสร็จสมบูรณ์แล้ว",
    "webSiteTooltipTitle": "เว็บไซต์ใดๆ ซึ่งรวมถึงเว็บไซต์ของแมริออทหรือเว็บไซต์ภายนอก"
  },
  "dontShowAgain": "ไม่ต้องแสดงข้อความนี้อีก",
  "deletionWarningEnabled": "คำเตือนการลบเปิดใช้งานอยู่",
  "deletionSelected": "เมื่อเลือกไว้ คุณจะเห็นคำเตือนในแต่ละครั้งที่ลบการแจ้งเตือน",
  "manageEmailNotifications": "จัดการกับการแจ้งเตือนทางอีเมล",
  "seeHowDLZContinue": "พบกับวิธีที่ DLZ สามารถช่วยให้คุณเติบโตได้อย่างต่อเนื่อง",
  "Notification Center:Overdue": "คุณมีการฝึกอบรมใหม่หนึ่งรายการที่ครบกำหนดในวันที่ {Variable date ddmmyy}",
  "accesDenied": {
    "title": "การเข้าถึงเส้นทางการเรียนรู้ของคุณอยู่ในระหว่างดำเนินการ! ",
    "subtitle": "ระบบกำลังสร้างการเข้าถึงของคุณและจะพร้อมใช้งานใน 1-2 วันหลังจากเปิดใช้งาน EID ของคุณแล้ว หากคุณรอมาเป็นเวลานานกว่า 2 วัน โปรดติดต่อศูนย์บริการ mHUB"
  },
  "jfTour": {
    "title": "ตอนนี้คุณสามารถดูและแก้ไขหน้าที่ในงานและความรับผิดชอบในงานเพิ่มเติม (AWR) ที่เลือกจาก \"แผนของฉัน\" ได้โดยตรง"
  },
  "onboardingPlan": {
    "requiredDescription": "ด้านล่างนี้คือหลักสูตรที่ต้องเรียนซึ่งต้องดำเนินการให้เสร็จสมบูรณ์ภายในช่วงเวลาที่คุณได้เลือกไว้ภายใต้ \"แผนของฉัน\" ร่วมมือกับหัวหน้าของคุณเพื่อกำหนดเวลาในการดำเนินการเรียนรู้ที่จำเป็นให้เสร็จสมบูรณ์ขณะที่คุณเดินหน้าในบทบาทใหม่ คุณจะได้เรียนรู้เกี่ยวกับหัวข้อที่หลากหลาย โปรดปรึกษาหัวหน้าและเพื่อนร่วมงานหากคุณมีคำถามใดๆ ในระหว่างทาง   ",
    "basicsDescription": "เราทราบดีว่ามีสิ่งต่างๆ ที่ต้องเรียนรู้มากมาย เราจึงคัดสรรเนื้อหาพื้นฐานบางส่วนมาให้คุณสำหรับการทำงานในช่วงแรกเริ่ม เนื้อหาเหล่านี้สามารถใช้เสริมการปฐมนิเทศ การเตรียมความพร้อมในการเข้าสู่แบรนด์ หรือข้อมูลจากบริษัทที่บริหารจัดการระบบแฟรนไชส์ของคุณได้ เนื้อหาเหล่านี้จะเป็นทรัพยากรที่ยอดเยี่ยมตลอดเส้นทางอาชีพของคุณกับเรา และเป็นประโยชน์เมื่อคุณรับสมาชิกใหม่เข้ามาร่วมทีม",
    "recommendedDescription": "หลังจากที่คุณทำงานครบ 90 วันแรก คุณจะเห็นหลักสูตรเพิ่มเติมที่ได้รับการแนะนำโดยผู้จัดการของคุณ นอกจากนี้ คุณยังสามารถสำรวจหัวข้อต่างๆ ที่คุณสนใจด้วยตนเองได้อีกด้วย สำหรับตอนนี้ ขอให้คุณมุ่งเน้นไปที่การเรียนรู้ขั้นพื้นฐาน ",
    "day1": "ครบกำหนดภายในวันที่ 14",
    "day2": "ครบกำหนดภายในวันที่ 30",
    "day3": "ครบกำหนดภายในวันที่ 60",
    "day4": "ครบกำหนดภายในวันที่ 90",
    "day5": "ครบกำหนดหลังจากผ่านไป 90 วัน "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone คือจุดเริ่มต้นของคุณสำหรับการเรียนรู้และการพัฒนาในสายอาชีพ ด้านล่างนี้คุณจะพบกับเนื้อหาที่คัดสรรมาเพื่อช่วยคุณในการเริ่มต้น คุณสามารถดูการเรียนรู้ทั้งหมดของคุณได้เสมอภายใต้ \"แผนของฉัน\"  ",
    "overallLearningProgress": "ความคืบหน้าในการเรียนรู้โดยรวม",
    "learningProgressLabel": "ความคืบหน้าในการเรียนรู้",
    "learningProgressDescription": "นี่คือความคืบหน้าในการเรียนรู้ที่จำเป็นของคุณ แถบความคืบหน้านี้ประกอบด้วยสถานะของการเรียนรู้ที่จำเป็นทั้งหมดของคุณ ขอให้คุณตั้งใจต่อไป!"
  },
  "poc": {
    "dlz": "เพื่อให้การเรียนรู้นี้เสร็จสมบูรณ์ ผู้จัดการหรือผู้ประสานงานด้านการเรียนรู้จะต้องทำเครื่องหมายว่าเสร็จสมบูรณ์ให้กับการเรียนรู้นี้ใน mHUB คุณสามารถดูรายละเอียดเกี่ยวกับวิธีการดำเนินการได้ในคู่มือปฏิบัติการ",
    "mhub": "ผู้รับสิทธิ์แฟรนไชส์: หากคุณเลือกที่จะใช้โปรแกรมการทำความสะอาดขั้นพื้นฐานของคุณเอง โปรดระบุชื่อโปรแกรมและรายละเอียดลงในส่วนความคิดเห็นเมื่อคุณทำเครื่องหมายว่าเสร็จสมบูรณ์"
  },
  "Recommended Courses ": "หลังจากที่คุณทำงานครบ 90 วันแรก คุณจะเห็นหลักสูตรเพิ่มเติมที่ได้รับการแนะนำโดยผู้จัดการของคุณ นอกจากนี้ คุณยังสามารถสำรวจหัวข้อต่างๆ ที่คุณสนใจด้วยตนเองได้อีกด้วย สำหรับตอนนี้ ขอให้คุณมุ่งเน้นไปที่การเรียนรู้ขั้นพื้นฐาน ",
  "survey": {
    "v2": {
      "weValueButtonText": "ทำแบบสำรวจ",
      "lbiIntro": "ยินดีต้อนรับสู่การตรวจสอบพฤติกรรมการใช้บริการลักซ์ชัวรี่!",
      "lbiDescription": `<p>เราจะให้คุณอ่านสถานการณ์สั้นๆ ด้านล่างนี้ที่คุณอาจพบได้ในที่พักของคุณ โปรดอ่านทุกสถานการณ์และเลือกว่าคุณจะตอบสนองต่อแต่ละสถานการณ์อย่างไร โปรดคำนึงถึงเรื่องต่อไปนี้เมื่อคุณตอบ:</p>
      <ul>
          <li>
              <p>คุณอาจพบว่าดูเหมือนว่าจะมีคำตอบที่ถูกต้องให้เลือกมากกว่าหนึ่งข้อ เลือกคำตอบที่คุณน่าจะดำเนินการมากที่สุดในที่ทำงาน</p>
          </li>
          <li>
              <p>สมมติว่าไม่มีอุปสรรคในการสื่อสาร ภาษาที่คุณพูดเป็นภาษาเดียวกับภาษาที่ลูกค้าหรือผู้ร่วมสนทนาในสถานการณ์นั้นใช้</p>
          </li>
          <li>
              <p>บางสถานการณ์อาจขอให้คุณจินตนาการว่าตัวเองอยู่ในบทบาทการทำงานที่ต่างไปจากบทบาทที่คุณเป็นอยู่ในปัจจุบัน ตอบคำถามเท่าที่คุณทราบ โดยพิจารณาจากประสบการณ์ของคุณในฐานะมืออาชีพด้านการให้บริการลักซ์ชัวรี่</p>
          </li>
      </ul>
      <p><br></p>
      <p>โปรดทราบ: การเข้าร่วมในการตรวจสอบนี้เป็นไปโดยสมัครใจทั้งสิ้น (คุณอาจเลือกที่จะไม่เข้าร่วมก็ได้) และคำตอบของคุณจะเก็บเป็นความลับอย่างเคร่งครัด ผลลัพธ์ของการตรวจสอบนี้จะจัดทำเป็นรายงานสำหรับกลุ่มบุคคล ไม่ใช่สำหรับเฉพาะบุคคล คำตอบส่วนบุคคลจะได้รับการประเมินโดยทีมวิจัยและจะไม่แบ่งปันกับผู้จัดการอื่นๆ หรือผู้นำ</p>
      <p>เมื่อทำการตรวจสอบนี้ จะถือว่าคุณยินยอมให้มีการเก็บรวบรวมและประมวลผลคำตอบและข้อมูลส่วนบุคคลของคุณ (เช่น อายุงาน เพศ ตำแหน่งงาน) รวมถึงการถ่ายโอนข้อมูลส่วนบุคคลของคุณข้ามพรมแดนไปยัง Marriott Group การดำเนินการนี้เป็นไปตามแถลงการณ์ความเป็นส่วนตัวสำหรับข้อมูลของพนักงาน Marriott ซึ่งคุณยังสามารถค้นหาข้อมูลเพิ่มเติมและข้อมูลติดต่อเพื่อใช้อ้างอิงได้ในนโยบายความเป็นส่วนตัวของข้อมูลของ Marriott</p>
      `,
      "checkin": {
        q1Title: "คุณกำลังเดินผ่านล็อบบี้และสังเกตเห็นครอบครัวหนึ่งที่มีลูกเล็กๆ สามคน ครอบครัวดังกล่าวมีกระเป๋าหลายใบ ดูเครียดจากการเดินทางมาทั้งวัน เด็กๆ งอแงและไม่อยู่นิ่งอย่างเห็นได้ชัด คุณจะทำอย่างไร",
        q2Title: "คุณเพิ่งจะเริ่มงานกับที่พักในพื้นที่ที่ใหม่สำหรับคุณ ลูกค้าขอคำแนะนำเกี่ยวกับกิจกรรมน่าสนใจในพื้นที่ ลูกค้าได้อ่านหนังสือคู่มือมาหลายเล่ม แต่กำลังมองหาสิ่งที่เหมาะกับตัวเองที่สุด คุณจะทำอย่างไร ",
        q3Title: "ขณะที่คุณกำลังเดินผ่านล็อบบี้ คุณได้ยินลูกค้ากำลังคุยโทรศัพท์ และได้ทราบว่าบริการรถไปส่งพวกเขาที่สนามบินอาจจะล่าช้า ทางที่พักของคุณก็ไม่มีรถให้บริการหรือมีแต่ไม่ว่าง คุณจะทำอย่างไร ",
        q4Title: "ขณะที่คุณกำลังเดินผ่านล็อบบี้เพื่อไปทำงานเร่งด่วนให้เสร็จตามที่ผู้จัดการมอบหมาย คุณสังเกตเห็นเพื่อนร่วมงานคนหนึ่งกำลังพยายามทำงานอย่างทุลักทุเลซึ่งโดยปกติแล้วจะต้องใช้พนักงานสองคน คุณจะทำอย่างไร ",
        q5Title: "คุณเป็นพนักงานต้อนรับที่ร้านอาหารของโรงแรม ลูกค้าขอดูเมนูขณะรอโต๊ะที่ได้จองไว้ หลังจากที่ดูเมนูเสร็จ ลูกค้ารู้สึกหงุดหงิดเพราะเพื่อนร่วมงานอีกคนเป็นคนแนะนำร้านอาหารของโรงแรม แต่ไม่มีเมนูใดที่พวกเขาอยากจะทาน คุณจะทำอย่างไร",
        q6Title: "มีลูกค้ากลุ่มใหญ่ในบริเวณสระว่ายน้ำที่อัดแน่นไปด้วยผู้คนที่ไม่สามารถหาที่นั่งด้วยกันได้ ด้วยเหตุนี้ พวกเขาจึงปูผ้าเช็ดตัวและของวางใช้ส่วนตัวในลักษณะที่กีดขวางทางเดินหลัก ก่อให้เกิดอันตรายและรบกวนลูกค้าและพนักงานที่พยายามจะเดินเข้าพื้นที่สระว่ายน้ำ ต้องทำการเคลียร์พื้นที่ แต่คุณไม่อยากทำให้กลุ่มลูกค้าไม่พอใจ คุณจะทำอย่างไร",
        q1R1Title: "ช่วยครอบครัวยกกระเป๋าเดินทาง บอกทางไปยังเคาน์เตอร์เช็คอินให้กับพวกเขา และแนะนำสถานที่ผ่อนคลายในบริเวณใกล้เคียงที่เหมาะสำหรับกิจกรรมของครอบครัว แนะนำสิ่งอำนวยความสะดวกสำหรับครอบครัวของที่พัก",
        q1R2Title: "เสนอช่วยลูกค้ายกกระเป๋าเดินทางและนำครอบครัวไปยังเคาน์เตอร์เช็คอิน จากนั้นให้บริการที่ช่วยให้ผ่อนคลายหรือดูแลพ่อแม่และจัดกิจกรรมสนุกๆ ให้กับเด็กๆ ในห้องของพวกเขา",
        q1R3Title: "แนะนำให้พ่อแม่ทำการเช็คอิน จากนั้นพาเด็กๆ ไปยังบริเวณที่นั่งพร้อมบริการเสิร์ฟของว่างเป็นการส่วนตัวในขณะที่พ่อแม่ทำการเช็คอิน ให้แผนกต้อนรับแจ้งให้พ่อแม่ทราบเกี่ยวกับพื้นที่และกิจกรรมสำหรับเด็กในบริเวณที่พักเพื่อมอบความสะดวกสบายให้กับพวกเขา",
        q1R4Title: "บอกทางไปยังเคาน์เตอร์เช็คอินแก่ครอบครัวสำหรับกระบวนการเช็คอินที่รวดเร็ว แนะนำพื้นที่สำหรับเด็กและบริการต่างๆ สำหรับพ่อแม่ เพื่อให้เด็กๆ ได้สนุกไปกับการเข้าพักที่มีความสุข ",
        q2R1Title: "สอบถามลูกค้าว่าพวกเขาชอบอะไร จากนั้นร่วมมือกับเพื่อนร่วมงานเพื่อจัดทำรายชื่อกิจกรรมสนุกๆ ให้กับพวกเขา นอกจากนี้ แนะนำร้านอาหารดีๆ ในบริเวณใกล้เคียงด้วย",
        q2R2Title: "พูดคุยกับลูกค้าเกี่ยวกับสิ่งที่พวกเขาชอบ ร่วมงานกับเพื่อนร่วมงานเพื่อจัดทำรายชื่อกิจกรรมพิเศษสนุกๆ สำหรับพวกเขาโดยเฉพาะ นอกจากนี้ ตรวจดูโปรไฟล์ Bonvoy ของลูกค้าและส่งขนมที่เป็นแบบส่วนตัวไปให้กับพวกเขาที่ห้อง",
        q2R3Title: "ใส่ความเป็นส่วนตัวด้วยการเล่าถึงสิ่งที่คุณชอบทำมากที่สุดในพื้นที่นี้ ทำให้ข้อเสนอของคุณมีความโดดเด่นด้วยการแบ่งปันรายละเอียดเพิ่มเติมเกี่ยวกับประสบการณ์ของคุณ จากนั้นส่งขนมพื้นเมืองอร่อยๆ ไปให้กับพวกเขาที่ห้อง",
        q2R4Title: "แจ้งให้ลูกค้าทราบว่าคุณยังใหม่ในพื้นที่นี้และยังไม่มีไอเดียดีๆ กล่าวคำขอโทษและแนะนำให้พวกเขาสอบถามเพื่อนร่วมงานของคุณที่รู้มากกว่า จากนั้นสอบถามว่าต้องการให้ส่งขนมพื้นเมืองไปให้ที่ห้องหรือไม่",
        q3R1Title: "ในขณะที่ลูกค้ายังคงคุยโทรศัพท์ ไปนำรถยนต์ส่วนตัวของคุณหรือของเพื่อนร่วมงานมาที่หน้าที่พัก และแจ้งให้ลูกค้าทราบว่าคุณจะพาพวกเขาไปส่งที่สนามบินเองเป็นการส่วนตัว",
        q3R2Title: "ในขณะที่ลูกค้ายังคงคุยโทรศัพท์ ทำการรวบรวมรายชื่อบริการรถที่ได้รับอนุมัติจากที่พักพร้อมหมายเลขโทรศัพท์ หลังจากที่ลูกค้าวางสาย เสนอรายชื่อดังกล่าวและเสนอที่จะช่วยจัดเตรียมการเดินทาง",
        q3R3Title: "รอจนกว่าลูกค้าจะวางสายและแจ้งให้ทราบว่าที่พักของคุณไม่มีบริการรถรับส่ง แต่คุณยินดีที่จะช่วยประสานงานตัวเลือกการเดินทางอื่นๆ ",
        q3R4Title: "ในขณะที่ลูกค้ายังคงคุยโทรศัพท์ ค้นหาบริการรถรับส่งรายอื่นสำหรับลูกค้า หลังจากที่ลูกค้าวางสาย แจ้งให้ลูกค้าทราบว่าคุณได้จัดเตรียมบริการรถรับส่งไปยังสนามบินให้แล้ว และรถจะมาถึงในไม่ช้า",
        q4R1Title: "เดินไปที่เพื่อนร่วมงานของคุณแล้วบอกว่าคุณจะช่วยเขา แต่คุณต้องทำงานเร่งด่วนให้เสร็จก่อน สัญญาว่าจะรีบกลับมาช่วยเหลือเขา อย่าลืมพูดคุยถึงสถานการณ์นี้กับผู้จัดการของคุณ",
        q4R2Title: "ขั้นแรก รีบไปวานเพื่อนร่วมงานคนอื่นมาช่วยทำงานที่คุณได้รับมอบหมายแทน จากนั้นช่วยเหลือเพื่อนร่วมงานของคุณที่ต้องการความช่วยเหลือทันที หลังจากที่ดำเนินการเสร็จ แจ้งผู้จัดการของคุณว่าคุณได้วานคนอื่นให้มาช่วยทำงานให้ เพื่อที่คุณจะได้ช่วยเหลือเพื่อนร่วมงานอีกคนได้ ",
        q4R3Title: "หยุดและช่วยเหลือเพื่อนร่วมงานของคุณทันที หลังจากที่ช่วยเสร็จ กลับไปทำงานที่ได้รับมอบหมาย จากนั้นแจ้งให้ผู้จัดการของคุณทราบว่าคุณได้พักงานไว้ระยะหนึ่งเพื่อช่วยเพื่อนร่วมงาน",
        q4R4Title: "เดินไปยังเพื่อนร่วมงานที่ต้องการความช่วยเหลือ และอธิบายว่าคุณกำลังมีงานเร่งด่วน หยุดงานทั้งสอง ระดมความคิดหาทางแก้ไขทั้งสองงานร่วมกัน จากนั้นทำงานทั้งสองอย่างให้เสร็จพร้อมกัน",
        q5R1Title: "ขอโทษลูกค้าและแจ้งให้ทราบว่าคุณจะไปแจ้งให้ผู้จัดการมาพบ อย่าลืมอธิบายสถานการณ์เพื่อให้ผู้จัดการสามารถช่วยลูกค้าในเรื่องอาหารที่ต้องการได้",
        q5R2Title: "ขอโทษลูกค้าและสอบถามเกี่ยวกับอาหารที่ลูกค้าชอบ พูดคุยกับทีมครัวเพื่อเตรียมอาหารจานพิเศษสำหรับลูกค้าโดยเฉพาะ เพิ่มเติมจากนี้คือเสิร์ฟอาหารเรียกน้ำย่อยหรือเครื่องดื่มฟรีแก่ลูกค้าเพื่อชดเชยความผิดพลาด",
        q5R3Title: "ขอโทษและบอกลูกค้าว่าคุณเข้าใจ แทนที่จะยกเลิกการจอง ให้เสนอที่จะพูดคุยกับหัวหน้าพ่อครัวและดูว่าพวกเขาสามารถทำอะไรให้กับลูกค้าเป็นพิเศษได้หรือไม่ มอบขนมฟรีหรือส่วนลดแก่ลูกค้าเพื่อชดเชยความผิดพลาด",
        q5R4Title: "กล่าวคำขอโทษและแจ้งลูกค้าว่าคุณสามารถช่วยจองโต๊ะในร้านอาหารใกล้เคียงที่มีอาหารที่พวกเขาชอบได้ ให้ข้อมูลเพิ่มเติมหรือความช่วยเหลือในการเดินทางไปที่นั่นหากจำเป็น",
        q6R1Title: "กล่าวคำขอโทษลูกค้าที่มีพื้นที่รองรับไม่เพียงพอและแจ้งลูกค้าว่าสามารถใช้พื้นที่ได้ตามสบาย ตราบใดที่ลูกค้าระมัดระวังลูกค้ารายอื่นที่เดินผ่านไปมา ",
        q6R2Title: "แจ้งต่อลูกค้าอย่างสุภาพว่าพวกเขาไม่สามารถกีดขวางทางเดินได้ และจะต้องย้ายไปจุดอื่นหรือรอจนกว่าจะมีที่ว่าง ในขณะเดียวกัน ขอให้เพื่อนร่วมงานของคุณนำที่นั่งเสริมจากห้องบอลรูมด้านนอกมาให้บริการแก่ลูกค้า ",
        q6R3Title: "แจ้งให้ลูกค้าทราบว่าไม่สามารถใช้พื้นที่ในตอนนี้ได้ และพวกเขาจะต้องย้ายไปยังที่อื่นชั่วคราวในขณะที่คุณหาที่นั่งเสริมมาให้บริการ เสนอพื้นที่ให้ลูกค้าใช้พื้นที่บาร์/ร้านอาหารในระหว่างนี้ ",
        q6R4Title: "ขอโทษลูกค้าสำหรับปัญหาเรื่องที่นั่ง สอบถามว่าสามารถวางของรวมกันเพื่อเผื่อทางเดินเล็กๆ ให้กับคนอื่นที่เดินผ่านไปมาได้หรือไม่ เสนอตัวให้ความช่วยเหลือแก่พวกเขา เพื่อให้ทุกคนรู้สึกพอใจ ส่งขนมพิเศษจากบาร์/ร้านอาหารไปให้กับลูกค้าเพื่อเป็นการขอโทษสำหรับความไม่สะดวก"
      }
    }
  },
};