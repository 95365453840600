export default {
  "banner": {
    "viewDetails": "Details anzeigen",
    "requiredCourses": "Ihre erforderlichen Kurse sind fällig in",
    "days": "Tage"
  },
  "header": {
    "plan": "Mein Plan",
    "manager": "MANAGER",
    "explore": "LERNEN ERKUNDEN",
    "reporting": "BERICHTERSTATTUNG",
    "myTeam": "MEIN TEAM",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "MEIN PLAN",
    "manager": "MANAGER",
    "explore": "LERNEN ERKUNDEN",
    "tagline": "Lernen leicht gemacht. Es beginnt bei Ihnen!"
  },
  "welcome": {
    "hi": "Hallo",
    "intro1": "Herzlich willkommen in der Digital Learning Zone! Die DLZ ist eine personalisierte Lernplattform und die zentrale Anlaufstelle für das Lernen bei Marriott. Finden Sie die vorgeschriebene Schulung für Ihre Rolle und erkunden Sie die Lernmöglichkeiten für Ihre persönliche und berufliche Entwicklung. Gestalten Sie Ihre Lernerfahrung und entwickeln Sie Ihre Fähigkeiten.",
    "intro2": "Bevor wir beginnen, möchten wir mehr über Sie erfahren.",
    "letsGo": "AUF GEHT’S!",
    "preferredLanguage": "Welche Sprache bevorzugen Sie?",
    "step": "SCHRITT",
    "of": "VON",
    "previous": "ZURÜCK",
    "next": "WEITER",
    "startDate": "Seit wann arbeiten Sie bei Marriott International oder Starwood?",
    "selectYear": "JAHR AUSWÄHLEN",
    "selectMonth": "MONAT AUSWÄHLEN",
    "selectDay": "DATUM AUSWÄHLEN",
    "selectFunction": "VERANSTALTUNG AUSWÄHLEN",
    "selectInterests": "INTERESSEN AUSWÄHLEN",
    "chooseYear": "JAHR AUSWÄHLEN",
    "chooseMonth": "MONAT AUSWÄHLEN",
    "chooseDay": "TAG AUSWÄHLEN",
    "whatDo": "Welche Funktion haben Sie?",
    "selected": "AUSGEWÄHLT",
    "searchFunctions": "Alle Funktionen durchsuchen",
    "searchInterests": "Alle Interessen durchsuchen",
    "additionalJobFunctionsLabel": "Sagen Sie uns mehr über Ihre Tätigkeiten",
    "managementLevelLabel": "Was ist Ihr Mitarbeiter-Level?",
    "preferredBrandLabel": "Bitte wählen Sie eine Marke, falls Sie für mehr als eine Marke arbeiten.",
    "additionalJobFunctionsLabelExtra": "Bitte lesen Sie Folgendes und WÄHLEN SIE MINDESTENS EINE zusätzliche Verantwortlichkeit aus.",
    "searchAdditionalFunctions": "Zusätzliche Verantwortlichkeiten",
    "highGuestContactLabel": "Sind Sie ein Mitarbeiter mit häufigem Gästekontakt?",
    "yes": "Ja",
    "no": "Nein "
  },
  "landing": {
    "started": "Packen wir’s also an",
    "welcomeBack": "Willkommen zurück,",
    "greatWork": "Großartig!",
    "requiredCourses": "Ihre erforderlichen Kurse",
    "requiredCoursesDesc": "Bitte schließen Sie die Kurse vor dem Fälligkeitstermin ab.",
    "myPlan": "Mein Plan:",
    "courses": "Kurse",
    "courseComplete": "KURS ABGESCHLOSSEN",
    "viewMore": "MEHR ERFORDERLICHE KURSE ANZEIGEN",
    "viewMoreBtn": "MEHR KURSE ANZEIGEN",
    "showing": "WIRD ANGEZEIGT",
    "of": "VON",
    "heroTitle": "Etwas Neues erlernen Befähigen Sie sich. Erlernen Sie Fähigkeiten, die Sie benötigen.",
    "heroParagraph1": "Sie können alle erforderlichen Kurse auf einfache Weise verfolgen und erhalten Echtzeit-Erinnerungen, wann sie abzuschließen sind.",
    "heroParagraph2": "Maßgeschneidertes Lernen, angepasst an Ihre Tätigkeit und Ihre Interessen.",
    "recommendedCoursesTitle": "Empfohlene Kurse",
    "recommendedCoursesDescNoContent": "Sie haben derzeit keine empfohlenen Kurse. Im Folgenden finden Sie weitere Lernmöglichkeiten.",
    "recommendedCoursesDesc": "Diese Kurse hat Ihr HR-Spezialist für Sie ausgewählt.",
    "recommendedCoursesBtn": "EMPFOHLENE KURSE AUSWÄHLEN",
    "completedCoursesBtn": "ABGESCHLOSSENE KURSE ANZEIGEN",
    "exploreTitle": "Bleiben Sie neugierig.",
    "exploreDesc": "Wählen Sie Themen aus, die Sie interessieren.",
    "jobFunctions": "Disziplinarische Maßnahmen",
    "growthSkills": "Fähigkeiten verbessern",
    "leadershipSkills": "Führung verbessern",
    "brands": "Marken",
    "languageLearning": "Sprachkurse",
    "exploreBtn": "Neue Kurse",
    "managerRecommendation": "MANAGEREMPFEHLUNGEN",
    "learningRecommendation": "LERNEMPFEHLUNGEN",
    "marriottRecommendation": "EMPFEHLUNGEN VON MARRIOTT",
    "completedCourses": "Sie haben alle erforderlichen Kurse abgeschlossen!",
    "investTitle": "Ihre eigene Lernerfahrung",
    "investParagraph1": "Wir sind uns bewusst, dass die aktuellen Umstände in vielerlei Hinsicht herausfordernd sind - die DLZ ist gerade jetzt eine wunderbare Ressource. Ganz gleich, ob Sie Ihre Fähigkeiten aktualisieren oder etwas Neues lernen wollen, Ihren Stress managen oder lernen möchten, in einem virtuellen Team zu arbeiten. Wir haben Ressourcen gesammelt, um Sie zu unterstützen.",
    "updatedTopics": "Aktualisierte Themen",
    "workingRemotely": "Virtuelles Team - Ressourcen",
    "relatedCoursesNew": "Aktualisieren Sie Ihre Interessen durch einen Klick unten, da wir neue Themen und Kurse hinzugefügt haben.",
    "relatedCourses": "Bitte klicken Sie unten Ihre Interessen an, um diese auszuwählen.",
    "notificationText": "Neue mHUB-Schulung verfügbar. Wählen Sie mHUB in Explore Learning.",
    "mHubHeroTitle1": "Simpel. Smart. Sicher. Lernen Sie mHUB kennen.",
    "mHubHeroManagedText1": "mHUB integriert Tools zum Lernen, zur Laufbahnentwicklung, für Explore-Rabatte und mehr auf einer anwenderfreundlichen Plattform.",
    "mHubHeroFranchisedText1": "mHUB integriert Tools zum Lernen, für Explore-Rabatte und mehr auf einer anwenderfreundlichen Plattform.",
    "mHubHeroVisitLink": "Klicken Sie hier, um weitere Angebotsdetails oder Anhänge zu sehen.",
    "myGateway": "My Gateway",
    "gatewayReview": "Bitte überprüfen und abschließen.",
    "gatewayProgress": "Fortschritt in Gateway",
    "complete": "ABGESCHLOSSEN",
    "reviewContent": "Ressourcen anzeigen",
    "gatewayCardTitle": "Ressourcen in Gateway",
    "gatewayCardDesc": "Greifen Sie auf die Grundlagen für das erste Jahr und darüber hinaus zu.",
    "gatewayCardDescGms": "Greifen Sie auf Ihre Grundlagen, Maßnahmen, Markenressourcen und Ressourcen nach Disziplin im ersten Jahr und darüber hinaus zu.",
    "gatewayCardDescNew": "Greifen Sie auf Ihre Grundlagen für das erste Jahr in Ihrer neuen Rolle zurück.",
    "gatewayCardDescGmsNew": "Greifen Sie auf Ihre Grundlagen, Maßnahmen, Markenressourcen und Ressourcen nach Disziplin im ersten Jahr in Ihrer neuen Rolle und darüber hinaus zu."
  },
  "courseBundle": {
    "completeBy": "ABGESCHLOSSEN VON",
    "remainingCourses": "Verbleibende Kurse",
    "completedCourses": "Abgeschlossene Kurse",
    "remainingLabel": "In diesem Lernweg sind {courseCount} Kurse vorhanden.",
    "recommendButton": "Empfehlungen für das Team",
    "startCourse": "Kurs starten",
    "durationMins": "MINUTEN",
    "completed": "Abgeschlossen",
    "back": "Zurück",
    "additionalLangs1": "Diese Kurse werden in Englisch, aber auch in folgenden Sprachen angeboten",
    "additionalLangs2": "Wählen Sie nach dem Start des Kurses im Drop-Down-Menü Ihre bevorzugte Sprache.",
    "targetAudience": "Zielpublikum",
    "all": "Alle",
    "globally": "weltweit",
    "source": "Quelle",
    "courseType": "Kurstyp",
    "course": "Kurs"
  },
  "profile": {
    "save": "Speichern",
    "edit": "Bearbeiten",
    "brand": "Marke",
    "brands": "Marken",
    "additionalWork": "Alle zusätzlichen Verantwortlichkeiten",
    "startDateLabel": "Seit wann arbeiten Sie bei Marriott International oder Starwood?",
    "preferredLanguageLabel": "Welche Sprache bevorzugen Sie?",
    "jobFunctionsLabel": "Was ist dabei Ihre Rolle?",
    "interestsLabel": "„Was möchten Sie zuerst machen?“",
    "brandsLabel": "Für welche Marken sind Sie tätig?",
    "additionalJobFunctionsLabel": "Welche zusätzlichen Arbeitsaufgaben erfüllen Sie?",
    "managementLevelLabel": "Was ist Ihr Mitarbeiter-Level?",
    "highGuestContactLabel": "Sind Sie ein Mitarbeiter mit häufigem Gästekontakt?"
  },
  "courseDetails": {
    "completeBy": "ABZUSCHLIESSEN BIS",
    "recommendTitlte": "Kursempfehlungen für Ihr Team",
    "courseName": "Kursname",
    "sendRecommendation": "Empfehlung senden"
  },
  "explore": {
    "relatedCourses": "Hier finden Sie Kurse für Ihren Interessenbereich.",
    "chooseInterests": "Ihre Interessen auswählen",
    "title": "Lernen erkunden",
    "chooseInterestsModal": "Interessen auswählen",
    "oppsTitle": "Lernmöglichkeiten erkunden",
    "growWithUsTitle": "Starten Sie mit uns durch",
    "growWithUsDesc": "Marriott glaubt daran, dass Ihre Karriere mit Ihnen wächst. Wir glauben ebenfalls, dass breitgefächerte Erfahrungen ein positives Gesamtbild zeichnen.",
    "growWithUsBtn": "STARTEN SIE MIT UNS DURCH",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "Marriott erkennt Erfolg und Engagement an. Wir prämieren und fördern Führungsqualitäten und außergewöhnliche Leistungen. Wir bieten außerdem Boni für erfolgreiche Empfehlungen und belohnen die Unternehmenstreue unserer Mitarbeiter.",
    "mdaBtn": "MARRIOTT DEVELOPMENT ACADEMY ANZEIGEN",
    "selectInterests": "Welche Themen interessieren Sie?",
    "otherRecommended": "Andere empfohlene Kurse",
    "searchByName": "Nach Interessensgebieten suchen"
  },
  "plan": {
    "completedRequired": "Sie haben alle erforderlichen Kurse abgeschlossen!",
    "requiredCourses": "Erforderliche Kurse",
    "reqDesc": "Bitte schließen Sie die Kurse rechtzeitig ab.",
    "complete": "Abschließen",
    "of": "von",
    "courses": "Kurse",
    "completedCourses": "Abgeschlossene Kurse",
    "completedDesc": "Herzlichen Glückwunsch! Sie haben in letzter Zeit die folgenden Kurse abgeschlossen.",
    "remaining": "Verbleibende Kurse",
    "myLearningTitle": "Mein zusätzliches Lernen ",
    "myLearningSubTitle": "Elemente, die ich zu Meinem Plan hinzugefügt habe",
    "history": "Verlauf",
    "myLearningHistoryLinkText": "Meinen vollständigen Verlauf anzeigen",
    "jf": "Ihre Funktion(en)",
    "awr": "Ihre zusätzlichen Arbeitspflichten (AWR)",
    "jfAwrDesc": "Wichtig: Von der Funktion und den AWR ist abhängig, welche Pflichtschulungen Ihnen in Mein Plan zugewiesen werden. ",
    "jfAwrEdit": "BEARBEITEN Sie Ihr Profil",
    "jfAwrEnd": "und aktualisieren Sie es.",
    "tourPlan": "„Mein Plan“ enthält alle Ihre erforderlichen und empfohlenen Kurse, die bis zum Fälligkeitsdatum abgeschlossen sein müssen",
    "tourBasics": "Marriott Basics umfasst Ressourcen, mit deren Hilfe Sie Ihre Marriott-Reise antreten können und die Ihnen während Ihrer gesamten Laufbahn zur Verfügung stehen. Marriott Basics sind eine Ergänzung zu Ihren Lerninhalten in „Mein Plan“.",
    "noCourses": "Sie haben zur Zeit alle verpflichtenden Lerninhalte abgeschlossen, können sich aber gerne schon über die anstehenden Lerninhalte informieren und sich auf sie vorbereiten"
  },
  "team": {
    "title": "Ihr Team verwalten",
    "subtitle": "Verbessern Sie Ihre Fähigkeiten und entwickeln Sie sich beruflich weiter.",
    "functions": "Funktionen",
    "viewTeam": "Team anzeigen",
    "allCourses": "Alle Kurse",
    "downloadExcel": "Excel-Bericht herunterladen"
  },
  "feedbackButton": {
    "havingTrouble": "Anwendungsvorschläge"
  },
  "recommendation": {
    "dismiss": "Sind Sie sicher, dass Sie diese Empfehlung ablehnen möchten?"
  },
  "table": {
    "filterTable": "फ़िल्टर टेबल"
  },
  "notifications": {
    "addAssignment2": "Eine Aufgabe %{assignment_type} für %{assignment_name} wurde zu Ihrem %{page location} hinzugefügt und ist fällig am %{due}.",
    "comingDueAssignment": "Ihre Aufgabe %{assignment_type} für %{assignment_name} ist bald fällig, und zwar am %{due}.",
    "overdueAssignment": "Ihre Aufgabe %{assignment_type} für %{assignment_name} ist jetzt überfällig.",
    "addAssignment": "Eine Aufgabe %{assignment_type} für %{assignment_name} wurde zu Ihrem %{page location} hinzugefügt.",
    "profile": "Es wurde eine Aktualisierung Ihrer Stellenbezeichnung oder Ihres Standorts festgestellt. Bitte überprüfen Sie Ihr Profil und nehmen Sie alle erforderlichen Aktualisierungen für die Marke, die Funktion und zusätzliche Aufgaben vor. Ein genaues DLZ-Profil stellt sicher, dass Sie die richtigen Schulungsaufgaben erhalten.",
    "helptip": "Alle Benachrichtigungen laufen nach 30 Tagen ab.",
    "markRead": "Als gelesen markieren",
    "delete": "Löschen",
    "markAllRead": "Alle als gelesen markieren",
    "settings": "Einstellungen",
    "tooltip": "Gelesene Benachrichtigungen werden nach 30 Tagen gelöscht",
    "reportComplete": "Ihr DLZ-Bericht wurde erstellt. Zum Herunterladen klicken",
    "dontShowThisAgain": "Nicht mehr anzeigen",
    "warningEnabled": "Die Löschwarnung wurde aktiviert.",
    "manageEmailNotifications": "E-Mail-Benachrichtigungen verwalten",
    "warningTooltip": "Wenn ausgewählt, wird eine Warnung angezeigt, wenn Sie eine Benachrichtigung löschen.",
    "changeEmailManagedTooltip": "Um Ihre E-Mail-Adresse zu ändern, besuchen Sie bitte mHUB > HR Direct > Ich > Meine öffentlichen Informationen. Folgen Sie den Anweisungen, um Ihre Kontaktdaten zu aktualisieren. Es dauert ein bis zwei Werktage, bis die aktualisierte E-Mail-Adresse angezeigt wird.",
    "changeEmailFranchisedTooltip": "Um Ihre E-Mail-Adresse zu ändern, kontaktieren Sie bitte Ihre(n) Vorgesetzte(n) und/oder Ihre(n) Franchise EID Tool Administrator(in). Es dauert ein bis zwei Werktage, bis die aktualisierte E-Mail-Adresse angezeigt wird.",
    "deleteAllNotifications": "Alle Benachrichtigungen löschen",
    "deleteIndividualHeader": "Benachrichtigung löschen",
    "deleteAllConfirmation": "Sind Sie sicher, dass Sie alle Benachrichtigungen löschen möchten? Sie werden in den Abschnitt „Gelöscht“ des Benachrichtigungszentrums verschoben, bis sie ablaufen.",
    "deleteIndividualConfirmation": "Soll diese Benachrichtigung wirklich gelöscht werden? Gelöschte Benachrichtigungen werden in den Abschnitt „Gelöscht“ des Benachrichtigungszentrums verschoben, bis sie ablaufen.",
    "permanentDeleteIndividiualConfirmation": "Sind Sie sicher, dass Sie diese Benachrichtigung dauerhaft löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    "permanentDeleteAllConfirmation": "Sind Sie sicher, dass Sie alle Benachrichtigungen dauerhaft löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    "notificationDeleted": "Benachrichtigung wurde gelöscht.",
    "notificationsDeleted": "Benachrichtigungen wurden gelöscht.",
    "notificationRestored": "Benachrichtigung wurde wiederhergestellt.",
    "notificationsRestored": "Benachrichtigungen wurden wiederhergestellt.",
    "enableDeletionWarning": "Löschwarnung aktiviert",
    "warningDisabled": "Die Löschwarnung wurde deaktiviert.",
    "viewAll": "Alle anzeigen",
    "deleteAll": "Alle löschen",
    "notificationCenterTitle": "Benachrichtigungszentrum",
    "markAsRead": "Als gelesen markieren",
    "noDeletedNotifications": "Sie sind auf dem neuesten Stand!",
    "restore": "Wiederherstellen",
    "restoreAll": "Alle wiederherstellen",
    "viewCourse": "Kurs ansehen",
    "title": "Benachrichtigungen",
    "noNewNotifications": "Sie haben keine Benachrichtigungen.",
    "cancel": "Abbrechen",
    "manageNotificationsCopy": "E-Mail-Benachrichtigungen verwalten\n\nWählen Sie aus, ob Sie wöchentliche E-Mails mit einer Zusammenfassung Ihrer überfälligen Lerninhalte erhalten möchten. Wählen Sie „Verwalten“ aus, um Ihre Einstellungen zu ändern."
  },
  "transcript": {
    "history": "Mein Verlauf",
    "completed": "Abgeschlossene Aufgaben",
    "assignmentName": "Name der Aufgabe",
    "type": "Art",
    "viewAssignment": "Aufgabe anzeigen",
    "assignmentSource": "Quelle der Aufgabe",
    "requiredAssignments": "Erforderliche Aufgaben",
    "recommendedAssignments": "Empfohlene Aufgaben",
    "voluntary": "Freiwillig",
    "trasscript": "Transkript",
    "retraining": "Erneut lernen",
    "completionDate": "Abschlussdatum",
    "assignmentType": "Art der Aufgabe",
    "contentType": "Inhaltstyp",
    "contentId": "Inhalts-ID"
  },
  "contentLaunch": {
    "review": "Lernen prüfen",
    "start": "Mit dem Lernen beginnen"
  },
  "survey": {
    "weValue": "Wir würden uns freuen, von Ihnen zu hören.",
    "weValueDescription": "Bitte beantworten Sie fünf kurze Fragen zu Ihren Erfahrungen mit der Arbeit in Ihrem Hotel.",
    "weValueButtonText": "An der Umfrage teilnehmen",
    "weValueG1Q1A1": "Ja",
    "weValueG1Q1A2": "Nein",
    "strongAgree": "Stimme voll zu",
    "agree": "Stimme zu",
    "slightAgree": "Stimme teilweise zu",
    "slightDisagree": "Stimme teilweise nicht zu",
    "disagree": "Stimme nicht zu",
    "strongDisagree": "Stimme definitiv nicht zu",
    "weValueG1Q1Title": "Haben Sie bei Ihrer letzten Schicht an einer täglichen Besprechung teilgenommen? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments oder Daily EDITION)",
    "weValueG1Q2Title": "Die tägliche Besprechung (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments oder Daily EDITION) inspiriert mich jeden Tag erneut, einen luxuriösen Service zu erbringen.",
    "weValueG1Q3Title": "Meine Abteilung konzentriert sich darauf, Mängel zu reduzieren, auch solche, die nicht von den Gästen gemeldet werden.",
    "weValueG1Q4Title": "Ich bin in der Lage, Probleme von Gästen zu lösen, ohne erst um Erlaubnis fragen zu müssen.",
    "weValueG1Q5Title": "Ich bin in der Lage, Gästen eine Freude zu machen, ohne erst um Erlaubnis fragen zu müssen.",
    "weValueG2Q1Title": "Ich erlebe in meinem Hotel regelmäßig, wie Teammitglieder über sich hinauswachsen, um die Gäste durch ihre Betreuung zu beeindrucken.",
    "weValueG2Q2Title": "Meine Ausbildung in dieser Abteilung hat mich in die Lage versetzt, einen luxuriösen Service zu erbringen.",
    "weValueG2Q3Title": "Ich bin zuversichtlich, dass ich mit meinem Wissen und meinen Fähigkeiten einen luxuriösen Service bieten kann.",
    "weValueG2Q4Title": "Wir sorgen in meiner Abteilung für eine luxuriöse, saubere und mängelfreie Umgebung.",
    "weValueG2Q5Title": "Selbst wenn ich auf ein Problem treffe, das nichts mit meiner Abteilung zu tun hat, bin ich befugt, einzugreifen und mich der Anliegen der Gäste anzunehmen.",
    "weValueG3Q1Title": "In meinem Hotel erhalten die Teammitglieder das gleiche Maß an Zuwendung wie die Gäste.",
    "weValueG3Q2Title": "Meine Abteilung verkörpert die Werte der Marke.",
    "weValueG3Q3Title": "Wenn ich mehr als das Erwartete leiste, um den Gästen ein luxuriöses Erlebnis zu bieten, wird meine Arbeit anerkannt und geschätzt.",
    "weValueG3Q4Title": "Ich bin an den Bemühungen unserer Abteilung beteiligt, unsere Arbeit zu verbessern.",
    "weValueG3Q5Title": "In meiner Abteilung steht der Gast immer an erster Stelle.",
    "submitButton": "Senden",
    "phasetwo": {
      "weValueG1Q1Title": "Haben Sie bei Ihrer letzten Schicht an einer täglichen Besprechung teilgenommen? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments  oder Daily EDITION)?",
      "weValueG1Q2Title": "Die tägliche Besprechung (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments oder Daily EDITION) inspiriert mich jeden Tag erneut, einen luxuriösen Service zu erbringen.",
      "weValueG1Q3Title": "Mein Hotel konzentriert sich darauf, Mängel zu reduzieren, auch solche, die nicht von Gästen gemeldet werden.",
      "weValueG1Q4Title": "In meinem Hotel sind wir in der Lage, Probleme von Gästen zu lösen, ohne erst um Erlaubnis fragen zu müssen.",
      "weValueG1Q5Title": "In meinem Hotel sind wir in der Lage, Gästen eine Freude zu machen, ohne erst um Erlaubnis fragen zu müssen.",
      "weValueG2Q1Title": "In meinem Hotel wachsen die Teammitglieder regelmäßig über sich hinaus, um die Gäste durch unsere Betreuung zu beeindrucken.",
      "weValueG2Q2Title": "Die Lerngelegenheiten in dem Hotel haben uns in die Lage versetzt, einen luxuriösen Service zu erbringen.",
      "weValueG2Q3Title": "In unserem Hotel sind wir zuversichtlich, dass wir mit unserem Wissen und unseren Fähigkeiten einen luxuriösen Service bieten können.",
      "weValueG2Q4Title": "Wir sorgen in meinem Hotel für eine luxuriöse, saubere und mängelfreie Umgebung.",
      "weValueG2Q5Title": "Selbst wenn wir auf ein Problem treffen, das nichts mit unserer eigenen Abteilung zu tun hat, sind wir befugt, einzugreifen und uns der Anliegen der Gäste anzunehmen.",
      "weValueG3Q1Title": "In meinem Hotel kümmern wir uns um uns gegenseitig genauso gut, wie wir uns um unsere Gäste kümmern.",
      "weValueG3Q2Title": "Mein Hotel verkörpert die Werte der Marke.",
      "weValueG3Q3Title": "Wenn wir in meinem Hotel mehr als das Erwartete leisten, um den Gästen ein luxuriöses Erlebnis zu bieten, wird unsere Arbeit anerkannt und geschätzt.",
      "weValueG3Q4Title": "Wir haben in meinem Hotel die Gelegenheit, uns an den Bemühungen unserer Abteilung zu beteiligen, unsere jeweilige Arbeit zu verbessern.",
      "weValueG3Q5Title": "In meinem Hotel steht der Gast für uns immer an erster Stelle."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} ABGESCHLOSSEN",
    "gatewayProgressComplete": "GATEWAY FORTSCHRITT ABGESCHLOSSEN",
    "heroMobileGatewayVideoText": "Gateway-Video abspielen",
    "basics": "GRUNDLAGEN",
    "brandResources": "MARKENRESSOURCEN",
    "disciplineResources": "RESSOURCEN DER DISZIPLIN",
    "immersionResourcesHeader": "Ressourcen zur Immersion",
    "brandCommunicationsHeaader": "Markenkommunikation",
    "brandContactsHeader": "Markenkontakte",
    "franchisedActionItemsText": "Maßnahmen helfen Ihnen, organisiert zu bleiben, und sie enthalten wichtige Onboarding-Informationen. Dies sind ergänzende Punkte zu den Informationen, die Sie von Ihrem Franchise-Management-Unternehmen erhalten, und nicht obligatorisch.",
    "global": "GLOBALE",
    "continent": "KONTINENT",
    "viewed": "ANGESEHEN",
    "completed": "ABGESCHLOSSEN",
    "heading": "Gateway",
    "basicsTab": "DIE GRUNDLAGEN",
    "onboardingTab": "Maßnahmen",
    "brandTab": "Markenressourcen",
    "disciplinesTab": "RESSOURCEN NACH DISZIPLIN",
    "basicsHeader": "Herzlich willkommen bei Marriott International",
    "basicsHeaderNewBrandOrRole": "Herzlich willkommen,",
    "basicsText": "Wir wissen, dass für Sie vieles neu ist, wenn Sie Ihre Karriere beginnen. Wir haben grundlegende Kenntnisse für die ersten Tage in Ihrer Funktion für Sie ausgewählt. Einige dieser Punkte werden möglicherweise in den Orientierungen oder beim Marken-Onboarding vertieft, aber mit diesen Grundlagen können Sie beginnen.",
    "basicsTextGms": "Wir wissen, dass für Sie vieles neu ist, wenn Sie Ihre Karriere beginnen. Wir haben grundlegende Kenntnisse für die ersten Tage in Ihrer Funktion für Sie ausgewählt. Einige dieser Punkte werden möglicherweise in den Orientierungen oder beim Marken-Onboarding vertieft, aber mit diesen Grundlagen können Sie beginnen. Wenn Sie sie abgeschlossen haben, arbeiten Sie die nächsten Gateway-Seiten durch, einschließlich Maßnahmen, Markenressourcen und Ressourcen nach Disziplin.",
    "basicsTextFranchise": "Wir wissen, dass für Sie vieles neu ist, und wir haben einige der Grundlagen für die ersten Tage in dieser Funktion ausgewählt. Diese Punkte sind möglicherweise eine Ergänzung Ihrer Orientierungen, des Marken-Onboardings und der Informationen Ihres Franchise-Management-Unternehmens.",
    "basicsTextFranchiseGms": "Wir wissen, dass für Sie vieles neu ist, und wir haben einige der Grundlagen für die ersten Tage in dieser Funktion ausgewählt. Diese Punkte sind möglicherweise eine Ergänzung Ihrer Orientierungen, des Marken-Onboardings und der Informationen Ihres Franchise-Management-Unternehmens. Wenn Sie sie abgeschlossen haben, arbeiten Sie die nächsten Gateway-Seiten durch, einschließlich Maßnahmen, Markenressourcen und Ressourcen nach Disziplin.",
    "basicsTextNewToRole": "Wir wissen, dass für Sie vieles neu ist, wenn Sie in Ihrer neuen Rolle beginnen. Wir haben grundlegende Kenntnisse für die ersten Tage in Ihrer Funktion für Sie ausgewählt. Einige Punkt werden vertieft, wenn Ihnen eine Schulung zugewiesen wird, aber mit diesen Grundlagen können Sie beginnen.",
    "basicsTextNewToRoleGms": "Wir wissen, dass für Sie vieles neu ist, wenn Sie in Ihrer neuen Rolle beginnen. Wir haben grundlegende Kenntnisse für die ersten Tage in Ihrer Funktion für Sie ausgewählt. Einige Punkt werden vertieft, wenn Ihnen eine Schulung zugewiesen wird, aber mit diesen Grundlagen können Sie beginnen. Wenn Sie sie abgeschlossen haben, arbeiten Sie die nächsten Gateway-Seiten durch, einschließlich Maßnahmen, Markenressourcen und Ressourcen nach Disziplin.",
    "basicsTextNewToRoleFranchise": "Wir wissen, dass für Sie vieles neu ist, und wir haben einige der Grundlagen für die ersten Tage in Ihrer neuen Rolle ausgewählt. Diese Punkte sind möglicherweise eine Ergänzung der Informationen Ihres Franchise-Management-Unternehmens.",
    "basicsTextNewToRoleFranchiseGms": "Wir wissen, dass für Sie vieles neu ist, und wir haben einige der Grundlagen für die ersten Tage in Ihrer neuen Rolle ausgewählt. Diese Punkte sind möglicherweise eine Ergänzung der Informationen Ihres Franchise-Management-Unternehmens. Wenn Sie sie abgeschlossen haben, arbeiten Sie die nächsten Gateway-Seiten durch, einschließlich Maßnahmen, Markenressourcen und Ressourcen nach Disziplin.",
    "basicsTextNewToBrand": "Wir wissen, dass vieles neu ist, wenn Sie Ihre neue Rolle bei %{brandName} beginnen. Wir haben grundlegende Kenntnisse für die ersten Tage in Ihrer Funktion für Sie ausgewählt. Einige dieser Punkte werden möglicherweise in den Orientierungen oder beim Marken-Onboarding vertieft, aber mit diesen Grundlagen können Sie beginnen.",
    "basicsTextNewToBrandGms": "Wir wissen, dass vieles neu ist, wenn Sie Ihre neue Rolle bei %{brandName} beginnen. Wir haben grundlegende Kenntnisse für die ersten Tage in Ihrer Funktion für Sie ausgewählt. Einige dieser Punkte werden möglicherweise in den Orientierungen oder beim Marken-Onboarding vertieft, aber mit diesen Grundlagen können Sie beginnen. Wenn Sie sie abgeschlossen haben, arbeiten Sie die nächsten Gateway-Seiten durch, einschließlich Maßnahmen, Markenressourcen und Ressourcen nach Disziplin.",
    "basicsTextNewToBrandFranchise": "Wir wissen, dass für Sie vieles neu ist, und wir haben einige der Grundlagen für die ersten Tage in Ihrer neuen Rolle ausgewählt. Diese Punkte sind möglicherweise eine Ergänzung Ihrer Orientierungen, des Marken-Onboardings und der Informationen Ihres Franchise-Management-Unternehmens.",
    "basicsTextNewToBrandFranchiseGms": "Wir wissen, dass für Sie vieles neu ist, und wir haben einige der Grundlagen für die ersten Tage in Ihrer neuen Rolle bei %{brandName} ausgewählt. Diese Punkte sind möglicherweise eine Ergänzung Ihrer Orientierungen, des Marken-Onboardings und der Informationen Ihres Franchise-Management-Unternehmens. Wenn Sie sie abgeschlossen haben, arbeiten Sie die nächsten Gateway-Seiten durch, einschließlich Maßnahmen, Markenressourcen und Ressourcen nach Disziplin.",
    "disciplineHeader": "Ressourcen nach Disziplin",
    "toDo": "AUFGABEN",
    "optional": "OPTIONAL",
    "overallComplete": "INSGESAMT ABGESCHLOSSEN",
    "keyResourcesDesc": "Erfahren Sie mehr über die wichtigsten Disziplinen, die täglichen Betrieb, Gästeerlebnis und geschäftliche Anforderungen unterstützen.",
    "franchiseFooter": "Dieses Material enthält Empfehlungen und vorgeschlagene Praktiken, die zur Einhaltung der Markenstandards beitragen. Marriott greift als Franchisegeber in keiner Weise in das Management und den Betrieb des Hotel des Franchisenehmers oder in den Geschäftsbetrieb ein. Die Franchisenehmer behalten die Kontrolle über den täglichen Betrieb ihrer Hotels und sind für ihre eigenen Mitarbeiter und Beschäftigungspraktiken verantwortlich.",
    "landingHeroTitle1": "Herzlich willkommen bei Marriott International.",
    "landingHeroTitle2": "Beginnen Sie hier.",
    "landingHeroText": "The Gateway ist ein Tool, das Sie im ersten Jahr in Ihrer neuen Rolle begleitet. Es bietet Ihnen die Informationen und Ressourcen, die Sie benötigen, um erfolgreich zu sein.",
    "landingHeroTitle1NewToRole": "Herzlich willkommen bei The Gateway.",
    "landingHeroTitle1NewToBrand": "Herzlich willkommen bei The Gateway.",
    "landingButtonText": "Legen Sie los",
    "twoMonthslandingHeroTitle1": "Schön, Sie wiederzusehen!",
    "twoMonthslandingHeroText": "Falls Sie es noch nicht getan haben, sollten Sie jetzt in The Gateway alle Grundlagen abschließen.",
    "twoMonthslandingButtonText": "The Gateway starten",
    "twoMonthslandingHeroTitle1NewToBrand": "Schön, Sie wiederzusehen!",
    "twoMonthslandingHeroTextNewToBrand": "Wir hoffen, Ihre ersten Tage bei %{brandName} waren großartig. Weiter so und nicht vergessen, dass Sie in The Gateway auf alle Ressourcen zugreifen können, die Sie benötigen.",
    "twoMonthslandingButtonTextNewToBrand": "Machen Sie weiter",
    "threeMonthslandingHeroTitle1": "Sie sind auf dem besten Weg!",
    "threeMonthslandingHeroText": "Alles, was Sie über Marriott wissen müssen, finden Sie in The Gateway",
    "threeMonthslandingButtonText": "The Gateway starten",
    "threeMonthslandingHeroTitle1NewToRole": "Sie sind auf dem besten Weg!",
    "threeMonthslandingHeroTextNewToRole": "Alles, was Sie wissen müssen, um in Ihrer neuen Rolle erfolgreich zu sein, finden Sie in The Gateway.",
    "threeMonthslandingButtonTextNewToRole": "The Gateway starten",
    "threeMonthslandingHeroTitle1NewToBrand": "Schön, Sie wiederzusehen!",
    "threeMonthslandingHeroTextNewToBrand": "Herzlichen Glückwunsch zum Abschluss Ihrer ersten %{numDays} Tage bei Ihrer neuen Marke. Sie können bei Bedarf jederzeit auf Ihre Maßnahmen und die Ressourcen Ihrer Marke und Disziplinen zugreifen.",
    "threeMonthslandingButtonTextNewToBrand": "The Gateway starten",
    "fourMonthslandingHeroTitle1": "Großartig! Sie haben Ihre Grundlagen geschafft!",
    "fourMonthslandingHeroText": "The Gateway bietet Ihnen Zugriff auf alles, was Sie über Marriott wissen müssen.",
    "fourMonthslandingButtonText": "The Gateway starten",
    "fourMonthslandingHeroTitle1NewToRole": "Großartig! Sie haben Ihre Grundlagen geschafft!",
    "fourMonthslandingHeroTextNewToRole": "Alles, was Sie benötigen, um in Ihrer neuen Rolle erfolgreich zu sein, finden Sie in The Gateway.",
    "fourMonthslandingButtonTextNewToRole": "The Gateway starten",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway – Einchecken",
    "fourMonthslandingHeroTextNewToBrand": "Ihr erstes Jahr in Ihrer neuen Rolle bei %{brandName} geht dem Ende zu. Falls Sie es noch nicht getan haben, sollten Sie jetzt alle Maßnahmen abschließen. Nutzen Sie alle in der Digital Learning Zone verfügbaren Entwicklungsressourcen. Klicken Sie auf 'Weiter', um Ihre Schulung fortzusetzen.",
    "fourMonthslandingButtonTextNewToBrand": "Weiter",
    "gmSecondVisitlandingHeroTitle1": "Schön, Sie wiederzusehen!",
    "gmSecondVisitlandingHeroText": "Wir hoffen, Ihre ersten Tage waren großartig. Setzen Sie den angefangenen Weg auf jeden Fall fort, damit Sie in Ihrer neuen Rolle wachsen.",
    "gmSecondVisitlandingButtonText": "Machen Sie weiter",
    "gmSecondVisitlandingHeroTextNewToRole": "Wir hoffen, dass Ihre ersten Tage in Ihrer neuen Rolle großartig waren. Weiter so und nicht vergessen, dass Sie in The Gateway auf alle Ressourcen zugreifen können, die Sie benötigen.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Wir hoffen, Ihre ersten Tage bei %{brandName} waren großartig. Weiter so und nicht vergessen, dass Sie in The Gateway auf alle Ressourcen zugreifen können, die Sie benötigen.",
    "gmThirdVisitlandingHeroTitle1": "Schön, Sie wiederzusehen!",
    "gmThirdVisitlandingHeroText": "Herzlichen Glückwunsch zu Ihren ersten %{numDays} Tagen als neuer Mitarbeiter. Sie können bei Bedarf jederzeit auf Ihre Maßnahmen und die Ressourcen Ihrer Marke und Disziplinen zugreifen.",
    "gmThirdVisitlandingButtonText": "The Gateway starten",
    "gmThirdVisitlandingHeroTextNewToRole": "Herzlichen Glückwunsch zum Abschluss Ihrer ersten %{numDays} Tage in Ihrer neuen Rolle. Sie können bei Bedarf jederzeit auf Ihre Maßnahmen und die Ressourcen Ihrer Marke und Disziplinen zugreifen.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Herzlichen Glückwunsch zum Abschluss Ihrer ersten %{numDays} Tage bei Ihrer neuen Marke. Sie können bei Bedarf jederzeit auf Ihre Maßnahmen und die Ressourcen Ihrer Marke und Disziplinen zugreifen.",
    "gmSevenMonthslandingHeroTitle1": "Sie scheinen nicht abgeschlossene Maßnahmen zu haben.",
    "gmSevenMonthslandingHeroText": "Ihr erstes Jahr neigt sich dem Ende zu, vergessen Sie nicht, Ihre Maßnahmen abzuschließen. Nutzen Sie alle in der Digital Learning Zone verfügbaren Entwicklungsressourcen. Klicken Sie auf 'Weiter', um Ihre Schulung fortzusetzen",
    "gmSevenMonthslandingButtonText": "Weiter",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway – Einchecken",
    "gmSevenMonthslandingHeroTextNewToRole": "Ihr erstes Jahr in Ihrer neuen Rolle geht dem Ende zu. Falls Sie es noch nicht getan haben, sollten Sie jetzt alle Maßnahmen abschließen. Nutzen Sie alle in der Digital Learning Zone verfügbaren Entwicklungsressourcen. Klicken Sie auf 'Weiter', um Ihre Schulung fortzusetzen",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway – Einchecken",
    "gmSevenMonthslandingHeroTextNewToBrand": "Ihr erstes Jahr in Ihrer neuen Rolle bei %{brandName} geht dem Ende zu. Falls Sie es noch nicht getan haben, sollten Sie jetzt alle Maßnahmen abschließen. Nutzen Sie alle in der Digital Learning Zone verfügbaren Entwicklungsressourcen. Klicken Sie auf 'Weiter', um Ihre Schulung fortzusetzen.",
    "gmOneYearlandingHeroTitle1": "Herzlichen Glückwunsch zum 1. Jahr bei %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "Sie sind auf dem besten Weg!",
    "gmOneYearlandingHeroTitle1NewToBrand": "Herzlichen Glückwunsch zum 1. Jahr in Ihrer neuen Rolle bei  %{brandName}!",
    "gmOneYearlandingHeroText": "The Gateway ist zwar zu Ende, aber das Beste kommt noch. Sie können von der Registerkarte The Gateway aus jederzeit auf die Ressourcen zugreifen.",
    "gmOneYearlandingButtonText": "Gateway ansehen"
  },
  "resources": {
    "iptTooltip": "Mehrere Standorte durch Kommas trennen",
    "iptAllFunctionTooltip": "Zur Auswahl spezifischer Funktionen deaktivieren Zur Anzeige aller Funktionen am ausgewählten Standort aktiviert lassen",
    "iptAllAWRTooltip": "Zur Auswahl spezifischer Verantwortlichkeiten deaktivieren Zur Anzeige der Schulungen aller zusätzlichen Verantwortlichkeiten des ausgewählten Standorts aktiviert lassen",
    "editJFTooltip": "Mehrere Einträge durch Kommas trennen"
  },
  "Notification Center: ": "Als ungelesen markieren",
  "Notification Center: Empty": "Sie sind auf dem neuesten Stand!",
  "Notification Center: Individual Delete": "Soll diese Benachrichtigung wirklich gelöscht werden? Gelöschte Benachrichtigungen werden in den Abschnitt „Gelöscht“ des Benachrichtigungszentrums verschoben, bis sie ablaufen.",
  "Notification Center: Delete All": "Sind Sie sicher, dass Sie alle Benachrichtigungen löschen möchten? Sie werden in den Abschnitt Gelöscht des Benachrichtigungszentrums verschoben, bis sie ablaufen.",
  "Notification Center: Mark All As Read": "Alle als gelesen markieren",
  "Notification Center: Restore All": "Alle wiederherstellen",
  "Notification Center: Delete Confirmation": "Löschen",
  "Warning Message Text: Remove Job Function or AWR": "Funktion oder zusätzliche Arbeitsaufgabe (AWR) entfernen?\r\nDurch Senden entfernen Sie %{Function and/or AWR} von %{count} Lernenden.",
  "Warning Message Text: Add Job Function or AWR": "Funktion oder zusätzliche Arbeitsaufgabe (AWR) hinzufügen?\r\nDurch Senden fügen Sie %{Function or AWR} zu %{count} Lernenden hinzu.",
  "Button": "Senden",
  "Success Message Text: Add Job Function and/or AWR": "Erfolg\r\nSie haben %{Function and/or AWR} zu den ausgewählten Mitarbeitenden in %{location} hinzugefügt. \r\n\r\nMöchten Sie weitere Funktionen oder zusätzliche Arbeitsaufgaben für %{location} bearbeiten?",
  "Message Text:AWR": "Erfolg\r\nSie haben die zusätzlichen Arbeitsaufgaben %{ __} zu den ausgewählten Mitarbeitenden in %{ __} hinzugefügt.\r\n\r\nMöchten Sie weitere Funktionen oder zusätzliche Arbeitsaufgaben für %{location, property, learner(s)} bearbeiten?",
  "Success Message Text: Remove Job Function": "Erfolg\r\nSie haben %{Job Function and/or AWR} von den ausgewählten Mitarbeitenden in %{location} entfernt. \r\n\r\nMöchten Sie weitere Funktionen oder zusätzliche Arbeitsaufgaben für %{location} bearbeiten?",
  "Success Message Text: Remove AWR": "Erfolg\r\nSie haben die zusätzliche Arbeitsaufgabe %{ __} von den ausgewählten Mitarbeitenden in %{ __}  entfernt.\r\n\r\nMöchten Sie weitere Funktionen oder zusätzliche Arbeitsaufgaben für %{location, property, learner(s)} bearbeiten?",
  "Tool Tip - Managed Change Email": "Um Ihre E-Mail-Adresse zu ändern, besuchen Sie bitte mHUB > HR Direct > Ich > Meine öffentlichen Informationen. Befolgen Sie die Anweisungen, um Ihre Kontaktdetails zu aktualisieren. Es dauert ein bis zwei Werktage, bis die aktualisierte E-Mail-Adresse angezeigt wird.",
  "Tool Tip - Franchised  Change Email": "Um Ihre E-Mail-Adresse zu ändern, kontaktieren Sie bitte Ihre(n) Vorgesetzte(n) und/oder Ihre(n) Franchise EID Tool Administrator(in). Es dauert ein bis zwei Werktage, bis die aktualisierte E-Mail-Adresse angezeigt wird.",
  "User Profile: Email Opt-in": "Nein, vielen Dank",
  "User Profile: Email Frequency": "Wöchentliche Zusammenfassung",
  "Generic Notification Email Subject Line:": "Sie haben neue Benachrichtigungen im DLZ",
  "Generic Body Text (<150 Characters)": "Bitte besuchen Sie die DLZ, um sich die Details anzusehen.",
  "All Emails: Signature": "Digital Learning Zone – Lernen leicht gemacht. Es beginnt bei Ihnen.",
  "All Emails: Footer": "Sie können die E-Mail-Benachrichtigungen jederzeit abbestellen/abwählen. Besuchen Sie die Digital Learning Zone/Profil, um Ihre Einstellungen zu aktualisieren.",
  "Overdue Emails: Subject Line: ": "DLZ-Zuweisungen erfordern Ihre Aufmerksamkeit",
  "Overdue Emails: Body Test": "Sie haben überfällige Zuweisungen. Bitte melden Sie sich bei der DLZ an und überprüfen Sie Ihre Zuweisungen.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Schärfen Sie Ihre Fähigkeiten",
    "sharpenYourSkillsSubTitle": "Erweitern Sie Ihre Kenntnisse und verbessern Sie Ihre Fähigkeiten, um jetzt und künftig Ihre Karriere voranzubringen.",
    "growYourCareerTitle": "Machen Sie Karriere",
    "growYourCareerSubTitle": "Planen Sie Ihren nächsten Schritt mit fachlichen Fähigkeiten und übergreifender Schulung für Ihre einzigartigen Interessen und Ziele.",
    "additionalResourcesTitle": "Zusätzliche Ressourcen",
    "additionalResourcesSubTitle": "Erkunden Sie getAbstract® und CultureWizard™. Finden Sie heraus, wie Ihnen das DLZ hilft beruflich voranzukommen.",
    "tourHeader": "Willkommen im aktualisierten DLZ. Jetzt finden Sie alles, was Sie brauchen, wann immer Sie es brauchen. Außerdem finden Sie ein aktualisiertes Lernen erkunden  und Mein Plan wurde weiter personalisiert. Machen Sie die Tour!",
    "tourBtnSkip": "Später",
    "tourBtnNext": "Schließen (6/6)",
    "tourSearchBar": "Finden Sie, was Sie brauchen, wenn Sie es brauchen, mit einer neuen und robusteren Suchfunktion.",
    "tourBtnBack": "Zurück",
    "tourMyPlan": "Mein Plan enthält jetzt Elemente, die Sie aus Suchen und Erkunden hinzugefügt haben. Sie können Ihre Lernreise vollständig selbst gestalten können!",
    "tourExploreLearningLink": "Von hier aus gelangen Sie zu Lernen erkunden!",
    "tourExploreLearningProfile": "Machen Sie das Beste aus Ihrer Lernerfahrung und halten Sie Mein Plan und vor allem die beruflichen Funktionen regelmäßig auf dem neuesten Stand.",
    "mhubTraining": "mHUB Schulung",
    "growthSkills": "Verbessern Sie Ihre Fähigkeiten",
    "leadershipSkills": "Führung verbessern",
    "virtualTeamResources": "Virtuelle Teamressourcen",
    "jobFunctions": "Funktion",
    "brands": "Marken",
    "languageLearning": "Sprachkurse",
    "become": "Become",
    "emeaMentoringProgram": "EMEA Mentoringprogramm",
    "learningPathways": "Lernwege",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare Community Hub",
    "mhubTrainingDesc": "Finden Sie rollenbasierte Lernpfade und themenspezifische Kurse zur Nutzung von mHUB.",
    "growthSkillsDesc": "Erwerben Sie praktisches Wissen über verschiedene Funktionen und Themen.",
    "leadershipSkillsDesc": "Erfahren Sie, wie Sie ein Team leiten und coachen und eine effektivere Führungskraft werden.",
    "virtualTeamResourcesDesc": "Lernen Sie Techniken zur Förderung einer kollaborativen und integrativen Kultur in virtuellen Teams.",
    "jobFunctionsDesc": "Erweitern Sie Ihr Wissen über Ihre aktuelle Tätigkeit und lernen Sie andere kennen.",
    "brandsDesc": "Erfahren Sie mehr über die verschiedenen Marken von Marriott International.",
    "languageLearningDesc": "Kommunizieren Sie effektiv mit einer globalen Zielgruppe.",
    "becomeDesc": "Entdecken Sie einen ganzheitlichen Ansatz für Ihre Karriere und Entwicklung: Reflektieren, Planen, Handeln, Verfeinern.",
    "emeaMentoringProgramDesc": "Erschließen Sie Ihr Potenzial mit dem EMEA-Mentoring-Programm.",
    "learningPathwaysDesc": "Erkunden Sie spezielle Lernangebote, die auf Ihre Führungsebene zugeschnitten sind.",
    "mdaDesc": "Bauen Sie mit der Marriott Development Academy Führungsqualitäten und fachliche Fähigkeiten auf.",
    "takeCareGrowDesc": "Finden Sie Ressourcen für den Aufbau einer integrativen und inspirierenden Gemeinschaft.",
    "getAbstractDesc": "Lernen Sie jeden Tag etwas Neues mit Buchübersichten, die Ihnen helfen, bessere Entscheidungen zu treffen.",
    "cultureWizardDesc": "Sammeln Sie wertvolles Wissen für eine effektive Kommunikation der vielfältigen Kulturen.",
    "takeCareCommunityHubDesc": "Entdecken Sie die Teambuilding-Aktivitäten, Lernprogramme und Tools von TakeCare.",
    "searchAllLearning": "Alle Lernangebote durchsuchen",
    "searchBarPlaceHolder": "Es gibt immer etwas zu lernen.",
    "clearFilters": "Filter entfernen",
    "noResults": "Keine Ergebnisse",
    "hasBeenUnpinned": "{Variable-tile_Name} wurde abgeheftet.",
    "hasBeenPinned": "{Variable-tile_Name} wurde angeheftet.",
    "lpRemovedFromPlan": "Der Lernweg wurde aus Ihrem Plan entfernt.",
    "courseRemovedFromPlan": "Der Kurs wurde aus Ihrem Plan entfernt.",
    "lpAddedToPlan": "Der Lernweg wurde zu Ihrem Plan hinzugefügt.",
    "courseAddedToPlan": "Der Kurs wurde zu Ihrem Plan hinzugefügt.",
    "clearSelection": "Auswahl löschen",
    "removeFromMyPlan": "Aus meinem Plan entfernen",
    "addToMyPlan": "Zu meinem Plan hinzufügen",
    "loadingResults": "Laden…",
    "viewDetails": "Details anzeigen",
    "clearAll": "Alle löschen",
    "noOptions": "Keine Optionen verfügbar",
    "viewLess": "Weniger anzeigen",
    "viewMore": "Mehr anzeigen",
    "recentSearch": "Letzte Suchanfragen",
    "exploreLearningTitle": "Lernen erkunden",
    "exploreLearningHelpText": "Sie wissen nicht, wonach Sie suchen sollen?",
    "exploreMore": "Mehr erkunden.",
    "viewAllResults": "Alle <#> Ergebnisse anzeigen",
    "searchSubTitle": "Suchen Sie mit Schlüsselwörtern, Titeln oder Inhalts-ID nach Kursen.",
    "pinnedLearningTitle": "Meine angehefteten Lernangebote",
    "landingSubTitle": "Gestalten Sie Ihre Lernreise. Erkunden Sie Ihre Interessen. Es kommen ständig neue Themen und Kurse hinzu.",
    "columnTitle": "Titel",
    "columnDesc": "Beschreibung",
    "columnType": "Art",
    "columnAddedToPlan": "Zum Plan hinzufügen (hinzugefügt)",
    "columnComplete": "Abgeschlossen",
    "columnDetails": "Details",
    "additionalSearchFilters": "Zusätzliche Suchfilter",
    "tourBtnClose": "Schließen",
    "tourBtnLast": "Schließen",
    "resultsFor": "Ergebnisse für ",
    "resultsFortheFilter": "Ergebnisse für den Filter {Filter Name}",
    "no": "Nein",
    "results": "Ergebnisse",
    "courseTooltipTitle": "Ein einzelnes Lernelement, das eine oder mehrere Aktivitäten enthalten kann.<br />Ein Kurs kann online, z. B. als Video oder eLearning, oder in einem Klassenzimmer stattfinden.",
    "gatewayToolTipTitle": "Digitale Ressourcen für neue Mitarbeiter, neue Mitarbeiter im Management und GMs, die neu bei ihrer Marke sind.",
    "speciallizationOrPathwayTooltipTitle": "Eine Reihe von Kursen, die ein bestimmtes Thema in den Mittelpunkt stellen.",
    "taskTooltipTitle": "Anweisungen zum Abschließen einer Aktivität (normalerweise am Arbeitsplatz) und anschließendes Bestätigen, dass Sie sie abgeschlossen haben.",
    "webSiteTooltipTitle": "Jede Website, einschließlich Marriott-Websites oder externe Websites."
  },
  "dontShowAgain": "Nicht mehr anzeigen",
  "deletionWarningEnabled": "Löschwarnung aktiviert",
  "deletionSelected": "Wenn ausgewählt, wird eine Warnung angezeigt, wenn Sie eine Benachrichtigung löschen.",
  "manageEmailNotifications": "E-Mail-Benachrichtigungen verwalten",
  "seeHowDLZContinue": "Finden Sie heraus, wie Ihnen das DLZ hilft, beruflich voranzukommen.",
  "Notification Center:Overdue": "Sie haben eine neue Schulung, die am {Variable date ddmmyy} fällig war",
  "accesDenied": {
    "title": "Der Zugang zu Ihrer Lernerfahrung wird vorbereitet! ",
    "subtitle": "Ihr Zugang wurde erstellt und steht nach ein bis zwei Tagen nach der Aktivierung Ihrer EID zur Verfügung. Falls es länger als zwei Tage dauert, kontaktieren Sie bitte das mHUB-Servicecenter."
  },
  "jfTour": {
    "title": "Jetzt können Sie Ihre ausgewählten Funktionen anzeigen und bearbeiten sowie direkt in Mein Plan zusätzliche Arbeitspflichten hinzufügen."
  },
  "onboardingPlan": {
    "requiredDescription": "Nachfolgend finden Sie die obligatorischen Kurse, die in dem von Ihnen unter „Mein Plan“ gewählten Zeitraum abgeschlossen werden müssen. Planen Sie gemeinsam mit Ihrem Vorgesetzten die Zeit, die Sie benötigen, um sich in Ihrer neuen Rolle zurechtzufinden. Sie werden sich mit vielen Themen vertraut machen. Wenden Sie sich bitte an Ihren Vorgesetzten und Ihre Kollegen, wenn Sie Fragen haben.   ",
    "basicsDescription": "Wir wissen, dass Vieles für Sie neu ist und wir haben einige der Grundlagen für Ihre ersten Tage in dieser Funktion ausgewählt. Diese Punkte sind möglicherweise eine Ergänzung Ihrer Orientierungen, des Marken-Onboardings und der Informationen Ihres Franchise-Unternehmens. Sie werden Ihnen während Ihrer gesamten Laufbahn bei uns und beim Onboarding neuer Teammitglieder eine große Hilfe sein.",
    "recommendedDescription": "Nach den ersten 90 Tagen finden Sie weitere Kurse, die von Ihrem Vorgesetzten empfohlen wurden. Sie können Themen, die Sie interessieren, auch selbstständig erkunden. Konzentrieren Sie sich vorerst auf das Grundlagenwissen. ",
    "day1": "Fällig in 14 Tagen",
    "day2": "Fällig in 30 Tagen",
    "day3": "Fällig in 60 Tagen",
    "day4": "Fällig in 90 Tagen",
    "day5": "Fällig nach 90 Tagen "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Die Digital Learning Zone ist Ihr Ausgangspunkt für das Lernen und Ihre berufliche Entwicklung. Im Folgenden finden Sie Inhalte, die Ihnen den Einstieg erleichtern sollen. Sie finden Ihre Lerninhalte stets unter „Mein Plan“.  ",
    "overallLearningProgress": "Ihr Lernfortschritt insgesamt",
    "learningProgressLabel": "Lernfortschritt",
    "learningProgressDescription": "Hier sehen Sie den Fortschritt bei Ihren obligatorischen Lernaufgaben. Diese Fortschrittsleiste umfasst den Status aller Ihrer obligatorischen Lernaufgaben. Weiter so!"
  },
  "poc": {
    "dlz": "Um diesen Kurs abzuschließen, müssen Vorgesetzte oder Schulungskoordinatoren die Fertigstellung in mHUB bestätigen. Genaueres dazu finden Sie in der Implementierungsrichtlinie.",
    "mhub": "Franchisenehmer:  Wenn Sie für die Reinigung Ihr eigenes Grundlagenprogramm nutzen möchten, geben Sie bitte den Programmnamen und die Details im Kommentarbereich an, wenn Sie die Fertigstellung bestätigen."
  },
  "Recommended Courses ": "Nach den ersten 90 Tagen finden Sie weitere Kurse, die von Ihrem Vorgesetzten empfohlen wurden. Sie können Themen, die Sie interessieren, auch selbstständig erkunden. Konzentrieren Sie sich vorerst auf das Grundlagenwissen. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Nimm an der Umfrage teil",
      "lbiIntro": "Willkommen beim Luxury Behavior Check-in!",
      "lbiDescription": `<p>Unten wirst du gebeten, einige kurze Szenarien zu lesen, die du möglicherweise in deinem Hotel erlebst. Bitte lies jedes Szenario und wähle aus, wie du auf die jeweiligen Situationen reagieren würdest. Beachte bei der Beantwortung Folgendes:
      </p>
      <ul>
          <li>
              <p>Möglicherweise findest du mehr als eine der Antwortmöglichkeiten richtig. Wähle diejenige Antwortmöglichkeit aus, für die du dich am wahrscheinlichsten bei der Arbeit entscheiden würdest.
              </p>
          </li>
          <li>
              <p>Gehe davon aus, dass keine Kommunikationsbarrieren vorliegen. Du sprichst die gleiche Sprache wie der Gast oder Mitarbeiter im Szenario.
              </p>
          </li>
          <li>
              <p>Das Szenario kann dich auffordern, dich in einer anderen Arbeitsrolle vorzustellen als der, die du derzeit innehast. Beantworte die Frage nach bestem Wissen und Gewissen, basierend auf deiner Erfahrung als Luxury Hospitality Professional.
              </p>
          </li>
      </ul>
      <p><br></p>
      <p>Hinweis: Deine Teilnahme an diesem Check-in ist vollkommen freiwillig (du kannst dich gegen eine Teilnahme entscheiden) und deine Antworten werden streng vertraulich behandelt. Die Ergebnisse dieses Check-ins werden für Personengruppen und nicht für bestimmte Personen berichtet. Die Antworten der einzelnen Teilnehmer werden vom Research Team ausgewertet und nicht an Manager von anderen oder Führungskräfte weitergegeben.</p>
      <p>Mit dem Ausfüllen dieses Check-ins stimmst du der Erfassung und Verarbeitung deiner persönlichen Antworten und personenbezogenen Daten (z. B. Dauer der Betriebszugehörigkeit, Geschlecht, Stellenbezeichnung) sowie der grenzüberschreitenden Übermittlung deiner personenbezogenen Daten an die Marriott Group zu. Dies entspricht der Datenschutzerklärung für Marriott-Mitarbeiter, die auch in den Datenschutzrichtlinien von Marriott zu finden ist.</p>
      `,
      "checkin": {
        q1Title: "Du gehst durch die Lobby und siehst eine Familie mit drei kleinen Kindern. Die Familie hat viel Gepäck dabei, sieht gestresst von einem langen Reisetag aus und die Kinder sind quengelig und sichtlich unruhig. Was würdest du tun?",
        q2Title: "Du hast gerade in einem Hotel in einer für dich neuen Gegend angefangen. Ein Gast bittet um Vorschläge für Unternehmungen in der Umgebung. Der Gast hat mehrere Reiseführer gelesen, sucht aber nach etwas Persönlicherem. Was würdest du tun? ",
        q3Title: "Du gehst durch die Lobby und hörst, wie ein Gast am Telefon darüber spricht, dass sich sein Fahrdienst zum Flughafen verspätet. Dein Hotel hat entweder kein Fahrzeug oder es ist nicht verfügbar. Was würdest du tun? ",
        q4Title: "Du gehst durch die Lobby, um eine dringende Aufgabe zu erledigen, die dir von deinem Manager zugewiesen wurde. Du bemerkst, dass ein Kollege Schwierigkeiten mit einer Aufgabe hat, die normalerweise von zwei Personen erledigt wird. Was würdest du tun? ",
        q6Title: "Im überfüllten Poolbereich befindet sich eine große Gruppe von Gästen, die keine gemeinsamen Sitzplätze finden konnten. Infolgedessen haben sie ihre Handtücher und persönlichen Gegenstände so ausgelegt, dass ein Hauptweg blockiert wird. Das stellt eine Gefahr für Gäste und Mitarbeiter dar, die versuchen, den Poolbereich zu betreten, und stört sie dabei. Der Bereich muss geräumt werden, aber du willst die Gruppe nicht verärgern. Was würdest du tun? ",
        q5Title: "Du bist ein Mitarbeiter des Hotelrestaurants. Ein Gast bittet darum, die Speisekarte zu sehen, während er auf seinen reservierten Tisch wartet. Nach einem Blick auf die Speisekarte ist der Gast frustriert, weil ihm das Hotelrestaurant von einem anderen Kollegen empfohlen wurde, sich aber auf der Speisekarte nichts befindet, was seinen Ernährungsbedürfnissen entspricht. Was würdest du tun?",
        q1R1Title: "Hilf der Familie mit dem Gepäck, weise ihr den Weg zum Check-in-Schalter und schlage ihr ein paar entspannende Orte in der Nähe mit familienfreundlichen Aktivitäten vor. Erwähne die Annehmlichkeiten für Familien des Hotels.",
        q1R2Title: "Biete an, mit dem Gepäck zu helfen und die Familie zum Check-in zu begleiten. Dann arrangiere einen entspannenden Service oder ein Extra für die Eltern und spannende Aktivitäten für die Kinder in ihrem Zimmer.",
        q1R3Title: "Begleite die Eltern zum Check-in und bringe die Kinder dann persönlich zu einem Sitzbereich mit Snacks, während die Eltern einchecken. Informiere die Eltern an der Rezeption über Spielplätze und Aktivitäten für Kinder im Hotel, damit sie sich wohl fühlen.",
        q1R4Title: "Zeige der Familie den Weg zum Check-in, damit das Einchecken zügig vonstattengeht. Erwähne den Eltern gegenüber die kinderfreundlichen Bereiche und Services, damit die Kinder einen angenehmen Aufenthalt genießen können. ",
        q2R1Title: "Frage den Gast, was ihm Spaß macht, und erstelle dann gemeinsam mit einem Kollegen eine Liste mit interessanten Aktivitäten. Schlage außerdem ein nettes Restaurant in der Nähe vor.",
        q2R2Title: "Sprich mit dem Gast darüber, was er mag. Arbeite mit einem Kollegen zusammen, um eine spezielle Liste mit interessanten Aktivitäten für ihn zu erstellen. Überprüfe außerdem das Bonvoy Profil des Gastes und sende ihm einen persönlichen Leckerbissen auf sein Zimmer.",
        q2R3Title: "Mache es persönlich, indem du einige deiner Lieblingsaktivitäten in der Region nennst. Hebe deine Vorschläge hervor, indem du weitere Einzelheiten zu deinen Erfahrungen aufführst. Sende dann einen lokalen Leckerbissen auf das Zimmer.",
        q2R4Title: "Lasse den Gast wissen, dass du neu in der Gegend bist und nicht viele Ideen hast. Entschuldige dich und schlage vor, mit deinem Kollegen zu sprechen, der sich besser auskennt. Frage dann, ob du einen lokalen Leckerbissen auf sein Zimmer schicken sollst.",
        q3R1Title: "Während der Gast noch telefoniert, fahre mit deinem eigenen Fahrzeug oder dem deines Kollegen vor das Hotel und teile dem Gast mit, dass du ihn persönlich zum Flughafen bringen wirst.",
        q3R2Title: "Stelle, während der Gast telefoniert, eine Liste der vom Hotel zugelassenen Fahrdienste mit deren Telefonnummern zusammen. Sobald der Gast nicht mehr am Telefon ist, lege ihm die Liste vor und biete ihm an, bei der Organisation des Transports zu helfen.",
        q3R3Title: "Warte, bis der Gast aufgelegt hat, und teile ihm mit, dass dein Hotel keinen Transportservice anbietet, dass du aber gerne bei der Koordinierung anderer Transportmöglichkeiten behilflich bist. ",
        q3R4Title: "Suche nach einer alternativen Transportmöglichkeit für den Gast, während er noch telefoniert. Sobald der Gast das Telefonat beendet hat, teile ihm mit, dass du den Transport zum Flughafen arrangiert hast und das Fahrzeug in Kürze eintreffen wird.",
        q4R1Title: "Gehe auf deinen Kollegen zu und sage ihm, dass du ihm helfen wirst, aber zuerst deine dringende Aufgabe erledigen musst. Versprich, schnell zurückzukehren, um ihm zu helfen. Besprich die Situation unbedingt mit deinem Manager.",
        q4R2Title: "Suche zunächst schnell einen anderen Kollegen, der die Aufgabe übernimmt, die dir zugewiesen wurde. Hilf dann sofort deinem Kollegen, der Hilfe benötigt. Wenn du fertig bist, teile deinem Manager mit, dass du eine andere Person gefunden hast, die deine Aufgabe erledigt, damit du deinem Kollegen helfen kannst. ",
        q4R3Title: "Bleib stehen und hilft deinem Kollegen sofort. Nachdem du geholfen hast, kehre zu der dir zugewiesenen Aufgabe zurück. Teile deinem Vorgesetzten mit, dass du deine Aufgabe aufgeschoben hast, um deinem Kollegen zu helfen.",
        q4R4Title: "Gehe zu deinem Kollegen, der Hilfe braucht, und erkläre ihm, dass du gerade eine dringende Aufgabe zu erledigen hast. Unterbrecht beide Aufgaben, überlegt, wie Ihr beide gemeinsam lösen könnt, und erledigt sie dann.",
        q5R1Title: "Entschuldige dich bei dem Gast und informiere ihn, dass du den Manager holen wirst. Stelle sicher, dass du die Situation erklärst, damit der Manager dem Gast bei seinen Essenswünschen helfen kann.",
        q5R2Title: "Entschuldige dich bei dem Gast und frage ihn nach seinen Essensvorlieben. Sprich mit dem Küchenteam, um ein spezielles Gericht für ihn zubereiten zu lassen. Gib ihm auch eine kostenlose Vorspeise oder ein Getränk, um den Fehler wiedergutzumachen.",
        q5R3Title: "Entschuldige dich und sage dem Gast, dass du ihn verstehst. Anstatt die Reservierung zu stornieren, biete an, mit dem Küchenchef zu sprechen und zu sehen, ob er etwas Besonderes für den Gast zubereiten kann. Gib ihm einen kostenlosen Leckerbissen oder einen Rabatt, um ihn für die Unannehmlichkeiten zu entschädigen.",
        q5R4Title: "Entschuldige dich und sage dem Gast, dass du ihm helfen kannst, einen Tisch in einem nahe gelegenen Restaurant zu reservieren, in dem es Essen gibt, das seinen Vorlieben entspricht. Biete bei Bedarf zusätzliche Informationen an oder hilf ihm dabei, dorthin zu gelangen.",
        q6R1Title: "Entschuldige dich bei den Gästen für den Platzmangel und sage ihnen, dass sie gerne bleiben können, wo sie sind, solange sie auf die anderen Passanten achten. ",
        q6R2Title: "Sage den Gästen freundlich, dass sie den Gehweg nicht blockieren dürfen und sich einen anderen Platz suchen oder warten müssen, bis ein Platz frei wird. Bitte in der Zwischenzeit deine Kollegen, zusätzliche Sitzgelegenheiten aus dem Ballsaal nach draußen zu bringen, damit sie diese nutzen können. ",
        q6R3Title: "Weise die Gäste darauf hin, dass sie nicht an ihrem derzeitigen Platz bleiben können und vorübergehend an einen anderen Ort gehen müssen, während du zusätzliche Sitzgelegenheiten suchst. Biete ihnen in der Zwischenzeit einen Platz in der Bar bzw. im Restaurant an. ",
        q6R4Title: "Entschuldige sich bei den Gästen für das Sitzproblem. Frage sie, ob sie ihre Sachen zusammenlegen können, um einen kleinen Gehweg für andere Passanten zu schaffen. Biete ihnen deine Hilfe an und sorge dafür, dass sich alle wohl fühlen. Schicke ihnen für ihre Unannehmlichkeiten einen besonderen Leckerbissen aus der Bar bzw. dem Restaurant."
      }
    }
  }
};