export default {
  "banner": {
    "viewDetails": "詳細の確認",
    "requiredCourses": "必修コースの期限: ",
    "days": "日"
  },
  "header": {
    "plan": "マイプラン",
    "manager": "マネージャー",
    "explore": "ラーニングの検索",
    "reporting": "レポート",
    "myTeam": "マイチーム",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "マイプラン",
    "manager": "マネージャー",
    "explore": "ラーニングの検索",
    "tagline": "ラーニングが簡単になりました。まずは始めてみましょう。"
  },
  "welcome": {
    "hi": "こんにちは",
    "intro1": "Digital Learning Zoneへようこそ！DLZは、マリオットのすべての学習プログラムにアクセスできる一人ひとりに合わせた学習プラットフォームです。自身の役割で義務付けられている必須トレーニングや、自己開発や専門能力開発のための学習機会を検索することができます。学びの旅を自分のものにして、スキル向上に向けた道のりをスタートさせましょう。",
    "intro2": "始める前にあなた自身について何点か教えてください。",
    "letsGo": "それでは始めましょう。",
    "preferredLanguage": "希望の言語は？",
    "step": "ステップ",
    "of": "/",
    "previous": "前へ",
    "next": "次へ",
    "startDate": "Marriott InternationalまたはStarwoodで働き始めたのはいつですか？",
    "selectYear": "年を選択",
    "selectMonth": "月を選択",
    "selectDay": "日を選択",
    "selectFunction": "部門を選択",
    "selectInterests": "興味をを選択",
    "chooseYear": "年を選択してください",
    "chooseMonth": "月を選択してください",
    "chooseDay": "日を選択してください",
    "whatDo": "担当業務を教えてください？",
    "selected": "選択済み",
    "searchFunctions": "担当業務をすべて検索",
    "searchInterests": "全ての興味を検索",
    "additionalJobFunctionsLabel": "あなたの仕事について詳しく教えてください",
    "managementLevelLabel": "アソシエイトレベルは？",
    "preferredBrandLabel": "複数のブランドで仕事をしている場合は、当てはまるものをすべてお選びください。",
    "additionalJobFunctionsLabelExtra": "以下を確認して、その他の担当業務を最低1つ選択してください。",
    "searchAdditionalFunctions": "その他の業務をすべて検索",
    "highGuestContactLabel": "ゲストとかかわることが多いですか？",
    "yes": "はい",
    "no": "いいえ "
  },
  "landing": {
    "started": "では始めましょう",
    "welcomeBack": "おかえりなさい。",
    "greatWork": "お疲れさまです！",
    "requiredCourses": "必修コース",
    "requiredCoursesDesc": "期限が切れる前にコースを修了してください。",
    "myPlan": "マイプラン",
    "courses": "コース",
    "courseComplete": "コース修了",
    "viewMore": "他の必修コースを表示",
    "viewMoreBtn": "他のコースを表示",
    "showing": "表示中の件数",
    "of": "/",
    "heroTitle": "新しいことを学びましょう。力をつけましょう。自分自身に役立つスキルを身に着けましょう。",
    "heroParagraph1": "すべての必修コースを簡単に管理して、リアルタイムで通知を受信できます。",
    "heroParagraph2": "担当業務や興味に基づいて学習体験をカスタマイズできます。",
    "recommendedCoursesTitle": "おすすめのコース",
    "recommendedCoursesDescNoContent": "現在おすすめのコースはありません。以下のその他の学習機会をご覧ください。",
    "recommendedCoursesDesc": "マネージャーまたは人事担当者があなたのために何を選んだのかを見てみましょう。",
    "recommendedCoursesBtn": "おすすめのコースを表示",
    "completedCoursesBtn": "修了したコースを表示",
    "exploreTitle": "好奇心を持ちましょう。",
    "exploreDesc": "興味のあるトピックを選びましょう。",
    "jobFunctions": "規則",
    "growthSkills": "成長スキル",
    "leadershipSkills": "リーダーシップスキル",
    "brands": "ブランド",
    "languageLearning": "語学学習",
    "exploreBtn": "コースを表示",
    "managerRecommendation": "マネージャーのおすすめ",
    "learningRecommendation": "ラーニングのおすすめ",
    "marriottRecommendation": "MARRIOTTのおすすめ",
    "completedCourses": "全ての必修コースを修了しました！",
    "investTitle": "学びの旅を自分のものに",
    "investParagraph1": "私たちは、現在の状況が多くの点で困難であることを認識しています - DLZは、あなたのリソースとしてここにあります。スキルを最新の状態に保つ、新しいことを学ぶ、ストレスを管理する、バーチャルチームで仕事を学ぶ。私たちはあなたをサポートするためにリソースを集めました。",
    "updatedTopics": "更新されたトピック",
    "workingRemotely": "バーチャルチームリソース",
    "relatedCoursesNew": "新しいトピックやコースを追加したので、以下をクリックしてあなたの関心を更新してください。",
    "relatedCourses": "あなたの関心を選択するには、以下をクリックしてください。",
    "notificationText": "新しいmHUBトレーニングがあります。Explore LearningでmHUBを選択してください。",
    "mHubHeroTitle1": "シンプルでスマートで安全mHUBをご紹介します。",
    "mHubHeroManagedText1": "mHUBは、学習、キャリアアップ、Exploreディスカウントなどのツールをひとつにまとめた使いやすいプラットフォームです。",
    "mHubHeroFranchisedText1": "mHUBは、学習、Exploreディスカウントなどのツールをひとつにまとめた使いやすいプラットフォームです。",
    "mHubHeroVisitLink": "こちらをクリックして、その他のオファー詳細や添付ファイルをご覧ください。",
    "myGateway": "My Gateway",
    "gatewayReview": "確認して完了してください。",
    "gatewayProgress": "Gateway進捗状況",
    "complete": "修了",
    "reviewContent": "リソースを確認する",
    "gatewayCardTitle": "Gatewayリソース",
    "gatewayCardDesc": "一年目およびそれ以降も基本事項をご活用ください。",
    "gatewayCardDescGms": "一年目およびそれ以降も基本事項、アクションアイテム、ブランドリソース、部門別のリソースをご活用ください。",
    "gatewayCardDescNew": "新しい仕事での一年目の基本事項をご活用ください。",
    "gatewayCardDescGmsNew": "新しい仕事での一年目およびそれ以降も基本事項、アクションアイテム、ブランドリソース、部門別のリソースをご活用ください。"
  },
  "courseBundle": {
    "completeBy": "修了期限:",
    "remainingCourses": "残りのコース",
    "completedCourses": "修了したコース",
    "remainingLabel": "このがラーニングパスウェイには{courseCount}件のコースがあります。",
    "recommendButton": "チームへのおすすめ",
    "startCourse": "コースを開始する",
    "durationMins": "分",
    "completed": "修了済み",
    "back": "前へ",
    "additionalLangs1": "このコースは英語の他に以下の言語でも提供されています: ",
    "additionalLangs2": "コース開始後にドロップダウンメニューからご希望の言語をお選びください。",
    "targetAudience": "対象者",
    "all": "すべて",
    "globally": "世界",
    "source": "ソース",
    "courseType": "コースタイプ",
    "course": "コース"
  },
  "profile": {
    "save": "保存",
    "edit": "編集",
    "brand": "ブランド",
    "brands": "ブランド",
    "additionalWork": "その他すべての担当業務",
    "startDateLabel": "Marriott InternationalまたはStarwoodで働き始めたのはいつですか？",
    "preferredLanguageLabel": "希望の言語は？",
    "jobFunctionsLabel": "担当業務を教えてください。",
    "interestsLabel": "どんなことを学びたいですか？",
    "brandsLabel": "どのブランドのホテルで働いていますか？",
    "additionalJobFunctionsLabel": "これ以外に担当している業務は？",
    "managementLevelLabel": "アソシエイトレベルは？",
    "highGuestContactLabel": "ゲストとかかわることが多いですか？"
  },
  "courseDetails": {
    "completeBy": "完了が必要",
    "recommendTitlte": "チームにおすすめのコース",
    "courseName": "コース名",
    "sendRecommendation": "おすすめを送信"
  },
  "explore": {
    "relatedCourses": "以下はあなたが興味を持っていることに関連のあるコースです。",
    "chooseInterests": "興味のあるものをお選びください",
    "title": "ラーニングの検索",
    "chooseInterestsModal": "興味の選択",
    "oppsTitle": "ラーニング機会の検索",
    "growWithUsTitle": "Grow With Us",
    "growWithUsDesc": "マリオットは、あなたにぴったりのキャリアがあると信じています。また、経験の幅を広げれば、それだけ豊かな体験ができると信じています。",
    "growWithUsBtn": "GROW WITH USを表示",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "マリオットは成功と努力を評価します。リーダーシップと卓越したサービスを称え、奨励します。紹介者が採用された場合のボーナスもあります。",
    "mdaBtn": "MARRIOTT DEVELOPMENT ACADEMYを表示",
    "selectInterests": "興味のあるトピックを教えてください。",
    "otherRecommended": "その他のおすすめのコースを表示",
    "searchByName": "興味関心を検索する"
  },
  "plan": {
    "completedRequired": "全ての必修コースを修了しました！",
    "requiredCourses": "必修コース",
    "reqDesc": "期限までに修了してください。",
    "complete": "修了",
    "of": "/",
    "courses": "コース",
    "completedCourses": "修了したコース",
    "completedDesc": "おめでとうございます！最近以下のコースを修了されました。",
    "remaining": "残り",
    "myLearningTitle": "追加済みの学習 ",
    "myLearningSubTitle": "[マイ・プラン] に追加した項目",
    "history": "履歴",
    "myLearningHistoryLinkText": "すべての履歴を見る",
    "jf": "職務",
    "awr": "その他の担当業務 (AWR)",
    "jfAwrDesc": "重要：職務とAWRによってあなたに必要なトレーニングが決定され、マイ・プランに割り当てられます。",
    "jfAwrEdit": "プロフィールを編集",
    "jfAwrEnd": "して更新してください。",
    "tourPlan": "[マイ・プラン] には、期限までに受講する必要のあるすべての必須コースとおすすめコースが表示されています。",
    "tourBasics": "Marriott Basicsには、マリオットでのキャリア開始に役立つリソースが盛り込まれており、雇用期間を通じていつでもアクセスすることができます。Marriott Basicsは、[マイ・プラン] での学習内容を補足するものです。",
    "noCourses": "この時期は必須学習の期限に追われていることと思いますが、今後公開される必須学習にも目を向けてみてください。"
  },
  "team": {
    "title": "チームの管理",
    "subtitle": "チームメンバーのスキルとキャリアを伸ばしましょう。",
    "functions": "業務",
    "viewTeam": "チームを表示",
    "allCourses": "全てのコース",
    "downloadExcel": "Excelレポートをダウンロード"
  },
  "feedbackButton": {
    "havingTrouble": "提案"
  },
  "recommendation": {
    "dismiss": "このおすすめを拒否しますか？"
  },
  "table": {
    "filterTable": "필터 테이블"
  },
  "notifications": {
    "addAssignment2": "%{assignment_name}の%{assignment_type}課題が%{page location}に追加されました。期限は%{due}です。",
    "comingDueAssignment": "%{assignment_name}の%{assignment_type}課題は%{due}に期限を迎えます。",
    "overdueAssignment": "%{assignment_name}の%{assignment_type}課題は期限を過ぎました。",
    "addAssignment": "%{assignment_name}の%{assignment_type}課題が%{page location}に追加されました。",
    "profile": "肩書または勤務地が更新されました。プロフィールを確認し、必要に応じて「ブランド」、「職務」、「その他の担当業務」の情報を更新してください。DLZプロフィールに正確な情報を入力することで、適切な学習課題を受け取ることができます。",
    "helptip": "すべての通知の期限は30日です。",
    "markRead": "既読としてマーク",
    "delete": "削除",
    "markAllRead": "すべて既読としてマーク",
    "settings": "設定",
    "tooltip": "既読通知は30日後に削除されます",
    "reportComplete": "DLZレポートが完了しました。[ダウンロード] をクリックします。",
    "dontShowThisAgain": "今後は表示しない",
    "warningEnabled": "削除警告が有効になりました。",
    "manageEmailNotifications": "メール通知の管理",
    "warningTooltip": "選択すると、通知を削除するたびに警告が表示されます。",
    "changeEmailManagedTooltip": "メールを変更するには、mHUB > HR Direct > 自分 > マイ公開情報の順に開いてください。手順に従って連絡先情報を更新してください。変更後のメールが表示されるまでには 1～2 営業日かかる場合があります。",
    "changeEmailFranchisedTooltip": "メールを変更するには、マネージャーおよび/またはフランチャイズ EID Tool 管理者にお問い合わせください。変更後のメールが表示されるまでには 1～2 営業日かかる場合があります。",
    "deleteAllNotifications": "すべての通知を削除",
    "deleteIndividualHeader": "通知を削除",
    "deleteAllConfirmation": "すべての通知を削除しますか？ これらの通知は、期限が切れるまで通知センターの [削除済み] のセクションに移動されます。",
    "deleteIndividualConfirmation": "この通知を削除しますか？ 削除した通知は、期限が切れるまで通知センターの [削除済み] のセクションに移動されます。",
    "permanentDeleteIndividiualConfirmation": "この通知を完全に削除しますか？ いったん削除すると元に戻せません。",
    "permanentDeleteAllConfirmation": "すべての通知を完全に削除しますか？ いったん削除すると元に戻せません。",
    "notificationDeleted": "通知を削除しました。",
    "notificationsDeleted": "通知を削除しました。",
    "notificationRestored": "通知を復元しました。",
    "notificationsRestored": "通知を復元しました。",
    "enableDeletionWarning": "削除警告が有効です",
    "warningDisabled": "削除警告が無効になりました。",
    "viewAll": "すべて表示",
    "deleteAll": "すべて削除",
    "notificationCenterTitle": "通知センター",
    "markAsRead": "既読としてマーク",
    "noDeletedNotifications": "最新の状態です！",
    "restore": "復元",
    "restoreAll": "すべてを復元",
    "viewCourse": "コースを表示",
    "title": "通知",
    "noNewNotifications": "通知はありません。",
    "cancel": "取消",
    "manageNotificationsCopy": "メール通知の管理\n\n期限を過ぎた学習項目をまとめたメールを週 1 回受け取るかどうかを選択できます。[管理] を選択して、設定を変更してください。"
  },
  "transcript": {
    "history": "受講履歴",
    "completed": "完了済みの課題",
    "assignmentName": "課題名",
    "type": "タイプ",
    "viewAssignment": "課題を表示",
    "assignmentSource": "課題の提供元",
    "requiredAssignments": "必須課題",
    "recommendedAssignments": "推奨課題",
    "voluntary": "任意",
    "trasscript": "トランスクリプト",
    "retraining": "再受講",
    "completionDate": "完了日",
    "assignmentType": "課題タイプ",
    "contentType": "コンテンツタイプ",
    "contentId": "コンテンツID"
  },
  "contentLaunch": {
    "review": "学習内容を確認",
    "start": "学習を始める"
  },
  "survey": {
    "weValue": "皆さんのご意見をお聞かせください。",
    "weValueDescription": "宿泊施設での勤務体験について、5つの簡単な質問にお答えください。",
    "weValueButtonText": "アンケートに参加する",
    "weValueG1Q1A1": "はい",
    "weValueG1Q1A2": "いいえ",
    "strongAgree": "強くそう思う",
    "agree": "そう思う",
    "slightAgree": "ややそう思う",
    "slightDisagree": "あまりそう思わない",
    "disagree": "そう思わない",
    "strongDisagree": "全くそう思わない",
    "weValueG1Q1Title": "前回のシフト勤務でデイリーミーティングに参加しましたか？ (Line-Up、What’s Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments、およびDaily EDITION)",
    "weValueG1Q2Title": "デイリーミーティング (Line-Up、What's Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments、およびDaily EDITION) は、日々ラグジュアリーなサービスを生み出す意欲を与えてくれる。",
    "weValueG1Q3Title": "所属部署は問題の削減に取り組んでいる (ゲストからの報告がないものを含む)。",
    "weValueG1Q4Title": "自分には承認を得なくてもゲストの問題を解決する権限を与えられている。",
    "weValueG1Q5Title": "自分には承認を得なくてもゲストを喜ばせるサービスを提供する権限を与えられている。",
    "weValueG2Q1Title": "宿泊施設のチームメンバーは、ゲストに感動を与えるために期待を超えるサービスを提供している。",
    "weValueG2Q2Title": "所属部署での経験を通じて、ゲストにラグジュアリーなサービスを提供する能力が身に着いた。",
    "weValueG2Q3Title": "ラグジュアリーなサービスを提供するための知識とスキルに自信がある。",
    "weValueG2Q4Title": "所属部署では、ラグジュアリーで清潔で完璧な環境を提供している。",
    "weValueG2Q5Title": "所属部署に関係のない問題が発生した場合でも、自分にはゲストの不安を解消するために取り組む権限を与えられている。",
    "weValueG3Q1Title": "宿泊施設のチームメンバーは、ゲストと同様の配慮を受けている。",
    "weValueG3Q2Title": "所属部署はブランド価値を体現している。",
    "weValueG3Q3Title": "ラグジュアリーなゲスト体験を生み出すために期待を超える仕事をした際は、評価・感謝してもらえる。",
    "weValueG3Q4Title": "仕事を改善するための所属部署の取り組みに参加している。",
    "weValueG3Q5Title": "所属部署では、常にゲストを第一に考えている。",
    "submitButton": "送信",
    "phasetwo": {
      "weValueG1Q1Title": "前回のシフト勤務でデイリーミーティングに参加しましたか （Line-Up、What's Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments、またはDaily EDITION）？",
      "weValueG1Q2Title": "デイリーミーティング（Line-Up、What's Up Meeting、Daily Rehearsal、House Briefing、Authentic Moments、またはDaily EDITION）は、チームメンバーに日々ラグジュアリーなサービスを生み出す意欲を与えてくれる。",
      "weValueG1Q3Title": "私たちのホテルでは問題の削減に取り組んでいる（ゲストからの報告がないものを含む）。",
      "weValueG1Q4Title": "私たちのホテルでは、承認を得なくてもゲストの問題を解決する権限が自分たちに与えられている。",
      "weValueG1Q5Title": "私たちのホテルでは、承認を得なくてもゲストを喜ばせるサービスを提供する権限が自分たちに与えられている。",
      "weValueG2Q1Title": "私たちのホテルでは、ゲストに感動を与えるために期待を超えるサービスを提供している。",
      "weValueG2Q2Title": "ホテルで提供されている学習の機会のおかげで、ラグジュアリーなサービスを提供することができている。",
      "weValueG2Q3Title": "私たちのホテルは、ラグジュアリーなサービスを提供するための知識とスキルに自信がある。",
      "weValueG2Q4Title": "私たちのホテルでは、ラグジュアリーで清潔で完璧な環境を提供している。",
      "weValueG2Q5Title": "所属部署に関係のない問題が発生した場合でも、自分たちにはゲストの不安を解消するために取り組む権限が与えられている。",
      "weValueG3Q1Title": "私たちのホテルでは、ゲストに対するものと同様の配慮を従業員同士が互いに払っている。",
      "weValueG3Q2Title": "私たちのホテルはブランド価値を体現している。",
      "weValueG3Q3Title": "私たちのホテルでは、ラグジュアリーなゲスト体験を生み出すために期待を超える仕事をした際は、評価・感謝してもらえる。",
      "weValueG3Q4Title": "私たちのホテルでは、仕事を改善するための部署の取り組みに参加する機会がある。",
      "weValueG3Q5Title": "私たちのホテルでは、常にゲストを第一に考えている。"
    }
  },
  "gateway": {
    "planProgressComplete": "%{label}完了",
    "gatewayProgressComplete": "GATEWAY進捗状況完了",
    "heroMobileGatewayVideoText": "Gatewayビデオの再生",
    "basics": "基本事項",
    "brandResources": "ブランドリソース",
    "disciplineResources": "部門別のリソース",
    "immersionResourcesHeader": "イマージョンリソース",
    "brandCommunicationsHeaader": "ブランドコミュニケーション",
    "brandContactsHeader": "ブランド問い合わせ先",
    "franchisedActionItemsText": "アクションアイテムは自己管理に役立つもので、主要な新人研修情報を確認することができます。これらのアイテムは必須ではありませんが、フランチャイズ管理会社から受け取る情報を補足するものです。",
    "global": "グローバル",
    "continent": "大陸",
    "viewed": "閲覧済み",
    "completed": "完了",
    "heading": "Gateway",
    "basicsTab": "基本事項",
    "onboardingTab": "アクションアイテム",
    "brandTab": "ブランドリソース",
    "disciplinesTab": "部門別のリソース",
    "basicsHeader": "マリオット・インターナショナルへようこそ。",
    "basicsHeaderNewBrandOrRole": "ようこそ。",
    "basicsText": "キャリアを開始するにあたって、覚えなければならないことはたくさんあります。最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーションやブランド新人研修で詳しく説明するものもありますが、こちらで先に知識を身に着けておくこともできます。",
    "basicsTextGms": "キャリアを開始するにあたって、覚えなければならないことはたくさんあります。最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーションやブランド新人研修で詳しく説明するものもありますが、こちらで先に知識を身に着けておくこともできます。完了したら、アクションアイテム、ブランドリソース、部門別のリソースなど、他のGatewayページに進んでください。",
    "basicsTextFranchise": "覚えなければならないことはたくさんありますが、まずは最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーション、ブランド新人研修またはフランチャイズ管理会社からの情報を補足するものあります。",
    "basicsTextFranchiseGms": "覚えなければならないことはたくさんありますが、まずは最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーション、ブランド新人研修またはフランチャイズ管理会社からの情報を補足するものあります。完了したら、アクションアイテム、ブランドリソース、部門別のリソースなど、他のGatewayページに進んでください。",
    "basicsTextNewToRole": "新しい仕事を始めるにあたり、覚えなければならないことはたくさんあります。最初の数日間向けに基本事項をいくつかセレクトしました。中には指定のトレーニングで詳しく説明するものもありますが、こちらで先に知識を身に着けておくこともできます。",
    "basicsTextNewToRoleGms": "新しい仕事を始めるにあたり、覚えなければならないことはたくさんあります。最初の数日間向けに基本事項をいくつかセレクトしました。中には指定のトレーニングで詳しく説明するものもありますが、こちらで先に知識を身に着けておくこともできます。完了したら、アクションアイテム、ブランドリソース、部門別のリソースなど、他のGatewayページに進んでください。",
    "basicsTextNewToRoleFranchise": "覚えなければならないことはたくさんありますが、まずは最初の数日間向けに基本事項をいくつかセレクトしました。中にはフランチャイズ管理会社からの情報を補足するものもあります。",
    "basicsTextNewToRoleFranchiseGms": "覚えなければならないことはたくさんありますが、まずは最初の数日間向けに基本事項をいくつかセレクトしました。中にはフランチャイズ管理会社からの情報を補足するものもあります。完了したら、アクションアイテム、ブランドリソース、部門別のリソースなど、他のGatewayページに進んでください。",
    "basicsTextNewToBrand": "%{brandName}で新しい仕事を始めるにあたり、覚えなければならないことはたくさんあります。最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーションやブランド新人研修で詳しく説明するものもありますが、こちらで先に知識を身に着けておくこともできます。",
    "basicsTextNewToBrandGms": "%{brandName}で新しい仕事を始めるにあたり、覚えなければならないことはたくさんあります。最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーションやブランド新人研修で詳しく説明するものもありますが、こちらで先に知識を身に着けておくこともできます。完了したら、アクションアイテム、ブランドリソース、部門別のリソースなど、他のGatewayページに進んでください。",
    "basicsTextNewToBrandFranchise": "覚えなければならないことはたくさんありますが、まずは最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーション、ブランド新人研修またはフランチャイズ管理会社からの情報を補足するものあります。",
    "basicsTextNewToBrandFranchiseGms": "覚えなければならないことはたくさんありますが、まずは%{brandName}での最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーション、ブランド新人研修またはフランチャイズ管理会社からの情報を補足するものあります。完了したら、アクションアイテム、ブランドリソース、部門別のリソースなど、他のGatewayページに進んでください。",
    "disciplineHeader": "部門別のリソース",
    "toDo": "必須",
    "optional": "オプション",
    "overallComplete": "全体完了",
    "keyResourcesDesc": "ホテルでの日常業務、ゲスト体験、ビジネスニーズをサポートする主要な部門を詳しく知ることができます。",
    "franchiseFooter": "本資料には、ブランド基準の遵守率を高めるための推奨事項や提案事項が含まれています。フランチャイザーであるマリオットが、フランチャイズ加盟ホテルまたはその業務運営の管理に関与することは一切ありません。フランチャイズ加盟店が引き続き、ホテルの日常業務管理を行い、従業員や雇用慣行に関する責任を負うものとします。",
    "landingHeroTitle1": "マリオット・インターナショナルへようこそ。",
    "landingHeroTitle2": "あなたの旅はここから始まります。",
    "landingHeroText": "The Gatewayは、入社一年目の皆さんをガイドするためのツールです。ここには、上手く仕事をこなすために必要な情報やリソースがそろっています。",
    "landingHeroTitle1NewToRole": "The Gatewayへようこそ。",
    "landingHeroTitle1NewToBrand": "The Gatewayへようこそ。",
    "landingButtonText": "開始",
    "twoMonthslandingHeroTitle1": "おかえりなさい",
    "twoMonthslandingHeroText": "まだの方は、The Gatewayですべての基本事項を完了してください。",
    "twoMonthslandingButtonText": "Gatewayを開く",
    "twoMonthslandingHeroTitle1NewToBrand": "おかえりなさい",
    "twoMonthslandingHeroTextNewToBrand": "%{brandName}での最初の数日間はいかがでしたか。今後もThe Gatewayにあるリソースを活用して、引き続き頑張ってください。",
    "twoMonthslandingButtonTextNewToBrand": "さらに続ける",
    "threeMonthslandingHeroTitle1": "順調に進んでいます！",
    "threeMonthslandingHeroText": "マリオット・インターナショナルについて知っておくべきことは、すべてThe Gatewayにあります。",
    "threeMonthslandingButtonText": "Gatewayを開く",
    "threeMonthslandingHeroTitle1NewToRole": "順調に進んでいます！",
    "threeMonthslandingHeroTextNewToRole": "仕事を上手くこなすために知っておくべきことは、すべてThe Gatewayにあります。",
    "threeMonthslandingButtonTextNewToRole": "Gatewayを開く",
    "threeMonthslandingHeroTitle1NewToBrand": "おかえりなさい",
    "threeMonthslandingHeroTextNewToBrand": "まずは新ブランドでの%{numDays}日間お疲れ様でした。必要なときはいつでも、すべてのアクションアイテム、ブランド、部門のリソースにアクセスできます。",
    "threeMonthslandingButtonTextNewToBrand": "Gatewayを開く",
    "fourMonthslandingHeroTitle1": "お疲れ様でした。基本事項を完了しました。",
    "fourMonthslandingHeroText": "The Gatewayにアクセスして、マリオット・インターナショナルについて知っておくべき知識を手に入れてください。",
    "fourMonthslandingButtonText": "Gatewayを開く",
    "fourMonthslandingHeroTitle1NewToRole": "お疲れ様でした。基本事項を完了しました。",
    "fourMonthslandingHeroTextNewToRole": "The Gatewayにアクセスして、新しい仕事について知っておくべき知識を手に入れてください。",
    "fourMonthslandingButtonTextNewToRole": "Gatewayを開く",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - チェックイン",
    "fourMonthslandingHeroTextNewToBrand": "%{brandName}入社一年目を終えるにあたり、まだの方は必ず、Digital Learning Zoneにある開発リソースを活用して、すべてのアクションアイテムを完了してください。[続行] をクリックして、次にお進みください。",
    "fourMonthslandingButtonTextNewToBrand": "続行",
    "gmSecondVisitlandingHeroTitle1": "おかえりなさい",
    "gmSecondVisitlandingHeroText": "最初の数日間はいかがでしたか。新しい仕事で成長できるよう、引き続き頑張ってください。",
    "gmSecondVisitlandingButtonText": "さらに続ける",
    "gmSecondVisitlandingHeroTextNewToRole": "新しい仕事に就いて最初の数日間はいかがでしたか。今後もThe Gatewayにあるリソースを活用して、引き続き頑張ってください。",
    "gmSecondVisitlandingHeroTextNewToBrand": "%{brandName}での最初の数日間はいかがでしたか。今後もThe Gatewayにあるリソースを活用して、引き続き頑張ってください。",
    "gmThirdVisitlandingHeroTitle1": "おかえりなさい",
    "gmThirdVisitlandingHeroText": "まずは%{numDays}日間お疲れ様でした。必要なときはいつでも、すべてのアクションアイテム、ブランド、部門のリソースにアクセスできます。",
    "gmThirdVisitlandingButtonText": "Gatewayを開く",
    "gmThirdVisitlandingHeroTextNewToRole": "まずは%{numDays}日間お疲れ様でした。必要なときはいつでも、すべてのアクションアイテム、ブランド、部門のリソースにアクセスできます。",
    "gmThirdVisitlandingHeroTextNewToBrand": "まずは新しいブランドでの最初の%{numDays}日間お疲れ様でした。必要なときはいつでも、すべてのアクションアイテム、ブランド、部門のリソースにアクセスできます。",
    "gmSevenMonthslandingHeroTitle1": "完了すべきアクションアイテムが他にもあるようです。",
    "gmSevenMonthslandingHeroText": "一年目を終えるにあたり、Digital Learning Zoneにある開発リソースを活用して、必ずすべてのアクションアイテムを完了してください。[続行] をクリックして、次にお進みください。",
    "gmSevenMonthslandingButtonText": "続行",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - チェックイン",
    "gmSevenMonthslandingHeroTextNewToRole": "一年目を終えるにあたり、まだの方は必ず、Digital Learning Zoneにある開発リソースを活用して、すべてのアクションアイテムを完了してください。[続行] をクリックして、次にお進みください。",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - チェックイン",
    "gmSevenMonthslandingHeroTextNewToBrand": "%{brandName}入社一年目を終えるにあたり、まだの方は必ず、Digital Learning Zoneにある開発リソースを活用して、すべてのアクションアイテムを完了してください。[続行] をクリックして、次にお進みください。",
    "gmOneYearlandingHeroTitle1": "まずは%{brandName}での最初の一年間、お疲れ様でした！",
    "gmOneYearlandingHeroTitle1NewToRole": "まずは一年間お疲れ様でした！",
    "gmOneYearlandingHeroTitle1NewToBrand": "まずは%{brandName}での最初の一年間お疲れ様でした！",
    "gmOneYearlandingHeroText": "The Gatewayの旅は以上で終了となりますが、今後も引き続きご活用ください。The Gatewayのリソースには、いつでもアクセスすることができます。",
    "gmOneYearlandingButtonText": "Gatewayを見る"
  },
  "resources": {
    "iptTooltip": "勤務地が複数ある場合はコンマで区切ってください",
    "iptAllFunctionTooltip": "特定の「担当業務」を選択するには、選択を解除してください。選択した勤務地のすべての業務のトレーニングを確認するには、選択したままにしておいてください。",
    "iptAllAWRTooltip": "特定の「その他の業務」を選択するには、選択を解除してください。選択した勤務地のすべての「その他の業務」のトレーニングを確認するには、選択したままにしておいてください。",
    "editJFTooltip": "入力が複数ある場合はコンマで区切ってください"
  },
  "Notification Center: ": "未読としてマーク",
  "Notification Center: Empty": "最新の状態です！",
  "Notification Center: Individual Delete": "この通知を削除しますか？ 削除した通知は、期限が切れるまで通知センターの [削除済み] のセクションに移動されます。",
  "Notification Center: Delete All": "すべての通知を削除しますか？ これらの通知は、期限が切れるまで通知センターの [削除済み] のセクションに移動されます。",
  "Notification Center: Mark All As Read": "すべて既読としてマーク",
  "Notification Center: Restore All": "すべてを復元",
  "Notification Center: Delete Confirmation": "削除",
  "Warning Message Text: Remove Job Function or AWR": "職務またはその他の担当業務 (AWR) を削除しますか？\r\n送信すると、%{Function and/or AWR} が %{count} 人の学習者から削除されます。",
  "Warning Message Text: Add Job Function or AWR": "職務またはその他の担当業務 (AWR) を追加しますか？\r\n送信すると、%{Function and/or AWR} が %{count} 人の学習者に追加されます。",
  "Button": "送信",
  "Success Message Text: Add Job Function and/or AWR": "成功\r\n%{Job Function and/or AWR} を %{location} の要求したアソシエイトに追加しました。\r\n\r\n%{location} のその他の職務またはその他の担当業務を編集しますか？",
  "Message Text:AWR": "成功\r\nその他の担当業務 %{ __} を %{ __} の要求したアソシエイトに追加しました。\r\n\r\n%{location, property, learner(s)} のその他の職務またはその他の担当業務を編集しますか？",
  "Success Message Text: Remove Job Function": "成功\r\n%{Job Function and/or AWR} を %{location} の要求したアソシエイトから削除しました。\r\n\r\n%{location} のその他の職務またはその他の担当業務を編集しますか？",
  "Success Message Text: Remove AWR": "成功\r\nその他の担当業務 %{ __} を %{ __} の要求したアソシエイトから削除しました。\r\n\r\n%{location, property, learner(s)} のその他の職務またはその他の担当業務を編集しますか？",
  "Tool Tip - Managed Change Email": "メールを変更するには、mHUB > HR Direct > 自分 > マイ公開情報の順に開いてください。指示に従って連絡先情報を更新してください。変更後のメールが表示されるまでには 1～2 営業日かかる場合があります。",
  "Tool Tip - Franchised  Change Email": "メールを変更するには、マネージャーおよび/またはフランチャイズ EID Tool 管理者にお問い合わせください。変更後のメールが表示されるまでには 1～2 営業日かかる場合があります。",
  "User Profile: Email Opt-in": "いいえ、受信を希望しません",
  "User Profile: Email Frequency": "毎週",
  "Generic Notification Email Subject Line:": "DLZ に新しい通知があります。",
  "Generic Body Text (<150 Characters)": "DLZ を開いて詳細をご覧ください。",
  "All Emails: Signature": "Digital Learning Zone - 学習がシンプルになりました。まずは始めてみましょう。",
  "All Emails: Footer": "メールによる通知の配信はいつでも停止/取り消しできます。Digital Learning Zone/プロフィールを開いて設定を変更してください。",
  "Overdue Emails: Subject Line: ": "注意が必要な DLZ 学習課題",
  "Overdue Emails: Body Test": "期限を過ぎた学習課題があります。DLZ にログインして学習課題をご確認ください。",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "スキルを磨く",
    "sharpenYourSkillsSubTitle": "今、そしてこれからも知識とスキルを身に着けてキャリアップを目指しましょう。",
    "growYourCareerTitle": "キャリアアップ",
    "growYourCareerSubTitle": "関心のある分野や目標に合わせて特殊スキルを身に着けたり、クロストレーニングを行ったりすることで、次のステップの計画を立てましょう。",
    "additionalResourcesTitle": "その他の参考資料",
    "additionalResourcesSubTitle": "getAbstract®およびCultureWizard™を見て、DLZがいかにあなたの成長に役立つかを確かめてください。",
    "tourHeader": "最新のDLZへようこそ。必要な情報を必要なときに検索できるようになりました。さらに、最新の [ラーニングの検索] やさらに特化した [マイ・プラン] も登場しました。ツアーを見る",
    "tourBtnSkip": "閉じる",
    "tourBtnNext": "終了する (6/6)",
    "tourSearchBar": "強化された新しい検索機能で、必要な情報を必要なときに見つけることができます。",
    "tourBtnBack": "戻る",
    "tourMyPlan": "[検索] および [探索] から追加した項目が [マイ・プラン] に表示されるようになったため、自分に合った方法で学習を進めることができます。",
    "tourExploreLearningLink": "引き続きここからも [ラーニングの検索] にアクセスできます。",
    "tourExploreLearningProfile": "学習体験をフル活用し、（特に担当業務の）プロフィールを定期的にチェックして、[マイ・プラン] を常に最新の状態に保ちましょう。",
    "mhubTraining": "mHUB トレーニング",
    "growthSkills": "成長スキル",
    "leadershipSkills": "リーダーシップスキル",
    "virtualTeamResources": "バーチャルチームリソース",
    "jobFunctions": "担当業務",
    "brands": "ブランド",
    "languageLearning": "語学学習",
    "become": "ビカム",
    "emeaMentoringProgram": "EMEA メンタリング プログラム",
    "learningPathways": "学習の進路",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare コミュニティハブ",
    "mhubTrainingDesc": "ロールベースの学習パスとmHUBの使い方に関するトピック別のコースを見ることができます。",
    "growthSkillsDesc": "様々な業務やトピックについて、実務に役立つ知識を身に着けることができます。",
    "leadershipSkillsDesc": "チームの管理・指導の方法を学んで、より優れたリーダーを目指しましょう。",
    "virtualTeamResourcesDesc": "バーチャルチーム内で協調的かつインクルーシブな文化を育むためのテクニックを学ぶことができます。",
    "jobFunctionsDesc": "現在の担当業務の知識を広げ、他の人の業務についても学ぶことができます。",
    "brandsDesc": "マリオット・インターナショナルの様々なブランドについて詳しく学ぶことができます。",
    "languageLearningDesc": "グローバルなオーディエンスに響く効果的なコミュニケーションについて学ぶことができます。",
    "becomeDesc": "キャリアアップや自己開発への総合的なアプローチ（内省する、計画を立てる、実行する、明確化する）を学ぶことができます。",
    "emeaMentoringProgramDesc": "EMEAメンタリング プログラムで自分の可能性を引き出しましょう。",
    "learningPathwaysDesc": "各自のリーダーシップレベルに合った具体的な学習機会を見つけることができます。",
    "mdaDesc": "Marriott Development Academyでリーダーシップスキルと職務スキルを身に着けましょう。",
    "takeCareGrowDesc": "インクルーシブでインスピレーションあふれるコミュニティを構築するために作成されたリソースを見つけることができます。",
    "getAbstractDesc": "意思決定能力の強化に役立つブックサマリーで毎日新しいことを学びましょう。",
    "cultureWizardDesc": "価値ある知識を集めて、多様なカルチャーに響く効果的なコミュニケーションについて学ぶことができます。",
    "takeCareCommunityHubDesc": "TakeCareチームビルディングアクティビティ、学習プログラム、各種ツールを見てみましょう。",
    "searchAllLearning": "すべてのラーニングを検索",
    "searchBarPlaceHolder": "学ぶことは常にたくさんあります。",
    "clearFilters": "フィルターを消去",
    "noResults": "検索結果なし",
    "hasBeenUnpinned": "{Variable-tile_Name}のピン留めが解除されました。",
    "hasBeenPinned": "{Variable-tile_Name}がピン留めされました。",
    "lpRemovedFromPlan": "ラーニングパスウェイがプランから削除されました。",
    "courseRemovedFromPlan": "コースがプランから削除されました。",
    "lpAddedToPlan": "ラーニングパスウェイがプランに追加されました。",
    "courseAddedToPlan": "コースがプランに追加されました。",
    "clearSelection": "選択を消去",
    "removeFromMyPlan": "マイ・プランから削除",
    "addToMyPlan": "マイ・プランに追加",
    "loadingResults": "読み込み中",
    "viewDetails": "詳細の表示",
    "clearAll": "すべて消去",
    "noOptions": "使用できるオプションはありません",
    "viewLess": "コンパクトに表示",
    "viewMore": "さらに表示",
    "recentSearch": "最近の検索",
    "exploreLearningTitle": "ラーニングの検索",
    "exploreLearningHelpText": "何を探しているのか分からない場合。",
    "exploreMore": "詳細をご覧ください。",
    "viewAllResults": "<#>件すべての結果を表示",
    "searchSubTitle": "キーワード、タイトル、コンテンツIDを使ってコースを検索できます。",
    "pinnedLearningTitle": "ピン留めされたラーニング",
    "landingSubTitle": "学びの旅を自分のものに。興味のある分野を探しに行きましょう。常に新しいトピックとコースがあなたを待っています。",
    "columnTitle": "タイトル",
    "columnDesc": "説明",
    "columnType": "タイプ",
    "columnAddedToPlan": "プランに追加（済み）",
    "columnComplete": "完了",
    "columnDetails": "詳細",
    "additionalSearchFilters": "その他の検索フィルター",
    "tourBtnClose": "閉じる",
    "tourBtnLast": "閉じる",
    "resultsFor": "の検索結果",
    "resultsFortheFilter": "フィルター{Filter Name}の検索結果",
    "no": "いいえ",
    "results": "結果",
    "courseTooltipTitle": "1 つまたは複数のアクティビティを含む個別の学習項目。<br />コースは、オンライン (動画や Eラーニングなど) または対面クラスがあります。",
    "gatewayToolTipTitle": "新入社員、新たに幹部職に就任した人、新しいブランド担当になった GM 向けのデジタルリソース。",
    "speciallizationOrPathwayTooltipTitle": "特定のトピックに特化した一連のコース。",
    "taskTooltipTitle": "アクティビティ (通常は実地) を完了し、完了とマークをするための手順。",
    "webSiteTooltipTitle": "マリオットのサイトまたは外部のウェブサイトを含むあらゆるウェブサイト。"
  },
  "dontShowAgain": "今後は表示しない",
  "deletionWarningEnabled": "削除警告が有効です",
  "deletionSelected": "選択すると、通知を削除するたびに警告が表示されます。",
  "manageEmailNotifications": "メール通知の管理",
  "seeHowDLZContinue": "DLZがいかにあなたの成長に役立つかを確かめてください。",
  "Notification Center:Overdue": "{Variable date ddmmyy}に期限切れとなった新しいトレーニングが 1 つあります",
  "accesDenied": {
    "title": "学びの旅へのアクセスは現在作成中です！",
    "subtitle": "現在アクセスを作成しており、EIDが有効化されてから1～2日後に利用可能となります。すでに2日以上経っている場合は、mHUBサービスセンターまでお問い合わせください。"
  },
  "jfTour": {
    "title": "選択した職務やその他の担当業務 (AWR) をマイ・プランから直接表示して編集できるようになります。"
  },
  "onboardingPlan": {
    "requiredDescription": "以下は、[マイ・プラン] であなたが選択した期間中に完了する必要のある必須コースです。リーダーに相談して、新しい仕事に慣れながら必須学習を完了するスケジュールを設定してください。さまざまなトピックを学習することになりますので、途中質問があればリーダーや同僚に必ず聞くようにしてください。   ",
    "basicsDescription": "覚えなければならないことはたくさんありますが、まずは最初の数日間向けに基本事項をいくつかセレクトしました。中にはオリエンテーション、ブランド新人研修またはフランチャイズ管理会社からの情報を補足するものあります。これらの資料は、今後のあなたのキャリアにおいて、また新しいチームメンバーが加わった際にも役立ちます。",
    "recommendedDescription": "90日後には、マネージャーが推奨するコースがさらに追加されます。また、自分で興味のあるトピックを探すこともできます。当面は、基礎学習に集中してください。",
    "day1": "期限 - 14日目",
    "day2": "期限 - 30日目",
    "day3": "期限 - 60日目",
    "day4": "期限 - 90日目",
    "day5": "期限 - 90日目以降 "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zoneは、仕事のための学習や自己開発のスタートポイントとなるものです。以下は、仕事を始めるにあたり、あなたにとって役立つ内容をまとめたものです。学習内容はいつでも [マイ・プラン] で確認することができます。  ",
    "overallLearningProgress": "全般的な学習の進捗状況",
    "learningProgressLabel": "学習の進捗状況",
    "learningProgressDescription": "これは必須学習の進捗状況を示しています。このプログレスバーには、すべての必須学習のステータスが含まれています。今後も頑張りましょう！"
  },
  "poc": {
    "dlz": "この学習を完了するには、マネージャーまたは学習コーディネーターがこの学習をmHUBで完了とマークする必要があります。詳しい手順は実施ガイドをご確認ください。",
    "mhub": "フランチャイズ加盟者：独自の基礎クリーニングプログラムを使用する場合は、完了をマークする際に、コメント欄にプログラムの名称と詳細を記入してください"
  },
  "Recommended Courses ": "90日後には、マネージャーが推奨するコースがさらに追加されます。また、自分で興味のあるトピックを探すこともできます。当面は、基礎学習に集中してください。",
  "survey": {
    "v2": {
      "weValueButtonText": "調査を行う",
      "lbiIntro": "ラグジュアリー行動チェックへようこそ！",
      "lbiDescription": `<p>以下では、ホテルで遭遇する可能性のあるいくつかの短いシナリオを読んでいただきます。各シナリオを読み、各状況にどのように対応するかを選択してください。回答する際は、以下の点に留意してください。</p>
      <ul>
          <li>
              <p>複数の回答を選ぶことができるかもしれません。あなたが職場で対応すると思われる行動に最も当てはまる回答を選択してください。</p>
          </li>
          <li>
              <p>コミュニケーションの障壁はないと考えてください。シナリオでは、あなたが話す言語と、ゲストまたはアソシエイトが話す言語は同じものとします。</p>
          </li>
          <li>
              <p>シナリオには、現在の役割とは異なる職務である場合があります。ラグジュアリーホスピタリティの専門家としてのあなたの経験に基づいて、わかる範囲で回答してください。</p>
          </li>
      </ul>
      <p><br></p>
      <p>注意：このチェックへの参加は完全に任意であり (不参加を選択することもできます)、回答は極秘扱いとなります。このチェックの結果は、特定の個人ではなく、グループに対して報告されます。個々の回答は研究チームによって評価され、他社のマネージャーやリーダーと共有されることはありません。</p>
      <p>このチェックを完了することにより、あなたは、あなたの個人的な回答とデータ (勤続年数、性別、役職など) の収集と処理、およびあなたの個人データのマリオットグループの他国への転送に同意することになります。これは、マリオットのアソシエイトデータプライバシーステートメントに準拠しています。ステートメントでは、マリオットのデータプライバシーポリシーに関する追加情報や問い合わせ先も参照することができます。</p>
      `,
      "checkin": {
        q1Title: "あなたはロビーを歩いていて、3人の小さな子供がいる家族に気づきました。家族は多くの荷物を持っており、長旅のストレスで疲れており、子供たちは機嫌が悪く落ち着きがない様子です。どうしますか？",
        q2Title: "あなたは、馴染みのない新しい地域にあるホテルで仕事を始めたばかりです。ゲストから、この地域でできることを提案してほしいと言われました。ゲストはガイドブックを何冊か読んではいますが、よりパーソナライズされたものを探しています。どうしますか？ ",
        q3Title: "あなたがロビーを歩いていると、ゲストが電話で話していて、空港へ行く送迎車が遅れているということを耳にします。あなたのホテルには専用車がないか、利用することができません。どうしますか？ ",
        q4Title: "あなたは、マネージャーから至急のタスクを割り当てられてそれを完了するためにロビーを歩いています。同僚が通常2人で行われるタスクを一人で行おうと苦心していることに気づきました。どうしますか？ ",
        q5Title: "あなたはホテルのレストランのホストを務めています。予約テーブルの準備を待っているゲストからメニューを見たいと言われます。ゲストは別の同僚からこのレストランを提案されたのですが、メニューはゲストの食事ニーズに対応していないため、ゲストは失望しました。どうしますか？",
        q6Title: "混雑したプールエリアには、一緒に座れる座席を見つけられなかったゲストが大勢います。その結果、タオルや身の回り品がメイン通路を塞ぐように置かれて、プールエリアにアクセスしようとするゲストやアソシエイトにとって危険で混乱した状況になってしまいました。そのエリアから荷物を動かして通れるようにしなければなりませんが、その人たちの気分を害したくはありません。どうしますか？",
        q1R1Title: "荷物を運ぶのを手伝い、チェックインデスクの方向を示し、家族向けのアクティビティでリラックスできる近くの場所を提案する。ホテルのファミリーアメニティについて言及する。",
        q1R2Title: "荷物を運び、家族をチェックインに案内する。その後、リラックスできるサービス、親向けのお菓子や子供向けの楽しいアクティビティを客室内で楽しめるように手配する。",
        q1R3Title: "親をチェックインへと案内し、親がチェックインしている間、子供をお菓子が用意されている座席エリアに連れて行く。ホテルで利用できるプレイエリアやキッズアクティビティについて、両親に伝えるようにとフロントデスクに依頼する。",
        q1R4Title: "迅速なチェックイン手続きのために、家族にチェックインデスクの方向を示す。子供が滞在を快適に楽しめるように、子供向けのエリアやサービスについて親に伝える。",
        q2R1Title: "ゲストに何が好きかを尋ね、同僚と協力して、ゲスト向けに楽しめることのリストを作る。また、周辺の素晴らしいレストランを薦める。",
        q2R2Title: "ゲストと、ゲストが好きなことについて話す。同僚と協力して、ゲストのために楽しめることを挙げた特別なリストを作る。また、ゲストのMarriott Bonvoyプロフィールを確認し、客室にゲストのためにパーソナライズしたお菓子を届ける。",
        q2R3Title: "この地域で現時点で自分が好きなことを共有する。自分の経験を詳しく共有することにより、自分の提案を際立たせる。その後、美味しい地元のお菓子を客室に届ける。",
        q2R4Title: "あなたがこの地域にまだ慣れておらず、あまりアイデアがないことをゲストに伝える。申し訳ないと詫びて、詳しい情報を持っている同僚に尋ねるように提案する。そして、地元のお菓子を客室に届けてもらいたいかを尋ねる。",
        q3R1Title: "ゲストがまだ電話中に、自分の車、または同僚の車をホテルの正面につけて、ゲストに自分が個人的に空港に送迎すると伝える。",
        q3R2Title: "ゲストがまだ電話中に、ホテル承認済みの送迎サービスのリストと電話番号をまとめる。ゲストが電話を切ったら、そのリストを渡して、車の手配を申し出る。",
        q3R3Title: "ゲストが電話を切るまで待ち、ホテルには送迎サービスがないものの、他の送迎オプションを手配できることを伝える。",
        q3R4Title: "ゲストがまだ電話中に、代替交通手段を見つける。ゲストが電話を切ったら、空港への送迎手配は済んでおり、車がまもなく到着することを伝える。",
        q4R1Title: "同僚のところに行き、手助けしたいが、まず至急のタスクを完了しなければならないと伝える。すぐに戻ってきて手伝うことを約束する。自分のマネージャーにこの状況について必ず伝えるようにする。",
        q4R2Title: "まず、自分のタスクを代わりに行ってくれる同僚を迅速に見つける。そして、助けが必要な同僚をすぐに手伝う。そのタスクが終わったら、同僚を手伝うために自分のタスクは他の人に頼んだことをマネージャーに伝える。",
        q4R3Title: "すぐに同僚を手伝う。手助けした後、自分のタスクに戻り、その後、自分のタスクを保留にして同僚に手を貸したことをマネージャーに伝える。",
        q4R4Title: "助けが必要な同僚のところに行き、自分には至急のタスクがあることを説明する。両方のタスクを中断し、両方のタスクを共に解決する方法をブレインストーミングして、一緒に両方のタスクを完了する。",
        q5R1Title: "ゲストに謝罪して、マネージャーを呼んでくるとゲストに伝える。マネージャーがゲストの食事ニーズに対応できるように、状況を必ず伝えるようにする。",
        q5R2Title: "ゲストに謝罪して、食事の好みについて尋ねる。ゲスト向けに特別な料理を用意ができるかどうか、キッチンチームに相談する。また、手違いがあったことの埋め合わせに、前菜やドリンクを無料で提供する。",
        q5R3Title: "謝罪して、状況を理解したことをゲストに伝える。予約をキャンセルする代わりに、ゲストのために特別な料理を用意できるかどうか、シェフと話すことを申し出る。面倒をかけたことの埋め合わせに、無料のお菓子や割引を提供する。",
        q5R4Title: "謝罪して、ゲストが好きな食事ができる周辺のレストランの予約を手配すると申し出る。追加情報やそこに行くためのサポートを申し出る。",
        q6R1Title: "スペースが足りないことをゲストに謝罪し、周りを歩く人に迷惑がかからない限りその場所にいてかまわないと伝える。",
        q6R2Title: "歩道を塞いではならないので、別の場所を見つけるか、場所が空くまで待つ必要があることをゲストに丁重に伝える。また、ゲストが使えるように、ボールルームから追加の座席を屋外に出してもらうよう同僚に依頼する。",
        q6R3Title: "ゲストに、現在の場所にはいられないこと、また、あなたが空いている座席を見つけるので、一時的に移動してもらいたいことを伝える。待つ間、ゲストにバー/レストランのスペースを提供する。 ",
        q6R4Title: "座席の問題をゲストに謝罪する。他の人が歩くスペースを少し作るために、荷物をまとめることができるかをゲストに尋ねる。皆が快適に過ごせるよう、ゲストに手助けを申し出る。不便をかけたお詫びに、バー/レストランから特別にお菓子を届ける。"
      }
    }
  },
};