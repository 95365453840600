export default {
  "banner": {
    "viewDetails": "عرض التفاصيل",
    "requiredCourses": "دوراتك التدريبية المطلوبة واجبة الأداء في",
    "days": "أيام"
  },
  "header": {
    "plan": "خطتي",
    "manager": "المدير",
    "explore": "استكشاف التعلم",
    "reporting": "إعداد التقارير",
    "myTeam": "فريقي",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "خطتي",
    "manager": "المدير",
    "explore": "استكشاف التعلم",
    "tagline": "تبسيط التعلم. إنه يبدأ بك أنت."
  },
  "welcome": {
    "hi": "مرحبًا",
    "intro1": "مرحبًا بك في Digital Learning Zone! إن DLZ هو نظام تعلم مخصص يمثل نقطة وصول مركزية إلى محتوى التعلم الخاص بشركة ماريوت. ابحث عن التدريب المطلوب لدورك الوظيفي واستكشف فرص التعلم المتاحة لتطورك الشخصي والمهني. تولى مسؤولية رحلة تعلمك وابدأ مسار تنمية مهاراتك.",
    "intro2": "قبل البدء، نحتاج إلى معرفة بعض الأشياء عنك.",
    "letsGo": "هيا بنا",
    "preferredLanguage": "ما لغتك المفضلة؟",
    "step": "الخطوة",
    "of": "من",
    "previous": "السابق",
    "next": "التالي",
    "startDate": "متى بدأت العمل في Marriott International أو Starwood؟",
    "selectYear": "تحديد عام",
    "selectMonth": "تحديد شهر",
    "selectDay": "تحديد تاريخ",
    "selectFunction": "تحديد وظيفة",
    "selectInterests": "تحديد الاهتمامات",
    "chooseYear": "اختيار عام",
    "chooseMonth": "اختيار شهر",
    "chooseDay": "احتيار يوم",
    "whatDo": "ما هي مهامك الوظيفية",
    "selected": "محددة",
    "searchFunctions": "بحث في جميع المهام الوظيفية",
    "searchInterests": "بحث في كل الاهتمامات",
    "additionalJobFunctionsLabel": "أخبرنا بالمزيد عما تقوم به",
    "managementLevelLabel": "ما مستوى زميلك في العمل؟",
    "preferredBrandLabel": "إذا كنت تعمل لدى أكثر من سلسلة فنادق، يُرجى تحديد جميع ما ينطبق عليك.",
    "additionalJobFunctionsLabelExtra": "يُرجى مراجعة ما يلي وتحديد مسؤولية وظيفية إضافية واحدة على الأقل.",
    "searchAdditionalFunctions": "بحث في جميع مسؤوليات العمل الإضافية",
    "highGuestContactLabel": "هل لديك اتصالات كثيرة مع النزلاء؟",
    "yes": "نعم",
    "no": "لا "
  },
  "landing": {
    "started": "فلنبدأ",
    "welcomeBack": "مرحبًا بعودتك،",
    "greatWork": "عمل رائع",
    "requiredCourses": "دوراتك التدريبية المطلوبة",
    "requiredCoursesDesc": "يرجى إكمال الدورات التدريبية قبل تاريخ وجوب الأداء.",
    "myPlan": "خطتي",
    "courses": "الدورات التدريبية",
    "courseComplete": "تم إكمال الدورة التدريبية",
    "viewMore": "عرض المزيد من الدورات التدريبية المطلوبة",
    "viewMoreBtn": "عرض المزيد من الدورات التدريبية",
    "showing": "عرض",
    "of": "من",
    "heroTitle": "تعلم مهارة جديدة. ارفع مستوى قدراتك. اكتسب مهارات مناسبة لعالمك.",
    "heroParagraph1": "يمكنك تتبع كل دوراتك التدريبية المطلوبة بسهولة والحصول على إخطارات في الوقت الفعلي لإكمالها.",
    "heroParagraph2": "استمتع بتجارب تعلم مخصصة بناءً على وظيفتك واهتماماتك.",
    "recommendedCoursesTitle": "الدورات التدريبية الموصى بها",
    "recommendedCoursesDescNoContent": "لا يوجد لديك أي دورات تدريبية موصى بها في هذا الوقت. يرجى الاطلاع على المعلومات أدناه لاستكشاف فرص التعلم الأخرى.",
    "recommendedCoursesDesc": "شاهد ما اختاره مديرك أو أخصائي الموارد البشرية لك.",
    "recommendedCoursesBtn": "عرض الدورات التدريبية الموصى بها",
    "completedCoursesBtn": "عرض الدورات التدريبية المكتملة",
    "exploreTitle": "حافظ على حب الاستطلاع لديك.",
    "exploreDesc": "حدد الموضوعات التي تهتم بها.",
    "jobFunctions": "التهذيب",
    "growthSkills": "مهارات التطور",
    "leadershipSkills": "مهارات القيادة",
    "brands": "سلاسل الفنادق",
    "languageLearning": "تعلم اللغات",
    "exploreBtn": "عرض الدورات التدريبية",
    "managerRecommendation": "توصية المدير",
    "learningRecommendation": "توصية التعلم",
    "marriottRecommendation": "توصية MARRIOTT",
    "completedCourses": "لقد أكملت جميع الدورات التدريبية المطلوبة منك!",
    "investTitle": "تولى مسؤولية رحلة تعلمك",
    "investParagraph1": "نحن ندرك أن الظروف الحالية صعبة من نواح عدة - DLZ هنا كمورد لك. سواء كنت ترغب في الحفاظ على مهاراتك الحالية، تعلم شيء جديد، التعامل مع التوتر اليومى، أو تعلم كيفية العمل مع  فريق افتراضي. لقد عملنا على  جمع الموارد الاساسية لدعمكم.",
    "updatedTopics": "الموضوعات المحدثة",
    "workingRemotely": "موارد الفريق الافتراضى",
    "relatedCoursesNew": "انقر أدناه لتحديث اهتماماتك، حيث أضفنا موضوعات ودورات جديدة.",
    "relatedCourses": "يرجى النقر أدناه لتحديد اهتماماتك.",
    "notificationText": "هناك تدريب جديد متوفر على mHUB. اختر mHUB في Explore Learning.",
    "mHubHeroTitle1": "بساطة. ذكاء. أمان. نقدّم mHUB.",
    "mHubHeroManagedText1": "يجمع mHUB أدوات التعلم، والتطور المهني، وخصومات Explore، والمزيد في نظام واحد سهل الاستخدام.",
    "mHubHeroFranchisedText1": "يجمع mHUB أدوات التعلم، وخصومات Explore، والمزيد في نظام واحد سهل الاستخدام.",
    "mHubHeroVisitLink": "انقر هنا لعرض تفاصيل العروض الإضافية أو المرفقات.",
    "myGateway": "My Gateway",
    "gatewayReview": "يرجى المراجعة والاستكمال.",
    "gatewayProgress": "التقدم في Gateway",
    "complete": "اكتمل",
    "reviewContent": "عرض الموارد",
    "gatewayCardTitle": "موارد Gateway",
    "gatewayCardDesc": "قم بالوصول إلى العناصر الأساسية الخاصة بك للعام الأول وما يليه.",
    "gatewayCardDescGms": "قم بالوصول إلى العناصر الأساسية الخاصة بك، وعناصر الإجراءات، وموارد سلسلة الفنادق، والموارد حسب التخصص للعام الأول وما يليه.",
    "gatewayCardDescNew": "قم بالوصول إلى العناصر الأساسية الخاصة بك للعام الأول في دورك الوظيفي الجديد.",
    "gatewayCardDescGmsNew": "قم بالوصول إلى العناصر الأساسية الخاصة بك، وعناصر الإجراءات، وموارد سلسلة الفنادق، والموارد حسب التخصص للعام الأول في دورك الوظيفي الجديد وما يليه."
  },
  "courseBundle": {
    "completeBy": "تم الإكمال من قِبل",
    "remainingCourses": "الدورات التدريبية المتبقية",
    "completedCourses": "الدورات التدريبية المكتملة",
    "remainingLabel": "توجد {courseCount} دورات تدريبية في مسار التعلم هذا.",
    "recommendButton": "التوصية للفريق",
    "startCourse": "بدء الدورة التدريبية",
    "durationMins": "دقائق",
    "completed": "مكتملة",
    "back": "رجوع",
    "additionalLangs1": "بالإضافة إلى تقديم هذه الدورة التدريبية باللغة الإنجليزية، فإنها متاحة الآن باللغة",
    "additionalLangs2": "يرجى تحديد لغتك المفضلة من القائمة المنسدلة بعد تشغيل الدورة التدريبية.",
    "targetAudience": "الجمهور المستهدف",
    "all": "الكل",
    "globally": "على مستوى العالم",
    "source": "المصدر",
    "courseType": "نوع الدورة التدريبية",
    "course": "الدورة التدريبية"
  },
  "profile": {
    "save": "حفظ",
    "edit": "تحرير",
    "brand": "سلسلة الفنادق",
    "brands": "سلاسل الفنادق",
    "additionalWork": "كل مسؤوليات العمل الإضافية",
    "startDateLabel": "متى بدأت العمل في Marriott International أو Starwood؟",
    "preferredLanguageLabel": "ما لغتك المفضلة؟",
    "jobFunctionsLabel": "ما وظيفتك؟",
    "interestsLabel": "ما الذي تريد تعلمه؟",
    "brandsLabel": "ما سلسلة (سلاسل) الفنادق التي تعمل بها؟",
    "additionalJobFunctionsLabel": "ما وظائف العمل الإضافية التي تؤديها؟",
    "managementLevelLabel": "ما مستوى الزميل الذي توجد فيه",
    "highGuestContactLabel": "هل لديك اتصالات كثيرة مع النزلاء؟"
  },
  "courseDetails": {
    "completeBy": "المهام التي يتعين أداؤها",
    "recommendTitlte": "التوصية بالدورة التدريبية لفريقك",
    "courseName": "اسم الدورة التدريبية",
    "sendRecommendation": "إرسال التوصية"
  },
  "explore": {
    "relatedCourses": "يرجى النقر أدناه لتحديد اهتماماتك.",
    "chooseInterests": "اختر اهتماماتك",
    "title": "استكشاف التعلم",
    "chooseInterestsModal": "اختيار الاهتمامات",
    "oppsTitle": "استكشاف فرص التعلم",
    "growWithUsTitle": "التطور معنا",
    "growWithUsDesc": "تؤمن Marriott بمجال عمل يتطور ويزدهر معك. إننا نؤمن أيضًا بأن التجارب الأكثر تنوعًا غالبًا ما تقدم تجربة أكثر ثراءً.",
    "growWithUsBtn": "عرض \"التطور معنا\"",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "تقدر Marriott النجاح والالتزام. نقدر القيادة والخدمة الاستثنائية ونشجعهما. نعطي حوافز أيضًا للإحالات الناجحة.",
    "mdaBtn": "عرض MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "أخبرنا بالموضوعات التي تثير اهتمامك.",
    "otherRecommended": "الدورات التدريبية الأخرى الموصى بها",
    "searchByName": "ابحث عن اهتماماتك"
  },
  "plan": {
    "completedRequired": "لقد أكملت جميع الدورات التدريبية المطلوبة منك!",
    "requiredCourses": "الدورات التدريبية المطلوبة",
    "reqDesc": "يرجى إكمالها قبل تواريخ وجوب أدائها.",
    "complete": "تم",
    "of": "من",
    "courses": "الدورات التدريبية",
    "completedCourses": "الدورات التدريبية المكتملة",
    "completedDesc": "تهانينا! لقد أكملت مؤخرًا الدورات التدريبية أدناه.",
    "remaining": "متبقٍ",
    "myLearningTitle": "مهام التعلم المضافة من جانبي ",
    "myLearningSubTitle": "العناصر التي قمت بإضافتها إلى \"خطتي\"",
    "history": "السجل",
    "myLearningHistoryLinkText": "الاطلاع على سجلي الكامل",
    "jf": "مهامك الوظيفية",
    "awr": "مسؤوليات العمل الإضافية (AWR) الخاصة بك",
    "jfAwrDesc": "مهم: تحدد المهام الوظيفية ومسؤوليات العمل الإضافية التدريبات المطلوبة والمعينة لك في \"خطتي\". ",
    "jfAwrEdit": "قم بتحرير ملف تعريفك",
    "jfAwrEnd": "لتحديثه.",
    "tourPlan": "تتضمن \"خطتي\" جميع الدورات التدريبية المطلوبة منك والموصى بها لك والتي يلزم إكمالها بحلول الموعد المحدد",
    "tourBasics": "تتضمن \"أساسيات ماريوت\" موارد لمساعدتك في بدء رحلتك في ماريوت ويمكن الوصول إليها في أي وقت خلال مسيرتك المهنية. تعد \"أساسيات ماريوت\" مكمّلة للدورات التدريبية الموجودة في \"خطتي\".",
    "noCourses": "لقد أتممت الدورات التدريبية واجبة الأداء في الوقت الحالي، يمكنك الآن النظر إلى المستقبل والبدء في الدورات التدريبية المطلوبة القادمة"
  },
  "team": {
    "title": "إدارة فريقك",
    "subtitle": "قم بتطوير مهاراتهم ومجالات عملهم.",
    "functions": "الوظائف",
    "viewTeam": "عرض الفريق",
    "allCourses": "جميع الدورات التدريبية",
    "downloadExcel": "تنزيل تقرير Excel"
  },
  "feedbackButton": {
    "havingTrouble": "اقتراحات"
  },
  "recommendation": {
    "dismiss": "هل تريد بالتأكيد تجاهل هذه التوصية؟"
  },
  "table": {
    "filterTable": "筛选表"
  },
  "notifications": {
    "addAssignment2": "تمت إضافة مهمة %{assignment_type} لـ %{assignment_name} إلى %{page location} الخاصة بك وهي مستحقة في %{due}.",
    "comingDueAssignment": "يحين موعد استحقاق مهمة %{assignment_type} الخاصة بك لـ %{assignment_name} قريبًا في %{due}.",
    "overdueAssignment": "مهمة %{assignment_type} الخاصة بك لـ %{assignment_name} متأخرة الآن.",
    "addAssignment": "تمت إضافة مهمة %{assignment_type} لـ %{assignment_name} إلى %{page location} الخاصة بك.",
    "profile": "تم اكتشاف تحديث للمسمى الوظيفي أو الموقع. يُرجى مراجعة ملف تعريفك لإجراء أي تحديثات قابلة للتطبيق على سلسلة الفنادق أو الوظيفة أو مسؤولية العمل الإضافية. تساعد معلومات ملف تعريف DLZ الدقيقة في ضمان استلامك لمهام التعلم الصحيحة.",
    "helptip": "تنتهي صلاحية جميع الإعلامات بعد 30 يومًا.",
    "markRead": "وضع علامة كمقروء",
    "delete": "حذف",
    "markAllRead": "وضع علامة على الكل كمقروء",
    "settings": "الإعدادات",
    "tooltip": "سيتم حذف الإعلامات المقروءة في خلال 30 يومًا",
    "reportComplete": "انتهي تقرير DLZ الخاص بك. انقر للتنزيل",
    "dontShowThisAgain": "عدم إظهار ذلك مرة أخرى",
    "warningEnabled": "تم تمكين التحذير من الحذف.",
    "manageEmailNotifications": "إدارة إعلامات البريد الإلكتروني",
    "warningTooltip": "عند تحديد هذا الخيار، سترى رسالة تحذير في كل مرة تقوم فيها بحذف إعلام.",
    "changeEmailManagedTooltip": "لتغيير بريدك الإلكتروني، تفضل بزيارة mHUB >‏ HR Direct >‏ Me (معلوماتي) >‏ My Public Info (معلوماتي العامة). اتبع التوجيهات لتحديث تفاصيل الاتصال الخاصة بك. قد يستغرق الأمر فترة تتراوح من يوم إلى يومين عمل حتى يتم عرض البريد الإلكتروني المحدث.",
    "changeEmailFranchisedTooltip": "لتغيير بريدك الإلكتروني، اتصل بمديرك و/أو بمسؤول EID Tool في شركة إدارة حقوق الامتياز. قد يستغرق الأمر فترة تتراوح من يوم إلى يومين عمل حتى يتم عرض البريد الإلكتروني المحدث.",
    "deleteAllNotifications": "حذف كل الإعلامات",
    "deleteIndividualHeader": "حذف الإعلام",
    "deleteAllConfirmation": "هل تريد بالتأكيد حذف كل الإعلامات؟ سيتم نقلها إلى قسم المحذوفات في مركز الإعلامات إلى أن تنتهي صلاحيتها.",
    "deleteIndividualConfirmation": "هل تريد بالتأكيد حذف هذا الإعلام؟ يتم نقل الإعلامات المحذوفة إلى قسم المحذوفات في مركز الإعلامات إلى أن تنتهي صلاحيتها.",
    "permanentDeleteIndividiualConfirmation": "هل تريد بالتأكيد حذف هذا الإعلام بصورة نهائية؟ لا يمكن التراجع عن هذا الإجراء.",
    "permanentDeleteAllConfirmation": "هل تريد بالتأكيد حذف كل الإعلامات بصورة نهائية؟ لا يمكن التراجع عن هذا الإجراء.",
    "notificationDeleted": "تم حذف الإعلام.",
    "notificationsDeleted": "تم حذف الإعلامات.",
    "notificationRestored": "تمت استعادة الإعلام.",
    "notificationsRestored": "تمت استعادة الإعلامات.",
    "enableDeletionWarning": "التحذير من الحذف ممكّن",
    "warningDisabled": "تم تعطيل التحذير من الحذف.",
    "viewAll": "عرض الكل",
    "deleteAll": "حذف الكل",
    "notificationCenterTitle": "مركز الإعلامات",
    "markAsRead": "تحديد كمقروء",
    "noDeletedNotifications": "أنت على دراية بأحدث المستجدات!",
    "restore": "استعادة",
    "restoreAll": "استعادة الكل",
    "viewCourse": "عرض الدورة التدريبية",
    "title": "الإعلامات",
    "noNewNotifications": "ليس لديك إعلامات.",
    "cancel": "إلغاء",
    "manageNotificationsCopy": "إدارة إعلامات البريد الإلكتروني\n\nاختر ما إذا كنت ترغب في استلام رسائل بريد إلكتروني أسبوعية تلخص عناصر التعلم المتأخرة. حدد Manage (إدارة) لتعديل تفضيلاتك."
  },
  "transcript": {
    "history": "السجل الشخصي",
    "completed": "المهام المكتملة",
    "assignmentName": "اسم المهمة",
    "type": "النوع",
    "viewAssignment": "عرض المهمة",
    "assignmentSource": "مصدر المهمة",
    "requiredAssignments": "المهام المطلوبة",
    "recommendedAssignments": "المهام الموصى بها",
    "voluntary": "اختيارية",
    "trasscript": "النص",
    "retraining": "إعادة التدريب",
    "completionDate": "تاريخ الإكمال",
    "assignmentType": "نوع المهمة",
    "contentType": "نوع المحتوى",
    "contentId": "معرف المحتوى"
  },
  "contentLaunch": {
    "review": "مراجعة التعلم",
    "start": "بدء التعلم"
  },
  "survey": {
    "weValue": "نوّد معرفة آرائك.",
    "weValueDescription": "يرجى الإجابة عن خمسة أسئلة سريعة بخصوص تجربتك في العمل لدى فندقك.",
    "weValueButtonText": "إجراء الاستطلاع",
    "weValueG1Q1A1": "نعم",
    "weValueG1Q1A2": "لا",
    "strongAgree": "أوافق تمامًا",
    "agree": "أوافق",
    "slightAgree": "أوافق نوعًا ما",
    "slightDisagree": "أرفض نوعًا ما",
    "disagree": "أرفض",
    "strongDisagree": "أرفض تمامًا",
    "weValueG1Q1Title": "هل حضرت اجتماعًا يوميًا خلال نوبة عملك الماضية؟ (Line-Up، أو What’s Up Meeting، أو Daily Rehearsal، أو House Briefing، أو Authentic Moments، أو Daily EDITION)",
    "weValueG1Q2Title": "يحفّزني الاجتماع اليومي (Line-Up، أو What's Up Meeting، أو Daily Rehearsal، أو House Briefing، أو Authentic Moments، أو Daily EDITION) لتقديم خدمة فاخرة كل يوم.",
    "weValueG1Q3Title": "يركز القسم لدي على تقليل أوجه القصور، حتى تلك التي لم ترد في شكاوى النزلاء.",
    "weValueG1Q4Title": "أمتلك الصلاحيات اللازمة لحل مشكلات النزلاء دون الحاجة لطلب الإذن بذلك أولاً.",
    "weValueG1Q5Title": "أمتلك الصلاحيات اللازمة لإسعاد النزلاء دون الحاجة لطلب الإذن بذلك أولاً.",
    "weValueG2Q1Title": "ألمس دائمًا حرص أفراد فريق العمل في الفندق لدي على تجاوز كل التوقعات في سبيل إبهار النزلاء بمستوى الاهتمام الذي يقدمونه.",
    "weValueG2Q2Title": "أتاحت لي الخبرات التي اكتسبتها في هذا القسم تقديم خدمة فاخرة.",
    "weValueG2Q3Title": "أثق بقدرتي على تقديم خدمة فاخرة بفضل المعرفة والمهارات التي أمتلكها.",
    "weValueG2Q4Title": "في القسم لدي، نوفر بيئة فاخرة ونظيفة دون أوجه قصور.",
    "weValueG2Q5Title": "حتى عندما أواجه مشكلة بعيدة عن القسم لدي، فإنني أمتلك صلاحية التدخل وحل المشكلة التي تؤرق النزيل.",
    "weValueG3Q1Title": "في الفندق لدي، يلقى أفراد فريق العمل نفس مستوى الاهتمام الذي يلقاه النزيل.",
    "weValueG3Q2Title": "يُجسد قسمي قيم سلسلة الفنادق.",
    "weValueG3Q3Title": "عندما أتجاوز كل التوقعات من أجل تقديم تجربة فاخرة للنزيل، فإن عملي يكون موضع اعتبار وتقدير.",
    "weValueG3Q4Title": "أشارك في الجهود التي يبذلها القسم لدينا في سبيل الارتقاء بمستوى عملنا.",
    "weValueG3Q5Title": "في القسم لدي، يمثل العميل صدارة اهتماماتنا دائمًا.",
    "submitButton": "إرسال",
    "phasetwo": {
      "weValueG1Q1Title": "هل حضرت اجتماعًا يوميًا خلال نوبة عملك الماضية؟ (Line-Up أو What's Up Meeting أو Daily Rehearsal أو House Briefing أو Authentic Moments  أو Daily EDITION)؟",
      "weValueG1Q2Title": "يحفّز الاجتماع اليومي (Line-Up أو What's Up Meeting أو Daily Rehearsal أو House Briefing أو Authentic Moments أو Daily EDITION) أفراد فريق العمل لتقديم خدمة فاخرة كل يوم.",
      "weValueG1Q3Title": "في فندقي، نركز على تقليل أوجه القصور، حتى تلك التي لم ترد في شكاوى النزلاء.",
      "weValueG1Q4Title": "في فندقي، نمتلك الصلاحيات اللازمة لحل مشكلات النزلاء دون الحاجة لطلب الإذن بذلك أولاً.",
      "weValueG1Q5Title": "في فندقي، نمتلك الصلاحيات اللازمة لإسعاد النزلاء دون الحاجة لطلب الإذن بذلك أولاً.",
      "weValueG2Q1Title": "في فندقي، نتجاوز دائمًا كل التوقعات في سبيل إبهار النزلاء بمستوى الاهتمام الذي نقدمه.",
      "weValueG2Q2Title": "تتيح لنا فرص التعلم المتاحة في الفندق تقديم خدمة فاخرة.",
      "weValueG2Q3Title": "في فندقي، نثق بقدرتنا على تقديم خدمة فاخرة بفضل المعرفة والمهارات التي نمتلكها.",
      "weValueG2Q4Title": "في فندقي، نوفر بيئة فاخرة ونظيفة دون أوجه قصور.",
      "weValueG2Q5Title": "حتى عندما نواجه مشكلة بعيدة عن القسم لدينا، فإننا نمتلك صلاحية التدخل وحل المشكلة التي تؤرق النزيل.",
      "weValueG3Q1Title": "في فندقي، نلقى من بعضنا البعض نفس مستوى الاهتمام الذي نقدمه للنزيل.",
      "weValueG3Q2Title": "يُجسّد فندقي قيم سلسلة الفنادق.",
      "weValueG3Q3Title": "في فندقي، عندما نتجاوز كل التوقعات من أجل تقديم تجربة فاخرة للنزيل، فإن عملنا يكون موضع اعتبار وتقدير.",
      "weValueG3Q4Title": "في فندقي، تُتاح لنا فرصة المشاركة في الجهود التي يبذلها القسم لدينا في سبيل الارتقاء بمستوى عملنا.",
      "weValueG3Q5Title": "في فندقي، نضع العميل في صدارة اهتماماتنا دائمًا."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} مكتمل",
    "gatewayProgressComplete": "اكتمل التقدم في GATEWAY",
    "heroMobileGatewayVideoText": "تشغيل مقطع فيديو Gateway",
    "basics": "العناصر الأساسية",
    "brandResources": "موارد سلسلة الفنادق",
    "disciplineResources": "الموارد حسب التخصص",
    "immersionResourcesHeader": "موارد الإلمام الدقيق بسلسلة الفنادق",
    "brandCommunicationsHeaader": "مراسلات سلسلة الفنادق",
    "brandContactsHeader": "جهات اتصال سلسلة الفنادق",
    "franchisedActionItemsText": "تساعدك عناصر الإجراءات في تنظيم مهام عملك باستمرار وتمنحك معلومات أساسية حول الالتحاق بالعمل. هذه العناصر غير مطلوبة وهي مكملة للمعلومات التي قد تحصل عليها من شركة إدارة حقوق الامتياز.",
    "global": "جميع أنحاء العالم",
    "continent": "القارة",
    "viewed": "المشاهد",
    "completed": "المكتمل",
    "heading": "Gateway",
    "basicsTab": "العناصر الأساسية",
    "onboardingTab": "عناصر الإجراءات",
    "brandTab": "موارد سلسلة الفنادق",
    "disciplinesTab": "الموارد حسب التخصص",
    "basicsHeader": "مرحبًا بك في Marriott International",
    "basicsHeaderNewBrandOrRole": "مرحبًا بك،",
    "basicsText": "وأنت بصدد بدء رحلتك المهنية، نعرف أن هناك الكثير أمامك لتعلمه. لقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. يمكن استعراض بعض من هذه العناصر بمزيد من التفصيل في البرامج التوجيهية أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، ولكن ستساعدك هذه المعلومات في بدء العمل.",
    "basicsTextGms": "وأنت بصدد بدء رحلتك المهنية، نعرف أن هناك الكثير أمامك لتعلمه. لقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. يمكن استعراض بعض من هذه العناصر بمزيد من التفصيل في البرامج التوجيهية أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، ولكن ستساعدك هذه المعلومات في بدء العمل. بعد الانتهاء، انتقل إلى صفحات Gateway الأخرى، التي تتضمن عناصر الإجراءات، وموارد سلسلة الفنادق، والموارد حسب التخصص.",
    "basicsTextFranchise": "نعرف أن هناك الكثير أمامك لتعلمه، وقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. قد تكون هذه العناصر مكملة للبرامج التوجيهية، أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، أو المعلومات المقدمة من شركة إدارة حقوق الامتياز.",
    "basicsTextFranchiseGms": "نعرف أن هناك الكثير أمامك لتعلمه، وقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. قد تكون هذه العناصر مكملة للبرامج التوجيهية، أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، أو المعلومات المقدمة من شركة إدارة حقوق الامتياز. بعد الانتهاء، انتقل إلى صفحات Gateway الأخرى، التي تتضمن عناصر الإجراءات، وموارد سلسلة الفنادق، والموارد حسب التخصص.",
    "basicsTextNewToRole": "وأنت بصدد بدء دورك الوظيفي الجديد، نعرف أن هناك الكثير أمامك لتعلمه. لقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. يمكن استعراض بعض من هذه العناصر بمزيد من التفصيل في التدريب المخصص لك، ولكن ستساعدك هذه المعلومات في بدء العمل.",
    "basicsTextNewToRoleGms": "وأنت بصدد بدء دورك الوظيفي الجديد، نعرف أن هناك الكثير أمامك لتعلمه. لقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. يمكن استعراض بعض من هذه العناصر بمزيد من التفصيل في التدريب المخصص لك، ولكن ستساعدك هذه المعلومات في بدء العمل. بعد الانتهاء، انتقل إلى صفحات Gateway الأخرى، التي تتضمن عناصر الإجراءات، وموارد سلسلة الفنادق، والموارد حسب التخصص.",
    "basicsTextNewToRoleFranchise": "نعرف أن هناك الكثير أمامك لتعلمه، وقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في دورك الوظيفي الجديد. قد تكون هذه العناصر مكملة للمعلومات المقدمة من شركة إدارة حقوق الامتياز.",
    "basicsTextNewToRoleFranchiseGms": "نعرف أن هناك الكثير أمامك لتعلمه، وقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في دورك الوظيفي الجديد. قد تكون هذه العناصر مكملة للمعلومات المقدمة من شركة إدارة حقوق الامتياز. بعد الانتهاء، انتقل إلى صفحات Gateway الأخرى، التي تتضمن عناصر الإجراءات، وموارد سلسلة الفنادق، والموارد حسب التخصص.",
    "basicsTextNewToBrand": "وأنت بصدد بدء دورك الوظيفي الجديد لدى %{brandName}، نعرف أن هناك الكثير أمامك لتعلمه. لقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. يمكن استعراض بعض من هذه العناصر بمزيد من التفصيل في البرامج التوجيهية أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، ولكن ستساعدك هذه المعلومات في بدء العمل.",
    "basicsTextNewToBrandGms": "وأنت بصدد بدء دورك الوظيفي الجديد لدى %{brandName}، نعرف أن هناك الكثير أمامك لتعلمه. لقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. يمكن استعراض بعض من هذه العناصر بمزيد من التفصيل في البرامج التوجيهية أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، ولكن ستساعدك هذه المعلومات في بدء العمل. بعد الانتهاء، انتقل إلى صفحات Gateway الأخرى، التي تتضمن عناصر الإجراءات، وموارد سلسلة الفنادق، والموارد حسب التخصص.",
    "basicsTextNewToBrandFranchise": "نعرف أن هناك الكثير أمامك لتعلمه، وقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في دورك الوظيفي الجديد. قد تكون هذه العناصر مكملة للبرامج التوجيهية، أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، أو المعلومات المقدمة من شركة إدارة حقوق الامتياز.",
    "basicsTextNewToBrandFranchiseGms": "نعرف أن هناك الكثير أمامك لتعلمه، وقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في دورك الوظيفي الجديد لدى %{brandName}. قد تكون هذه العناصر مكملة للبرامج التوجيهية، أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، أو المعلومات المقدمة من شركة إدارة حقوق الامتياز. بعد الانتهاء، انتقل إلى صفحات Gateway الأخرى، التي تتضمن عناصر الإجراءات، وموارد سلسلة الفنادق، والموارد حسب التخصص.",
    "disciplineHeader": "الموارد حسب التخصص",
    "toDo": "المطلوب",
    "optional": "الاختياري",
    "overallComplete": "الإجمالي المستكمل",
    "keyResourcesDesc": "تعرف على المزيد عن التخصصات الرئيسية التي تدعم عمليات الفندق اليومية، وتجربة النزلاء، واحتياجات العمل.",
    "franchiseFooter": "تحتوي هذه المادة على توصيات وممارسات مقترحة قد تحسن احتمالية الالتزام بمعايير سلسلة الفنادق. باعتبارها جهة مانحة لحقوق الامتياز، لا تشارك Marriott في إدارة أو تشغيل أي جانب من جوانب فنادق الشركة صاحبة حقوق الامتياز أو أنشطة أعمالها. تحتفظ الشركات صاحبة حقوق الامتياز بصلاحية التحكم في التشغيل اليومي لفنادقها وهي مسؤولة عن موظفيها وممارسات التوظيف الخاصة بها.",
    "landingHeroTitle1": "مرحبًا بك في Marriott International.",
    "landingHeroTitle2": "ابدأ رحلتك من هنا.",
    "landingHeroText": "The Gateway هي أداة تهدف إلى إرشادك خلال عامك الأول في دورك الوظيفي الجديد. ستوفر لك هذه الأداة المعلومات والموارد التي تحتاج إليها من أجل تحقيق النجاح في عملك.",
    "landingHeroTitle1NewToRole": "مرحبًا بك في The Gateway.",
    "landingHeroTitle1NewToBrand": "مرحبًا بك في The Gateway.",
    "landingButtonText": "بدء العمل",
    "twoMonthslandingHeroTitle1": "مرحباً بعودتك!",
    "twoMonthslandingHeroText": "استكمل جميع العناصر الأساسية الخاصة بك في  The Gateway إذا لم تكن قد قمت بذلك بالفعل!",
    "twoMonthslandingButtonText": "دخول Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "مرحباً بعودتك!",
    "twoMonthslandingHeroTextNewToBrand": "نأمل أن تكون أيامك الأولى لدى %{brandName} قد سارت على نحو رائع. لا تنس مواصلة رحلتك والحصول على كل المعلومات التي تحتاج إليها في The Gateway",
    "twoMonthslandingButtonTextNewToBrand": "مواصلة العمل",
    "threeMonthslandingHeroTitle1": "أنت في طريقك لتحقيق النجاح!",
    "threeMonthslandingHeroText": "كل المعلومات التي تحتاج إلى معرفتها عن Marriott International موجودة في The Gateway.",
    "threeMonthslandingButtonText": "دخول Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "أنت في طريقك لتحقيق النجاح!",
    "threeMonthslandingHeroTextNewToRole": "كل المعلومات التي تحتاج إلى معرفتها من أجل تحقيق النجاح في دورك الوظيفي الجديد موجودة في The Gateway.",
    "threeMonthslandingButtonTextNewToRole": "دخول Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "مرحباً بعودتك!",
    "threeMonthslandingHeroTextNewToBrand": "تهانينا على استكمال أول %{numDays} أيام لدى سلسلة فنادقك الجديدة. يمكنك الوصول إلى كل عناصر الإجراءات الخاصة بك وموارد سلسلة الفنادق والتخصص وقتما تحتاج إليها.",
    "threeMonthslandingButtonTextNewToBrand": "دخول Gateway",
    "fourMonthslandingHeroTitle1": "أحسنت! لقد انتهيت من استكمال العناصر الأساسية الخاصة بك!",
    "fourMonthslandingHeroText": "يمكنك الحصول على كل المعلومات التي تحتاج إلى معرفتها عن Marriott International في The Gateway.",
    "fourMonthslandingButtonText": "دخول Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "أحسنت! لقد انتهيت من استكمال العناصر الأساسية الخاصة بك!",
    "fourMonthslandingHeroTextNewToRole": "يمكنك الحصول على كل المعلومات التي تحتاج إليها من أجل تحقيق النجاح في دورك الوظيفي الجديد في The Gateway.",
    "fourMonthslandingButtonTextNewToRole": "دخول Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - تسجيل الدخول",
    "fourMonthslandingHeroTextNewToBrand": "وأنت على وشك إتمام عامك الأول لدى  %{brandName}، احرص على استكمال كل عناصر الإجراءات الخاصة بك والاستفادة من موارد التطوير المتاحة هنا في Digital Learning Zone، إذا لم تكن قد قمت بذلك بالفعل. انقر فوق \"متابعة\" للمضي قدمًا في رحلتك.",
    "fourMonthslandingButtonTextNewToBrand": "متابعة",
    "gmSecondVisitlandingHeroTitle1": "مرحباً بعودتك!",
    "gmSecondVisitlandingHeroText": "نأمل أن تكون أيامك الأولى قد سارت على نحو رائع. لا تنس مواصلة رحلتك لمساعدتك في التطور في دورك الوظيفي الجديد.",
    "gmSecondVisitlandingButtonText": "مواصلة العمل",
    "gmSecondVisitlandingHeroTextNewToRole": "نأمل أن تكون أيامك الأولى في دورك الوظيفي الجديد قد سارت على نحو رائع. لا تنس مواصلة رحلتك والحصول على كل المعلومات التي تحتاج إليها في The Gateway.",
    "gmSecondVisitlandingHeroTextNewToBrand": "نأمل أن تكون أيامك الأولى لدى %{brandName} قد سارت على نحو رائع. لا تنس مواصلة رحلتك والحصول على كل المعلومات التي تحتاج إليها في The Gateway",
    "gmThirdVisitlandingHeroTitle1": "مرحباً بعودتك!",
    "gmThirdVisitlandingHeroText": "تهانينا على استكمال أول %{numDays} أيام من رحلتك الوظيفية الجديدة. يمكنك الوصول إلى كل عناصر الإجراءات الخاصة بك وموارد سلسلة الفنادق والتخصص وقتما تحتاج إليها.",
    "gmThirdVisitlandingButtonText": "دخول Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "تهانينا على استكمال أول %{numDays} أيام في دورك الوظيفي الجديد. يمكنك الوصول إلى كل عناصر الإجراءات الخاصة بك وموارد سلسلة الفنادق والتخصص وقتما تحتاج إليها.",
    "gmThirdVisitlandingHeroTextNewToBrand": "تهانينا على استكمال أول %{numDays} أيام لدى سلسلة فنادقك الجديدة. يمكنك الوصول إلى كل عناصر الإجراءات الخاصة بك وموارد سلسلة الفنادق والتخصص وقتما تحتاج إليها.",
    "gmSevenMonthslandingHeroTitle1": "يبدو أن لديك المزيد من عناصر الإجراءات التي يجب عليك استكمالها.",
    "gmSevenMonthslandingHeroText": "وأنت على وشك إتمام عامك الأول، لا تنس استكمال كل عناصر الإجراءات الخاصة بك والاستفادة من موارد التطوير المتاحة هنا في Digital Learning Zone. انقر فوق \"متابعة\" للمضي قدمًا في رحلتك",
    "gmSevenMonthslandingButtonText": "متابعة",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - تسجيل الدخول",
    "gmSevenMonthslandingHeroTextNewToRole": "وأنت على وشك إتمام عامك الأول في دورك الوظيفي الجديد، احرص على استكمال كل عناصر الإجراءات الخاصة بك والاستفادة من موارد التطوير المتاحة هنا في Digital Learning Zone، إذا لم تكن قد قمت بذلك بالفعل. انقر فوق \"متابعة\" للمضي قدمًا في رحلتك",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - تسجيل الدخول",
    "gmSevenMonthslandingHeroTextNewToBrand": "وأنت على وشك إتمام عامك الأول لدى %{brandName}، احرص على استكمال كل عناصر الإجراءات الخاصة بك والاستفادة من موارد التطوير المتاحة هنا في Digital Learning Zone، إذا لم تكن قد قمت بذلك بالفعل. انقر فوق \"متابعة\" للمضي قدمًا في رحلتك.",
    "gmOneYearlandingHeroTitle1": "تهانينا على إتمام عامك الأول لدى %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "تهانينا على إتمام عامك الأول في دورك الوظيفي الجديد!",
    "gmOneYearlandingHeroTitle1NewToBrand": "تهانينا على إتمام عامك الأول في دورك الوظيفي الجديد لدى %{brandName}!",
    "gmOneYearlandingHeroText": "ربما تكون رحلة The Gateway قد انتهت ولكن لا يزال الأفضل بانتظارك. يمكنك الوصول إلى الموارد من علامة تبويب  The Gateway في أي وقت.",
    "gmOneYearlandingButtonText": "عرض Gateway"
  },
  "resources": {
    "iptTooltip": "استخدم الفواصل للفصل بين المواقع العديدة",
    "iptAllFunctionTooltip": "قم بإلغاء تحديد هذا الخيار لتحديد مهام وظيفية معينة. احتفظ بهذا الخيار محددًا لعرض التدريب الخاص بكل المهام في الموقع المحدد",
    "iptAllAWRTooltip": "قم بإلغاء تحديد هذا الخيار لتحديد مسؤوليات عمل إضافية معينة. احتفظ بهذا الخيار محددًا لعرض التدريب الخاص بجميع مسؤوليات العمل الإضافية للموقع المحدد",
    "editJFTooltip": "استخدم الفواصل للفصل بين الإدخالات العديدة"
  },
  "Notification Center: ": "تعليم كغير مقروء",
  "Notification Center: Empty": "أنت على دراية بأحدث المستجدات!",
  "Notification Center: Individual Delete": "هل تريد بالتأكيد حذف هذا الإعلام؟ يتم نقل الإعلامات المحذوفة إلى قسم المحذوفات في مركز الإعلامات إلى أن تنتهي صلاحيتها.",
  "Notification Center: Delete All": "هل تريد بالتأكيد حذف كل الإعلامات؟ سيتم نقلها إلى قسم المحذوفات في مركز الإعلامات إلى أن تنتهي صلاحيتها.",
  "Notification Center: Mark All As Read": "تعليم الكل كمقروء",
  "Notification Center: Restore All": "استعادة الكل",
  "Notification Center: Delete Confirmation": "حذف",
  "Warning Message Text: Remove Job Function or AWR": "هل تريد إزالة المهمة الوظيفية أو مسؤولية العمل الإضافية (AWR)؟\r\nمن خلال الإرسال، أنت على وشك إزالة %{Function and/or AWR} من %{count} متعلمين.",
  "Warning Message Text: Add Job Function or AWR": "هل تريد إضافة مهمة وظيفية أو مسؤولية عمل إضافية (AWR)؟\r\nمن خلال الإرسال، أنت على وشك إضافة %{Function and/or AWR} إلى %{count} متعلمين.",
  "Button": "إرسال",
  "Success Message Text: Add Job Function and/or AWR": "نجاح\r\nلقد أضفت %{Job Function and/or AWR} إلى الزميل (الزملاء) المطلوب في %{location}. \r\n\r\nهل ترغب في تعديل مهام وظيفية إضافية أو مسؤوليات عمل إضافية من أجل %{location}؟",
  "Message Text:AWR": "نجاح\r\nلقد أضفت مسؤوليات العمل الإضافية %{ __} إلى الزميل (الزملاء) المطلوب في %{ __}.\r\n\r\nهل ترغب في تعديل مهام وظيفية إضافية أو مسؤوليات عمل إضافية من أجل %{location, property, learner(s)}؟",
  "Success Message Text: Remove Job Function": "نجاح\r\nلقد قمت بإزالة %{Job Function and/or AWR} من الزميل (الزملاء) المطلوب في %{location}. \r\n\r\nهل ترغب في تعديل مهام وظيفية إضافية أو مسؤوليات عمل إضافية من أجل %{location}؟",
  "Success Message Text: Remove AWR": "نجاح\r\nلقد قمت بإزالة مسؤولية العمل الإضافية %{ __} من الزميل (الزملاء) المطلوب في %{ __} .\r\n\r\nهل ترغب في تعديل مهام وظيفية إضافية أو مسؤوليات عمل إضافية من أجل %{location, property, learner(s)}؟",
  "Tool Tip - Managed Change Email": "لتغيير بريدك الإلكتروني، تفضل بزيارة mHUB >‏ HR Direct >‏ Me (معلوماتي) >‏ My Public Info (معلوماتي العامة). اتبع التعليمات لتحديث تفاصيل الاتصال بك. قد يستغرق الأمر فترة تتراوح من يوم إلى يومين عمل حتى يتم عرض البريد الإلكتروني المحدث.",
  "Tool Tip - Franchised  Change Email": "لتغيير بريدك الإلكتروني، اتصل بمديرك و/أو بمسؤول EID Tool في الشركة المالكة لحقوق الامتياز. قد يستغرق الأمر فترة تتراوح من يوم إلى يومين عمل حتى يتم عرض البريد الإلكتروني المحدث.",
  "User Profile: Email Opt-in": "لا، شكرًا لك",
  "User Profile: Email Frequency": "ملخص أسبوعي",
  "Generic Notification Email Subject Line:": "لديك إعلامات جديدة في DLZ",
  "Generic Body Text (<150 Characters)": "يرجى زيارة DLZ للاطلاع على التفاصيل.",
  "All Emails: Signature": "Digital Learning Zone - تبسيط التعلم. الأمر يبدأ بك.",
  "All Emails: Footer": "يمكنك إلغاء الاشتراك في إعلامات البريد الإلكتروني في أي وقت. تفضل بزيارة Digital Learning Zone/ملف التعريف لتحديث تفضيلاتك.",
  "Overdue Emails: Subject Line: ": "مهام DLZ تحتاج إلى انتباهك",
  "Overdue Emails: Body Test": "لديك مهام متأخرة. يرجى تسجيل الدخول إلى DLZ لمراجعة المهام الخاصة بك.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "صقل مهاراتك",
    "sharpenYourSkillsSubTitle": "قم ببناء معرفتك ومهاراتك لتحسين مسيرتك المهنية الآن وفي المستقبل.",
    "growYourCareerTitle": "تطوير مسيرتك المهنية",
    "growYourCareerSubTitle": "خطّط لخطوتك القادمة من خلال المهارات المتخصصة أو التدريبات في مختلف المجالات التي تدعم اهتماماتك وأهدافك الفريدة.",
    "additionalResourcesTitle": "الموارد الإضافية",
    "additionalResourcesSubTitle": "استكشف ®getAbstract و™CultureWizard. شاهد كيف يمكن لـ DLZ الاستمرار في مساعدتك على النمو والتطور.",
    "tourHeader": "مرحبًا بك في DLZ المحدّث. يمكنك الآن البحث للعثور على أي شيء تحتاجه عندما تحتاج إليه. بالإضافة إلى ذلك، ستجد استكشاف التعلم المحدث و\"خطتي\" بصورة أكثر تخصيصًا. قم بالجولة!",
    "tourBtnSkip": "تجاهل",
    "tourBtnNext": "إغلاق (6/6)",
    "tourSearchBar": "يمكنك العثور على ما تحتاج إليه وقت الحاجة إليه من خلال ميزة بحث جديدة أقوى.",
    "tourBtnBack": "السابق",
    "tourMyPlan": "تتضمن \"خطتي\" الآن عناصر قمت بإضافتها من البحث والاستكشاف بحيث يمكنك تولي مسؤولية رحلة التعلم الخاص بك بشكل كامل.",
    "tourExploreLearningLink": "لا يزال بإمكانك الوصول إلى استكشاف التعلم من هنا أيضًا!",
    "tourExploreLearningProfile": "حقق أكبر استفادة من تجربة التعلم الخاص بك وتحقق من ملف بياناتك، خاصة المهام الوظيفية، بصورة منتظمة للحفاظ على تحديث \"خطتي\".",
    "mhubTraining": "تدريب mHUB",
    "growthSkills": "مهارات التطور",
    "leadershipSkills": "مهارات القيادة",
    "virtualTeamResources": "موارد فرق العمل الافتراضية",
    "jobFunctions": "المهام الوظيفية",
    "brands": "سلاسل الفنادق",
    "languageLearning": "تعلم اللغات",
    "become": "يصبح",
    "emeaMentoringProgram": "برنامج التدريب لمنطقة أوروبا والشرق الأوسط وإفريقيا",
    "learningPathways": "مسارات التعلم",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "®getAbstract",
    "cultureWizard": "™CultureWizard",
    "takeCareCommunityHub": "مركز مجتمع TakeCare",
    "mhubTrainingDesc": "اعثر على مسارات تعلم مستندة إلى الأدوار ودورات تدريبية خاصة بمواضيع معينة حول كيفية استخدام mHUB.",
    "growthSkillsDesc": "اكتسب معرفة عملية عبر وظائف ومواضيع متنوعة.",
    "leadershipSkillsDesc": "اكتشف كيفية إدارة فريق عمل وتدريبه وزيادة فعاليتك كقائد.",
    "virtualTeamResourcesDesc": "تعرف على أساليب تعزيز ثقافة تعاونية تستوعب الجميع ضمن فرق العمل الافتراضية.",
    "jobFunctionsDesc": "قم بزيادة معرفتك بمهامك الوظيفية الحالية وتعرف على مهام أخرى.",
    "brandsDesc": "تعرف على المزيد حول سلاسل الفنادق المتنوعة لشركة ماريوت الدولية.",
    "languageLearningDesc": "تواصل بشكل فعال مع جمهور عالمي.",
    "becomeDesc": "اكتشف منهجًا كليًا لتنمية مسيرتك المهنية وتطويرها: التفكير، التخطيط، التنفيذ‎، التحسين.",
    "emeaMentoringProgramDesc": "أطلق العنان لقدراتك مع برنامج التدريب لمنطقة أوروبا والشرق الأوسط وإفريقيا.",
    "learningPathwaysDesc": "استكشف فرص تعلم محددة تم إعدادها خصيصًا لمستوى القيادة الخاص بك.",
    "mdaDesc": "قم ببناء مهارات القيادة والمهارات الوظيفية عبر Marriott Development Academy.",
    "takeCareGrowDesc": "حدد الموارد المصممة لبناء مجتمع مُستلهَم يستوعب الجميع.",
    "getAbstractDesc": "تعلم شيئًا جديدًا كل يوم من خلال ملخصات الكتب التي تساعدك في اتخاذ قرارات أفضل.",
    "cultureWizardDesc": "اكتسب معرفة قيّمة للتواصل بشكل فعال عبر الثقافات المتنوعة.",
    "takeCareCommunityHubDesc": "اكتشف أنشطة بناء فرق العمل وبرامج التعلم والأدوات من TakeCare.",
    "searchAllLearning": "بحث في كل موارد التعلم",
    "searchBarPlaceHolder": "هناك المزيد دائمًا لتعلّمه.",
    "clearFilters": "مسح عوامل التصفية",
    "noResults": "لا توجد نتائج",
    "hasBeenUnpinned": "تم إلغاء تثبيت {Variable-tile_Name}.",
    "hasBeenPinned": "تم تثبيت {Variable-tile_Name}.",
    "lpRemovedFromPlan": "تمت إزالة مسار التعلم من خطتك.",
    "courseRemovedFromPlan": "تمت إزالة الدورة التدريبية من خطتك.",
    "lpAddedToPlan": "تمت إضافة مسار التعلم إلى خطتك.",
    "courseAddedToPlan": "تمت إضافة الدورة التدريبية إلى خطتك.",
    "clearSelection": "مسح التحديد",
    "removeFromMyPlan": "إزالة من خطتي",
    "addToMyPlan": "إضافة إلى خطتي",
    "loadingResults": "جارٍ التحميل",
    "viewDetails": "عرض التفاصيل",
    "clearAll": "مسح الكل",
    "noOptions": "لا توجد خيارات متاحة",
    "viewLess": "عرض أقل",
    "viewMore": "عرض أكثر",
    "recentSearch": "عمليات البحث الأخيرة",
    "exploreLearningTitle": "استكشاف التعلم",
    "exploreLearningHelpText": "لا تدري ما تبحث عنه؟",
    "exploreMore": "استكشف المزيد.",
    "viewAllResults": "عرض كل <#> نتائج",
    "searchSubTitle": "ابحث عن الدورات التدريبية باستخدام الكلمات الأساسية، أو العناوين، أو معرف المحتوى.",
    "pinnedLearningTitle": "مسارات تعلمي المثبتة",
    "landingSubTitle": "تولى قيادة رحلة تعلمك. اكتشف اهتماماتك - هناك موضوعات ودورات تدريبية جديدة تتم إضافتها باستمرار.",
    "columnTitle": "العنوان",
    "columnDesc": "الوصف",
    "columnType": "النوع",
    "columnAddedToPlan": "إضافة (مضافة) إلى الخطة",
    "columnComplete": "مكتمل",
    "columnDetails": "التفاصيل",
    "additionalSearchFilters": "عوامل تصفية بحث إضافية",
    "tourBtnClose": "إغلاق",
    "tourBtnLast": "إغلاق",
    "resultsFor": "نتائج ",
    "resultsFortheFilter": "نتائج عامل التصفية {Filter Name}",
    "no": "لا",
    "results": "النتائج",
    "courseTooltipTitle": "عنصر تعلم واحد والذي قد يحتوي على نشاط واحد أو أكثر.<br />قد تكون الدورة التدريبية عبر الإنترنت مثل فيديو أو برنامج تعلم إلكتروني أو تحدث في فصل دراسي.",
    "gatewayToolTipTitle": "موارد رقمية للموظفين الجدد، أولئك الذين ليس لديهم خبرة في الإدارة، والمديرين العموميين الذين ليس لديهم خبرة مع سلسلة فنادقهم.",
    "speciallizationOrPathwayTooltipTitle": "مجموعة من الدورات التدريبية التي تركز على موضوع معين.",
    "taskTooltipTitle": "تعليمات لإكمال نشاط (عادة في الوظيفة) ثم وضع علامة تشير إلى إكمالك له.",
    "webSiteTooltipTitle": "أي موقع ويب بما في ذلك مواقع Marriott أو مواقع الويب الخارجية."
  },
  "dontShowAgain": "عدم إظهار ذلك مرة أخرى",
  "deletionWarningEnabled": "تحذير الحذف ممكّن",
  "deletionSelected": "عند تحديد هذا الخيار، سترى رسالة تحذير في كل مرة تقوم فيها بحذف إعلام.",
  "manageEmailNotifications": "إدارة إعلامات البريد الإلكتروني",
  "seeHowDLZContinue": "شاهد كيف يمكن لـ DLZ الاستمرار في مساعدتك على النمو والتطور.",
  "Notification Center:Overdue": "لديك تدريب جديد كان واجب الأداء في {Variable date ddmmyy}",
  "accesDenied": {
    "title": "صلاحية الوصول إلى رحلة التعلم الخاصة بك قيد التقدم! ",
    "subtitle": "جارِ إنشاء صلاحية الوصول الخاصة بك ومن المفترض أن تتوفر في خلال فترة تتراوح من يوم إلى يومين بعد نشاط معرفك المؤسسي (EID). إذا كان قد مرّ أكثر من يومين، يُرجى الاتصال بمركز خدمة mHUB."
  },
  "jfTour": {
    "title": "يمكنك الآن رؤية المهام الوظيفية ومسؤوليات العمل الإضافية (AWR) المحددة وتحريرها مباشرة من \"خطتي\"."
  },
  "onboardingPlan": {
    "requiredDescription": "فيما يلي الدورات التدريبية اللازم إكمالها في الفترة الزمنية التي حددتها أسفل \"خطتي\". تعاون مع قائدك لوضع جدول زمني لإكمال عملية اكتساب المعرفة المطلوبة منك وأنت بصدد الانتقال إلى دورك الوظيفي الجديد. سوف تتعرف على مجموعة متنوعة من الموضوعات، احرص على التواصل مع قائدك وزملائك لطرح أي أسئلة لديك خلال مسيرة تعلمك.   ",
    "basicsDescription": "نعرف أن هناك الكثير أمامك لتعلمه، وقد اخترنا بضعة من العناصر الأساسية التي يجب أن تكون على دراية بها خلال الأيام القليلة الأولى في وظيفتك. قد تكون هذه العناصر مكملة للبرامج التوجيهية، أو جلسات الالتحاق بالعمل الخاصة بسلسلة الفنادق، أو المعلومات المقدمة من شركة إدارة حقوق الامتياز. ستكون هذه العناصر موارد رائعة طوال مسيرتك المهنية لدينا ومفيدة كذلك عند انضمام أفراد جدد إلى فريق العمل.",
    "day1": "واجبة الأداء بحلول اليوم 14",
    "day2": "واجبة الأداء بحلول اليوم 30",
    "day3": "واجبة الأداء بحلول اليوم 60",
    "day4": "واجبة الأداء بحلول اليوم 90",
    "recommendedDescription": "بعد أول 90 يومًا لك، سوف تجد دورات تدريبية إضافية مقترحة من مديرك. يمكنك أيضًا التعرف على الموضوعات التي تهمك بنفسك. في الوقت الحالي، ركّز على المعرفة الأساسية المطلوبة منك. ",
    "day5": "واجبة الأداء بعد 90 يومًا "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "تمثل Digital Learning Zone نقطة انطلاقك في مسيرة التعلم والتطور المهني. سوف تجد أدناه محتوى مصمم من أجل مساعدتك على بدء العمل. يمكنك العثور دائمًا على كل الموضوعات التي تعلمتها أسفل \"خطتي\".  ",
    "overallLearningProgress": "التقدم الكلي في التعلم",
    "learningProgressLabel": "التقدم في التعلم",
    "learningProgressDescription": "إليك مدى تقدمك خلال عناصر التعلم المطلوبة. يتضمن شريط التقدم هذا حالة كل عناصر التعلم المطلوبة. واصل العمل الرائع!"
  },
  "poc": {
    "dlz": "لكي تكتمل هذه الدورة التدريبية، يجب على المديرين أو منسقي التعليم تعليم هذه الدورة التدريبية كمكتملة في mHUB. يمكن العثور على تفاصيل حول كيفية القيام بذلك في دليل التطبيق.",
    "mhub": "الشركات صاحبة حقوق الامتياز:  إذا اخترت استخدام برنامج التنظيف الأساسي لديك، يرجى تضمين اسم البرنامج وتفاصيله في قسم التعليقات عند وضع علامة الاكتمال"
  },
  "Recommended Courses ": "بعد أول 90 يومًا لك، سوف تجد دورات تدريبية إضافية مقترحة من مديرك. يمكنك أيضًا التعرف على الموضوعات التي تهمك بنفسك. في الوقت الحالي، ركّز على المعرفة الأساسية المطلوبة منك. ",
  "survey": {
    "v2": {
      "weValueButtonText": "خذ الاستطلاع",
      "lbiIntro": "مرحبًا بك في تقييم سلوك الضيافة الفاخرة!",
      "lbiDescription": `<p>سيُطلب منك فيما يلي قراءة بعض السيناريوهات القصيرة التي قد تُواجهها في منشأتك. يُرجى قراءة كل سيناريو واختيار الطريقة التي ستستجيب بها لكل موقف. ضع ما يلي في اعتبارك أثناء إجابتك:</p>
      <ul>
          <li>
              <p>قد تجد أكثر من إجابة واحدة للاختيار من بينها تبدو صحيحة. اختر الإجابة التي من المُرجّح أن تتبعها في العمل.
              </p>
          </li>
          <li>
              <p>افترض عدم وجود عوائق اتصال. اللغة التي تتحدّثها هي نفس اللغة التي يتحدّث بها النزيل أو الموظف في السيناريو.</p>
          </li>
          <li>
              <p>قد يطلب منك السيناريو أن تتخيّل نفسك في دور وظيفي مختلف عن الدور الذي تضطلع به حاليًا. أجب عن السؤال على حد عِلمك، وبناءً على تجربتك كمهني في مجال الضيافة الفاخرة.</p>
          </li>
      </ul>
      <p><br></p>
      <p>يُرجى ملاحظة ما يلي: مشاركتك في هذا التقييم طوعية تمامًا (يمكنك اختيار عدم المُشاركة)، وستكون إجاباتك سرية للغاية. سيتم الإبلاغ عن نتائج هذا التقييم لمجموعات من الأشخاص وليس لأفرادٍ مُحدّدين. وسيتم تقييم الإجابات الفردية من قِبل فريق البحث ولن تتم مُشاركتها مع Managers of Others (مديري الآخرين) أو القادة.
      </p>
      <p>وبإكمالك لهذا التقييم، فإنك تُوافق على جمع إجاباتك وبياناتك الشخصية ومُعالجتها (مثل مدة الخدمة، والنوع الجنساني، والمُسمّى الوظيفي) بالإضافة إلى نقل بياناتك الشخصية عبر الحدود إلى مجموعة ماريوت. وهذا وفقًا لبيان خصوصية بيانات موظفي ماريوت، الذي يمكنك أيضًا أن تعثر فيه على معلومات وجهات اتصال إضافية للرجوع إليها بشأن سياسات خصوصية بيانات ماريوت.
      </p>
      `,
      "checkin": {
        q1Title: "تُلاحظ، في أثناء مشيك في البهو، أسرة لديها ثلاثة أطفال صغار. ولدى الأسرة العديد من الحقائب، ويبدو على أفراد الأسرة الإجهاد بسبب يوم سفر طويل، والأطفال يئنّون ويشعرون بالتعب بشكلٍ واضح. فما الذي ستفعله؟",
        q2Title: "بدأت للتو العمل في منشأة وفي منطقة جديدة عليك. ويطلب منك أحد النزلاء اقتراحات لأشياء يمكنه القيام بها في هذه المنطقة. وقد قرأ النزيل العديد من الأدلّة، لكنه يبحث عن شيء أكثر تخصيصًا. فما الذي ستفعله؟ ",
        q3Title: "في أثناء مشيك في البهو، تسمع نزيلاً يتحدّث عبر الهاتف وتعلم أن خدمة نقله بالسيارة إلى المطار متأخرة. إمّا أن منشأتك لا يوجد بها سيارة خاصة بها أو أنها غير مُتاحة. فما الذي ستفعله؟ ",
        q4Title: "عندما كنت تتجوّل في البهو في طريقك لإكمال مهمة عاجلة أسندها إليك مديرك. تُلاحظ أن زميلاً يُعاني لإتمام مهمة يقوم بها عادةً شخصان. فما الذي ستفعله؟ ",
        q5Title: "تعمل مُضيفًا في مطعم الفندق. ويطلب أحد النزلاء الاطّلاع على القائمة أثناء انتظاره على الطاولة المحجوزة. وبعد اطّلاعه على القائمة، يشعر النزيل بالإحباط لأن زميلاً آخر هو من اقترح عليه مطعم الفندق، ولكن لم يجد في القائمة شيئًا يُلبّي احتياجاته الغذائية. فما الذي ستفعله؟",
        q6Title: "هناك مجموعة كبيرة من النزلاء في منطقة حمام السباحة المُزدحمة الذين لم يتمكّنوا من العثور على مقاعد تكفيهم جميعًا. ونتيجةً لذلك، فقد وضعوا مناشفهم ومتعلقاتهم الشخصية بطريقة تسدّ ممرًا رئيسيًا داخل المنطقة، وهو ما يُشكّل خطرًا على النزلاء والموظفين ويُعيقهم عن الوصول إلى منطقة حمام السباحة. يجب إخلاء المنطقة، ولكنك لا تُريد إزعاج هذه المجموعة من النزلاء. فما الذي ستفعله؟ ",
        q2R1Title: "الثانية ___تسأل النزيل عمّا يجعله يشعر بالاستمتاع، ثم تتعاون مع زميلٍ لوضع قائمة بالأشياء المُمتعة التي يمكنه القيام بها. وبالإضافة لذلك، تقترح مطعمًا لطيفًا بالقُرب من المنشأة.",
        q2R2Title: "الأولى __تتحدّث إلى النزيل حول الأشياء التي تُعجبه. وتعمل مع زميلٍ لوضع قائمة خاصة بالأشياء المُمتعة له. وبالإضافة لذلك، تتحقّق من ملف تعريف النزيل في برنامج Bonvoy وتُرسِل إليه هدية مُخصّصة إلى غرفته.",
        q2R3Title: "لثالثة __تجعل الأمر شخصيًا من خلال مشاركة بعض الأشياء المُفضّلة لديك للقيام بها في المنطقة حتى الآن. تجعل اقتراحاتك مُميّزة عن طريق مشاركة تفاصيل إضافية حول تجاربك. ثم تُرسِل حلوى محلية لذيذة إلى غرفته.",
        q2R4Title: "لرابعة __تُخبر النزيل بأنك جديد في المنطقة وليس لديك الكثير من الأفكار. تعتذر وتقترح التحدُّث إلى زميلك الذي يعرف أكثر. ثم تسأله عمّا إذا كان يرغب في إرسال حلوى محلية إلى غرفته.",
        q3R1Title: "في أثناء إجراء النزيل لمكالمته الهاتفية، تقود سيارتك الشخصية أو سيارة زميلك حتى تصل أمام المنشأة وتُخبر النزيل بأنك ستقلُّه بنفسك إلى المطار",
        q3R2Title: " أثناء إجراء النزيل لمكالمته الهاتفية، تضع قائمة بخدمات السيارة المُعتمدة في المنشأة وأرقام الهواتف. وبمجرد إنهاء النزيل لمكالمته الهاتفية، تُقدّم له القائمة وتعرِض عليه المساعدة في ترتيب وسيلة النقل.",
        q3R3Title: "تنتظر حتى ينتهي النزيل من مكالمته الهاتفية وتُخبره أن منشأتك لا تُقدّم خدمات النقل، ولكنك ستكون سعيدًا بمساعدته في تنسيق خيارات نقل أخرى. ",
        q3R4Title: "في أثناء إجراء النزيل لمكالمته الهاتفية، تبحث عن وسيلة نقل بديلة للنزيل. بمجرد إنهاء النزيل لمكالمته الهاتفية، تُخبره بأنك قد رتّبت لانتقاله إلى المطار وأن السيارة ستصل قريبًا.",
        q4R1Title: "تذهب إلى زميلك وتُخبره بأنك ستُساعده، ولكن يتعيّن عليك إنهاء مُهمّتك العاجلة أولاً. تعِده بالعودة بسرعة لمساعدتهم. تتأكّد من مناقشة الموقف مع مديرك.",
        q4R2Title: "أولاً، تبحث بسرعة عن زميل آخر لتولِّي المُهمّة المُوكلة إليك. وبعد ذلك، تُساعد زميلك الذي يحتاج إلى المساعدة على الفور. وعند الانتهاء، تُبلغ مديرك بأنك وجدت شخصًا آخر لإتمام مُهمّتك حتى تتمكّن من مساعدة زميلك.",
        q4R3Title: "تتوقّف عن إتمام مُهمّتك وتُساعد زميلك على الفور. وبعد أن تنتهي من مساعدته، تعود إلى المُهمّة المُسندة إليك، ثم تُخبر مديرك بأنك أوقفت مُهمّتك مؤقتًا لمساعدة زميلك.",
        q4R4Title: "تذهب إلى زميلك الذي يحتاج إلى مساعدة وتشرح له أنك في وسط مُهمّة عاجلة. تتوقّف عن كلتا المُهمّتين، وتتبادل الأفكار مع زميلك حول طريقة لأداء كلتا المُهمّتين معًا، ثم تُكمل كلتا المُهمّتين معًا.",
        q5R1Title: "تعتذر للنزيل وتُخبره بأنك ستُخبر المدير. تتأكّد من شرح الموقف حتى يتمكّن المدير من مساعدة النزيل وتلبية احتياجاته الغذائية.",
        q5R2Title: "تعتذر للنزيل وتسأله عن أطعمته المُفضّلة. تتحدّث إلى فريق المطبخ لإعداد طبق خاص له. وتُقدّم له أيضًا مقبلات أو مشروبات مجانية لتُعوِّضه عن هذا الخطأ.",
        q5R3Title: "تعتذر للنزيل وتُخبره بأنك تتفهّم موقفه. وبدلاً من إلغاء الحجز، تعرِض عليه التحدُّث إلى كبير الطُهاة ومعرفة ما إذا كان بإمكانه تقديم شيءٍ مُميّز للنزيل. تمنحه وجبة مجانية أو خصمًا مجانيًا لتُعوِّضه عن المشكلة.",
        q5R4Title: "تعتذر للنزيل وتُخبره بأنك يمكنك مساعدته في حجز طاولة في مطعم قريب يُقدّم الطعام الذي يُحبه. وتُقدِّم معلوماتٍ إضافية أو تُساعده في الوصول إلى هناك إذا لزم الأمر.",
        q6R1Title: "تعتذر للنزلاء عن ضيق المساحة وتُخبرهم بأنه لا بأس في بقائهم في المكان الذي يتواجدون فيه طالما أنهم يُراعون الآخرين الذين يمُرّون بهم.",
        q6R2Title: "تُخبر النزلاء بلطف بأنه لا يمكنهم سد الممر، وسيتعيّن عليهم البحث عن مكانٍ آخر أو الانتظار حتى يتّسع لهم المكان. وفي هذه الأثناء، اطلُب من زملائك إحضار مقاعد إضافية من قاعة الاحتفالات بالخارج ليستخدمها النزلاء.",
        q6R3Title: "تُخبر النزلاء بأنه لا يمكنهم البقاء في موقعهم الحالي وأنه يتعيّن عليهم الانتقال إلى مكانٍ آخر مؤقتًا بينما تبحث عن مقاعد إضافية. تُوفّر لهم مساحة في البار/المطعم في هذه الأثناء.",
        q6R4Title: "تعتذر للنزلاء عن مشكلة الجلوس. تسألهم عمّا إذا كان بإمكانهم تجميع أغراضهم في مكانٍ واحد لإفساح ممرٍ صغير للآخرين الذين يسيرون بجوارهم. تعرِض عليهم المساعدة، وتتأكّد من أن الجميع مُرتاحون. تُرسِل إليهم هدية خاصة من البار/المطعم ليشعروا بالراحة والاسترخاء.",
        q1R1Title: "أساعد أفراد الأسرة في حمل الأمتعة، وأدُلّهم على مكتب تسجيل الوصول، وأقترح عليهم بعض الأماكن القريبة الباعثة على الاسترخاء مع أنشطة مناسبة للعائلات. أذكُر وسائل الراحة العائلية بالمنشأة.",
        q1R2Title: "تعرِض عليهم المساعدة في حمل أمتعتهم وتدُلّهم على مكتب تسجيل الوصول. وبعد ذلك، تُرتّب لحصولهم على خدمة باعثة على الاسترخاء أو هدية للآباء وأنشطة مُمتعة للأطفال في غرفتهم.",
        q1R3Title: "تدُل الوالدين على مكتب تسجيل الوصول، ثم تصطحب الأطفال بنفسك إلى منطقة جلوس وتُقدّم لهم الوجبات الخفيفة أثناء تسجيل الوالدين لوصولهم. تطلُب من مكتب الاستقبال إبلاغ الوالدين بمناطق اللعب وأنشطة الأطفال في المنشأة لتوفير سُبل الراحة لهم.",
        q1R4Title: "تدُل الأسرة على مكتب تسجيل الوصول لإجراء عملية تسجيل الوصول بسرعة. تذكُر للوالدين المناطق والخدمات المناسبة للأطفال حتى يتمكّن الأطفال من الاستمتاع بإقامة مُمتعة. "
      }
    }
  },
};