import React, { Component } from 'react';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import { observer, inject } from 'mobx-react';
import { remCalc } from '../util/style-util';
import { globalColors } from '../styles/style-constants';

import rootTheme from './themes/rootTheme';

const CORPORATE_THEME_KEY = '31';
const AXONIFY_THEME_KEY = '-1';

const baseTheme = {
  globalColors: {
    blackOnlyForButtons: globalColors.blackOnlyForButtons,
    white: globalColors.white,
    digitalBlack: globalColors.digitalBlack,
    mediumBlackIconOnly: global.mediumBlackIconOnly,
    mediumGrey: globalColors.mediumGrey,
    lightestGrey: globalColors.lightestGrey,
    greyDisabled: globalColors.greyDisabled
  },
  overrides: {
    MuiTab: {
      textColorPrimary: {
        fontSize: remCalc(14),
        fontWeight: 600,
        color: globalColors.black,
        '&$selected': {
          color: globalColors.black,
        }
      }
    },
    MuiButton: {
      contained: {
        '&$disabled': {
          backgroundColor: '#d2d2d2',
          color: '#FFF'
        }
      }
    }
  }
};

class ThemeSelector extends Component {
  render() {
    const { userStore } = this.props;
    let brandTheme = rootTheme[userStore.profile.brandKey] || rootTheme[CORPORATE_THEME_KEY]; // rootTheme[AXONIFY_THEME_KEY]; // rootTheme[userStore.profile.brandKey] || rootTheme[CORPORATE_THEME_KEY];
    if (window.location.pathname.indexOf('validator') > -1) {
      brandTheme = rootTheme[AXONIFY_THEME_KEY];
    }

    const theme = createTheme(baseTheme, brandTheme.extendedTheme()); // createTheme({ ...baseTheme, ...brandTheme.extendedTheme()});

    theme.typography = { ...theme.typography, ...brandTheme.typography(theme, remCalc) };
    theme.extraTypography = { ...brandTheme.extraTypography(theme, remCalc) };

    // TODO: fix
    theme.extraTypography.card[theme.breakpoints.up('sm')] = {
      fontSize: remCalc(28),
      lineHeight: 1.16
    };

    return (
      <MuiThemeProvider theme={theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

export default inject('userStore')(observer(ThemeSelector));