export default {
  "banner": {
    "viewDetails": "Ver detalles",
    "requiredCourses": "Sus cursos obligatorios expiran en",
    "days": "días"
  },
  "header": {
    "plan": "Mi plan",
    "manager": "GERENTE",
    "explore": "EXPLORAR APRENDIZAJE",
    "reporting": "PREPARACIÓN DE INFORMES",
    "myTeam": "MI EQUIPO",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "MI PLAN",
    "manager": "GERENTE",
    "explore": "EXPLORAR APRENDIZAJE",
    "tagline": "El aprendizaje es más sencillo. Comienza con usted."
  },
  "welcome": {
    "hi": "Hola.",
    "intro1": "Bienvenido a Digital Learning Zone. DLZ es una plataforma de aprendizaje personalizada que constituye un punto de acceso central al aprendizaje de Marriott. Encuentre las capacitaciones necesarias para su puesto y explore oportunidades de aprendizaje para su desarrollo personal y profesional. Planifique su camino de aprendizaje y comience a desarrollar sus habilidades.",
    "intro2": "Antes de comenzar, necesitamos saber más sobre usted.",
    "letsGo": "VAMOS",
    "preferredLanguage": "¿Cuál es su idioma de preferencia?",
    "step": "PASO",
    "of": "DE",
    "previous": "ANTERIOR",
    "next": "SIGUIENTE",
    "startDate": "¿Cuándo comenzó a trabajar en Marriott International o Starwood?",
    "selectYear": "SELECCIONAR EL AÑO",
    "selectMonth": "SELECCIONAR EL MES",
    "selectDay": "SELECCIONAR LA FECHA",
    "selectFunction": "SELECCIONAR LA FUNCIÓN",
    "selectInterests": "SELECCIONAR LOS INTERESES",
    "chooseYear": "ELEGIR UN AÑO",
    "chooseMonth": "ELEGIR UN MES",
    "chooseDay": "ELEGIR UN DÍA",
    "whatDo": "¿Qué función desempeña?",
    "selected": "SELECCIONADO",
    "searchFunctions": "Buscar todas las funciones",
    "searchInterests": "Buscar todos los intereses",
    "additionalJobFunctionsLabel": "Cuéntenos más acerca de lo que hace",
    "managementLevelLabel": "¿Cuál es su nivel de asociado?",
    "preferredBrandLabel": "Si ha trabajado para más de una marca, seleccione todas las que correspondan.",
    "additionalJobFunctionsLabelExtra": "Revise lo siguiente y SELECCIONE AL MENOS UNA responsabilidad laboral adicional.",
    "searchAdditionalFunctions": "Buscar todas las responsabilidades laborales adicionales",
    "highGuestContactLabel": "¿Tiene mucho contacto con los huéspedes?",
    "yes": "Sí",
    "no": "No "
  },
  "landing": {
    "started": "Comencemos",
    "welcomeBack": "Le damos la bienvenida de nuevo.",
    "greatWork": "¡Bien hecho!",
    "requiredCourses": "Sus cursos obligatorios",
    "requiredCoursesDesc": "Complete los cursos antes de la fecha de expiración.",
    "myPlan": "Mi plan",
    "courses": "Cursos",
    "courseComplete": "CURSO COMPLETO",
    "viewMore": "VER MÁS CURSOS OBLIGATORIOS",
    "viewMoreBtn": "VER MÁS CURSOS",
    "showing": "MOSTRANDO",
    "of": "DE",
    "heroTitle": "Aprenda algo nuevo. Empodérese. Adquiera habilidades que se aplican a su mundo.",
    "heroParagraph1": "Controle fácilmente todos sus cursos obligatorios y obtenga notificaciones en tiempo real para completarlos.",
    "heroParagraph2": "Disfrute de experiencias de aprendizaje personalizadas según su trabajo y sus intereses.",
    "recommendedCoursesTitle": "Cursos recomendados",
    "recommendedCoursesDescNoContent": "No posee cursos recomendados en este momento. Consulte la información a continuación para explorar otras oportunidades de aprendizaje.",
    "recommendedCoursesDesc": "Descubra lo que su gerente o especialista de RR. HH. seleccionó para usted.",
    "recommendedCoursesBtn": "VER CURSOS RECOMENDADOS",
    "completedCoursesBtn": "VER CURSOS COMPLETADOS",
    "exploreTitle": "Conserve la curiosidad.",
    "exploreDesc": "Seleccione los temas que le interesan.",
    "jobFunctions": "Disciplina",
    "growthSkills": "Habilidades de crecimiento",
    "leadershipSkills": "Habilidades de liderazgo",
    "brands": "Marcas",
    "languageLearning": "Aprendizaje de idioma",
    "exploreBtn": "VER CURSOS",
    "managerRecommendation": "RECOMENDACIÓN DEL GERENTE",
    "learningRecommendation": "RECOMENDACIÓN DE APRENDIZAJE",
    "marriottRecommendation": "RECOMENDACIÓN DE MARRIOTT",
    "completedCourses": "Ha completado todos los cursos obligatorios.",
    "investTitle": "Planifique su camino de aprendizaje",
    "investParagraph1": "Entendemos que las circunstancias actuales son retadoras en muchos aspectos- DLZ está aqui como un recurso para usted.  Ya sea que desee mantener sus habilidades actualizadas, aprender algo nuevo, manejar el estrés, o aprender a trabajar en un equipo virtual. Hemos recopilado recursos para ayudarlo y apoyarlo.",
    "updatedTopics": "Temas actualizados",
    "workingRemotely": "Recursos para un equipo virtual",
    "relatedCoursesNew": "Haga clic a continuación para actualizar sus intereses, ya que hemos añadido nuevos temas y cursos.",
    "relatedCourses": "Por favor, haga clic a continuación para seleccionar sus intereses.",
    "notificationText": "Nueva capacitación de mHUB disponible. Seleccione mHUB en Explore Learning.",
    "mHubHeroTitle1": "Simple. Inteligente. Seguro. Presentamos mHUB.",
    "mHubHeroManagedText1": "mHUB integra herramientas para el aprendizaje y el desarrollo profesional y descuentos en Explore, entre otras cosas, en una plataforma única y práctica.",
    "mHubHeroFranchisedText1": "mHUB integra herramientas para el aprendizaje y descuentos en Explore, entre otras cosas, en una plataforma única y práctica.",
    "mHubHeroVisitLink": "Haga clic aquí para ver los detalles adicionales sobre la oferta o los archivos adjuntos.",
    "myGateway": "My Gateway",
    "gatewayReview": "Revise y finalice.",
    "gatewayProgress": "Progreso de Gateway",
    "complete": "COMPLETAR",
    "reviewContent": "Ver Recursos",
    "gatewayCardTitle": "Recursos de Gateway",
    "gatewayCardDesc": "Acceda a los Datos básicos para el primer año y más.",
    "gatewayCardDescGms": "Acceda a los Datos básicos, los Elementos de acción, los Recursos de la marca y los Recursos por disciplina del primer año y más.",
    "gatewayCardDescNew": "Acceda a los Datos básicos para el primer año en su nuevo puesto.",
    "gatewayCardDescGmsNew": "Acceda a los Datos básicos, los Elementos de acción, los Recursos de la marca y los Recursos por disciplina del primer año y más."
  },
  "courseBundle": {
    "completeBy": "COMPLETAR ANTES DE",
    "remainingCourses": "Cursos restantes",
    "completedCourses": "Cursos completados",
    "remainingLabel": "Hay {courseCount} cursos en este camino del aprendizaje.",
    "recommendButton": "Recomendar al equipo",
    "startCourse": "Comenzar curso",
    "durationMins": "MINUTOS",
    "completed": "Completado",
    "back": "Volver",
    "additionalLangs1": "Además de ofrecerse en inglés, ahora este curso está disponible en",
    "additionalLangs2": "Seleccione su idioma de preferencia en el menú desplegable luego de iniciar el curso.",
    "targetAudience": "Público objetivo",
    "all": "Todos",
    "globally": "mundialmente",
    "source": "Fuente",
    "courseType": "Tipo de curso",
    "course": "Curso"
  },
  "profile": {
    "save": "Guardar",
    "edit": "Modificar",
    "brand": "Marca",
    "brands": "Marcas",
    "additionalWork": "Todas las responsabilidades laborales adicionales",
    "startDateLabel": "¿Cuándo comenzó a trabajar en Marriott International o Starwood?",
    "preferredLanguageLabel": "¿Cuál es su idioma de preferencia?",
    "jobFunctionsLabel": "¿Qué función desempeña?",
    "interestsLabel": "¿Qué le gustaría aprender?",
    "brandsLabel": "¿Para qué marcas trabaja?",
    "additionalJobFunctionsLabel": "¿Qué otras funciones desempeña?",
    "managementLevelLabel": "¿Cuál es su nivel de asociado?",
    "highGuestContactLabel": "¿Tiene mucho contacto con los huéspedes?"
  },
  "courseDetails": {
    "completeBy": "Para completar",
    "recommendTitlte": "Recomendar curso a su equipo",
    "courseName": "Nombre del curso",
    "sendRecommendation": "Enviar recomendación"
  },
  "explore": {
    "relatedCourses": "Aquí le mostramos los cursos relacionados con sus áreas de interés.",
    "chooseInterests": "Elija sus intereses",
    "title": "Explore el aprendizaje",
    "chooseInterestsModal": "Elija intereses",
    "oppsTitle": "Explore oportunidades de aprendizaje",
    "growWithUsTitle": "Crezca con nosotros",
    "growWithUsDesc": "Marriott cree en una trayectoria que crece con usted. También creemos que las experiencias más amplias a menudo generan una experiencia más enriquecedora.",
    "growWithUsBtn": "VER CREZCA CON NOSOTROS",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "Marriott reconoce el éxito y el compromiso. Reconocemos y promovemos el liderazgo y el servicio excepcional. También otorgamos bonificaciones por referencias exitosas.",
    "mdaBtn": "VER MARRIOTT DEVELOPMENT ACADEMY",
    "selectInterests": "Cuéntenos qué temas le interesan.",
    "otherRecommended": "Otros cursos recomendados",
    "searchByName": "Busque lo que le interesa"
  },
  "plan": {
    "completedRequired": "Ha completado todos los cursos obligatorios.",
    "requiredCourses": "Cursos obligatorios",
    "reqDesc": "Complételos antes de las fechas de expiración.",
    "complete": "COMPLETOS",
    "of": "DE",
    "courses": "CURSOS",
    "completedCourses": "Cursos completados",
    "completedDesc": "¡Felicitaciones! Recientemente completó los cursos que se muestran a continuación.",
    "remaining": "restantes",
    "myLearningTitle": "Mi aprendizaje agregado ",
    "myLearningSubTitle": "Elementos que agregué a Mi plan",
    "history": "Historial",
    "myLearningHistoryLinkText": "Ver mi historial completo",
    "jf": "Su(s) función(es) laboral(es)",
    "awr": "Sus Responsabilidades laborales adicionales (AWR)",
    "jfAwrDesc": "Importante: la función laboral y las AWR determinan las capacitaciones obligatorias que se le asignan en Mi Plan. ",
    "jfAwrEdit": "EDITE su perfil",
    "jfAwrEnd": "para actualizar.",
    "tourPlan": "Mi Plan incluye todos los cursos recomendados y obligatorios que debe completar antes de la fecha límite",
    "tourBasics": "Marriott Basics incluye recursos para que dé los primeros pasos en su recorrido dentro de Marriott. Puede acceder a estos recursos en cualquier momento de su carrera. Los recursos de Marriott Basics son complementarios a la capacitación incluida dentro de Mi Plan.",
    "noCourses": "En este momento, se encuentra al día con la capacitación obligatoria. Lo invitamos a anticiparse y comenzar con los próximos cursos obligatorios."
  },
  "team": {
    "title": "Dirija su equipo",
    "subtitle": "Fomente sus habilidades y carreras.",
    "functions": "Funciones",
    "viewTeam": "Ver equipo",
    "allCourses": "Todos los cursos",
    "downloadExcel": "Descargar informe de Excel"
  },
  "feedbackButton": {
    "havingTrouble": "Sugerencias"
  },
  "recommendation": {
    "dismiss": "¿Está seguro de que desea desestimar esta recomendación?"
  },
  "table": {
    "filterTable": "ตารางตัวกรอง"
  },
  "notifications": {
    "addAssignment2": "Se agregó una tarea %{assignment_type} para %{assignment_name} en %{page location} y debe entregarse el %{due}.",
    "comingDueAssignment": "La tarea %{assignment_type} para %{assignment_name} debe entregarse pronto, el %{due}.",
    "overdueAssignment": "Se venció el plazo para entregar la tarea %{assignment_type} para %{assignment_name}.",
    "addAssignment": "Se agregó una tarea %{assignment_type} para %{assignment_name} en %{page location}.",
    "profile": "Se detectó una actualización de su puesto o ubicación. Revise su Perfil para realizar las actualizaciones correspondientes con respecto a la marca, las funciones o las responsabilidades laborales adicionales. La información precisa del perfil de DLZ garantiza que reciba las tareas de aprendizaje correctas.",
    "helptip": "Todas las notificaciones caducan después de 30 días.",
    "markRead": "Marcar como leído",
    "delete": "Eliminar",
    "markAllRead": "Marcar todo como leído",
    "settings": "Configuración",
    "tooltip": "Las notificaciones leídas se eliminarán dentro de 30 días.",
    "reportComplete": "Finalizó el informe de DLZ. Haga clic para descargar.",
    "dontShowThisAgain": "No volver a mostrar",
    "warningEnabled": "Se habilitó la advertencia de eliminación.",
    "manageEmailNotifications": "Administrar las notificaciones por correo electrónico",
    "warningTooltip": "Al seleccionar esta opción, verá una advertencia cada vez que elimine una notificación.",
    "changeEmailManagedTooltip": "Para cambiar su dirección de correo electrónico, visite mHUB > HR Direct > Yo > Mi información pública. Siga las instrucciones para actualizar sus datos de contacto. Es posible que la dirección de correo electrónico actualizada tarde 1 o 2 días hábiles en mostrarse.",
    "changeEmailFranchisedTooltip": "Para modificar su dirección de correo electrónico, comuníquese con su gerente o con el administrador de la EID Tool de su franquicia. Es posible que la dirección de correo electrónico actualizada tarde 1 o 2 días hábiles en mostrarse.",
    "deleteAllNotifications": "Eliminar todas las notificaciones",
    "deleteIndividualHeader": "Eliminar notificación",
    "deleteAllConfirmation": "¿Está seguro de que desea eliminar todas las notificaciones? Se trasladarán a la sección Eliminado en el Centro de Notificaciones hasta su vencimiento.",
    "deleteIndividualConfirmation": "¿Está seguro de que desea eliminar esta notificación? Las notificaciones eliminadas se trasladan a la sección Eliminado en el Centro de Notificaciones hasta su vencimiento.",
    "permanentDeleteIndividiualConfirmation": "¿Está seguro de que desea eliminar de forma permanente esta notificación? Esta acción no puede deshacerse.",
    "permanentDeleteAllConfirmation": "¿Está seguro de que desea eliminar de forma permanente todas las notificaciones? Esta acción no puede deshacerse.",
    "notificationDeleted": "La notificación se eliminó.",
    "notificationsDeleted": "Las notificaciones se eliminaron.",
    "notificationRestored": "La notificación se restauró.",
    "notificationsRestored": "Las notificaciones se restauraron.",
    "enableDeletionWarning": "Advertencia de eliminación habilitada",
    "warningDisabled": "Se deshabilitó la advertencia de eliminación.",
    "viewAll": "Ver todo",
    "deleteAll": "Eliminar todo",
    "notificationCenterTitle": "Centro de Notificaciones",
    "markAsRead": "Marcar como leído",
    "noDeletedNotifications": "¡Está actualizado!",
    "restore": "Restaurar",
    "restoreAll": "Restaurar todo",
    "viewCourse": "Ver curso",
    "title": "Notificaciones",
    "noNewNotifications": "No tiene notificaciones.",
    "cancel": "Cancelar",
    "manageNotificationsCopy": "Administrar las notificaciones por correo electrónico\n\nElija si quiere recibir mensajes de correo electrónico semanales con un resumen de sus elementos de aprendizaje vencidos. Seleccione «Gestionar» para modificar sus preferencias."
  },
  "transcript": {
    "history": "Mi historial",
    "completed": "Tareas completadas",
    "assignmentName": "Nombre de la tarea",
    "type": "Tipo",
    "viewAssignment": "Ver tarea",
    "assignmentSource": "Fuente de la tarea",
    "requiredAssignments": "Tareas obligatorias",
    "recommendedAssignments": "Tareas recomendadas",
    "voluntary": "Voluntaria",
    "trasscript": "Transcripción",
    "retraining": "Perfeccionamiento",
    "completionDate": "Fecha de finalización",
    "assignmentType": "Tipo de tarea",
    "contentType": "Tipo de contenido",
    "contentId": "Identificación de contenido"
  },
  "contentLaunch": {
    "review": "Revisar aprendizaje",
    "start": "Empezar a aprender"
  },
  "survey": {
    "weValue": "Nos encantaría saber su opinión.",
    "weValueDescription": "Responda cinco preguntas rápidas sobre su experiencia trabajando para el hotel.",
    "weValueButtonText": "Responder la encuesta",
    "weValueG1Q1A1": "Sí",
    "weValueG1Q1A2": "No",
    "strongAgree": "Totalmente de acuerdo",
    "agree": "De acuerdo",
    "slightAgree": "Ligeramente de acuerdo",
    "slightDisagree": "Ligeramente en desacuerdo",
    "disagree": "En desacuerdo",
    "strongDisagree": "Totalmente en desacuerdo",
    "weValueG1Q1Title": "¿Asistió a una reunión diaria durante el último turno en el que trabajó? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments o Daily EDITION)",
    "weValueG1Q2Title": "La reunión diaria (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments o Daily EDITION) me inspira para crear un servicio de lujo cada día.",
    "weValueG1Q3Title": "Mi departamento se centra en la reducción de defectos, incluso aquellos que los huéspedes no comunican.",
    "weValueG1Q4Title": "Tengo la facultad para resolver problemas de los huéspedes sin tener que solicitar autorización.",
    "weValueG1Q5Title": "Tengo la facultad para complacer a los huéspedes sin tener que solicitar autorización.",
    "weValueG2Q1Title": "A menudo, veo que miembros del equipo en mi hotel se esfuerzan al máximo para impresionar a los huéspedes con el cuidado que brindan.",
    "weValueG2Q2Title": "Mi aprendizaje en este departamento me ha permitido brindar un servicio de lujo.",
    "weValueG2Q3Title": "Confío en mi conocimiento y mis aptitudes para brindar un servicio de lujo.",
    "weValueG2Q4Title": "En mi departamento, ofrecemos un entorno de lujo, limpio y sin defectos.",
    "weValueG2Q5Title": "Incluso cuando me encuentro con un problema que no está relacionado con mi departamento, tengo la facultad para responder ante la inquietud del huésped.",
    "weValueG3Q1Title": "En mi hotel, los miembros del equipo reciben el mismo nivel de cuidado que el huésped.",
    "weValueG3Q2Title": "Mi departamento es el reflejo de los valores de la marca.",
    "weValueG3Q3Title": "Cuando me esfuerzo al máximo para crear una experiencia de lujo para el huésped, mi trabajo es reconocido y valorado.",
    "weValueG3Q4Title": "Me involucro en los esfuerzos del departamento para la mejora de nuestro trabajo.",
    "weValueG3Q5Title": "En mi departamento, siempre ponemos al huésped en primer lugar.",
    "submitButton": "Enviar",
    "phasetwo": {
      "weValueG1Q1Title": "¿Asistió a una reunión diaria durante el último turno en el que trabajó? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, o Daily EDITION)",
      "weValueG1Q2Title": "La reunión diaria (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, o Daily EDITION) inspira a los miembros del equipo a crear un servicio de lujo cada día.",
      "weValueG1Q3Title": "En mi hotel, nos centramos en la reducción de defectos, incluso aquellos que los huéspedes no comunican.",
      "weValueG1Q4Title": "En mi hotel, tenemos la facultad para resolver problemas de los huéspedes sin tener que solicitar autorización.",
      "weValueG1Q5Title": "En mi hotel, tenemos la facultad para complacer a los huéspedes sin tener que solicitar autorización.",
      "weValueG2Q1Title": "En mi hotel, habitualmente nos esforzamos al máximo para impresionar a los huéspedes con el cuidado que brindamos.",
      "weValueG2Q2Title": "Las oportunidades de aprendizaje en el hotel nos han permitido brindar un servicio de lujo.",
      "weValueG2Q3Title": "En mi hotel, confiamos en nuestros conocimientos y nuestras aptitudes para brindar un servicio de lujo.",
      "weValueG2Q4Title": "En mi hotel, ofrecemos un entorno de lujo, limpio y sin defectos.",
      "weValueG2Q5Title": "Incluso cuando nos encontramos con un problema que no está relacionado con nuestro departamento, tenemos la facultad para responder ante la inquietud del huésped.",
      "weValueG3Q1Title": "En mi hotel, brindamos el mismo nivel de cuidado a nuestros compañeros que el que les brindamos a los huéspedes.",
      "weValueG3Q2Title": "Mi hotel es el reflejo de los valores de la marca.",
      "weValueG3Q3Title": "En mi hotel, cuando nos esforzamos al máximo para crear una experiencia de lujo para el huésped, nuestro trabajo es reconocido y valorado.",
      "weValueG3Q4Title": "En mi hotel, tenemos la oportunidad de participar en las iniciativas de nuestro departamento para mejorar nuestro propio trabajo.",
      "weValueG3Q5Title": "En mi hotel, siempre priorizamos al huésped."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} COMPLETO",
    "gatewayProgressComplete": "PROGRESO COMPLETO DE GATEWAY",
    "heroMobileGatewayVideoText": "Reproducir video de Gateway",
    "basics": "DATOS BÁSICOS",
    "brandResources": "RECURSOS DE LA MARCA",
    "disciplineResources": "RECURSOS DE DISCIPLINA",
    "immersionResourcesHeader": "Recursos de inmersión",
    "brandCommunicationsHeaader": "Comunicaciones de la marca",
    "brandContactsHeader": "Contactos de la marca",
    "franchisedActionItemsText": "Los Elementos de acción lo ayudan a mantenerse organizado y le proporcionan información sobre la incorporación. Estos elementos no son obligatorios y son complementarios a lo que es posible que reciba por parte de la compañía que gestiona la franquicia.",
    "global": "GLOBAL",
    "continent": "CONTINENTE",
    "viewed": "VISTO",
    "completed": "COMPLETO",
    "heading": "Gateway",
    "basicsTab": "DATOS BÁSICOS",
    "onboardingTab": "Elementos de acción",
    "brandTab": "Recursos de la marca",
    "disciplinesTab": "RECURSOS POR DISCIPLINA",
    "basicsHeader": "Bienvenido a Marriott International",
    "basicsHeaderNewBrandOrRole": "Bienvenido.",
    "basicsText": "Sabemos que hay mucho por aprender al comenzar la carrera. Seleccionamos algunos datos básicos para los primeros días en el trabajo. Se revisarán algunos de esos elementos en detalle en las orientaciones o en la incorporación de la marca, pero esto le servirá para comenzar.",
    "basicsTextGms": "Sabemos que hay mucho por aprender al comenzar la carrera. Seleccionamos algunos datos básicos para los primeros días en el trabajo. Se revisarán algunos de esos elementos en detalle en las orientaciones o en la incorporación de la marca, pero esto le servirá para comenzar. Una vez que termine, diríjase a las otras páginas de Gateway, que incluyen los Elementos de acción, los Recursos de la marca y los Recursos por disciplina.",
    "basicsTextFranchise": "Sabemos que hay mucho por aprender, y seleccionamos algunos datos básicos para los primeros días en el trabajo. Es posible que estos elementos sean complementarios a las orientaciones, la incorporación de la marca o la información de la compañía que gestiona la franquicia.",
    "basicsTextFranchiseGms": "Sabemos que hay mucho por aprender, y seleccionamos algunos datos básicos para los primeros días en el trabajo. Es posible que estos elementos sean complementarios a las orientaciones, la incorporación de la marca o la información de la compañía que gestiona la franquicia. Una vez que termine, diríjase a las otras páginas de Gateway, que incluyen los Elementos de acción, los Recursos de la marca y los Recursos por disciplina.",
    "basicsTextNewToRole": "Sabemos que cuando comience con su nuevo puesto tendrá mucho que aprender. Seleccionamos algunos datos básicos para los primeros días en el trabajo. Se revisarán algunos de esos elementos en detalle en la capacitación asignada, pero esto le servirá para comenzar.",
    "basicsTextNewToRoleGms": "Sabemos que cuando comience con su nuevo puesto tendrá mucho que aprender. Seleccionamos algunos datos básicos para los primeros días en el trabajo. Se revisarán algunos de esos elementos en detalle en la capacitación asignada, pero esto le servirá para comenzar. Una vez que termine, diríjase a las otras páginas de Gateway, que incluyen los Elementos de acción, los Recursos de la marca y los Recursos por disciplina.",
    "basicsTextNewToRoleFranchise": "Sabemos que hay mucho por aprender, y seleccionamos algunos datos básicos para los primeros días en el nuevo puesto. Es posible que estos elementos sean complementarios a la información de la compañía que gestiona la franquicia.",
    "basicsTextNewToRoleFranchiseGms": "Sabemos que hay mucho por aprender, y seleccionamos algunos datos básicos para los primeros días en su nuevo puesto. Es posible que estos elementos sean complementarios a la información de la compañía que gestiona la franquicia. Una vez que termine, diríjase a las otras páginas de Gateway, que incluyen los Elementos de acción, los Recursos de la marca y los Recursos por disciplina.",
    "basicsTextNewToBrand": "Sabemos que cuando comience con su nuevo puesto en %{brandName} tendrá mucho que aprender. Seleccionamos algunos datos básicos para los primeros días en el trabajo. Se revisarán algunos de esos elementos en detalle en las orientaciones o en la incorporación de la marca, pero esto le servirá para comenzar.",
    "basicsTextNewToBrandGms": "Sabemos que cuando comience con su nuevo puesto en %{brandName} tendrá mucho que aprender. Seleccionamos algunos datos básicos para los primeros días en el trabajo. Se revisarán algunos de esos elementos en detalle en las orientaciones o en la incorporación de la marca, pero esto le servirá para comenzar. Una vez que termine, diríjase a las otras páginas de Gateway, que incluyen los Elementos de acción, los Recursos de la marca y los Recursos por disciplina.",
    "basicsTextNewToBrandFranchise": "Sabemos que hay mucho por aprender, y seleccionamos algunos datos básicos para los primeros días en su nuevo puesto. Es posible que estos elementos sean complementarios a las orientaciones, la incorporación de la marca o la información de la compañía que gestiona la franquicia.",
    "basicsTextNewToBrandFranchiseGms": "Sabemos que hay mucho por aprender, y seleccionamos algunos datos básicos para los primeros días en su nuevo puesto en %{brandName}. Es posible que estos elementos sean complementarios a las orientaciones, la incorporación de la marca o la información de la compañía que gestiona la franquicia. Una vez que termine, diríjase a las otras páginas de Gateway, que incluyen los Elementos de acción, los Recursos de la marca y los Recursos por disciplina.",
    "disciplineHeader": "RECURSOS POR DISCIPLINA",
    "toDo": "TAREAS PENDIENTES",
    "optional": "OPCIONAL",
    "overallComplete": "TAREAS TOTALES COMPLETAS",
    "keyResourcesDesc": "Obtenga más información sobre las disciplinas clave que respaldan las operaciones diarias del hotel, la experiencia del huésped y las necesidades del negocio.",
    "franchiseFooter": "Este material contiene recomendaciones y prácticas sugeridas que pueden mejorar la posibilidad de cumplimiento de los estándares de la marca. Como franquiciador, Marriott no participa en la gestión o en la operación de los aspectos del hotel o la operación empresarial del franquiciado. Los franquiciados tienen el control de las operaciones diarias de sus hoteles y son responsables de las prácticas laborales y de sus empleados.",
    "landingHeroTitle1": "Bienvenidos a Marriott International.",
    "landingHeroTitle2": "Empiece su experiencia aquí.",
    "landingHeroText": "The Gateway es una herramienta que lo guía durante el primer año en su nuevo puesto. Allí encontrará la información y los recursos que necesita para tener un buen desempeño.",
    "landingHeroTitle1NewToRole": "Bienvenido a The Gateway.",
    "landingHeroTitle1NewToBrand": "Bienvenido a The Gateway.",
    "landingButtonText": "Comenzar",
    "twoMonthslandingHeroTitle1": "¡Bienvenido de nuevo!",
    "twoMonthslandingHeroText": "Si aún no lo ha hecho, complete sus datos básicos en The Gateway.",
    "twoMonthslandingButtonText": "Ingresar a The Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "¡Bienvenido de nuevo!",
    "twoMonthslandingHeroTextNewToBrand": "Esperamos que los primeros días en %{brandName} hayan sido excelentes. No olvide continuar su experiencia y obtener acceso a todo lo que necesita en The Gateway",
    "twoMonthslandingButtonTextNewToBrand": "No se detenga",
    "threeMonthslandingHeroTitle1": "¡Está en camino!",
    "threeMonthslandingHeroText": "Todo lo que necesita saber acerca de Marriott International se encuentra en The Gateway.",
    "threeMonthslandingButtonText": "Ingresar a The Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "¡Está en camino!",
    "threeMonthslandingHeroTextNewToRole": "Todo lo que necesita para tener un buen desempeño en su nuevo puesto se encuentra en The Gateway.",
    "threeMonthslandingButtonTextNewToRole": "Ingresar a The Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "¡Bienvenido de nuevo!",
    "threeMonthslandingHeroTextNewToBrand": "Felicitaciones por haber completado los primeros %{numDays} días con su nueva marca. Puede acceder a los Elementos de acción, los Recursos de la marca y los Recursos por disciplina cuando lo necesite.",
    "threeMonthslandingButtonTextNewToBrand": "Ingresar a The Gateway",
    "fourMonthslandingHeroTitle1": "¡Muy bien! Ya completó los datos básicos.",
    "fourMonthslandingHeroText": "Obtenga acceso a todo lo que necesita saber acerca de Marriott International en The Gateway.",
    "fourMonthslandingButtonText": "Ingresar a The Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "¡Muy bien! Ya completó los datos básicos.",
    "fourMonthslandingHeroTextNewToRole": "Acceda a todo lo que necesita para tener un buen desempeño en su nuevo puesto en The Gateway.",
    "fourMonthslandingButtonTextNewToRole": "Ingresar a The Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway: Registro",
    "fourMonthslandingHeroTextNewToBrand": "A medida que se acerca el final del primer año en %{brandName}, si no lo ha hecho aún, complete todos los Elementos de acción y utilice los recursos de desarrollo disponibles aquí, en Digital Learning Zone. Haga clic en «Continuar» para avanzar en su experiencia.",
    "fourMonthslandingButtonTextNewToBrand": "Continuar",
    "gmSecondVisitlandingHeroTitle1": "¡Bienvenido de nuevo!",
    "gmSecondVisitlandingHeroText": "Esperamos que los primeros días hayan sido excelentes. No olvide continuar con la experiencia para crecer en su nuevo puesto.",
    "gmSecondVisitlandingButtonText": "No se detenga",
    "gmSecondVisitlandingHeroTextNewToRole": "Esperamos que los primeros días en su nuevo puesto hayan sido excelentes. No olvide continuar la experiencia y obtener acceso a todo lo que necesita en The Gateway.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Esperamos que los primeros días en %{brandName} hayan sido excelentes. No olvide continuar la experiencia y obtener acceso a todo lo que necesita en The Gateway.",
    "gmThirdVisitlandingHeroTitle1": "¡Bienvenido de nuevo!",
    "gmThirdVisitlandingHeroText": "Felicitaciones por completar los primeros %{numDays} días de su experiencia como empleado nuevo. Puede acceder a los Elementos de acción, los Recursos de la marca y los Recursos por disciplina cuando lo necesite.",
    "gmThirdVisitlandingButtonText": "Ingresar a The Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "Felicitaciones por completar los primeros %{numDays} días de su nuevo puesto. Puede acceder a los Elementos de acción, los Recursos de la marca y los Recursos por disciplina cuando lo necesite.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Felicitaciones por completar los primeros %{numDays} días con su nueva marca. Puede acceder a los Elementos de acción, los Recursos de la marca y los Recursos por disciplina cuando lo necesite.",
    "gmSevenMonthslandingHeroTitle1": "Parece que le falta completar algunos Elementos de acción.",
    "gmSevenMonthslandingHeroText": "A medida que se acerca el final del primer año, no olvide completar todos los Elementos de acción y utilizar los recursos de desarrollo disponibles aquí, en Digital Learning Zone. Haga clic en «Continuar» para avanzar en su experiencia.",
    "gmSevenMonthslandingButtonText": "Continuar",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway: Registro",
    "gmSevenMonthslandingHeroTextNewToRole": "A medida que se acerca el final del primer año en su nuevo puesto, si no lo ha hecho aún, complete todos los Elementos de acción y utilice los recursos de desarrollo disponibles aquí, en Digital Learning Zone. Haga clic en «Continuar» para avanzar en su experiencia.",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway: Registro",
    "gmSevenMonthslandingHeroTextNewToBrand": "A medida que se acerca el final del primer año en %{brandName}, si no lo ha hecho aún, complete todos los Elementos de acción y utilice los recursos de desarrollo disponibles aquí, en Digital Learning Zone. Haga clic en «Continuar» para avanzar en su experiencia.",
    "gmOneYearlandingHeroTitle1": "¡Felicitaciones por completar el primer año en %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "¡Felicitaciones por el primer año en el nuevo puesto!",
    "gmOneYearlandingHeroTitle1NewToBrand": "¡Felicitaciones por el primer año en el nuevo puesto en %{brandName}!",
    "gmOneYearlandingHeroText": "Quizá la experiencia The Gateway terminó, pero lo mejor está por venir. Puede acceder a los recursos desde la pestaña The Gateway en cualquier momento.",
    "gmOneYearlandingButtonText": "Ver The Gateway"
  },
  "resources": {
    "iptTooltip": "Utilice comas para separar las múltiples ubicaciones.",
    "iptAllFunctionTooltip": "Desmarque para seleccionar las funciones laborales específicas. Manténgase conectado para ver la capacitación de todas las funciones en la ubicación seleccionada.",
    "iptAllAWRTooltip": "Desmarque para seleccionar las responsabilidades laborales adicionales específicas. Manténganse conectado para ver la capacitación de todas las responsabilidades laborales adicionales en la ubicación seleccionada.",
    "editJFTooltip": "Utilice comas para separar los múltiples ingresos."
  },
  "Notification Center: ": "Marcar como no leído",
  "Notification Center: Empty": "¡Está todo al día!",
  "Notification Center: Individual Delete": "¿Está seguro de que desea eliminar esta notificación? Las notificaciones eliminadas se trasladan a la sección Eliminado en el Centro de Notificaciones hasta su vencimiento.",
  "Notification Center: Delete All": "¿Está seguro de que desea eliminar todas las notificaciones? Se trasladarán a la sección Eliminado en el Centro de Notificaciones hasta su vencimiento.",
  "Notification Center: Mark All As Read": "Marcar todo como leído",
  "Notification Center: Restore All": "Restaurar todo",
  "Notification Center: Delete Confirmation": "Eliminar",
  "Warning Message Text: Remove Job Function or AWR": "¿Desea eliminar una función laboral o responsabilidad laboral adicional (AWR)?\r\nSi hace clic en Enviar, eliminará %{Function and/or AWR} en %{count} estudiantes.",
  "Warning Message Text: Add Job Function or AWR": "¿Desea agregar una función laboral o responsabilidad laboral adicional (AWR)?\r\nSi hace clic en Enviar, agregará %{Function and/or AWR} en %{count} estudiantes.",
  "Button": "Enviar",
  "Success Message Text: Add Job Function and/or AWR": "Felicidades\r\nHa agregado %{Job Function and/or AWR} en los asociados seleccionados de %{location}. \r\n\r\n¿Desea editar otras funciones laborales o responsabilidades laborales adicionales en %{location}?",
  "Message Text:AWR": "Felicidades\r\nHa agregado las responsabilidades laborales adicionales %{ __} en los asociados seleccionados de %{ __}.\r\n\r\n¿Desea editar otras funciones laborales o responsabilidades laborales adicionales en %{location, property, learner(s)}?",
  "Success Message Text: Remove Job Function": "Felicidades\r\nHa eliminado %{Job Function and/or AWR} en los asociados seleccionados de %{location}. \r\n\r\n¿Desea editar otras funciones laborales o responsabilidades laborales adicionales en %{location}?",
  "Success Message Text: Remove AWR": "Felicidades\r\nHa eliminado la responsabilidad laboral adicional %{ __} en los asociados seleccionados de %{ __} .\r\n\r\n¿Desea editar otras funciones laborales o responsabilidades laborales adicionales en %{location, property, learner(s)}?",
  "Tool Tip - Managed Change Email": "Para cambiar su dirección de correo electrónico, visite mHUB > HR Direct > Yo > Mi información pública. Siga las indicaciones para actualizar sus datos de contacto. Es posible que la dirección de correo electrónico actualizada tarde 1 o 2 días hábiles en aparecer.",
  "Tool Tip - Franchised  Change Email": "Para modificar su dirección de correo electrónico, comuníquese con su gerente o con el administrador de la EID Tool de la franquicia. Es posible que la dirección de correo electrónico actualizada tarde 1 o 2 días hábiles en aparecer.",
  "User Profile: Email Opt-in": "No, gracias.",
  "User Profile: Email Frequency": "Resumen semanal",
  "Generic Notification Email Subject Line:": "Tiene notificaciones nuevas en DLZ",
  "Generic Body Text (<150 Characters)": "Visite DLZ para obtener más información.",
  "All Emails: Signature": "Digital Learning Zone - Aprendizaje simplificado. Todo comienza con usted.",
  "All Emails: Footer": "Puede anular/cancelar la recepción de notificaciones de correo electrónico en cualquier momento. Visite Digital Learning Zone/Perfil para actualizar sus preferencias.",
  "Overdue Emails: Subject Line: ": "Las actividades de DLZ requieren su atención",
  "Overdue Emails: Body Test": "Tiene actividades atrasadas. Inicie sesión en DLZ para revisar las actividades.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Perfeccione sus habilidades",
    "sharpenYourSkillsSubTitle": "Desarrolle sus conocimientos y habilidades para mejorar su carrera ahora y en el futuro.",
    "growYourCareerTitle": "Desarrolle su carrera",
    "growYourCareerSubTitle": "Planifique su próximo paso con habilidades especializadas o capacitación cruzada que respalden sus intereses y objetivos únicos.",
    "additionalResourcesTitle": "Recursos adicionales",
    "additionalResourcesSubTitle": "Explore getAbstract® y CultureWizard™. Vea cómo DLZ puede seguir ayudándolo a crecer.",
    "tourHeader": "Bienvenido a DLZ actualizada. Ahora puede buscar lo que necesite, cuando lo necesite. Además, encontrará la sección Explorar aprendizaje actualizada y la sección Mi plan más personalizada. Haga el recorrido",
    "tourBtnSkip": "Descartar",
    "tourBtnNext": "Cierre (6/6)",
    "tourSearchBar": "Encuentre lo que necesita, cuando lo necesita, con una función de búsqueda nueva y más sólida.",
    "tourBtnBack": "Volver",
    "tourMyPlan": "Mi plan ahora tiene elementos que usted agrega desde Buscar y Explorar, para que pueda controlar por completo su camino de aprendizaje.",
    "tourExploreLearningLink": "Aún puede acceder a Explorar aprendizaje desde aquí.",
    "tourExploreLearningProfile": "Aproveche al máximo su experiencia de aprendizaje y revise su perfil, especialmente las funciones laborales, de manera rutinaria para mantener Mi plan actualizado.",
    "mhubTraining": "Capacitación de mHUB",
    "growthSkills": "Habilidades de crecimiento",
    "leadershipSkills": "Habilidades de liderazgo",
    "virtualTeamResources": "Recursos de equipos virtuales",
    "jobFunctions": "Funciones laborales",
    "brands": "Marcas",
    "languageLearning": "Aprendizaje de idioma",
    "become": "Llegar a Ser",
    "emeaMentoringProgram": "Programa de tutorías de Europa, Medio Oriente y África",
    "learningPathways": "Trayectorias de aprendizaje",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "Centro comunitario de TakeCare",
    "mhubTrainingDesc": "Encuentre trayectorias de aprendizaje correspondientes a su función y cursos específicos sobre cómo utilizar mHUB.",
    "growthSkillsDesc": "Obtenga conocimientos prácticos en diferentes funciones y temas.",
    "leadershipSkillsDesc": "Descubra cómo dirigir y capacitar un equipo y conviértase en un líder más eficaz.",
    "virtualTeamResourcesDesc": "Aprenda técnicas para fomentar una cultura colaborativa e inclusiva dentro de los equipos virtuales.",
    "jobFunctionsDesc": "Amplíe los conocimientos de su función laboral actual y aprenda sobre otras funciones.",
    "brandsDesc": "Obtenga más información sobre las diversas marcas de Marriott International.",
    "languageLearningDesc": "Comuníquese de manera eficaz con una audiencia global.",
    "becomeDesc": "Descubra un enfoque integral sobre el crecimiento y desarrollo profesional: reflexión, planificación, acción, redefinición.",
    "emeaMentoringProgramDesc": "Revele su potencial con el Programa de tutorías de Europa, Medio Oriente y África.",
    "learningPathwaysDesc": "Explore oportunidades de aprendizaje específicas elaboradas para su nivel de liderazgo.",
    "mdaDesc": "Desarrolle habilidades de liderazgo y habilidades funcionales con Marriott Development Academy.",
    "takeCareGrowDesc": "Identifique recursos diseñados para crear una comunidad inclusiva e inspirada.",
    "getAbstractDesc": "Aprenda algo nuevo todos los días con resúmenes de libros que lo ayudan a tomar mejores decisiones.",
    "cultureWizardDesc": "Adquiera conocimientos valiosos para comunicarse de manera eficaz en diferentes culturas.",
    "takeCareCommunityHubDesc": "Descubra las actividades de integración de equipos, los programas de aprendizaje y las herramientas de TakeCare.",
    "searchAllLearning": "Buscar todo el aprendizaje",
    "searchBarPlaceHolder": "Siempre hay más para aprender.",
    "clearFilters": "Borrar filtros",
    "noResults": "No se encontraron resultados",
    "hasBeenUnpinned": "No se ancló {Variable-tile_Name}.",
    "hasBeenPinned": "Se ancló {Variable-tile_Name}.",
    "lpRemovedFromPlan": "Se eliminó la trayectoria de aprendizaje de su plan.",
    "courseRemovedFromPlan": "Se eliminó el curso de su plan.",
    "lpAddedToPlan": "Se añadió la trayectoria de aprendizaje a su plan.",
    "courseAddedToPlan": "Se añadió el curso a su plan.",
    "clearSelection": "Borrar selección",
    "removeFromMyPlan": "Eliminar de Mi Plan",
    "addToMyPlan": "Añadir a Mi Plan",
    "loadingResults": "Cargando",
    "viewDetails": "Ver detalles",
    "clearAll": "Borrar todo",
    "noOptions": "No hay opciones disponibles",
    "viewLess": "Ver menos",
    "viewMore": "Ver más",
    "recentSearch": "Búsquedas recientes",
    "exploreLearningTitle": "Explorar aprendizaje",
    "exploreLearningHelpText": "¿No está seguro de lo que está buscando?",
    "exploreMore": "Explorar más.",
    "viewAllResults": "Ver los <#> resultados",
    "searchSubTitle": "Buscar cursos usando palabras clave, títulos o identificación de contenido.",
    "pinnedLearningTitle": "Mis aprendizajes anclados",
    "landingSubTitle": "Aprópiese de su camino de aprendizaje. Explore sus intereses: se agregan temas y cursos nuevos todo el tiempo.",
    "columnTitle": "Cargo",
    "columnDesc": "Descripción",
    "columnType": "Tipo",
    "columnAddedToPlan": "Añadir/Añadido al Plan",
    "columnComplete": "Completado",
    "columnDetails": "Detalles",
    "additionalSearchFilters": "Filtros de búsqueda adicionales",
    "tourBtnClose": "Cerrar",
    "tourBtnLast": "Cerrar",
    "resultsFor": "Resultados para ",
    "resultsFortheFilter": "Resultados para el filtro {Filter Name}",
    "no": "No se encontraron",
    "results": "resultados",
    "courseTooltipTitle": "Un elemento de aprendizaje que puede contener una o más actividades.<br />Un curso puede llevarse a cabo en línea, por ejemplo, en formato de video o de eLearning, o realizarse en un aula.",
    "gatewayToolTipTitle": "Recursos digitales para los empleados nuevos, las personas nuevas en el equipo directivo y los gerentes generales nuevos de la marca.",
    "speciallizationOrPathwayTooltipTitle": "Un conjunto de cursos centrados en un tema particular.",
    "taskTooltipTitle": "Instrucciones para completar una actividad (habitualmente en el trabajo) y luego marcar que ya la ha completado.",
    "webSiteTooltipTitle": "Cualquier sitio web, incluidos los sitios web de Marriott o los sitios web externos."
  },
  "dontShowAgain": "No volver a mostrar",
  "deletionWarningEnabled": "Advertencia de eliminación habilitada",
  "deletionSelected": "Al seleccionar esta opción, verá una advertencia cada vez que elimine una notificación.",
  "manageEmailNotifications": "Administrar las notificaciones por correo electrónico",
  "seeHowDLZContinue": "Vea cómo DLZ puede seguir ayudándolo a crecer.",
  "Notification Center:Overdue": "Tiene una capacitación que venció el {Variable date ddmmyy}",
  "accesDenied": {
    "title": "El acceso a su recorrido de aprendizaje está en curso. ",
    "subtitle": "Su acceso se está creando y estará disponible entre uno y dos días luego de la activación de su EID. Si han transcurrido más de dos días, comuníquese con el centro de servicio de mHUB."
  },
  "jfTour": {
    "title": "Ahora puede ver y editar las funciones laborales seleccionadas y las responsabilidades laborales adicionales (additional work responsibilities, AWR) directamente desde Mi Plan."
  },
  "onboardingPlan": {
    "requiredDescription": "A continuación, se muestran los cursos obligatorios que debe completar en el período que seleccionó en Mi Plan. A medida que se familiariza con su nuevo puesto, planifique, junto con su líder, cuándo completará las capacitaciones obligatorias. Aprenderá sobre diversos temas; asegúrese de solicitar asistencia a su líder o a sus compañeros si tiene preguntas durante el proceso.   ",
    "day1": "Plazo límite el día 14",
    "day2": "Plazo límite el día 30",
    "day3": "Plazo límite el día 60",
    "day4": "Plazo límite el día 90",
    "day5": "Plazo límite después del día 90 "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone es el punto de partida para su desarrollo y aprendizaje profesional. A continuación, encontrará contenido específicamente seleccionado para ayudarlo a dar sus primeros pasos. Todas sus capacitaciones pueden encontrarse siempre en Mi Plan.  ",
    "overallLearningProgress": "Progreso de aprendizaje general",
    "learningProgressLabel": "Progreso de aprendizaje",
    "learningProgressDescription": "Aquí encontrará su progreso en el aprendizaje que debe completar. La barra de progreso indica cuánto ha avanzado en el aprendizaje que debe completar. ¡Buen trabajo, siga así!"
  },
  "Recommended Courses ": "Luego de los primeros 90 días, encontrará cursos adicionales recomendados por su gerente. También puede explorar temas que sean de su propio interés. Por el momento, concéntrese en su aprendizaje básico. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Realice la encuesta",
      "lbiIntro": "¡Le damos la bienvenida al registro de un comportamiento de lujo!",
      "lbiDescription": `<p>A continuación, se le pedirá que lea algunas situaciones que podría experimentar en su hotel. Lea cada situación y elija cómo respondería a cada una. Tenga en cuenta lo siguiente cuando responda:</p>
      <ul>
          <li>
              <p>Es posible que más de una respuesta le parezca correcta. Elija aquella opción que sea más probable que adopte en el trabajo.</p>
          </li>
          <li>
              <p>Haga como si no existieran barreras de comunicación. El idioma que habla es el mismo que habla el huésped o asociado en la situación.</p>
          </li>
          <li>
              <p>Es posible que tenga que imaginarse en un puesto de trabajo diferente al puesto que ocupa actualmente. Responda a la pregunta según lo que sabe y basándose en su experiencia como profesional de la hostelería de lujo.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Tenga en cuenta: Su participación en este registro es totalmente voluntaria (puede elegir no participar), y sus respuestas serán estrictamente confidenciales. Los resultados de este registro se informarán agrupados y no por persona. Las respuestas individuales serán evaluadas por el equipo de investigación y no serán compartidas con gerentes de otros o líderes.</p>
      <p>Al completar este registro, acepta la recopilación y el procesamiento de sus respuestas y datos personales (p. ej., antigüedad, sexo, puesto de trabajo), así como la transferencia transfronteriza de sus datos personales al Grupo Marriott. Esto se realiza de acuerdo con la Declaración de privacidad de datos de asociados de Marriott; también puede encontrar información adicional y contactos de referencia en las Políticas de privacidad de datos de Marriott.</p>
      `,
      "checkin": {
        q1Title: "Está caminando por el lobby y observa a una familia con tres niños pequeños. La familia tiene muchos bolsos, parece estresada por el largo día de viaje, y los niños están llorando y se muestran inquietos. ¿Qué haría?",
        q2Title: "Acaba de comenzar a trabajar en un área nueva del hotel. Un huésped pide sugerencias sobre qué hacer en la zona. El huésped ha leído varias guías, pero está buscando algo más personalizado. ¿Qué haría? ",
        q3Title: "Está caminando por el lobby cuando escucha a un huésped hablando por teléfono y se entera de que su servicio de automóvil al aeropuerto llegará tarde. Su hotel no tiene un automóvil o no está disponible. ¿Qué haría?",
        q4Title: "Está pasando por el lobby y se dirige a realizar una tarea urgente que le ha asignado su gerente. Nota que un colega tiene dificultades para hacer una tarea que normalmente realizan dos personas. ¿Qué haría? ",
        q5Title: "Usted es el anfitrión del restaurante del hotel. Un huésped pide ver el menú mientras espera su mesa reservada. Después de mirar el menú, el huésped se siente frustrado porque otro colega le sugirió el restaurante, pero no hay nada en el menú que satisfaga sus necesidades relacionadas con la dieta. ¿Qué haría?",
        q6Title: "El área de la piscina tiene mucha gente y un gran grupo de huéspedes no pudieron encontrar asientos juntos. Como resultado, han colocado sus toallas y pertenencias personales de tal manera que bloquean un pasillo principal, lo que genera un peligro y una interrupción para los huéspedes y asociados que intentan acceder al área de la piscina. Es necesario despejar el área, pero no desea molestar al grupo. ¿Qué haría? ",
        q1R1Title: "Ayuda a la familia con su equipaje, la dirige hacia el mostrador de check-in y le sugiere algunos lugares cercanos relajantes con actividades para toda la familia. Menciona los servicios familiares con los que cuenta el hotel.",
        q1R2Title: "Ofrece ayuda con su equipaje y dirige a la familia para que haga el check-in. Luego, organiza un servicio relajante o una atención para los padres y actividades divertidas para los niños en su habitación. ",
        q1R3Title: "Dirige a los padres para que hagan el check-in y luego lleva usted mismo a los niños a una sala de estar con refrigerios mientras los padres hacen el check-in. Se cerciora de que la recepción les informe a los padres sobre las áreas de juego y las actividades que los niños tienen disponible en el hotel para su comodidad.",
        q1R4Title: "Señala a la familia el mostrador para que realicen el proceso de check-in rápido. Menciona a los padres las áreas y los servicios aptos para niños para que puedan disfrutar de una estadía agradable.",
        q2R1Title: "Pregunta al huésped qué le gusta y, luego, llama a un colega para crear juntos una lista de cosas divertidas que puede hacer. Además, le sugiere un buen restaurante cercano.",
        q2R2Title: "Conversa con el huésped sobre lo que le gusta. Trabaja con un colega para hacer una lista especial de cosas divertidas especialmente pensadas para el huésped. Además, revisa el perfil de Bonvoy del huésped y le envía un regalo personalizado a su habitación.",
        q2R3Title: "Ofrece su opinión y comparte algunas de las cosas favoritas que descubrió para hacer en la zona hasta el momento. Destaca esas sugerencias compartiendo detalles adicionales sobre sus experiencias. Luego, le envía un delicioso bocadillo local a su habitación.",
        q2R4Title: "Explica al huésped que es nuevo en la zona y que no tiene muchas ideas. Se disculpa por ello y le sugiere que hable con su colega que sabe más. Luego, le pregunta si desea que le envíen un bocadillo local a su habitación.",
        q3R1Title: "Mientras el huésped aún está al teléfono, busca su propio vehículo o el de un colega y lo lleva hasta la entrada del hotel y le hace saber que lo llevará usted mismo al aeropuerto.",
        q3R2Title: "Mientras el huésped aún está al teléfono, prepara una lista con los servicios de automóvil y números de teléfono aprobados por el hotel. Una vez que el huésped para de hablar, le entrega la lista y le ofrece ayuda para organizar el transporte.",
        q3R3Title: "Espera a que el huésped pare de hablar y le informa que su hotel no ofrece servicios de transporte, pero con gusto lo ayudará a coordinar otras opciones de transporte. ",
        q3R4Title: "Mientras el huésped sigue hablando por teléfono, le busca otra opción de transporte. Una vez que para de hablar, le informa al huésped que ha hecho los arreglos para su transporte al aeropuerto y que el automóvil llegará en breve.",
        q4R1Title: "Le indica a su colega que lo ayudará, pero primero debe terminar una tarea urgente. Promete regresar rápidamente para ayudarlo. Se asegura de comentarle la situación a su gerente.",
        q4R2Title: "Primero, busca rápidamente a otro colega para que se ocupe de su tarea. Luego, asiste de inmediato a su colega que necesita ayuda. Cuando termina, le informa a su gerente de que ha encontrado a otra persona para hacer su tarea y así poder ayudar a su colega. ",
        q4R3Title: "Se detiene y ayuda a su colega de inmediato. Después de ayudarlo, retoma la tarea asignada y, a continuación, le informa a su gerente que ha interrumpido su tarea para ayudar a su colega.",
        q4R4Title: "Le explica a su colega que necesita ayuda que está en medio de una tarea urgente. Pone en pausa ambas tareas y piensa cómo resolverlas conjuntamente y luego realiza ambas tareas juntas.",
        q5R1Title: "Se disculpa con el huésped y le informa que llamará al gerente. Se cerciora de explicar la situación, para que el gerente pueda ayudar al huésped con sus necesidades de comida.",
        q5R2Title: "Se disculpa con el huésped y le pregunta cuáles son sus preferencias de comida. Habla con el equipo de cocina para preparar un plato especial solo para el huésped. Además, le ofrece un aperitivo o una bebida de cortesía para compensar el error.",
        q5R3Title: "Se disculpa y le dice al huésped que entiende la situación. En lugar de cancelar la reserva, le ofrece hablar con el chef para ver si puede prepararle algo especial. Le ofrece una atención o un descuento de cortesía para compensar el problema.",
        q5R4Title: "Se disculpa y le dice al huésped que puede ayudarlo a reservar una mesa en un restaurante cercano que tenga la comida que le gusta. Le ofrece información adicional o lo ayuda a llegar allí si es necesario.",
        q6R1Title: "Se disculpa con los huéspedes por la falta de espacio y les indica que pueden permanecer donde están, siempre que estén atentos a las personas que necesiten pasar. ",
        q6R2Title: "Les indica a los huéspedes que no pueden bloquear el pasillo y que tendrán que buscar otro lugar o esperar hasta que se libere espacio. Mientras tanto, les pide a sus colegas que traigan asientos adicionales del salón al aire libre para que los usen. ",
        q6R3Title: "Les indica a los huéspedes que no pueden permanecer en esa ubicación y que deben moverse temporalmente mientras usted busca otros asientos. Entretanto, les ofrece un espacio en el bar/restaurante. ",
        q6R4Title: "Se disculpa con los huéspedes por el problema de los asientos. Les pregunta si pueden juntar sus cosas para hacer un pequeño pasillo para que los demás puedan pasar. Ofrece ayudarlos y se asegura de que todos se sientan cómodos. Les envía una atención especial del bar/restaurante por sus inconvenientes."
      }
    }
  },
};