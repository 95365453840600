export default {
  onboardingLanding: {
    gettingStarted: 'Getting Started',
    gettingStartedDescription: 'The Digital Learning Zone is your launching point for professional learning and development. Below you will find content curated to help you get started. All your learning can always be found under My Plan.',
    overallLearningProgress: 'Overall Learning Progress',
    learningProgressLabel: 'Learning Progress',
    learningProgressDescription: 'Here is how you are progressing through your required learning. This progress bar includes the status of all your required learning. Keep up the good work!'  
  },
  onboardingPlan: {
    day1: 'Due by Day 14',
    day2: 'Due by Day 30',
    day3: 'Due by Day 60',
    day4: 'Due by Day 90',
    day5: 'Due after 90 days',
    requiredDescription: 'Below are the required courses for completion in the time period you have selected under My Plan. Work with your leader to schedule time to complete your required learning as you navigate your new role. You will learn about a variety of topics, be sure to reach out to your leader and peers with any questions you have.',
    recommendedDescription: 'After your first 90 days, you will find additional courses that have been recommended by your manager. You can also explore topics that interest you on your own. For now, focus on your foundational learning.',
  },
  accesDenied: {
    title: 'Access to your Learning Journey is in progress!',
    subtitle: 'Your access is being created and should be available 1-2 days after your EID is active.  If it’s been longer than 2 days, please contact the mHUB Service Center',
  },
  moment: 'THIS IS YOUR MOMENT.',
  becomeTitle: 'Become.',
  banner: {
    viewDetails: 'View Details',
    requiredCourses: 'Your required courses are due in',
    days: 'days'
  },
  logout: {
    label: 'You have been logged out. Please close your browser now to fully disconnect from your current session.'
  },
  header: {
    plan: 'My Plan',
    basics: 'MARRIOTT BASICS',
    manager: 'Manager',
    explore: 'Explore Learning',
    reporting: 'Reporting',
    myTeam: 'My Team',
    logout: 'Log Out',
    profile: 'Profile',
    myHistory: 'My History',
    help: 'Resources',
    gateway: 'Gateway'
  },
  footer: {
    plan: 'MY PLAN',
    manager: 'MANAGER',
    explore: 'EXPLORE LEARNING',
    tagline: 'Learning made simple. It starts with you.'
  },
  gateway: {
    planProgressComplete: '%{label} COMPLETE',
    gatewayProgressComplete: 'GATEWAY PROGRESS COMPLETE',
    heroMobileGatewayVideoText: 'Play Gateway Video',
    basics: 'MARRIOTT BASICS',
    brandResources: 'BRAND RESOURCES',
    disciplineResources: 'DISCIPLINE RESOURCES',
    immersionResourcesHeader: 'Immersion Resources',
    brandCommunicationsHeaader: 'Brand Communications',
    brandContactsHeader: 'Brand Contacts',
    franchisedActionItemsText: 'Action items help you stay organized and give you key onboarding information. These items are not required and are supplemental to what you may receive from your franchise management company.',
    global: 'GLOBAL',
    continent: 'CONTINENT',
    viewed: 'VIEWED',
    completed: 'COMPLETED',
    heading: 'Gateway',
    basicsTab: 'MARRIOTT BASICS',
    onboardingTab: 'Action Items',
    brandTab: 'Brand Resources',
    disciplinesTab: 'RESOURCES BY DISCIPLINE',
    basicsHeader: 'Welcome to Marriott International',
    basicsHeaderNewBrandOrRole: 'Welcome,',
    basicsText: 'As you start your career, we know there is a lot to learn. We’ve selected a few of the basics for your first few days on the job. Some of these items may be reviewed more in-depth in orientations or brand onboarding, but this will get you started.',
    basicsTextGms: 'As you start your career, we know there is a lot to learn. We’ve selected a few of the basics for your first few days on the job. Some of these items may be reviewed more in-depth in orientations or brand onboarding, but this will get you started. Once you’re finished, move on to the other Gateway pages, including the Action Items, Brand Resources and Resources by Discipline.',
    basicsTextFranchise: 'We know there is a lot to learn, and we’ve selected a few of the basics for your first days on the job. These items may be supplemental to your orientations, brand onboarding or information from your franchise management company.',
    basicsTextFranchiseGms: 'We know there is a lot to learn, and we’ve selected a few of the basics for your first days on the job. These items may be supplemental to your orientations, brand onboarding or information from your franchise management company. Once you’re finished, move on to the other Gateway pages, including the Action Items, Brand Resources and Resources by Discipline.',
    basicsTextNewToRole: 'As you start your new role, we know there is a lot to learn. We’ve selected a few of the basics for your first few days on the job. Some of these items may be reviewed more in-depth in your assigned training, but this will get you started.',
    basicsTextNewToRoleGms: 'As you start your new role, we know there is a lot to learn. We’ve selected a few of the basics for your first few days on the job. Some of these items may be reviewed more in-depth in your assigned training, but this will get you started. Once you’re finished, move on to the other Gateway pages, including the Action Items, Brand Resources and Resources by Discipline.',
    basicsTextNewToRoleFranchise: 'We know there is a lot to learn, and we’ve selected a few of the basics for your first days in your new role. These items may be supplemental to information from your franchise management company.',
    basicsTextNewToRoleFranchiseGms: 'We know there is a lot to learn, and we’ve selected a few of the basics for your first days in your new role. These items may be supplemental to information from your franchise management company. Once you’re finished, move on to the other Gateway pages, including the Action Items, Brand Resources and Resources by Discipline.',
    basicsTextNewToBrand: 'As you start your new role with %{brandName}, we know there is a lot to learn. We’ve selected a few of the basics for your first few days on the job. Some of these items may be reviewed more in-depth in orientations or brand onboarding, but this will get you started.',
    basicsTextNewToBrandGms: 'As you start your new role with %{brandName}, we know there is a lot to learn. We’ve selected a few of the basics for your first few days on the job. Some of these items may be reviewed more in-depth in orientations or brand onboarding, but this will get you started. Once you’re finished, move on to the other Gateway pages, including the Action Items, Brand Resources and Resources by Discipline.',
    basicsTextNewToBrandFranchise: 'We know there is a lot to learn, and we’ve selected a few of the basics for your first days in your new role. These items may be supplemental to your orientations, brand onboarding or information from your franchise management company.',
    basicsTextNewToBrandFranchiseGms: 'We know there is a lot to learn, and we’ve selected a few of the basics for your first days in your new role with %{brandName}. These items may be supplemental to your orientations, brand onboarding or information from your franchise management company. Once you’re finished, move on to the other Gateway pages, including the Action Items, Brand Resources and Resources by Discipline.',
    disciplineHeader: 'Resources By Discipline',
    disciplineText: 'This text is TBD',
    toDo: 'TO DO',
    optional: 'OPTIONAL',
    overallComplete: 'OVERALL COMPLETE',
    keyResourcesDesc: 'Learn more about key disciplines that support daily property operations, guest experience and business needs.',
    franchiseFooter: 'This material contains recommendations and suggested practices that may enhance likelihood of compliance with Brand Standards. As a franchisor, Marriott does not engage in the management or operation of any aspect of a franchisee’s hotel or business operation. Franchisees retain control over the day-to-day operation of their hotels and are responsible for their own employees and employment practices.',
    landingHeroTitle1: 'Welcome to Marriott International.',
    landingHeroTitle2: 'Start your jouney here.',
    landingHeroText: 'The Gateway is a tool to guide you through your first year in your new role. It will provide you information and resources that you need to be successful.',
    landingHeroTitle1NewToRole: 'Welcome to The Gateway.',
    landingHeroTitle1NewToBrand: 'Welcome to The Gateway.',
    landingButtonText: 'Get Started',
    twoMonthslandingHeroTitle1: 'Welcome Back!',
    twoMonthslandingHeroText: '',
    twoMonthslandingButtonText: 'Enter Gateway',
    twoMonthslandingHeroTitle1NewToBrand: 'Welcome Back!',
    twoMonthslandingHeroTextNewToBrand: 'Hope your first days with %{brandName} have been great.  Don\'t forget to continue your journey and get access to all you need in the Gateway',
    twoMonthslandingButtonTextNewToBrand: 'Keep Going',
    threeMonthslandingHeroTitle1: 'You are on your way!',
    threeMonthslandingHeroText: 'All you need to know about Marriott International is in the Gateway.',
    threeMonthslandingButtonText: 'Enter Gateway',
    threeMonthslandingHeroTitle1NewToRole: 'You are on your way!',
    threeMonthslandingHeroTextNewToRole: 'All you need to know to be successful in your new role is in the Gateway.',
    threeMonthslandingButtonTextNewToRole: 'Enter Gateway',
    threeMonthslandingHeroTitle1NewToBrand: 'Welcome Back!',
    threeMonthslandingHeroTextNewToBrand: 'Congrats on completing the first %{numDays} days with your new brand You can access all of your Action Items, Brand, and Discipline Resources whenever you need.',
    threeMonthslandingButtonTextNewToBrand: 'Enter Gateway',
    fourMonthslandingHeroTitle1: 'Great job!',
    fourMonthslandingHeroText: 'Get access to all you need to know about Marriott International in the Gateway.',
    fourMonthslandingButtonText: 'Enter Gateway',
    fourMonthslandingHeroTitle1NewToRole: 'Great job!',
    fourMonthslandingHeroTextNewToRole: 'Get access to all you need to be successful in your new role in the Gateway.',
    fourMonthslandingButtonTextNewToRole: 'Enter Gateway',
    fourMonthslandingHeroTitle1NewToBrand: 'The Gateway - Checking In',
    fourMonthslandingHeroTextNewToBrand: 'As you approach the completion of your first year with %{brandName}, if you haven\'t already done so, complete all your Action Items and utilize the development resources available here in the Digital Learning Zone.  Click \'Continue\' to move forward in your journey.',
    fourMonthslandingButtonTextNewToBrand: 'Continue',
    gmSecondVisitlandingHeroTitle1: 'Welcome Back!',
    gmSecondVisitlandingHeroText: 'Hope your first days have been great.  Don\'t forget to continue your journey to help you grow in your new role.',
    gmSecondVisitlandingButtonText: 'Keep Going',
    gmSecondVisitlandingHeroTextNewToRole: 'Hope your first days in your new role have been great.  Don\'t forget to continue your journey and get access to all you need in the Gateway.',
    gmSecondVisitlandingHeroTextNewToBrand: 'Hope your first days with %{brandName} have been great.  Don\'t forget to continue your journey and get access to all you need in the Gateway',
    gmThirdVisitlandingHeroTitle1: 'Welcome Back!',
    gmThirdVisitlandingHeroText: 'Congrats on completing the first %{numDays} days of your new hire journey. You can access all of your Action Items, Brand, and Discipline Resources whenever you need.',
    gmThirdVisitlandingButtonText: 'Enter Gateway',
    gmThirdVisitlandingHeroTextNewToRole: 'Congrats on completing the first %{numDays} days in your new role. You can access all of your Action Items, Brand, and Discipline Resources whenever you need.',
    gmThirdVisitlandingHeroTextNewToBrand: 'Congrats on completing the first %{numDays} days with your new brand. You can access all of your Action Items, Brand, and Discipline Resources whenever you need.',
    gmSevenMonthslandingHeroTitle1: 'Looks like you have more Action Items to complete.',
    gmSevenMonthslandingHeroText: 'As you approach the completion of your first year, don\'t forget to complete all your Action Items and utilize the development resources available here in the Digital Learning Zone. Click \'Continue\' to move forward in your journey',
    gmSevenMonthslandingButtonText: 'Continue',
    gmSevenMonthslandingHeroTitle1NewToRole: 'The Gateway - Checking In',
    gmSevenMonthslandingHeroTextNewToRole: 'As you approach the completion of your first year in your new role, if you haven\'t already done so, complete all your Action Items and utilize the development resources available here in the Digital Learning Zone. Click \'Continue\' to move forward in your journey',
    gmSevenMonthslandingHeroTitle1NewToBrand: 'The Gateway - Checking In',
    gmSevenMonthslandingHeroTextNewToBrand: 'As you approach the completion of your first year with %{brandName}, if you haven\'t already done so, complete all your Action Items and utilize the development resources available here in the Digital Learning Zone.  Click \'Continue\' to move forward in your journey.',
    gmOneYearlandingHeroTitle1: 'Congratulations on 1 year with %{brandName}!',
    gmOneYearlandingHeroTitle1NewToRole: 'Congratulations on 1 year in your new role!',
    gmOneYearlandingHeroTitle1NewToBrand: 'Congratulations on 1 year in your new role with %{brandName}!',
    gmOneYearlandingHeroText: 'The Gateway journey may be over but the best is yet to come. You can access the resources from the Gateway tab at any time.',
    gmOneYearlandingButtonText: 'View Gateway',
  },
  resourcesHelp: {
    serviceRequestForm: 'Support Request Form',
    selfHelpGuides: 'Self Help Guides',
    title: 'Resources',
    subtitle: 'Here are the resources to help you get started.',
    learningGuidesTitle: 'Learning Guides',
    learningSitesTitle: 'Learning Sites',
    troubleshootingTitle: 'Troubleshooting',
    reportingTitle: 'Reporting',
    faqsTitle: 'Faqs',
    additionalResourcesTitle: 'Additional Resources',
    systemsTrainingListTitle: 'Systems Training List',
    ipt: 'Interactive Planning Tool',
    iptSubhead: 'The Interactive Planning Tool helps leaders understand what training is required or recommended for their property or above property/HQ location. Enter one or more locations in step 1.  Leave the default values in steps 2 – 4 to see all required training for the location(s), or customize your choices using the filters as needed.  Then click to Submit, and you can view the assignments or download them to a spreadsheet.',
    cancel: 'Cancel',
    submit: 'Submit',
    iptErrors: {
      awr: 'Please select an additional work responsibility'
    },
    iptTooltip: 'Use commas to separate multiple locations',
    iptAllFunctionTooltip: 'Uncheck to select specific Job Function(s). Keep checked to view training for all functions at the location selected',
    iptAllAWRTooltip: 'Uncheck to select specific Additional Work Responsibilities. Keep checked to view training for all Additional Work Responsibilities for the location selected',
    editJFTooltip: 'Use commas to separate multiple entries'

  },
  jfTour: {
    title: 'You are now able to see and edit your selected job functions and additional work responsibilities (AWR) directly from your My Plan.'
  },
  welcome: {
    hi: 'Hi',
    intro1: 'Welcome to the Digital Learning Zone! The DLZ is a personalized learning platform that’s a central access point to Marriott learning. Find required training for your role and explore learning opportunities for your personal and professional development. Own your learning journey and start the path of growing your skills.',
    intro2: '',
    letsGo: 'LET\'S GO',
    preferredLanguage: 'What is your preferred language?',
    step: 'STEP',
    of: 'OF',
    previous: 'PREVIOUS',
    next: 'NEXT',
    startDate: 'When did you start working at Marriott International or Starwood?',
    selectYear: 'SELECT YEAR',
    selectMonth: 'SELECT MONTH ',
    selectDay: 'SELECT DATE',
    selectFunction: 'SELECT FUNCTION',
    selectInterests: 'SELECT INTERESTS',
    chooseYear: 'CHOOSE A YEAR',
    chooseMonth: 'CHOOSE A MONTH',
    chooseDay: 'CHOOSE A DAY',
    whatDo: 'What is your job function?',
    selected: 'SELECTED',
    searchFunctions: 'Search All Functions',
    searchInterests: 'Search All Interests',
    additionalJobFunctionsLabel: 'Tell us more about what you do:',
    additionalJobFunctionsLabelExtra: 'Please review the following and SELECT AT LEAST ONE additional job responsibility.',
    highGuestContactLabel: 'Are you high guest contact?',
    brandsLabel: 'What brand(s) do you work for?',
    preferredBrandLabel: 'If you work for more than one brand, please select all that apply to you.',
    managementLevelLabel: 'What is your associate level?',
    searchAdditionalFunctions: 'Search All Additional Work Responsibilities',
    searchBrands: 'Search Brands',
    loadingMsg: 'Personalizing your experience. This may take a few moments.',
    yes: 'Yes',
    no: 'No'
  },
  search: {
    users: 'Search Learners by Name, Email, Person Number, or EID',
    courses: 'Search courses by title or ID',
    locations: 'Search locations by Location Code, Name, or MARSHA'
  },
  become: {
    title: 'Become.',
    description: 'This is your moment'
  },
  reporting: {
    editReportCriteria: 'EDIT YOUR REPORT CRITERIA',
    loadingMsg: 'Generating your report.  This may take a few moments.',
    loadingMsgCachedReport: 'Generating your report. A notification will alert you to when it is ready for download.',
    property: 'Property',
    functions: 'Functions',
    title: 'Reporting Dashboard',
    locations: 'Locations',
    associateList: 'Associate List',
    backToAll: 'Back to Report Overview',
    backToFilters: 'Back to Filters',
    backTo: 'Back To',
    filterReport: 'Filter Report',
    allFunctions: 'All Functions',
    subtitle: 'Track your team\'s progress towards their learning goals.',
    subtitleNew: `Customize your report using the criteria below to track your team's progress toward their learning goals.`,
    subtitleNew2: 'The reporting experience has been updated. To select a particular Learning, use the Program prompt.<br/>Go <a target="_blank" href="https://dlz.marriott-ld.com/help/reporting">here</a> for resources on reporting including a job aid, video, and link to reporting in mHUB.',
    selectCriteria: 'Select Your Report Criteria Below',
    helpTitle: 'Course completions will be reflected in the DLZ up to 48 hours after completion.',
    helpProgress: 'Display the percentage of courses that have been completed out of the courses assigned.',
    helpCompletion: 'Display the percentage of associates that have completed all courses.',
    helpJF: 'Display the percentage of associates that have selected a job function.',
    helpProgram: 'Filter assignments by their Program categorization. Click ‘view all’ to view Sub-Programs.',
    reportType: 'Report Type',
    assignmentType: 'Assignment Type',
    locationAssociateResults: 'Location/Associate-Level Results',
    programTooltip: 'Filter associate assignments by their Program, Sub-Program, or individual assignment.',
    advancedCriteria: 'ADVANCED REPORTING CRITERIA',
    runReport: 'Run Report',
    associates: 'Associates',
    summary: 'Summary',
    continents: 'Continents',
    mf: 'M/F',
    functions: 'Job Functions',
    brands: 'Brands',
    regions: 'Regions',
    managed: 'Managed',
    franchised: 'Franchised',
    summaryLoading: 'Generating summary, this may take a few moments...',
    assignmentPlaceholder: 'Search Program, Sub-Program or Assignment',
    jfPlaceholder: 'Search All Job Functions',
    program: 'Program',
    subProgram: 'Sub-Program',
    assignment: 'Assignment',
    noBrand: 'No Brand Selected',
    noContinent: 'No Continent Set',
    learningProgressTooltip: 'Displays the percentage of assignments completed out of the total number of assignments.',
    learningCompletionTooltip: 'Displays the percentage of associates that have completed all assignments.',
    learningComplianceTooltip: 'Displays the percentage of assignments that have come due and are completed out of the total number of assignments that have come due.',
    jobFunctionSelectedTooltip: 'Display the percentage of associates that have selected a job function.',
    awrsTooltip: 'Filter associates by their additional work responsibility selection.',
    dgsssTooltip: 'Filter associates by their department’s Group, Subgroup, Specialty, or Subspecialty (GSSS). Applicable to above-property reports.',
    viewTeamByManagerTooltip: 'Filter associates by their top-level manager. Direct and indirect reports of the manager will appear in the report. Applicable to managed locations',
    summaryTableTooltip: 'Indicates percentage of assignments that are complete. The numbers in parentheses are complete assignments over total assignments.',
    jfToolTip: 'Filter associates by their function selection.'
  },
  landing: {
    started: 'Let\'s get started',
    welcomeBack: 'Welcome back',
    greatWork: 'Great work',
    requiredCourses: 'Your Required Courses',
    requiredCoursesDesc: 'Please complete courses prior to the due date.',
    myPlan: 'My Plan',
    courses: 'Courses',
    courseComplete: 'COURSE COMPLETE',
    viewMore: 'VIEW MORE REQUIRED COURSES',
    viewMoreBtn: 'VIEW MORE COURSES',
    showing: 'SHOWING',
    of: 'OF',
    heroTitle: 'Learn something new. Empower yourself. Gain skills that apply to your world.',
    heroParagraph1: 'Easily track all of your required courses and get real-time notifications to complete them.',
    heroParagraph2: 'Enjoy customized learning experiences based on your job and interests.',
    recommendedCoursesTitle: 'Recommended Courses',
    recommendedCoursesDesc: 'See what courses have been selected for you.',
    recommendedCoursesDescNoContent: 'You do not have any recommended courses at this time. Please see below to explore other learning opportunities.',
    recommendedCoursesBtn: 'VIEW RECOMMENDED COURSES',
    completedCoursesBtn: 'VIEW COMPLETED COURSES',
    exploreTitle: 'Stay curious.',
    exploreDesc: 'Select the topics you\'re interested in. ',
    updatedTopics: 'Updated topics',
    exploreBtn: 'VIEW COURSES',
    managerRecommendation: 'MANAGER RECOMMENDATION',
    learningRecommendation: 'LEARNING RECOMMENDATION',
    marriottRecommendation: 'MARRIOTT RECOMMENDATION',
    completedCourses: 'You\'ve completed all your required courses!',
    jobFunctions: 'Job Functions',
    languageLearning: 'Language Learning',
    brands: 'Brands',
    leadershipSkills: 'Leadership Skills',
    growthSkills: 'Growth Skills',
    workingRemotely: 'Virtual Team Resources',
    investTitle: 'Own Your Learning Journey',
    investParagraph1: 'Welcome to the Digital Learning Zone! The DLZ is a personalized learning platform that’s a central access point to Marriott learning. Find required training for your role and explore learning opportunities for your personal and professional development. Own your learning journey and start the path of growing your skills.',
    newContent: 'New Content',
    relatedCourses: 'Please click below to select your interests.',
    relatedCoursesNew: 'Click below to update your interests, as we have added new topics and courses.',
    mhubTopicCategories: 'mHUB',
    mHubHeroTitle1: 'Simple. Smart. Secure. Introducing mHUB.',
    mHubHeroManagedText1: 'mHUB integrates tools for learning, career development, Explore discounts and more into one, easy-to-use platform.',
    mHubHeroFranchisedText1: 'mHUB integrates tools for learning, Explore discounts and more into one, easy-to-use platform.',
    mHubHeroVisitLink: 'Visit mHUB today to own your Marriott career journey.',
    notificationText: 'New mHUB training available. Choose mHUB in Explore Learning.<br/><b>REMINDER:</b>  Due to the migration to mHUB learning completions are still in progress of being added to the new system. If you need assistance, please contact the Service Center.',
    myGateway: 'My Gateway',
    gatewayReview: 'Please review and complete.',
    gatewayProgress: 'Gateway Progress',
    complete: 'COMPLETE',
    reviewContent: 'View Resources',
    gatewayCardTitle: 'Gateway Resources',
    gatewayCardDesc: 'Access your Basics, Action Items, Brand Resources and Resources by Discipline for the first year and beyond.',
    gatewayCardDescGms: 'Access your Action Items, Brand Resources and Resources by Discipline for the first year and beyond.',
    gatewayCardDescNew: 'Access your Basics, Action Items, Brand Resources and Resources by Discipline for the first year and beyond.',
    gatewayCardDescGmsNew: 'Access your Action Items, Brand Resources and Resources by Discipline for the first year in your new role and beyond.',    
    mhubVideoExtra: 'Need help searching for learning? <u>Watch this.</u>'
  },
  courseBundle: {
    completedOn: 'COMPLETED ON',
    remainingCourses: 'Remaining Courses',
    completedCourses: 'Completed Courses',
    remainingLabel: 'There are {courseCount} courses in this learning pathway.',
    recommendButton: 'Recommend To Team',
    startCourse: 'Start Course',
    startLearningPath: 'View Path',
    durationMins: 'MINUTES',
    completeBy: 'COMPLETE BY',
    overdue: 'OVERDUE',
    completeAsap: 'COMPLETE AS SOON AS POSSIBLE',
    completed: 'Completed',
    back: 'Back',
    additionalLangs1: 'In addition to being offered in English, this course is now available in',
    additionalLangs2: 'Please select your preferred language from the drop down menu after launching the course.',
    targetAudience: 'Audience:',
    all: 'All',
    globally: 'globally',
    source: 'Source',
    courseType: 'Course Type',
    recertification: 'Retraining',
    lastCompletion: 'Last Completion Date',
    course: 'Course',
    durationMinutes: 'minutes',
    durationHours: 'hours',
    markComplete: 'Mark Complete',
    markedComplete: 'Marked as Complete',
    attestationDescription: 'By marking as complete, I affirm that I have reviewed all content and completed all relevant tasks and activities. I will continue to complete any ongoing tasks as indicated.',
    startLearning: 'Start Learning',
    reviewLearning: 'Review Learning',
    tuition: 'Tuition',
  },
  profile: {
    save: 'Save',
    edit: 'Edit',
    brand: 'Brand',
    brands: 'Brands',
    additionalWork: 'All Additional Work Responsibilities',
    startDateLabel: 'When did you start working at Marriott International or Starwood?',
    preferredLanguageLabel: 'What is your preferred language?',
    jobFunctionsLabel: 'What is your job function?',
    interestsLabel: 'What do you want to learn?',
    brandsLabel: 'What brand(s) do you work for?',
    preferredBrandLabel: 'What brand do you want the DLZ experience to be?',
    additionalJobFunctionsLabel: 'What additional job functions do you perform?',
    highGuestContactLabel: 'Are you high guest contact?',
    managementLevelLabel: 'What is your associate level?',
    emailPreferencesLabel: 'Do you want to receive email notifications?',
    emailFrequencyLabel: 'How often do you prefer to receive email notifications?',
    emailFrequencyNone: 'No Emails',
    emailFrequencyDaily: 'Daily',
    emailFrequencyWeekly: 'Weekly',
    emailPreferenceOptInLabel: 'Yes, please send notifications',
    emailPreferenceOptOutLabel: 'No, thank you',
    emailPreferenceLabel: 'Would you like to receive weekly emails summarizing your overdue learning items?'
  },
  locationTable: {
    marsha: 'MARSHA',
    locationCode: 'LOCATION',
    propertyName: 'PROPERTY NAME',
    avp: 'AVP',
    coo: 'COO',
    region: 'REGION',
    brand: 'BRAND',
    progress: 'COURSE PROGRESS',
    completion: 'COURSE COMPLETION',
    managedFranchised: 'MANAGED/FRANCHISE',
    jfSelection: 'JOB FUNCTION SELECTION'
  },
  associatesTable: {
    id: 'EID',
    name: 'NAME',
    functionTypes: 'FUNCTION',
    locationCode: 'LOCATION CODE',
    marshaCode: 'MARSHA CODE',
    awrs: 'ADDITIONAL WORK RESPONSIBILITIES',
    managementLevel: 'MANAGEMENT LEVEL',
    hireDate: 'HIRE DATE',
    managerName: 'MANAGER NAME',
    jobTitle: 'JOB TITLE',
    progress: 'PROGRESS',
    recommendedProgress: 'Recommended Assignments',
    requiredProgress: 'Required Assignments',
    completion: 'COURSE COMPLETION',
    gatewayProgress: 'GATEWAY PROGRESS',
    directs: 'DIRECTS',
    jfSelection: 'JOB FUNCTION SELECTION'
  },
  userHistory: {
    title: 'My History',
    filterText: 'Filter History'
  },
  userHistoryTable: {
    assignmentName: 'Assignment Name',
    required: 'Required',
    completionDate: 'Completion Date',
    program: 'Program',
    source: 'Source',
    type: 'Type',
    sourceId: 'Source ID',
    id: 'ID',
    retraining: 'Retraining'
  },
  courseTableContainer: {
    title: 'SYSTEMS TRAINING LIST'
  },
  table: {
    previous: 'Previous',
    next: 'Next',
    showing: 'VIEWING',
    of: 'OF',
    results: 'RESULTS',
    filterTable: 'FILTER TABLE',
    tableSettings: 'TABLE SETTINGS',
    downloadable: 'DOWNLOADABLE',
    notApplicable: 'Not Applicable',
    gatewayToolTip: 'Percentage does not include optional items',
    noResult: 'No Results'
  },
  courseDetails: {
    completeBy: 'Complete by',
    recommendTitlte: 'Recommend Course to Your Team',
    courseName: 'Course Name',
    sendRecommendation: 'Send Recommendation',
  },
  recommend: {
    title: 'Recommend Training to Learners',
    selectLearners: 'Select Learners',
    selectTraining: 'Select Training'
  },
  markCompletion: {
    title: 'Mark Completion for Learners'
  },
  awrBulkEdit: {
    title: 'Edit Job Function or Additional Work Responsibilities (AWR)',
    selectLocationOrLearner: 'Select location or learner',
    searchLocationOrLearner: 'Select learners by location number or MARSHA, name, email, or person number',
    tableTitle: 'Refine selection(s) by filtering or selecting from the table below',
    actionType: 'Choose Add or Remove Job Function or Additional Work Responsibility',
    back: 'Back',
    associatesTableTitle: 'Associate List',
    searchTooltipText: 'Multiple entries can be selected from the search'
  },
  explore: {
    searchByName: 'Search your interests',
    relatedCourses: 'Please click below to select your interests.',
    relatedCoursesNew: 'Click below to update your interests, as we have added new topics and courses.',
    chooseInterests: 'Choose your interests',
    title: 'Explore Learning',
    chooseInterestsModal: 'Choose Interests',
    oppsTitle: 'Explore Learning Opportunities',
    growWithUsTitle: 'Grow With Us',
    growWithUsDesc: 'Marriott believes in a career that flourishes with you. We also believe that wider experiences often provide a more enriching experience.',
    growWithUsBtn: 'VIEW GROW WITH US',
    mdaTitle: 'Marriott Development Academy',
    mdaDesc: 'Marriott recognizes success and commitment. We honor and encourage leadership and exceptional service. We also give bonuses for successful referrals.',
    mdaBtn: 'VIEW MARRIOTT DEVELOPMENT ACADEMY',
    selectInterests: 'Tell us what topics interest you.',
    otherRecommended: 'Other Recommended Courses',
    dismissTooltip: 'Dismissed recommendations will be removed from your plan.'
  },
  plan: {
    tourPlan: 'My Plan includes all your required and recommended courses for completion by due date',
    tourBasics: 'Marriott Basics includes resources to get you started in your Marriott journey and can be accessed at any time throughout your career. Marriott Basics are supplemental to your learning in My Plan.',
    tourHeader: 'You are now able to see and edit your selected job functions and additional work responsibilities (AWR) directly from your My Plan',
    jf: 'Your Job Function(s):',
    awr: 'Your Additional Work Responsibilities (AWR):',
    jfAwrDesc: 'Important: job function and AWR determine your required trainings assigned on your My Plan. ',
    jfAwrEdit: 'EDIT your profile',
    jfAwrEnd: 'to update.',
    searchByName: 'Search plan by name',
    showingSearch: 'Showing results for "%{term}". (%{count} of %{total})',
    myPlan: 'My Plan',
    completedRequired: 'You\'ve completed all your required courses!',
    requiredCourses: 'Required Courses',
    noCourses: `You're all caught up on required learning due at this time, feel free to look ahead and get started on upcoming required learning.`,
    reqDesc: 'Please complete courses prior to the due date.',
    complete: 'COMPLETE',
    of: 'OF',
    courses: 'RESOURCES',
    completedCourses: 'Completed Courses',
    completedDesc: 'To see your full learning history, click <u style="cursor:pointer;">here</u>.',
    remaining: 'remaining',
    myLearningTitle: 'My Added Learning',
    myLearningSubTitle: 'Items I have added to My Plan.',
    myLearningHistoryLinkText: 'See my full',
    history: 'History'
  },
  team: {
    title: 'Manage Your Team',
    subtitle: 'Grow their skills and careers.',
    functions: 'Functions',
    viewTeam: 'View Team',
    allCourses: 'Assignments',
    downloadExcel: 'Download Excel Report',
    basics: 'MARRIOTT BASICS',
    disciplineResources: 'Resources by Discipline',
    actionItems: 'Action Items',
    brandResources: 'Brand Resources',
    communicationsResource: 'Communications Resource',
    notStarted: 'NOT STARTED',
    pastDue: 'PAST DUE',
    completed: 'COMPLETED',
    myPlabTab: 'MY PLAN PROGRESS',
    gatewayTab: 'GATEWAY PROGRESS',
    allGatewayResources: 'All Gateway Resources',
    optional: 'Optional',
    toDo: 'To Do'
  },
  courseTable: {
    courseName: 'COURSE NAME',
    required: 'REQUIRED',
    program: 'PROGRAM',
    dueDate: 'DUE DATE',
    courseProgress: 'COURSE PROGRESS',
    jobFunction: 'Job Function',
    subprogram: 'Subprogram',
    courseDescription: 'Course Description',
    lessons: 'Lessons',
    url: 'URL',
    resourceTitle: 'RESOURCE TITLE',
    toDoOptional: 'TO DO / OPTIONAL',
    section: 'SECTION',
    resourceProgress: 'RESOURCE PROGRESS'
  },
  filters: {
    requiredCourses: 'Required',
    recommendedCourses: 'Recommended',
    gatewayResources: 'Gateway',
    metric: 'Metric',
    metricTooltip: 'Select the type of report that will be displayed.',
    courseType: 'Type',
    program: 'Program',
    function: 'Function',
    functionTooltip: 'Filter associates by their Function selection. Click ‘view all’ to view individual Functions.',
    allCourseCompletion: 'All Course Completion',
    allAssociatesProgress: 'All Associates Progress',
    newHireCompletion: 'New Hire Completion',
    newHireProgress: 'New Hire Progress',
    newHireCompliance: 'New Hire Compliance',
    jobFunctionSelected: 'Job Function Selected',
    viewAll: 'View All',
    reset: 'RESET',
    applyFilters: 'APPLY FILTER(S)',
    viewAllFilters: 'View All Filters',
    generalManager: 'General Manager',
    finance: 'Finance',
    targetedFrontOffice: 'Targeted Front Office',
    sales: 'Sales',
    eventManagement: 'Event Management',
    revenueManagement: 'Revenue Management',
    housekeeping: 'Housekeeping',
    reservations: 'Reservations',
    other: 'Other',
    comingSoon: '(Coming Soon)',
    myPlanProgress: 'My Plan Progress',
    learnerStatus: 'Learner Status',
    excludeLoa: 'Exclude LOA/Pool/Seasonal',
    learningProgress: 'Learning Progress',
    learningCompletion: 'Learning Completion',
    learningCompliance: 'Learning Progress - Past Due',
    propertyIncompleteAssignments: 'Property Incomplete Assignments',
    jobFunctionSelected: 'Job Function Selected',
    associateLevel: 'Associate-level',
    locationLevel: 'Location-level',
    jobFunctions: 'Job Functions',
    awr: 'Additional Work Responsibilities',
    awrTooltip: 'AWR Tooltip',
    hireDate: 'Hire Date',
    continentRegion: 'Region',
    brandBrandSegment: 'Brand/Brand Segment',
    managedFranchised: 'Managed or Franchise',
    departmentGroupSubgroupEtc: 'Department Group, Subgroup, Specialty or Sub-Specialty',
    summarizeResults: 'Summarize Results By',
    viewTeamByManager: 'View Team by Manager',
    viewTeamByOwnerFranchise: 'View Team by Franchise Company',
    hiredLast6Months: 'Hired in the Last 6 Months',
    hiredLast3Months: 'Hired in the Last 3 Months',
    allAssociates: 'All Associates',
    hiredThisYear: 'Hired This Year',
    hiredLastYear: 'Hired Last Year',
    hiredBeforeThisYear: 'Hired Before This Year',
    region: 'Regions',
    continent: 'Continents',
    brands: 'Brands',
    brandSegments: 'Brand Segments',
    managed: 'Managed',
    franchised: 'Franchise',
    summaryLevelOne: 'Summary Level 1',
    summaryLevelTwo: 'Summary Level 2',
    none: 'None',
    managedFranchise: 'Managed/Franchise',
    brand: 'Brand',
    group: 'Groups',
    subgroup: 'Subgroups',
    specialty: 'Specialties',
    subspecialty: 'Subspecialty',
    franchiseCompany:'Franchise Company',
    owner:'Owner'
  },
  recommendation: {
    dismiss: "Are you sure you want to dismiss this recommendation?"
  },
  feedbackButton: {
    havingTrouble: 'SUGGESTIONS',
    feedbackUrl: 'https://jam8.sapjam.com/groups/uEIyFc8b7wueVNciK50LOA/articles/new?predefined=false&templateId=SHLhZquaQnkdQks1uxkuPi'
  },
  download: 'Download',
  courseCard: {
    startLearning: 'Start Learning',
    viewPath: 'View Path',
    hidePath: 'Hide Path'
  },
  notifications: {
    title: 'Notifications',
    helptip: 'All notifications expire after 30 days.',
    addAssignment: 'A %{assignment_type} assignment for %{assignment_name} has been added to your %{page_location}.',
    profile: 'An update to your job title or location has been detected.  Please review your Profile to make any applicable Brand, Function, or Additional Work Responsibility updates.  Accurate DLZ profile information helps ensure you receive the correct learning assignments.',
    markAllRead: 'Mark All As Read',
    noNewNotifications: 'You have no notifications.',
    overdueAssignment: 'Your %{assignment_type} assignment for %{assignment_name} is now overdue.',
    comingDueAssignment: 'Your %{assignment_type} assignment for %{assignment_name} is coming due soon on %{due}.',
    markRead: 'Mark as read',
    delete: 'Delete',
    cancel: 'Cancel',
    reportComplete: 'Your DLZ report has finished. Click to download.',
    viewAll: 'View All',
    deleteAll: 'Delete All',
    notificationCenterTitle: 'Notification Center',
    markAsRead: 'Mark As Read',
    noDeletedNotifications: 'You are up to date!',
    restore: 'Restore',
    restoreAll: 'Restore All',
    viewCourse: 'View Course',
    manageEmailNotifications: 'Manage Email Notifications',
    changeEmailManagedTooltip: 'To change your email, visit mHUB > HR Direct > Me > My Public Info.  Follow the directions to update your contact details.It may take 1-2 business days for the updated email to display.',
    changeEmailFranchisedTooltip: 'To change your email, contact your Manager and/or your Franchise EID Tool Administrator.  It may take 1-2 business days for the updated email to display.',
    deleteAllNotifications: 'Delete All Notifications',
    deleteIndividualHeader: 'Delete Notification',
    deleteAllConfirmation: 'Are you sure you want to delete all notifications? They will be moved to the Deleted section on the Notification Center until they expire.',
    deleteIndividualConfirmation: 'Are you sure you want to delete this notification? Deleted notifications are moved to the Deleted section on the Notification Center until they expire.',
    permanentDeleteIndividiualConfirmation: 'Are you sure you want to permanently delete this notification? This action cannot be undone.',
    permanentDeleteAllConfirmation: 'Are you sure you want to permanently delete all notifications? This action cannot be undone.',
    dontShowThisAgain: 'Don\'t show this again',
    notificationDeleted: 'Notification has been deleted.',
    notificationsDeleted: 'Notifications have been deleted.',
    notificationRestored: 'Notification has been restored.',
    notificationsRestored: 'Notifications have been restored.',
    enableDeletionWarning: 'Deletion warning enabled',
    warningEnabled: 'Deletion warning has been enabled.',
    warningDisabled: 'Deletion warning has been disabled.',
    warningTooltip: 'When selected, you will see a warning each time you delete a notification.',
    manageNotificationsCopy: 'Choose whether to receive weekly emails summarizing your overdue learning items. Select “Manage” to modify your preferences.',
    manage: 'Manage'
  },
  poc: {
    dlz: 'For this learning to be complete, Managers or Learning Coordinators must mark completion for this learning in mHUB. Details on how to do this can be found in the Implementation Guide.',
    mhub: 'Franchisees:  if you opt to use you own foundation cleaning program, please include the program name and details in the comments section when you mark completion'
  },
  topline: {
    beginAssessments: 'Begin Assessments',
    continueAssessments: 'Continue Learning',
    question: 'Question',
    correct: 'Correct',
    incorrect: 'Incorrect',
    next: 'Next',
    finish: 'Finish',
    yourAnswer: 'Your Answer',
    correctAnswer: 'Correct Answer',
    modal: {
      title: 'Congratulations on finishing your personalized learning pathway(s)!',
      subtitle: `If you previously ranked "Enabled" on any of the eight functions, you can still learn more about the function's core foundational activities and additional resources by selecting the function title from your dashboard.
      <br/><br/>
      Also, make sure to scroll down to the Recommended Learning section (below the dashboard) to continue enhancing your knowledge in the areas that directly impact Topline performance at a property or properties.`,
      button: 'Continue'
    },
    lpDetailText: `The following activities have been identified for you based on your assessment responses. Once you complete each learning experience, please check the box to indicate completion. You will then be given access to the post-assessment for this function (in your topline dashboard), where you will leverage your gained knowledge to complete this course.
<br/><br/>
If you do not have learning activities listed directly below, please scroll down to review the resources and optional learning available to support topline performance at the property.`,
    assessment: {
      viewPreassessmentResults: 'Pre-Assessment Results',
      title: 'Phase One: Assess Where You Are',
      subtitle: 'Welcome to your Topline Performance Pre-Assessment',
      description: 'Let’s assess your Topline Performance knowledge to find out what you already know and how to further increase your smarts!<br/><br/>Using the tabs below, which represent the key functions that support Topline Performance, navigate through each function and complete a short assessment to reveal your knowledge level. Understanding your strengths in these areas will help unlock your potential and lead you to greater performance.',
      subtitleDesc: 'This assessment covers the core foundational activities that have been identified as high value for ',
      subtitleDescTry2: 'Click the Start Assessment button below to complete your final post-assessment for this function. If you want to review the results from your previous attempt, click the View Results button.',
      subtitleDesc2: 'You will get 90 seconds to complete each question.',
      startButton: 'Start Assessment',
      reviewButton: 'Review Results',
      viewScoreButton: 'View Score',
      score: 'Score',
      enabledLabelTitle: 'Congratulations, you are Topline Enabled.',
      awareLabelTitle: 'Congratulations, you are Topline Aware.',
      inTheKnowLabelTitle: 'You are Topline Get in the Know.',
      enabledLabel: 'You are a Marriott Topline Guru! Whether it be from your experience or training, you are aware of what is needed to activate and drive topline performance at the property, for each function. That is no small accomplishment. You also have a clear understanding of the Marriott’s strategies, processes, and tools aimed at maximizing property revenue and profit.',
      awareLabel: 'You have Topline experience and education to brag about! And there are also functions that may not be your core area of expertise. Using your assessment responses, in Phase Three (3) of this learning, you will receive a customized learning path to increase your smarts in those areas to become Topline Enabled',
      inTheKnowLabel: 'Congratulations, you are in the right place to increase your smarts on everything Topline at Marriott! Marriott offers effective strategies, processes, and tools that are aimed at maximizing property revenue and profit for all functions.',
      proceedToPhaseTwo: 'Continue'
    },
    dashboard: {
      title: 'Dashboard'
    },
    phaseTwo: {
      title1: 'Your Topline Performance Results',
      subtitle1: 'Congratulations, your Topline Performance Results are in!',
      description1: `
        Nice work on completing your Topline Performance pre-assessment. Use <a target="_blank" rel="noopener referrer" href="https://www.marriott-ld.com/dev/cmdb/api/app/topline/pdf?application=dlp&project=dlp">this report</a> to make the most of your strongest areas and dive in deeper to others.
        <br/>
        <br/>
        Once you have downloaded and reviewed your results, click “Topline Fundamentals + Activation” to continue with your learning journey. A learning path unique to you is coming up, but first, let’s bring the fundamentals of topline all together.
      `,
      title2: 'Phase Two: Topline Fundamentals + Activation',
      description2: `
      The video below will go into detail about the core foundational activities and high-value tasks that are recommended for each function in order to drive and optimize property performance.
      `,
      enabledCompleteDescription: `Before proceeding, please select and download both of the resources listed below, which will be useful when focusing your cross-functional topline efforts at the property. The Topline Property Activation Guide is designed for property teams to use in order to activate Marriott's key topline drivers.`,
      buttonDescription1: 'Topline Fundamentals + Activation',
      buttonDescription2: 'Continue Learning',
    },
    phaseThree: {
      returnPhaseTwo: 'Return to Phase Two',
      title: 'Phase Three: Become Topline Enabled – Learn Only What <i>You</i> Need',
      subtitle: 'Are you ready to dive into your customized learning pathway to fill the gaps in your overall awareness? For the function or functions where you ranked Topline: Get In The Know and Topline: Aware, you’ve been assigned specific learnings and tasks.',
      dashboardTitle: 'Topline Dashboard',
      dashboardDescription: 'After completing a learning path, test your knowledge by accessing and completing the post-assessment. This can be done individually or all at once. Your experience is entirely up to you.',
      function: 'FUNCTION',
      knowledgeLevel: 'KNOWLEDGE LEVEL',
      learningPath: 'CUSTOMIZED LEARNING PATH',
      actions: 'POST ASSESSMENT',
      retakeButton: 'RETAKE ASSESSMENT',
      incomplete: 'Incomplete',
      start: 'START',
      inProgress: 'IN PROGRESS',
      na: 'N/A',
      compare: 'Compare and contrast your answers with the correct ones for this function.',
      viewResults: 'VIEW RESULTS',
      completed: 'COMPLETED',
      viewPreassessmentResultsDesc: 'If you would like to review how you did on your pre-assessment, click the “View Results” button below.',
      preassessmentResults: 'Pre-Assessment Results',
      postAssessment: 'POST ASSESSMENT',
      allEnabledDesc: `
        <b>CONGRATULATIONS!</b> As a <b>Topline Enabled Leader</b>, you will be able to clearly summarize how each function supports and contributes to topline revenue, and you will be able to validate and even activate the foundational tasks and activities recommended at the property to improve topline property performance.
      `,
      tableDesc: 'Here is how to understand where you are in your Topline Knowledge:',
      tableDesc2: `
        Topline: Get In The Know -  You’ve got some experience and education on the topic, but some particular function(s) may not be your core area of expertise.  
        <br/>
        Topline: Aware - You generally know the content well, but there are functions and areas where you could dive deeper for further knowledge.
        <br/>
        Topline: Enabled - You are a Marriott Topline Guru! Whether from your experience or training, you are comfortable with how the various functions help drive topline performance.
      `,
      recommendedTitle: 'Recommended Learning: Master Topline Performance at Your Property',
      recommendedDesc: 'In addition to your unique learning, it is recommended you review the following learning and resources to continue to become Topline: Enabled.',
      resources: 'Resources',
      postAssessmentTitle: 'Topline Performance Post-Assessment',
      postAssessmentSubtitle: `Let’s wrap it up! Great work on your journey in striving to become a TOPLINE ENABLED LEADER. Now it’s time to complete your post-assessment(s) and see how you have grown. You will have two attempts for each function. Your goal is to score 80% and higher.`,
      additionalLearning: 'Additional Learning',
      passedTitle: 'Congratulations, you are Topline Enabled.',
      passedDescription: 'As a Topline Guru for this function, you are very comfortable deploying the core foundational activities and tasks recommended to optimize and drive topline performance.',
      didNotPass: 'Sorry, you did not pass.',
      didNotPassDesc: 'Please click the "Retake Assessment” button below to try again. Or, use the “View Dashboard” to navigate back to the Topline Dashboard to continue with your learning. You can retake this post-assessment from the Topline Dashboard at a later time.',
      didNotPassDesc2: 'Click the view results button below to review the correct and incorrect answers. You can navigate back to the dashboard at any time using the View Dashboard button.',
      viewDashboard: 'View Dashboard'
    }
  },
  "Notification Center: ": "Mark as Unread",
  "Notification Center: Empty": "You are up to date!",
  "Notification Center: Individual Delete": "Are you sure you want to delete this notification? Deleted notifications are moved to the Deleted section on the Notification Center until they expire.",
  "Notification Center: Delete All": "Are you sure you want to delete all notifications? They will be moved to the Deleted section on the Notification Center until they expire.",
  "Notification Center: Mark All As Read": "Mark All As Read",
  "Notification Center: Restore All": "Restore All",
  "Notification Center: Delete Confirmation": "Delete",
  "Warning Message Text: Remove Job Function or AWR": "Remove Job Function or Additional Work Responsibility (AWR)?\r\nBy Submitting, you are about to remove %{Function and/or AWR} from %{count} Learners.",
  "Warning Message Text: Add Job Function or AWR": "Add Job Function or Additional Work Responsibility (AWR)?\r\nBy Submitting, you are about to add %{Function and/or AWR} to %{count} Learners.",
  "Button": "Submit",
  "Success Message Text: Add Job Function and/or AWR": "Success\r\nYou have added the %{Job Function and/or AWR} to the requested associate(s) at %{location}.  \r\n\r\nWould you like to edit additional Job Functions or Additional Work Responsibilities for  %{location}?",
  "Message Text:AWR": "Success\r\nYou have added the Additional Work Responsibilities %{ __} to the requested associate(s) at %{ __}.\r\n \r\nWould you like to edit additional Job Functions or Additional Work Responsibilities for %{location, property, learner(s)}?",
  "Success Message Text: Remove Job Function": "Success\r\nYou have removed the %{Job Function and/or AWR} from the requested associate(s) at %{location}.  \r\n\r\nWould you like to edit additional Job Functions or Additional Work Responsibilities for  %{location}?",
  "Success Message Text: Remove AWR": "Success\r\nYou have removed the Additional Work Responsibility %{ __}  from the requested associate(s) at %{ __} .\r\n \r\nWould you like to edit additional Job Functions or Additional Work responsibilities for %{location, property, learner(s)}?",
  "Tool Tip - Managed Change Email": "To change your email, visit mHUB > HR Direct > Me > My Public Info.  Follow the directions to update your contact details.  It may take 1-2 business days for the updated email to display.",
  "Tool Tip - Franchised  Change Email": "To change your email, contact your Manager and/or your Franchise EID Tool Administrator.  It may take 1-2 business days for the updated email to display.",
  "User Profile: Email Opt-in": "No, thank you",
  "User Profile: Email Frequency": "Weekly Summary",
  "Generic Notification Email Subject Line:": "You have new notifications in the DLZ",
  "Generic Body Text (<150 Characters)": "Please visit the DLZ to view the details.",
  "All Emails: Signature": "Digital Learning Zone - Learning made simple. It starts with you.",
  "All Emails: Footer": "You may unsubscribe/opt-out of email notifications at any time.  Visit the Digital Learning Zone/Profile to update your preferences.",
  "Overdue Emails: Subject Line: ": "DLZ Assignments Need Your Attention",
  "Overdue Emails: Body Test": "You have Overdue Assignments.  Please log into the DLZ to review your assignments.",
  
  "transcript": {
    "history": "Mon historique",
    "completed": "Tâches terminées",
    "assignmentName": "Nom de la tâche",
    "type": "Type",
    "viewAssignment": "Afficher la tâche",
    "assignmentSource": "Source de la tâche",
    "requiredAssignments": "Tâches requises",
    "recommendedAssignments": "Tâches recommandées",
    "voluntary": "Volontaires",
    "trasscript": "Transcription",
    "retraining": "Reconversion",
    "completionDate": "Date d’achèvement",
    "assignmentType": "Type de tâche",
    "contentType": "Type de contenu",
    "contentId": "Identifiant du contenu"
  },
  "contentLaunch": {
    "review": "Review Learning",
    "start": "Start Learning"
  },
  "survey": {
    "v2": {
      "lbiIntro": "Welcome to the Luxury Behavior Check-in!",
      "lbiDescription": `<p>Below you will be asked to read a few short scenarios you might encounter at your property. &nbsp;Please read each scenario and choose how you would respond to each situation. &nbsp;Keep the following in mind as you respond:</p>
      <ul>
          <li>
              <p>You may find that more than one of the responses to choose from seem correct. Choose the response that you would most likely take at work.</p>
          </li>
          <li>
              <p>Assume there are no communication barriers. &nbsp;The language you speak is the same as the language the guest or associate in the scenario speaks.</p>
          </li>
          <li>
              <p>The scenario may ask you to imagine yourself in a different work role than the role you are currently in. &nbsp;Answer the question to the best of your knowledge, based on your experience as a luxury hospitality professional.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Please note: Your participation in this check-in is entirely voluntary (you may choose not to participate), and your responses will be strictly confidential. Results for this check-in will be reported for groups of people and not for specific individuals. Individual responses will be evaluated by the research team and will not be shared with Managers of Others or leaders.</p>
      <p>By completing this check-in you are agreeing to the collection and processing of your personal responses and data (e.g., length of service, gender, job title), as well as the cross-border transfer of your personal data to the Marriott Group. This is in accordance with&nbsp;<a href="https://mgscloud.marriott.com/NA/-footer/-termsofuse/associate-personal-information-protection-statement.html">Marriott&rsquo;s Associate Personal Data Privacy Statement</a>, in which you can also find additional information and contacts for reference on Marriott&rsquo;s Data Privacy Policies.&nbsp;</p>`,
      "weValue": "We would love to hear from you.",
      "weValueDescription": "Please answer these quick questions about your experience working for your property.",
      "weValueButtonText": "Take the survey",
      "weValueG1Q1A1": 'Yes',
      "weValueG1Q1A2": 'No',
      "strongAgree": "Strongly Agree",
      "agree": "Agree",
      "slightAgree": "Slightly Agree",
      "slightDisagree": "Slightly Disagree",
      "disagree": "Disagree",
      "strongDisagree": "Strongly Disagree",
      "weValueG1Q1Title": "Did you attend a daily meeting the last shift you worked? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, or Daily EDITION)",
      "weValueG1Q2Title": "The daily meeting (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, or Daily EDITION) inspires me to create luxury service each day.",
      "weValueG1Q3Title": "My department is focused on reducing defects, even those not reported by the guests.",
      "weValueG1Q4Title": "I am empowered to resolve guest issues without having to ask for permission first.",
      "weValueG1Q5Title": "I am empowered to delight guests without having to ask for permission first.",
      "weValueG2Q1Title": "I regularly see team members at my property go above and beyond to impress the guests with the care they deliver.",
      "weValueG2Q2Title": "My learning in this department has enabled me to deliver luxury service.",
      "weValueG2Q3Title": "I feel confident with my knowledge and skills to deliver luxury service.",
      "weValueG2Q4Title": "In my department, we deliver a luxury environment, clean and defect-free.",
      "weValueG2Q5Title": "Even when I encounter an issue unrelated to my department, I am empowered to step in and own the guest concern.",
      "weValueG3Q1Title": "In my property, team members receive the same level of care as the guest.",
      "weValueG3Q2Title": "My department embodies the values of the brand.",
      "weValueG3Q3Title": "When I go above and beyond to create a luxury guest experience, my work is acknowledged and appreciated.",
      "weValueG3Q4Title": "I am involved in our department's efforts to improve our work.",
      "weValueG3Q5Title": "In my department, we always put the guest first.",
      "copyright": "Ⓒ 2023 Marriott International. All Rights Reserved",
      "submitButton": "Submit",
      "phasetwo": {
        "weValueG1Q1Title": "Did you attend a daily meeting the last shift you worked? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, or Daily EDITION)?",
        "weValueG1Q2Title": "The daily meeting (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, or Daily EDITION) inspires team members to create luxury service each day.",
        "weValueG1Q3Title": "At my property, we are focused on reducing defects, even those not reported by the guests.",
        "weValueG1Q4Title": "At my property, we are empowered to resolve guest issues without having to ask for permission first.",
        "weValueG1Q5Title": "At my property, we are empowered to delight guests without having to ask for permission first."
      },
      "checkin": {
        q1Title: "You are walking through the lobby and notice a family with three small children.  The family has many bags, looks stressed from a long day of travel, and the children are whining and visibly restless.  What would you do?",
        q2Title: "You have just started at a property in an area new to you.  A guest asks for suggestions for things to do in the area.  The guest has read several guidebooks but is looking for something more personalized.  What would you do?",
        q3Title: "You are walking through the lobby when you hear a guest talking on the phone and learn that their car service to the airport is running late.  Your property either does not have a house car or it is unavailable.  What would you do? ",
        q4Title: "You are walking through the lobby on your way to complete an urgent task your manager assigned to you. You notice a fellow colleague struggling to complete a task that is typically accomplished by two people.  What would you do? ",
        q5Title: "You are a host at the hotel restaurant.  A guest asks to see the menu while they wait for their reserved table. After looking at the menu, the guest is frustrated because the hotel restaurant was suggested to them by another colleague, but there is nothing on the menu that meets their dietary needs.  What would you do?",
        q6Title: "There is a large group of guests in the crowded pool area who were unable to find seats together.  As a result, they have laid their towels and personal belongings out in such a way that blocks a main walkway, creating a hazard for and disruption to guests and associates trying to access the pool area.  The area needs to be cleared, but you don't want to upset the group.  What would you do? ",
        q1R1Title: "Help the family with their luggage, point them in the direction of the check-in desk, and suggest a few relaxing, nearby places with family-friendly activities. Mention the property's family amenities.",
        q1R3Title: "Guide the parents to check-in, then personally take the children to a seating area with snacks while the parents are checking in. Have the front desk inform the parents about play areas and kids' activities at the property for their comfort.",
        q1R2Title: "Offer to help them with their luggage and guide the family to check-in. Then, arrange for a relaxing service or treat for the parents and fun activities for the kids in their room.",
        q1R4Title: "Point the family toward the check-in desk for a swift check-in process. Mention the kid-friendly areas and services to the parents so the kids can enjoy a pleasant stay.",
        q2R1Title: "Ask the guest what they enjoy, then team up with a colleague to create a list of fun things for them to do. Additionally, suggest a nice restaurant nearby.",
        q2R2Title: "Talk to the guest about what they like. Work with a colleague to make a special list of fun things just for them. Additionally, check the guest’s Bonvoy profile and send a personalized treat to their room.",
        q2R3Title: "Make it personal by sharing some of your own favorite things to do in the area so far. Make your suggestions stand out by sharing extra details about your experiences. Then, send a tasty local treat to their room.",
        q2R4Title: "Let the guest know you're new to the area and don't have many ideas. Apologize and suggest they talk to your colleague who knows more. Then, ask if they'd like a local treat sent to their room.",
        q3R1Title: "While the guest is still on the phone, pull your own or your colleague’s personal vehicle up to the front of the property and let the guest know that you will personally take them to their airport.",
        q3R2Title: "While the guest is still on the phone, put together a list of property-approved car services and phone numbers. Once the guest is off the phone, present them with the list and offer to help arrange the transportation.",
        q3R3Title: "Wait until the guest is off the phone and tell them that your property does not offer transportation services, but you’d be happy to help coordinate other transportation options. ",
        q3R4Title: "While the guest is still on the phone, find alternative transportation for the guest. Once they are off the phone, let the guest know that you have arranged for their transport to the airport and the car will be arriving shortly.",
        q4R1Title: "Go up to your colleague and say you'll help them, but you need to finish your urgent task first. Promise to return quickly to assist them. Make sure to discuss the situation with your manager.",
        q4R2Title: "First, quickly find another colleague to take over your assigned task. Then, immediately help your colleague who needs assistance. When complete, inform your manager that you found someone else to complete your task so you could help your colleague. ",
        q4R3Title: "Stop and help your colleague right away. After helping, return to your assigned task, then let your manager know that you put your task on hold to help your colleague out.",
        q4R4Title: "Go to your colleague needing help and explain you're in the middle of an urgent task. Stop both tasks, brainstorm a way to solve both your tasks jointly, and then complete both tasks together. ",
        q5R1Title: "Apologize to the guest and let them know you'll get the manager. Make sure to explain the situation so the manager can help the guest with their food needs.",
        q5R2Title: "Apologize to the guest and ask about their food preferences. Talk to the kitchen team to make a special dish just for them. Also, give them a complimentary appetizer or drink to make up for the mistake.",
        q5R3Title: "Apologize and tell the guest you understand. Instead of canceling the reservation, offer to talk to the chef and see if they can make something special for the guest. Give them a complimentary treat or discount to make up for the trouble. ",
        q5R4Title: "Apologize and tell the guest you can help them book a table at a nearby restaurant that has the food they like. Offer extra info or help with getting there if needed.",
        q6R1Title: "Apologize to the guests for the lack of space and tell them they are welcome to remain where they are as long as they are attentive to others walking by. ",
        q6R2Title: "Kindly tell the guests they can't block the walkway, and they'll need to find another spot or wait until space opens up. Meanwhile, enlist your colleagues to bring additional seating from the ballroom outside for them to use. ",
        q6R3Title: "Let the guests know that they cannot stay in their current location and that they must temporarily move while you find additional seating. Offer them space at the bar/restaurant in the meantime. ",
        q6R4Title: "Apologize to the guests for the seating issue. Ask if they can put their stuff together to make a small walkway for others walking by. Offer to help them, making sure everyone is comfortable. Send them a special treat from the bar/restaurant for their inconvenience.",
      }
    },
    "lbiIntro": "Welcome to the Luxury Behavior Check-in!",
    "lbiDescription": `<p>Below you will be asked to read a few short scenarios you might encounter at your property. &nbsp;Please read each scenario and choose how you would respond to each situation. &nbsp;Keep the following in mind as you respond:</p>
    <ul>
        <li>
            <p>You may find that more than one of the responses to choose from seem correct. Choose the response that you would most likely take at work.</p>
        </li>
        <li>
            <p>Assume there are no communication barriers. &nbsp;The language you speak is the same as the language the guest or associate in the scenario speaks.</p>
        </li>
        <li>
            <p>The scenario may ask you to imagine yourself in a different work role than the role you are currently in. &nbsp;Answer the question to the best of your knowledge, based on your experience as a luxury hospitality professional.</p>
        </li>
    </ul>
    <p><br></p>
    <p>Please note: Your participation in this check-in is entirely voluntary (you may choose not to participate), and your responses will be strictly confidential. Results for this check-in will be reported for groups of people and not for specific individuals. Individual responses will be evaluated by the research team and will not be shared with Managers of Others or leaders.&nbsp;</p>
    <p>By completing this check-in you are agreeing to the collection and processing of your personal responses and data (e.g., length of service, gender, job title), as well as the cross-border transfer of your personal data to the Marriott Group. This is in accordance with&nbsp;<a href="https://mgscloud.marriott.com/NA/-footer/-termsofuse/associate-personal-information-protection-statement.html">Marriott&rsquo;s Associate Personal Data Privacy Statement</a>, in which you can also find additional information and contacts for reference on Marriott&rsquo;s Data Privacy Policies.&nbsp;</p>`,
    "weValue": "We would love to hear from you.",
    "weValueDescription": "Please answer these quick questions about your experience working for your property.",
    "weValueButtonText": "Take the survey",
    "weValueG1Q1A1": 'Yes',
    "weValueG1Q1A2": 'No',
    "strongAgree": "Strongly Agree",
    "agree": "Agree",
    "slightAgree": "Slightly Agree",
    "slightDisagree": "Slightly Disagree",
    "disagree": "Disagree",
    "strongDisagree": "Strongly Disagree",
    "weValueG1Q1Title": "Did you attend a daily meeting the last shift you worked? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, or Daily EDITION)",
    "weValueG1Q2Title": "The daily meeting (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, or Daily EDITION) inspires me to create luxury service each day.",
    "weValueG1Q3Title": "My department is focused on reducing defects, even those not reported by the guests.",
    "weValueG1Q4Title": "I am empowered to resolve guest issues without having to ask for permission first.",
    "weValueG1Q5Title": "I am empowered to delight guests without having to ask for permission first.",
    "weValueG2Q1Title": "I regularly see team members at my property go above and beyond to impress the guests with the care they deliver.",
    "weValueG2Q2Title": "My learning in this department has enabled me to deliver luxury service.",
    "weValueG2Q3Title": "I feel confident with my knowledge and skills to deliver luxury service.",
    "weValueG2Q4Title": "In my department, we deliver a luxury environment, clean and defect-free.",
    "weValueG2Q5Title": "Even when I encounter an issue unrelated to my department, I am empowered to step in and own the guest concern.",
    "weValueG3Q1Title": "In my property, team members receive the same level of care as the guest.",
    "weValueG3Q2Title": "My department embodies the values of the brand.",
    "weValueG3Q3Title": "When I go above and beyond to create a luxury guest experience, my work is acknowledged and appreciated.",
    "weValueG3Q4Title": "I am involved in our department's efforts to improve our work.",
    "weValueG3Q5Title": "In my department, we always put the guest first.",
    "copyright": "Ⓒ 2021 Marriott International. All Rights Reserved",
    "submitButton": "Submit",
    "phasetwo": {
      "weValueG1Q1Title": "Did you attend a daily meeting the last shift you worked? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, or Daily EDITION)?",
      "weValueG1Q2Title": "The daily meeting (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, or Daily EDITION) inspires team members to create luxury service each day.",
      "weValueG1Q3Title": "At my property, we are focused on reducing defects, even those not reported by the guests.",
      "weValueG1Q4Title": "At my property, we are empowered to resolve guest issues without having to ask for permission first.",
      "weValueG1Q5Title": "At my property, we are empowered to delight guests without having to ask for permission first.",
      "weValueG2Q1Title": "At my property, we regularly go above and beyond to impress the guests with the care we deliver.",
      "weValueG2Q2Title": "Learning opportunities at the property have enabled us to deliver luxury service.",
      "weValueG2Q3Title": "At my property, we feel confident with our knowledge and skills to deliver luxury service.",
      "weValueG2Q4Title": "At my property, we deliver a luxury environment, clean and defect-free.",
      "weValueG2Q5Title": "Even when we encounter an issue unrelated to our own department, we are empowered to step in and own the guest concern.",
      "weValueG3Q1Title": "At my property, we receive the same level of care from each other as we provide to the guest.",
      "weValueG3Q2Title": "My property embodies the values of the brand.",
      "weValueG3Q3Title": "At my property, when we go above and beyond to create a luxury guest experience, our work is acknowledged and appreciated.",
      "weValueG3Q4Title": "At my property, we have the opportunity to be involved in our department's efforts to improve our own work.",
      "weValueG3Q5Title": "At my property, we always put the guest first."
    },
    "checkin": {
      q1Title: "You are walking through the lobby when you hear a guest talking on the phone and learn that their car service to the airport is running late.  Your property either does not have a house car or it is unavailable.  What would you do? ",
      q2Title: "Your property is very busy and your colleagues on duty are overwhelmed.  There is trash scattered in the lobby, dirty glasses on side tables, and the chairs and pillows have been moved out of place.  You do not have the supplies needed to clean up the mess on hand and you are on your way to respond to a non-urgent guest request.  You don’t want to keep the guest waiting, but you know the mess needs to be cleaned up.  What would you do? ",
      q3Title: "You are walking through the lobby and notice a family with three small children.  The family has many bags, looks stressed from a long day of travel, and the children are whining and visibly restless.  What would you do? ",
      q4Title: "You have just started at a property in an area new to you.  A guest asks for suggestions for things to do in the area.  The guest has read several guidebooks but is looking for something more personalized.  What would you do? ",
      q5Title: "You are a host at the hotel restaurant.  A guest asks to see the menu while they wait for their reserved table. After looking at the menu, the guest is frustrated because the hotel restaurant was suggested to them by another colleague, but there is nothing on the menu that meets their dietary needs.  What would you do?",
      q6Title: "You are walking through the lobby on your way to complete a task your manager assigned, and you notice a fellow colleague struggling to complete a task that is typically accomplished with two people.  What would you do?",
      q7Title: "There is a large group of guests in the crowded pool area who were unable to find seats together.  As a result, they have laid their towels and personal belongings out in such a way that blocks a main walkway, prevents guests and associates from accessing the pool area, and has disrupted other guests.  The area needs to be cleared, but you don't want to upset the group.  What would you do?",
      q1R1Title: "Own the issue by partnering with the appropriate colleague to offer other transportation options or personally take the guest if necessary.",
      q1R2Title: "Offer the guest a list of other car services in the area so they can make their own plan to get to the airport.",
      q1R3Title: "Nothing, if the property cannot offer a car service, you unfortunately will not be able to find them another option to get to the airport.",
      q1R4Title: "Book another car service for the guest on your own.  When it arrives, inform the guest the car is waiting outside.",
      q2R1Title: "Call a colleague to help clean up the mess before returning to the guest request, then return to assist with the mess and ensure it was taken care of.",
      q2R2Title: "You quickly return the chairs to their proper location as well as replace and fluff the pillows.  Then you find the appropriate colleague to clean up the remaining mess so that you can return to the guest request.",
      q2R3Title: "Notify the appropriate colleague about the mess and return to the guest request.",
      q2R4Title: "Nothing, you prioritize completing the guest request.",
      q3R1Title: "Offer to assist with the luggage, escort the parents to the check in desk, and coordinate to have something relaxing for the parents and entertaining for the kids sent to their room.",
      q3R2Title: "Offer to assist with the luggage and direct them to the check-in desk",
      q3R3Title: "Direct the family to the check in desk so they can get to their room more quickly.",
      q3R4Title: "Direct the parents to check in while you walk the children over to a seating area and offer them a snack while they wait.",
      q4R1Title: "Ask the guest about their interests, then work with the appropriate colleague to come up with a list of activities for you to provide to the guest. In the meantime, send a local treat to the guest’s room with a personalized, handwritten note.",
      q4R2Title: "Ask the guest about their interests, then work with the appropriate colleague to come up with a list of activities for you to provide to the guest.",
      q4R3Title: "Offer suggestions for things to do in the area.",
      q4R4Title: "Tell the guest that you are new to the area and don’t have any suggestions but ask them if they have been enjoying their trip so far. ",
      q5R1Title: "Apologize to the guest and note their preferences internally for the future. Ask the guest about their dietary needs, then work with the appropriate team to come up and offer other options to the guest.",
      q5R2Title: "Apologize to the guest and offer to help them make a reservation at a nearby restaurant that can meet their dietary needs.",
      q5R3Title: "Apologize to the guest and offer to get the manager to assist them. ",
      q5R4Title: "Apologize to the guest and remind them that the restaurant menu is available for guests to review prior to making a reservation. Suggest cancelling the reservation for them.",
      q6R1Title: "Stop and help your fellow colleague on your way to complete your task.",
      q6R2Title: "Approach your fellow colleague and ask them to wait a few minutes to complete the task.  You must complete your task first but would be happy to come back and assist them when you are finished. ",
      q6R3Title: "Approach your fellow colleague and tell them to find another colleague that can help them with the task, as it really should be handled by two people. ",
      q6R4Title: "Your assigned task is more important, so you handle your task immediately and continue with the remainder of your daily tasks. ",
      q7R1Title: "Apologize to the guests and work with the appropriate colleagues to make extra room for the guests if possible.  If not possible, engage your leader for guidance on how to best handle the situation and send the guests a special treat for moving.",
      q7R2Title: "Apologize to the guests for the lack of seating around the pool and ask if they can consolidate their belongings to allow a small opening on the walkway.",
      q7R3Title: "Tell the guests that they cannot block the walkway and will need to find another place to sit.",
      q7R4Title: "Politely ask the guests to relocate, but do not press the issue any further if they refuse so as to not disrupt their experience."
    }
  },
  exploreLearning: {
    recentSearch: 'Recent Searches',
    exploreLearningTitle: 'Explore Learning',
    exploreLearningHelpText: 'Not sure what you\'re looking for?',
    exploreMore: 'Explore More.',
    viewAllResults: 'View All <#> Results',
    searchSubTitle: 'Search for courses using keywords, titles or content ID.',
    columnTitle: 'Title',
    columnDesc: 'Description',
    columnType: 'Type',
    columnAddedToPlan: 'Add(ed) To Plan',
    columnComplete: 'Complete',
    columnDetails: 'Details',
    additionalSearchFilters: 'Additional Search Filters',
    landingSubTitle: 'Own your learning journey. Explore your interests — there are new topics and courses added all the time.',
    pinnedLearningTitle: 'My Pinned Learnings',
    sharpenYourSkillsTitle: 'Sharpen Your Skills',
    sharpenYourSkillsSubTitle: 'Build your knowledge and skills to enhance your career, now and in the future.',
    growYourCareerTitle: 'Grow Your Career',
    growYourCareerSubTitle: 'Plan your next move with specialized skills or cross-training that support your unique interests and goals.',
    additionalResourcesTitle: 'Additional Resources',
    additionalResourcesSubTitle: 'See how the DLZ can continue to help you grow.',
    viewMore: 'View More',
    viewLess: 'View Less',
    noOptions: 'No options available',
    clearAll: 'Clear All',
    viewDetails: 'View Details',
    tourHeader : 'Welcome to the updated DLZ. Now you can search to find whatever you need, when you need it. Plus you will find an updated Explore Learning and a more personalized My Plan. Take the Tour!',
    tourMyPlan: 'My Plan now has items you added from Search and Explore so you can fully own your learning journey!',
    tourSearchBar: 'Find what you need, when you need it, with a new and more robust Search feature.',
    tourExploreLearning: 'You can still get to Explore Learning from here!',
    tourExploreLearningLink: 'We have moved and improved Explore Learning.',
    tourExploreLearningProfile: 'Make the most of your learning experience and check your profile, especially job functions, on a routine basis to keep your My Plan up to date.',
    tourBtnBack: 'Back',
    tourBtnClose: 'Close',
    tourBtnLast: 'Close',
    tourBtnNext: 'Next',
    tourBtnSkip: 'Dismiss',
    loadingResults: 'Loading',
    addToMyPlan: 'Add to My Plan',
    removeFromMyPlan: 'Remove from My Plan',
    clearSelection: 'Clear selection',
    courseAddedToPlan: 'Course has been added to your plan.',
    lpAddedToPlan: 'Learning Pathway has been added to your plan.',
    courseRemovedFromPlan: 'Course has been removed from your plan.',
    lpRemovedFromPlan: 'Learning Pathway has been removed from your plan.',
    hasBeenPinned: 'has been pinned.',
    hasBeenUnpinned: 'has been unpinned.',
    noResults: 'No Results',
    clearFilters: 'Clear Filters',
    searchBarPlaceHolder: 'There\'s always more to learn.',
    searchAllLearning: 'Search All Learning',
    mhubTraining: 'mHUB Training',
    growthSkills: 'Growth Skills',
    leadershipSkills: 'Leadership Skills',
    virtualTeamResources: 'Virtual Team Resources',
    jobFunctions: 'Job Functions',
    brands: 'Brands',
    languageLearning: 'Language Learning',
    become: 'Become',
    emeaMentoringProgram: 'EMEA Mentoring Program',
    learningPathways: 'Learning Pathways',
    mda: 'MDA',
    takeCareGrow: 'TakeCare GROW',
    getAbstract: 'getAbstract®',
    cultureWizard: 'CultureWizard™',
    takeCareCommunityHub: 'TakeCare Community Hub',
    mhubTrainingDesc: 'Find role-based learning paths and topic-specific courses on how to use mHUB.',
    growthSkillsDesc: 'Gain practical knowledge across various functions and topics.',
    leadershipSkillsDesc: 'Uncover how to manage and coach a team and become a more effective leader.',
    virtualTeamResourcesDesc: 'Learn techniques to foster a collaborative and inclusive culture within virtual teams.',
    jobFunctionsDesc: 'Expand knowledge of your current job function and learn about others.',
    brandsDesc: 'Learn more about the diverse brands of Marriott International.',
    languageLearningDesc: 'Communicate effectively with a global audience.',
    becomeDesc: 'Discover a total approach to career growth & development: reflect, plan, act, refine.',
    emeaMentoringProgramDesc: 'Unlock your potential with the EMEA Mentoring Program.',
    learningPathwaysDesc: 'Explore specific learning opportunities curated for your leadership level.',
    mdaDesc: 'Build leadership skills and functional skills with Marriott Development Academy.',
    takeCareGrowDesc: 'Identify resources designed to build an inclusive and inspired community.',
    getAbstractDesc: 'Learn something new every day with book summaries that help you make better decisions.',
    cultureWizardDesc: 'Gather valuable knowledge to communicate effectively across diverse cultures.',
    takeCareCommunityHubDesc: 'Discover TakeCare teambuilding activities, learning programs, and tools.',
    resultsFor: 'Results for',
    resultsFortheFilter: 'Results for the filter',
    no: 'No',
    results: 'Results',
    courseTooltipTitle: 'An individual learning item that may contain one or more activities.<br />A course may be online, such as a video or eLearning, or occur in a classroom.',
    gatewayToolTipTitle: 'Digital resources for new hires, those new to management, and GMs new to their brand.',
    speciallizationOrPathwayTooltipTitle: 'A set of courses focused on a particular topic.',
    taskTooltipTitle: 'Instructions to complete an activity (usually on-the-job) and then mark that you have completed it.',
    webSiteTooltipTitle: 'Any web site, including Marriott sites or external web sites.'
  },
  "dontShowAgain": "Don't show this again",
  "deletionWarningEnabled": "Deletion warning enabled",
  "deletionSelected": "When selected, you will see a warning each time you delete a notification.",
  "manageEmailNotifications": "Manage Email Notifications",
  "seeHowDLZContinue": "See how the DLZ can continue to help you grow.",
  "bonvoy": {
    "submitButton": "Enroll Here",
    "title": "Did You Know?",
    "titleManaged": "Marriott Bonvoy Just Turned Five!",
    "titleFranchised": "Marriott Bonvoy Just Turned Five!",
    "descriptionManaged": "<p>Did you know that you can be Marriott Bonvoy member too? Get even more out of the EXPLORE by Marriott Bonvoy™ program and start earning points with each stay. Be sure to tell friends and family they can be members too!</p>",
    "descriptionFranchised": "<p>Did you know that you can be Marriott Bonvoy member too? Get even more out of the EXPLORE by Marriott Bonvoy™ program and start earning points with each stay!</p>"
  },
  validatorHeader: {
    myTeam: 'MY TEAM',
    admin: 'ADMIN',
    editJobFunctionAwr: 'EDIT ROLE, AWR, OR ADDL. BRANDS',
    impersonate: 'PREVIEW LEARNER',
  },
  validatorTable: {
    id: 'EID',
    name: 'Name',
    functionTypes: 'Role',
    title: "Validate Your Team's Data",
    awrTypes: 'AWRs',
    startDate: 'Start Date',
    actions: 'Actions',
    brands: 'Addl. Brands',
  },
  validatorProfile: {
    preferredLanguageLabel: 'What is your preferred language?',
    startDateLabel: 'When did you start working at Marriott International or Starwood?',
    brandsLabel: 'Do you support additional brands?',
    managementLevelLabel: 'What is your associate level?',
    jobFunctionsLabel: 'Tell us more about your work tasks.',
    additionalJobFunctionsLabel: 'Are there additional tasks you perform?',
    highGuestContactLabel: 'Are you high guest contact?',
    save: 'Save',
    edit: 'Edit',
    close: 'Close',
  },
  validatorBulk: {
    title: 'Edit Role, Additional Work Responsibilities (AWR), or Additional Brands',
    selectLocationOrLearner: 'Select location or learner',
    searchLocationOrLearner: 'Select learners by location number or MARSHA, name, email, or person number',
    tableTitle: 'Refine selection(s) by filtering or selecting from the table below',
    actionType: 'Choose Add or Remove Role, AWR, or Additional Brands',
    searchTooltipText: 'Multiple entries can be selected from the search',
    addTitle: 'Add Role, AWR, or Additional Brand',
    removeTitle: 'Remove Role, AWR, or Additional Brand',
    searchRoles: 'Search Roles',
    searchAwrs: 'Search Additional Work Responsibilities',
    searchBrands: 'Search Additional Brands',
    roles: 'Role(s)',
    awrs: 'AWR(s)',
    brands: 'Addl. Brand(s)',
    confirmationAdd: 'By submitting, you are about to add',
    confirmationRemove: 'By submitting, you are about to remove',
    toLearners: 'to the following Learner(s):',
    fromLearners: 'from the following Learner(s):',
    success: 'Success',
    added: 'You have added',
    removed: 'You have removed',
    toAssociates: 'to the requested associate(s)',
    fromAssociates: 'from the requested associate(s)',
    atProperty: 'at',
    more: 'Would you like to edit additional Roles, AWRs, or Brands for',

    back: 'Back',
    associatesTableTitle: 'Associate List',
  },
  validatorImpersonate: {
    title: 'Preview Learner',
    subtitle: 'Select learner',
    button: 'Preview',
    impersonating: 'Previewing',
  },
};