import { decorate, observable, action } from 'mobx';
import CourseStore  from './CourseStore'
import UserStore from './UserStore';
import Api from '../api/CMDBApi';
import LanguageStore from './LanguageStore';
import _ from 'lodash';

class SearchStore {
  isSearching = false;
  awrSearchResults = [];
  totalAwrSearchResultCount = 0;
  learningsSearchResults = [];
  filteredLearningsSearchResults = [];
  currentLearningSearchTerm = "";
  recentSearchItems = [];
  selectedViewDetailRow = null;

  clearSearchLearning() {
    this.filteredLearningsSearchResults = [];
    this.learningsSearchResults = [];
    this.recentSearchItems = [];
  }
  async searchUsers(userSearch) {
    try {
      this.isSearching = true;
      const response = await Api.search(userSearch, 'user', null, 20);
      const totalResultCount = response.headers['x-total-result-count'];

      if (response.data !== undefined) {
        if (response.data.results) {
          this.userResults = response.data.results;
        } else {
          this.userResults = response.data;
        }
        this.totalUserResultCount = totalResultCount;
      }
      this.isSearching = false;
    } catch (e) {
      this.isSearching = false;
      console.log(e);
    }
  }

  async searchLocations(locationSearch) {
    try {
      this.isSearching = true;
      const response = await Api.search(locationSearch, 'location');
      const totalResultCount = response.headers['x-total-result-count'];

      if (response.data !== undefined) {
        if (response.data.results) {
          this.locationResults = response.data.results;
        } else {
          this.locationResults = response.data;
        }
        this.totalLocationResultCount = totalResultCount;
      }
      this.isSearching = false;
    } catch (e) {
      this.isSearching = false;
      console.log(e);
    }
  }

  async searchResources(resourceSearch) {
    try {
      this.isSearching = true;
      const response = await Api.search(resourceSearch, 'resource');
      const totalResultCount = response.headers['x-total-result-count'];

      if (response.data !== undefined) {
        if (response.data.results) {
          this.resourceResults = response.data.results.map(r => {
            if (r.type && r.type === 'DLP Course Bundle') {
              r.type = 'Course Bundle';
            }
            return r;
          });
        } else {
          this.resourceResults = (response.data || []).map(r => {
            if (r.type && r.type === 'DLP Course Bundle') {
              r.type = 'Course Bundle';
            }
            return r;
          });
        }
        this.totalResourceResultCount = totalResultCount;
      }
      this.isSearching = false;
    } catch (e) {
      this.isSearching = false;
      console.log(e);
    }
  }

  async searchAwrData(query) {
    try {
      this.isSearching = true;
      const response = await Api.search(query, 'awr');
      const totalAwrSearchResultCount = response.headers['x-total-result-count'];

      if (!_.isUndefined(response.data)) {
        if (response.data.results) {
          this.awrSearchResults = response.data.results;
        } else {
          this.awrSearchResults = response.data;
        }
        this.totalAwrSearchResultCount = totalAwrSearchResultCount;
      }
    } catch (e) {
      console.log('Error in finding learners for AWR bulk edit', e);
    } finally {
      this.isSearching = false;
    }
  }

  async searchLearnings (query, filters) {
    try {
      this.isSearching = true;
      const response = await Api.search(query, 'learnings', filters);

      if (!_.isUndefined(response.data)) {
        this.isSearching = false;
      }

      let { results, recentSearchItems } = response.data || [];

      if(!_.isEmpty(results)) {
        results = (results || []).map(row => ({
          ...row,
          isInPlan: CourseStore.isInPlan(row),
          isRequiredCourse: (CourseStore.requiredCourses || []).find(rc => {
            return (
              parseInt(rc.id) === row.id
              || (!_.isEmpty(rc.sourceId) && rc.sourceId === row.sourceId)
            )
          }) != null
        }));
        this.learningsSearchResults = results;
        this.filteredLearningsSearchResults = results;
        this.totalResultCount = response.headers['x-total-result-count'];
      } else {
        this.learningsSearchResults = [];
        this.filteredLearningsSearchResults = [];
      }

      if (recentSearchItems) {
        this.recentSearchItems = (recentSearchItems || "").split(',');
      }
    } catch (e) {
      console.log('Error occurred while searching for learnings', e);
    } finally {
      this.isSearching = false;
    }
  }

  async getRecentSearchItems() {
    try {
      const { data  } = await Api.getRecentSearchItems();
      this.recentSearchItems = data.recentSearchItems;
    } catch (e) {
      console.log(e);
    }
  }


  clearUserResults() {
    this.userResults = [];
  }

  clearAwrSearchResults() {
    this.awrSearchResults = [];
  }

  clearLearningsSearchResutls() {
    this.learningsSearchResults = [];
  }
}

decorate(SearchStore, {
  userResults: observable,
  resourceResults: observable,
  locationResults: observable,
  totalResourceResultCount: observable,
  totalUserResultCount: observable,
  totalLocationResultCount: observable,
  awrSearchResults: observable,
  totalAwrSearchResultCount: observable,
  learningsSearchResults: observable,
  currentLearningSearchTerm: observable,
  recentSearchItems: observable,
  filteredLearningsSearchResults: observable,
  selectedViewDetailRow: observable,
  isSearching: observable,
  searchUsers: action,
  searchLocations: action,
  searchResources: action,
  clearUserResults: action,
  searchAwrData: action,
  clearAwrSearchResults: action,
  searchLearnings: action,
  clearLearningsSearchResutls: action,
  getRecentSearchItems: action,
  clearSearchLearning: action
});

export default new SearchStore();
