import ReactGA from 'react-ga4';
import { decorate, observable, action, computed, extendObservable } from 'mobx';
import * as mobx from 'mobx';

import LookupStore from './LookupStore';
import LanguageStore from './LanguageStore';
import Api from '../api/CMDBApi';

function shuffle2(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


const supportedLanguages = [{
  key: 'af',
  value: 'Afrikaans'
},
{
  key: 'ar',
  value: 'Arabic'
},
{
  key: 'zh-CN',
  value: 'Chinese, Simplified'
},
{
  key: 'zh-TW',
  value: 'Chinese, Traditional'
},
{
  key: 'fr-FR',
  value: 'French, France'
},
{
  key: 'de',
  value: 'German'
},
{
  key: 'en',
  value: 'English'
},
{
  key: 'hi',
  value: 'Hindi'
},
{
  key: 'id',
  value: 'Indonesian'
},
{
  key: 'it',
  value: 'Italian'
},
{
  key: 'ja',
  value: 'Japanese'
},
{
  key: 'ko',
  value: 'Korean'
},
{
  key: 'pt-BR',
  value: 'Portuguese (Brazil)'
},
{
  key: 'ru',
  value: 'Russian'
},
{
  key: 'es',
  value: 'Spanish, Universal/Mexico'
},
{
  key: 'th',
  value: 'Thai'
},
{
  key: 'tr',
  value: 'Turkish'
},
{
  key: 'vi',
  value: 'Vietnamese'
},
{
  key: 'zu',
  value: 'Zulu'
}];

class BecomeStore {
  initializing = false;
  initialized = false;
  baseData = null;

  async initialize() {
    if (this.initializing) {
      return;
    }

    this.initializing = true;

    try {
      const { data: baseData } = await Api.getProjectContent('become');
      this.baseData = baseData;

      this.initialized = true;
    } catch (e) {
      console.log(e);
    }
  }

  get siteContent() {
    if (!this.baseData) {
      return {};
    }

    return this.baseData.find(b => b.content.type === 'site-content').content;
  }

  get stories() {
    if (!this.baseData) {
      return [];
    }

    const langCode = LanguageStore.code || 'en';
    const lang = LanguageStore.lang;

    const translatedJFName = jfId => {
      if (langCode === 'en') {
        return LookupStore.jobFunctionCategories.find(jf => jf.id === jfId).name
      } else {
        const langValue = supportedLanguages.find(l => l.key === langCode).value;
        return _.get(LookupStore.jobFunctionCategories.find(jf => jf.id === jfId), `extra.${langValue}.name`) 
      }
    }

    const stories = shuffle2(this.baseData.filter(b => b.content.type === 'story').map(s => ({
      ...mobx.toJS(s.content),
      jobFunctionString: (s.content.jobFunctions || []).map(jfId => translatedJFName(jfId)).join(', '),
      archetypeString: lang.t((s.content.archetypes || []).map(id => {
        const found = this.baseData.find(b => b.id === id);
        if (found) {
          return found.content.name;
        } else {
          console.log('not found: ' + id)
        }
      }).join(', ')),
      regionString: (s.content.regions || []).join(', '),
      url: _.get(s, `pdf.${langCode}`, (s.content.pdf || {}).en)
    })));

    console.log(stories);
    return stories;
  }

  get filterArchetypes() {
    if (!this.baseData) {
      return [];
    }

    const lang = LanguageStore.lang;

    const archetypes = [];
    this.baseData.filter(b => b.content.type === 'story').forEach(s => {
      (s.content.archetypes || []).map(id => this.baseData.find(b => b.id === id).content.name).forEach(a => archetypes.push(lang.t(a)))
    });
    return _.uniq(archetypes).sort();
  }

  get filterJFs() {
    if (!this.baseData) {
      return [];
    }

    const langCode = LanguageStore.code || 'en';
    const lang = LanguageStore.lang;

    const translatedJFName = jfId => {
      if (langCode === 'en') {
        return LookupStore.jobFunctionCategories.find(jf => jf.id === jfId).name
      } else {
        const langValue = supportedLanguages.find(l => l.key === langCode).value;
        return _.get(LookupStore.jobFunctionCategories.find(jf => jf.id === jfId), `extra.${langValue}.name`) 
      }
    }

    const jobFunctions = [];
    this.baseData.filter(b => b.content.type === 'story').forEach(s => {
      (s.content.jobFunctions || []).map(jfId => translatedJFName(jfId)).forEach(a => jobFunctions.push(a))
    });
    return _.uniq(jobFunctions).sort();
  }

  get filterRegions() {
    if (!this.baseData) {
      return [];
    }

    const regions = [];
    this.baseData.filter(b => b.content.type === 'story').forEach(s => {
      (s.content.regions || []).forEach(a => regions.push(a))
    });
    return _.uniq(regions).sort();
  }

  get archetypes() {
    if (!this.baseData) {
      return [];
    }

    const archetypes = [];
    this.baseData.filter(b => b.content.type === 'archetype').forEach(s => archetypes.push(s));
    return archetypes;
  }
}

decorate(BecomeStore, {
  initialized: observable,
  initializing: observable,
  baseData: observable,
  stories: computed,
  archetypes: computed,

  initialize: action,
  filterRegions: computed,
  filterJFs: computed,
  filterArchetypes: computed,  
});

export default new BecomeStore();
