const includes = require('lodash/includes');

const defaultLanguageCode = 'en';

const supportedLanguages = [{
  key: 'af',
  value: 'Afrikaans'
},
{
  key: 'ar',
  value: 'Arabic'
},
{
  key: 'zh-CN',
  value: 'Chinese, Simplified'
},
{
  key: 'zh-TW',
  value: 'Chinese, Traditional'
},
{
  key: 'fr-FR',
  value: 'French, France'
},
{
  key: 'de',
  value: 'German'
},
{
  key: 'en',
  value: 'English'
},
{
  key: 'hi',
  value: 'Hindi'
},
{
  key: 'id',
  value: 'Indonesian'
},
{
  key: 'it',
  value: 'Italian'
},
{
  key: 'ja',
  value: 'Japanese'
},
{
  key: 'ko',
  value: 'Korean'
},
{
  key: 'pt-BR',
  value: 'Portuguese (Brazil)'
},
{
  key: 'ru',
  value: 'Russian'
},
{
  key: 'es',
  value: 'Spanish, Universal/Mexico'
},
{
  key: 'th',
  value: 'Thai'
},
{
  key: 'tr',
  value: 'Turkish'
},
{
  key: 'vi',
  value: 'Vietnamese'
},
{
  key: 'zu',
  value: 'Zulu'
}];	
const supportedLanugaeCodes = supportedLanguages.map(s => s.key);

function getDefaultLanguagePack() {
  return require(`./phrases_${defaultLanguageCode}.js`);
}

function getLanguagePack(languageCode) {
  const validLanguageCode = languageCode && includes(supportedLanugaeCodes, languageCode);
  const languagePhrases = validLanguageCode ?
    require(`./phrases_${languageCode}`) :
    getDefaultLanguagePack();

  return languagePhrases;
}

export default {
  defaultLanguageCode,
  getLanguagePack,
  getDefaultLanguagePack
};