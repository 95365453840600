import { decorate, observable, computed, action } from 'mobx';
//import dlzApi from '../api/LDApi'

const courseData = require('./courseBundleData.json');

class CourseBundleStore {
  async getPathway() {
    //const { data } = await dlzApi.getCourses();
    this.pathway = courseData;
    this.courses = courseData.resources;
  }

  get pathwayTitle() {
    return this.pathway.title;
  }

  get completedCourses() { 
    return this.courses.filter((course) => course.isCompleted);
  }

  get remainingCourses() { 
    return this.courses.filter((course) => !course.isCompleted);
  }
}

decorate(CourseBundleStore, {
  courses: observable,
  pathway: observable,
  getPathway: action,
  pathwayTitle: computed,
  completedCourses: computed,
  remainingCourses: computed
});

export default new CourseBundleStore();
