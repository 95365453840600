// const logo = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/logo.png';
// const heroImageLarge = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/heroLarge.png';
// const heroImageSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/heroSmall.png';
// const heroImageXSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/heroXSmall.png';
// const exploreImageLarge = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/exploreLarge.png';
// const exploreImageSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/exploreSmall.png';
// const exploreImageXSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/exploreXSmall.png';
import { themePrimaryColorsBrand } from '../style-constants';

export default {
  extendedTheme: () => ({
    logo: new Image().src = 'https://test-marriott.axonify.com/content/892958b2…7ac8dae0/e1bccb67-2f2f-46d5-8b98-9953c1700ec7.png',
    logoStyle: { width: 261, height: 'inherit', maxHeight: 'inherit' },
    heroImageLarge: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/heroLarge.png',
    exploreImageLarge: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/courtyard/exploreLarge.png',
    palette: {
      primary: {
        main:  '#343437'
      },
      secondary: {
        main: '#303e47'
      },
      primaryBtnHover: {
        main: '#845410',
      },
      buttonText: {
        main: '#ffffff'
      },
      interaction: {
        main: '#b83775'
      },
      text: {
        primary: '#1c1c1c'
      },
      link: {
        primary: '#1c1c1c'
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        '"Roboto"',
        '"ProximaNova-Regular"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
      h3: {
        fontFamily: [
          '"Roboto"',
        ]
      },
      h5: {
        fontFamily: [
          '"Roboto"',
        ]
      }
    },
  }),
  typography: (theme, remCalc) => {
    return {
      h1: {
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(25),
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(25),
          lineHeight: 1.24
        }
      },
      h2: {
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(21),
          lineHeight: 1.15
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(21),
          lineHeight: 1.2
        }
      },
      
    }
  },
  extraTypography: (theme, remCalc) => {
    return {
      h3: {
        fontFamily: 'Roboto !important',
        fontWeight: 400,
        textRendering: 'optimizelegibility'
      },
      h5: {
        fontFamily: 'Roboto !important',
        fontWeight: 400,
        textRendering: 'optimizelegibility'
      },
      profileCardFieldColor: {
        color: 'rgb(114, 114, 120)'
      },
      hero: {
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        }
      },
      card: {
        fontWeight: 'bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(38),
          lineHeight: 1.16
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(28),
          lineHeight: 1.21
        }
      }
    }
  }
  
};
