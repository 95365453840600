export default {
  "banner": {
    "viewDetails": "세부 정보 보기",
    "requiredCourses": "필수 과정이 다음 기간 내에 만료됩니다.",
    "days": "일"
  },
  "header": {
    "plan": "내 계획",
    "manager": "관리자",
    "explore": "학습 탐색",
    "reporting": "보고",
    "myTeam": "내 팀",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "내 계획",
    "manager": "관리자",
    "explore": "학습 탐색",
    "tagline": "학습은 단순하게 구성되었습니다. 학습은 학습자인 여러분이 시작합니다."
  },
  "welcome": {
    "hi": "안녕하세요.",
    "intro1": "Digital Learning Zone에 오신 것을 환영합니다! DLZ는 Marriott 학습에 대한 중앙 액세스 지점이 되는 개인 맞춤형 학습 플랫폼입니다. 역할에 필요한 교육을 찾고 개인 및 전문 개발을 위한 학습 기회를 탐색해 보세요. 학습 여정을 소유하고 기술을 성장시키기 위한 길을 떠나보세요.",
    "intro2": "시작하기 전에 귀하에 대해 몇 가지 질문을 드리고자 합니다.",
    "letsGo": "시작하기",
    "preferredLanguage": "선호하는 언어는 무엇입니까?",
    "step": "단계",
    "of": "/",
    "previous": "이전",
    "next": "다음",
    "startDate": "언제부터 Marriott International 또는 Starwood에서 근무하셨습니까?",
    "selectYear": "연도 선택",
    "selectMonth": "달 선택",
    "selectDay": "날짜 선택",
    "selectFunction": "직무 선택",
    "selectInterests": "관심 사항 선택",
    "chooseYear": "연도 선택",
    "chooseMonth": "달 선택",
    "chooseDay": "일 선택",
    "whatDo": "귀하의 직무는 무엇입니까?",
    "selected": "선택 완료",
    "searchFunctions": "모든 직무 검색",
    "searchInterests": "모든 관심 사항 검색",
    "additionalJobFunctionsLabel": "직무에 대해 더 자세히 알려주십시오",
    "managementLevelLabel": "귀하의 직원 레벨은 무엇입니까?",
    "preferredBrandLabel": "두 개 브랜드 이상에서 근무하고 계시는 경우 귀하에게 적용되는 모든 사항을 선택하십시오.",
    "additionalJobFunctionsLabelExtra": "다음을 검토한 후 추가 업무 책임을 한 개 이상 선택해 주십시오.",
    "searchAdditionalFunctions": "모든 추가 업무 책임 검색",
    "highGuestContactLabel": "고객과 자주 연락을 하십니까?",
    "yes": "예",
    "no": "아니요 "
  },
  "landing": {
    "started": "이제 시작하겠습니다.",
    "welcomeBack": "다시 찾아주셔서 감사합니다.",
    "greatWork": "수고하셨습니다.",
    "requiredCourses": "필수 과정",
    "requiredCoursesDesc": "마감일 이전에 과정을 완료해 주십시오.",
    "myPlan": "내 계획",
    "courses": "과정",
    "courseComplete": "과정 완료",
    "viewMore": "필수 과정 더 보기",
    "viewMoreBtn": "과정 더 보기",
    "showing": "표시",
    "of": "/",
    "heroTitle": "새로운 내용을 배우십시오. 권한을 강화하십시오. 직무에 적용되는 기술을 습득하십시오.",
    "heroParagraph1": "손쉽게 필수 과정을 추적하고 과정 완료 시 실시간으로 알림을 받으십시오.",
    "heroParagraph2": "직무와 관심 사항에 따라 사용자 정의 학습 경험을 즐기십시오.",
    "recommendedCoursesTitle": "추천 과정",
    "recommendedCoursesDescNoContent": "현재는 추천 과정이 없습니다. 다른 학습 기회를 찾으려면 아래를 참고하십시오.",
    "recommendedCoursesDesc": "관리자 또는 HR 전문가가 귀하를 위해 선택한 학습 기회를 확인하십시오.",
    "recommendedCoursesBtn": "추천 과정 보기",
    "completedCoursesBtn": "완료된 과정 보기",
    "exploreTitle": "계속 호기심을 가지십시오.",
    "exploreDesc": "관심 있는 주제를 선택하십시오.",
    "jobFunctions": "징계",
    "growthSkills": "성장 기술",
    "leadershipSkills": "리더십 기술",
    "brands": "브랜드",
    "languageLearning": "언어 학습",
    "exploreBtn": "과정 보기",
    "managerRecommendation": "관리자 추천",
    "learningRecommendation": "학습 추천",
    "marriottRecommendation": "MARRIOTT 추천",
    "completedCourses": "필수 과정을 모두 완료하셨습니다!",
    "investTitle": "학습 여정을 소유하세요",
    "investParagraph1": "우리는 현재 상황이 여러 가지 면에서 어렵다는 것을 잘 알고있습니다. DLZ는 여러분을 위한 자원을 제공하고자 합니다. 여러분의 기술을 최신 상태로 유지하거나, 새로운 것을 배우고, 스트레스를 관리하며, 가상 팀에서 작업하는 방법을 배우는 등 DLZ은 여러분을 지원하기 위해 다양한 자원을 제공합니다.",
    "updatedTopics": "업데이트된 항목",
    "workingRemotely": "가상 팀 리소스",
    "relatedCoursesNew": "새롭게 추가된 주제와 강좌를 참고하여 아래를 클릭하여 관심사를 업데이트하십시오.",
    "relatedCourses": "아래를 클릭하여 관심사를 선택하십시오.",
    "notificationText": "새로운 mHUB 교육을 이용할 수 있습니다. Explore Learning에서 mHUB를 선택하세요.",
    "mHubHeroTitle1": "간단하고, 스마트하고, 안전한 mHUB를 소개합니다.",
    "mHubHeroManagedText1": "mHUB는 학습, 경력 개발, Explore 할인 등의 도구들을 하나의 사용이 간편한 플랫폼으로 통합합니다.",
    "mHubHeroFranchisedText1": "mHUB는 학습, 경력 개발, Explore 할인 등의 도구들을 하나의 사용이 간편한 플랫폼으로 통합합니다.",
    "mHubHeroVisitLink": "추가 혜택 세부 사항 또는 부가 정보를 보려면 여기를 클릭하십시오.",
    "myGateway": "My Gateway",
    "gatewayReview": "검토 및 완료해주십시오.",
    "gatewayProgress": "Gateway 진행 상황",
    "complete": "완료",
    "reviewContent": "리소스 보기",
    "gatewayCardTitle": "Gateway 리소스",
    "gatewayCardDesc": "업무 담당 첫 해와 그 이후를 위한 기본 사항을 확인하세요.",
    "gatewayCardDescGms": "업무 담당 첫 해와 그 이후를 위한 기본 사항, 실행 과제, 브랜드 리소스, 분야별 리소스를 확인하세요.",
    "gatewayCardDescNew": "새 직무 담당 첫 해를 위한 실행 과제를 확인하세요.",
    "gatewayCardDescGmsNew": "새 직무 담당 첫 해와 그 이후를 위한 기본 사항, 실행 과제, 브랜드 리소스, 분야별 리소스를 확인하세요."
  },
  "courseBundle": {
    "completeBy": "완료자",
    "remainingCourses": "남은 과정",
    "completedCourses": "완료한 과정",
    "remainingLabel": "이 학습 경로에 {courseCount}개 과정이 있습니다.",
    "recommendButton": "팀에게 추천",
    "startCourse": "과정 시작",
    "durationMins": "분",
    "completed": "완료",
    "back": "뒤로",
    "additionalLangs1": "영어로 제공되는 과정뿐 아니라 이 과정도 현재 사용할 수 있습니다.",
    "additionalLangs2": "과정을 시작한 후 드롭다운 메뉴에서 선호하는 언어를 선택하십시오.",
    "targetAudience": "적용 대상",
    "all": "모두",
    "globally": "전 세계",
    "source": "출처:",
    "courseType": "과정 유형",
    "course": "과정"
  },
  "profile": {
    "save": "저장",
    "edit": "편집",
    "brand": "브랜드",
    "brands": "브랜드",
    "additionalWork": "모든 추가 업무 책임",
    "startDateLabel": "언제부터 Marriott International 또는 Starwood에서 근무하셨습니까?",
    "preferredLanguageLabel": "선호하는 언어는 무엇입니까?",
    "jobFunctionsLabel": "귀하의 직무는 무엇입니까?",
    "interestsLabel": "무엇을 배우고 싶으십니까?",
    "brandsLabel": "어떤 브랜드를 위해 일하고 계십니까?",
    "additionalJobFunctionsLabel": "수행하고 있는 추가 직무는 무엇입니까?",
    "managementLevelLabel": "귀하의 직원 레벨은 무엇입니까?",
    "highGuestContactLabel": "고객과 자주 연락을 하십니까?"
  },
  "courseDetails": {
    "completeBy": "완료 예정",
    "recommendTitlte": "귀하의 팀에게 추천하는 과정",
    "courseName": "과정 이름",
    "sendRecommendation": "추천 과정 보내기"
  },
  "explore": {
    "relatedCourses": "다음은 귀하의 관심 영역과 관련이 있는 과정입니다.",
    "chooseInterests": "관심 사항 선택",
    "title": "학습 탐색",
    "chooseInterestsModal": "관심 사항 선택",
    "oppsTitle": "학습 기회 탐색",
    "growWithUsTitle": "Marriott와 함께 성장",
    "growWithUsDesc": "메리어트는 여러분과 함께 성장하는 경력에 대한 믿음이 있습니다. 더욱 폭넓은 경험이 흔히 더욱 풍부한 경험으로 연결된다는 점 또한 믿고 있습니다.",
    "growWithUsBtn": "Marriott와 함께 성장 보기",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "메리어트는 성공과 노력을 보상합니다. 리더십과 뛰어난 서비스를 기리며 장려합니다. 또한, 리퍼럴 성공 시 보너스를 지급합니다.",
    "mdaBtn": "MARRIOTT DEVELOPMENT ACADEMY 보기",
    "selectInterests": "관심 있는 주제를 알려 주십시오.",
    "otherRecommended": "기타 추천 과정",
    "searchByName": "관심 분야 검색"
  },
  "plan": {
    "completedRequired": "필수 과정을 모두 완료하셨습니다!",
    "requiredCourses": "필수 과정",
    "reqDesc": "마감일 이전에 완료해 주십시오.",
    "complete": "완료",
    "of": "/",
    "courses": "과정",
    "completedCourses": "완료한 과정",
    "completedDesc": "축하합니다! 최근에 아래 과정을 완료하셨습니다.",
    "remaining": "남은 과정",
    "myLearningTitle": "내 추가된 학습 ",
    "myLearningSubTitle": "\"내 플랜\"에 추가한 항목",
    "history": "내 수강 이력",
    "myLearningHistoryLinkText": "내 전체 수강 이력 보기",
    "jf": "귀하의 직무",
    "awr": "추가 업무 책임(AWR)",
    "jfAwrDesc": "중요: 직무 및 AWR에 따라 \"내 계획\"에 할당된 필수 교육이 결정됩니다. ",
    "jfAwrEdit": "프로필을 편집",
    "jfAwrEnd": "하여 업데이트하세요.",
    "tourPlan": "\"내 계획\"에는 마감일까지 완료하기 위한 모든 필수 및 권장 과정이 포함되어 있습니다.",
    "tourBasics": "Mariott Basics에는 메리어트 여정을 시작할 수 있는 리소스가 포함되어 있으며, 경력 전반에 걸쳐 언제든지 액세스할 수 있습니다. Marriott Basics는 \"내 계획\"에서 학습하는 데 도움이 됩니다.",
    "noCourses": "현재 마감된 필수 학습을 모두 따라잡았으니, 앞으로 예정된 필수 학습을 자유롭게 시작하십시오."
  },
  "team": {
    "title": "팀 관리",
    "subtitle": "팀의 기술과 경력을 향상시키십시오.",
    "functions": "직무",
    "viewTeam": "팀 보기",
    "allCourses": "모든 과정",
    "downloadExcel": "Excel 보고서 다운로드"
  },
  "feedbackButton": {
    "havingTrouble": "제안"
  },
  "recommendation": {
    "dismiss": "해당 추천을 해제하시겠습니까?"
  },
  "table": {
    "filterTable": "Tabela de filtro"
  },
  "notifications": {
    "addAssignment2": "%{assignment/coursename}의 %{assignment_type} 과제가 %{page location}에 추가되었으며 마감 기한은 %{due}입니다.",
    "comingDueAssignment": "%{assignment/coursename}의 %{assignment_type} 과제가 %{due}에 마감될 예정입니다.",
    "overdueAssignment": "%{assignment/coursename}의 %{assignment_type} 과제 학습 기한이 지났습니다.",
    "addAssignment": "%{assignment/coursename}의 %{assignment_type} 과제가 %{page location}에 추가되었습니다.",
    "profile": "직책 또는 위치에 대한 업데이트가 감지되었습니다. 내 프로필을 확인하고 필요한 경우 브랜드, 직무 또는 추가 업무 책임을 업데이트하세요. DLZ 프로필 정보를 정확히 입력해야 올바른 학습 과제를 받을 수 있습니다.",
    "helptip": "모든 알림은 30일 후에 만료됩니다.",
    "markRead": "읽은 상태로 표시",
    "delete": "삭제",
    "markAllRead": "모두 읽은 상태로 표시",
    "settings": "설정",
    "tooltip": "읽기 알림은 30일 후에 삭제됩니다.",
    "reportComplete": "DLZ 보고가 완료되었습니다. 클릭하여 다운로드",
    "dontShowThisAgain": "다시 표시 안 함",
    "warningEnabled": "삭제 경고가 활성화되었습니다.",
    "manageEmailNotifications": "이메일 알림 관리",
    "warningTooltip": "이 옵션을 선택하면 알림을 삭제할 때마다 경고가 표시됩니다.",
    "changeEmailManagedTooltip": "이메일은 mHUB > HR Direct > 나 > 내 공개 정보에서 변경할 수 있습니다. 지침에 따라 연락처 세부 정보를 업데이트하시기 바랍니다. 업데이트된 이메일이 표시되기까지 1~2영업일이 걸릴 수 있습니다.",
    "changeEmailFranchisedTooltip": "이메일을 변경하려면 매니저 및/또는 프랜차이즈 EID Tool 관리자에게 문의하십시오. 업데이트된 이메일이 표시되기까지 1~2영업일이 걸릴 수 있습니다.",
    "deleteAllNotifications": "모든 알림 삭제",
    "deleteIndividualHeader": "알림 삭제",
    "deleteAllConfirmation": "모든 알림을 삭제하시겠습니까? 만료될 때까지 알림 센터의  \"삭제됨\" 섹션으로 이동됩니다.",
    "deleteIndividualConfirmation": "이 알림을 삭제하시겠습니까? 삭제된 알림은 만료될 때까지 알림 센터의 \"삭제됨\" 섹션으로 이동합니다.",
    "permanentDeleteIndividiualConfirmation": "이 알림을 영구적으로 삭제하시겠습니까? 이 작업은 취소할 수 없습니다.",
    "permanentDeleteAllConfirmation": "모든 알림을 영구적으로 삭제하시겠습니까? 이 작업은 취소할 수 없습니다.",
    "notificationDeleted": "알림이 삭제되었습니다.",
    "notificationsDeleted": "알림이 삭제되었습니다.",
    "notificationRestored": "알림이 복원되었습니다.",
    "notificationsRestored": "알림이 복원되었습니다.",
    "enableDeletionWarning": "삭제 경고 활성화",
    "warningDisabled": "삭제 경고가 비활성화되었습니다.",
    "viewAll": "전체 보기",
    "deleteAll": "모두 삭제",
    "notificationCenterTitle": "알림 센터",
    "markAsRead": "읽은 상태로 표시",
    "noDeletedNotifications": "최신 정보로 업데이트되었습니다!",
    "restore": "복원",
    "restoreAll": "모두 복원",
    "viewCourse": "과정 보기",
    "title": "알림",
    "noNewNotifications": "알림이 없습니다.",
    "cancel": "취소",
    "manageNotificationsCopy": "All-Inclusive 관리\n\n기한이 지난 학습 항목을 요약한 주간 이메일 수신 여부를 선택합니다. \"관리\"를 선택하여 기본 설정을 수정하십시오."
  },
  "transcript": {
    "history": "내 수강 이력",
    "completed": "완료한 과제",
    "assignmentName": "과제명",
    "type": "유형",
    "viewAssignment": "과제 보기",
    "assignmentSource": "과제 소스",
    "requiredAssignments": "필수 과제",
    "recommendedAssignments": "권장 과제",
    "voluntary": "선택",
    "trasscript": "성적",
    "retraining": "재수강",
    "completionDate": "완료일",
    "assignmentType": "과제 유형",
    "contentType": "콘텐츠 유형",
    "contentId": "콘텐츠 ID"
  },
  "contentLaunch": {
    "review": "학습 검토",
    "start": "학습 시작"
  },
  "survey": {
    "weValue": "여러분의 의견을 듣고 싶습니다.",
    "weValueDescription": "호텔 근무 경험에 대한 다섯 가지 질문에 답변해 주시기 바랍니다.",
    "weValueButtonText": "설문조사 참여",
    "weValueG1Q1A1": "예",
    "weValueG1Q1A2": "아니오",
    "strongAgree": "매우 동의함",
    "agree": "동의함",
    "slightAgree": "약간 동의함",
    "slightDisagree": "약간 동의 안함",
    "disagree": "동의 안함",
    "strongDisagree": "전혀 동의하지 않음",
    "weValueG1Q1Title": "마지막 근무 조의 일일 미팅에 참석하셨습니까? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments 또는 Daily EDITION)",
    "weValueG1Q2Title": "일일 미팅(Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments 또는 Daily EDITION)은 매일 고품격 서비스를 제공하기 위한 영감을 준다.",
    "weValueG1Q3Title": "내 근무 부서는 고객이 제기하지 않은 결함까지도 줄이는 데 집중한다.",
    "weValueG1Q4Title": "나는 먼저 허가를 구하지 않고도 고객의 문제를 해결할 권한이 있다.",
    "weValueG1Q5Title": "나는 먼저 허가를 구하지 않고도 고객을 기쁘게 할 권한이 있다.",
    "weValueG2Q1Title": "나는 내가 근무하는 호텔의 팀원들이 진심어린 서비스로 고객을 감동시키는 것을 자주 본다.",
    "weValueG2Q2Title": "나는 이 부서에서 배운 대로 고품격 서비스를 제공할 수 있게 되었다.",
    "weValueG2Q3Title": "나는 고품격 서비스를 제공하기 위한 지식과 스킬을 갖추었다는 확신을 갖고 있다.",
    "weValueG2Q4Title": "나의 근무 부서에서는 청결하고 결점이 없는 고급스러운 환경을 제공한다.",
    "weValueG2Q5Title": "나는 나의 근무 부서와 무관한 문제와 맞닥뜨리더라도 개입하여 고객의 우려를 해결할 권한이 있다.",
    "weValueG3Q1Title": "내가 근무하는 호텔의 팀원들은 고객과 동일한 수준의 보호를 받는다.",
    "weValueG3Q2Title": "내 근무 부서는 브랜드 가치를 상징한다.",
    "weValueG3Q3Title": "내가 품격 높은 고객 경험을 제공하기 위해 정해진 업무 이상의 일을 했을 때 그 점을 인정하고 높이 평가해준다.",
    "weValueG3Q4Title": "나는 업무 수준을 개선하기 위한 부서의 노력에 동참하고 있다.",
    "weValueG3Q5Title": "내 근무 부서에서는 항상 고객을 최우선으로 대우한다.",
    "submitButton": "제출하기",
    "phasetwo": {
      "weValueG1Q1Title": "마지막 근무 조의 일일 미팅에 참석하셨습니까 (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments 또는 Daily EDITION)?",
      "weValueG1Q2Title": "일일 미팅(Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments 또는 Daily EDITION)은 우리 팀원에게 매일 고품격 서비스를 제공하기 위한 영감을 준다.",
      "weValueG1Q3Title": "우리 호텔은 고객이 제기하지 않은 결함까지도 줄이는 데 집중한다.",
      "weValueG1Q4Title": "우리 호텔은 먼저 허가를 구하지 않고도 고객의 문제를 해결할 권한이 있다.",
      "weValueG1Q5Title": "우리 호텔은 먼저 허가를 구하지 않고도 고객을 기쁘게 할 권한이 있다.",
      "weValueG2Q1Title": "우리 호텔에서 우리는 진심어린 서비스로 고객을 감동시키는 것을 자주 본다.",
      "weValueG2Q2Title": "우리 호텔에서의 학습 기회 덕분에 고품격 서비스를 제공할 수 있었다.",
      "weValueG2Q3Title": "우리 호텔은 고품격 서비스를 제공하기 위한 지식과 스킬을 갖추었다는 확신을 갖고 있다.",
      "weValueG2Q4Title": "우리 호텔에서는 청결하고 결점이 없는 고급스러운 환경을 제공한다.",
      "weValueG2Q5Title": "우리는 우리의 근무 부서와 무관한 문제와 맞닥뜨리더라도 개입하여 고객의 우려를 해결할 권한이 있다.",
      "weValueG3Q1Title": "우리 호텔은 우리가 고객에게 서비스하는 것과 동일한 수준으로 서로 대우한다.",
      "weValueG3Q2Title": "우리 호텔은 브랜드 가치를 상징한다.",
      "weValueG3Q3Title": "우리 호텔은 우리가 품격 높은 고객 경험을 제공하기 위해 정해진 업무 이상의 일을 했을 때 그 점을 인정하고 높이 평가해준다.",
      "weValueG3Q4Title": "우리 호텔은 우리 부서의 업무 개선 노력에 참여할 수 있는 기회가 있다.",
      "weValueG3Q5Title": "우리 호텔은 항상 고객을 최우선으로 대우한다."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} 완료",
    "gatewayProgressComplete": "GATEWAY 진행 상황 완료",
    "heroMobileGatewayVideoText": "Gateway 동영상 재생",
    "basics": "기본",
    "brandResources": "브랜드 리소스",
    "disciplineResources": "분야 리소스",
    "immersionResourcesHeader": "브랜드 몰입 리소스",
    "brandCommunicationsHeaader": "브랜드 커뮤니케이션",
    "brandContactsHeader": "브랜드 연락처",
    "franchisedActionItemsText": "실행 과제는 체계적으로 업무를 익힐 수 있도록 도와주며, 주요 온보딩 정보를 제공해줍니다. 이러한 항목은 필수는 아니며, 프랜차이즈 관리 업체에서 받게 될 수 있는 추가 과정입니다.",
    "global": "글로벌",
    "continent": "대륙",
    "viewed": "시청함",
    "completed": "완료됨",
    "heading": "Gateway",
    "basicsTab": "기본 사항",
    "onboardingTab": "실행 과제",
    "brandTab": "브랜드 리소스",
    "disciplinesTab": "분야별 리소스",
    "basicsHeader": "메리어트 인터내셔널에 오신 것을 환영합니다",
    "basicsHeaderNewBrandOrRole": "환영합니다.",
    "basicsText": "업무를 시작하는 단계에서는 배워야 할 것이 많이 있습니다. 업무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 이 중 일부 항목은 오리엔테이션이나 브랜드 온보딩 단계에서 보다 심도 있게 배우게 되겠지만, 처음 시작하기에는 이 정도면 충분합니다.",
    "basicsTextGms": "업무를 시작하는 단계에서는 배워야 할 것이 많이 있습니다. 업무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 이 중 일부 항목은 오리엔테이션이나 브랜드 온보딩 단계에서 보다 심도 있게 배우게 되겠지만, 처음 시작하기에는 이 정도면 충분합니다. 기본 과정을 마친 후에는 실행 과제, 브랜드 리소스, 분야별 리소스를 포함한 기타 Gateway 페이지로 이동하세요.",
    "basicsTextFranchise": "앞으로 배워야 할 것이 많지만, 그 중에서도 업무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 기본 과정 항목에 대해서는 오리엔테이션이나 브랜드 온보딩, 프랜차이즈 관리 업체 제공 정보를 통한 추가 교육이 이루어질 수도 있습니다.",
    "basicsTextFranchiseGms": "앞으로 배워야 할 것이 많지만, 그 중에서도 업무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 기본 과정 항목에 대해서는 오리엔테이션이나 브랜드 온보딩, 프랜차이즈 관리 업체 제공 정보를 통한 추가 교육이 이루어질 수도 있습니다. 기본 과정을 마친 후에는 실행 과제, 브랜드 리소스, 분야별 리소스를 포함한 기타 Gateway 페이지로 이동하세요.",
    "basicsTextNewToRole": "새로운 직무를 시작하는 단계에서는 배워야 할 것이 많이 있습니다. 업무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 이 중 일부 항목은 필수 교육을 통해 보다 심도 있게 배우게 되겠지만, 처음 시작하기에는 이 정도면 충분합니다.",
    "basicsTextNewToRoleGms": "새로운 직무를 시작하는 단계에서는 배워야 할 것이 많이 있습니다. 업무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 이 중 일부 항목은 필수 교육을 통해 보다 심도 있게 배우게 되겠지만, 처음 시작하기에는 이 정도면 충분합니다. 기본 과정을 마친 후에는 실행 과제, 브랜드 리소스, 분야별 리소스를 포함한 기타 Gateway 페이지로 이동하세요.",
    "basicsTextNewToRoleFranchise": "앞으로 배워야 할 것이 많지만, 그 중에서도 새로운 직무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 기본 과정 항목에 대해서는 프랜차이즈 관리 업체 제공 정보를 통한 추가 교육이 이루어질 수도 있습니다.",
    "basicsTextNewToRoleFranchiseGms": "앞으로 배워야 할 것이 많지만, 그 중에서도 새로운 직무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 기본 과정 항목에 대해서는 프랜차이즈 관리 업체 제공 정보를 통한 추가 교육이 이루어질 수도 있습니다. 기본 과정을 마친 후에는 실행 과제, 브랜드 리소스, 분야별 리소스를 포함한 기타 Gateway 페이지로 이동하세요.",
    "basicsTextNewToBrand": "%{brandName}에서 새로운 직무를 시작하는 단계에서는 배워야 할 것이 많이 있습니다. 업무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 이 중 일부 항목은 오리엔테이션이나 브랜드 온보딩 단계에서 보다 심도 있게 배우게 되겠지만, 처음 시작하기에는 이 정도면 충분합니다.",
    "basicsTextNewToBrandGms": "%{brandName}에서 새로운 직무를 시작하는 단계에서는 배워야 할 것이 많이 있습니다. 업무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 이 중 일부 항목은 오리엔테이션이나 브랜드 온보딩 단계에서 보다 심도 있게 배우게 되겠지만, 처음 시작하기에는 이 정도면 충분합니다. 기본 과정을 마친 후에는 실행 과제, 브랜드 리소스, 분야별 리소스를 포함한 기타 Gateway 페이지로 이동하세요.",
    "basicsTextNewToBrandFranchise": "앞으로 배워야 할 것이 많지만, 그 중에서도 새로운 직무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 선정했습니다. 기본 과정 항목에 대해서는 오리엔테이션이나 브랜드 온보딩, 프랜차이즈 관리 업체 제공 정보를 통한 추가 교육이 이루어질 수도 있습니다.",
    "basicsTextNewToBrandFranchiseGms": "앞으로 배워야 할 것이 많지만, 그중에서도 %{brandName}에서 새로운 직무를 시작한 처음 며칠 동안 배워야 할 기본 사항 몇 가지를 엄선했습니다. 기본 과정 항목에 대해서는 오리엔테이션이나 브랜드 온보딩, 프랜차이즈 관리 업체 제공 정보를 통한 추가 교육이 이루어질 수도 있습니다. 기본 과정을 마친 후에는 실행 과제, 브랜드 리소스, 분야별 리소스를 포함한 기타 Gateway 페이지로 이동하세요.",
    "disciplineHeader": "분야별 리소스",
    "toDo": "필수",
    "optional": "선택",
    "overallComplete": "총 완료율",
    "keyResourcesDesc": "일상적인 호텔 운영, 고객 경험, 비즈니스 요구 사항에 관한 주요 분야에 대해 자세히 알아보세요.",
    "franchiseFooter": "본 자료에는 브랜드 표준 준수에 도움이 되는 권장 사항 및 권고 사례가 포함되어 있습니다. 메리어트는 가맹 본부로서, 가맹점 호텔 또는 비즈니스 운영의 어떤 측면에서도 관리나 운영에 관여하지 않습니다. 가맹점은 호텔의 일상적인 운영에 대한 통제권을 유지하고 직원 및 고용 관행에 대한 책임을 집니다.",
    "landingHeroTitle1": "메리어트 인터내셔널에 오신 것을 환영합니다.",
    "landingHeroTitle2": "여정을 시작해보세요.",
    "landingHeroText": "The Gateway는 새로운 직무를 맡은 첫 해에 여러분을 이끌어줄 안내 도구로서 성공적인 업무 수행을 위해 필요한 정보와 리소스를 제공해드립니다.",
    "landingHeroTitle1NewToRole": "The Gateway에 오신 것을 환영합니다.",
    "landingHeroTitle1NewToBrand": "The Gateway에 오신 것을 환영합니다.",
    "landingButtonText": "시작",
    "twoMonthslandingHeroTitle1": "환영합니다!",
    "twoMonthslandingHeroText": "아직 기본 과정을 모두 이수하지 않았다면 The Gateway에서 완료하세요!",
    "twoMonthslandingButtonText": "Gateway 들어가기",
    "twoMonthslandingHeroTitle1NewToBrand": "환영합니다!",
    "twoMonthslandingHeroTextNewToBrand": "%{brandName}과(와) 함께 한 처음 며칠이 즐거우셨길 바랍니다. 여정을 계속해 나가면서 그 과정에 필요한 모든 것을 The Gateway에서 얻을 수 있습니다.",
    "twoMonthslandingButtonTextNewToBrand": "진행",
    "threeMonthslandingHeroTitle1": "이제 준비되셨습니다!",
    "threeMonthslandingHeroText": "The Gateway에는 메리어트 인터내셔널에 대해 알아야 할 모든 것이 있습니다.",
    "threeMonthslandingButtonText": "Gateway 들어가기",
    "threeMonthslandingHeroTitle1NewToRole": "이제 준비되셨습니다!",
    "threeMonthslandingHeroTextNewToRole": "The Gateway에는 새로운 직무를 성공적으로 수행하기 위해 알아야 할 모든 것이 있습니다.",
    "threeMonthslandingButtonTextNewToRole": "Gateway 들어가기",
    "threeMonthslandingHeroTitle1NewToBrand": "환영합니다!",
    "threeMonthslandingHeroTextNewToBrand": "새로운 브랜드를 담당한 처음 %{numDays}일을 완료하신 것을 축하합니다. 이제는 필요할 때 언제든 실행 과제, 브랜드 리소스 및 분야 리소스를 모두 이용할 수 있습니다.",
    "threeMonthslandingButtonTextNewToBrand": "Gateway 들어가기",
    "fourMonthslandingHeroTitle1": "수고하셨습니다. 기본 과정을 모두 이수하셨습니다!",
    "fourMonthslandingHeroText": "The Gateway에서 메리어트 인터내셔널에 대해 알아야 할 모든 것을 확인하세요.",
    "fourMonthslandingButtonText": "Gateway 들어가기",
    "fourMonthslandingHeroTitle1NewToRole": "수고하셨습니다. 기본 과정을 모두 이수하셨습니다!",
    "fourMonthslandingHeroTextNewToRole": "새로운 직무를 성공적으로 수행하기 위해 알아야 할 모든 것을 The Gateway에서 확인하세요.",
    "fourMonthslandingButtonTextNewToRole": "Gateway 들어가기",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - 체크인",
    "fourMonthslandingHeroTextNewToBrand": "%{brandName} 담당 1년차가 마무리되어 가고 있습니다. 아직 완료하지 않은 실행 과제가 있다면 완료해 주시고, 본 Digital Learning Zone에 있는 개발 리소스를 활용하세요. 여정을 계속 진행하려면 '계속'을 클릭하세요.",
    "fourMonthslandingButtonTextNewToBrand": "계속",
    "gmSecondVisitlandingHeroTitle1": "환영합니다!",
    "gmSecondVisitlandingHeroText": "처음 며칠이 즐거우셨길 바랍니다. 새로운 직무에서의 역량 개발을 위한 여정을 계속해나가세요!",
    "gmSecondVisitlandingButtonText": "진행",
    "gmSecondVisitlandingHeroTextNewToRole": "새로운 직무를 맡은 처음 며칠이 즐거우셨길 바랍니다. 여정을 계속해 나가면서 그 과정에 필요한 모든 것을 The Gateway에서 얻을 수 있습니다.",
    "gmSecondVisitlandingHeroTextNewToBrand": "%{brandName}과(와) 함께 한 처음 며칠이 즐거우셨길 바랍니다. 여정을 계속해 나가면서 그 과정에 필요한 모든 것을 The Gateway에서 얻을 수 있습니다.",
    "gmThirdVisitlandingHeroTitle1": "환영합니다!",
    "gmThirdVisitlandingHeroText": "신입 사원 교육의 첫 %{numDays}일을 완료한 것을 축하드립니다! 모든 실행 과제, 브랜드, 분야 리소스는 언제든 필요할 때 이용할 수 있습니다.",
    "gmThirdVisitlandingButtonText": "Gateway 들어가기",
    "gmThirdVisitlandingHeroTextNewToRole": "새로운 직무를 시작한 첫 %{numDays}일을 완료한 것을 축하드립니다! 모든 실행 과제, 브랜드, 분야 리소스는 언제든 필요할 때 이용할 수 있습니다.",
    "gmThirdVisitlandingHeroTextNewToBrand": "새로운 브랜드를 담당한 처음 %{numDays}일을 완료한 것을 축하드립니다. 모든 실행 과제, 브랜드, 분야 리소스는 언제든 필요할 때 이용할 수 있습니다.",
    "gmSevenMonthslandingHeroTitle1": "아직 완료하지 않은 실행 과제가 있습니다.",
    "gmSevenMonthslandingHeroText": "입사 1년차가 마무리되어감에 따라, 아직 완료하지 않은 실행 과제가 있는 경우 완료해주시고 본 Digital Learning Zone에 있는 개발 리소스를 활용하세요. 여정을 계속 진행하려면 ‘계속’을 클릭하세요.",
    "gmSevenMonthslandingButtonText": "계속",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - 체크인",
    "gmSevenMonthslandingHeroTextNewToRole": "새로운 직무를 맡은 지 1년이 되어감에 따라, 아직 완료하지 않은 실행 과제가 있는 경우 완료해주시고, 본 Digital Learning Zone에 있는 개발 리소스를 활용하세요. 여정을 계속 진행하려면 ‘계속’을 클릭하세요.",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - 체크인",
    "gmSevenMonthslandingHeroTextNewToBrand": "%{brandName} 담당 1년차가 마무리되어 가고 있습니다. 아직 완료하지 않은 실행 과제가 있다면 완료해 주시고, 본 Digital Learning Zone에 있는 개발 리소스를 활용하세요. 여정을 계속 진행하려면 '계속'을 클릭하세요.",
    "gmOneYearlandingHeroTitle1": "%{brandName}을(를) 담당한 지 1년이 된 것을 축하합니다!",
    "gmOneYearlandingHeroTitle1NewToRole": "새로운 직무를 맡은 지 1년이 된 것을 축하드립니다!",
    "gmOneYearlandingHeroTitle1NewToBrand": "%{brandName}의 새로운 직무를 맡은 지 1년이 된 것을 축하드립니다!",
    "gmOneYearlandingHeroText": "The Gateway 여정은 이제 끝나지만 더욱 흥미진진한 일들이 여러분을 기다립니다. The Gateway 탭에서 언제든지 리소스를 이용할 수 있습니다.",
    "gmOneYearlandingButtonText": "Gateway 보기"
  },
  "resources": {
    "iptTooltip": "위치가 여러 개인 경우 쉼표로 구분하세요.",
    "iptAllFunctionTooltip": "특정 직무를 선택하려면 선택을 해제하세요. 선택한 위치의 모든 직무 교육을 보려면 선택 상태를 유지하세요.",
    "iptAllAWRTooltip": "특정 추가 담당 업무를 선택하려면 선택을 해제하세요. 선택한 위치에 대한 모든 추가 담당 업무 관련 교육을 보려면 선택 상태를 유지하세요.",
    "editJFTooltip": "입력 항목이 여러 개인 경우 쉼표로 구분하세요."
  },
  "Notification Center: ": "읽지 않은 상태로 표시",
  "Notification Center: Empty": "최신 정보로 업데이트되었습니다!",
  "Notification Center: Individual Delete": "이 알림을 삭제하시겠습니까? 삭제된 알림은 만료될 때까지 알림 센터의 삭제됨 섹션으로 이동합니다.",
  "Notification Center: Delete All": "모든 알림을 삭제하시겠습니까? 만료될 때까지 알림 센터의 삭제됨 섹션으로 이동됩니다.",
  "Notification Center: Mark All As Read": "모두 읽은 상태로 표시",
  "Notification Center: Restore All": "모두 복원",
  "Notification Center: Delete Confirmation": "삭제",
  "Warning Message Text: Remove Job Function or AWR": "직무 또는 추가 업무 책임(AWR)을 삭제하시겠습니까?\r\n제출할 경우 학습자 %{count}명의 %{Function and/or AWR}이(가) 삭제됩니다.",
  "Warning Message Text: Add Job Function or AWR": "직무 또는 추가 업무 책임(AWR)을 추가하시겠습니까?\r\n제출할 경우 학습자 %{count}명에 대해 %{Function and/or AWR}이(가) 추가됩니다.",
  "Button": "제출",
  "Success Message Text: Add Job Function and/or AWR": "성공\r\n%{location}의 요청한 직원에 대해 %{Job Function and/or AWR}을(를) 추가했습니다.\r\n\r\n%{location}에 대해 추가 직무 또는 추가 업무 책임을 편집하시겠습니까?",
  "Message Text:AWR": "성공\r\n%{ __}의 요청한 직원에 대해 추가 업무 책임 %{ __}을(를) 추가했습니다.\r\n\r\n%{location, property, learner(s)}에 대해 추가 직무 또는 추가 업무 책임을 편집하시겠습니까?",
  "Success Message Text: Remove Job Function": "성공\r\n%{location}의 요청한 직원에 대해  %{Job Function and/or AWR}을(를) 삭제했습니다.\r\n\r\n%{location}에 대해 추가 직무 또는 추가 업무 책임을 편집하시겠습니까?",
  "Success Message Text: Remove AWR": "성공\r\n%{ __}의 요청한 직원에 대한 추가 업무 책임 %{ __}을(를) 삭제했습니다.\r\n\r\n%{location, property, learner(s)}에 대해 추가 직무 또는 추가 업무 책임을 편집하시겠습니까?",
  "Tool Tip - Managed Change Email": "이메일은 mHUB > HR Direct > 나 > 내 공개 정보에서 변경할 수 있습니다. 지침에 따라 연락처 세부 정보를 업데이트하십시오. 업데이트된 이메일이 표시되기까지 1~2영업일이 걸릴 수 있습니다.",
  "Tool Tip - Franchised  Change Email": "이메일을 변경하려면 관리자 및/또는 프랜차이즈 EID Tool 관리자에게 문의하십시오. 업데이트된 이메일이 표시되기까지 1~2영업일이 걸릴 수 있습니다.",
  "User Profile: Email Opt-in": "아니요, 괜찮습니다.",
  "User Profile: Email Frequency": "주간 요약",
  "Generic Notification Email Subject Line:": "DLZ에 새로운 알림이 있습니다.",
  "Generic Body Text (<150 Characters)": "자세한 내용은 DLZ에서 확인하십시오.",
  "All Emails: Signature": "Digital Learning Zone - 간편해진 학습. 여러분과 함께 시작됩니다.",
  "All Emails: Footer": "언제든지 구독 취소/이메일 알림 수신 거부를 할 수 있습니다. 기본 설정은 Digital Learning Zone/프로필에서 업데이트할 수 있습니다.",
  "Overdue Emails: Subject Line: ": "확인해야 하는 DLZ 과제",
  "Overdue Emails: Body Test": "기한이 지난 과제가 있습니다. DLZ에 로그인하여 과제를 확인하십시오.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "기술을 연마하세요",
    "sharpenYourSkillsSubTitle": "현재와 미래의 경력 향상을 위해 지식과 기술을 구축해 보세요.",
    "growYourCareerTitle": "경력을 키우세요",
    "growYourCareerSubTitle": "고유의 관심사와 목표를 뒷받침하는 전문화된 기술 또는 교차 훈련으로 다음 행보를 계획해 보세요.",
    "additionalResourcesTitle": "추가 리소스",
    "additionalResourcesSubTitle": "getAbstract®와 CultureWizard™을 살펴보세요. DLZ가 계속해서 성장을 도울 수 있는 방식을 확인할 수 있습니다.",
    "tourHeader": "업데이트된 DLZ에 오신 것을 환영합니다. 이제 필요한 것이 무엇이든, 필요할 때 검색하여 찾을 수 있습니다. 또한, 업데이트된 \"학습 탐색\"과 더욱 맞춤화된 \"내 플랜\"을 찾아볼 수 있습니다. 둘러보세요!",
    "tourBtnSkip": "닫기",
    "tourBtnNext": "닫기(6/6)",
    "tourSearchBar": "새롭고 더욱 견고해진 검색 기능으로 필요할 때 필요한 것을 찾아보세요.",
    "tourBtnBack": "뒤로",
    "tourMyPlan": "\"내 플랜\"에서는 이제 \"검색\" 및 \"탐색\"에서 추가한 항목을 볼 수 있어, 학습 여정을 전체적으로 컨트롤하고 관리할 수 있습니다.",
    "tourExploreLearningLink": "여기에서도 계속해서 \"학습 탐색\"을 시작할 수 있습니다!",
    "tourExploreLearningProfile": "학습 경험을 최대한 활용하고 정기적으로 프로필, 특히 직무를 확인하여 \"내 플랜\"을 최신 상태로 유지하세요.",
    "mhubTraining": "mHUB 교육",
    "growthSkills": "성장 기술",
    "leadershipSkills": "리더십 기술",
    "virtualTeamResources": "가상 팀 리소스",
    "jobFunctions": "직무",
    "brands": "브랜드",
    "languageLearning": "언어 학습",
    "become": "인재로 성장하기",
    "emeaMentoringProgram": "EMEA 멘토링 프로그램",
    "learningPathways": "학습 과정",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare Community Hub",
    "mhubTrainingDesc": "mHUB 사용 방법에 대한 역할별 학습 경로와 주제별 교육과정을 찾아보세요.",
    "growthSkillsDesc": "다양한 기능과 주제에 걸쳐 실용적인 지식을 획득하세요.",
    "leadershipSkillsDesc": "팀 관리 및 코칭 방법을 알아보고 더욱 유능한 리더가 되어보세요.",
    "virtualTeamResourcesDesc": "가상 팀 내에서 협력적이고 포용적인 문화를 기를 수 있는 기법을 학습해보세요.",
    "jobFunctionsDesc": "현재 직무의 지식을 확장하고 타인에 대해 배워보세요.",
    "brandsDesc": "메리어트 인터내셔널의 다양한 브랜드에 대해 자세히 알아보세요.",
    "languageLearningDesc": "글로벌 고객과 효과적으로 소통해보세요.",
    "becomeDesc": "경력 성장 및 개발에 대한 총체적 접근법인 \"성찰하기, 계획하기, 실행하기, 구체화하기\"를 확인해 보세요.",
    "emeaMentoringProgramDesc": "EMEA 멘토링 프로그램으로 잠재력을 드러내 보세요.",
    "learningPathwaysDesc": "리더십 수준에 맞게 조정된 특정 학습 기회를 살펴보세요.",
    "mdaDesc": "Marriott Development Academy로 리더십 기술과 직무 기술을 구축해 보세요.",
    "takeCareGrowDesc": "포용적이고 영감이 넘치는 커뮤니티를 구축하도록 설계된 리소스를 파악해 보세요.",
    "getAbstractDesc": "더 나은 결정을 내리는 데 도움이 되는 도서 요약본으로 매일 새로운 것을 배워보세요.",
    "cultureWizardDesc": "다양한 문화에 걸쳐 효과적으로 소통할 수 있는 가치 있는 지식을 모아보세요.",
    "takeCareCommunityHubDesc": "TakeCare 팀 구축 활동, 학습 프로그램, 도구를 확인해 보세요.",
    "searchAllLearning": "모든 학습 검색",
    "searchBarPlaceHolder": "언제나 배울 것이 더 많이 있습니다.",
    "clearFilters": "필터 지우기",
    "noResults": "결과 없음",
    "hasBeenUnpinned": "{Variable-tile_Name}이(가) 고정 해제되었습니다.",
    "hasBeenPinned": "{Variable-tile_Name}이(가) 고정되었습니다.",
    "lpRemovedFromPlan": "학습 과정이 계획에서 제거되었습니다.",
    "courseRemovedFromPlan": "과정이 계획에서 제거되었습니다.",
    "lpAddedToPlan": "학습 과정이 계획에 추가되었습니다.",
    "courseAddedToPlan": "과정이 계획에 추가되었습니다.",
    "clearSelection": "선택 지우기",
    "removeFromMyPlan": "내 계획에서 제거",
    "addToMyPlan": "내 계획에 추가",
    "loadingResults": "로딩 중",
    "viewDetails": "세부 정보 보기",
    "clearAll": "모두 지우기",
    "noOptions": "이용 가능한 옵션 없음",
    "viewLess": "간단히 보기",
    "viewMore": "자세히 보기",
    "recentSearch": "최근 검색 내역",
    "exploreLearningTitle": "학습 탐색",
    "exploreLearningHelpText": "찾고 있는 것이 확실하지 않습니까?",
    "exploreMore": "더 자세히 알아보세요.",
    "viewAllResults": "<#>개 결과 모두 보기",
    "searchSubTitle": "키워드, 제목 또는 콘텐츠 ID를 사용하여 과정을 검색하세요.",
    "pinnedLearningTitle": "내 고정된 학습",
    "landingSubTitle": "학습 여정을 소유하세요. 관심 항목을 살펴보세요. 새 주제 및 과정이 항상 추가됩니다.",
    "columnTitle": "제목",
    "columnDesc": "설명",
    "columnType": "유형",
    "columnAddedToPlan": "계획에 추가(됨)",
    "columnComplete": "완료",
    "columnDetails": "상세 정보",
    "additionalSearchFilters": "추가 검색 필터",
    "tourBtnClose": "닫기",
    "tourBtnLast": "닫기",
    "resultsFor": "에 대한 결과",
    "resultsFortheFilter": "{Filter Name} 필터에 대한 결과",
    "no": "아니요",
    "results": "결과",
    "courseTooltipTitle": "개인 학습 항목은 하나 이상의 활동을 포함할 수 있습니다.<br />과정은 동영상 또는 이러닝으로 진행되거나 강의실에서 진행될 수 있습니다.",
    "gatewayToolTipTitle": "신입사원, 관리직 신규, 브랜드를 처음 접하는총지배인을 위한 디지털 리소스입니다.",
    "speciallizationOrPathwayTooltipTitle": "특정 주제에 중점을 둔 일련의 과정입니다.",
    "taskTooltipTitle": "지침을 통해 활동(일반적으로 현장 활동)을 완료하고, 완료했음을 표시합니다.",
    "webSiteTooltipTitle": "메리어트 사이트 또는 외부 웹사이트를 포함한 모든 웹사이트."
  },
  "dontShowAgain": "다시 표시 안 함",
  "deletionWarningEnabled": "삭제 경고 활성화",
  "deletionSelected": "이 옵션을 선택하면 알림을 삭제할 때마다 경고가 표시됩니다.",
  "manageEmailNotifications": "이메일 알림 관리",
  "seeHowDLZContinue": "DLZ가 계속해서 성장을 도울 수 있는 방식을 확인할 수 있습니다.",
  "Notification Center:Overdue": "{Variable date ddmmyy}에 마감된 새로운 교육이 하나 있습니다.",
  "accesDenied": {
    "title": "학습 여정에 대한 액세스가 진행 중입니다!",
    "subtitle": "액세스를 생성 중이며, EID가 활성화되고 나서 1~2일 후에 이용할 수 있습니다. 2일 이상이 소요되면 mHUB 서비스 센터에 문의하십시오."
  },
  "jfTour": {
    "title": "이제 \"내 계획\"에서 선택한 직무 및 추가 업무 책임(AWR)을 직접 확인하고 편집할 수 있습니다."
  },
  "onboardingPlan": {
    "requiredDescription": "아래는 \"내 계획\"에서 선택한 기간 내에 완료하기 위한 필수 과정입니다. 리더와 함께 새로운 직무를 찾아볼 때 필수 학습을 완료하기 위한 시간을 예약하세요. 다양한 주제에 대해 배우게 될 것이며, 도중에 궁금한 점이 있으면 반드시 리더 및 동료에게 연락하시기 바랍니다.   ",
    "basicsDescription": "앞으로 배워야 할 것이 많지만 그중에서도 업무를 시작한 처음 며칠 동안 알아둬야 할 기본 과정들을 엄선했습니다. 기본 과정 항목에 대해서는 오리엔테이션이나 브랜드 온보딩, 프랜차이즈 관리 업체 제공 정보를 통한 추가 교육이 이루어질 수도 있습니다. 저희와 함께 일하는 동안 이것이 좋은 리소스가 될 것이며, 새로운 팀원들을 데려오는 데 유용할 것입니다.",
    "recommendedDescription": "처음 90일이 지나고 나면 매니저가 추천한 추가 과정을 찾을 수 있습니다. 또한, 관심 있는 주제를 스스로 탐색할 수도 있습니다. 지금은 기초 학습에 집중하세요.",
    "day1": "14일 마감",
    "day2": "30일 마감",
    "day3": "60일 마감",
    "day4": "90일 마감",
    "day5": "90일 후 마감 "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone은 전문적인 학습과 발전을 위한 여러분의 출발점입니다. 아래에는 시작하는 데 도움이 되도록 전문적인 식견으로 엄선한 콘텐츠가 있습니다. 모든 학습 내용은 항상 \"내 계획\"에서 확인할 수 있습니다.  ",
    "overallLearningProgress": "전체 학습 진행 상황",
    "learningProgressLabel": "학습 진행 상황",
    "learningProgressDescription": "다음은 필수 학습을 진행하는 방법입니다. 이 진행 상황 표시줄에는 필수 학습의 모든 상태가 포함됩니다. 계속해서 잘 진행해주시기 바랍니다!"
  },
  "poc": {
    "dlz": "이 학습을 완료하려면 매니저 또는 학습 코디네이터가 mHUB에서 이 학습을 완료로 표시해야 합니다. 이렇게 하는 방법에 대한 자세한 내용은 실행 가이드에서 확인할 수 있습니다.",
    "mhub": "가맹점: 본인 소유의 기초 청소 교육 프로그램을 사용하기로 한 경우, 완료를 표시할 때 댓글 섹션에 프로그램 이름과 상세 내용을 기재해 주시기 바랍니다."
  },
  "Recommended Courses ": "처음 90일이 지나고 나면 매니저가 추천한 추가 과정을 찾을 수 있습니다. 또한, 관심 있는 주제를 스스로 탐색할 수도 있습니다. 지금은 기초 학습에 집중하세요.",
  "survey": {
    "v2": {
      "weValueButtonText": "설문 조사에 참여 해주세요",
      "lbiIntro": "‘품위 있는 행동’ 체크인 코스에 오신 것을 환영합니다!",
      "lbiDescription": `<p>아래에서 호텔에서 발생할 수 있는 몇 가지 간단한 시나리오를 읽어보라는 요청을 받게 됩니다. 시나리오를 읽고 각 상황에 어떻게 대응할지 선택하세요. 답을 할 때는 다음 사항에 유의해야 합니다.</p>
      <ul>
          <li>
              <p>하나 이상의 답변이 정답으로 보일 수 있습니다. 현재 근무하고 있는 호텔에서 취할 가능성이 가장 높은 답을 선택하세요.</p>
          </li>
          <li>
              <p>의사소통에 어려움이 없다고 가정합니다. 여러분이 사용하는 언어는 시나리오에서 투숙객이나 직원이 사용하는 언어와 같습니다.</p>
          </li>
          <li>
              <p>시나리오에서는 현재 맡고 계신 역할과 다른 업무를 맡고 있다고 가정해야 할 수 있습니다. 최고급 호텔 응대 전문가로서의 경험을 바탕으로, 알고 있는 범위 내에서 질문에 답하시면 됩니다.</p>
          </li>
      </ul>
      <p><br></p>
      <p>유의사항: 이 체크인 활동에 대한 참여는 전적으로 자발적인 의사에 따르며(참여하지 않을 수 있음), 여러분의 답변은 엄격하게 기밀로 유지됩니다. 이 체크인 결과는 개별 직원이 아닌 그룹 단위로 보고됩니다. 연구팀에서 개별 응답을 평가하겠지만 다른 관리자나 리더와 답변이 공유되지 않습니다.</p>
      <p>이 체크인에 참여함으로써 여러분은 개별 답변 및 데이터(예: 근속 기간, 성별, 직책)의 수집 및 처리와, 개인 데이터의 국외 이전(메리어트 그룹)에 동의하는 것으로 간주합니다. 이는 메리어트 직원 개인정보 처리방침에 따르며 메리어트 개인정보 처리방침에서 추가 정보 및 연락처를 확인할 수 있습니다.</p>
      `,
      "checkin": {
        q1Title: "로비를 지나가다가 세 명의 어린 자녀가 있는 가족을 발견합니다. 짐이 많고, 긴 여행으로 인해 스트레스를 받은 표정이며, 아이들은 칭얼대고 눈에 띄게 불안해합니다. 어떻게 하시겠습니까?",
        q2Title: "여러분은 익숙하지 않은 지역에서 이제 막 호텔 업무를 시작했습니다. 투숙객이 근처 즐길 거리를 추천해 달라고 요청합니다. 투숙객은 여러 가이드북을 읽었지만 좀 더 개인화된 무언가를 찾고 있습니다. 어떻게 하시겠습니까?",
        q3Title: "로비를 지나가던 중 게스트의 전화 통화 내용을 듣게 되는데, 공항으로 향하는 차량 서비스가 늦어지고 있다는 사실을 알게 됩니다. 호텔에 하우스 카가 없거나 이용할 수 없습니다. 어떻게 하시겠습니까?",
        q4Title: "매니저가 지시한 긴급한 업무를 처리하기 위해 로비를 지나가고 있습니다. 보통 두 사람이 함께하는 업무를 동료 직원 혼자서하며 힘들어하는 모습을 발견합니다. 어떻게 하시겠습니까?",
        q5Title: "여러분은 호텔 레스토랑 호스트입니다. 투숙객이 예약된 테이블을 기다리는 동안 메뉴를 보고 싶다고 말합니다. 다른 동료의 추천으로 호텔 레스토랑을 찾은 투숙객이 메뉴를 살펴본 후 자기 취향에 맞는 메뉴가 없어서 실망합니다. 어떻게 하시겠습니까?",
        q6Title: "혼잡한 수영장 구역에 사람이 너무 많아서 단체 투숙객이 함께 앉을 수 있는 자리를 찾지 못했습니다. 그로 인해 수건과 개인 소지품을 주요 통로를 가로막게 놓아 수영장을 오가는 투숙객과 직원들이 다니기 불편하고 위험한 상황이 발생했습니다. 통로를 확보해야 하지만 단체 투숙객의 기분을 상하게 하고 싶지는 않습니다. 어떻게 하시겠습니까?",
        q1R1Title: "족의 짐을 들어 드리고, 체크인 데스크의 방향을 안내하고, 온 가족이 편안하게 근처에서 즐길 수 있는 액티비티 몇 개를 추천합니다. 호텔 내 가족용 부대시설을 안내합니다. ",
        q1R2Title: "가족의 짐을 들어 드리고 체크인까지 직접 안내합니다. 그런 다음 부부를 위해서는 편히 쉴 수 있는 서비스나 간식을, 아이들을 위해서는 객실에서 즐길 수 있는 재미있는 활동을 준비합니다. ",
        q1R3Title: "부부가 체크인할 수 있도록 안내하고, 체크인하는 동안 간식과 가지고 직접 아이들을 좌석 공간으로 데려갑니다. 프런트 데스크에서 부부의 편의를 위한 호텔 내 놀이 공간과 어린이 액티비티를 안내하도록 합니다.",
        q1R4Title: "빠른 체크인을 위해 가족을 체크인 데스크 쪽으로 안내합니다. 아이들이 즐거운 시간을 보낼 수 있도록 부모에게 키즈 공간 및 서비스를 안내합니다.",
        q2R1Title: "게스트에게 좋아하는 것을 물어보고 동료 직원과 함께 게스트를 위해 재밌는 활동 목록을 작성합니다. 추가로 인근의 멋진 레스토랑도 추천합니다.",
        q2R2Title: "투숙객이 무엇을 좋아하는지 대화를 나눕니다. 동료 직원과 함께 게스트만을 위한 즐길 거리를 적은 특별 목록을 만듭니다. 추가로 투숙객의 본보이 프로필을 확인하고 객실로 맞춤형 간식을 보냅니다.",
        q2R3Title: "지금까지 이 지역에서 개인적으로 경험한 활동 중 가장 즐거웠던 몇 가지를 공유합니다. 여러분의 개인적인 경험을 좀 더 자세히 설명하여 여러분의 제안을 더욱 특별하게 만듭니다. 그런 다음 맛있는 현지 간식을 객실로 보냅니다.",
        q2R4Title: "투숙객에게 여기에 온 지 얼마 되지 않아 잘 모른다고 설명합니다. 양해를 구하고 더 많이 알고 있는 동료 직원과 대화하도록 제안합니다. 그런 다음 현지 간식을 객실로 보내도 괜찮은지 여쭤봅니다.",
        q3R1Title: "투숙객이 계속 통화하는 동안 본인이나 동료 직원의 개인 차량을 호텔 앞으로 가져와서 해당 투숙객에게 공항까지 직접 데려다주겠다고 말합니다.",
        q3R2Title: "투숙객이 통화하는 동안 호텔에서 승인한 차량 서비스 및 전화번호를 목록으로 정리합니다. 투숙객이 전화를 끊으면 목록을 제시하고 교통편 예약을 도와 드리겠다고 안내합니다.",
        q3R3Title: "투숙객이 전화를 끊을 때까지 기다렸다가 호텔에서 교통편 서비스를 제공하지 않지만, 다른 교통편을 알아볼 수 있도록 도움을 드리겠다고 안내합니다.",
        q3R4Title: "투숙객이 통화하는 동안 이용할 수 있는 대체 교통편을 찾습니다. 통화가 끝나면 투숙객에게 공항까지 교통편을 예약했으며 곧 차량이 도착할 것이라고 안내합니다.",
        q4R1Title: "동료에게 다가가 먼저 급한 업무를 처리한 다음에 도와주겠다고 말하고는  최대한 빨리 다시 와서 돕겠다고 약속합니다. 매니저와 상황을 의논합니다.",
        q4R2Title: "우선, 처리해야 하는 업무를 맡아 줄 다른 동료를 빨리 찾습니다. 그런 다음 도움이 필요한 동료를 바로 돕습니다. 업무를 완료한 후 매니저에게 다른 동료를 돕기 위해 해당 업무를 대신할 다른 동료를 찾았다고 말합니다.",
        q4R3Title: "즉시 멈춰서 동료를 돕습니다. 동료를 도운 후에는 처리해야 하는 업무에 복귀하고, 매니저에게 동료를 돕느라 처리가 늦어졌다고 말합니다.",
        q4R4Title: "도움이 필요한 동료에게 가서 여러분은 급한 업무를 처리해야 한다고 설명합니다. 두 업무를 모두 중지하고 함께 처리할 방법을 궁리한 후 두 업무를 함께 완료합니다.",
        q5R1Title: "투숙객에게 사과하고 매니저가 곧 올 것이라고 말합니다. 매니저가 투숙객이 원하는 음식에 대해 도움을 줄 수 있도록 상황을 설명합니다.",
        q5R2Title: "투숙객에게 사과하고 선호하는 음식에 대해 질문합니다. 주방팀과 상의하여 투숙객을 위한 특별한 요리를 만듭니다. 또한, 실수를 만회할 수 있도록 무료로 애피타이저나 음료를 제공합니다.",
        q5R3Title: "사과하고 충분히 이해한다고 공감을 표시합니다. 예약을 취소하는 대신 셰프와 상의하여 투숙객을 위한 특별한 요리를 만들어줄 수 있는지 알아봅니다. 실수를 만회하기 위해 무료 간식이나 할인을 제공합니다.",
        q5R4Title: "사과하고 투숙객이 좋아하는 음식을 제공하는 인근 레스토랑을 예약할 수 있도록 도와드리겠다고 말합니다. 필요한 경우 추가 정보나 목적지까지 도움을 제공합니다.",
        q6R1Title: "공간 부족에 대해 투숙객에게 사과하고 여기에 계속 계셔도 되지만 다른 투숙객들이 지나다닐 수 있도록 자리를 좀 정리해 달라고 부탁합니다.",
        q6R2Title: "투숙객에게 통로를 막으면 안 된다고 친절하게 설명하고, 다른 자리를 찾거나 공간이 생길 때까지 기다려 달라고 말합니다. 한편 동료 직원에게 투숙객들이 사용할 수 있도록 연회장에서 추가 좌석을 가져다 놓아달라고 요청합니다.",
        q6R3Title: "투숙객에게 현재 위치에 있을 수 없다고 말하고, 다른 좌석을 찾아 드리는 동안 잠시 자리를 옮겨야 한다고 안내합니다. 그동안 바/레스토랑의 공간을 제공합니다. ",
        q6R4Title: "좌석 문제에 대해 투숙객에게 사과합니다. 다른 사람들이 지나다닐 수 있도록 통로를 만들기 위해 개인 물품을 한 곳으로 정리해 달라고 요청합니다. 정리를 돕겠다고 제안하고 모두 편하신지 확인합니다. 불편을 겪은 투숙객에게 바/레스토랑에서 특별한 간식을 제공합니다."
      }
    }
  },
};