export default {
  "banner": {
    "viewDetails": "Bheka Imininingwane",
    "requiredCourses": "Izifundo zakho ezidingekayo zidingeka ungakashayi umhlaka",
    "days": "izinsuku"
  },
  "header": {
    "plan": "Uhlelo Lwami",
    "manager": "UMLAWULI",
    "explore": "ZINIKE ISIKHATHI SOKUFUNDA",
    "reporting": "UKUBIKA",
    "myTeam": "IQEMBU LAMI",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "UHLELO LWAMI",
    "manager": "UMLAWULI",
    "explore": "ZINIKE ISIKHATHI SOKUFUNDA",
    "tagline": "Ukufunda kwenziwe lula. Kuqala ngawe."
  },
  "welcome": {
    "hi": "Sawubona",
    "intro1": "Uyamukelwa Kwi-Digital Learning Zone! I-DLZ iwuhlelo olulungiselelwe lokufunda noluyisizinda esiyinhloko sokufunda kwa-Marriott. Thola ukuqeqeshwa okudingayo komsebenzi wakho futhi wandise amathuba okufunda ukuze uzithuthukise wena kanye nomsebenzi wakho. Lawula indlela yakho yokufunda uqale inkambo yokuthuthukisa amakhono akho.",
    "intro2": "Ngaphambi kokuba uqale, sidinga ukwazi izinto ezimbalwa ngawe.",
    "letsGo": "ASIQALE KE",
    "preferredLanguage": "Yiluphi ulimi oluthandayo?",
    "step": "ISINYATHELO",
    "of": "SOKUTHI",
    "previous": "OKUDLULE",
    "next": "OKULANDELAYO",
    "startDate": "Uqale nini ukusebenza e-Marriott International noma e-Starwood?",
    "selectYear": "KHETHA UNYAKA",
    "selectMonth": "KHETHA INYANGA",
    "selectDay": "KHETHA USUKU",
    "selectFunction": "KHETHA UMSEBENZI",
    "selectInterests": "KHETHA OKUTHANDAYO",
    "chooseYear": "KHETHA UNYAKA",
    "chooseMonth": "KHETHA INYANGA",
    "chooseDay": "KHETHA USUKU",
    "whatDo": "Yini oyenzayo emsebenzini?",
    "selected": "OKUKHETHIWE",
    "searchFunctions": "Sesha yonke imisebenzi",
    "searchInterests": "Sesha Konke Okuthandayo",
    "additionalJobFunctionsLabel": "Sitshele okwengeziwe ngomsebenzi wakho",
    "managementLevelLabel": "Lithini izinga lobudlelwano bakho?",
    "preferredBrandLabel": "Uma usebenzela i-brand engaphezu kweyodwa, sicela ukhethe onke owasebenzelayo.",
    "additionalJobFunctionsLabelExtra": "Sicela ubukeze okulandelayo futhi UKHETHE OKUNGENANI OKUKODWA okuyisibopho somsebenzi okwengeziwe",
    "searchAdditionalFunctions": "Sesha zonke izibopho zomsebenzi ezengeziwe",
    "highGuestContactLabel": "Ingabe uphezulu ohlwini lwalabo izihambi ezixhumana nabo.",
    "yes": "Yebo",
    "no": "Cha "
  },
  "landing": {
    "started": "Asiqale",
    "welcomeBack": "Siyakwamukela futhi,",
    "greatWork": "Umsebenzi omuhle",
    "requiredCourses": "Izifundo Zakho Ezidingekayo",
    "requiredCoursesDesc": "Sicela uqedele izifundo ngaphambi kosuku lokugcina olubekiwe.",
    "myPlan": "Uhlelo Lwami",
    "courses": "Izifundo",
    "courseComplete": "ISIFUNDO SIQEDIWE",
    "viewMore": "BHEKA EZINYE IZIFUNDO EZIDINGEKAYO",
    "viewMoreBtn": "BHEKA EZINYE IZIFUNDO EZININGI",
    "showing": "KUKHANGISWA",
    "of": "NGAZO",
    "heroTitle": "Funda okuthile okusha. Yandisa ulwazi lwakho. Thola amakhono angakusiza ekuphileni kwakho.",
    "heroParagraph1": "Landelela kalula zonke izifundo zakho ezidingekayo bese uthola izaziso zesikhathi sangempela ukuze uziqede.",
    "heroParagraph2": "Jabulela indlela yokufunda esekelwe emsebenzini wakho nakulokho okuthandayo.",
    "recommendedCoursesTitle": "Izifundo Okutuswa Ukuba Uzenze",
    "recommendedCoursesDescNoContent": "Njengamanje awunazo izifundo okutuswa ukuba uzenze. Sicela ubheke ngezansi ukuze uhlole amanye amathuba okufunda.",
    "recommendedCoursesDesc": "Buka ukuthi umlawuli wakho noma uchwepheshe we-HR ukukhetheleni.",
    "recommendedCoursesBtn": "BUKA IZIFUNDO OKUTUSWA UKUBA UZENZE",
    "completedCoursesBtn": "BUKA IZIFUNDO EZIQEDIWE",
    "exploreTitle": "Hlala unelukuluku.",
    "exploreDesc": "Khetha izihloko ozithandayo.",
    "jobFunctions": "Iziyalo",
    "growthSkills": "Amakhono Okukhula",
    "leadershipSkills": "Amakhono Obuholi",
    "brands": "Imikhiqizo",
    "languageLearning": "Ukufunda Ulimi",
    "exploreBtn": "BUKA IZIFUNDO",
    "managerRecommendation": "IZIPHAKAMISO ZOMLAWULI",
    "learningRecommendation": "IZIPHAKAMISO ZOKUFUNDA",
    "marriottRecommendation": "IZIPHAKAMISO ZE-MARRIOTT",
    "completedCourses": "Uqede zonke izifundo zakho ezidingekayo!",
    "investTitle": "Lawula Indlela Yakho Yokufunda",
    "investParagraph1": "Siyabona ukuthi izimo zamanje zinzima ngezindlela eziningi - - i-DLZ ilapha ingumthombo ngolwazi. Noma ngabe ufuna ukugcina amakhono akho ekhona namanje, funda okuthile okusha, phatha ukucindezela kwakho, noma ufunde ukusebenza neqembu langempela. Siqoqe izinsiza zokusiza.",
    "updatedTopics": "Izihloko ezibuyekeziwe",
    "workingRemotely": "Izinsiza zeqembu etholakala ngempela",
    "relatedCoursesNew": "Chofoza lapha ngezansi ukubuyekeza izintshisekelo zakho, njengoba sengeze izihloko ezintsha nezifundo.",
    "relatedCourses": "Sicela uchofoze ngezansi ukukhetha izifundo ozithandayo.",
    "notificationText": "Ukuqeqesha okusha kwe-mHUB kuyatholakala. Khetha i-mHUB ngaphansi kokuthi Explore Learning.",
    "mHubHeroTitle1": "Kulula. Kuhlakaniphile. Kuvikelekile. Sethula i-mHUB.",
    "mHubHeroManagedText1": "I-mHUB ihlanganisa amathuluzi okufunda, okufundisa ngezomsebenzi, izephulelo ze-Explore kanye nokuningi kuyipulatifomu eyodwa okulula ukuyisebenzisa.",
    "mHubHeroFranchisedText1": "I-mHUB ihlanganisa amathuluzi okufunda, izephulelo ze-Explore kanye nokuningi kuyipulatifomu eyodwa okulula ukuyisebenzisa.",
    "mHubHeroVisitLink": "Chofoza lapha ukuze ubone imininingwane eyengeziwe yalokho okutholakalayo noma lokho okunanyathiselwe.",
    "myGateway": "My Gateway",
    "gatewayReview": "Sicela ubuyekeze bese uyaqedela.",
    "gatewayProgress": "Intuthuko kwi-Gateway",
    "complete": "OKUQEDIWE",
    "reviewContent": "Bheka Izinsiza",
    "gatewayCardTitle": "Izinsiza kwi-Gateway",
    "gatewayCardDesc": "Thola Okokucathula konyaka wokuqala kuqhubeke.",
    "gatewayCardDescGms": "Thola Okokucathula, Izinto Ezikudinga Ngqo, Izinsiza ze-Brand kanye Nezinsiza kuye Ngomnyango onyakeni wokuqala kuqhubeke.",
    "gatewayCardDescNew": "Thola Okokucathula onyakeni wakho wokuqala endimeni yakho.",
    "gatewayCardDescGmsNew": "Thola Okokucathula, Izinto Ezikudinga Ngqo, Izinsiza ze-Brand kanye Nezinsiza kuye Ngomnyango onyakeni wokuqala endimeni yakho entsha kuqhubeke."
  },
  "courseBundle": {
    "completeBy": "USIQEDE UNGAKADLULI UMHLAKA",
    "remainingCourses": "Izifundo Ezisele",
    "completedCourses": "Izifundo Eziqediwe",
    "remainingLabel": "Kunezifundo eziyi- {courseCount} kule ndlela yokufunda.",
    "recommendButton": "Tusa Ukuba Yenziwe Yithimba",
    "startCourse": "Qala Isifundo",
    "durationMins": "IMIZUZU",
    "completed": "Okuqediwe",
    "back": "Emuva",
    "additionalLangs1": "Ngaphezu kokunikezwa ngesiNgisi lesi sifundo sesiyatholakala ngesi",
    "additionalLangs2": "Sicela ukhethe ulimi oluthandayo kwimenyu eyehlayo ngemva kokuqalisa isifundo.",
    "targetAudience": "Izithameli eziqondiwe",
    "all": "Konke",
    "globally": "kuzwelonke",
    "source": "Umthombo",
    "courseType": "Uhlobo Lwesifundo",
    "course": "Isifundo"
  },
  "profile": {
    "save": "Gcina",
    "edit": "Hlela",
    "brand": "Umkhiqizo",
    "brands": "Imikhiqizo",
    "additionalWork": "Zonke Izibopho Zomsebenzi Ezengeziwe",
    "startDateLabel": "Uqale nini ukusebenza eMarriott International noma eStarwood?",
    "preferredLanguageLabel": "Yiluphi ulimi oluthandayo?",
    "jobFunctionsLabel": "Wenza muphi umsebenzi?",
    "interestsLabel": "Yini ofuna ukuyifunda?",
    "brandsLabel": "Yimuphi umkhiqizo noma imikhiqizo osebenza ngayo?",
    "additionalJobFunctionsLabel": "Yimiphi imisebenzi eyengeziwe oyenzayo?",
    "managementLevelLabel": "Iyini i-Associate Level yakho?",
    "highGuestContactLabel": "Ingabe uphezulu ohlwini lwalabo izihambi ezixhumana nabo."
  },
  "courseDetails": {
    "completeBy": "Okumelwe Kugcwaliswe",
    "recommendTitlte": "Tusa Isifundo Eqenjini Lakho",
    "courseName": "Igama Lesifundo",
    "sendRecommendation": "Thumela Ukutusa"
  },
  "explore": {
    "relatedCourses": "Nazi izifundo ezihlobene nezinto ozithandayo.",
    "chooseInterests": "Khetha izinto ozithandayo",
    "title": "Zinike Isikhathi Sokufunda",
    "chooseInterestsModal": "Khetha Izinto Ozithandayo",
    "oppsTitle": "Hlola Amathuba Okufunda",
    "growWithUsTitle": "Khula Nathi",
    "growWithUsDesc": "I-Marriott ikholelwa emsebenzini okhula nawe. Siphinde sikholelwe ukuthi okuhlangenwe nakho okubanzi kuvame ukuhlinzeka ngolwazi olunothisayo.",
    "growWithUsBtn": "BUKA UKHULE NATHI",
    "mdaTitle": "Isikhungo Sokuthuthukisa Se-Marriott",
    "mdaDesc": "I-Marriott iyayiqaphela impumelelo nokuzibophezela. Sihlonipha futhi sikhuthaza ubuholi kanye nenkonzo esezingeni eliphezulu. Siphinde futhi sinikeze amabhonasi ngokudluliselwa okuphumelelayo.",
    "mdaBtn": "HLOLA ISIKHUNGO SOKUTHUTHUKISA SE-MARRIOTT",
    "selectInterests": "Sitshele ukuthi yiziphi izihloko ozithandayo.",
    "otherRecommended": "Ezinye Izifundo Ezinconyiwe",
    "searchByName": "Funa izinto ozithandayo"
  },
  "plan": {
    "completedRequired": "Uqede zonke izifundo zakho ezidingekayo!",
    "requiredCourses": "Izifundo Ezidingekayo",
    "reqDesc": "Sicela uziqede ngaphambi kwezinsuku zokugcina ezibekiwe.",
    "complete": "OKUQEDIWE",
    "of": "KWE",
    "courses": "ZIFUNDO",
    "completedCourses": "Izifundo Eziqediwe",
    "completedDesc": "Halala! Usanda kuqeda lezi zifundo ezingezansi.",
    "remaining": "ezisele",
    "myLearningTitle": "Engikufundayo Okwengeziwe ",
    "myLearningSubTitle": "Izinto engizifake kokuthi Icebo Lami",
    "history": "Umlando",
    "myLearningHistoryLinkText": "Bheka Umlando wami ophelele",
    "jf": "Izenzo Zomsebenzi",
    "awr": "Izibopho Zomsebenzi Ezengeziwe (AWR)",
    "jfAwrDesc": "Okubalulekile: isenzo somsebenzi kanye ne-AWR kunquma ukuqeqeshwa kwakho okudingekayo okwabelwe wena Ohlelweni Lwami. ",
    "jfAwrEdit": "LUNGISA iphrofayela yakho",
    "jfAwrEnd": "ukuze ubuyekeze.",
    "tourPlan": "Uhlelo Lwami luhlanganisa zonke izifundo zakho ezidingekayo nezinconyelwayo okumelwe ziphothulwe ngosuku olubekiwe ",
    "tourBasics": "I-Marriott Basics ihlanganisa izinsiza ezikusiza uqale ohambweni lwakho lwe-Marriot futhi ingafinyelelwa noma nini phakathi nomsebenzi wakho. I-Marriot Basics iyenezela ekufundeni kwakho Ohlelweni Lwami",
    "noCourses": "Uqede konke okufundwayo okudingeka ngalesi sikhathi, zizwe ukhululekile ukuqhubekela phambili futhi uqale ukufunda okulandelayo okudingekayo"
  },
  "team": {
    "title": "Lawula Iqembu Lakho",
    "subtitle": "Khulisa amakhono abo nemisebenzi yabo.",
    "functions": "Imisebenzi",
    "viewTeam": "Bheka Ithimba",
    "allCourses": "Zonke Izifundo",
    "downloadExcel": "Dawuniloda Umbiko we-Excel"
  },
  "feedbackButton": {
    "havingTrouble": "Iziphakamiso"
  },
  "recommendation": {
    "dismiss": "Ingabe uyaqiniseka ukuthi ufuna ukusichitha lesi sincomo?"
  },
  "table": {
    "filterTable": "Bảng lọc"
  },
  "notifications": {
    "addAssignment2": "Umsebenzi wesikole %{assignment_type}, we-%{assignment_name} ufakiwe ku-%{page location}  yakho futhi udingeka ngomhla ka-%{due}.",
    "comingDueAssignment": "Usuku lomsebenzi wakho wesikole %{assignment_type}, we-%{assignment_name} seluyasondela ngomhla ka-%{due}.",
    "overdueAssignment": "Umsebenzi wakho wesikole %{assignment_type} we-%{assignment_name} usudlule usuku okumelwe uthunyelwe ngalo.",
    "addAssignment": "Umsebenzi wesikole %{assignment_type} we-%{assignment_name} ufakiwe ku-%{page location} yakho.",
    "profile": "Kubonakale ushintsho lwesihloko somsebenzi wakho wokuziphilisa noma indawo okuyo. Sicela uhlole Iphrofayela ukuze wenze ushintsho olufanele Lomkhiqizo, Umsebenzi, noma Ezinye Izibopho Zomsebenzi Ezengeziwe. Imininingwane enembile yephrofayela ye-DLZ isiza ekuqikeleleni ukuthi uthole imisebenzi yokufunda enembile.",
    "helptip": "Zonke izaziso ziphelelwa yisikhathi ngemva kwezinsuku ezingu-30.",
    "markRead": "Phawula njengokufundiwe",
    "delete": "Dilitha",
    "markAllRead": "Phawula konke njengokufundiwe",
    "settings": "Amasethingi",
    "tooltip": "Izaziso ezifundiwe zizodilithwa ngemva kwezinsuku ezingu-30.",
    "reportComplete": "Umbiko wakho we-DLZ usuphothuliwe. Thinta ukuze udawunilode",
    "dontShowThisAgain": "Ungabe usakuveza lokhu",
    "warningEnabled": "Isixwayiso Sokususa sivuliwe.",
    "manageEmailNotifications": "Lawula Izaziso Ze-imeyili",
    "warningTooltip": "Lapho kukhethiwe, uzobona isixwayiso isikhathi ngasinye lapho uthola isaziso sokususa.",
    "changeEmailManagedTooltip": "Ukuze ushintshe i-imeyili yakho, yiya kokuthi mHUB > HR Direct > Mina > Imininingwane Yami Engeyona Imfihlo. Landela iziqondiso ukuze ulungise imininingwane yakho yokuxhumana. Kungase kuthathe 1-2 yezinsuku zomsebenzi ngaphambi kokuba i-imeyili elungisiwe ivele.",
    "changeEmailFranchisedTooltip": "Ukuze ushintshe i-imeyili yakho, xhumana noMphathi wakho kanye/noma Umqondisi wakho we-Franchise ye-EID Tool. Kungase kuthathe 1-2 yezinsuku zebhizinisi ngaphambi kokuba i-imeyili ebuyekeziwe ivele.",
    "deleteAllNotifications": "SUSA ZONKE IZAZISO",
    "deleteIndividualHeader": "Susa Zonke Izaziso",
    "deleteAllConfirmation": "Ingabe uyaqiniseka ngokuthi ufuna ukususa zonke isaziso? Zizoyiswa engxenyeni Yokususiwe Endaweni Yezaziso kuze kube yilapho ziphelelwa yisikhathi.",
    "deleteIndividualConfirmation": "Ingabe uyaqiniseka ngokuthi ufuna ukususa lesi saziso? Izaziso ezisusiwe ziyiswa engxenyeni Yokususiwe Endaweni Yezaziso kuze kube yilapho ziphelelwa yisikhathi.",
    "permanentDeleteIndividiualConfirmation": "Ingabe uyaqiniseka ngokuthi ufuna ukususa zonke isaziso unomphela? Lesi senzo ngeke sishintshwe.",
    "permanentDeleteAllConfirmation": "Ingabe uyaqiniseka ngokuthi ufuna ukususa zonke isaziso unomphela? Lesi senzo ngeke sishintshwe.",
    "notificationDeleted": "Isaziso sisusiwe.",
    "notificationsDeleted": "Izaziso zisusiwe.",
    "notificationRestored": "Isaziso sibuyiselwe.",
    "notificationsRestored": "Izaziso zibuyiselwe.",
    "enableDeletionWarning": "Isixwayiso Sokususa Sivuliwe",
    "warningDisabled": "Isixwayiso Sokususa sivaliwe.",
    "viewAll": "Buka Konke",
    "deleteAll": "Susa Konke",
    "notificationCenterTitle": "Indawo Yezaziso",
    "markAsRead": "Phawula Njengokufundiwe",
    "noDeletedNotifications": "Unokwakamuva!",
    "restore": "Buyisela",
    "restoreAll": "Buyisela Konke",
    "viewCourse": "Buka Izifundo",
    "title": "Izaziso",
    "noNewNotifications": "Awunazo izaziso.",
    "cancel": "Khansela",
    "manageNotificationsCopy": "Lawula Izaziso Ze-imeyili\n\nKhetha ukuthi uzothola ama-imeyili masonto onke abukeza izinto zokufunda ezidlulelwe yisikhathi. Khetha okuthi “Lawula” ukuze ushintshe okuncamelayo."
  },
  "transcript": {
    "history": "Umlando Wami",
    "completed": "Imisebenzi Yesikole Ephothuliwe",
    "assignmentName": "Igama Lomsebenzi Wesikole",
    "type": "Uhlobo",
    "viewAssignment": "Bheka Umsebenzi Wesikole",
    "assignmentSource": "Umthombo Womsebenzi Wesikole",
    "requiredAssignments": "Imisebenzi Yesikole Edingekayo",
    "recommendedAssignments": "Imisebenzi Etuswayo",
    "voluntary": "Ngokuzithandela",
    "trasscript": "Okubhaliwe",
    "retraining": "Ukuqeqeshwa Kabusha",
    "completionDate": "Usuku Lokuphothula",
    "assignmentType": "Uhlobo Lomsebenzi Wesikole",
    "contentType": "Uhlobo Lokuqukethwe",
    "contentId": "I-ID Yokuqukethwe"
  },
  "contentLaunch": {
    "review": "Hlola Ukufunda",
    "start": "Qala Ukufunda"
  },
  "survey": {
    "weValue": "Singathanda ukuzwa umbono wakho.",
    "weValueDescription": "Sicela uphendule imibuzo emihlanu emifushane ngokuhlangenwe nakho kokusebenza endaweni yakho.",
    "weValueButtonText": "Yenza inhlolovo",
    "weValueG1Q1A1": "Yebo",
    "weValueG1Q1A2": "Cha",
    "strongAgree": "Ngiyavuma Kakhulu",
    "agree": "Ngiyavuma",
    "slightAgree": "Ngivuma Kancane",
    "slightDisagree": "Angivumi Kancane",
    "disagree": "Angivumi",
    "strongDisagree": "Angivumi Ngokuphelele",
    "weValueG1Q1Title": "Ingabe uyile emhlanganweni wansuku zonke ngosuku lokugcina ongene ngalo emsebenzini? (i-Line-Up, i-What’s Up Meeting, i-Daily Rehearsal, i-House Briefing, i-Authentic Moments, noma i-Daily EDITION)",
    "weValueG1Q2Title": "Umhlangano wansuku zonke (i-Line-Up, i-What’s Up Meeting, i-Daily Rehearsal,i- House Briefing, i-Authentic Moments, noma i-Daily EDITION) ungikhuthaza ukuba ngenze umsebenzi osezingeni lomntakabani usuku ngalunye.",
    "weValueG1Q3Title": "Umnyango wami ugxile ekunciphiseni izinkinga, ngisho nalezo ezingabikwanga izivakashi.",
    "weValueG1Q4Title": "Nginikwe igunya lokuxazulula izinkinga zezivakashi ngaphandle kokucela kuqala imvume yazo kuqala.",
    "weValueG1Q5Title": "Nginegunya lokujabulisa izivakashi ngaphandle kokucela imvume yazo kuqala.",
    "weValueG2Q1Title": "Ngivame ukubona amalungu ethimba elisendaweni yami enza okungaphezulu kakhulu kokulindelekile ukuze ajavulise izivakashi ngokunakekela ezikunikezayo.",
    "weValueG2Q2Title": "Engikufundile ngalo mnyango kungenze ngakwazi ukuhlinzeka ngezinkonzo ezisezingeni eliphezulu.",
    "weValueG2Q3Title": "Ngizizwa nginokuzethemba ngolwazi kanye namakhono ami okwenza umsebenzi osezingeni eliphezulu.",
    "weValueG2Q4Title": "Emnyangweni wami, sehlinzeka ngendawo esezingeni lomntakabani, ehlanzekile nengenazinkinga.",
    "weValueG2Q5Title": "Ngisho nalapho ngihlangabezana nenkinga engahlobene nomnyango wami, nginegunya lokungenelela nginakekele inkinga ekhathaza isivakashi.",
    "weValueG3Q1Title": "Endaweni yami, amalungu ethimba athola ukunakekelwa ngendlela efanayo nokunakekelwa ngayo isivakashi.",
    "weValueG3Q2Title": "Umnyango wami uwumgogodla wokuba igugu kwenkampani yethu.",
    "weValueG3Q3Title": "Uma ngenza okungaphezulu kakhulu kwalokho okulindelekile ukunikeza isivakashi isikhathi esimnandi somtakabani, umsebenzi wami uyabonwa futhi uyaziswa.",
    "weValueG3Q4Title": "Nginesandla emzamweni womnyango wethu wokuthuthukisa umsebenzi wethu.",
    "weValueG3Q5Title": "Emnyangweni wami, sihlale sibeka phambili isivakashi.",
    "submitButton": "Thumela",
    "phasetwo": {
      "weValueG1Q1Title": "Ingabe uyile emhlanganweni wansuku zonke ngosuku lokugcina ongene ngalo emsebenzini? (I-Line-Up, i-What's Up Meeting, i-Daily Rehearsal, i-House Briefing, i-Authentic Moments, noma i-Daily EDITION)",
      "weValueG1Q2Title": "Umhlangano wansuku zonke (I-Line-Up, i-What's Up Meeting, i-Daily Rehearsal, i-House Briefing, i-Authentic Moments, noma i-Daily EDITION) ukhuthaza amalungu ethimba ukuba enze umsebenzi osezingeni eliphezulu kakhulu usuku ngalunye.",
      "weValueG1Q3Title": "Esakhiweni sami, sigxile ekunciphiseni izinkinga, ngisho nalezo ezingabikwanga yizivakashi.",
      "weValueG1Q4Title": "Esakhiweni sami, sinegunya lokulungisa izinkinga zesivakashi ngaphandle kokucela imvume kuqala.",
      "weValueG1Q5Title": "Esakhiweni sami, sinegunya lokujabulisa izivakashi ngaphandle kokucela imvume kuqala.",
      "weValueG2Q1Title": "Esikhiweni sami, sihlale senza okungaphezu kokulindelekile ukuze sijabulise izivakashi ngendlela esizinakekela ngayo.",
      "weValueG2Q2Title": "Ukufunda amathuba esakhiweni kusivumele ukuba sihlinzeke ngamasevisi kanokusho.",
      "weValueG2Q3Title": "Esakhiweni sami, siyazethemba ngolwazi namakhono esinawo okuhlinzeka ngamasevisi kanokusho.",
      "weValueG2Q4Title": "Esakhiweni sami, sihlinzeka ngendawo kanokusho, ehlanzekile nengenazinkinga.",
      "weValueG2Q5Title": "Ngisho nalapho sihlangabezana nenkinga engahlobene nomnyango wethu, sinegunya lokungenela sisize isivakathi ngokusikhathazayo.",
      "weValueG3Q1Title": "Esakhiweni sami, sinakekelana ngendlela efana naleyo esinakekela ngayo isivakashi.",
      "weValueG3Q2Title": "Isakhiwo sami sihambisana nokubalulekile kwebhrendi.",
      "weValueG3Q3Title": "Esakhiweni sami, lapho senza okungaphezulu kakhulu kwalokho okulindelekile ukuze senze isivakashi sijabule kakhulu, umsebenzi wethu uyabonwa futhi uyaziswa.",
      "weValueG3Q4Title": "Esakhiweni sami, sinethuba lokubandakanyeka emizamweni yomnyango wethu yokuthuthukisa umsebenzi wethu.",
      "weValueG3Q5Title": "Esakhiweni sami, sihlale sibeka isivakashi kuqala."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} QEDELA",
    "gatewayProgressComplete": "INTUTHUKO YE-GATEWAY IQEDIWE",
    "heroMobileGatewayVideoText": "Dlala Ividiyo Ye-Gateway",
    "basics": "OKUYISISEKELO",
    "brandResources": "IZINSIZA ZOMKHIQIZO",
    "disciplineResources": "IZINSIZA ZOMNYANGO",
    "immersionResourcesHeader": "Izinsiza Zokwethula",
    "brandCommunicationsHeaader": "Okokuxhumana kwe-Brand",
    "brandContactsHeader": "Imininingwane Yokuthinta i-Brand",
    "franchisedActionItemsText": "Izinto Ezikudinga Ngqo zikusiza uhlale uhlelekile futhi zikupha ukwaziswa okubalulekile kokuqashwa kwakho. Lezi zinto aziyona impoqo futhi zingasetshenziswa kanye nokwaziswa okuvela kwinkampani ephethe i-franchise yakho.",
    "global": "UMHLABA WONKE",
    "continent": "IZWEKAZI",
    "viewed": "OKUBUKIWE",
    "completed": "OKUQEDIWE",
    "heading": "I-Gateway",
    "basicsTab": "OKUYISISEKELO",
    "onboardingTab": "Izinto Ezikudinga Ngqo",
    "brandTab": "Izinsiza Zomkhiqizo",
    "disciplinesTab": "IZINSIZA KUYE NGOMNYANGO",
    "basicsHeader": "Uyamukelwa e-Marriott International",
    "basicsHeaderNewBrandOrRole": "Siyakwamukela,",
    "basicsText": "Njengoba uqala kulo msebenzi wakho, siyazi ukuthi kuningi okumelwe ukufunde. Sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Ezinye zalezi zinto zingaphinde  zibuyekezwe ngokuthe xaxa lapho ujwayezwa noma lapho ufakwa kwi-brand, kodwa lokhu kuzokusiza uqalise.",
    "basicsTextGms": "Njengoba uqala kulo msebenzi wakho, siyazi ukuthi kuningi okumelwe ukufunde. Sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Ezinye zalezi zinto zingaphinde  zibuyekezwe ngokuthe xaxa lapho ujwayezwa noma lapho ufakwa kwi-brand, kodwa lokhu kuzokusiza uqalise. Lapho usuqedile, dlulela kwamanye amakhasi e-Gateway, ahlanganisa athi Izinto Ezikudinga Ngqo,  Izinsiza ze-Brand kanye Nezinsiza kuye Ngomnyango.",
    "basicsTextFranchise": "Siyazi ukuthi kuningi okumelwe ukufunde, yingakho sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Lezi zinto zingasetshenziswa kanye nokufunda lapho ujwayezwa, ufakwa kwi-brand noma nokwaziswa okuvela kwinkampani ephethe i-franchise yakho.",
    "basicsTextFranchiseGms": "Siyazi ukuthi kuningi okumelwe ukufunde, yingakho sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Lezi zinto zingasetshenziswa kanye nokufunda lapho ujwayezwa, ufakwa kwi-brand noma nokwaziswa okuvela kwinkampani ephethe i-franchise yakho. Lapho usuqedile, dlulela kwamanye amakhasi e-Gateway, ahlanganisa athi Izinto Ezikudinga Ngqo,  Izinsiza ze-Brand kanye Nezinsiza kuye Ngomnyango.",
    "basicsTextNewToRole": "Njengoba uqala kulo msebenzi wakho, siyazi ukuthi kuningi okumelwe ukufunde. Sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Okunye kwalezi zinto kungaphinde kubuyekezwe ngokuthe xaxa ekuqeqesheni owabelwe kona, kodwa lokhu kuzokusiza uqalise.",
    "basicsTextNewToRoleGms": "Njengoba uqala kulo msebenzi wakho, siyazi ukuthi kuningi okumelwe ukufunde. Sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Okunye kwalezi zinto kungaphinde kubuyekezwe ngokuthe xaxa ekuqeqesheni owabelwe kona, kodwa lokhu kuzokusiza uqalise. Lapho usuqedile, dlulela kwamanye amakhasi e-Gateway, ahlanganisa athi Izinto Ezikudinga Ngqo,  Izinsiza ze-Brand kanye Nezinsiza kuye Ngomnyango.",
    "basicsTextNewToRoleFranchise": "Siyazi ukuthi kuningi okumelwe ukufunde, yingakho sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Lezi zinto zingasetshenziswa kanye nokwaziswa okuvela kwinkampani ephethe i-franchise yakho.",
    "basicsTextNewToRoleFranchiseGms": "Siyazi ukuthi kuningi okumelwe ukufunde, yingakho sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Lezi zinto zingasetshenziswa kanye nokwaziswa okuvela kwinkampani ephethe i-franchise yakho. Lapho usuqedile, dlulela kwamanye amakhasi e-Gateway, ahlanganisa athi Izinto Ezikudinga Ngqo,  Izinsiza ze-Brand kanye Nezinsiza kuye Ngomnyango.",
    "basicsTextNewToBrand": "Njengoba uqala kulo msebenzi wakho omusha ne-%{brandName}, siyazi ukuthi kuningi okumelwe ukufunde. Sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Ezinye zalezi zinto zingaphinde  zibuyekezwe ngokuthe xaxa lapho ujwayezwa noma lapho ufakwa kwi-brand, kodwa lokhu kuzokusiza uqalise.",
    "basicsTextNewToBrandGms": "Njengoba uqala kulo msebenzi wakho omusha ne-%{brandName}, siyazi ukuthi kuningi okumelwe ukufunde. Sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Ezinye zalezi zinto zingaphinde  zibuyekezwe ngokuthe xaxa lapho ujwayezwa noma lapho ufakwa kwi-brand, kodwa lokhu kuzokusiza uqalise. Lapho usuqedile, dlulela kwamanye amakhasi e-Gateway, ahlanganisa athi Izinto Ezikudinga Ngqo,  Izinsiza ze-Brand kanye Nezinsiza kuye Ngomnyango.",
    "basicsTextNewToBrandFranchise": "Siyazi ukuthi kuningi okumelwe ukufunde, yingakho sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Lezi zinto zingasetshenziswa kanye nokufunda lapho ujwayezwa, ufakwa kwi-brand noma nokwaziswa okuvela kwinkampani ephethe i-franchise yakho.",
    "basicsTextNewToBrandFranchiseGms": "Siyazi ukuthi kuningi okumelwe ukufunde, yingakho sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini wakho ne-%{brandName}. Lezi zinto zingasetshenziswa kanye nokufunda lapho ujwayezwa, ufakwa kwi-brand noma nokwaziswa okuvela kwinkampani ephethe i-franchise yakho. Lapho usuqedile, dlulela kwamanye amakhasi e-Gateway, ahlanganisa athi Izinto Ezikudinga Ngqo,  Izinsiza ze-Brand kanye Nezinsiza kuye Ngomnyango.",
    "disciplineHeader": "Izinsiza Kuye Ngomnyango",
    "toDo": "OKUMELWE UKWENZE",
    "optional": "ONGAZENZELA NJE",
    "overallComplete": "SEKUKONKE OKUQEDIWE",
    "keyResourcesDesc": "Funda kabanzi ngeminyango esemqoka ekunakekeleni ukusebenza kwansuku zonke kwendawo, ekwenameni kwezivakashi kanye nasezidingweni zebhizinisi.",
    "franchiseFooter": "Loku okuqukethwe kuphethe okunconywayo kanye nezindlela zokusebenza ezigqugquzelwayo ezinganyusa amathuba okuthi kulandelwe Imigangatho ye-Brand. Njengomnini we-franchise, i-Marriott ayingeni ekuphathweni noma ekusebenzeni kwanoma yisiphi isici sehhotela somnini we-franchise noma ukusebenza kwebhizinisi lakhe. Abanini be-franchise yibo abalawula ukusebenza kwansuku zonke kwamahhotela abo futhi okwenziwa abasebenzi babo kanye nezindlela zokuqashwa zisemahlombe abo.",
    "landingHeroTitle1": "Uyemukelwa e-Mariott International.",
    "landingHeroTitle2": "Qalisa inkambo yakho la.",
    "landingHeroText": "I-The Gateway ithuluzi lokukuqondisa phakathi nonyaka wakho wokuqala emsebenzini wakho omusha. Izokuhlinzeka ngokwaziswa nangezinsiza ozozidinga ukuze uphumelele.",
    "landingHeroTitle1NewToRole": "Uyemukelwa kwi-The Gateway.",
    "landingHeroTitle1NewToBrand": "Uyemukelwa kwi-The Gateway.",
    "landingButtonText": "Qalisa",
    "twoMonthslandingHeroTitle1": "Siyakwamukela Futhi!",
    "twoMonthslandingHeroText": "Uma ubungakakwenzi, phothula Okokucathula ku-Gateway!",
    "twoMonthslandingButtonText": "Ngena ku-Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "Siyakwamukela Futhi!",
    "twoMonthslandingHeroTextNewToBrand": "Sithemba izinsuku zakho zokuqala ne-%{brandName} zibe ngezithokozisayo. Ungakhohlwa ukuqhubeka nenkambo yakho nokuphinde uthole konke okudingayo ku-Gateway.",
    "twoMonthslandingButtonTextNewToBrand": "Qhubekela Phambi",
    "threeMonthslandingHeroTitle1": "Ususendleleni yakho!",
    "threeMonthslandingHeroText": "Konke odinga ukukwazi nge-Marriott International ku-Gateway.",
    "threeMonthslandingButtonText": "Ngena ku-Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "Ususendleleni yakho!",
    "threeMonthslandingHeroTextNewToRole": "Konke odinga ukukwazi ukuze uphumelele emsebenzini wakho omusha ku-Gateway.",
    "threeMonthslandingButtonTextNewToRole": "Ngena ku-Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "Siyakwamukela Futhi!",
    "threeMonthslandingHeroTextNewToBrand": "Siyakuhalalisela ngokuphothula izinsuku zakho zokuqala ze-%{numDays} ne-brand yakho entsha Ungafinyelela zonke izinto ezikudinga ngqo, I-Brand, Nezinsiza Zomnyango noma nini lapho uzidinga.",
    "threeMonthslandingButtonTextNewToBrand": "Ngena ku-Gateway",
    "fourMonthslandingHeroTitle1": "Umsebenzi oncomekayo! Usuqedile ngokokuCathula!",
    "fourMonthslandingHeroText": "Thola konke odinga ukukwazi nge-Marriott International ku-Gateway.",
    "fourMonthslandingButtonText": "Ngena ku-Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "Umsebenzi oncomekayo! Usuqedile ngokokuCathula!",
    "fourMonthslandingHeroTextNewToRole": "Thola konke odinga ukukwazi ukuze uphumelele emsebenzini wakho omusha ku-Gateway.",
    "fourMonthslandingButtonTextNewToRole": "Ngena ku-Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "I-The Gateway - Ukungena",
    "fourMonthslandingHeroTextNewToBrand": "Njengoba usondela ukuphothuleka konyaka wakho une-%{brandName}, uma ubungakakwenzi ungakhohlwa ukugcwalisa zonke Izinto Ezikudinga Ngqo nokusebenzisa izinsiza zokukuthuthukisa ohlinzekwe ngazo ku-Digital Learning Zone. Thinta u-’Qhubeka’ ukuze uqhubekele phambili nohambo lwakho",
    "fourMonthslandingButtonTextNewToBrand": "Qhubeka",
    "gmSecondVisitlandingHeroTitle1": "Siyakwamukela Futhi!",
    "gmSecondVisitlandingHeroText": "Sithemba izinsuku zakho zokuqala zibe ngezijabulisayo. Ungakhohlwa ukuqhubeka nenkambo yakho ezokusiza udlondlobale emsebenzini wakho omusha.",
    "gmSecondVisitlandingButtonText": "Qhubekela Phambi",
    "gmSecondVisitlandingHeroTextNewToRole": "Sithemba izinsuku zakho zokuqala zibe ngezijabulisayo. Ungakhohlwa ukuqhubeka nenkambo yakho nokuphinde uthole konke okudingayo ku-Gateway.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Sithemba izinsuku zakho zokuqala ne-%{brandName} zibe ngezithokozisayo. Ungakhohlwa ukuqhubeka nenkambo yakho nokuphinde uthole konke okudingayo ku-Gateway.",
    "gmThirdVisitlandingHeroTitle1": "Siyakwamukela Futhi!",
    "gmThirdVisitlandingHeroText": "Halala ngokuphothula izinsuku zakho zokuqala ezingu-%{numDays} zenkambo yakho yokuqashwa uva nje. Ungathola zonke Izinto Ezikudinga Ngqo zakho, Nezinsiza ze-Brand, kanye Nokomnyango noma yinini lapho uzidinga.",
    "gmThirdVisitlandingButtonText": "Ngena ku-Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "Halala ngokuphothula izinsuku zakho zokuqala ezingu-%{numDays} emsebenzini wakho omusha. Ungathola zonke Izinto Ezikudinga Ngqo zakho, Nezinsiza ze-Brand, kanye Nokomnyango noma yinini lapho uzidinga.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Halala ngokuphothula izinsuku zakho zokuqala ezingu-%{numDays} ne-brand yakho entsha. Ungathola zonke Izinto Ezikudinga Ngqo zakho, Nezinsiza ze-Brand, kanye Nokomnyango noma yinini lapho uzidinga.",
    "gmSevenMonthslandingHeroTitle1": "Kubukeka sengathi ziseningana Izinto Ezikudinga Ngqo okusamelwe uziphothule.",
    "gmSevenMonthslandingHeroText": "Njengoba kusondela ukuphothuleka konyaka wakho wokuqala, ungakhohlwa ukugcwalisa zonke Izinto Ezikudinga Ngqo nokusebenzisa izinsiza zokukuthuthukisa ohlinzekwe ngazo ku-Digital Learning Zone. Thinta u-”Qhubeka” ukuze uqhubekele phambili nohambo lwakho",
    "gmSevenMonthslandingButtonText": "Qhubeka",
    "gmSevenMonthslandingHeroTitle1NewToRole": "I-The Gateway - Ukungena",
    "gmSevenMonthslandingHeroTextNewToRole": "Njengoba usondela ukuphothuleka konyaka wakho wokuqala kulomsebenzi omusha, ungakhohlwa ukugcwalisa zonke Izinto Ezikudinga Ngqo nokusebenzisa izinsiza zokukuthuthukisa ohlinzekwe ngazo ku-Digital Learning Zone. Thinta u-”Qhubeka” ukuze uqhubekele phambili nohambo lwakho",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "I-The Gateway - Ukungena",
    "gmSevenMonthslandingHeroTextNewToBrand": "Njengoba usondela ukuphothuleka konyaka wakho une-%{brandName}, uma ubungakakwenzi ungakhohlwa ukugcwalisa zonke Izinto Ezikudinga Ngqo nokusebenzisa izinsiza zokukuthuthukisa ohlinzekwe ngazo ku-Digital Learning Zone. Thinta u-’Qhubeka’ ukuze uqhubekele phambili nohambo lwakho",
    "gmOneYearlandingHeroTitle1": "Halala ngonyaka 1 une-%{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "Halala ngonyaka 1 usemsebenzini wakho omusha!",
    "gmOneYearlandingHeroTitle1NewToBrand": "Halala ngonyaka 1 usemsebenzini wakho omusha ne-%{brandName}!",
    "gmOneYearlandingHeroText": "Yize uhambo lwe-The Gateway seluphelile kukhulu okujabulisayo okuseza. Ungazithola noma yinini izinsiza kwithebhu ye-Gateway nganoma yisiphi isikhathi.",
    "gmOneYearlandingButtonText": "Bheka i-Gateway"
  },
  "resources": {
    "iptTooltip": "Sebenzisa okhefana ukwehlukanisa izindawo eziningana",
    "iptAllFunctionTooltip": "Cisha la ukuze ukhethe Okumelwe Kwenziwe Emsebenzini. Yekela kungacishiwe ukuze ubheke ukuqeqeshwa kukho konke okumelwe kwenziwe endaweni oyikhethile",
    "iptAllAWRTooltip": "Cisha la ukuze ukhethe Eminye Imithwalo Yomsebenzi Engenziwa. Yekela kungacishiwe ukuze ubheke ukuqeqeshwa Kweminye Imithwalo Yomsebenzi Engenziwa endaweni oyikhethile",
    "editJFTooltip": "Sebenzisa okhefana ukwehlukanisa okufakiwe okuningana."
  },
  "Notification Center: ": "Phawula Njengokungafundiwe",
  "Notification Center: Empty": "Unokwakamuva!",
  "Notification Center: Individual Delete": "Ingabe uyaqiniseka ngokuthi ufuna ukudiletha lesi saziso? Izaziso ezidilethiwe ziyiswa engxenyeni Yokudilethiwe Endaweni Yezaziso kuze kube yilapho ziphelelwa yisikhathi.",
  "Notification Center: Delete All": "Ingabe uyaqiniseka ngokuthi ufuna ukudiletha zonke isaziso? Zizoyiswa engxenyeni Yokudilethiwe Endaweni Yezaziso kuze kube yilapho ziphelelwa yisikhathi.",
  "Notification Center: Mark All As Read": "Phawula Konke Njengokufundiwe",
  "Notification Center: Restore All": "Buyisela Konke",
  "Notification Center: Delete Confirmation": "Diletha",
  "Warning Message Text: Remove Job Function or AWR": "Susa Indlela Yokusebenza noma Umthwalo Owengeziwe Womsebenzi (AWR)?\r\nNgokuthumela, usuzosusa %{Function and/or AWR} kusuka %{count} Abafundi.",
  "Warning Message Text: Add Job Function or AWR": "Faka Indlela Yokusebenza noma Umthwalo Owengeziwe Womsebenzi (AWR)?\r\nNgokuthumela, usuzonezela %{Function and/or AWR} kusuka %{count} Abafundi.",
  "Button": "Thumela",
  "Success Message Text: Add Job Function and/or AWR": "Kuphumelele\r\nWenezele %{Job Function and/or AWR} kokuceliwe e %{location}. \r\n\r\nUngathanda yini ukulungisa Indlela Yokusebenza noma Umthwalo Owengeziwe Womsebenzi e %{location}?",
  "Message Text:AWR": "Kuphumelele\r\nWenezele Umthwalo Owengeziwe Womsebenzi %{ __} kokuceliwe e %{ __}.\r\n\r\nUngathanda yini ukulungisa Indlela Yokusebenza noma Umthwalo Owengeziwe Womsebenzi kwe %{location, property, learner(s)}?",
  "Success Message Text: Remove Job Function": "Kuphumelele\r\nUsuse  %{Job Function and/or AWR} kokuceliwe e %{location}. \r\n\r\nUngathanda yini ukulungisa Indlela Yokusebenza noma Umthwalo Owengeziwe Womsebenzi e %{location}?",
  "Success Message Text: Remove AWR": "Kuphumelele\r\nUsuse Umthwalo Owengeziwe Womsebenzi %{ __} kwesisebenzisana naye o(aba)celiwe e %{ __} .\r\n\r\nUngathanda yini ukulungisa Indlela Yokusebenza noma Umthwalo Owengeziwe Womsebenzi kwe %{location, property, learner(s)}?",
  "Tool Tip - Managed Change Email": "Ukuze ushintshe i-imeyili yakho, yiya kokuthi mHUB > HR Direct > Mina > Imininingwane Yami Engeyona Imfihlo. Landela iziqondiso ukuze ulungise imininingwane yakho yokuxhumana. Kungase kuthathe 1-2 yezinsuku zebhizinisi ngaphambi kokuba i-imeyili ebuyekeziwe ivele.",
  "Tool Tip - Franchised  Change Email": "Ukuze ushintshe i-imeyili yakho, xhumana noMphathi wakho kanye/noma Umqondisi wakho we-Franchise ye-EID Tool. Kungase kuthathe 1-2 yezinsuku zebhizinisi ngaphambi kokuba i-imeyili ebuyekeziwe ivele.",
  "User Profile: Email Opt-in": "Cha, ngiyabonga",
  "User Profile: Email Frequency": "Okufinqiwe Kwamasonto Onke",
  "Generic Notification Email Subject Line:": "Unezaziso ezintsha ku DLZ",
  "Generic Body Text (<150 Characters)": "Sicela uvakashele i DLZ ukuze ubheke imininingwane.",
  "All Emails: Signature": "Digital Learning Zone kwenziwe lula. Kuqala ngawe.",
  "All Emails: Footer": "Ungase ukhethe ukuphuma ekutholeni izaziso ze-imeyili nganoma yisiphi isikhathi. Vakashela i-Digital Leaning Zone/iProfayela ukuze ubuyekeze okuncamelayo.",
  "Overdue Emails: Subject Line: ": "Imisebenzi ekhona ku-DLZ edinga Ukuba Uyenze",
  "Overdue Emails: Body Test": "Unomsebenzi Osudlulelwe Yisikhathi. Sicela ungene ku DLZ ukuze uhlole umsebenzi wakho.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Lola Amakhono Akho",
    "sharpenYourSkillsSubTitle": "Yakha ulwazi lwakho namakhono ukuze uthuthukise umsebenzi wakho, manje nasesikhathini esizayo.",
    "growYourCareerTitle": "Khulisa Umsebenzi Wakho",
    "growYourCareerSubTitle": "Hlela isinyathelo sakho esilandelayo ngamakhono akhethekile noma ngokuqeqeshwa emisebenzini ehlukahlukene esekela izinto ozithandayo kanye nemigomo yakho.",
    "additionalResourcesTitle": "Ezinye Izinsiza",
    "additionalResourcesSubTitle": "Hlola i-getAbstract® kanye ne-CultureWizard™. Bheka indlela i-DLZ engaqhubeka ikusiza ngayo ukuba ukhule.",
    "tourHeader": "Siyakwamukela ku-DLZ ebuyekeziwe. Manje ungasesha ukuze uthole noma yini oyidingayo, ngesikhathi oyidinga ngaso. Ngaphezu kwalokho, uzothola okuthi Zinike Isikhathi Sokufunda okubuyekeziwe kanye nokuthi Icebo Lami okufanelana nawe. Ake uthi ukubukabuka!",
    "tourBtnSkip": "Susa",
    "tourBtnNext": "Vala (6/6)",
    "tourSearchBar": "Thola okudingayo, ngesikhathi okudinga ngaso, usebenzisa ithuluzi lokuFuna elisha nelisebenza kangcono.",
    "tourBtnBack": "Emuva",
    "tourMyPlan": "Okuthi Icebo Lami manje sekunezinto ezenezelwe ezivela kokuthi Funa nokuthi Hlola ukuze ululawule ngokuphelele uhambo lwakho lokufunda!",
    "tourExploreLearningLink": "Usengakwazi ukuthola okuthi Zinike Isikhathi Sokufunda nalapha! ",
    "tourExploreLearningProfile": "Zuza ngokugcwele ekufundeni kwakho futhi uhlole iphrofayela yakho, ikakhulukazi okumayelana nomsebenzi, ukwenze lokho njalo ukuze okuthi Icebo Lami kuhlale kunokwakamuva.",
    "mhubTraining": "Ukuqeqeshwa Kwe-mHUB",
    "growthSkills": "Amakhono Okukhula",
    "leadershipSkills": "Amakhono Obuholi",
    "virtualTeamResources": "Izinsiza Zeqembu Eliku-inthanethi",
    "jobFunctions": "Imisebenzi Oyenzayo",
    "brands": "Amabhrendi",
    "languageLearning": "Ukufunda Ulimi",
    "become": "Iba",
    "emeaMentoringProgram": "Uhlelo Lokuqeqesha Lwe-EMEA",
    "learningPathways": "Izindlela Zokufunda",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "Isikhungo Somphakathi Se-TakeCare",
    "mhubTrainingDesc": "Thola imizila yokufunda esekelwe endimeni kanye nezifundo ezigxile esihlokweni esithile mayelana nendlela yokusebenzisa i-mHUB.",
    "growthSkillsDesc": "Thola ulwazi olusebenzayo emisebenzini nasezihlokweni ezihlukehlukene. ",
    "leadershipSkillsDesc": "Thola indlela yokulawula nokuqeqesha iqembu futhi ube umholi ophumelela kangcono.",
    "virtualTeamResourcesDesc": "Funda amakhono okwakha ukubambisana kanye nesiko lokubandakanya bonke abantu emaqenjini aku-inthanethi.",
    "jobFunctionsDesc": "Khulisa ulwazi lomsebenzi owenza njengamanje futhi ufunde ngeminye imisebenzi.",
    "brandsDesc": "Funda okwengeziwe ngamabhrendi ahlukahlukene e-Marriott International.",
    "languageLearningDesc": "Xhumana ngokuphumelelayo nezilaleli ezisemhlabeni jikelele.",
    "becomeDesc": "Thola indlela ephelele yokukhula nokuthuthuka emsebenzini: cabanga, icebo, isenzo, phucula.",
    "emeaMentoringProgramDesc": "Funda amakhono ngoHlelo Lokuqeqesha Lwe-EMEA.",
    "learningPathwaysDesc": "Hlola amathuba aqondile okufunda ahlelelwe izinga lobuholi bakho.",
    "mdaDesc": "Yakha amakhono obuholi namakhono okusebenza nge-Marriott Development Academy.",
    "takeCareGrowDesc": "Thola izinsiza eziklanyelwe ukwakha ukuxhumana okubandakanya wonke umuntu nokukhuthazayo.",
    "getAbstractDesc": "Funda okuthile okusha nsuku zonke ngezincwadi ezifinqayo ezikusiza wenze izinqumo ezingcono.",
    "cultureWizardDesc": "Thola ulwazi olubalulekile lokuxhumana ngokuphumelelayo nabantu bawo wonke amasiko.",
    "takeCareCommunityHubDesc": "Thola imisebenzi ye-TakeCare yokuqinisa iqembu, izinhlelo zokufunda kanye namathuluzi.",
    "searchAllLearning": "Cinga Konke Ukufunda",
    "searchBarPlaceHolder": "Kuhlale kukuningi ongakufunda.",
    "clearFilters": "Susa Izihlungi",
    "noResults": "Akukho Miphumela",
    "hasBeenUnpinned": "{Variable-tile_Name} akusanamekiwe.",
    "hasBeenPinned": "{Variable-tile_Name} kunamekiwe.",
    "lpRemovedFromPlan": "Umzila Wokufunda ususiwe ohlelweni lwakho.",
    "courseRemovedFromPlan": "Isifundo sisusiwe ohlelweni lwakho.",
    "lpAddedToPlan": "Umzila Wokufunda wenezelwe ohlelweni lwakho.",
    "courseAddedToPlan": "Isifundo senezelwe ohlelweni lwakho.",
    "clearSelection": "Susa ukukhetha",
    "removeFromMyPlan": "Susa Ohlelweni Lwami",
    "addToMyPlan": "Faka Ohlelweni Lwami",
    "loadingResults": "Iyalayisha",
    "viewDetails": "Bheka Imininingwane",
    "clearAll": "Susa Konke",
    "noOptions": "Azikho izinketho ezitholakalayo",
    "viewLess": "Bona Okumbalwa",
    "viewMore": "Bona Okuningi",
    "recentSearch": "Okucingile Kwakamuva",
    "exploreLearningTitle": "Zinike Isikhathi Sokufunda",
    "exploreLearningHelpText": "Awuqiniseki ukuthi ufunani?",
    "exploreMore": "Hlola Okwengeziwe.",
    "viewAllResults": "Bheka Konke <#> Imiphumela",
    "searchSubTitle": "Cinga izifundo usebenzisa amazwi ayinhloko, izihloko noma i-ID yokuqukethwe.",
    "pinnedLearningTitle": "Engikufundayo Engikunamekile",
    "landingSubTitle": "Lawula indlela yakho yokufunda Hlola lokho okuthandayo — kunezihloko ezintsha nezifundo ezifakwa njalo.",
    "columnTitle": "Isihloko",
    "columnDesc": "Incazelo",
    "columnType": "Uhlobo",
    "columnAddedToPlan": "Kufakiwe Ohlelweni",
    "columnComplete": "Kuqediwe",
    "columnDetails": "Imininingwane",
    "additionalSearchFilters": "Izihlungi Ezengeziwe Zokucinga",
    "tourBtnClose": "Vala",
    "tourBtnLast": "Vala",
    "resultsFor": "Imiphumela ",
    "resultsFortheFilter": "Imiphumela yesihlungi {Filter Name}",
    "no": "Cha",
    "results": "Imiphumela",
    "courseTooltipTitle": "Into yokufunda engase iqukathe umsebenzi owodwa noma eminingi.<br />Isifundo singase senziwe ku-inthanethi, njengevidiyo noma i-eLearning, noma senziwe ekilasini.",
    "gatewayToolTipTitle": "Izinsiza zobuchwepheshe zalabo abasanda kuqashwa, labo abasha ekuphatheni, kanye nama-GM amasha kumabhrendi awo.",
    "speciallizationOrPathwayTooltipTitle": "Izifundo ezigxile esihlokweni esithile.",
    "taskTooltipTitle": "Iziqondiso zokugcwalisa umsebenzi (ngokuvamile emsebenzini) bese ubonisa ukuthi uwuqedile.",
    "webSiteTooltipTitle": "Noma iyiphi iwebusayithi, kuhlanganise amasayithi e-Marriott noma amawebusayithi angaphandle."
  },
  "dontShowAgain": "Ungabe usakuveza lokhu",
  "deletionWarningEnabled": "Isixwayiso sokususa sivuliwe",
  "deletionSelected": "Lapho kukhethiwe, uzobona isixwayiso isikhathi ngasinye lapho uthola isaziso sokususa.",
  "manageEmailNotifications": "Lawula Izaziso Ze-imeyili",
  "seeHowDLZContinue": "Bheka indlela i-DLZ engaqhubeka ikusiza ngayo ukuze ukhule.",
  "Notification Center:Overdue": "Unokukodwa kokuqeqeshwa obekufuneka ngomhlaka {Variable date ddmmyy}",
  "accesDenied": {
    "title": "Ukufinyelela Uhambo Lwakho Lokufunda kuyaqhubeka! ",
    "subtitle": "Ukufinyelela kwakho kuyasungulwa futhi kufanele kube khona ezinsukwini ezingu-1-2 ngemva kokuba i-EID yakho isisebenza. Uma kuthatha isikhathi esingaphezu kwezinsuku ezingu-2, sicela uxhumane Nesikhungo Sesevisi Se-mHUB."
  },
  "jfTour": {
    "title": "Manje usuyakwazi ukubona nokulungisa izenzo zomsebenzi kanye nemithwalo eyengeziwe yomsebenzi (AWR) ngokuqondile Ohlelweni Lwami."
  },
  "onboardingPlan": {
    "requiredDescription": "Ngezansi yizifundo ezidingekayo okumelwe ziphothulwe ngesikhathi osikhethile ngaphansi kokuthi Uhlelo Lwami.  Sebenza nomholi wakho ukuze uhlele isikhathi sokuphothula izimfundo zakho ezidingekayo njengoba usebenza endimeni yakho entsha. Uzofunda ngezihloko ezihlukahlukene, ngakho qikelela ukuthi uxhumana nomholi wakho kanye nazakwenu uma uba nanoma yimiphi imibuzo njengoba uqhubeka.   ",
    "basicsDescription": "Siyazi ukuthi kuningi okumelwe ukufunde, yingakho sikhethe okumbalwa kokokucathula kwezinsuku zakho zokuqala emsebenzini. Lezi zinto zingasetshenziswa kanye nokufunda lapho ujwayezwa, ufakwa kumkhiqizo noma nokwaziswa okuvela kwinkampani ephethe i-franchise yakho. Lezi kuzoba yizinsiza ezinhle kakhulu phakathi naso sonke isikhathi usebenza nathi futhi zikusize njengoba uletha amalungu amasha ethimba.",
    "recommendedDescription": "Ngemva kwezinsuku ezingu-90 zokuqala, uzothola izifundo ezengeziwe eziye zanconyelwa umphathi wakho. Ungahlola nezihloko ozithandayo ngokwakho. Okwamanje, gxila ekufundeni izinto ezizokwenza ube nesisekelo. ",
    "day1": "Kufuneka ngosuku 14",
    "day2": "Kufuneka ngosuku 30",
    "day3": "Kufuneka ngosuku 60",
    "day4": "Kufuneka ngosuku 90",
    "day5": "Kufuneka ngemva kwezinsuku ezingu-90 "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "I-Digital Learning Zone iyindawo ekusiza ufunde futhi uthuthuke emsebenzini wakho. Ngezansi uzothola okuqukethwe uhlelelwe ukukusiza uqale. Zonke izifundo zakho ungazithola njalo ngaphansi kokuthi Uhlelo Lwami.  ",
    "overallLearningProgress": "Konke Ukuqhubeka Kokufunda",
    "learningProgressLabel": "Ukuqhubeka Kokufunda",
    "learningProgressDescription": "Nayi indlela oqhubeka ngayo ekufundeni kwakho okufunekayo. Le bha yokuqhubeka ibandakanya isimo sakho konke ukufunda okudingekayo. Qhubeka nomsebenzi omuhle!"
  },
  "poc": {
    "dlz": "Ukuze lesi sifundo siphothulwe, Abaphathi noma Abaqondisi Besifundo kumelwe bamake lesi sifundo njengesiphothuliwe ku-mHUB. Imininingwane echaza indlela yokwenza lokho ingatholwa Emhlahlandleleni Wokusebenzisa. ",
    "mhub": "Abanikazi be-franchise:  uma ukhetha ukusebenzisa uhlelo lokuhlanza lwenhlangano yakho, sicela ufake igama lohlelo nemininingwane engxenyeni yokuphawula lapho umaka njengokuphothuliwe"
  },
  "Recommended Courses ": "Ngemva kwezinsuku ezingu-90 zokuqala, uzothola izifundo ezengeziwe eziye zanconyelwa umphathi wakho. Ungahlola nezihloko ozithandayo ngokwakho. Okwamanje, gxila ekufundeni izinto ezizokwenza ube nesisekelo. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Thatha Ucwaningo",
      "lbiIntro": "Siyakwamukela Ekungeneni Kokuziphatha Okunethezekile! ",
      "lbiDescription": `<p>Ngezansi uzocelwa ukuba ufunde izimo ezimbalwa ezimfushane ongahlangabezana nazo endaweni yakho.  Sicela ufunde isimo ngasinye bese ukhetha ukuthi uzosabela kanjani esimweni ngasinye.  Gcina lokhu okulandelayo engqondweni njengoba uphendula: </p>
      <ul>
          <li>
              <p>Ungase uthole ezingaphezu kweyodwa izimpendulo ongakhetha kuzo ezibonakala zilungile.  Khetha impendulo ongase uyiphendule emsebenzini. </p>
          </li>
          <li>
              <p>Cabanga ngokuthi azikho izithiyo zokuxhumana.  Ulimi olukhulumayo luyafana nolimi olukhulunywa isivakashi noma sohlangana naye kule simo. </p>
          </li>
          <li>
              <p>Isimo singase sikucele ukuba uzicabange usemsebenzini ohlukile kunendima okuyo njengamanje.  Phendula umbuzo ngolwazi lwakho olungcono kakhulu, ngokusekelwe kokuhlangenwe nakho kwakho njengochwepheshe wezokwamukela izihambi ezinethezekile. </p>
          </li>
      </ul>
      <p><br></p>
      <p>Sicela uphawule:  Ukubamba kwakho iqhaza kulokhu kungena kungokuzithandela ngokuphelele (ungakhetha ukungalibambi iqhaza), futhi izimpendulo zakho zizoba yimfihlo kakhulu.  Imiphumela yalokhu kungena izobikwa emaqenjini abantu hhayi abantu abathile.  Izimpendulo ngazinye zizohlolwa ithimba labacwaningi futhi ngeke kwabelwane ngazo Nabaphathi Babanye Abantu noma abaholi. </p>
      <p>Ngokugcwalisa lokhu kuhlola uvumelana nokuqoqwa nokucutshungulwa kwezimpendulo zakho zomuntu siqu kanye nedatha (isb. ubude besevisi, ubulili, isikhundla emsebenzini) kanye nokudluliswa kwedatha yakho yomuntu siqu eQenjini le-Marriott.  Lokhu kuvumelana Nesitatimende Sobumfihlo Sedatha Ye-Marriott, ongathola futhi ulwazi olwengeziwe noxhumana nabo ukuze uthole ireferensi Kuzinqubomgomo Zobumfihlo Zedatha ye-Marriott. </p>
      `,
      "checkin": {
        q1Title: "Uhamba endaweni yokwamukela izivakashi futhi uqaphela umndeni onabantwana abathathu abancane.  Lo mndeni unezikhwama eziningi, ubukeka ucindezelekile ngenxa yohambo olude, futhi izingane ziyabubula futhi azihlaliseki.  Ungenzenjani? ",
        q2Title: "Usanda kuqala endaweni esendaweni entsha kuwe.  Isivakashi sicela izincomo zezinto okumele zenziwe endaweni.  Isivakashi sifunde izincwadi zokuqondisa ezimbalwa kodwa sifuna okuthile okuqondene naso luqobo.  Ungenzenjani? ",
        q3Title: "Uhamba endaweni yokwamukela izivakashi lapho uzwa isivakashi sikhuluma ngocingo futhi uthola ukuthi isevisi yemoto yaso esikhumulweni sezindiza izofika sekwephuzile.  Indawo yakho ayinayo imoto yasendlini noma ayitholakali.  Ungenzenjani? ",
        q4Title: "Uhamba endaweni yokwamukela izivakashi usendleleni yokuyoqedela umsebenzi ophuthumayo owabelwe umphathi wakho.  Uqaphela uzakwenu edonsa kanzima ukuze aqedele umsebenzi ovame ukwenziwa abantu ababili.  Ungenzenjani? ",
        q5Title: "Ungumbungazi ovelele endaweni yokudlela yasehhotela.  Isivakashi sicela ukubona imenyu ngesikhathi sisalinde itafula laso elibekelwe eceleni.  Ngemva kokubuka imenyu, isivakashi sikhungathekile ngoba indawo yokudlela yasehhotela iphakanyiswe omunye uzakwethu, kodwa akukho lutho kumenyu oluhlangabezana nezidingo zaso zokudla.  Ungenzenjani? ",
        q6Title: "Kuneqembu elikhulu lezivakashi endaweni ephithizelayo yamachibi ezingakwazanga ukuthola izihlalo ndawonye.  Ngenxa yalokho, baye babeka amathawula abo kanye nempahla yabo siqu ngendlela yokuthi ivimbe indlela ebanzi, okudala ingozi nokuphazamiseka ezivakashini nakubantu abasebenzisana nabo abazama ukufinyelela endaweni yokubhukuda.  Le ndawo kudingeka isuswe izinto, kodwa awufuni ukucasula iqembu.  Ungenzenjani? ",
        q1R1Title: "Siza umndeni ngemithwalo yawo, uwukhombe lapho kunedeski lokungena, bese ubatshele ngezindawo ezimbalwa zokuphumula, eziseduze ezinemisebenzi elungele umndeni. Yisho izinsiza zomndeni zale ndawo.",
        q1R2Title: "Nikeza ukubasiza ngemithwalo yabo futhi uqondise umndeni ukuthi ubike ukuthi usufikile. Bese, uhlela isevisi yokuphumula noma ukuzijabulisa kwabazali kanye nezinto zokudlala zezingane ekamelweni labo. ",
        q1R3Title: "Qondisa abazali ukuba babike ukuthi sebefikile, bese uthatha izingane uziyise endaweni enokudla okulula ngesikhathi abazali bebika ukuthi sebefikile. Yazisa ideski eliphambili ukuba lazise abazali ngezindawo zokudlala nemisebenzi yezingane endaweni ukuze bakhululeke.",
        q1R4Title: "Khombisa lo mndeni ngasedeskini lokubika ukuthi sebefikile ukuze uthole inqubo yokubika esheshayo. Yisho izindawo ezilungele izingane kanye nezinsizakalo kubazali ukuze izingane zijabulele ukuhlala.",
        q2R1Title: "Buza isivakashi ukuthi yini esiyijabulelayo, bese uhlanganyela nozakwenu ukwenza uhlu lwezinto ezijabulisayo esingazenza. Ngaphezu kwalokho, sikisela indawo yokudlela enhle eseduze.",
        q2R2Title: "Khuluma nesivakashi ngalokho esikuthandayo. Sebenza nozakwenu usenzele uhlu olukhethekile lwezinto ezijabulisayo. Ngaphezu kwalokho, hlola iphrofayela yesivakashi sika-Bonvoy bese uthumela amanandinandi ekamelweni laso.",
        q2R3Title: "Yenze kube ngokwakho ngokwabelana ngezinye zezinto zakho ozithandayo ongazenza endaweni kuze kube manje. Yenza iziphakamiso zakho zigqame ngokwabelana ngemininingwane eyengeziwe mayelana nolwazi lwakho. Bese, thumela isiphuzo sendawo esimnandi ekamelweni labo.",
        q2R4Title: "Yazisa isivakashi ukuthi umusha endaweni futhi awunamibono eminingi. Xolisa futhi uphakamise ukuthi bakhulume nozakwenu owazi okwengeziwe. Bese, buza ukuthi bangathanda yini ukuba amanandinandi asendaweni ayiswe ekamelweni labo.",
        q3R1Title: "Ngenkathi isivakashi sisasocingweni, sondeza imoto yakho siqu noma yozakwenu iye ngaphambili endlini futhi wazise isivakashi ukuthi uzosiyisa wena mathupha esikhumulweni sezindiza.",
        q3R2Title: "Ngenkathi isivakashi sisesocingweni, hlanganisa uhlu lwamasevisi emoto agunyazwe indawo kanye nezinombolo zocingo. Uma isivakashi siqeda ocingweni, sinikeze uhlu bese ucela ukusiza ukuhlela izinto zokuhamba.",
        q3R3Title: "Linda kuze kube yilapho isivakashi sivala ucingo futhi usitshele ukuthi indawo yakho ayinikezi izinsiza zokuhamba, kodwa ungakujabulela ukusiza ukuxhumanisa ezinye izinketho zezinto zokuhamba. ",
        q3R4Title: "Ngenkathi isivakashi sisocingweni, tholela isivakashi enye into yokuhamba. Uma eseqedile ocingweni, yazisa isivakashi ukuthi usilungiselele into yokuhamba eya esikhumulweni sezindiza futhi imoto izofika maduze.",
        q4R1Title: "Yiya kuzakwenu uthi uzomsiza, kodwa udinga ukuqeda umsebenzi wakho ophuthumayo kuqala. Thembisa ukuthi uzobuya ngokushesha ukuze umsize. Qiniseka ukuthi uxoxa ngalesi simo nomphathi wakho.",
        q4R2Title: "Okokuqala, thola ngokushesha omunye ozakwenu ozothatha umsebenzi wakho owabelwe. Bese, ngokushesha usize uzakwenu odinga usizo. Uma usuqedile, yazisa umphathi wakho ukuthi uthole omunye umuntu ozoqedela umsebenzi wakho ukuze usize osebenza naye. ",
        q4R3Title: "Yima usize uzakwenu ngaso leso sikhathi. Ngemva kokusiza, buyela emsebenzini owabelwe, bese wazisa umphathi wakho ukuthi umise umsebenzi wakho ukuze usize uzakwenu.",
        q4R4Title: "Yiya kosebenza naye odinga usizo umchazele ukuthi uphakathi komsebenzi ophuthumayo. Misa yomibili imisebenzi, xoxani ngendlela yokuxazulula yomibili imisebenzi yenu ngokuhlanganyela, bese niqedela yomibili imisebenzi ndawonye.",
        q5R1Title: "Xolisa futhi utshele isivakashi ukuthi ungasisiza sibhukhe itafula endaweni yokudlela eseduze enokudla esikuthandayo. Nikeza ulwazi olwengeziwe noma usizo ngokufika lapho uma kudingeka.",
        q5R2Title: "Xolisa futhi utshele isivakashi ukuthi uyaqonda. Esikhundleni sokukhansela ukubhukha, cela ukukhuluma nompheki futhi ubone ukuthi angakwazi yini ukwenzela isivakashi okuthile okukhethekile. Sinikeze isipho noma isaphulelo ukuze ulungise okonakele.",
        q5R3Title: "Xolisa esivakashini futhi ubuze mayelana nokudla esikuthandayo. Khuluma nethimba lasekhishini ukuze lenze isidlo esikhethekile. Futhi, sinikeze isiphuzo samahhala noma isiphuzo ukulungisa iphutha.",
        q5R4Title: "Xolisa esivakashini futhi usitshele ukuthi uzothola umphathi. Qiniseka ukuthi uyasichaza isimo ukuze umphathi akwazi ukusiza isivakashi ngezidingo zaso zokudla.",
        q6R1Title: "Xolisa kubavakashi ngokuntuleka kwendawo futhi ubatshele ukuthi bamukelekile ukuhlala lapho bekhona inqobo nje uma beqaphela abanye abahambayo. ",
        q6R2Title: "Ngomusa tshela izivakashi ukuthi azikwazi ukuvimba indlela yokuhamba, futhi kuzodingeka zithole enye indawo noma zilinde kuze kuvuleke isikhala. Okwamanje, cela ozakwenu ukuba balethe izihlalo ezengeziwe e-ballroom ngaphandle ukuze bazisebenzise. ",
        q6R3Title: "Yazisa izivakashi ukuthi azikwazi ukuhlala endaweni ezikuyo nokuthi kufanele zisuke okwesikhashana kuyilapho uthola izindawo zokuhlala ezengeziwe. Banikeze isikhala endaweni yokuphuzela utshwala/indawo yokudlela okwamanje. ",
        q6R4Title: "Xolisa ezivakashini ngenkinga yokuhlala. Buza ukuthi bangakwazi yini ukubeka izinto zabo ndawonye ukuze bavule indlela encane ukuze abanye bakwazi ukudlula. Cela ukubasiza, wenze isiqiniseko sokuthi wonke umuntu ukhululekile. Bathumele amanandinandi akhethekile avela endaweni yokuphuzela utshwala/indawo yokudlela ngokuphazamisa kwabo."
      }
    }
  },
};