import FileSaver from 'file-saver';
import axios from 'axios';
import { cmdbURL } from '../config';
import cookies from '../util/cookies';

const GET = 'get';
const PUT = 'put';
const POST = 'POST';
const DELETE = 'delete';

const initApi = () => {
  const api = axios.create();
  api.defaults.baseURL = cmdbURL;

  return api;
};

const axiosApi = initApi();

const redirectOn401 = (error) => {
  if (error.response.data) {
    if (error.response.data.ssoUrl) {
      window.location = error.response.data.ssoUrl + window.location.pathname + window.location.search;
    }
    else {
      window.location.href = '/login'; // TODO handle redirect after login
    }
  }
};

const getBody = (verb, body) => {
  // https://github.com/axios/axios/issues/897#issuecomment-343715381
  if (body !== undefined && verb === DELETE) {
    return { data: body };
  }

  return body;
};

const execute = async (verb, url, body, responseType) => {
  try {
    const config = {
      url,
      method: verb,
      data: getBody(verb, body),
      withCredentials: true, // include existing cookies as part of request header
      responseType: responseType
    };
    const { status, headers, data } = await axiosApi.request(config);
    return {
      status,
      headers,
      data
    };
  } catch (error) {
    const { status, data } = error.response;
    // handle 401s in the store, payload may contain relevant redirect info
    if (status === 401) {
      if (url === '/users/me') { // return 401 response on user fetch
        return {
          status,
          data
        };
      } else { // if 401 on any other request other than user fetch, redirect to non-auth page
        redirectOn401(error);
      }
    }

    throw error;
    // return { status };
  }
};

class cmdbApi {

  async getExploreLearnings() {
    return execute(GET, `/app/explore-learning?application=dlp&filter=true` );
  }
  async getResourceFiles(resourceId) {
    return execute(GET, `/files?${resourceId}`);
  }

  async getBaseData() {
    return execute(GET, '/app/base?application=dlp');
  }

  async getReportingBaseData() {
    return execute(GET, '/app/base/reporting?application=dlp');
  }

  async getToplineBaseData() {
    return execute(GET, '/app/base?application=topline');
  }

  async getCourses() {
    return { data: [] };
    // return execute(GET, '/app/learner/resources?application=dlp');
  }

  async createOrUpdateUserCompletion(userCompletion) {
    return execute(PUT, '/app/user-completions?application=dlp', userCompletion);
  }

  async getAllCourses() {
    return execute(GET, '/app/resource?application=dlp');
  }

  async getGatewayResources() {
    return execute(GET, '/app/dlz-projects/gateway-resources?application=dlp')
  }

  async getGatewayPermissions() {
    return execute(GET, '/app/dlz-projects/gateway-permissions?application=dlp')
  }

  async getProjectContent(project, typeFilter, filterBrand, filterRegion) {
    let url = `/app/project-content?application=dlp&project=${project}`;
    url = typeFilter ? url + `&typeFilter=${typeFilter}` : url;
    url = filterBrand ? url + '&filterBrand=true' : url;
    url = filterRegion ? url + '&filterRegion=true' : url;
    return execute(GET, url);
  }

  async getEnabledProjects() {
    return execute(GET, '/app/dlz-projects?application=dlp');
  }

  async getContentGroups(project) {
    return execute(GET, `/app/content-group?application=dlp${project ? `&project=${project}` : ''}`);
  }

  async getAllLessons() {
    return execute(GET, '/app/lesson?application=dlp');
  }

  async getAllLearningPathways() {
    return execute(GET, '/app/learning-pathway?application=dlp');
  }

  async getCourseCompletions() {
    return execute(GET, '/app/learner/completions?application=dlp');
  }

  async getTeam(eid) {
    if (eid) {
      return execute(GET, `/app/learner/team?application=dlp&managerEid=${eid}`);
    } else {
      return execute(GET, '/app/learner/team?application=dlp');
    }
  }

  async getValidatorTeam(eid) {
    if (eid) {
      return execute(GET, `/app/learner/team-validator?application=dlp&managerEid=${eid}`);
    } else {
      return execute(GET, '/app/learner/team-validator?application=dlp');
    }
  }

  async updateValidatorUserProfile(payload) {
    return execute(PUT, `/app/learner/user-validator/${payload.eid}?application=dlp`, { payload });
  }

  async getTeamGateway() {
    return execute(GET, '/app/dlz-projects/gateway-team-report?application=dlp');
  }

  async getRequiredCompletions() {
    return execute(GET, '/app/learner/required-completions?application=dlp')
  }

  async getCompletionInfo() {
    return { data: [] } ;
    // return execute(GET, '/app/learner/course-completions?application=dlp');
  }

  async getExploreCourses() {
    return execute(GET, '/app/learner/recommended-resources?application=dlp');
  }

  async getCourseDetails(courseId) {
    return execute(GET, `/app/learner/resources/${courseId}?application=dlp`);
  }

  async getPathwayDetails(pathwayId) {
    return execute(GET, `/app/learner/pathways/${pathwayId}?application=dlp`);
  }

  async getPathwayCourseDetails(pathwayId) {
    return execute(GET, `/app/learner/pathways/${pathwayId}/courses?application=dlp`);
  }

  async getIptPlan(locationCodes, jobFunctions, additionalWorkResponsibilities = [], newHireOnly, requiredTraining, recommendedTraining, allFunctionsSelected, allAWRSelected) {
    return execute(POST, `/app/ipt/plan/new?application=dlp`, { locationCodes, jobFunctions, additionalWorkResponsibilities, newHireOnly, requiredTraining, recommendedTraining, allFunctionsSelected, allAWRSelected })
  }

  async getAssociateCoursePlan(eid, filters, showGatewayCourses) {
    return execute(POST, `/app/learner/resources/plan/${eid}?application=dlp${showGatewayCourses ? '&gateway=true' : ''}`, { filters })
  }

  async getAssociateGatewayPlan(eid) {
    return execute(GET, `/app/dlz-projects/gateway-associate-report/${eid}?application=dlp`)
  }

  async getSurvey() {
    const forceSurvey = window.location.search.indexOf('?force=true') === 0;
    const lbiTwo = window.location.search.indexOf('?lbiTwo=true') == 0;

    const impersonationEid = cookies.getCookie('impersonationEid');
    if (impersonationEid) {
      if (forceSurvey) {
        return execute(GET, `/app/surveys/me?eid=${impersonationEid}&application=dlp&ignoreCompletion=true`)
      } else {
        if (lbiTwo) {
          return execute(GET, `/app/surveys/me?eid=${impersonationEid}&application=dlp&lbiTwo=true`)
        } else {
          return execute(GET, `/app/surveys/me?eid=${impersonationEid}&application=dlp`)
        }

        
      }
    } else {
      if (forceSurvey) {
        return execute(GET, `/app/surveys/me?application=dlp&ignoreCompletion=true`)
      } else {
        if (lbiTwo) {
          return execute(GET, `/app/surveys/me?application=dlp&lbiTwo=true`)
        } else {
          return execute(GET, `/app/surveys/me?application=dlp`)
        }
      }
    }
  }

  async submitSurvey(surveyId, results) {
    const impersonationEid = cookies.getCookie('impersonationEid');
    if (impersonationEid) {
      return execute(POST, `/app/surveys/me/${surveyId}?eid=${impersonationEid}&application=dlp`, results)

    } else {
      return execute(POST, `/app/surveys/me/${surveyId}?application=dlp`, results);
    }
  }

  async dismissSurvey(surveyId, results) {
    const impersonationEid = cookies.getCookie('impersonationEid');
    if (impersonationEid) {
      return execute(DELETE, `/app/surveys/me/${surveyId}?eid=${impersonationEid}&application=dlp`, results)

    } else {
      return execute(DELETE, `/app/surveys/me/${surveyId}?application=dlp`, results);
    }
  }

  async surveyEvent(surveyId, payload) {
    const impersonationEid = cookies.getCookie('impersonationEid');
    if (impersonationEid) {
      return execute(POST, `/app/surveys/${surveyId}/events?eid=${impersonationEid}&application=dlp`, payload)

    } else {
      return execute(POST, `/app/surveys/${surveyId}/events?application=dlp`, payload);
    }
  }

  async markComplete(course) {
    return execute(POST, `/app/resource/complete?application=dlp&resourceId=${course.id}`)
  }

  async markPhase2Complete() {
    return execute(GET, '/app/topline/mark-phase-2-complete?application=topline');
  }

  async markCompleteUsers(courses, eids) {
    return execute(POST, `/app/resource/completeMany?application=dlp`, { courses, eids })
  }

  async getAssociateDataV2(filter, limit, offset, reportFilter, sort = {}, selectedLocationCode, cachedReportId) {
    return execute(POST, `/app/manager/reporting/v2/associates?application=dlp`, { limit, offset, filter, reportFilter, sort, selectedLocationCode, cachedReportId });
  }

  async getAssociateProgressV2(eids, filter) {
    return execute(POST, `/app/manager/reporting/v2/associates/progress?application=dlp`, { eids, filter });
  }

  async getLocationData(filter, limit, offset, reportFilter, sort = {}) {
    return execute(POST, `/app/manager/reporting/locations?application=dlp`, { limit, offset, filter, reportFilter, sort });
  }

  async getLocationDataV2(filter, limit, offset, reportFilter, sort = {}) {
    return execute(POST, `/app/manager/reporting/v2/locations?application=dlp`, { limit, offset, filter, reportFilter, sort });
  }

  async getSummaryTable(filter, levelOneSummary, levelTwoSummary, locationCode, polling) {
    return execute(POST, `/app/manager/reporting/v2/summary?application=dlp`, { filter, levelOneSummary, levelTwoSummary, locationCode, polling });
  }

  async getLocationProgress(locationCodes, filter) {
    return execute(POST, `/app/manager/reporting/locations/progress?application=dlp`, { locationCodes, filter });
  }

  async getLocationProgressV2(locationCodes, filter) {
    return execute(POST, `/app/manager/reporting/v2/locations/progress?application=dlp`, { locationCodes, filter });
  }

  async getReportingCoursePlan(eid, filters) {
    return execute(POST, `/app/manager/reporting/v2/associates/assignments?application=dlp`, {eid, filters});
  }

  async postCourseFilters(filters, query = {}, fauxFilters) {
    //delete null/undefined from query
    Object.keys(query).forEach(key => {
      if (!query[key]) {
        delete query[key];
      }
    });

    const queryString = query ? Object.keys(query).map(key => key + '=' + query[key]).join('&') : '';
    return execute(POST, `/app/manager/reporting/report?application=dlp&${queryString}`, { filters, fauxFilters });
  }

  async wait(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  async downloadCachedExcelReport(reportId) {
    const response = await execute(GET, `/app/manager/reporting/cached-excel-report?application=dlp&reportId=${reportId}`, {}, 'blob');
    FileSaver.saveAs(new Blob([response.data]), 'report.xlsx');
  }

  async downloadExcelReport(filters, view, query = {}, allLocationCodes, textFilters) {
    //delete null/undefined from query
    Object.keys(query).forEach(key => {
      if (!query[key]) {
        delete query[key];
      }
    });

    const queryString = query ? Object.keys(query).map(key => key + '=' + query[key]).join('&') : '';
    let response = await execute(POST, `/app/manager/reporting/excel-report?application=dlp&${queryString}`, { filters, view, allLocationCodes, textFilters }, 'blob');
    if (response.status === 200) {
      FileSaver.saveAs(new Blob([response.data]), 'report.xlsx');
    }
  }

  async downloadExcelReportV2(filters, view, query = {}, textFilters, assignmentIdGroups) {
    //delete null/undefined from query
    Object.keys(query).forEach(key => {
      if (!query[key]) {
        delete query[key];
      }
    });

    const queryString = query ? Object.keys(query).map(key => key + '=' + query[key]).join('&') : '';
    let response = await execute(POST, `/app/manager/reporting/v2/excel-report?application=dlp&${queryString}`, { filters, view, textFilters, assignmentIdGroups }, 'blob');
    if (response.status === 200) {
      FileSaver.saveAs(new Blob([response.data]), 'report.xlsx');
    }
  }

  async downloadFunctionReport(cacheKey) {
    if (!cacheKey) {
      return await execute(GET, `/app/manager/reporting/function-selection-report?application=dlp`);
    } else {
      return await execute(GET, `/app/manager/reporting/function-selection-report?application=dlp&cacheKey=${cacheKey}`);
    }
  }

  async postRecommendation(courseIds, eids, type = 'manager') {
    const payload = { courseIds, eids, type };
    return execute(POST, '/app/learner/recommendation?application=dlp', payload);
  }

  async dismissRecommendation(resourceId) {
    const payload = { resourceId };
    return execute(DELETE, '/app/learner/recommendation?application=dlp', payload);
  }

  async search(obj, model, filterParams = {}, limit = 200) {
    return execute(GET, `/app/search?application=dlp&model=${model}&limit=${limit}&filter=${JSON.stringify({ term: encodeURIComponent(obj), filterParams })}`);
  }

  async getRecentSearchItems() {
    return execute(GET, '/app/getRecentSearchItems?application=dlp');
  }

  async fetchUserHistory() {
    return execute(GET, `/app/user-history?application=dlp`);
  }

  async getStaticFileContent(filePath) {
    return fetch(filePath)
      .then(response => response.text())
      .then(text => {
        return text;
      });
  }

  async getNotifications(includeAdditions = false) {
    return { data: [] };
    // return execute(GET, `/app/manager/reporting/notifications/filtered-notifications?application=dlp&includeAdditions=${includeAdditions}`);
  }

  async refreshPlan() {
    return execute(GET, '/app/manager/reporting/learner/update-plan?application=dlp');
  }

  async fetchMongoUsers(eids, locationCodes) {
    return execute(GET, `/app/users/mongo-users?application=dlp&eids=${JSON.stringify(eids)}&locationCodes=${JSON.stringify(locationCodes)}`);
  }

  async createAudit(eid, scope, value) {
    return execute(POST, `/app/users/audit?application=dlp`, {
      eid,
      scope,
      value: {
        url: window.location.pathname,
        ...value
      }
    });
  }

  async updateAwrs(payload) {
    return execute(POST, '/app/bulk/jf-awrs?application=dlp', { payload });
  }

  async updateValidatorAwrs(payload) {
    return execute(POST, '/app/bulk/validator-jf-awrs?application=dlp', { payload });
  }

  async getToplineAssessments(type) {
    return execute(GET, `/app/user-assessment?application=topline&assessmentType=${type}`);
  }

  async getToplineLP() {
    return execute(GET, '/app/topline/learning-path?application=topline');
  }

  async updateProgram(program) {
    return execute(PUT, '/app/user-program?application=dlp', program);
  }

  async markPhase2Complete() {
    return execute(GET, '/app/topline/mark-phase-2-complete?application=topline');
  }

  async markVideoComplete() {
    return execute(GET, '/app/topline/mark-video-complete?application=topline');
  }

  async markVideo2Complete() {
    return execute(GET, '/app/topline/mark-video2-complete?application=topline');
  }

  async markToplineComplete() {
    return execute(GET, '/app/topline/mark-topline-complete?application=topline');
  }

  async createAssessment(payload) {
    return execute(POST, `/app/user-assessment?application=topline`, payload);
  }

  async updateAssessment(payload) {
    return execute(PUT, `/app/user-assessment/${payload.id}?application=topline`, payload);
  }

  async getCommunicationsResources() {
    return execute(GET, '/app/learner/communication-resources?application=dlp');
  }

  async getLanguagePack(project, languageCode) {
    return execute(GET, `/app/language/pack?project=${project}&languageCode=${languageCode}&application=dlp`);
  }

  async addCourseToPlan(eid, courseId, resourceType) {
    return execute(POST, `/app/learner/plan?application=dlp`, { eid, courseId, resourceType });
  }

  async removeCourseFromPlan(eid, courseId, resourceType) {
    return execute(DELETE, `/app/learner/plan?application=dlp`, { eid, courseId, resourceType });
  }

  async searchMhub(term = 'Course') {
    return execute(GET, `/app/search/mhub?term=${term}&application=dlp`);
  }

  async findCourseInResourceLegacy(courseId) {
    return execute(GET, `/app/resource/findInLegacy?courseId=${courseId}&application=dlp`);
  }

  async findResourceBySourceID(courseId, sourceId) {
    const params = ['application=dlp'];
    if (courseId) {
      params.push(`courseId=${courseId}`);
    }

    if (sourceId) {
      params.push(`sourceId=${sourceId}`);
    }
    return execute(GET, `/app/resource/findBySourceId?${params.join('&')}`);
  }

  async findCourses(ids = []) {
    return execute(GET, `/app/resource/findResources?ids=${JSON.stringify(ids)}&application=dlp`);
  }

  async findLearningPathways(ids = []) {
    return execute(GET, `/app/resource/findLearningPathways?ids=${JSON.stringify(ids)}&application=dlp`);
  }
}

export default new cmdbApi();
