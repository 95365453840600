// const logo = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/logo.png';
//const heroImageLarge = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/heroLarge.png';
// const heroImageSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/heroSmall.png';
// const heroImageXSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/heroXSmall.png';
//const exploreImageLarge = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/exploreLarge.png';
// const exploreImageSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/exploreSmall.png';
// const exploreImageXSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/exploreXSmall.png';
import { themePrimaryColorsBrand } from '../style-constants';

export default {
  extendedTheme: () => ({
    logo: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/logo.png',
    logoStyle: { height: 55 },
    heroImageLarge: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/jw-marriott/group-jw.png',
    //exploreImageLarge: exploreImageLarge,
    palette: {
      primary: {
        main: themePrimaryColorsBrand['JW Marriott']
      },
      secondary: {
        main: '#6d716b'
      },
      primaryBtnHover: {
        main: '#B69891',
      },
      buttonText: {
        main: '#1c1c1c'
      },
      interaction: {
        main: '#975a4d'
      },
      text: {
        primary: '#1c1c1c'
      },
      link: {
        primary: '#1c1c1c'
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        '"ProximaNova-Regular"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
    },
  }),
  typography: (theme, remCalc) => {
    return {
      h1: {
        fontFamily: 'AvenirNextLTPro-Light',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(54),
          lineHeight: 1.11
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(34),
          lineHeight: 1.24
        }
      },
      h2: {
        fontFamily: 'AvenirNextLTPro-Light',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(40),
          lineHeight: 1.15
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(30),
          lineHeight: 1.2
        }
      },
      
    }
  },
  extraTypography: (theme, remCalc) => {
    return {
      hero: {
        fontFamily: 'AvenirNextLTPro-Light',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        }
      },
      card: {
        fontFamily: 'AvenirNextLTPro-Light',
        fontWeight: 'bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(38),
          lineHeight: 1.16
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(28),
          lineHeight: 1.21
        }
      }
    }
  }
  
};
