export default {
  "banner": {
    "viewDetails": "Lihat Perincian",
    "requiredCourses": "Kursus wajib Anda akan jatuh tempo dalam",
    "days": "hari"
  },
  "header": {
    "plan": "Rencana Saya",
    "manager": "MANAJER",
    "explore": "JELAJAHI PEMBELAJARAN",
    "reporting": "PELAPORAN",
    "myTeam": "TIM SAYA",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "RENCANA SAYA",
    "manager": "MANAJER",
    "explore": "JELAJAHI PEMBELAJARAN",
    "tagline": "Belajar menjadi sederhana. Dimulai dengan Anda."
  },
  "welcome": {
    "hi": "Hai",
    "intro1": "Selamat datang di Digital Learning Zone! DLZ adalah platform pembelajaran khusus yang merupakan titik akses pusat pembelajaran Marriott. Temukan pelatihan yang diperlukan untuk peran Anda dan jelajahi kesempatan belajar untuk pengembangan pribadi dan profesional Anda. Kuasai perjalanan belajar Anda dan mulailah jalur untuk mengembangkan keterampilan Anda.",
    "intro2": "Sebelum mulai, kami perlu tahu beberapa hal tentang Anda.",
    "letsGo": "AYO",
    "preferredLanguage": "Apa bahasa pilihan Anda?",
    "step": "LANGKAH",
    "of": "DARI",
    "previous": "SEBELUMNYA",
    "next": "BERIKUTNYA",
    "startDate": "Kapan Anda mulai bekerja di Marriott International atau Starwood?",
    "selectYear": "PILIH TAHUN",
    "selectMonth": "PILIH BULAN",
    "selectDay": "PILIH TANGGAL",
    "selectFunction": "PILIH FUNGSI",
    "selectInterests": "PILIH MINAT",
    "chooseYear": "PILIH TAHUN",
    "chooseMonth": "PILIH BULAN",
    "chooseDay": "PILIH HARI",
    "whatDo": "Apa Peran Pekerjaan Anda?",
    "selected": "DIPILIH",
    "searchFunctions": "Telusuri semua fungsi",
    "searchInterests": "Telusuri Semua Minat",
    "additionalJobFunctionsLabel": "Ceritakan lebih lanjut tentang pekerjaan Anda",
    "managementLevelLabel": "Apa tingkat karyawan Anda?",
    "preferredBrandLabel": "Jika Anda bekerja untuk lebih dari satu merek, silakan pilih semua yang sesuai untuk Anda.",
    "additionalJobFunctionsLabelExtra": "Harap tinjau yang berikut dan PILIH SETIDAKNYA SATU tanggung jawab pekerjaan tambahan.",
    "searchAdditionalFunctions": "Telusuri semua tanggung jawab kerja tambahan",
    "highGuestContactLabel": "Apakah Anda yang berhubungan dengan tamu kelas atas?",
    "yes": "Ya",
    "no": "Tidak "
  },
  "landing": {
    "started": "Mari mulai",
    "welcomeBack": "Selamat datang kembali,",
    "greatWork": "Kerja bagus",
    "requiredCourses": "Kursus Wajib Anda",
    "requiredCoursesDesc": "Silakan selesaikan kursus sebelum tenggat.",
    "myPlan": "Rencana Saya",
    "courses": "Kursus",
    "courseComplete": "KURSUS SELESAI",
    "viewMore": "LIHAT LEBIH BANYAK KURSUS WAJIB",
    "viewMoreBtn": "LIHAT LEBIH BANYAK KURSUS",
    "showing": "MENAMPILKAN",
    "of": "DARI",
    "heroTitle": "Belajar sesuatu yang baru. Berdayakan diri Anda. Dapatkan keterampilan yang sesuai dengan bidang Anda.",
    "heroParagraph1": "Lacak semua program yang diperlukan dengan mudah dan dapatkan pemberitahuan waktu nyata untuk menyelesaikannya.",
    "heroParagraph2": "Nikmati pengalaman belajar yang disesuaikan berdasarkan pekerjaan dan minat Anda.",
    "recommendedCoursesTitle": "Kursus yang Disarankan",
    "recommendedCoursesDescNoContent": "Anda tidak memiliki kursus yang disarankan saat ini. Silakan lihat di bawah untuk menjelajahi peluang belajar lainnya.",
    "recommendedCoursesDesc": "Lihat apa yang dipilih manajer atau spesialis SDM untuk Anda.",
    "recommendedCoursesBtn": "LIHAT KURSUS YANG DISARANKAN",
    "completedCoursesBtn": "LIHAT KURSUS TERSELESAIKAN",
    "exploreTitle": "Bersemangatlah untuk belajar.",
    "exploreDesc": "Pilih topik yang Anda minati.",
    "jobFunctions": "Pendisiplinan",
    "growthSkills": "Keterampilan Pertumbuhan",
    "leadershipSkills": "Keterampilan Kepemimpinan",
    "brands": "Merek",
    "languageLearning": "Pembelajaran Bahasa",
    "exploreBtn": "LIHAT KURSUS",
    "managerRecommendation": "SARAN MANAJER",
    "learningRecommendation": "SARAN BELAJAR",
    "marriottRecommendation": "SARAN MARRIOTT",
    "completedCourses": "Anda telah menyelesaikan semua kursus wajib Anda!",
    "investTitle": "Kuasai Perjalanan Belajar Anda",
    "investParagraph1": "Kami menyadari keadaan saat ini menantang dalam banyak hal - DLZ di sini menjadi sumber daya untuk Anda. Apakah Anda ingin menjaga keterampilan Anda saat ini, mempelajari sesuatu yang baru, mengelola stres Anda, atau belajar untuk bekerja dalam sebuah tim virtual. Kami telah mengumpulkan sumber daya untuk membantu mendukung Anda.",
    "updatedTopics": "Topik yang diperbarui",
    "workingRemotely": "Sumber daya tim virtual",
    "relatedCoursesNew": "Klik di bawah untuk memperbarui minat Anda, karena kami telah menambahkan topik dan kursus baru.",
    "relatedCourses": "Silahkan klik di bawah untuk memilih minat Anda.",
    "notificationText": "Pelatihan mHUB baru tersedia. Pilih mHUB di Explore Learning.",
    "mHubHeroTitle1": "Mudah. Cerdas. Aman. Memperkenalkan mHUB.",
    "mHubHeroManagedText1": "mHUB mengintegrasikan berbagai alat untuk pembelajaran, pengembangan karier, diskon Explore, dan lainnya dalam satu platform yang mudah digunakan.",
    "mHubHeroFranchisedText1": "mHUB mengintegrasikan berbagai alat untuk pembelajaran, diskon Explore, dan lainnya dalam satu platform yang mudah digunakan.",
    "mHubHeroVisitLink": "Klik di sini untuk melihat detail penawaran tambahan atau lampiran.",
    "myGateway": "My Gateway",
    "gatewayReview": "Silakan tinjau dan selesaikan.",
    "gatewayProgress": "Kemajuan Gateway",
    "complete": "SELESAIKAN",
    "reviewContent": "Lihat Sumber Daya",
    "gatewayCardTitle": "Sumber Daya Gateway",
    "gatewayCardDesc": "Akses Hal Dasar Anda untuk tahun pertama dan seterusnya.",
    "gatewayCardDescGms": "Akses Hal Dasar, Item Tindakan, Sumber Daya Merek, dan Sumber Daya berdasarkan Departemen untuk tahun pertama dan seterusnya.",
    "gatewayCardDescNew": "Akses Hal Dasar Anda untuk tahun pertama di peran baru Anda.",
    "gatewayCardDescGmsNew": "Akses Hal Dasar, Item Tindakan, Sumber Daya Merek, dan Sumber Daya berdasarkan Departemen untuk tahun pertama di peran baru Anda dan seterusnya."
  },
  "courseBundle": {
    "completeBy": "SELESAIKAN SEBELUM",
    "remainingCourses": "Sisa Kursus",
    "completedCourses": "Kursus Terselesaikan",
    "remainingLabel": "Ada {courseCount} kursus dalam jalur belajar ini.",
    "recommendButton": "Sarankan Ke Tim",
    "startCourse": "Mulai Kursus",
    "durationMins": "MENIT",
    "completed": "Selesai",
    "back": "Kembali",
    "additionalLangs1": "Selain diberikan dalam bahasa Inggris, kursus ini sekarang tersedia dalam bahasa",
    "additionalLangs2": "Silakan pilih bahasa pilihan Anda dari menu tarik turun setelah meluncurkan kursus.",
    "targetAudience": "Khalayak target",
    "all": "Semua",
    "globally": "secara global",
    "source": "Sumber",
    "courseType": "Jenis Kursus",
    "course": "Kursus"
  },
  "profile": {
    "save": "Simpan",
    "edit": "Edit",
    "brand": "Merek",
    "brands": "Merek",
    "additionalWork": "Semua Tanggung Jawab Kerja Tambahan",
    "startDateLabel": "Kapan Anda mulai bekerja di Marriott International atau Starwood?",
    "preferredLanguageLabel": "Apa bahasa pilihan Anda?",
    "jobFunctionsLabel": "Apa peran pekerjaan Anda?",
    "interestsLabel": "Apa yang ingin Anda pelajari?",
    "brandsLabel": "Untuk merek apa Anda bekerja?",
    "additionalJobFunctionsLabel": "Apa fungsi pekerjaan tambahan yang Anda lakukan?",
    "managementLevelLabel": "Apa Tingkat Karyawan Anda?",
    "highGuestContactLabel": "Apakah Anda yang berhubungan dengan tamu kelas atas?"
  },
  "courseDetails": {
    "completeBy": "Untuk Diselesaikan",
    "recommendTitlte": "Sarankan Kursus ke Tim Anda",
    "courseName": "Nama Kursus",
    "sendRecommendation": "Kirim Saran"
  },
  "explore": {
    "relatedCourses": "Berikut adalah kursus yang berkaitan dengan bidang minat Anda.",
    "chooseInterests": "Pilih minat Anda",
    "title": "Jelajahi pembelajaran",
    "chooseInterestsModal": "Pilih Minat",
    "oppsTitle": "Jelajahi Kesempatan Belajar",
    "growWithUsTitle": "Tumbuhlah Bersama Kami",
    "growWithUsDesc": "Marriott percaya bahwa karier Anda berkembang seiring dengan pertumbuhan Anda. Kami juga percaya bahwa pengalaman yang lebih luas dapat menghadirkan pengalaman yang lebih memperkaya.",
    "growWithUsBtn": "LIHAT TUMBUH BERSAMA KAMI",
    "mdaTitle": "Akademi Pengembangan Marriott",
    "mdaDesc": "Marriott sangat menghargai kesuksesan dan komitmen. Kami menghormati dan mendorong jiwa kepemimpinan dan layanan yang luar biasa. Kami juga memberikan bonus untuk rujukan yang sukses.",
    "mdaBtn": "LIHAT AKADEMI PENGEMBANGAN MARRIOTT",
    "selectInterests": "Beri tahu kami topik apa yang Anda minati.",
    "otherRecommended": "Kursus yang Disarankan Lainnya",
    "searchByName": "Cari minat Anda"
  },
  "plan": {
    "completedRequired": "Anda telah menyelesaikan semua kursus wajib Anda!",
    "requiredCourses": "Kursus Wajib",
    "reqDesc": "Silakan selesaikan sebelum tanggal tenggat.",
    "complete": "SELESAIKAN",
    "of": "DARI",
    "courses": "KURSUS",
    "completedCourses": "Kursus Terselesaikan",
    "completedDesc": "Selamat! Anda baru saja menyelesaikan kursus di bawah ini.",
    "remaining": "sisa",
    "myLearningTitle": "Pelajaran Tambahan Saya ",
    "myLearningSubTitle": "Item yang telah saya tambahkan ke Rencana Saya",
    "history": "Riwayat",
    "myLearningHistoryLinkText": "Lihat Riwayat lengkap saya",
    "jf": "Fungsi Pekerjaan Anda",
    "awr": "Tanggung Jawab Kerja Tambahan (AWR) Anda",
    "jfAwrDesc": "Penting: fungsi pekerjaan dan AWR menentukan pelatihan penting yang ditugaskan pada Rencana Saya. ",
    "jfAwrEdit": "EDIT profil Anda",
    "jfAwrEnd": "untuk diperbarui.",
    "tourPlan": "Rencana Saya mencakup semua kursus wajib dan yang direkomendasikan untuk diselesaikan pada tanggal jatuh tempo",
    "tourBasics": "Marriott Basics menyertakan sumber daya untuk membantu Anda memulai perjalanan Marriott dan dapat diakses kapan saja selama Anda berkarier. Marriott Basics melengkapi pembelajaran Anda di Rencana Saya.",
    "noCourses": "Anda semua harus mengikuti pembelajaran wajib yang jatuh tempo saat ini, jangan ragu untuk melihat jadwal selanjutnya dan memulai pembelajaran wajib yang akan datang"
  },
  "team": {
    "title": "Kelola Tim Anda",
    "subtitle": "Kembangkan keterampilan dan karier mereka.",
    "functions": "Fungsi",
    "viewTeam": "Lihat Tim",
    "allCourses": "Semua Kursus",
    "downloadExcel": "Unduh Laporan Excel"
  },
  "feedbackButton": {
    "havingTrouble": "Usul"
  },
  "recommendation": {
    "dismiss": "Apakah Anda yakin ingin mengabaikan rekomendasi ini?"
  },
  "table": {
    "filterTable": "Tabella dei filtri"
  },
  "notifications": {
    "addAssignment2": "Tugas %{assignment_type} untuk %{assignment_name} telah ditambahkan ke %{page location} Anda dan jatuh tempo pada %{due}.",
    "comingDueAssignment": "Tugas %{assignment_type} Anda untuk %{assignment_name} akan segera jatuh tempo pada %{due}.",
    "overdueAssignment": "Tugas %{assignment_type} Anda untuk %{assignment_name} sekarang sudah lewat jatuh tempo.",
    "addAssignment": "Tugas %{assignment_type} untuk %{assignment_name} telah ditambahkan ke %{page location} Anda.",
    "profile": "Pembaruan untuk jabatan atau lokasi pekerjaan Anda telah terdeteksi. Silakan tinjau Profil Anda untuk melakukan pembaruan Merek, Fungsi, atau Tanggung Jawab Kerja Tambahan yang berlaku. Informasi profil DLZ yang akurat membantu memastikan Anda menerima tugas pembelajaran yang benar.",
    "helptip": "Semua notifikasi berakhir setelah 30 hari.",
    "markRead": "Tandai sudah dibaca",
    "delete": "Hapus",
    "markAllRead": "Tandai semua sudah dibaca",
    "settings": "Pengaturan",
    "tooltip": "Notifikasi baca akan dihapus dalam 30 hari",
    "reportComplete": "Laporan DLZ Anda telah selesai. Klik untuk mengunduh",
    "dontShowThisAgain": "Jangan tampilkan ini lagi",
    "warningEnabled": "Peringatan Penghapusan telah diaktifkan.",
    "manageEmailNotifications": "Kelola Pemberitahuan Email",
    "warningTooltip": "Ketika dipilih, Anda akan melihat peringatan setiap kali Anda menghapus pemberitahuan.",
    "changeEmailManagedTooltip": "Untuk mengubah email Anda, buka mHUB > HR Direct > Saya > Info Publik Saya. Ikuti petunjuk untuk memperbarui detail kontak Anda. Ini membutuhkan 1-2 hari kerja untuk menampilkan email yang telah diperbarui.",
    "changeEmailFranchisedTooltip": "Untuk mengubah email Anda, hubungi Manajer Anda dan/atau Administrator EID Tool Waralaba Anda. Mungkin perlu 1-2 hari kerja agar email yang diperbarui muncul.",
    "deleteAllNotifications": "Hapus Semua Pemberitahuan",
    "deleteIndividualHeader": "Hapus Pemberitahuan",
    "deleteAllConfirmation": "Apakah Anda yakin ingin menghapus semua pemberitahuan? Pemberitahuan akan dipindahkan ke bagian Dihapus di Pusat Pemberitahuan hingga masa berlakunya habis.",
    "deleteIndividualConfirmation": "Apakah Anda yakin ingin menghapus pemberitahuan ini? Pemberitahuan yang dihapus dipindahkan ke bagian Dihapus di Pusat Pemberitahuan hingga masa berlakunya habis.",
    "permanentDeleteIndividiualConfirmation": "Apakah Anda yakin ingin menghapus pemberitahuan ini secara permanen? Tindakan ini tidak dapat dibatalkan.",
    "permanentDeleteAllConfirmation": "Apakah Anda yakin ingin menghapus semua pemberitahuan secara permanen? Tindakan ini tidak dapat dibatalkan.",
    "notificationDeleted": "Pemberitahuan telah dihapus.",
    "notificationsDeleted": "Pemberitahuan telah dihapus.",
    "notificationRestored": "Pemberitahuan telah dipulihkan.",
    "notificationsRestored": "Pemberitahuan telah dipulihkan.",
    "enableDeletionWarning": "Peringatan Penghapusan Diaktifkan",
    "warningDisabled": "Peringatan Penghapusan telah dinonaktifkan.",
    "viewAll": "Lihat Semua",
    "deleteAll": "Hapus Semua",
    "notificationCenterTitle": "Pusat Pemberitahuan",
    "markAsRead": "Tandai Sudah Dibaca",
    "noDeletedNotifications": "Anda sudah mendapatkan informasi terbaru!",
    "restore": "Pulihkan",
    "restoreAll": "Pulihkan Semua",
    "viewCourse": "Lihat Kursus",
    "title": "Pemberitahuan",
    "noNewNotifications": "Anda tidak memiliki pemberitahuan.",
    "cancel": "Batal",
    "manageNotificationsCopy": "Kelola Pemberitahuan Email\n\nPilih apakah ingin menerima email mingguan yang meringkas item pembelajaran Anda yang telah kedaluwarsa. Pilih \"Kelola\" untuk mengubah preferensi Anda."
  },
  "transcript": {
    "history": "Riwayat Saya",
    "completed": "Tugas Selesai",
    "assignmentName": "Nama Tugas",
    "type": "Jenis",
    "viewAssignment": "Lihat Tugas",
    "assignmentSource": "Sumber Tugas",
    "requiredAssignments": "Tugas Wajib",
    "recommendedAssignments": "Tugas yang Disarankan",
    "voluntary": "Sukarela",
    "trasscript": "Transkrip",
    "retraining": "Pelatihan Ulang",
    "completionDate": "Tanggal Penyelesaian",
    "assignmentType": "Jenis Tugas",
    "contentType": "Jenis Konten",
    "contentId": "ID Konten"
  },
  "contentLaunch": {
    "review": "Tinjau Pembelajaran",
    "start": "Mulai Pembelajaran"
  },
  "survey": {
    "weValue": "Kami ingin mengetahui pendapat Anda.",
    "weValueDescription": "Jawablah lima pertanyaan singkat tentang pengalaman Anda bekerja untuk properti Anda.",
    "weValueButtonText": "Ikuti survei",
    "weValueG1Q1A1": "Ya",
    "weValueG1Q1A2": "Tidak",
    "strongAgree": "Sangat Setuju",
    "agree": "Setuju",
    "slightAgree": "Agak Setuju",
    "slightDisagree": "Agak Tidak Setuju",
    "disagree": "Tidak Setuju",
    "strongDisagree": "Sangat Tidak Setuju",
    "weValueG1Q1Title": "Apakah Anda menghadiri rapat harian pada shift terakhir Anda bekerja? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, atau Daily EDITION)",
    "weValueG1Q2Title": "Rapat harian (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, atau Daily EDITION) menginspirasi saya untuk menciptakan layanan yang mewah setiap hari.",
    "weValueG1Q3Title": "Departemen saya berfokus untuk memperbaiki kekurangan layanan, bahkan yang tidak dilaporkan oleh para tamu.",
    "weValueG1Q4Title": "Saya diberdayakan untuk menyelesaikan masalah tamu tanpa harus meminta izin terlebih dahulu.",
    "weValueG1Q5Title": "Saya diberdayakan untuk menyenangkan para tamu tanpa harus meminta izin terlebih dahulu.",
    "weValueG2Q1Title": "Saya secara teratur melihat anggota tim di properti saya berusaha keras untuk mengesankan para tamu dengan perhatian yang mereka berikan.",
    "weValueG2Q2Title": "Pemelajaran saya di departemen ini telah memungkinkan saya memberikan layanan yang mewah.",
    "weValueG2Q3Title": "Saya merasa percaya diri dengan pengetahuan dan keterampilan saya untuk memberikan layanan yang mewah.",
    "weValueG2Q4Title": "Di departemen saya, kami memberikan lingkungan yang mewah, bersih, dan bebas dari kekurangan.",
    "weValueG2Q5Title": "Bahkan ketika saya menghadapi masalah yang tidak terkait dengan departemen saya, saya diberdayakan untuk mengatasinya dan selalu mengutamakan tamu.",
    "weValueG3Q1Title": "Di properti saya, anggota tim menerima tingkat perhatian yang sama dengan tamu.",
    "weValueG3Q2Title": "Departemen saya mewujudkan nilai-nilai dari merek.",
    "weValueG3Q3Title": "Ketika saya berusaha keras menciptakan pengalaman tamu yang mewah, kerja saya diakui dan dihargai.",
    "weValueG3Q4Title": "Saya terlibat dalam upaya departemen kami untuk meningkatkan kerja kami.",
    "weValueG3Q5Title": "Di departemen saya, kami selalu mengutamakan tamu.",
    "submitButton": "Kirim",
    "phasetwo": {
      "weValueG1Q1Title": "Apakah Anda menghadiri rapat harian pada shift terakhir Anda bekerja (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, atau Daily EDITION)?",
      "weValueG1Q2Title": "Rapat harian (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments, atau Daily EDITION) menginspirasi saya untuk menciptakan layanan yang mewah setiap hari.",
      "weValueG1Q3Title": "Di properti saya, kami berfokus untuk memperbaiki kekurangan layanan, bahkan yang tidak dilaporkan oleh para tamu.",
      "weValueG1Q4Title": "Di properti saya, kami diberdayakan untuk menyelesaikan masalah tamu tanpa harus meminta izin terlebih dahulu.",
      "weValueG1Q5Title": "Di properti saya, kami diberdayakan untuk menyenangkan para tamu tanpa harus meminta izin terlebih dahulu.",
      "weValueG2Q1Title": "Di properti saya, kami secara rutin berusaha keras untuk mengesankan para tamu dengan perhatian yang kami berikan.",
      "weValueG2Q2Title": "Kesempatan belajar di properti membuat kami mampu untuk memberikan layanan mewah.",
      "weValueG2Q3Title": "Di properti saya, kami merasa percaya diri dengan pengetahuan dan keterampilan dalam memberikan layanan yang mewah.",
      "weValueG2Q4Title": "Di properti saya, kami memberikan lingkungan yang mewah, bersih, dan bebas dari kekurangan.",
      "weValueG2Q5Title": "Bahkan ketika kami menghadapi masalah yang tidak terkait dengan departemen kami, kami diberdayakan untuk mengatasinya dan selalu mengutamakan tamu.",
      "weValueG3Q1Title": "Di properti saya, kami menerima tingkat perhatian yang sama dari rekan kerja seperti yang kami berikan kepada tamu.",
      "weValueG3Q2Title": "Properti saya mewujudkan nilai-nilai dari merek.",
      "weValueG3Q3Title": "Di properti saya, ketika kami berusaha keras menciptakan pengalaman tamu yang mewah, upaya kami diakui dan dihargai.",
      "weValueG3Q4Title": "Di properti saya, kami memiliki kesempatan untuk terlibat dalam upaya departemen kami untuk meningkatkan prestasi kami sendiri.",
      "weValueG3Q5Title": "Di properti saya, kami selalu mengutamakan tamu."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} SELESAI",
    "gatewayProgressComplete": "KEMAJUAN GATEWAY SELESAI",
    "heroMobileGatewayVideoText": "Putar Video Gateway",
    "basics": "DASAR",
    "brandResources": "SUMBER DAYA MEREK",
    "disciplineResources": "SUMBER DAYA DEPARTEMEN",
    "immersionResourcesHeader": "Sumber Daya Imersi",
    "brandCommunicationsHeaader": "Komunikasi Merek",
    "brandContactsHeader": "Kontak Merek",
    "franchisedActionItemsText": "Item tindakan membantu Anda tetap teratur dan memberi Anda informasi orientasi utama. Item-item ini tidak diperlukan dan merupakan tambahan dari apa yang mungkin Anda terima dari perusahaan manajemen waralaba Anda.",
    "global": "GLOBAL",
    "continent": "BENUA",
    "viewed": "TELAH DILIHAT",
    "completed": "SELESAI",
    "heading": "Gateway",
    "basicsTab": "HAL DASAR",
    "onboardingTab": "Item Tindakan",
    "brandTab": "Sumber Daya Merek",
    "disciplinesTab": "SUMBER DAYA BERDASARKAN DEPARTEMEN",
    "basicsHeader": "Selamat Datang di Marriott International",
    "basicsHeaderNewBrandOrRole": "Selamat datang,",
    "basicsText": "Saat Anda memulai karier, kami tahu ada banyak yang dipelajari. Kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Beberapa item ini mungkin ditinjau lebih mendalam dalam orientasi atau orientasi merek, tetapi ini akan membantu Anda memulai.",
    "basicsTextGms": "Saat Anda memulai karier, kami tahu ada banyak yang dipelajari. Kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Beberapa item ini mungkin ditinjau lebih mendalam dalam orientasi atau orientasi merek, tetapi ini akan membantu Anda memulai. Setelah Anda menyelesaikannya, pindah ke halaman Gateway lainnya, termasuk Item Tindakan, Sumber Daya Merek, dan Sumber Daya berdasarkan Departemen.",
    "basicsTextFranchise": "Kami tahu ada banyak yang harus dipelajari, dan kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Item-item ini mungkin tambahan untuk orientasi Anda, orientasi merek, atau informasi dari perusahaan manajemen waralaba Anda.",
    "basicsTextFranchiseGms": "Kami tahu ada banyak yang harus dipelajari, dan kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Item-item ini mungkin tambahan untuk orientasi Anda, orientasi merek, atau informasi dari perusahaan manajemen waralaba Anda. Setelah Anda menyelesaikannya, pindah ke halaman Gateway lainnya, termasuk Item Tindakan, Sumber Daya Merek, dan Sumber Daya berdasarkan Departemen.",
    "basicsTextNewToRole": "Saat Anda memulai peran baru, kami tahu ada banyak yang harus dipelajari. Kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Beberapa item ini mungkin ditinjau lebih mendalam dalam pelatihan yang ditugaskan, tetapi ini akan membantu Anda memulai.",
    "basicsTextNewToRoleGms": "Saat Anda memulai peran baru, kami tahu ada banyak yang harus dipelajari. Kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Beberapa item ini mungkin ditinjau lebih mendalam dalam pelatihan yang ditugaskan, tetapi ini akan membantu Anda memulai. Setelah Anda menyelesaikannya, pindah ke halaman Gateway lainnya, termasuk Item Tindakan, Sumber Daya Merek, dan Sumber Daya berdasarkan Departemen.",
    "basicsTextNewToRoleFranchise": "Kami tahu ada banyak yang harus dipelajari, dan kami telah memilih beberapa hal dasar untuk beberapa hari pertama di peran baru Anda. Item-item ini mungkin tambahan informasi dari perusahaan manajemen waralaba Anda.",
    "basicsTextNewToRoleFranchiseGms": "Kami tahu ada banyak yang harus dipelajari, dan kami telah memilih beberapa hal dasar untuk beberapa hari pertama di peran baru Anda. Item-item ini mungkin tambahan informasi dari perusahaan manajemen waralaba Anda. Setelah Anda menyelesaikannya, pindah ke halaman Gateway lainnya, termasuk Item Tindakan, Sumber Daya Merek, dan Sumber Daya berdasarkan Departemen.",
    "basicsTextNewToBrand": "Saat Anda memulai peran baru bersama %{brandName}, kami tahu ada banyak yang harus dipelajari. Kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Beberapa item ini mungkin ditinjau lebih mendalam dalam orientasi atau orientasi merek, tetapi ini akan membantu Anda memulai.",
    "basicsTextNewToBrandGms": "Saat Anda memulai peran baru bersama %{brandName}, kami tahu ada banyak yang harus dipelajari. Kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Beberapa item ini mungkin ditinjau lebih mendalam dalam orientasi atau orientasi merek, tetapi ini akan membantu Anda memulai. Setelah Anda menyelesaikannya, pindah ke halaman Gateway lainnya, termasuk Item Tindakan, Sumber Daya Merek, dan Sumber Daya berdasarkan Departemen.",
    "basicsTextNewToBrandFranchise": "Kami tahu ada banyak yang harus dipelajari, dan kami telah memilih beberapa hal dasar untuk beberapa hari pertama di peran baru Anda. Item-item ini mungkin tambahan untuk orientasi Anda, orientasi merek, atau informasi dari perusahaan manajemen waralaba Anda.",
    "basicsTextNewToBrandFranchiseGms": "Kami tahu ada banyak yang harus dipelajari, dan kami telah memilih beberapa hal dasar untuk hari pertama Anda di peran baru bersama %{brandName}. Item-item ini mungkin tambahan untuk orientasi Anda, orientasi merek, atau informasi dari perusahaan manajemen waralaba Anda. Setelah Anda menyelesaikannya, pindah ke halaman Gateway lainnya, termasuk Item Tindakan, Sumber Daya Merek, dan Sumber Daya berdasarkan Departemen.",
    "disciplineHeader": "Sumber Daya Berdasarkan Departemen",
    "toDo": "YANG HARUS DIKERJAKAN",
    "optional": "PILIHAN",
    "overallComplete": "KESELURUHAN SELESAI",
    "keyResourcesDesc": "Pelajari lebih lanjut tentang departemen utama yang mendukung pengoperasian properti, pengalaman tamu, dan kebutuhan bisnis sehari-hari.",
    "franchiseFooter": "Materi ini berisi rekomendasi dan praktik yang disarankan yang dapat meningkatkan kemungkinan kepatuhan terhadap Standar Merek. Sebagai pemilik waralaba, Marriott tidak ikut serta dalam pengelolaan atau pengoperasian segala aspek operasional bisnis atau hotel penerima waralaba. Para penerima waralaba tetap mengontrol pengoperasian hotel mereka sehari-hari dan bertanggung jawab akan karyawan dan praktik kerja mereka sendiri.",
    "landingHeroTitle1": "Selamat Datang di Marriott International.",
    "landingHeroTitle2": "Mulai perjalanan Anda dari sini.",
    "landingHeroText": "The Gateway adalah alat untuk memandu Anda selama tahun pertama Anda di peran baru. Ini akan menyediakan informasi dan sumber daya yang Anda perlukan agar berhasil.",
    "landingHeroTitle1NewToRole": "Selamat Datang di The Gateway.",
    "landingHeroTitle1NewToBrand": "Selamat Datang di The Gateway.",
    "landingButtonText": "Memulai",
    "twoMonthslandingHeroTitle1": "Selamat Datang Kembali!",
    "twoMonthslandingHeroText": "Jika Anda belum melakukannya, selesaikan semua Hal Dasar Anda di The Gateway!",
    "twoMonthslandingButtonText": "Masuk Gateway",
    "twoMonthslandingHeroTitle1NewToBrand": "Selamat Datang Kembali!",
    "twoMonthslandingHeroTextNewToBrand": "Semoga hari-hari pertama Anda bersama %{brandName} terasa luar biasa. Jangan lupa melanjutkan perjalanan Anda dan mendapatkan akses ke The Gateway untuk semua yang Anda butuhkan",
    "twoMonthslandingButtonTextNewToBrand": "Lanjutkan",
    "threeMonthslandingHeroTitle1": "Anda dalam perjalanan!",
    "threeMonthslandingHeroText": "Segala sesuatu yang Anda perlu tahu tentang Marriott International ada di The Gateway.",
    "threeMonthslandingButtonText": "Masuk Gateway",
    "threeMonthslandingHeroTitle1NewToRole": "Anda dalam perjalanan!",
    "threeMonthslandingHeroTextNewToRole": "Semua yang Anda butuhkan untuk mencapai kesuksesan di peran baru Anda ada di The Gateway.",
    "threeMonthslandingButtonTextNewToRole": "Masuk Gateway",
    "threeMonthslandingHeroTitle1NewToBrand": "Selamat Datang Kembali!",
    "threeMonthslandingHeroTextNewToBrand": "Selamat telah menyelesaikan %{numDays} hari pertama Anda dengan merek baru. Anda dapat mengakses semua Item Tindakan, Merek, dan Sumber Daya Departemen kapan pun Anda membutuhkannya.",
    "threeMonthslandingButtonTextNewToBrand": "Masuk Gateway",
    "fourMonthslandingHeroTitle1": "Selamat! Anda berhasil melalui Hal Dasar Anda!",
    "fourMonthslandingHeroText": "Dapatkan akses ke segala sesuatu yang Anda perlu tahu tentang Marriott International di The Gateway.",
    "fourMonthslandingButtonText": "Masuk Gateway",
    "fourMonthslandingHeroTitle1NewToRole": "Selamat! Anda berhasil melalui Hal Dasar Anda!",
    "fourMonthslandingHeroTextNewToRole": "Dapatkan akses ke The Gateway untuk semua yang Anda butuhkan guna mencapai kesuksesan di peran baru Anda.",
    "fourMonthslandingButtonTextNewToRole": "Masuk Gateway",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - Check In",
    "fourMonthslandingHeroTextNewToBrand": "Saat Anda mendekati penyelesaian tahun pertama Anda bersama %{brandName}, jika Anda belum melakukannya, selesaikan semua Item Tindakan dan manfaatkan sumber daya pengembangan yang tersedia di Digital Learning Zone. Klik ‘Lanjutkan’ untuk bergerak maju dalam perjalanan Anda.",
    "fourMonthslandingButtonTextNewToBrand": "Lanjutkan",
    "gmSecondVisitlandingHeroTitle1": "Selamat Datang Kembali!",
    "gmSecondVisitlandingHeroText": "Semoga hari-hari pertama Anda terasa luar biasa. Jangan lupa melanjutkan perjalanan Anda untuk membantu Anda berkembang di peran yang baru.",
    "gmSecondVisitlandingButtonText": "Lanjutkan",
    "gmSecondVisitlandingHeroTextNewToRole": "Semoga hari-hari pertama Anda di peran baru terasa luar biasa. Jangan lupa melanjutkan perjalanan Anda dan mendapatkan akses ke The Gateway untuk semua yang Anda butuhkan guna mencapai kesuksesan di peran baru.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Semoga hari-hari pertama Anda bersama %{brandName} terasa luar biasa. Jangan lupa melanjutkan perjalanan Anda dan mendapatkan akses ke The Gateway untuk semua yang Anda butuhkan",
    "gmThirdVisitlandingHeroTitle1": "Selamat Datang Kembali!",
    "gmThirdVisitlandingHeroText": "Selamat telah menyelesaikan %{numDays} hari pertama dari perjalanan karyawan baru Anda. Anda dapat mengakses semua Item Tindakan, Merek, dan Sumber Daya Departemen kapan pun Anda membutuhkannya.",
    "gmThirdVisitlandingButtonText": "Masuk Gateway",
    "gmThirdVisitlandingHeroTextNewToRole": "Selamat telah menyelesaikan %{numDays} hari pertama di peran baru Anda. Anda dapat mengakses semua Item Tindakan, Merek, dan Sumber Daya Departemen kapan pun Anda membutuhkannya.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Selamat telah menyelesaikan %{numDays} hari pertama Anda bersama merek baru Anda. Anda dapat mengakses semua Item Tindakan, Merek, dan Sumber Daya Departemen kapan pun Anda membutuhkannya.",
    "gmSevenMonthslandingHeroTitle1": "Sepertinya Anda memiliki Item Tindakan untuk diselesaikan.",
    "gmSevenMonthslandingHeroText": "Saat Anda mendekati penyelesaian tahun pertama Anda, jangan lupa untuk menyelesaikan semua Item Tindakan dan memanfaatkan sumber daya pengembangan yang tersedia di Digital Learning Zone. Klik ‘Lanjutkan’ untuk bergerak maju dalam perjalanan Anda",
    "gmSevenMonthslandingButtonText": "Lanjutkan",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - Check In",
    "gmSevenMonthslandingHeroTextNewToRole": "Saat Anda mendekati penyelesaian tahun pertama Anda, jika Anda belum melakukannya, selesaikan semua Item Tindakan dan manfaatkan sumber daya pengembangan yang tersedia di Digital Learning Zone. Klik ‘Lanjutkan’ untuk bergerak maju dalam perjalanan Anda",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - Check In",
    "gmSevenMonthslandingHeroTextNewToBrand": "Saat Anda mendekati penyelesaian tahun pertama Anda bersama %{brandName}, jika Anda belum melakukannya, selesaikan semua Item Tindakan dan manfaatkan sumber daya pengembangan yang tersedia di Digital Learning Zone. Klik ‘Lanjutkan’ untuk bergerak maju dalam perjalanan Anda.",
    "gmOneYearlandingHeroTitle1": "Selamat atas 1 tahun bersama %{brandName}!",
    "gmOneYearlandingHeroTitle1NewToRole": "Selamat atas 1 tahun Anda di peran baru!",
    "gmOneYearlandingHeroTitle1NewToBrand": "Selamat atas 1 tahun Anda di peran baru bersama %{brandName}!",
    "gmOneYearlandingHeroText": "Perjalanan The Gateway mungkin sudah berakhir tetapi yang terbaik belum datang. Anda dapat mengakses sumber daya dari tab The Gateway kapan pun.",
    "gmOneYearlandingButtonText": "Lihat Gateway"
  },
  "resources": {
    "iptTooltip": "Gunakan tanda koma “,” untuk memisahkan beberapa lokasi.",
    "iptAllFunctionTooltip": "Hapus centang untuk memilih Fungsi Pekerjaan spesifik. Tetap centang guna melihat pelatihan untuk semua fungsi di lokasi yang dipilih",
    "iptAllAWRTooltip": "Hapus centang untuk memilih Tanggung Jawab Pekerjaan Tambahan spesifik. Tetap centang guna melihat pelatihan untuk Tanggung Jawab Pekerjaan Tambahan di lokasi yang dipilih",
    "editJFTooltip": "Gunakan tanda koma “,” untuk memisahkan beberapa entri."
  },
  "Notification Center: ": "Tandai sebagai Belum Dibaca",
  "Notification Center: Empty": "Anda sudah mendapatkan informasi terbaru!",
  "Notification Center: Individual Delete": "Apakah Anda yakin ingin menghapus pemberitahuan ini? Pemberitahuan yang dihapus dipindahkan ke bagian Dihapus di Pusat Pemberitahuan hingga masa berlakunya habis.",
  "Notification Center: Delete All": "Apakah Anda yakin ingin menghapus semua pemberitahuan? Pemberitahuan akan dipindahkan ke bagian Dihapus di Pusat Pemberitahuan hingga masa berlakunya habis.",
  "Notification Center: Mark All As Read": "Tandai Semua Sudah Dibaca",
  "Notification Center: Restore All": "Pulihkan Semua",
  "Notification Center: Delete Confirmation": "Hapus",
  "Warning Message Text: Remove Job Function or AWR": "Hapus Fungsi Pekerjaan atau Tanggung Jawab Pekerjaan Tambahan (AWR)?\r\nDengan Mengirimkan, Anda akan menghapus %{Function and/or AWR} dari %{count} Pelajar.",
  "Warning Message Text: Add Job Function or AWR": "Tambahkan Fungsi Pekerjaan atau Tanggung Jawab Pekerjaan Tambahan (AWR)?\r\nDengan Mengirimkan, Anda akan menambahkan %{Function and/or AWR} ke %{count} Pelajar.",
  "Button": "Kirim",
  "Success Message Text: Add Job Function and/or AWR": "Berhasil\r\nAnda telah menambahkan %{Job Function and/or AWR} ke associate yang diminta di %{location}. \r\n\r\nApakah Anda ingin mengubah Fungsi Pekerjaan tambahan atau Tanggung Jawab Pekerjaan Tambahan untuk %{location}?",
  "Message Text:AWR": "Berhasil\r\nAnda telah menambahkan Tanggung Jawab Pekerjaan Tambahan %{ __} ke associate yang diminta di %{ __}.\r\n\r\nApakah Anda ingin mengubah Fungsi Pekerjaan tambahan atau Tanggung Jawab Pekerjaan Tambahan untuk %{location, property, learner(s)}?",
  "Success Message Text: Remove Job Function": "Berhasil\r\nAnda telah menghapus %{Job Function and/or AWR} dari associate yang diminta di %{location}. \r\n\r\nApakah Anda ingin mengubah Fungsi Pekerjaan tambahan atau Tanggung Jawab Pekerjaan Tambahan untuk %{location}?",
  "Success Message Text: Remove AWR": "Berhasil\r\nAnda telah menghapus Tanggung Jawab Pekerjaan Tambahan %{ __} dari associate yang diminta di %{ __} .\r\n\r\nApakah Anda ingin mengubah Fungsi Pekerjaan Tambahan atau Tanggung Jawab Pekerjaan Tambahan untuk %{location, property, learner(s)}?",
  "Tool Tip - Managed Change Email": "Untuk mengubah email Anda, buka mHUB > HR Direct > Saya > Info Publik Saya. Ikuti petunjuk untuk memperbarui detail kontak Anda. Mungkin perlu 1-2 hari kerja agar email yang diperbarui muncul.",
  "Tool Tip - Franchised  Change Email": "Untuk mengubah email Anda, hubungi Manajer Anda dan/atau Administrator EID Tool Waralaba Anda. Mungkin perlu 1-2 hari kerja agar email yang diperbarui muncul.",
  "User Profile: Email Opt-in": "Tidak, terima kasih",
  "User Profile: Email Frequency": "Ringkasan Mingguan",
  "Generic Notification Email Subject Line:": "Ada pemberitahuan baru di DLZ",
  "Generic Body Text (<150 Characters)": "Silakan kunjungi DLZ untuk melihat perinciannya.",
  "All Emails: Signature": "Digital Learning Zone - Pembelajaran menjadi mudah. Dimulai dengan Anda.",
  "All Emails: Footer": "Anda dapat berhenti berlangganan/berhenti menerima pemberitahuan email kapan saja. Kunjungi Digital Learning Zone/Profil untuk memperbarui preferensi Anda.",
  "Overdue Emails: Subject Line: ": "Tugas DLZ yang Perlu Anda Periksa",
  "Overdue Emails: Body Test": "Anda memiliki Tugas Yang Sudah Terlambat Diserahkan. Harap masuk ke DLZ untuk meninjau tugas Anda.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Pertajam Keterampilan Anda",
    "sharpenYourSkillsSubTitle": "Bangun pengetahuan dan keterampilan Anda untuk meningkatkan karier Anda, sekarang dan di masa depan.",
    "growYourCareerTitle": "Kembangkan Karier Anda",
    "growYourCareerSubTitle": "Rencanakan langkah Anda selanjutnya dengan keterampilan tertentu atau pelatihan lintas bidang yang mendukung minat dan tujuan khusus Anda.",
    "additionalResourcesTitle": "Sumber Daya Tambahan",
    "additionalResourcesSubTitle": "Jelajahi getAbstract® dan CultureWizard™. Lihat cara DLZ dapat terus membantu Anda berkembang.",
    "tourHeader": "Selamat datang di DLZ yang diperbarui. Sekarang Anda dapat mencari untuk menemukan apa pun yang Anda perlukan, saat Anda memerlukannya. Selain itu, Anda akan menemukan Jelajahi Pembelajaran yang diperbarui dan Rencana Saya yang lebih personal. Ikuti tur!",
    "tourBtnSkip": "Berhenti",
    "tourBtnNext": "Tutup (6/6)",
    "tourSearchBar": "Temukan apa yang Anda perlukan, saat Anda memerlukannya, dengan fitur Pencarian yang baru dan lebih tangguh.",
    "tourBtnBack": "Kembali",
    "tourMyPlan": "Rencana Saya sekarang memiliki item yang Anda tambahkan dari Penelusuran dan Jelajahi sehingga Anda dapat mengambil kendali penuh atas kemajuan belajar Anda!",
    "tourExploreLearningLink": "Anda juga masih bisa mendapatkan Jelajahi Pembelajaran dari sini!",
    "tourExploreLearningProfile": "Manfaatkan pengalaman belajar Anda sebaik-baiknya dan lihat profil Anda, terutama fungsi pekerjaan, secara rutin agar Rencana Saya selalu terbaru.",
    "mhubTraining": "Pelatihan mHUB",
    "growthSkills": "Keterampilan Pertumbuhan",
    "leadershipSkills": "Keterampilan Kepemimpinan",
    "virtualTeamResources": "Sumber Daya Tim Virtual",
    "jobFunctions": "Fungsi Pekerjaan",
    "brands": "Merek",
    "languageLearning": "Pembelajaran Bahasa",
    "become": "Perubahan",
    "emeaMentoringProgram": "Program Mentoring EMEA",
    "learningPathways": "Jalur Pembelajaran",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare Community Hub",
    "mhubTrainingDesc": "Temukan jalur pembelajaran berbasis peran dan kursus khusus topik tentang cara menggunakan mHUB.",
    "growthSkillsDesc": "Dapatkan pengetahuan praktis di berbagai fungsi dan topik.",
    "leadershipSkillsDesc": "Temukan cara mengelola dan melatih tim, serta menjadi pemimpin yang lebih efektif.",
    "virtualTeamResourcesDesc": "Pelajari teknik untuk mendorong budaya kolaboratif dan inklusif dalam tim virtual.",
    "jobFunctionsDesc": "Perluas pengetahuan tentang fungsi pekerjaan Anda saat ini dan pelajari tentang orang lain.",
    "brandsDesc": "Pelajari lebih lanjut tentang beragam merek Marriott International.",
    "languageLearningDesc": "Berkomunikasi secara efektif dengan audiens global.",
    "becomeDesc": "Temukan pendekatan total untuk pertumbuhan dan pengembangan karier: refleksikan, rencanakan, bertindak, sempurnakan.",
    "emeaMentoringProgramDesc": "Buka potensi Anda dengan Program Mentoring EMEA.",
    "learningPathwaysDesc": "Jelajahi peluang pembelajaran khusus yang dikuratori untuk tingkat kepemimpinan Anda.",
    "mdaDesc": "Bangun keterampilan kepemimpinan dan keterampilan fungsional dengan Marriott Development Academy.",
    "takeCareGrowDesc": "Identifikasi sumber daya yang dirancang untuk membangun komunitas yang inklusif dan terinspirasi.",
    "getAbstractDesc": "Pelajari sesuatu yang baru setiap hari dengan ringkasan buku yang membantu Anda membuat keputusan yang lebih baik.",
    "cultureWizardDesc": "Kumpulkan pengetahuan berharga untuk berkomunikasi secara efektif lintas budaya yang beragam.",
    "takeCareCommunityHubDesc": "Temukan aktivitas pembangunan , program pembelajaran, dan alat tim TakeCare.",
    "searchAllLearning": "Telusuri Semua Pembelajaran",
    "searchBarPlaceHolder": "Selalu ada hal yang bisa dipelajari.",
    "clearFilters": "Kosongkan Filter",
    "noResults": "Tidak Ada Hasil",
    "hasBeenUnpinned": "{Variable-tile_Name} telah dilepaskan.",
    "hasBeenPinned": "{Variable-tile_Name} telah disematkan.",
    "lpRemovedFromPlan": "Jalur Pembelajaran telah dihapus dari rencana Anda.",
    "courseRemovedFromPlan": "Kursus telah dihapus dari rencana Anda.",
    "lpAddedToPlan": "Jalur Pembelajaran telah ditambahkan ke rencana Anda.",
    "courseAddedToPlan": "Kursus telah ditambahkan ke rencana Anda.",
    "clearSelection": "Hapus pilihan",
    "removeFromMyPlan": "Hapus dari Rencana Saya",
    "addToMyPlan": "Tambahkan ke Rencana Saya",
    "loadingResults": "Memuat",
    "viewDetails": "Lihat Perincian",
    "clearAll": "Hapus Semua",
    "noOptions": "Tidak ada pilihan tersedia",
    "viewLess": "Tampilkan Sedikit",
    "viewMore": "Tampilkan Lebih Banyak",
    "recentSearch": "Pencarian Terakhir",
    "exploreLearningTitle": "Jelajahi Pembelajaran",
    "exploreLearningHelpText": "Bingung dengan hal yang ingin Anda cari?",
    "exploreMore": "Jelajahi Lebih Lanjut.",
    "viewAllResults": "Tampilkan Semua <#> Hasil",
    "searchSubTitle": "Telusuri kursus menggunakan kata kunci, judul, atau ID konten.",
    "pinnedLearningTitle": "Pembelajaran Saya yang Disematkan",
    "landingSubTitle": "Kuasai perjalanan belajar Anda. Jelajahi minat Anda – selalu ada kursus dan topik baru yang ditambahkan setiap saat.",
    "columnTitle": "Judul",
    "columnDesc": "Deskripsi",
    "columnType": "Jenis",
    "columnAddedToPlan": "Ditambahkan Ke Rencana",
    "columnComplete": "Selesai",
    "columnDetails": "Rincian",
    "additionalSearchFilters": "Filter Pencarian Tambahan",
    "tourBtnClose": "Tutup",
    "tourBtnLast": "Tutup",
    "resultsFor": "Hasil untuk ",
    "resultsFortheFilter": "Hasil untuk filter {Filter Name}",
    "no": "Tidak",
    "results": "Hasil",
    "courseTooltipTitle": "Item pembelajaran individu yang dapat terdiri dari satu atau lebih aktivitas.<br />Kursus bisa diadakan secara online, seperti video atau e-Learning, atau diadakan di ruang kelas.",
    "gatewayToolTipTitle": "Sumber daya digital untuk karyawan baru, mereka yang baru mempelajari manajemen, dan manajer umum yang baru saja mengenal merek mereka.",
    "speciallizationOrPathwayTooltipTitle": "Serangkaian kursus yang berfokus pada topik tertentu.",
    "taskTooltipTitle": "Instruksi untuk menyelesaikan suatu aktivitas (biasanya di tempat kerja) dan kemudian menandai bahwa Anda telah menyelesaikannya.",
    "webSiteTooltipTitle": "Situs web apa pun, termasuk situs Marriott atau situs web eksternal."
  },
  "dontShowAgain": "Jangan tampilkan ini lagi",
  "deletionWarningEnabled": "Peringatan penghapusan diaktifkan",
  "deletionSelected": "Ketika dipilih, Anda akan melihat peringatan setiap kali Anda menghapus pemberitahuan.",
  "manageEmailNotifications": "Kelola Pemberitahuan Email",
  "seeHowDLZContinue": "Lihat cara DLZ dapat terus membantu Anda berkembang.",
  "Notification Center:Overdue": "Anda memiliki satu pelatihan baru yang telah jatuh tempo pada {Variable date ddmmyy}",
  "accesDenied": {
    "title": "Akses ke Perjalanan Belajar Anda sedang berlangsung! ",
    "subtitle": "Akses Anda sedang dibuat dan akan tersedia dalam 1-2 hari setelah EID Anda aktif. Jika prosesnya sudah lebih dari 2 hari, silakan hubungi Pusat Layanan mHUB."
  },
  "jfTour": {
    "title": "Kini Anda dapat melihat dan mengedit fungsi pekerjaan yang dipilih dan tanggung jawab kerja tambahan (AWR) langsung dari Rencana Saya."
  },
  "onboardingPlan": {
    "requiredDescription": "Di bawah ini adalah kursus yang harus diselesaikan dalam jangka waktu yang Anda pilih di Rencana Saya. Diskusikan dengan pemimpin Anda untuk menjadwalkan waktu guna menyelesaikan pembelajaran yang diperlukan saat Anda menerima peran baru. Anda akan mempelajari tentang berbagai topik, pastikan untuk menghubungi pemimpin dan rekan kerja Anda jika memiliki pertanyaan tentang kursus ini.   ",
    "basicsDescription": "Kami tahu ada banyak hal yang harus dipelajari, dan kami telah memilih beberapa hal dasar untuk beberapa hari pertama Anda bekerja. Item-item ini mungkin tambahan untuk orientasi Anda, orientasi merek, atau informasi dari perusahaan manajemen waralaba Anda. Ini akan menjadi sumber informasi yang memadai sepanjang karier Anda bersama kami dan membantu saat Anda memiliki anggota tim baru.",
    "recommendedDescription": "Setelah 90 hari pertama, Anda akan menemukan kursus tambahan yang telah direkomendasikan oleh manajer Anda. Anda juga dapat menjelajahi topik yang menarik bagi Anda. Untuk saat ini, fokuslah pada pembelajaran dasar Anda. ",
    "day1": "Jatuh tempo pada hari ke-14",
    "day2": "Jatuh tempo pada hari ke 30",
    "day3": "Jatuh tempo pada hari ke 60",
    "day4": "Jatuh tempo pada hari ke 90",
    "day5": "Jatuh tempo setelah 90 hari "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone adalah titik awal untuk pembelajaran dan pengembangan profesional Anda. Di bawah ini Anda akan menemukan konten yang dirangkai untuk membantu Anda memulai. Semua pembelajaran Anda akan selalu tersedia di Rencana Saya.  ",
    "overallLearningProgress": "Kemajuan Pembelajaran Secara Keseluruhan",
    "learningProgressLabel": "Kemajuan Pembelajaran",
    "learningProgressDescription": "Ini adalah cara mencapai kemajuan atas pembelajaran yang Anda butuhkan. Bilah kemajuan ini mencakup status semua pembelajaran yang Anda butuhkan. Pertahankan prestasi Anda!"
  },
  "poc": {
    "dlz": "Agar pembelajaran ini dapat diselesaikan, Manajer atau Koordinator Pembelajaran harus menandai selesainya pembelajaran ini di mHUB. Rincian tentang cara melakukannya dapat ditemukan di Panduan Pelaksanaan.",
    "mhub": "Penerima Waralaba:  jika Anda memilih menggunakan program pelatihan untuk kebersihan milik lembaga Anda sendiri, harap sertakan nama dan rincian programnya di bagian komentar saat Anda menandainya sebagai selesai"
  },
  "Recommended Courses ": "Setelah 90 hari pertama, Anda akan menemukan kursus tambahan yang telah direkomendasikan oleh manajer Anda. Anda juga dapat menjelajahi topik yang menarik bagi Anda. Untuk saat ini, fokuslah pada pembelajaran dasar Anda. ",
  "survey": {
    "v2": {
      "weValueButtonText": "Ikuti Survei",
      "lbiIntro": "Selamat Datang di Pemeriksaan Perilaku Mewah!",
      "lbiDescription": `<p>Di bawah ini Anda akan diminta untuk membaca beberapa skenario singkat yang mungkin Anda temui di properti Anda. Bacalah setiap skenario dan pilih cara Anda akan menanggapi setiap situasi. Ingatlah hal berikut saat Anda menanggapi:</p>
      <ul>
          <li>
              <p>Anda mungkin menemukan bahwa lebih dari satu jawaban yang dapat dipilih, tampak benar. Pilih tanggapan yang paling mungkin Anda ambil di tempat kerja.</p>
          </li>
          <li>
              <p>Asumsikan tidak ada hambatan komunikasi. Bahasa yang Anda gunakan sama dengan bahasa yang digunakan tamu atau karyawan dalam skenario.</p>
          </li>
          <li>
              <p>Skenario mungkin meminta Anda untuk membayangkan diri Anda dalam peran pekerjaan yang berbeda dari peran Anda saat ini. Jawab pertanyaan sejauh pengetahuan Anda, berdasarkan pengalaman Anda sebagai tenaga profesional perhotelan mewah.</p>
          </li>
      </ul>
      <p><br></p>
      <p>Harap diperhatikan: Partisipasi Anda dalam pemeriksaan ini sepenuhnya bersifat sukarela (Anda dapat memilih untuk tidak berpartisipasi), dan jawaban Anda akan sepenuhnya dirahasiakan. Hasil pemeriksaan ini akan dilaporkan untuk sekelompok orang dan bukan untuk individu tertentu. Jawaban individu akan dievaluasi oleh tim riset dan tidak akan dibagikan kepada Manajer Tim Lain atau pemimpin.</p>
      <p>Dengan menyelesaikan pemeriksaan ini, Anda menyetujui pengumpulan serta pemrosesan data dan jawaban pribadi Anda (misalnya lama bekerja, jenis kelamin, jabatan pekerjaan) serta pemindahan lintas negara data pribadi Anda ke Marriott Group. Hal ini sesuai dengan Pernyataan Privasi Data Karyawan Marriott, dan Anda juga dapat menemukan informasi serta kontak tambahan sebagai referensi di Kebijakan Privasi Data Marriott.</p>
      `,
      "checkin": {
        q1Title: "Anda sedang berjalan melewati lobi dan melihat rombongan keluarga dengan tiga anak kecil. Keluarga tersebut membawa banyak tas, terlihat stres karena perjalanan sehari penuh, serta anak-anak tersebut mengeluh dan tampak gelisah. Apa yang akan Anda lakukan?",
        q2Title: "Anda baru mulai bekerja di sebuah properti di area yang baru bagi Anda. Seorang tamu meminta saran untuk hal-hal yang dapat dilakukan di area tersebut. Tamu tersebut telah membaca beberapa buku panduan tetapi ia mencari sesuatu yang lebih bersifat pribadi. Apa yang akan Anda lakukan? ",
        q3Title: "Ketika Anda sedang berjalan melewati lobi, Anda mendengar seorang tamu sedang berbicara di telepon dan mengetahui layanan mobil ke bandara mereka sudah terlambat. Properti Anda tidak memiliki mobil hotel atau mobil sedang tidak tersedia. Apa yang akan Anda lakukan? ",
        q4Title: "Anda sedang berjalan melewati lobi untuk menyelesaikan tugas mendesak yang diberikan oleh manajer Anda kepada Anda. Anda melihat seorang rekan kerja kesulitan menyelesaikan tugas yang biasanya diselesaikan oleh dua orang. Apa yang akan Anda lakukan? ",
        q5Title: "Anda adalah host di sebuah restoran hotel. Seorang tamu meminta untuk melihat menu sambil menunggu meja yang dipesan. Setelah melihat menu, tamu itu frustrasi karena restoran hotel itu disarankan kepada mereka oleh rekan kerja lain, tetapi tidak ada satu pun makanan dalam menu yang memenuhi kebutuhan diet mereka. Apa yang akan Anda lakukan?",
        q6Title: "Ada rombongan besar tamu di area kolam renang yang ramai, dan mereka tidak dapat menemukan tempat untuk duduk bersama. Akibatnya, mereka meletakkan handuk dan barang pribadi mereka sedemikian rupa sehingga menghalangi jalan utama, menciptakan bahaya dan gangguan bagi tamu dan karyawan yang mencoba mengakses area kolam renang. Area tersebut harus dibersihkan, tetapi Anda tidak ingin membuat rombongan tersebut kesal. Apa yang akan Anda lakukan? ",
        q1R1Title: "Membantu keluarga tersebut menangani bagasi mereka, mengarahkan mereka ke meja check-in, lalu menyarankan beberapa tempat yang santai dan dekat, yang memiliki aktivitas ramah keluarga. Sebutkan fasilitas keluarga di properti. ",
        q1R2Title: "Menawarkan untuk membantu mereka menangani bagasi dan memandu keluarga tersebut untuk check-in. Kemudian, mengatur layanan santai atau suguhan untuk orang tua dan aktivitas menyenangkan untuk anak-anak di kamar mereka.",
        q1R3Title: "Memandu orang tua untuk check-in, kemudian secara pribadi mengantar anak-anak ke area duduk dengan kudapan selagi orang tua mereka check-in. Minta resepsionis memberi tahu orang tua tentang area bermain dan aktivitas anak-anak di properti demi kenyamanan mereka.",
        q1R4Title: "Menunjukkan meja check-in kepada keluarga tersebut agar mereka dapat melakukan proses check-in secara cepat. Sebutkan area dan layanan ramah anak kepada orang tua agar anak-anak dapat menikmati masa inap yang menyenangkan.",
        q2R1Title: "Menanyakan kepada tamu hal yang mereka sukai, kemudian bekerja sama dengan rekan kerja untuk membuat daftar kegiatan menyenangkan untuk mereka lakukan. Selain itu, sarankan restoran yang menyenangkan di dekat properti.",
        q2R2Title: "Berbicara dengan tamu tentang hal yang mereka sukai. Bekerja sama dengan rekan kerja untuk membuat daftar hal spesial yang menyenangkan, khusus untuk mereka. Selain itu, periksa profil Bonvoy tamu dan kirim suguhan yang disesuaikan ke kamarnya.",
        q2R3Title: "Buat suasana pribadi dengan berbagi beberapa hal favorit untuk dilakukan versi Anda sendiri di area tersebut sejauh ini. Buat saran Anda menonjol dengan berbagi detail tambahan tentang pengalaman Anda. Kemudian, kirim suguhan lokal yang lezat ke kamar mereka.",
        q2R4Title: "Memberi tahu tamu bahwa Anda orang baru di area tersebut dan tidak memiliki banyak gagasan. Meminta maaf dan menyarankan agar ia berbicara dengan rekan kerja Anda yang lebih tahu. Kemudian, tanyakan, apakah tamu berkenan untuk menerima suguhan lokal di kamar mereka.",
        q3R1Title: "Saat tamu masih menelepon, bawa kendaraan pribadi Anda sendiri atau milik rekan kerja Anda ke depan properti dan beri tahu tamu bahwa Anda akan mengantar mereka secara pribadi ke bandara.",
        q3R2Title: "Saat tamu masih menelepon, susun daftar nomor telepon dan layanan mobil yang disetujui properti. Seusai tamu menelepon, tunjukkan daftar dan tawarkan untuk membantu mengatur transportasi.",
        q3R3Title: "Tunggu sampai tamu selesai menelepon dan beri tahu mereka bahwa properti Anda tidak menawarkan layanan transportasi, tetapi Anda akan dengan senang hati membantu mengoordinasikan opsi transportasi lainnya. ",
        q3R4Title: "Saat tamu masih menelepon, cari transportasi alternatif untuk tamu. Seusai mereka menelepon, beri tahu tamu bahwa Anda telah mengatur transportasi mereka ke bandara dan mobil akan segera tiba.",
        q4R1Title: "Menghampiri rekan kerja Anda dan mengatakan Anda akan membantu mereka, tetapi Anda harus menyelesaikan tugas mendesak Anda terlebih dahulu. Berjanji untuk segera kembali membantu mereka. Pastikan untuk membahas situasi tersebut dengan manajer Anda.",
        q4R2Title: "Pertama, segera cari rekan kerja lain untuk mengambil alih tugas yang diberikan kepada Anda. Kemudian, langsung bantu rekan kerja Anda yang membutuhkan bantuan. Setelah selesai, beri tahu manajer Anda bahwa Anda menemukan orang lain untuk menyelesaikan tugas Anda, sehingga Anda dapat membantu rekan kerja Anda. ",
        q4R3Title: "Berhenti dan bantu rekan kerja Anda saat itu juga. Seusai membantu, kembali ke tugas yang diberikan kepada Anda, kemudian beri tahu manajer Anda bahwa Anda menunda tugas Anda untuk membantu rekan kerja.",
        q4R4Title: "Pergi ke rekan kerja Anda yang membutuhkan bantuan dan menjelaskan bahwa Anda sedang mengerjakan tugas mendesak. Hentikan kedua tugas, lakukan sumbang saran mengenai cara untuk menyelesaikan kedua tugas Anda secara bersama-sama, lalu selesaikan kedua tugas sekaligus.",
        q5R1Title: "Meminta maaf kepada tamu dan memberi tahu mereka bahwa Anda akan memanggil manajer. Pastikan untuk menjelaskan situasinya, sehingga manajer dapat membantu tamu memenuhi kebutuhan makanan mereka.",
        q5R2Title: "Meminta maaf kepada tamu dan menanyakan preferensi makanan mereka. Bicara dengan tim dapur untuk membuat hidangan spesial khusus bagi mereka. Selain itu, beri mereka makanan pembuka atau minuman gratis untuk menebus kesalahan tersebut.",
        q5R3Title: "Meminta maaf dan memberi tahu tamu bahwa Anda mengerti. Alih-alih membatalkan reservasi, tawarkan untuk berbicara dengan chef dan mencari tahu, apakah chef dapat membuat sajian spesial bagi tamu. Beri tamu suguhan atau diskon gratis untuk menebus masalah tersebut.",
        q5R4Title: "Meminta maaf dan memberi tahu tamu bahwa Anda dapat membantu mereka memesan meja di restoran terdekat yang memiliki makanan yang mereka sukai. Tawarkan info atau bantuan tambahan untuk sampai ke sana jika diperlukan.",
        q6R1Title: "Meminta maaf kepada tamu karena kurangnya ruang dan memberi tahu mereka bahwa mereka boleh tetap berada di tempat selama mereka memperhatikan orang lain yang lewat. ",
        q6R2Title: "Memberi tahu tamu secara baik-baik bahwa mereka tidak boleh menghalangi jalan, dan mereka harus mencari tempat lain atau menunggu sampai ada ruang kosong. Sementara itu, mintalah rekan kerja Anda untuk membawa tempat duduk tambahan dari ballroom untuk digunakan oleh tamu, di luar. ",
        q6R3Title: "Memberi tahu tamu bahwa mereka tidak dapat berada di lokasi mereka saat ini dan mereka harus pindah untuk sementara selagi Anda mencari tempat duduk tambahan. Tawarkan ruang di bar/restoran untuk sementara waktu. ",
        q6R4Title: "Meminta maaf kepada tamu karena masalah tempat duduk. Menanyakan, apakah tamu tersebut dapat mengumpulkan barang mereka untuk memberi jalan bagi orang lain yang lewat. Tawarkan untuk membantu mereka, pastikan semua orang merasa nyaman. Kirim suguhan spesial kepada mereka dari bar/restoran atas ketidaknyamanan mereka."
      }
    }
  },
};