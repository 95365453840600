import { themePrimaryColorsBrand } from '../style-constants';

export default {
  extendedTheme: () => ({
    palette: {
      primary: {
        main: themePrimaryColorsBrand.Corporate
      },
      primaryBtnHover: {
        main: '#cc774e',
      },
      buttonText: {
        main: '#1c1c1c'
      },
      secondary: {
        main: '#444054'
      },
      interaction: {
        main: '#B84C16'
      },
      text: {
        primary: '#1c1c1c'
      },
      link: {
        primary: '#1c1c1c'
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        '"ProximaNova-Regular"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
    },
  }),
  typography: (theme, remCalc) => {
    return {
      h1: {
        fontFamily: 'Swiss721BT',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(54),
          lineHeight: 1.11
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(34),
          lineHeight: 1.24
        }
      },
      h2: {
        fontFamily: 'Swiss721BT',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(40),
          lineHeight: 1.15
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(30),
          lineHeight: 1.2
        }
      },

    }
  },
  extraTypography: (theme, remCalc) => {
    return {
      hero: {
        fontFamily: 'Swiss721BT',
        fontWeight: 'bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(44),
          lineHeight: 1.16
        }
      },
      card: {
        fontFamily: 'Swiss721BT',
        fontWeight: 'bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(38),
          lineHeight: 1.16
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(28),
          lineHeight: 1.21
        }
      }
    }
  }

};
