export const application = 'dlz-dev';

export const apiURL = 'http://localhost:4000/dev/api/api/dlz2';

export const cmdbURL = 'http://localhost:5200/cmdb/api';

export const logoutEnabled = true;

export const sessionCookieName = 'connect.sid';

export const gaId = 'UA-91016801-7';
