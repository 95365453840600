import { action, computed, decorate, observable } from 'mobx';

import Api from '../api/LDApi';
import CMDBApi from '../api/CMDBApi';
import GatewayStore from './GatewayStore';
import _ from 'lodash';
import cookies from '../util/cookies';
import courseStore from './CourseStore';
import languageStore from './LanguageStore';
//import authStore from './AuthStore';
import lookupStore from './LookupStore';
import moment from 'moment';
import toplineStore from './ToplineStore';
import userNotificationStore from './UserNotificationStore';

const associateData = require('./associateData.json');
let toplineAccessData = require('./toplinePilotAccess.json');
toplineAccessData = toplineAccessData.map(eid => eid.toLowerCase());
console.log(toplineAccessData);

const GM_JOB_FUNCTION_IDS = [1, 2, 121, 176]
class UserStore {
  userInfo = {};
  completionInfo = [];
  submittingAttestation = false;
  initialRoute = window.location.pathname;
  selectedCourseDetail = null;

  profileMenuShow = false;
  notificationMenuShow = false;

  completedToplinePhaseTwo = false;
  addedToPlanLps = [];
  addedToPlanResources = [];

  setSelectedCourseDetail(course) {
    this.selectedCourseDetail = course;
  }

  setUserProfile(user) {
    let profile = _.get(user, 'dlz2.profile', {});
    if (profile.brands) {
      profile.brands = lookupStore.getBrandsLabels(profile.brands);
    }

    if (profile.jobFunctions) {
      profile.jobFunctions = lookupStore.getJobFunctionLabels(profile.jobFunctions);
    }

    if (profile.interests && profile.interests.map) {
      profile.interests = lookupStore.getExploreTagLabels(profile.interests);
    }

    if (profile.additionalJobFunctions && profile.additionalJobFunctions.map) {
      profile.additionalJobFunctions = lookupStore.getAdditionalJobFunctionLabels(profile.additionalJobFunctions);

      if (profile.additionalJobFunctions.length === 0 && profile.hasCompletedWelcomeMat) {
        profile.additionalJobFunctions.push({
          id: -1,
          name: 'N/A'
        })
      } else if (!profile.hasCompletedWelcomeMat && _.isEmpty(cookies.getCookie('impersonationEid'))) {
        profile.additionalJobFunctions = [];
      }
    }

    user.profile = profile;
    // user.resourceRecommendations = _.get(user, 'dlz2.recommendations');
    this.addedToPlanResources = _.get(user, 'dlz2.addedToPlanResources');
    this.addedToPlanLps = _.get(user, 'dlz2.addedToPlanLps');
    // add LP recommendations here

    this.isAuthenticated = true;
    this.user = user;
    //authStore.start(); // start tracking auth session
    if (this.user.dlz2) {
      const impersonationLanguage = cookies.getCookie('impersonationLanguage') || this.user.dlz2.profile.preferredLanguage || 'en';
      languageStore.setLanguage(impersonationLanguage);

      const reporting = _.get(this.user, 'dlz2.reporting.access', '');

      if (reporting.type === 'marsha' && reporting.locationCodes.length > 0) {
        this.hasReportingAccess = true;
      } else if (reporting.type === 'region' && reporting.regions.length > 0) {
        this.hasReportingAccess = true;
      } else if (reporting.type === 'arne') {
        this.hasReportingAccess = true;
      } else {
        this.hasReportingAccess = false;
      }
    }

    this.completedToplinePhaseTwo = _.get(user, 'topline.completedPhaseTwo', false);
  }

  async login(username) {
    try {
      const { data } = await Api.login(username);
      if (data !== undefined) {
        this.setUserProfile(data);
      }
    } catch (e) {
      // TODO
    }
  }

  async logout() {
    try {
      await Api.logout();
      // this.isAuthenticated = false;
      // this.user = null;
      //authStore.stop(); // stop tracking auth session
    } catch (e) {
      // TODO
    }
  }

  async fetchUserProfileLite() {
    try {
      const { data } = await Api.getUserProfile();
      const { data: gatewayPermissions } = await CMDBApi.getGatewayPermissions();
      this.setGatewayPermissions(gatewayPermissions);
      this.setUserProfile(data);
      this.setToplinePermissions();
    } catch (e) {
      console.log(e);
    }
  }

  async fetchUserProfile() {
    if (this.user) {
      return;
    }

    try {
      this.isFetchingUserProfile = true;
      const { status, data } = await Api.getUserProfile();
      const { data: gatewayPermissions } = await CMDBApi.getGatewayPermissions();
      this.setGatewayPermissions(gatewayPermissions);

      window.user = data;
      if (status === 401) {
        this.isAuthenticated = false;
        this.ssoUrl = data.ssoUrl;
      } else {
        if (data !== undefined) {
          await lookupStore.getBaseData();
          if (window.location.pathname.indexOf('validator') === -1) {
            await courseStore.getCourses();
            courseStore.getContentGroups();
            courseStore.getRequiredCompletions();
          }
          this.setUserProfile(data);
          if (window.location.pathname.indexOf('validator') === -1) {
            this.setToplinePermissions();
            this.getUserCompletions().then(() => {
              courseStore.setPercentComplete();
            });
          }
        }
      }

      this.isFetchingUserProfile = false;
    }
    catch (e) {
      this.isFetchingUserProfile = false;
      this.isAuthenticated = false;
    }
  }

  async fetchUserInfo() {
    const { status, data } = await Api.getUserInfo();

    if (data) {
      this.userInfo = data;
    } else {
      console.error('Error while fetching user info. Status', status);
    }
  }

  async getUserCompletions() {
    const { status, data } = await CMDBApi.getCompletionInfo();
    if (data) {
      this.completionInfo = data;
    } else {
      console.error('Error while fetching user info. Status', status);
    }
  }

  get lastCompletionDate() {
    if (!this.selectedCourseDetail || this.completionInfo == null) {
      return null;
    }

    const sorted = _.orderBy(this.completionInfo.filter(c => c.completionDate != null && (c.componentID === (this.selectedCourseDetail.sourceId || '').replace('Lobster_', '')) || (c.resourceId && c.resourceId === this.selectedCourseDetail.id)), c => c.completionDate).reverse(); 
    if (sorted.length > 0) {
      return moment(sorted[0].completionDate).add(5, 'hours').format('MMMM D, YYYY');
    }

    return null;
  }

  getName(key) {
    let name = _.get(this.user, `dlz2.profile.userFileRaw2.${key}`, '');
    if (_.isEmpty(name)) {
      name = _.get(this.user, `dlz2.profile.userFileRaw2.${key}`, '');
    }
    if (name !== '') {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    } else {
      return '';
    }
  }

  get isGeneralManager() {
    return this.user.dlz2.profile.jobFunctions.findIndex(jf => jf.id === 1) !== -1;
  }

  get firstName() {
    return this.getName('First Name');
  }

  get lastName() {
    return this.getName('Last Name Surname');
  }

  get profile() {
    let profile = (this.user && this.user.dlz2 && this.user.dlz2.profile) || {};

    return profile;
  }

  impersonate = eid => {
    cookies.setCookie('impersonationEid', eid, 1);
    cookies.setCookie('impersonationLanguage', this.user.dlz2.profile.preferredLanguage, 1);
    if (window.location.pathname.toLowerCase() === '/become') {
      window.location.reload();
    } else if (window.location.pathname.toLowerCase().indexOf('validator') !== -1) {
      window.location.href = '/validator';
    } else {
      window.location.href = '/';
    }
  };

  stopImpersonation = async () => {
    cookies.eraseCookie('impersonationEid');
    cookies.eraseCookie('impersonationLanguage');
    await Api.clearImpersonation();
    window.location.reload();
  }

  get isImpersonating() {
    return !_.isEmpty(cookies.getCookie('impersonationEid'));
  }

  addNewToBrandFlag = (dataToSave) => {
    if (dataToSave.brands && this.isManagerInGateway) {
      const profileBrands = this.user.dlz2.profile.brands.map((brand) => brand.id);
      const dataToSaveBrands = dataToSave.brands.map((brand) => brand.id);
      const addedBrands = _.difference(dataToSaveBrands, profileBrands);
      const removedBrands = _.difference(profileBrands, dataToSaveBrands);
      const currentGatewayBrands = [...this.user.dlz2.profile.gatewayBrandsToShow];

      if (!_.isEmpty(addedBrands)) {
        dataToSave.gatewayBrandsToShow = currentGatewayBrands.concat(addedBrands);
      }

      if (!_.isEmpty(removedBrands)) {
        dataToSave.gatewayBrandsToShow = _.difference(dataToSave.gatewayBrandsToShow, removedBrands);
      }

      if (!_.isEmpty(dataToSave.gatewayBrandsToShow)) {
        dataToSave.newToBrand = true;
        dataToSave.newToRole = false;
        dataToSave.lastGatewayDesignation = 'newToBrand';
        dataToSave.newToBrandStartDate = new Date();
      } else {
        dataToSave.lastGatewayDesignation = null;
        dataToSave.newToBrand = false;
        dataToSave.newToBrandStartDate = null;
      }
    }
  };

  addNewToRoleFlag = (dataToSave) => {
    if (dataToSave.managementLevel) {
      if (dataToSave.managementLevel === 'Management Associate'
        && _.get(this.user, 'dlz2.profile.managementLevel') !== 'Management Associate') {
        dataToSave.newToRole = true;
        dataToSave.newToBrand = false;
        dataToSave.gatewayBrandsToShow = [];
        dataToSave.lastGatewayDesignation = 'newToRole';
        dataToSave.newToRoleStartDate = new Date();
      }
      else if (dataToSave.managementLevel !== 'Management Associate') {
        dataToSave.lastGatewayDesignation = null;
        dataToSave.newToRole = false;
        dataToSave.newToRoleStartDate = null;
      }
    }

    if (_.get(dataToSave, 'jobFunctions')) {
      let addedGMRole = false;
      const wasAlreadyGM = this.user.dlz2.profile.jobFunctions.find(jf => GM_JOB_FUNCTION_IDS.includes(jf.id))

      dataToSave.jobFunctions.forEach(jf => {
        if (GM_JOB_FUNCTION_IDS.includes(jf.id)) {
          dataToSave.newToRole = true;
          dataToSave.newToBrand = false;
          dataToSave.gatewayBrandsToShow = [];
          dataToSave.lastGatewayDesignation = 'newToRole';
          dataToSave.newToRoleStartDate = new Date();
          addedGMRole = true;
        }
      });

      if (!addedGMRole && !wasAlreadyGM && _.get(this.user, 'dlz2.profile.managementLevel') !== 'Management Associate') {
        dataToSave.lastGatewayDesignation = null;
        dataToSave.newToRole = false;
        dataToSave.newToRoleStartDate = null;
      }
    }
  }

  saveToProfile = async (dataToSave = {}, refreshCourseData = true, refreshExploreData = true, refreshProfile = true) => {
    if (Object.keys(dataToSave).indexOf('startYear') !== -1) {
      // const now = moment();

      // if (now.isBefore(moment(`${dataToSave.startMonth}-${dataToSave.startDay}-${dataToSave.startYear}`, 'M-D-YYYY'))) {
      //   throw new Error('Date provided is in the future.');
      // }
    }

    // if(this.profile.hasCompletedWelcomeMat) {
    this.addNewToRoleFlag(dataToSave);
    this.addNewToBrandFlag(dataToSave);
    // }
    let mergedData = { ...this.user.dlz2.profile, ...dataToSave };
    this.sanitizeProfile(mergedData);

    // Check to make sure AWRs are appropriate for brand.
    // this.user.dlz2.profile.additionalJobFunctions
    const response = await Api.updateUserProfile({ _id: this.user._id, profile: mergedData });
    const { data: gatewayPermissions } = await CMDBApi.getGatewayPermissions();
    this.setGatewayPermissions(gatewayPermissions);

    if (refreshCourseData) {
      courseStore.getCourses();
      lookupStore.getBaseData();
    }

    if (refreshExploreData) {
      courseStore.getExploreCourses();
    }

    if (refreshProfile) {
      this.setUserProfile(response.data);
      courseStore.getCourses().then(() => {
        courseStore.getRequiredCompletions();
        userNotificationStore.fetchNotifications(true, false, true);
      });
    }    
  }

  setGatewayPermissions(gatewayPermissions) {
    GatewayStore.getGatewayResources();
    this.canSeeGateway = gatewayPermissions.canSeeGateway;
    this.isManagerInGateway = gatewayPermissions.isManagerInGateway;
    this.isGatewayNewHire = gatewayPermissions.isGatewayNewHire;
  }

  isUSCanada() {
    const regionId = _.find(lookupStore.regions, r => r.name === _.get(this.profile, 'region', 'Americas US East')).id;
    return [20, 21, 22, 23, 24, 25, 26, 27, 33, 34, 35, 60].indexOf(regionId);
  }

  setToplinePermissions() {
    this.canSeeTopline = ['dpate818', 'mhune346', 'mmint850', 'ksker726', 'abroo197', 'jmett048', 'rsnyd705', 'dwar880', 'ksmcg486', 'dewar880', 'abroo192', 'megal678', 'behme298', 'ohakv579', 'hvqkd840', 'hoia508', 'uhvhu569', 'gjthc347', 'bshvd692'].indexOf(this.user.eid) !== -1; // TODO: change by JF
    if (!this.canSeeTopline && moment().isAfter(moment('02/15/2022 06:45', 'MM/DD/YYYY hh:mm'))) {
      this.canSeeTopline = toplineAccessData.indexOf(this.user.eid) !== -1
    }
  }

  sanitizeProfile(profile) {
    if (profile.brands.length === 1) {
      profile.brandKey = profile.brands[0].id;
    } else if (profile.brands.length === 0) {
      //Use the corporate brand key when no brands are selected
      profile.brandKey = '31';
    } else {
      //Use the first selected brand when multiple brands present
      profile.brandKey = profile.brands[0].id.toString();
    }

    profile.brands = profile.brands.map((brand) => brand.id);
    profile.jobFunctions = profile.jobFunctions.map((jobFunction) => jobFunction.id);
    profile.interests = profile.interests.map((interest) => ({ groupKey: interest.groupKey, id: interest.id }));
    profile.additionalJobFunctions = profile.additionalJobFunctions.map((additionalJobFunction) => additionalJobFunction.id).filter(awrId => {
      const foundAWR = _.find(lookupStore.additionalJobFunctions, r => r.id === awrId);
      if (!foundAWR) {
        return false;
      }

      if (foundAWR.brands && _.intersection(profile.brands, foundAWR.brands.map(b => parseInt(b, 10))).length === 0) {
        return false;
      }

      return true;
    });
  }

  async handleMarkCompleteClicked(course) {
    this.submittingAttestation = true;
    await CMDBApi.markComplete(course);
    courseStore.getCourses();
    this.submittingAttestation = false;
  }

  get hireDate() {
    const startDay = _.get(this.user, 'dlz2.profile.startDay');
    const startMonth = _.get(this.user, 'dlz2.profile.startMonth');
    const startYear = _.get(this.user, 'dlz2.profile.startYear');

    if (startDay && startMonth && startYear) {
      return moment(`${startMonth}/${startDay}/${startYear}`);
    }
    return null;
  }

  get isHiredWithinNinetyDays() {
    const startDay = _.get(this.user, 'dlz2.profile.startDay');
    const startMonth = _.get(this.user, 'dlz2.profile.startMonth');
    const startYear = _.get(this.user, 'dlz2.profile.startYear');

    const ninetyDays = moment().subtract(90, 'days');
    if (startDay && startMonth && startYear) {
      return true; //moment(`${startMonth}/${startDay}/${startYear}`, 'M/D/YYYY').isAfter(ninetyDays);
    }
  }

  get gatewayStartDate() {
    const newToRoleStartDate = _.get(this.user, 'dlz2.profile.newToRoleStartDate');
    const newToBrandStartDate = _.get(this.user, 'dlz2.profile.newToBrandStartDate');

    if(!newToRoleStartDate && !newToBrandStartDate) {
      return this.hireDate;
    } else if(!newToRoleStartDate && newToBrandStartDate) {
      return moment(newToBrandStartDate);
    } else if(newToRoleStartDate && !newToBrandStartDate) {
      return moment(newToRoleStartDate);
    } else {
      if(moment(newToRoleStartDate).isAfter(moment(newToBrandStartDate))) {
        return moment(newToRoleStartDate);
      } else {
        return moment(newToBrandStartDate);
      }
    }

  }

  get myTeam() {
    return associateData;
  }

  get isFranchised() {
    return _.get(this.profile, 'employeeType') === 'Franchise';
  }

  get isManaged() {
    return _.get(this.profile, 'employeeType') === 'Managed';
  }

  get acknowledgedBonvoy() {
    return _.get(this.profile, 'acknowledgedBonvoy') == true;
  }

  

  get isManager() {
    return this.profile && this.profile.managerOfOthers;
  }

  get location() {
    return this.profile && this.profile.userFileRaw2 ? this.profile.userFileRaw2['Unit Location'] : '';
  }

  get jobTitle() {
    return this.profile && this.profile.userFileRaw2 ? this.profile.userFileRaw2.Title : '';
  }

  get regionId() {
    return _.find(lookupStore.regions, r => r.name === _.get(this.profile, 'region', 'Americas US East')).id;
  }

  get employeeTypeId() {
    return _.find(lookupStore.employeeTypes, r => r.name === _.get(this.profile, 'employeeType', 'Franchise')).id;
  }

  get managementLevelId() {
    const level = _.get('managementLevel', this.profile, 'Management Associate');
    if (level === 'Management Associate') {
      return lookupStore.managementLevels.find(m => m.id === 1).id;
    } else if (level === 'Non-Management (Hourly)') {
      return lookupStore.managementLevels.find(m => m.id === 2).id;
    } else if (level === 'Supervisor') {
      return lookupStore.managementLevels.find(m => m.id === 3).id;
    } else {
      return '';
    }
  }

  get isLearningCoordinator() {
    return this.profile && this.profile.isLearningCoordinator;
  }

  get isSuperAdmin() {
    return this.profile && this.profile.isSuperAdmin;
  }

  get hideGatewayBrandTab() {
    const userBrandIds = this.profile.brands.map((b) => b.id);
    const hideableBrandIds = [26, 29, 12]; //St Regis, W Hotels, Gaylord
    const isAllExcludedBrands = _.intersection(hideableBrandIds, userBrandIds).length === userBrandIds.length;

    return isAllExcludedBrands && this.isFranchised;
  }

  get preferredLanguage() {
    return this.user.dlz2.profile.preferredLanguage;
  }

  setNeedsToplinePhaseTwo() {
    this.completedToplinePhaseTwo = false;
    toplineStore.setAdvanceToPhaseTwo();
  }

  get needsToplinePhaseTwo() {
    return !this.completedToplinePhaseTwo;
  }

  toggleProfileShow() {
    this.profileMenuShow = !this.profileMenuShow;
    this.notificationMenuShow = false;
  }

  toggleNotificationShow() {
    this.notificationMenuShow = !this.notificationMenuShow;
    this.profileMenuShow = false;
  }

  hideMenus() {
    this.notificationMenuShow = false;
    this.profileMenuShow = false;
  }

  get enableEnhancedOnboarding() {

    const startDay = _.get(this.user, 'dlz2.profile.startDay');
    const startMonth = _.get(this.user, 'dlz2.profile.startMonth');
    const startYear = _.get(this.user, 'dlz2.profile.startYear');

    if (startDay && startMonth && startYear) {
      const date = moment(`${startMonth}/${startDay}/${startYear}`, 'M/D/YYYY');
      const startDate = moment(`12/31/2022`, 'M/D/YYYY');
      if (date.isAfter(startDate)) {
        return true;
      }
    }

    return false;
  }
}

decorate(UserStore, {
  userInfo: observable,
  user: observable,
  isFetchingUserProfile: observable,
  isAuthenticated: observable,
  ssoUrl: observable,
  hasReportingAccess: observable,
  submittingAttestation: observable,
  completedToplinePhaseTwo: observable,
  completionInfo: observable,
  fetchUserProfile: action,
  getUserCompletions: action,
  fetchUserInfo: action,
  login: action,
  logout: action,
  isGeneralManager: computed,
  firstName: computed,
  profile: computed,
  isFranchised: computed,
  hireDate: computed,
  gatewayStartDate: computed,
  isManaged: computed,
  isManager: computed,
  isLearningCoordinator: computed,
  isSuperAdmin: computed,
  jobTitle: computed,
  location: computed,
  regionId: computed,
  managementLevelId: computed,
  employeeTypeId: computed,
  saveToProfile: action,
  impersonate: action,
  isImpersonating: computed,
  handleMarkCompleteClicked: action,
  initialRoute: observable,
  canSeeGateway: observable,
  isManagerInGateway: observable,
  isGatewayNewHire: observable,
  hideGatewayBrandTab: computed,
  lastCompletionDate: computed,
  needsToplinePhaseTwo: computed,
  selectedCourseDetail: observable,
  setSelectedCourseDetail: action,
  canSeeTopline: observable,
  hideMenus: action,
  toggleProfileShow: action,
  toggleNotificationShow: action,
  profileMenuShow: observable,
  notificationMenuShow: observable,
  addedToPlanResources: observable,
  addedToPlanLps: observable,
  preferredLanguage: computed,
  enableEnhancedOnboarding: computed,
  acknowledgedBonvoy: computed,
  isHiredWithinNinetyDays: computed
});

export default new UserStore();
