import languageStore from './LanguageStore';
import { decorate, observable, action, computed } from 'mobx';
import * as mobx from 'mobx';
import LanguageStore from './LanguageStore';
import IPTStore from './IPTStore';
import UserStore from './UserStore';
import Api from '../api/CMDBApi';
import _ from 'lodash';

const filterLookupValue = (values, employeeType, brands) => values.filter(v => {
  const debug = false;

  const log = msg => {
    if (debug) {
      console.log(msg);
    }
  }

  log(v);
  log(employeeType);
  log(brands);

  const employeeTypeMap = {
    'Managed': 1,
    'Franchise': 2,
    'Contractor': 3
  }

  const valueBrands = _.get(v, 'extra.selectedBrands', []).map(s => parseInt(s, 10));
  const employeeTypes = _.get(v, 'extra.selectedEmployeeTypes', []).map(s => parseInt(s, 10));

  if (valueBrands.length > 0) {
    if (_.intersection(brands.map(b => parseInt(b, 10)), valueBrands).length === 0) {
      return false;
    }
  }
  
  if (employeeTypes.length > 0) {
    if (employeeTypes.indexOf(employeeTypeMap[employeeType]) === -1) {
      return false;
    }
  }

  return true;
});

class LookupStore {

  selectedLocations = []

  async getBaseData() {
    try {
      const { data } = await Api.getBaseData();
      if (data !== undefined) {
        this.brandTopics = data.brandTopics;
        this.sources = data.sources;
        this.managementLevels = data.managementLevels;
        this.jobFunctions = data.jobFunctions.filter(jf => jf.JobFunctionCategory.name !== 'Additional Work Responsibilities' && jf.name !== 'MIHQ/Headquarters' && jf.name !== 'Continent / Above Property');
        this.validatorRoles = data.validatorRoles.filter(jf => jf.JobFunctionCategory.name !== 'Additional Work Responsibilities' && jf.name !== 'MIHQ/Headquarters' && jf.name !== 'Continent / Above Property');
        this.additionalJobFunctions = data.additionalWorkResponsibilities;
        this.disciplines = data.disciplines;
        this.leadership = data.leadership;
        this.skills = data.skills;
        this.brands = data.brands;
        this.languages = data.languages;
        this.programs = data.programs;
        this.coursePlans = data.coursePlans;
        this.jobFunctionCategories = data.jobFunctionCategories;
        this.assignmentTypes = data.assignmentTypes;
        this.languageLearnings = data.languageLearnings;
        this.regions = data.regions.filter(r => r.id > 10);
        this.employeeTypes = data.employeeTypes;
        this.recertifications = data.recertifications;
        this.workingRemotely = data.workingRemotely || [];
        this.mhubTopicCategories = data.mhubTopicCategories || [];
        this.pms = data.pms || [];
        this.continents = data.continents || [];
        this.brandSegments = data.brandSegments || [];
        this.countries = data.countries || [];
        this.salesCatering = data.salesCatering || []
      }
    } catch (e) {
      console.error(e);
      // TODO
    }
  }

  setReportingBaseData(reportingBaseData) {
    this.groups = reportingBaseData.groups;
    this.subgroups = reportingBaseData.subgroups;
    this.specialties = reportingBaseData.specialties;
    this.subspecialties = reportingBaseData.subSpecialties;
    this.franchiseCompanies = reportingBaseData.franchiseCompanies;
    this.owners = reportingBaseData.owners;
  }

  get coursePlansWithCategories() {
    return this.programs.map(p => ({
      id: p.id,
      name: p.name,
      groupKey: 'program',
      isCategory: true,
      subItems: this.coursePlans.filter(cp => _.uniq(_.compact((cp.programs || []).concat(cp.programTagId))).map(p => parseInt(p, 10)).indexOf(p.id) !== -1).map(cp => ({...cp, groupKey: 'coursePlan', name: cp.label}))
    }));
  }

  get jobFunctionsWithCategories() {
    return this.jobFunctionCategories.map(jfc => ({
      id: jfc.id,
      name: jfc.name,
      groupKey: 'jobCategory',
      isCategory: true,
      subItems: this.jobFunctions.filter(jf => jf.categoryId === jfc.id).map(jf => ({...jf, groupKey: 'jobFunction'}))
    }));
  }

  get exploreTagsByGroup() {

    const getByKey = key => {
      if (!LanguageStore.lang || !LanguageStore.lang.t) {
        return key;
      }

      return LanguageStore.lang.t(key);
    };

    const { user } = UserStore;

    // if (!user) {
    //   console.log('USER!!');
    //   console.log(window.user);
    // }

    const employeeType = _.get(user, 'dlz2.profile.employeeType', 'Managed');
    let brands = [];
    
    if (user && user.dlz2.profile.brands) {
      brands = mobx.toJS(user.dlz2.profile.brands);
      if (brands.map) {
        brands = brands.map(b => {
          if (typeof b === 'object') {
            return b.id;
          } else {
            return b;
          }
        })
      }
    }

    return [
      {
        groupName: getByKey('landing.mhubTopicCategories'),
        groupKey: 'mhubTopicCategories',
        hasNewContent: true,
        items: filterLookupValue(this.mhubTopicCategories, employeeType, brands).map((item) => ({ id: item.id, name: item.name, groupKey: 'mhubTopicCategories' }))
      },
      {
        groupName: getByKey('landing.growthSkills'),
        groupKey: 'skills',
        items: filterLookupValue(this.skills, employeeType, brands).map((item) => ({ id: item.id, name: item.name, groupKey: 'skills' }))
      },
      {
        groupName: getByKey('landing.leadershipSkills'),
        groupKey: 'leadership',
        items: filterLookupValue(this.leadership, employeeType, brands).map((item) => ({ id: item.id, name: item.name, groupKey: 'leadership' }))
      },
      {
        groupName: getByKey('landing.workingRemotely'),
        groupKey: 'workingRemotely',
        items: filterLookupValue(this.workingRemotely, employeeType, brands).map((item) => ({ id: item.id, name: item.name, groupKey: 'workingRemotely' }))
      },
      {
        groupName: getByKey('landing.jobFunctions'),
        groupKey: 'disciplines',
        items: _.uniqBy(this.jobFunctionCategories.filter(jfc => !jfc.excludeFromExplore), jf => jf.name).map((item) => ({ id: item.id, name: item.name, groupKey: 'disciplines' }))
      },
      {
        groupName: getByKey('landing.brands'),
        groupKey: 'brandTopics',
        items: filterLookupValue(this.brandTopics, employeeType, brands).map((item) => ({ id: item.id, name: item.name, groupKey: 'brandTopics' }))
      },
      {
        groupName: getByKey('landing.languageLearning'),
        groupKey: 'languageLearnings',
        items: filterLookupValue(this.languageLearnings, employeeType, brands).map((item) => ({ id: item.id, name: item.name, groupKey: 'languageLearnings' }))
      }
    ]
  }

  get jobFunctionsByGroup() {
    const groups = _.groupBy(this.jobFunctions, jf => jf.JobFunctionCategory.name);
    const allFunctions = [];

    Object.keys(groups).filter(g => g !== 'Additional Work Responsibilities').sort().forEach(group => {
      allFunctions.push({
        groupName: group,
        items: groups[group].map(g => ({ id: g.id, name: g.name, description: g.description, brands: mobx.toJS(g.brands) }))
      });
    });

    return allFunctions;
  }

  get additionalJobFunctionsByGroup() {
    return [{
      groupName: languageStore.lang.t('profile.additionalWork'),
      items: this.additionalJobFunctions
    }];
  }

  get brandsByGroup() {
    return [{
      groupName: languageStore.lang.t('profile.brands'),
      items: this.brands.map((brand) => ({ id: brand.id, name: brand.label, code: brand.name }))
    }];
  }

  get brandsById() {
    let brandsMap = {};

    this.brands.forEach((brand) => {
      brandsMap[brand.id] = brand.label;
    });

    return brandsMap;
  }

  get brandsByCode() {
    let brandsMap = {};

    this.brands.forEach((brand) => {
      brandsMap[brand.id] = brand.name;
    });

    return brandsMap;
  }

  get brandsByLabel() {
    let brandsMap = {};

    this.brands.forEach((brand) => {
      brandsMap[brand.id] = brand.label;
    });

    return brandsMap;
  }

  get programsById() {
    let programsMap = {};

    this.programs.forEach(program => {
      programsMap[program.id] = program.name;
    });

    return programsMap;
  }

  get disciplinesById() {
    let disciplinesMap = {};

    this.disciplines.forEach(discipline => {
      disciplinesMap[discipline.id] = discipline.name;
    });

    return disciplinesMap;
  }

  get jobFunctionsById() {
    let jobFunctionMap = {}

    this.jobFunctions.forEach((jobFunction) => {
      jobFunctionMap[jobFunction.id] = jobFunction.name;
    });

    return jobFunctionMap;
  }

  get validatorRolesById() {
    let jobFunctionMap = {}

    this.validatorRoles.forEach((jobFunction) => {
      jobFunctionMap[jobFunction.id] = jobFunction.name === 'Leader/Department Head' ? `Leader/Department Head - ${jobFunction.JobFunctionCategory.name}` : jobFunction.name;
    });

    return jobFunctionMap;
  }

  get additionalJobFunctionsById() {
    let jobFunctionMap = {};

    this.additionalJobFunctions.forEach((jobFunction) => {
      jobFunctionMap[jobFunction.id] = jobFunction.name;
    });

    return jobFunctionMap;
  }

  get validatorAdditionalJobFunctionsById() {
    let jobFunctionMap = {};

    this.additionalJobFunctions.filter(ajf => !ajf.excludeFromValidator).forEach((jobFunction) => {
      jobFunctionMap[jobFunction.id] = jobFunction.name;
    });

    return jobFunctionMap;
  }

  get languagesById() {
    let languageMap = {};

    this.languages.forEach((language) => {
      languageMap[language.id] = language.name;
    });

    return languageMap
  }

  get exploreTagsById() {
    let exploreTagsMap = {};

    this.exploreTagsByGroup.forEach((exploreTag) => {
      exploreTagsMap[exploreTag.groupKey] = {};
      exploreTag.items.forEach((item) => {
        exploreTagsMap[exploreTag.groupKey][item.id] = item.name;
      });
    });

    return exploreTagsMap;
  }

  get iptRoles() {
    const employeeTypeId = UserStore.employeeTypeId;

    const locationFilter = model => {
      if (this.selectedLocations.length === 0) {
        return true;
      }

      let debug = false;

      if (model.name === 'General Manager in position on or after January 1, 2014') {
        debug = true;
      }

      const log = msg => {
        if (debug) {
          console.log(msg)
        }
      }

      log(model.name)

      const allBrands = this.selectedLocations.map(l => l.brandId.toString());
      const allRegions = this.selectedLocations.map(l => l.regionId.toString());

      if (model.brands && model.brands.length > 0 && _.intersection(mobx.toJS(model.brands), allBrands).length === 0) {
        log('false1`')
        return false;
      }
      log(mobx.toJS(model.regions))
      log(allRegions.map(r => r.toString()));
      if (model.regions && model.regions.length > 0 && _.intersection(mobx.toJS(model.regions), allRegions).length === 0) {
        log('false2`')
        return false;
      }
      if (model.employeeTypes && model.employeeTypes.length > 0 && model.employeeTypes.map(e => parseInt(e, 10)).indexOf(employeeTypeId) === -1) {
        console.log(model.employeeTypes);
        log('false3`')
        return false;
      }

      log('TRUE')
      return true;
    }

    const onProperty = this.jobFunctionCategories.filter(jfc => jfc.name !== 'Above Property and HQ' && jfc.name !== 'Additional Work Responsibilities').map(jfc => ({
      title: jfc.name,
      children: this.jobFunctions.filter(locationFilter).filter(jf => jf.categoryId === jfc.id).map(jf => ({
        id: jf.id,
        key: jf.name,
        value: jf.name,
        description: jf.description
      }))
    }));

    console.log(this.jobFunctionCategories);
    const aboveProperty = this.jobFunctionCategories.filter(jfc => jfc.name === 'Above Property and HQ' && jfc.name !== 'Additional Work Responsibilities').map(jfc => ({
      title: jfc.name,
      children: this.jobFunctions.filter(locationFilter).filter(jf => jf.categoryId === jfc.id).map(jf => ({
        id: jf.id,
        key: jf.name,
        value: jf.name,
        description: jf.description
      }))
    }));

    const additionalRoles = [{
      title: 'Additional Work Responsibilities',
      children: this.additionalJobFunctions.filter(locationFilter).map(ajf => ({
        id: ajf.id,
        key: ajf.name,
        value: ajf.name,
        description: ajf.description
      }))
    }];

    return {
      onProperty: onProperty.filter(p => p.children.length > 0),
      aboveProperty: aboveProperty.filter(p => p.children.length > 0),
      additionalRoles: additionalRoles.filter(p => p.children.length > 0)
    };
  }

  get vRoles() {
    const employeeTypeId = UserStore.employeeTypeId;

    const locationFilter = model => {
      if (this.selectedLocations.length === 0) {
        return true;
      }

      let debug = false;

      if (model.name === 'General Manager in position on or after January 1, 2014') {
        debug = true;
      }

      const log = msg => {
        if (debug) {
          console.log(msg)
        }
      }

      log(model.name)

      const allBrands = this.selectedLocations.map(l => l.brandId.toString());
      const allRegions = this.selectedLocations.map(l => l.regionId.toString());

      if (model.brands && model.brands.length > 0 && _.intersection(mobx.toJS(model.brands), allBrands).length === 0) {
        log('false1`')
        return false;
      }
      log(mobx.toJS(model.regions))
      log(allRegions.map(r => r.toString()));
      if (model.regions && model.regions.length > 0 && _.intersection(mobx.toJS(model.regions), allRegions).length === 0) {
        log('false2`')
        return false;
      }
      if (model.employeeTypes && model.employeeTypes.length > 0 && model.employeeTypes.map(e => parseInt(e, 10)).indexOf(employeeTypeId) === -1) {
        console.log(model.employeeTypes);
        log('false3`')
        return false;
      }

      log('TRUE')
      return true;
    }

    const onProperty = this.jobFunctionCategories.filter(jfc => jfc.name !== 'Above Property and HQ' && jfc.name !== 'Additional Work Responsibilities').map(jfc => ({
      title: jfc.name,
      children: this.validatorRoles.filter(locationFilter).filter(jf => jf.categoryId === jfc.id).map(jf => ({
        id: jf.id,
        key: jf.name,
        value: jf.name,
        description: jf.description
      }))
    }));

    const aboveProperty = this.jobFunctionCategories.filter(jfc => jfc.name === 'Above Property and HQ' && jfc.name !== 'Additional Work Responsibilities').map(jfc => ({
      title: jfc.name,
      children: this.validatorRoles.filter(locationFilter).filter(jf => jf.categoryId === jfc.id).map(jf => ({
        id: jf.id,
        key: jf.name,
        value: jf.name,
        description: jf.description
      }))
    }));

    const additionalRoles = [{
      title: 'Additional Work Responsibilities',
      children: this.additionalJobFunctions.filter(locationFilter).filter(ajf => !ajf.excludeFromValidator).map(ajf => ({
        id: ajf.id,
        key: ajf.name,
        value: ajf.name,
        description: ajf.description
      }))
    }];

    return {
      onProperty: onProperty.filter(p => p.children.length > 0),
      aboveProperty: aboveProperty.filter(p => p.children.length > 0),
      additionalRoles: additionalRoles.filter(p => p.children.length > 0)
    };
  }

  getBrandsLabels(brandIds) {
    return brandIds.map((id) => ({ id: id, code: this.brandsByCode[id], name: this.brandsById[id], label: this.brandsByLabel[id] })).filter(Boolean);
  }

  getJobFunctionLabels(jobFunctionIds, namesOnly) {
    return jobFunctionIds.map((id) => namesOnly ? this.jobFunctionsById[id] : { id: id, name: this.jobFunctionsById[id] }).filter(j => namesOnly ? !_.isEmpty(j) : !_.isEmpty(j.name)).filter(Boolean);
  }

  getValidatorRoleLabels(jobFunctionIds, namesOnly) {
    return jobFunctionIds.map((id) => namesOnly ? this.validatorRolesById[id] : { id: id, name: this.validatorRolesById[id] }).filter(j => namesOnly ? !_.isEmpty(j) : !_.isEmpty(j.name)).filter(Boolean);
  }

  getAdditionalJobFunctionLabels(jobFunctionIds, namesOnly) {
    return jobFunctionIds.map((id) => namesOnly ? this.additionalJobFunctionsById[id] : { id: id, name: this.additionalJobFunctionsById[id] }).filter(j => namesOnly ? !_.isEmpty(j) : !_.isEmpty(j.name)).filter(Boolean);
  }

  getValidatorAdditionalJobFunctionLabels(jobFunctionIds, namesOnly) {
    return jobFunctionIds.map((id) => namesOnly ? this.validatorAdditionalJobFunctionsById[id] : { id: id, name: this.validatorAdditionalJobFunctionsById[id] }).filter(j => namesOnly ? !_.isEmpty(j) : !_.isEmpty(j.name)).filter(Boolean);
  }

  setSelectedLocations(locations) {
    this.selectedLocations = locations;
  }

  getExploreTagLabels(tags) {
    const mapped = tags.map((tag) => ({
      id: tag.id,
      groupKey: tag.groupKey,
      name: this.exploreTagsById[tag.groupKey] ? this.exploreTagsById[tag.groupKey][tag.id] : ''
    }));

    return mapped.filter(tag => !_.isEmpty(tag.name));
  }

  getLanguageLabels(languageIds, namesOnly) {
    return languageIds.map((id) => namesOnly ? this.languagesById[id] : { id: id, name: this.languagesById[id]}).filter(Boolean);
  }
}

decorate(LookupStore, {
  additionalJobFunctions: observable,
  brandTopics: observable,
  jobFunctions: observable,
  validatorRoles: observable,
  disciplines: observable,
  leadership: observable,
  skills: observable,
  programs: observable,
  assignmentTypes: observable,
  managementLevels: observable,
  languageLearnings: observable,
  regions: observable,
  employeeTypes: observable,
  coursePlans: observable,
  jobFunctionCategories: observable,
  selectedLocations: observable,
  groups: observable,
  subgroups: observable,
  specialties: observable,
  subspecialties: observable,
  franchiseCompanies: observable,
  owners: observable,
  jobFunctionsByGroup: computed,
  iptRoles: computed,
  vRoles: computed,
  getJobFunctionLabels: action,
  getValidatorRoleLabels: action,
  jobFunctionsById: computed,
  validatorRolesById: computed,
  additionalJobFunctionsById: computed,
  validatorAdditionalJobFunctionsById: computed,
  getAdditionalJobFunctionLabels: action,
  getValidatorAdditionalJobFunctionLabels: action,
  brandsByGroup: computed,
  brandsById: computed,
  languagesById: computed,
  programsById: computed,
  disciplinesById: computed,
  coursePlansWithCategories: computed,
  jobFunctionsWithCategories: computed,
  setSelectedLocations: action,
  setReportingBaseData: action,
  getBaseData: action
});

export default new LookupStore();
