// const logo = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/logo.png';
// const heroImageLarge = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/heroLarge.png';
// const heroImageSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/heroSmall.png';
// const heroImageXSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/heroXSmall.png';
// const exploreImageLarge = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/exploreLarge.png';
// const exploreImageSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/exploreSmall.png';
// const exploreImageXSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/exploreXSmall.png';
import { themePrimaryColorsBrand } from '../style-constants';

export default {
  extendedTheme: () => ({
    logo: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/logo.png',
    heroImageLarge: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/heroLarge.png',
    exploreImageLarge: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/ritz/exploreLarge.png',
    palette: {
      primary: {
        main: themePrimaryColorsBrand.RITZ
      },
      secondary: {
        main: '#2e557a'
      },
      primaryBtnHover: {
        main: '#9db0c2',
      },
      buttonText: {
        main: '#1c1c1c'
      },
      interaction: {
        main: '#2e557a'
      },
      text: {
        primary: '#1c1c1c'
      },
      link: {
        primary: '#1c1c1c'
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        '"ProximaNova-Regular"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
    },
  }),
  typography: (theme, remCalc) => {
    return {
      h1: {
        fontFamily: 'Caslon540LTStd-Roman',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(54),
          lineHeight: 1.11
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(34),
          lineHeight: 1.24
        }
      },
      h2: {
        fontFamily: 'Caslon540LTStd-Roman',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(40),
          lineHeight: 1.15
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(30),
          lineHeight: 1.2
        }
      },

    }
  },
  extraTypography: (theme, remCalc) => {
    return {
      hero: {
        fontFamily: 'Caslon540LTStd-Roman',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(44),
          lineHeight: 1.09
        }
      },
      card: {
        fontFamily: 'Caslon540LTStd-Roman',
        fontWeight: 'bold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(38),
          lineHeight: 1.16
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(28),
          lineHeight: 1.21
        }
      }
    }
  }

};
