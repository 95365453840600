import { decorate, observable, action } from 'mobx';
import _ from 'lodash';
import CMDBApi from '../api/CMDBApi';

class AwrStore {
  constructor() {
    this.isLoading = false;
    this.rows = [];
  }

  async fetchMongoUsers(eids = [], locationCodes = []) {
    try {
      this.isLoading = true;
      const { data } = await CMDBApi.fetchMongoUsers(eids, locationCodes);
      this.rows = data;
    } catch(e) {
      console.log('Error caught while fetching learners AWR bulk edit', JSON.stringify(e));
    } finally {
      this.isLoading = false;
    }
  }
  async updateAwrs(payload) {
    try {
      this.isLoading = true;
      const { status } = await CMDBApi.updateAwrs(payload);
      return {
        status,
        message: 'awrs have been updated successfully'
      }
    } catch (e) {
      console.log('Error in updating Awrs', e);
      return {
        status: 500,
        message: ''
      };
    } finally {
      this.isLoading = false;
    }
  }

  async updateValidatorAwrs(payload) {
    try {
      this.isLoading = true;
      const { status } = await CMDBApi.updateValidatorAwrs(payload);
      return {
        status,
        message: 'awrs have been updated successfully'
      }
    } catch (e) {
      console.log('Error in updating Awrs', e);
      return {
        status: 500,
        message: ''
      };
    } finally {
      this.isLoading = false;
    }
  }
}


decorate(AwrStore,  {
  rows: observable,
  isLoading: observable,
  fetchMongoUsers: action,
});

export default new AwrStore();