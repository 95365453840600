import { decorate, action, observable, computed } from 'mobx';
import Api from '../api/LDApi';
// import LanguageStore from './LanguageStore';
import UserStore from './UserStore';

class NotificationStore {
  notificationCounter = 1;
  serverNotifications = [];
  localNotifications = [];
  temporaryNotifications = [];

  async getNotifications() {
    const { data } = await Api.getNotifications();
    const { hasReportingAccess } = UserStore;
    this.serverNotifications = data.filter(d => {
      if (d.page === 'reporting' && !hasReportingAccess) {
        return false;
      }

      return true;
    });
  }

  addNotification(notification) {
    this.localNotifications.push(notification);
  }

  createDismissableNotification(n) {
    const id = this.notificationCounter;
    const notification = { ...n, id };
    this.temporaryNotifications.push(notification);
    return id;
  }

  clearTemporaryNotifcations() {
    this.temporaryNotifications = [];
  }

  dismissNotification(id) {
    this.temporaryNotifications = this.temporaryNotifications.filter(notification => id !== notification.id);
  }

  createTemporaryNotification(n) {
    this.temporaryNotifications.push(n);

    setTimeout(() => {
      this.temporaryNotifications = this.temporaryNotifications.filter(notification => n.description !== notification.description);
    }, n.duration || 3000);
  }

  get notifications() {
    return this.serverNotifications.concat(this.localNotifications);
  }
}

decorate(NotificationStore, {
  clearTemporaryNotifcations: action,
  serverNotifications: observable,
  temporaryNotifications: observable,
  notifications: computed,
  createTemporaryNotification: action,
  getNotifications: action,
  addNotification: action,
  createDismissableNotification: action,
  dismissNotification: action
});

export default new NotificationStore();

// const notificationFormat = [
//   {
//     _id:"5b842d986eabd801add19c49",
//     type:"success",
//     page:"reporting",
//     messageHtml:"RD1 training results are now available for 6 critical functions. Training progress and completion data is now available for viewing. For instructions on how to use the reporting dashboards, please <a style=\"text-decoration: underline;\" href=\"https://vimeo.com/286959949/60b63a6696\" target=\"_blank\">click here</a>.",
//     linkText: "View Details",
//     linkLocation: "/plan"
//   }
// ];

// Example required course text as per the design team:
// You have a required course due in 21 days.

//Example notification for managers
//3 associates have not started their required courses.
//https://app.zeplin.io/project/5d1513aedb0aef274d67b401/screen/5d377e88346e042d91c6231e