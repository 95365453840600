import corporate from './corporate';
import ritz from './ritz';
import acHotels from './acHotels';
import aloft from './aloft';
import autographCollection from './autographCollection';
import bulgari from './bulgari';
import courtyard from './courtyard';
import deltaHotels from './deltaHotels';
import designHotels from './designHotels';
import edition from './edition';
import element from './element';
import fairfield from './fairfield';
import fourPoints from './fourPoints';
import gaylordHotels from './gaylordHotels';
import jwMarriott from './jwMarriott';
import leMeridien from './leMeridien';
import marriottExecutiveApartments from './marriottExecutiveApartments';
import marriottHotels from './marriottHotels';
import marriottVacationClub from './marriottVacationClub';
import moxyHotels from './moxyHotels';
import proteaHotels from './proteaHotels';
import renaissanceHotels from './renaissanceHotels';
import residenceInn from './residenceInn';
import sheraton from './sheraton';
import springhillSuites from './springhillSuites';
import stRegis from './stRegis';
import luxuryCollection from './luxuryCollection';
import townePlaceSuites from './townePlaceSuites';
import tributePortfolio from './tributePortfolio';
import westin from './westin';
import wHotels from './wHotels';
import axonify from './axonify';

const rootTheme = {
  '31': corporate,
  '23': ritz,
  '1': acHotels,
  '2': aloft,
  '18': autographCollection,
  '3': bulgari,
  '4': courtyard,
  '5': deltaHotels,
  '6': designHotels,
  '7': edition,
  '8': element,
  '10': fairfield,
  '11': fourPoints,
  '12': gaylordHotels,
  '54': jwMarriott,
  '13': leMeridien,
  '19': corporate, //marriottConferenceCenters,   // TODO
  '9': marriottExecutiveApartments,
  '15': marriottHotels,
  '16': marriottVacationClub,
  '17': moxyHotels,
  '20': proteaHotels,
  '21': renaissanceHotels,
  '22': residenceInn,
  '24': sheraton,
  '25': springhillSuites,
  '26': stRegis,
  '14': luxuryCollection,
  '63': ritz,
  '27': townePlaceSuites,
  '28': tributePortfolio,
  '32': corporate, //vistana, // TODO
  '30': westin,
  '29': wHotels,
  '-1': axonify
};

export default rootTheme;
