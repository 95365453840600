import { decorate, action, observable, computed } from 'mobx';
import lookupStore from './LookupStore';
import Api from '../api/CMDBApi';
import _ from 'lodash';
import moment from 'moment';

class TeamStore {
  rawTeam = [];
  rawTeamGateway = { byUser: [] };
  managerEidsBreadcrumbs = [];
  loadingTeam = false;
  managerEid = null;
  selectedFilter = {
    required: true,
    recommended: false,
    excludeLoa: true,
  };

  async getTeam() {
    this.loadingTeam = true;
    const team = await Api.getTeam(this.managerEid);
    this.rawTeam = team.data;

    const teamGateway = await Api.getTeamGateway();
    this.rawTeamGateway = teamGateway.data;
    this.loadingTeam = false;
  }

  async getValidatorTeam() {
    this.loadingTeam = true;
    const team = await Api.getValidatorTeam(this.managerEid);
    this.rawTeamValidator = team.data;
    this.loadingTeam = false;
  }

  getGatewayProgress(eid) {
    const progress = this.rawTeamGateway.byUser.find(u => u.eid === eid);
    if (_.isNil(progress)) {
      return null;
    }

    return progress.totalAssigned > 0 ? Math.round((progress.totalCompleted / progress.totalAssigned) * 100) : null;
  }

  get tableHeadingKey() {
    if (this.selectedFilter.required && this.selectedFilter.recommended) {
      return 'associatesTable.progress';
    } else if (this.selectedFilter.required) {
      return 'associatesTable.requiredProgress';
    } else {
      return 'associatesTable.recommendedProgress';
    }
  }

  get selectedFilterCourseType() {
    if (this.selectedFilter.required && this.selectedFilter.recommended) {
      return 'My Plan';
    } else if (this.selectedFilter.required) {
      return 'Required';
    } else {
      return 'Recommended';
    }
  }

  isNewHire(m) {
    return (
      moment.utc(m.hireDate).isSameOrAfter(new Date(), 'day') &&
      (!m.profileDataValidation || m.profileDataValidation.length === 0)
    );
  }

  get teamValidator() {
    const getName = member => {
      return `${_.get(member, `userFileRaw2.First Name`, '')} ${_.get(member, `userFileRaw2.Last Name Surname`, '')}`;
    }
    
    return (this.rawTeamValidator || []).map(member => {
      return {
        id: member.userFileRaw2.EID,
        eid: member.userFileRaw2.EID,
        name: getName(member),
        jobFunctions: member.jobFunctions,
        additionalJobFunctions: member.additionalJobFunctions,
        brands: member.brands.filter(b => b != member.brandKey),
        brandKey: member.brandKey,
        hgc: member.highGuestContact,
        managementLevel: member.managementLevel,
        hireDate: member.hireDate,
        employeeType: member.employeeType,
        profileDataValidation: member.profileDataValidation,
        isNewHire: this.isNewHire(member)
      }
    });
  }

  get teamValidatorNewHires() {
    return this.teamValidator.filter(m => this.isNewHire(m)).map(m => {
      // Remove isNewHire from the object so dialog doesn't show rows as bold
      let { isNewHire: _, ...rest } = m;
      return rest;
    });
  }

  get team() {
    const showRequired = this.selectedFilter.required;
    const showRecommended = this.selectedFilter.recommended;

    let team = this.rawTeam.map((member) => {
      let total = 0, complete = 0;
      total = showRequired ? total + member.totalRequiredCourses : total;
      total = showRecommended ? total + member.totalRecommendedCourses : total;
      complete = showRequired ? complete + member.completedRequiredCourses : complete;
      complete = showRecommended ? complete + member.completedRecommendedCourses : complete;

      return {
        id: member.eid,
        eid: member.eid,
        name: member.name,
        functionTypes: lookupStore.getJobFunctionLabels(member.jobFunctions, true).join(', '),
        progress: total > 0 ? Math.round((complete / total) * 100) : 100,
        gatewayProgress: this.getGatewayProgress(member.eid),
        isLOA: member.isLOA,
        total: member.totalCourses,
        numCoursesComplete: complete,
        complete: member.numCoursesComplete,
        totalCourses: total,
        reportCount: member.reportCount
      }
    });

    return this.selectedFilter.excludeLoa ? team.filter(m => !m.isLOA) : team;
  }

  async updateUserProfileValidator(user) {
    const { data } = await Api.updateValidatorUserProfile(user);
    this.rawTeamValidator[this.rawTeamValidator.findIndex(u => u.userFileRaw2.EID === user.eid)] = data;
  }

  setSelectedFilter(filter) {
    this.selectedFilter = filter;
  }

  setManagerDirectReport(data) {
    this.managerEid = data.eid;
    this.managerEidsBreadcrumbs.push(data);
    this.getTeam();
  }

  setSelectedManagerFromBreadcrumb(eid) {
    this.managerEidsBreadcrumbs = this.managerEidsBreadcrumbs.slice(0, this.managerEidsBreadcrumbs.indexOf(eid));
  }

  backTeamLevel() {
    this.managerEidsBreadcrumbs = this.managerEidsBreadcrumbs.slice(0, this.managerEidsBreadcrumbs.length - 1);

    if (this.managerEidsBreadcrumbs.length === 0) {
      this.managerEid = null;
    } else {
      const managerEid = this.managerEidsBreadcrumbs[this.managerEidsBreadcrumbs.length - 1].eid;
      this.managerEid = managerEid;
    }
    this.getTeam();
  }
}

decorate(TeamStore, {
  getTeam: action,
  rawTeam: observable,
  rawTeamValidator: observable,
  rawTeamGateway: observable,
  team: computed,
  teamValidator: computed,
  teamValidatorNewHires: computed,
  getValidatorTeam: action,
  tableHeadingKey: computed,
  selectedFilter: observable,
  setSelectedFilter: action,
  setManagerDirectReport: action,
  loadingTeam: observable,
  managerEid: observable,
  backTeamLevel: action,
  updateUserProfileValidator: action,
});

export default new TeamStore();
