import { decorate, observable, action, computed  } from 'mobx';
import Api from '../api/LDApi';
import CMDBApi from '../api/CMDBApi';
import CourseStore from './CourseStore';
import _ from 'lodash';

const orderByNotification = n => {
  if (n.status !== 'read' && n.eventType === 'profileChange') {
    return -10;
  } else if (n.status !== 'read' && n.eventType === 'overdueAssignment') {
    return -9;
  } else if (n.status !== 'read' && n.eventType === 'comingDueAssignment') {
    return -8;
  } else if (n.status !== 'read' && n.eventType === 'addAssignment') {
    return -7;
  } else if (n.status !== 'read') {
    return -6;
  }

  if (n.status === 'read' && n.eventType === 'profileChange') {
    return -5;
  } else if (n.status === 'read' && n.eventType === 'overdueAssignment') {
    return -4;
  } else if (n.status === 'read' && n.eventType === 'comingDueAssignment') {
    return -3;
  } else if (n.status === 'read' && n.eventType === 'addAssignment') {
    return -2;
  }

  return 0;
}

class UserNotificationStore {
  notifications = []
  loadingNotifications = true;
  selectedNotification = null;
  previousSelectedNotification = null;

  async fetchNotifications(force = false, loadCMDB = true, includeAdditions = false) {
    try {
      // await CMDBApi.refreshPlan();

      if (!_.isEmpty(this.notifications) && !force) {
        return this.notifications;
      }

      let allNotifications = [];
      this.loadingNotifications = true;

      if (loadCMDB) {
        const { data } = await CMDBApi.getNotifications(includeAdditions);
        const mongoResponse = await Api.getUserNotifications(includeAdditions); 
        if (!_.isEmpty(mongoResponse.data) || mongoResponse.data !== '') {
          allNotifications.push(...mongoResponse.data);
        }
        allNotifications = allNotifications.map(a => {
          const planItem = _.get(a, 'extra.planItem');
          if (planItem && !planItem.title) {
            a.extra.planItem.title = (CourseStore.allRawCourses.find(a => a.id === planItem.id) || {}).title;
          }
          return a;
        });

        // await CMDBApi.refreshPlan();
        this.notifications = _.orderBy(allNotifications, orderByNotification);
      } else {
        if (includeAdditions) {
          // await CMDBApi.refreshPlan();
          await CMDBApi.getNotifications(includeAdditions);
        }
        const mongoResponse = await Api.getUserNotifications(includeAdditions);
        if (!_.isEmpty(mongoResponse.data) || mongoResponse.data !== '') {
          allNotifications.push(...mongoResponse.data);
        }

        allNotifications = allNotifications.map(a => {
          const planItem = _.get(a, 'extra.planItem');
          if (planItem && !planItem.title) {
            a.extra.planItem.title = (CourseStore.allRawCourses.find(a => a.id === planItem.id) || {}).title;
          }

          return a;
        });
        this.notifications = _.orderBy(allNotifications, orderByNotification);
      }

      this.loadingNotifications = false;
    } catch (e) {
      this.loadingNotifications = false;
      console.error(`error is fetching notifications `, e);
    }
  }

  async markAsRead(id) {
    if (!id) return;
    await Api.updateUserNotification(id, { status: 'read' });
    this.fetchNotifications(true, false);
  }

  async createNotification(notification, systemNotification = false) {
    return Api.createUserNotification(notification, systemNotification);
  }

  async markAllRead() {
    const ids = (this.activeNotifications || []).map(n => n._id);
    if (!ids || !ids.length) return;
    await Api.markAllNotificationsAsRead(ids);
    await this.fetchNotifications(true, false);
  }

  async deleteNotification(id) {
    if (!id) return ;
    await Api.deleteUserNotification(id);
    await this.fetchNotifications(true, false); 
  }

  async deleteAllNotifications(ids = []) {
    if (_.isEmpty(ids)) {
      ids = this.activeNotifications.map(n => n._id);
    }
    await Promise.all(ids.map(id => Api.deleteUserNotification(id)))
    await this.fetchNotifications(true, true); // can be removed
  }

  async restoreNotification(id) {
    await this.markAsRead(id);
    await this.fetchNotifications(true, false); // can be removed
  }

  async restoreAllNotifications() {
    const ids = (this.deletedNotifications || []).map(n => n._id);
    if (_.isEmpty(ids)) return;
    await Api.markAllNotificationsAsRead(ids);
    await this.fetchNotifications(true, true); // can be removed
  }

  // delete individual notification
  async deleteOne(id) {
    return Api.deleteOne(id);
  }

  // all notifications
  async deleteAll(ids) {
    if (_.isEmpty(ids)) return;
    const toBeDeleted = ids.map(id =>  this.deleteOne(id));
    await Promise.all(toBeDeleted);
  }

  get deletedNotifications() {
    return (this.notifications || []).filter(n => n.status === 'deleted')
  }

  get activeNotifications() {
    return (this.notifications || []).filter(n => n.status !== 'deleted');
  }
}


decorate(UserNotificationStore, {
  notifications: observable,
  fetchNotifications: action,
  markAsRead: action,
  markAllRead: action,
  deleteNotification: action,
  deleteOne: action,
  deleteAll: action,
  loadingNotifications: observable,
  deleteAllNotifications: observable,
  restoreNotification: observable,
  restoreAllNotifications: observable,
  selectedNotification: observable,
  previousSelectedNotification: observable,
  deletedNotifications: computed,
  activeNotifications: computed
});

export default new UserNotificationStore;
