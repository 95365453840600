import { decorate, observable, action, computed } from 'mobx';
import _ from 'lodash';
import Api from '../api/CMDBApi';

const fakeData = [{
  id: 1,
  name: 'first image',
  // url: 'https://www.marriott-ld.com/cmdb/api/cdn/1643626169179-iStock-1182542143-Copy.jpg'
  url: 'https://d1ibh6b9498ljl.cloudfront.net/email-updates/iStock-1182542143.jpg'
}, {
  id: 2,
  name: 'second',
  // url: 'https://www.marriott-ld.com/cmdb/api/cdn/1643626208944-iStock-1314631685.jpg'
  url: 'https://d1ibh6b9498ljl.cloudfront.net/email-updates/iStock-1314631685.jpg'

},
{
  id: 2,
  name: 'third',
  url: 'https://d1ibh6b9498ljl.cloudfront.net/email-updates/iStockWomanPhone.jpg'
  // url: 'https://www.marriott-ld.com/cmdb/api/cdn/1643626230979-iStockWomanPhone-Copy.jpg'
}]

class ExploreStore {
  constructor() {
    this.isLoading = false;
    this.pinnedCategories = [];
    this.headings = [];
    this.learnings = [];
    this.currentShapenYourSkillCategory = null;
    this.init.bind(this)();
  }

  async init() {
  //   try {
  //     this.isLoading = true;
  //     const { data } = await CMDBApi.getProjectContent('dip');
  //     this.exploreLearningData = data;
  //     this.isLoading = false;
  //   } catch (e) {
  //     this.isLoading = false;
  //   }
    this.headings = fakeData;
  }

  get getImages() {
    return this.headings;
  }

  togglePinningCategory(item) {
    return Api.togglePinningCategory(item);
  }

  async getExploreLearningCategories() {
    try {
      this.isLoading = true;
      const response = await Api.getExploreLearnings();
      this.learnings =  response.data;
      this.isLoading = false;
    } catch (e) {
      console.log(e);
      this.isLoading = false;
    }
  }
}


decorate(ExploreStore,  {
  init: action,
  getImages: computed,
  isLoading: observable,
  learnings: observable,
  currentShapenYourSkillCategory: observable,
  pinnedCategories: observable,
  togglePinningCategory: action,
  getExploreLearningCategories: action
});

export default new ExploreStore();