// const logo = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/logo.png';
// const heroImageLarge = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/heroLarge.png';
// const heroImageSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/heroSmall.png';
// const heroImageXSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/heroXSmall.png';
// const exploreImageLarge = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/exploreLarge.png';
// const exploreImageSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/exploreSmall.png';
// const exploreImageXSmall = new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/exploreXSmall.png';
import { themePrimaryColorsBrand } from '../style-constants';

export default {
  extendedTheme: () => ({
    logo: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/logo.png',
    heroImageLarge: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/heroLarge.png',
    exploreImageLarge: new Image().src = 'https://s3.amazonaws.com/isistudio-public/dlz2/images/themes/w-hotels/exploreLarge.png',
    palette: {
      primary: {
        main: themePrimaryColorsBrand.WH
      },
      secondary: {
        main: '#1c1c1c'
      },
      primaryBtnHover: {
        main: '#B10076',
      },
      buttonText: {
        main: '#ffffff'
      },
      interaction: {
        main: '#d4008d'
      },
      text: {
        primary: '#1c1c1c'
      },
      link: {
        primary: '#1c1c1c'
      }
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        '"WSupreme-Regular"',
        '"ProximaNova-Regular"',
        '"Roboto"',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
    },
  }),
  typography: (theme, remCalc) => {
    return {
      h1: {
        fontFamily: 'WSupreme-SemiBold',
        color: '#1c1c1c',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(54),
          lineHeight: 1.11
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(34),
          lineHeight: 1.17
        }
      },
      h2: {
        fontFamily: 'WSupreme-SemiBold',
        color: '#1c1c1c',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(40),
          lineHeight: 1.15
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(30),
          lineHeight: 1.23
        }
      },
      
    }
  },
  extraTypography: (theme, remCalc) => {
    return {
      hero: {
        fontFamily: 'WSupreme-SemiBold',
        color: '#1c1c1c',
        textTransform: 'uppercase',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(44),
          lineHeight: 1.13
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(44),
          lineHeight: 1.13
        }
      },
      card: {
        fontFamily: 'WSupreme-SemiBold',
        color: '#1c1c1c',
        [theme.breakpoints.up('sm')]: {
          fontSize: remCalc(38),
          lineHeight: 1.16
        },
        [theme.breakpoints.down('xs')]: {
          fontSize: remCalc(28),
          lineHeight: 1.29
        }
      }
    }
  }
  
};
