import { decorate, observable, action, computed } from 'mobx';
import _ from 'lodash';
import lookupStore from './LookupStore';
import languageStore from './LanguageStore';
import CMDBApi from '../api/CMDBApi';
import moment from 'moment';

class ReportingV2Store {
  reportingData = {}
  isLoading = false
  loadingSummaryData = false
  reportingCriteria = {}
  currentReportFilterState = {}

  programSearchResults = []
  locationServerData = []
  associateServerData = []
  selectedLocationDetails = null
  summaryTableData = null
  reportingTableConfig = {
    limit: 25,
    offset: 0,
    filter: {

    },
    sorting: {}
  }
  reportingTableServerOffsets = [0]
  currentServerOffsetIndex = 0
  useServerOffsets = false
  getAssociateDataCount = 0
  getSummaryDataCount = 0;


  get filters() {
    return [
      {
        groupKey: 'isManaged',
        uiKey: 'managedFranchised',
        items: [
          {
            id: 1,
            uiValue: 'managed',
            name: 'Managed'
          }
        ]
      },
      {
        groupKey: 'isFranchised',
        uiKey: 'managedFranchised',
        items: [
          {
            id: 1,
            uiValue: 'franchised',
            name: 'Franchised'
          }
        ]
      },
      {
        groupKey: 'hireDate',
        uiKey: 'hireDate',
        items: [
          {
            id: 1,
            uiValue: 'hiredLast3Months',
            name: languageStore.lang.t('filters.hiredLast3Months')
          },
          {
            id: 2,
            uiValue: 'hiredLast6Months',
            name: languageStore.lang.t('filters.hiredLast6Months')
          },
          {
            id: 3,
            uiValue: 'hiredThisYear',
            name: languageStore.lang.t('filters.hiredThisYear')
          },
          {
            id: 4,
            uiValue: 'hiredLastYear',
            name: languageStore.lang.t('filters.hiredLastYear')
          },
          {
            id: 5,
            uiValue: 'hiredBeforeThisYear',
            name: languageStore.lang.t('filters.hiredBeforeThisYear')
          }
        ]
      },
      {
        groupName: languageStore.lang.t('filters.metric'),
        groupKey: 'metric',
        uiKey: 'reportType',
        tooltip: languageStore.lang.t('filters.metricTooltip'),
        items: [
          {
            id: 1,
            uiValue: 'learningProgress',
            name: languageStore.lang.t('filters.learningProgress'),
            tooltip: languageStore.lang.t('reporting.helpProgress')
          },
          {
            id: 2,
            uiValue: 'learningCompletion',
            name: languageStore.lang.t('filters.learningCompletion'),
            tooltip: languageStore.lang.t('reporting.helpCompletion')
          },
          {
            id: 3,
            uiValue: 'learningCompliance',
            name: languageStore.lang.t('filters.learningCompliance'),
            tooltip: languageStore.lang.t('reporting.helpCompletion')
          },
          {
            id: 4,
            uiValue: 'jobFunctionSelected',
            name: languageStore.lang.t('filters.jobFunctionSelected'),
            tooltip: languageStore.lang.t('reporting.helpJF')
          }
        ]
      },
      {
        groupName: languageStore.lang.t('filters.courseType'),
        groupKey: 'assignmentType',
        uiKey: 'assignmentTypes',
        items: [
          {
            id: lookupStore.assignmentTypes.find(at => at.name === 'Required').id,
            name: languageStore.lang.t('filters.requiredCourses'),
            uiValue: 'requiredCourses',
          },
          {
            id: lookupStore.assignmentTypes.find(at => at.name === 'Recommended').id,
            name: languageStore.lang.t('filters.recommendedCourses'),
            uiValue: 'recommendedCourses',
          },
          {
            id: lookupStore.assignmentTypes.find(at => at.name === 'Gateway Resources').id,
            name: languageStore.lang.t('filters.gatewayResources'),
            uiValue: 'gatewayResources'
          },
        ]
      },
      {
        groupName: languageStore.lang.t('filters.program'),
        tooltip: languageStore.lang.t('reporting.helpProgram'),
        groupKey: 'program',
        uiKey: 'programs',
        items: lookupStore.programs,
        drilldownItems: lookupStore.coursePlansWithCategories,
        isCategoryBased: true
      },
      {
        groupName: languageStore.lang.t('filters.function'),
        tooltip: languageStore.lang.t('filters.functionTooltip'),
        groupKey: 'jobFunction',
        uiKey: 'jobFunctions',
        items: lookupStore.jobFunctions,
        drilldownItems: lookupStore.jobFunctionsWithCategories,
        isCategoryBased: true
      },
      {
        groupName: languageStore.lang.t('filters.awr'),
        tooltip: languageStore.lang.t('filters.awrTooltip'),
        groupKey: 'awr',
        uiKey: 'awrs',
        items: lookupStore.additionalJobFunctions.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupName: languageStore.lang.t('filters.region'),
        tooltip: languageStore.lang.t('filters.regionTooltip'),
        groupKey: 'region',
        uiKey: 'regions',
        items: lookupStore.regions.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupName: languageStore.lang.t('filters.continent'),
        tooltip: languageStore.lang.t('filters.continentTooltip'),
        groupKey: 'continent',
        uiKey: 'continents',
        items: lookupStore.continents.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupName: languageStore.lang.t('filters.brands'),
        tooltip: languageStore.lang.t('filters.brandsTooltip'),
        groupKey: 'brand',
        uiKey: 'brands',
        items: lookupStore.brands.map(a => ({
          id: a.id,
          name: a.label,
        }))
      },
      {
        groupName: languageStore.lang.t('filters.brandSegments'),
        tooltip: languageStore.lang.t('filters.brandSegmentsTooltip'),
        groupKey: 'brandSegment',
        uiKey: 'brandSegments',
        items: lookupStore.brandSegments.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupKey: 'group',
        uiKey: 'groups',
        items: lookupStore.groups.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupKey: 'subGroup',
        uiKey: 'subgroups',
        items: lookupStore.subgroups.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupKey: 'specialty',
        uiKey: 'specialties',
        items: lookupStore.specialties.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupKey: 'subSpecialty',
        uiKey: 'subspecialties',
        items: lookupStore.subspecialties.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupKey: 'franchiseCompany',
        uiKey: 'franchiseCompanies',
        items: lookupStore.franchiseCompanies.map(a => ({
          id: a.id,
          name: a.name,
        }))
      },
      {
        groupKey: 'owner',
        uiKey: 'owners',
        items: lookupStore.owners.map(a => ({
          id: a.id,
          name: a.name,
        }))
      }
    ]
  }

  async wait(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  }

  addGroupedSummaryDataPoints(assignmentIdGroups, data) {
    let mergedDataPoints = {};

    assignmentIdGroups.forEach(idGroup => {
      const groupLabel = idGroup.join('-');

      mergedDataPoints[`assigned-assignmentGroup-${groupLabel}`] = idGroup.reduce((acc, id) => acc + data[`assigned-assignments-${id}`], 0);
      mergedDataPoints[`completed-assignmentGroup-${groupLabel}`] = idGroup.reduce((acc, id) => acc + data[`completed-assignments-${id}`], 0);
    });

    return mergedDataPoints;
  }

  stopSummaryLoad() {
    this.getSummaryDataCount++;
  }

  async getSummaryTable(filters = [], levelOneSummary, levelTwoSummary, selectedPropertyId) {
    const { reportingCriteria } = this;
    const assignmentIdGroups = reportingCriteria.assignments ?
      _.compact(reportingCriteria.assignments.map(a => a.multipleIds ? a.ids : null)) : [];

    this.getSummaryDataCount++;
    const queuePosition = this.getSummaryDataCount;
    this.loadingSummaryData = true;


    let response, poll;
    response = await CMDBApi.getSummaryTable(filters, levelOneSummary, levelTwoSummary, selectedPropertyId);
    poll = response.data.poll;
    while (poll) {
      await this.wait(1000);
      if (queuePosition !== this.getSummaryDataCount) {
        //back link has been clicked, abort
        this.stopSummaryLoadTriggered = false;
        return;
      }

      response = await CMDBApi.getSummaryTable(filters, levelOneSummary, levelTwoSummary, selectedPropertyId, poll);
      poll = response.data.poll;
    }

    let summaryTableData = response.data;

    if (summaryTableData.levelOne) {
      summaryTableData.levelOne = summaryTableData.levelOne.map(l1Data => {
        const additionalDataPoints = this.addGroupedSummaryDataPoints(assignmentIdGroups, l1Data);
        return { ...l1Data, ...additionalDataPoints };
      });
    }

    if (summaryTableData.levelTwo) {
      summaryTableData.levelTwo = summaryTableData.levelTwo.map(l2Data => {
        const additionalDataPoints = this.addGroupedSummaryDataPoints(assignmentIdGroups, l2Data);
        return { ...l2Data, ...additionalDataPoints };
      });
    }

    this.summaryTableData = summaryTableData;
    this.loadingSummaryData = false;
  }

  setReportTableConfig({ sorting = null, offset = null, limit = null, filter = null }) {
    if (offset !== null) {
      this.reportingTableConfig.offset = offset;
    }

    if (limit !== null) {
      this.reportingTableConfig.limit = limit;
    }

    if (filter !== null) {
      this.reportingTableConfig.filter = filter;
    }

    if (sorting) {
      this.reportingTableConfig.sorting = sorting;
    }
  }

  resetReportingTableConfig() {
    this.reportingTableConfig = {
      limit: 25,
      offset: 0,
      sorting: {

      },
      filter: {

      }
    };

    this.useServerOffsets = false;
    this.reportingTableServerOffsets = [0]
    this.currentServerOffsetIndex = 0

  }

  async searchPrograms(term) {
    const { data } = await CMDBApi.search(term, 'reporting-assignment');
    if (data.results) {
      this.programSearchResults = data.results;
    } else {
      this.programSearchResults = data;
    }
  }

  setReportTableUseServerOffsets({ isIncrement }) {
    if (isIncrement) {
      this.currentServerOffsetIndex++;
    } else {
      this.currentServerOffsetIndex--;
    }
    this.reportingTableConfig.offset = this.reportingTableServerOffsets[this.currentServerOffsetIndex];
  }

  addGroupedDataPoints(additionalDataPoints, assignmentIdGroups, data) {
    let mergedDataPoints = { ...additionalDataPoints };

    assignmentIdGroups.forEach(idGroup => {
      const assigned = idGroup.reduce((acc, id) => acc + data[`assigned-assignments-${id}`], 0);
      const completed = idGroup.reduce((acc, id) => acc + data[`completed-assignments-${id}`], 0);
      const percentage = assigned > 0 ? Math.round((completed / assigned) * 100) : 'N/A';
      const groupLabel = idGroup.join('-');

      mergedDataPoints[`assignmentGroup-${groupLabel}`] = `${assigned > 0 ? percentage + '%' : 'N/A'} (${completed}/${assigned})`;
    });

    return mergedDataPoints;
  }

  async getAssociateData(filters = [], selectedPropertyId) {
    this.loadingReportingData = true;
    this.associateServerData = [];
    this.selectedLocationDetails = null;
    const { reportingCriteria } = this;
    const assignmentIdGroups = reportingCriteria.assignments ?
      _.compact(reportingCriteria.assignments.map(a => a.multipleIds ? a.ids : null)) : [];


    this.getAssociateDataCount++;
    const queuePosition = this.getAssociateDataCount;

    let response, cachedReportId;

    const { limit, offset, filter, sorting } = this.reportingTableConfig;

    response = await CMDBApi.getAssociateDataV2(filter, limit, offset, filters, sorting, selectedPropertyId);
    cachedReportId = response.data.cachedReportId;
    while (cachedReportId) {
      await this.wait(1000);
      response = await CMDBApi.getAssociateDataV2(filter, limit, offset, filters, sorting, selectedPropertyId, cachedReportId);
      cachedReportId = response.data.cachedReportId;

      if (queuePosition !== this.getAssociateDataCount) {
        //another request has started, abort this one
        return;
      }
    }

    const { associateEids, totalAssociates, locationDetails, nextOffset } = response.data;

    if (nextOffset) {
      this.useServerOffsets = true;
      this.reportingTableServerOffsets.push(nextOffset);
    }

    this.totalAssociates = totalAssociates;
    this.selectedLocationDetails = locationDetails;

    const progressResponse = await CMDBApi.getAssociateProgressV2(associateEids, filters);

    progressResponse.data.forEach((associate) => {
      let additionalDataPoints = {};

      filters.forEach(filter => {
        if (
          filter.groupKey === 'program' ||
          filter.groupKey === 'coursePlan' ||
          filter.groupKey === 'sourceIdAssignment' ||
          (filter.groupKey === 'assignment' && !assignmentIdGroups.find(aig => aig.includes(filter.itemId)))
        ) {
          const assigned = associate[`assigned-${filter.groupKey}s-${filter.itemId}`];
          const completed = associate[`completed-${filter.groupKey}s-${filter.itemId}`];
          const percentage = assigned > 0 ? Math.round((completed / assigned) * 100) : 'N/A';

          additionalDataPoints[`${filter.groupKey}-${filter.itemId}`] = `${assigned > 0 ? percentage + '%' : 'N/A'} (${completed}/${assigned})`;
        }
      });

      additionalDataPoints = this.addGroupedDataPoints(additionalDataPoints, assignmentIdGroups, associate);

      this.associateServerData.push({
        id: associate.eid,
        eid: associate.eid,
        name: associate.name,
        functionTypes: lookupStore.getJobFunctionLabels(associate.jobFunctions, true).join(', '),
        additionalJobFunctions: lookupStore.getAdditionalJobFunctionLabels(associate.additionalJobFunctions).map(l=>l.name).join(', '),
        hireDate: moment(associate.hireDate).isValid() ? moment(associate.hireDate).format('MM/DD/YYYY') : '',
        locationCode: associate.locationCode,
        managementLevel: associate.managementLevel,
        marshaCode: associate.marshaCode,
        managerName: associate.managerName,
        jobTitle: associate.jobTitle,
        numCoursesComplete: associate.completeCount,
        totalRequiredCourses: associate.assignedCourseCount,
        progress: associate.assignedCourseCount > 0 ? Math.round((associate.completeCount / associate.assignedCourseCount) * 100) : 100,
        ...additionalDataPoints
      });
    });

    if (queuePosition !== this.getAssociateDataCount) {
      //another request has started, abort this one
      return;
    }

    this.getAssociateDataCount = 0;
    this.loadingReportingData = false;
  }

  async getLocationData(filters = []) {
    this.loadingReportingData = true;
    this.locationServerData = [];
    const { reportingCriteria } = this;
    const assignmentIdGroups = reportingCriteria.assignments ?
      _.compact(reportingCriteria.assignments.map(a => a.multipleIds ? a.ids : null)) : [];


    const { limit, offset, filter, sorting } = this.reportingTableConfig;
    const { data } = await CMDBApi.getLocationDataV2(filter, limit, offset, filters, sorting);
    const { brands } = lookupStore;
    const { locations, totalLocations } = data;

    const progressResponse = await CMDBApi.getLocationProgressV2(locations.map(l => l.locationCode), filters);

    progressResponse.data.forEach((location) => {
      const locationBrandId = (locations.find(l => l.locationCode === location.locationDetails.locationCode) || {}).brandId;
      let additionalDataPoints = {};

      filters.forEach(filter => {
        if (
          filter.groupKey === 'program' ||
          filter.groupKey === 'coursePlan' ||
          filter.groupKey === 'sourceIdAssignment' ||
          (filter.groupKey === 'assignment' && !assignmentIdGroups.find(aig => aig.includes(filter.itemId)))
        ) {
          const assigned = location[`assigned-${filter.groupKey}s-${filter.itemId}`];
          const completed = location[`completed-${filter.groupKey}s-${filter.itemId}`];
          const percentage = assigned > 0 ? Math.round((completed / assigned) * 100) : 'N/A';

          additionalDataPoints[`${filter.groupKey}-${filter.itemId}`] = `${assigned > 0 ? percentage + '%' : 'N/A'} (${completed}/${assigned})`;
        }
      });

      additionalDataPoints = this.addGroupedDataPoints(additionalDataPoints, assignmentIdGroups, location);

      this.locationServerData.push({
        id: location.locationDetails.locationCode,
        marshaCode: location.locationDetails.marshaCode,
        locationCode: location.locationDetails.locationCode,
        managedFranchised: location.locationDetails.managedFranchised === 'F' ? 'Franchised' : 'Managed',
        locationDescription: location.locationDetails.propertyName,
        avpName: location.locationDetails.avp,
        cooName: location.locationDetails.coo,
        region: location.locationDetails.region,
        brand: (brands.find(b => locationBrandId === b.id) || {}).label,
        progress: location.assignedCourseCount > 0 ? Math.round((location.completeCount / location.assignedCourseCount) * 100) : 100,
        ...additionalDataPoints
      });
    });

    if ((totalLocations < this.reportingTableConfig.limit) &&
      (this.locationServerData.length < totalLocations)
    ) {
      this.totalLocations = this.locationServerData.length;
    } else {
      this.totalLocations = totalLocations;
    }

    this.loadingReportingData = false;
  }

  setReportingCriteria(criteria) {
    this.currentReportFilterState = criteria;
    console.log('criteria', criteria);
    const transformCriteria = criteria => {
      const filters = this.filters;

      const { coursePlans, jobFunctions } = criteria;

      const coursePlanIds = [];
      Object.keys(coursePlans).forEach(groupKey => {
        coursePlans[groupKey].forEach(program => {
          const found = filters.find(f => f.groupKey === 'program').drilldownItems.find(i => i.name === groupKey).subItems.find(si => si.name === program);
          if (found) {
            coursePlanIds.push(found.id);
          }
        })
      });

      const jobFunctionIds = [];
      Object.keys(jobFunctions).forEach(groupKey => {
        jobFunctions[groupKey].forEach(program => {
          const found = filters.find(f => f.groupKey === 'jobFunction').drilldownItems.find(i => i.name === groupKey).subItems.find(si => si.name === program);
          if (found) {
            jobFunctionIds.push(found.id);
          }
        })
      });

      const awrIds = criteria.awrs.map(a => filters.find(a => a.groupKey === 'awr').items.find(awr => awr.name === a).id);
      const regionIds = criteria.regions.map(a => filters.find(a => a.groupKey === 'region').items.find(awr => awr.name === a).id);
      const continentIds = criteria.continents.map(a => filters.find(a => a.groupKey === 'continent').items.find(awr => awr.name === a).id);
      const brandIds = criteria.brands.map(a => filters.find(a => a.groupKey === 'brand').items.find(awr => awr.name === a).id);
      const brandSegmentIds = criteria.brandSegments.map(a => filters.find(a => a.groupKey === 'brandSegment').items.find(awr => awr.name === a).id);
      const groupIds = criteria.groups.map(a => lookupStore.groups.find(awr => awr.name === a).id);
      const subgroupIds = criteria.subgroups.map(a => lookupStore.subgroups.find(awr => awr.name === a).id);;
      const specialtyIds = criteria.specialties.map(a => lookupStore.specialties.find(awr => awr.name === a).id);;
      const subspecialtyIds = criteria.subspecialties.map(a => lookupStore.subspecialties.find(awr => awr.name === a).id);
      const programIds = criteria.programs.map(a => filters.find(a => a.groupKey === 'program').items.find(awr => awr.name === a).id);
      const franchiseCompanyIds = criteria.franchiseCompanies.map(a => filters.find(a => a.groupKey === 'franchiseCompany').items.find(awr => awr.name === a).id);
      const managers = criteria.selectedAssociateEids.map(sae => sae.eid);
      const ownerIds = criteria.owners.map(a => filters.find(a => a.groupKey === 'owner').items.find(awr => awr.name === a).id);

      const transformed = {
        ...criteria,
        programs: programIds,
        coursePlans: coursePlanIds,
        jobFunctions: jobFunctionIds,
        awrs: awrIds,
        regions: regionIds,
        continents: continentIds,
        brands: brandIds,
        brandSegments: brandSegmentIds,
        groups: groupIds,
        subgroups: subgroupIds,
        specialties: specialtyIds,
        subspecialties: subspecialtyIds,
        franchiseCompanies: franchiseCompanyIds,
        managers,
        owners: ownerIds
      };

      console.log(transformed);
      return transformed;
    };

    this.reportingCriteria = transformCriteria(criteria);
  }


  addOtherFiltersToPayload(payload, reportingCriteria) {
    reportingCriteria.managers.forEach(eid => {
      payload.filters.push({
        groupKey: 'manager',
        itemId: eid,
        itemName: eid
      });
    });

    reportingCriteria.coursePlans.forEach(coursePlanId => {
      const coursePlan = lookupStore.coursePlans.find(cp => cp.id === coursePlanId);
      payload.filters.push({
        groupKey: 'coursePlan',
        itemId: coursePlanId,
        itemName: coursePlan && coursePlan.name
      })
    });

    reportingCriteria.assignments.forEach(assignment => {

      if (assignment.multipleIds) {
        assignment.ids.forEach(id => {
          payload.filters.push({
            groupKey: assignment.idType === 'sourceId' ? 'sourceIdAssignment' : 'assignment',
            itemId: id,
            itemName: assignment.name
          });
        });
      } else {
        payload.filters.push({
          groupKey: assignment.idType === 'sourceId' ? 'sourceIdAssignment' : 'assignment',
          itemId: assignment.id,
          itemName: assignment.name
        });
      }
    });

    reportingCriteria.managedFranchised.forEach(mOrF => {
      payload.filters.push({
        groupKey: mOrF === 'managed' ? 'isManaged' : 'isFranchised',
        itemId: 1,
        itemName: mOrF === 'managed' ? 'Managed' : 'Franchised',
      });
    });

    //If no assignmentTypes selected, select them all implicitly
    if (reportingCriteria.assignmentTypes.length === 0) {
      const assignmentTypes = this.filters.find(f => f.groupKey === 'assignmentType');
      assignmentTypes.items.forEach(at => {
        payload.filters.push({
          groupKey: 'assignmentType',
          itemId: at.id,
          itemName: at.name
        });
      });
    }

    //If Job Function Metric selected, do not filter by assignment type
    if(reportingCriteria.reportType === 'jobFunctionSelected') {
      payload.filters = payload.filters.filter(f => f.groupKey !== 'assignmentType');
    }

    return payload;
  }

  addNonFiltersToPayload(payload, reportingCriteria) {
    payload.tableType = reportingCriteria.locationAssociateResults === 'locationLevel' ? 'location' : 'associate';
    payload.levelOneSummary = reportingCriteria.levelOneSummary;
    payload.levelTwoSummary = reportingCriteria.levelTwoSummary;

    return payload;
  }

  get reportingPayload() {
    const { reportingCriteria } = this;
    let payload = { filters: [] };
    for (const groupKey in reportingCriteria) {
      if (reportingCriteria[groupKey].slice && Array.isArray(reportingCriteria[groupKey].slice())) { //you must slice in mobx to test for array
        reportingCriteria[groupKey].forEach(selectedFilter => {
          const filterGroup = this.filters.find(group => group.uiKey === groupKey);
          if (filterGroup) {
            const hasUiValues = !!filterGroup.items[0].uiValue;
            const item = hasUiValues ? filterGroup.items.find(i => i.uiValue === selectedFilter)
              : filterGroup.items.find(i => i.id === selectedFilter);
            if (!_.isEmpty(item)) {
              payload.filters.push({
                groupKey: filterGroup.groupKey,
                itemId: item.id,
                itemName: item.name
              });
            }
          }
        });
      } else if (reportingCriteria[groupKey]) {
        const filterGroup = this.filters.find(group => group.uiKey === groupKey);
        if (filterGroup) {
          const selectedFilter = reportingCriteria[groupKey];
          const hasUiValues = !!filterGroup.items[0].uiValue;
          const item = hasUiValues ? filterGroup.items.find(i => i.uiValue === selectedFilter)
            : filterGroup.items.find(i => i.id === selectedFilter);
          if (!_.isEmpty(item)) {
            payload.filters.push({
              groupKey: filterGroup.groupKey,
              itemId: item.id,
              itemName: item.name
            });
          }
        }
      }
    }

    this.addNonFiltersToPayload(payload, reportingCriteria);
    this.addOtherFiltersToPayload(payload, reportingCriteria);

    return payload;
  }

}

decorate(ReportingV2Store, {
  reportingData: observable,
  getData: action,
  isLoading: observable,
  loadingSummaryData: observable,
  locationFilter: observable,
  reportingTableConfig: observable,
  loadingReportingData: observable,
  locationServerData: observable,
  associateServerData: observable,
  totalLocations: observable,
  totalAssociates: observable,
  selectedLocationDetails: observable,
  getLocationData: action,
  getAssociateData: action,
  setReportTableConfig: action,
  resetReportingTableConfig: action,
  getSummaryTable: action,
  summaryTableData: observable,
  reportingCriteria: observable,
  reportingPayload: computed,
  setReportingCriteria: action,
  filters: computed,
  programSearchResults: observable,
  searchPrograms: action,
  currentReportFilterState: observable,
  useServerOffsets: observable
});

export default new ReportingV2Store();