export const application = 'dlz';

export const apiURL = 'https://www.marriott-ld.com/api/dlz2';

export const cmdbURL = 'https://www.marriott-ld.com/cmdb/api'

export const logoutEnabled = false;

export const gaId = 'G-VV3Y4W4LZK';

export const idleTimeWarnLogout = 1000 * 60 * 14;

export const idleTimeLogout = idleTimeWarnLogout + (1000 * 60 * 1);
