export default {
  "banner": {
    "viewDetails": "Detayları Göster",
    "requiredCourses": "Almanız gereken kurslar için kalan süre:",
    "days": "gün"
  },
  "header": {
    "plan": "Planım",
    "manager": "YÖNETİCİ",
    "explore": "ÖĞRENİMİ KEŞFET",
    "reporting": "RAPORLAMA",
    "myTeam": "EKİBİM",
    "title": "Digital Learning Zone"
  },
  "footer": {
    "plan": "PLANIM",
    "manager": "YÖNETİCİ",
    "explore": "ÖĞRENİMİ KEŞFET",
    "tagline": "Öğrenmenin kolay yolu. Sizinle başlar."
  },
  "welcome": {
    "hi": "Merhaba",
    "intro1": "Digital Learning Zone’a Hoş Geldiniz! DLZ, Marriott eğitimlerine ana erişim noktası olan, kişiselleştirilmiş bir eğitim platformudur. Pozisyonunuzla ilgili gerekli eğitimleri bulun ve kişisel ve profesyonel gelişiminiz için eğitim fırsatlarını keşfedin. Eğitim yolculuğunuzu sahiplenin ve becerilerinizi geliştirmeye başlayın.",
    "intro2": "Başlamadan önce, sizinle ilgili birkaç şeyi bilmemiz gerekiyor.",
    "letsGo": "HADİ BAŞLAYALIM",
    "preferredLanguage": "Hangi dili tercih ediyorsunuz?",
    "step": "ADIM",
    "of": "/",
    "previous": "GERİ",
    "next": "İLERİ",
    "startDate": "Marriott International’da veya Starwood’da ne zaman çalışmaya başladınız?",
    "selectYear": "YILI SEÇ",
    "selectMonth": "AYI SEÇ",
    "selectDay": "TARİHİ SEÇ",
    "selectFunction": "FONKSİYON SEÇ",
    "selectInterests": "İLGİ ALANLARINI SEÇ",
    "chooseYear": "BİR YIL SEÇ",
    "chooseMonth": "BİR AY SEÇ",
    "chooseDay": "BİR GÜN SEÇ",
    "whatDo": "İş Fonksiyonunuz nedir",
    "selected": "SEÇİLDİ",
    "searchFunctions": "Tüm fonksiyonları ara",
    "searchInterests": "Tüm İlgi Alanlarını Ara",
    "additionalJobFunctionsLabel": "Ne iş yaptığınızla ilgili bize daha fazla bilgi verin",
    "managementLevelLabel": "Çalışan seviyeniz nedir?",
    "preferredBrandLabel": "Eğer birden fazla marka için çalışıyorsanız, lütfen sizin için geçerli olanların tümünü seçin.",
    "additionalJobFunctionsLabelExtra": "Lütfen aşağıdakileri inceleyin ve EN AZ BİR ek iş sorumluluğu SEÇİN.",
    "searchAdditionalFunctions": "Tüm ek iş sorumluluklarını ara",
    "highGuestContactLabel": "Konuklarla yoğun temas içerisinde misiniz?",
    "yes": "Evet",
    "no": "Hayır "
  },
  "landing": {
    "started": "Hadi başlayalım",
    "welcomeBack": "Tekrar hoş geldiniz,",
    "greatWork": "Harika iş",
    "requiredCourses": "Almanız Gereken Kurslar",
    "requiredCoursesDesc": "Lütfen kursları bitiş tarihinden önce tamamlayın.",
    "myPlan": "Planım",
    "courses": "Kurslar",
    "courseComplete": "KURS TAMAMLAMA",
    "viewMore": "DAHA FAZLA GEREKLİ KURS GÖRÜNTÜLE",
    "viewMoreBtn": "DAHA FAZLA KURS GÖRÜNTÜLE",
    "showing": "GÖSTERİLİYOR",
    "of": "/",
    "heroTitle": "Yeni bir şey öğrenin. Kendinizi güçlendirin. Dünyanıza uygun beceriler kazanın.",
    "heroParagraph1": "Almanız gereken tüm kursları kolayca takip edin ve bu kursların tamamlanması için gerçek zamanlı bildirimler alın.",
    "heroParagraph2": "İşinize ve ilgi alanlarınıza göre uyarlanmış bir öğrenim deneyimi yaşayın.",
    "recommendedCoursesTitle": "Önerilen Kurslar",
    "recommendedCoursesDescNoContent": "Şu anda sizin için önerilen kurs bulunmamaktadır. Diğer öğrenme fırsatlarını keşfetmek için lütfen aşağıya bakın.",
    "recommendedCoursesDesc": "Yöneticinizin veya İK uzmanının sizin için seçtiklerini görün.",
    "recommendedCoursesBtn": "ÖNERİLEN KURSLARI GÖRÜNTÜLE",
    "completedCoursesBtn": "TAMAMLANAN KURSLARI GÖRÜNTÜLE",
    "exploreTitle": "Meraklı kalın.",
    "exploreDesc": "İlgilendiğiniz konuları seçin.",
    "jobFunctions": "Disiplin",
    "growthSkills": "Gelişim Becerileri",
    "leadershipSkills": "Liderlik Becerileri",
    "brands": "Markalar",
    "languageLearning": "Dil Öğrenimi",
    "exploreBtn": "KURSLARI GÖRÜNTÜLE",
    "managerRecommendation": "YÖNETİCİ ÖNERİSİ",
    "learningRecommendation": "ÖĞRENME ÖNERİSİ",
    "marriottRecommendation": "MARRIOTT ÖNERİSİ",
    "completedCourses": "Almanız gereken tüm kursları tamamladınız!",
    "investTitle": "Eğitim Yolculuğunuzu Sahiplenin",
    "investParagraph1": "Mevcut koşullar birçok yönden zorlu bunun farkındayız - DLZ sizin için bir kaynak olarak burada. Yeteneklerinizi güncel tutmak, yeni bir şey öğrenmek, stresinizi yönetmek veya sanal bir ekipte çalışmayı öğrenmek isteyebilirsiniz. Size destek olmak için gerekli tüm kaynakları topladık.",
    "updatedTopics": "Güncelleştirilmiş konular",
    "workingRemotely": "Sanal Ekip Kaynakları ",
    "relatedCoursesNew": "Sizler için yeni konular ve kurslar ekledik, ilgi alanlarınızı yeniden güncellemek için aşağıya tıklayınız.",
    "relatedCourses": "İlgi alanlarınızı seçmek için lütfen aşağıya tıklayınız.",
    "notificationText": "Yeni mHUB eğitimi sizinle. Explore Learning platformunda mHUB öğesini seçin.",
    "mHubHeroTitle1": "Basit. Akıllı. Güvenli. Karşınızda mHUB.",
    "mHubHeroManagedText1": "mHUB, eğitim araçlarını, kariyer gelişim araçlarını, Explore indirimlerini ve çok daha fazlasını kullanımı kolay tek bir platformda birleştiriyor.",
    "mHubHeroFranchisedText1": "mHUB, eğitim araçlarını, Explore indirimlerini ve çok daha fazlasını kullanımı kolay tek bir platformda birleştiriyor.",
    "mHubHeroVisitLink": "Diğer teklif detaylarını ve ekleri görüntülemek için buraya tıklayın.",
    "myGateway": "My Gateway",
    "gatewayReview": "Lütfen göz atıp tamamlayın.",
    "gatewayProgress": "Gateway İlerleme",
    "complete": "TAMAMLAMA",
    "reviewContent": "Kaynakları Görüntüle",
    "gatewayCardTitle": "Gateway Kaynaklar",
    "gatewayCardDesc": "İlk yılınız ve sonrası için Temel Bilgilerinize erişin.",
    "gatewayCardDescGms": "İlk yılınız ve sonrası için Temel Bilgilerinize, Eylemlerinize, Marka Kaynaklarına ve Bölüm bazında Kaynaklara erişin.",
    "gatewayCardDescNew": "Yeni görevinizdeki ilk yılınız için Temel Bilgilerinize erişin.",
    "gatewayCardDescGmsNew": "Yeni görevinizdeki ilk yılınız ve sonrası için Temel Bilgilerinize, Eylemlerinize, Marka Kaynaklarına ve Bölüm bazında Kaynaklara erişin."
  },
  "courseBundle": {
    "completeBy": "TAMAMLANACAĞI SÜRE:",
    "remainingCourses": "Kalan Kurslar",
    "completedCourses": "Tamamlanan Kurslar",
    "remainingLabel": "Bu öğrenim yolunda {courseCount} kurs bulunmaktadır.",
    "recommendButton": "Ekibe Önerildi",
    "startCourse": "Kursa Başla",
    "durationMins": "DAKİKA",
    "completed": "Tamamlandı",
    "back": "Geri",
    "additionalLangs1": "İngilizce olarak sunulan bu kurs, artık şu dilde de sunulmaktadır:",
    "additionalLangs2": "Kursu başlattıktan sonra lütfen aşağı açılır menüden tercih ettiğiniz dili seçin.",
    "targetAudience": "Hedef kitle",
    "all": "Tümü",
    "globally": "küresel olarak",
    "source": "Kaynak",
    "courseType": "Kurs Tipi",
    "course": "Kurs"
  },
  "profile": {
    "save": "Kaydet",
    "edit": "Düzenle",
    "brand": "Marka",
    "brands": "Markalar",
    "additionalWork": "Tüm Ek İş Sorumlulukları",
    "startDateLabel": "Marriott International’da veya Starwood’da ne zaman çalışmaya başladınız?",
    "preferredLanguageLabel": "Hangi dili tercih ediyorsunuz?",
    "jobFunctionsLabel": "İş rolünüz nedir?",
    "interestsLabel": "Ne öğrenmek istiyorsunuz?",
    "brandsLabel": "Hangi marka(lar) için çalışıyorsunuz?",
    "additionalJobFunctionsLabel": "Hangi ek iş fonksiyonlarını yerine getiriyorsunuz?",
    "managementLevelLabel": "Çalışan Seviyeniz nedir",
    "highGuestContactLabel": "Konuklarla yoğun temas içerisinde misiniz?"
  },
  "courseDetails": {
    "completeBy": "Tamamlanacak",
    "recommendTitlte": "Ekibinize Önerilen Kurs",
    "courseName": "Kursun Adı",
    "sendRecommendation": "Öneri Gönder"
  },
  "explore": {
    "relatedCourses": "İlgi alanlarınıza uygun kurslar.",
    "chooseInterests": "İlgi alanlarınızı seçin",
    "title": "Öğrenimi Keşfet",
    "chooseInterestsModal": "İlgi Alanlarını Seç",
    "oppsTitle": "Öğrenme Fırsatlarını Keşfet",
    "growWithUsTitle": "Bizimle Gelişin",
    "growWithUsDesc": "Marriott sizinle birlikte gelişen bir kariyere inanır. Ayrıca, geniş deneyimlerin çoğu zaman daha zenginleştirici tecrübeler sunduğuna inanırız.",
    "growWithUsBtn": "BİZİMLE GELİŞİ GÖRÜNTÜLE",
    "mdaTitle": "Marriott Development Academy",
    "mdaDesc": "Marriott, başarı ve kararlılığı takdir eder. Liderlik ve sıra dışı hizmeti onurlandırır ve teşvik ederiz. Ayrıca, başarılı tavsiyeler için prim veririz.",
    "mdaBtn": "MARRIOTT DEVELOPMENT ACADEMY’İ GÖRÜNTÜLE",
    "selectInterests": "Hangi konulara ilgi duyduğunuzu söyleyin.",
    "otherRecommended": "Önerilen Diğer Kurslar",
    "searchByName": "İlgili alanlarınızı arayın"
  },
  "plan": {
    "completedRequired": "Almanız gereken tüm kursları tamamladınız!",
    "requiredCourses": "Gerekli Kurslar",
    "reqDesc": "Lütfen bu kursları bitiş tarihinden önce tamamlayın.",
    "complete": "TAMAMLA",
    "of": "/",
    "courses": "KURSLAR",
    "completedCourses": "Tamamlanan Kurslar",
    "completedDesc": "Tebrikler! Son olarak aşağıdaki kursları tamamladınız",
    "remaining": "kalan",
    "myLearningTitle": "Eklenen Eğitimlerim ",
    "myLearningSubTitle": "Planım’a Eklediğim Öğeler",
    "history": "Geçmiş",
    "myLearningHistoryLinkText": "Tüm Geçmişimi gör",
    "jf": "İş Fonksiyon(lar)ınız",
    "awr": "Ek İş Sorumluluklarınız (EİS)",
    "jfAwrDesc": "Önemli: iş fonksiyonu ve EİS, Planım’da size atanacak olan zorunlu eğitimleri belirler. Güncellemek için ",
    "jfAwrEdit": "profilinizi DÜZENLEYİN.",
    "jfAwrEnd": "",
    "tourPlan": "Planım bölümü, bitiş tarihine kadar tamamlanması gereken tüm zorunlu ve önerilen dersleri içerir",
    "tourBasics": "Marriott Temel Bilgiler bölümü ise, Marriott yolculuğunuza başlamanıza yardımcı olacak kaynakları içerir ve bu bölüme kariyeriniz boyunca istediğiniz zaman erişebilirsiniz. Marriott Temel Bilgiler, Planım kısmındaki eğitiminizi tamamlayıcı niteliktedir.",
    "noCourses": "Şu ana kadar bitmesi gereken tüm eğitimleri tamamlamış durumdasınız, önünüze bakıp yaklaşan zorunlu eğitimlere başlayabilirsiniz"
  },
  "team": {
    "title": "Ekibinizi Yönetin",
    "subtitle": "Becerilerini ve kariyerlerini geliştirin.",
    "functions": "Fonksiyonlar",
    "viewTeam": "Ekibi Görüntüle",
    "allCourses": "Tüm Kurslar",
    "downloadExcel": "Excel Raporunu İndir"
  },
  "feedbackButton": {
    "havingTrouble": "Tavsiyeler"
  },
  "recommendation": {
    "dismiss": "Bu öneriyi es geçmek istediğinizden emin misiniz?"
  },
  "table": {
    "filterTable": "Ihluzo lokuhlunga"
  },
  "notifications": {
    "addAssignment2": "%{assignment_name} ile ilgili %{assignment_type} ödev, %{page location} sayfanıza eklendi ve %{due} tarihinde tamamlanması gerekiyor.",
    "comingDueAssignment": "%{assignment_name} ile ilgili %{assignment_type} ödevinizin tamamlanma tarihi olan %{due} yaklaşıyor.",
    "overdueAssignment": "%{assignment_name} ile ilgili %{assignment_type} ödevinizin süresi geçti.",
    "addAssignment": "%{assignment_name} ile ilgili %{assignment_type} ödev, %{page location} sayfanıza eklendi.",
    "profile": "İş unvanınız veya çalıştığınız yer ile ilgili bir güncelleme tespit edildi. Lütfen Profilinizi gözden geçirip gerekli Marka, Fonksiyon ve Ek İş Sorumluluğu güncellemelerini yapın. DLZ profilinizde doğru bilgilerin bulunması, size doğru eğitimlerin atanmasını sağlar.",
    "helptip": "Tüm bildirimlerin süresi 30 gün sonra dolmaktadır.",
    "markRead": "Okundu olarak işaretle",
    "delete": "Sil",
    "markAllRead": "Tümünü okundu olarak işaretle",
    "settings": "Ayarlar",
    "tooltip": "Okunmuş bildirimler 30 gün içerisinde silinecektir.",
    "reportComplete": "DLZ raporunuz bitti. İndirmek için tıklayın",
    "dontShowThisAgain": "Bunu bir daha gösterme",
    "warningEnabled": "Silme Uyarısı etkinleştirildi.",
    "manageEmailNotifications": "E-Posta Bildirimlerini Yönet",
    "warningTooltip": "Seçtiğinizde, bir bildirimi her sildiğinizde bir uyarı görürsünüz.",
    "changeEmailManagedTooltip": "E-posta adresinizi değiştirmek için mHUB > HR Direct > Ben > Herkese Açık Bilgilerim öğesine gidin. İletişim bilgilerinizi güncellemek için talimatları izleyin. Güncellenen e-posta adresinin görünmesi 1-2 iş günü sürebilir.",
    "changeEmailFranchisedTooltip": "E-posta adresinizi değiştirmek için Yöneticiniz ve/veya Franchise EID Tool Yöneticisi ile iletişime geçin. Değiştirilen e-postanın görünmesi 1-2 iş günü sürebilir.",
    "deleteAllNotifications": "Tüm Bildirimleri Sil",
    "deleteIndividualHeader": "Bildirimi Sil",
    "deleteAllConfirmation": "Tüm bildirimleri silmek istediğinizden emin misiniz? Bu bildirimler, saklama süreleri dolana kadar Bildirim Merkezindeki Silinenler bölümüne taşınacak.",
    "deleteIndividualConfirmation": "Bu bildirimi silmek istediğinizden emin misiniz? Silinen bildirimler, saklama süreleri dolana kadar Bildirim Merkezindeki Silinenler bölümüne taşınır.",
    "permanentDeleteIndividiualConfirmation": "Bu bildirimi kalıcı olarak silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
    "permanentDeleteAllConfirmation": "Tüm bildirimleri kalıcı olarak silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
    "notificationDeleted": "Bildirim silindi.",
    "notificationsDeleted": "Bildirimler silindi.",
    "notificationRestored": "Bildirim geri yüklendi.",
    "notificationsRestored": "Bildirimler geri yüklendi.",
    "enableDeletionWarning": "Silme Uyarısı Etkin",
    "warningDisabled": "Silme Uyarısı devre dışı bırakıldı.",
    "viewAll": "Tümünü Göster",
    "deleteAll": "Tümünü Sil",
    "notificationCenterTitle": "Bildirim Merkezi",
    "markAsRead": "Okundu Olarak İşaretle",
    "noDeletedNotifications": "Bilgileriniz güncel!",
    "restore": "Geri Yükle",
    "restoreAll": "Tümünü Geri Yükle",
    "viewCourse": "Kursu Görüntüle",
    "title": "Bildirimler",
    "noNewNotifications": "Bildirimleriniz var.",
    "cancel": "İptal",
    "manageNotificationsCopy": "E-Posta Bildirimlerini Yönet\n\nGünü geçmiş eğitimleri özetleyen haftalık e-posta almak isteyip istemediğinizi seçin. Tercihlerinizi değiştirmek için “Yönet” öğesini seçin."
  },
  "transcript": {
    "history": "Geçmişim",
    "completed": "Tamamlanan Ödevler",
    "assignmentName": "Ödev Adı",
    "type": "Tür",
    "viewAssignment": "Ödev Görüntüle",
    "assignmentSource": "Ödev Kaynağı",
    "requiredAssignments": "Gerekli Ödevler",
    "recommendedAssignments": "Önerilen Ödevler",
    "voluntary": "İsteğe Bağlı",
    "trasscript": "Transkript",
    "retraining": "Yeniden Eğitim",
    "completionDate": "Tamamlanma Tarihi",
    "assignmentType": "Ödev Türü",
    "contentType": "İçerik Türü",
    "contentId": "İçerik No"
  },
  "contentLaunch": {
    "review": "Eğitime Göz At",
    "start": "Eğitime Başla"
  },
  "survey": {
    "weValue": "Fikirlerinizi öğrenmeyi çok isteriz.",
    "weValueDescription": "Lütfen otelinizdeki çalışma deneyiminiz ile ilgili beş kısa soruyu yanıtlayın.",
    "weValueButtonText": "Ankete katıl",
    "weValueG1Q1A1": "Evet",
    "weValueG1Q1A2": "Hayır",
    "strongAgree": "Kesinlikle Katılıyorum",
    "agree": "Katılıyorum",
    "slightAgree": "Kısmen Katılıyorum",
    "slightDisagree": "Kısmen Katılmıyorum",
    "disagree": "Katılmıyorum",
    "strongDisagree": "Kesinlikle Katılmıyorum",
    "weValueG1Q1Title": "En son çalıştığınız vardiyada günlük toplantıya katıldınız mı? (Line-Up, What’s Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments veya Daily EDITION)",
    "weValueG1Q2Title": "Günlük toplantı (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments veya Daily EDITION) her gün lüks hizmet yaratma konusunda bana ilham veriyor.",
    "weValueG1Q3Title": "Departmanım, konuklar tarafından rapor edilmemiş olanlar da dâhil, eksik ve kusurların azaltılmasına odaklanır.",
    "weValueG1Q4Title": "İlk önce izin almak zorunda kalmadan konukların sorunlarını çözme yetkim var.",
    "weValueG1Q5Title": "İlk önce izin almak zorunda kalmadan konukları memnun etme yetkim var.",
    "weValueG2Q1Title": "Gösterdikleri ilgi ve alakayla konukları etkilemek ve memnun etmek adına oteldeki çalışma arkadaşlarımın daima görev sınırlarının çok ötesinde hizmet verdiğini görüyorum.",
    "weValueG2Q2Title": "Bu departmanda öğrendiklerim lüks hizmet sunabilmemi sağladı.",
    "weValueG2Q3Title": "Sahip olduğum bilgi ve becerilerimle lüks hizmet sunabilme konusunda kendime güveniyorum.",
    "weValueG2Q4Title": "Departmanımda temiz, kusursuz ve lüks bir ortam sunuyoruz.",
    "weValueG2Q5Title": "Departmanımla ilgisi olmayan bir sorunla karşılaşsam bile, devreye girme ve konuğun sorununu sahiplenme yetkim var.",
    "weValueG3Q1Title": "Otelimde, çalışma arkadaşlarımız konuklarla aynı seviyede ilgi ve alaka görür.",
    "weValueG3Q2Title": "Departmanım, markanın değerlerini sergiliyor.",
    "weValueG3Q3Title": "Lüks bir konuk deneyimi yaratmak için görev sınırlarımın ötesine geçtiğimde, bu çabam takdir edilir.",
    "weValueG3Q4Title": "Departmanımın işimizi geliştirmeye yönelik çalışmalarına ben de dâhil oluyorum.",
    "weValueG3Q5Title": "Departmanımda konuklar her zaman önceliklidir.",
    "submitButton": "Gönder",
    "phasetwo": {
      "weValueG1Q1Title": "En son çalıştığınız vardiyada günlük toplantıya katıldınız mı? (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments  veya Daily EDITION)?",
      "weValueG1Q2Title": "Günlük toplantı (Line-Up, What's Up Meeting, Daily Rehearsal, House Briefing, Authentic Moments veya Daily EDITION) her gün lüks hizmet yaratma konusunda ekibe ilham kaynağı oluyor.",
      "weValueG1Q3Title": "Otelimde, konuklar tarafından rapor edilmemiş olanlar da dâhil, eksik ve kusurların azaltılmasına odaklanıyoruz.",
      "weValueG1Q4Title": "Otelimde, önden izin almak zorunda kalmadan konukların sorunlarını çözme yetkimiz var.",
      "weValueG1Q5Title": "Otelimde, önden izin almak zorunda kalmadan konukları memnun etme yetkimiz var.",
      "weValueG2Q1Title": "Otelimde, gösterdiğimiz ilgi ve alakayla konukları etkilemek için daima görev sınırlarımızın çok ötesinde hizmet veriyoruz.",
      "weValueG2Q2Title": "Oteldeki eğitim-öğrenme fırsatları, lüks hizmet sunabilmemizi sağlıyor.",
      "weValueG2Q3Title": "Otelimde, sahip olduğumuz bilgi ve becerilerle lüks hizmet sunabilme konusunda kendimize güveniyoruz.",
      "weValueG2Q4Title": "Otelimde temiz, kusursuz ve lüks bir ortam sunuyoruz.",
      "weValueG2Q5Title": "Departmanımızla ilgisi olmayan bir sorunla karşılaşsak bile, devreye girme ve konuğun sorununu sahiplenme yetkimiz var.",
      "weValueG3Q1Title": "Otelimde, konuklara gösterilen aynı ilgi çalışanlar olarak bizlere de gösteriliyor.",
      "weValueG3Q2Title": "Otelim, markanın değerlerini temsil ediyor.",
      "weValueG3Q3Title": "Otelimde, lüks bir konuk deneyimi yaratmak için görev sınırlarımızın daha ötesinde bir gayret gösterdiğimizde, bu çaba ve gayretimiz takdir edilir.",
      "weValueG3Q4Title": "Otelimde, kendi işimizi daha da geliştirmek için departmanımızın çalışmalarına katılma fırsatımız var.",
      "weValueG3Q5Title": "Otelimde, daima konuk önceliklidir."
    }
  },
  "gateway": {
    "planProgressComplete": "%{label} TAMAMLANDI",
    "gatewayProgressComplete": "GATEWAY İLERLEME",
    "heroMobileGatewayVideoText": "Gateway Videosunu Oynat",
    "basics": "TEMEL BİLGİLER",
    "brandResources": "MARKA KAYNAKLARI",
    "disciplineResources": "BÖLÜM KAYNAKLARI",
    "immersionResourcesHeader": "Marka Banyosu Kaynakları",
    "brandCommunicationsHeaader": "Marka İletişimleri",
    "brandContactsHeader": "Marka İrtibat Kişileri",
    "franchisedActionItemsText": "Eylemler, tertipli kalmanızı sağlar ve önemli oryantasyon bilgileri verir. Bu konular zorunlu değildir ve franchise yönetim şirketinizden alabileceğiniz bilgileri tamamlayıcı bilgilerdir.",
    "global": "KÜRESEL",
    "continent": "KITA",
    "viewed": "GÖRÜNTÜLENDİ",
    "completed": "TAMAMLANDI",
    "heading": "Gateway",
    "basicsTab": "TEMEL BİLGİLER",
    "onboardingTab": "Eylemler",
    "brandTab": "Marka Kaynakları",
    "disciplinesTab": "BÖLÜM BAZINDA KAYNAKLAR",
    "basicsHeader": "Marriott International’a Hoş Geldiniz",
    "basicsHeaderNewBrandOrRole": "Hoş Geldiniz,",
    "basicsText": "Kariyerinize başlarken, öğrenmeniz gereken çok şey olduğunu biliyoruz. İşteki ilk birkaç gününüz için temel bilgilerden birkaçını seçtik. Bu konulardan bazıları oryantasyonlarda veya marka oryantasyonunda daha detaylı bir şekilde ele alınabilir, fakat bu başlamanıza yardımcı olacaktır.",
    "basicsTextGms": "Kariyerinize başlarken, öğrenmeniz gereken çok şey olduğunu biliyoruz. İşteki ilk birkaç gününüz için temel bilgilerden birkaçını seçtik. Bu konulardan bazıları oryantasyonlarda veya marka oryantasyonunda daha detaylı bir şekilde ele alınabilir, fakat bu başlamanıza yardımcı olacaktır. Tamamladıktan sonra, Eylemler, Marka Kaynakları ve Bölüm Bazında Kaynaklar da dâhil diğer Gateway sayfalarına ilerleyin.",
    "basicsTextFranchise": "Öğrenmeniz gereken çok şey olduğunun farkındayız ve işinizdeki ilk günleriniz için temel bilgilerden birkaçını seçtik. Bunlar; oryantasyonlarınızı, marka oryantasyonu ve franchise yönetim şirketinizden aldığınız bilgileri tamamlayıcı olabilir.",
    "basicsTextFranchiseGms": "Öğrenmeniz gereken çok şey olduğunun farkındayız ve işinizdeki ilk günleriniz için temel bilgilerden birkaçını seçtik. Bunlar; oryantasyonlarınızı, marka oryantasyonu ve franchise yönetim şirketinizden aldığınız bilgileri tamamlayıcı olabilir. Bunları tamamladıktan sonra Eylemler, Marka Kaynakları ve Bölüm Bazında Kaynaklar da dâhil diğer Gateway sayfalarına ilerleyin.",
    "basicsTextNewToRole": "Yeni görevinize başlarken, öğrenmeniz gereken çok şey olduğunu biliyoruz. İşteki ilk birkaç gününüz için temel bilgilerden birkaçını seçtik. Bu konulardan bazıları size atanan eğitimlerde daha derinlemesine ele alınabilir, fakat bu başlamanıza yardımcı olacaktır.",
    "basicsTextNewToRoleGms": "Yeni görevinize başlarken, öğrenmeniz gereken çok şey olduğunu biliyoruz. İşteki ilk birkaç gününüz için temel bilgilerden birkaçını seçtik. Bu konulardan bazıları size atanan eğitimlerde daha derinlemesine ele alınabilir, fakat bu başlamanıza yardımcı olacaktır. Bunları tamamladıktan sonra Eylemler, Marka Kaynakları ve Bölüm Bazında Kaynaklar da dâhil diğer Gateway sayfalarına ilerleyin.",
    "basicsTextNewToRoleFranchise": "Öğrenmeniz gereken çok şey olduğunun farkındayız ve yeni görevinizde ilk günleriniz için temel bilgilerden birkaçını seçtik. Bunlar, franchise yönetim şirketinizden aldığınız bilgileri tamamlayıcı olabilir.",
    "basicsTextNewToRoleFranchiseGms": "Öğrenmeniz gereken çok şey olduğunun farkındayız ve yeni görevinizde ilk günleriniz için temel bilgilerden birkaçını seçtik. Bunlar, franchise yönetim şirketinizden aldığınız bilgileri tamamlayıcı olabilir. Bunları tamamladıktan sonra Eylemler, Marka Kaynakları ve Bölüm Bazında Kaynaklar da dâhil diğer Gateway sayfalarına ilerleyin.",
    "basicsTextNewToBrand": "%{brandName} bünyesindeki yeni görevinize başlarken, öğrenmeniz gereken çok şey olduğunu biliyoruz. İşteki ilk birkaç gününüz için temel bilgilerden birkaçını seçtik. Bu konulardan bazıları oryantasyonlarda veya marka oryantasyonunda daha detaylı bir şekilde ele alınabilir, fakat bu başlamanıza yardımcı olacaktır.",
    "basicsTextNewToBrandGms": "%{brandName} bünyesindeki yeni görevinize başlarken, öğrenmeniz gereken çok şey olduğunu biliyoruz. İşteki ilk birkaç gününüz için temel bilgilerden birkaçını seçtik. Bu konulardan bazıları oryantasyonlarda veya marka oryantasyonunda daha detaylı bir şekilde ele alınabilir, fakat bu başlamanıza yardımcı olacaktır. Bunları tamamladıktan sonra Eylemler, Marka Kaynakları ve Bölüm Bazında Kaynaklar da dâhil diğer Gateway sayfalarına ilerleyin.",
    "basicsTextNewToBrandFranchise": "Öğrenmeniz gereken çok şey olduğunun farkındayız ve yeni görevinizde ilk günleriniz için temel bilgilerden birkaçını seçtik. Bunlar; oryantasyonlarınızı, marka oryantasyonu ve franchise yönetim şirketinizden aldığınız bilgileri tamamlayıcı olabilir.",
    "basicsTextNewToBrandFranchiseGms": "Öğrenmeniz gereken çok şey olduğunun farkındayız ve %{brandName} bünyesindeki yeni görevinizde ilk günleriniz için temel bilgilerden birkaçını seçtik. Bunlar; oryantasyonlarınızı, marka oryantasyonu ve franchise yönetim şirketinizden aldığınız bilgileri tamamlayıcı olabilir. Bunları tamamladıktan sonra Eylemler, Marka Kaynakları ve Bölüm Bazında Kaynaklar da dâhil diğer Gateway sayfalarına ilerleyin.",
    "disciplineHeader": "Bölüm Bazında Kaynaklar",
    "toDo": "ZORUNLU",
    "optional": "İSTEĞE BAĞLI",
    "overallComplete": "GENEL TAMAMLAMA",
    "keyResourcesDesc": "Günlük otel faaliyetlerini, konuk deneyimini ve iş ihtiyaçlarını destekleyen önemli bölümler hakkında bilgi edinin.",
    "franchiseFooter": "Bu materyal, Marka Standartları ile uyumluluğu artırabilecek önerileri ve tavsiye edilen uygulamaları içermektedir. Franchising veren olarak Marriott, franchising alan tarafın otel veya iş faaliyetinin yönetimine veya operasyonun herhangi bir kısmına karışmamaktadır. Franchising alan, otellerin günlük faaliyetlerini yönetir ve kendi çalışanlarından ve istihdam uygulamalarından sorumludur.",
    "landingHeroTitle1": "Marriott International’a Hoş Geldiniz.",
    "landingHeroTitle2": "Yolculuğunuza buradan başlayın.",
    "landingHeroText": "The Gateway, yeni görevinizdeki ilk yılınızda size rehberlik edecek bir araçtır. Başarılı olabilmek için ihtiyacınız olan bilgi ve kaynakları sağlayacaktır.",
    "landingHeroTitle1NewToRole": "The Gateway’e Hoş Geldiniz.",
    "landingHeroTitle1NewToBrand": "The Gateway’e Hoş Geldiniz.",
    "landingButtonText": "Başla",
    "twoMonthslandingHeroTitle1": "Tekrar Hoş Geldiniz!",
    "twoMonthslandingHeroText": "Henüz yapmadıysanız The Gateway’deki tüm temel bilgilerinizi tamamlayın!",
    "twoMonthslandingButtonText": "Gateway’e Gir",
    "twoMonthslandingHeroTitle1NewToBrand": "Tekrar Hoş Geldiniz!",
    "twoMonthslandingHeroTextNewToBrand": "Umarız %{brandName} bünyesindeki ilk günleriniz harika geçmiştir. Yolculuğunuza devam etmeyi ve ihtiyacınız olan her şeye The Gateway üzerinden erişmeyi unutmayın.",
    "twoMonthslandingButtonTextNewToBrand": "İlerle",
    "threeMonthslandingHeroTitle1": "Yola çıktınız!",
    "threeMonthslandingHeroText": "Marriott International hakkında bilmeniz gereken her şeyi The Gateway’de bulabilirsiniz.",
    "threeMonthslandingButtonText": "Gateway’e Gir",
    "threeMonthslandingHeroTitle1NewToRole": "Yola çıktınız!",
    "threeMonthslandingHeroTextNewToRole": "Yeni görevinizde başarılı olabilmek için ihtiyaç duyduğunuz her şeyi The Gateway’de bulabilirsiniz.",
    "threeMonthslandingButtonTextNewToRole": "Gateway’e Gir",
    "threeMonthslandingHeroTitle1NewToBrand": "Tekrar Hoş Geldiniz!",
    "threeMonthslandingHeroTextNewToBrand": "Yeni marka bünyesindeki %{numDays} gününüzü tamamladığınız için tebrikler. İhtiyacınız olduğunda Eylemler, Marka ve Disiplin Kaynaklarınızın hepsine erişebilirsiniz.",
    "threeMonthslandingButtonTextNewToBrand": "Gateway’e Gir",
    "fourMonthslandingHeroTitle1": "Harika! Temel Bilgileri tamamladınız!",
    "fourMonthslandingHeroText": "Marriott International hakkında bilmeniz gereken her şeye The Gateway’den ulaşın.",
    "fourMonthslandingButtonText": "Gateway’e Gir",
    "fourMonthslandingHeroTitle1NewToRole": "Harika! Temel Bilgileri tamamladınız!",
    "fourMonthslandingHeroTextNewToRole": "Yeni görevinizde başarılı olabilmek için ihtiyaç duyduğunuz her şeye The Gateway üzerinden erişin.",
    "fourMonthslandingButtonTextNewToRole": "Gateway’e Gir",
    "fourMonthslandingHeroTitle1NewToBrand": "The Gateway - Kontrol",
    "fourMonthslandingHeroTextNewToBrand": "%{brandName} bünyesinde ilk yılınızı tamamlamaya yaklaşırken, henüz yapmadıysanız tüm Eylemlerinizi tamamlayın ve Digital Learning Zone’da bulunan gelişim kaynaklarından istifade edin. Yolculuğunuza devam etmek için ‘Devam et’ seçeneğine tıklayın.",
    "fourMonthslandingButtonTextNewToBrand": "Devam et",
    "gmSecondVisitlandingHeroTitle1": "Tekrar Hoş Geldiniz!",
    "gmSecondVisitlandingHeroText": "Umarız ilk günleriniz harika geçmiştir. Yeni görevinizde gelişmenize yardımcı olması için yolculuğunuzda ilerlemeyi unutmayın.",
    "gmSecondVisitlandingButtonText": "İlerle",
    "gmSecondVisitlandingHeroTextNewToRole": "Umarız yeni görevinizdeki ilk günleriniz harika geçmiştir. Yolculuğunuza devam etmeyi ve ihtiyacınız olan her şeye The Gateway üzerinden erişmeyi unutmayın.",
    "gmSecondVisitlandingHeroTextNewToBrand": "Umarız %{brandName} bünyesindeki ilk günleriniz harika geçmiştir. Yolculuğunuza devam etmeyi ve ihtiyacınız olan her şeye The Gateway üzerinden erişmeyi unutmayın.",
    "gmThirdVisitlandingHeroTitle1": "Tekrar Hoş Geldiniz!",
    "gmThirdVisitlandingHeroText": "Yeni iş yolculuğunuzun ilk %{numDays} gününü tamamladığınız için tebrikler. İhtiyacınız olduğunda Eylemlerinize, Marka ve Bölüm Kaynaklarınıza erişebilirsiniz.",
    "gmThirdVisitlandingButtonText": "Gateway’e Gir",
    "gmThirdVisitlandingHeroTextNewToRole": "Yeni görevinizdeki ilk %{numDays} gününüzü tamamladığınız için tebrikler. İhtiyacınız olduğunda Eylemlerinize, Marka ve Bölüm Kaynaklarınıza erişebilirsiniz.",
    "gmThirdVisitlandingHeroTextNewToBrand": "Yeni markanızdaki %{numDays} gününüzü tamamladığınız için tebrikler. İhtiyacınız olduğunda Eylemlerinize, Marka ve Bölüm Kaynaklarınıza erişebilirsiniz.",
    "gmSevenMonthslandingHeroTitle1": "Tamamlamanız gereken daha fazla Eylem var gibi görünüyor.",
    "gmSevenMonthslandingHeroText": "İlk yılınızı bitirmeye yaklaşırken, size atanmış tüm Eylemleri tamamlamayı ve Digital Learning Zone’da mevcut olan gelişim kaynaklarından istifade etmeyi unutmayın. Yolculuğunuzda ilerlemek için ‘Devam et’ seçeneğine tıklayın",
    "gmSevenMonthslandingButtonText": "Devam et",
    "gmSevenMonthslandingHeroTitle1NewToRole": "The Gateway - Kontrol",
    "gmSevenMonthslandingHeroTextNewToRole": "Yeni görevinizdeki ilk yılınızı tamamlamaya yaklaşırken, henüz yapmadıysanız tüm Eylemlerinizi tamamlayın ve Digital Learning Zone’da bulunan gelişim kaynaklarından istifade edin. Yolculuğunuzda ilerlemek için ‘Devam et’ seçeneğine tıklayın",
    "gmSevenMonthslandingHeroTitle1NewToBrand": "The Gateway - Kontrol",
    "gmSevenMonthslandingHeroTextNewToBrand": "%{brandName} bünyesinde ilk yılınızı tamamlamaya yaklaşırken, henüz yapmadıysanız tüm Eylemlerinizi tamamlayın ve Digital Learning Zone’da bulunan gelişim kaynaklarından istifade edin. Yolculuğunuza devam etmek için ‘Devam et’ seçeneğine tıklayın.",
    "gmOneYearlandingHeroTitle1": "%{brandName} bünyesindeki 1. yılınız için tebrikler!",
    "gmOneYearlandingHeroTitle1NewToRole": "Yeni görevinizdeki 1. yılınız için tebrikler!",
    "gmOneYearlandingHeroTitle1NewToBrand": "%{brandName} bünyesindeki yeni görevinizin 1. yılı için tebrikler!",
    "gmOneYearlandingHeroText": "The Gateway yolculuğu bitmiş olabilir, fakat en iyisi daha gelmedi. İstediğiniz zaman The Gateway sekmesi üzerinden kaynaklara erişebilirsiniz.",
    "gmOneYearlandingButtonText": "Gateway’i Görüntüle"
  },
  "resources": {
    "iptTooltip": "Birden fazla lokasyonu ayırmak için virgül kullanın",
    "iptAllFunctionTooltip": "Belirli İş Fonksiyon(lar)ını seçmek için işareti kaldırın. Seçilen lokasyonda tüm fonksiyonlara ait eğitimi görüntülemek için işareti kaldırmayın",
    "iptAllAWRTooltip": "Belirli Ek İş Sorumluluklarını seçmek için işareti kaldırın. Seçilen lokasyonda tüm Ek İş Sorumluluklarına ait eğitimi görüntülemek için işareti kaldırmayın",
    "editJFTooltip": "Birden fazla girdiyi ayırmak için virgül kullanın"
  },
  "Notification Center: ": "Okunmadı Olarak İşaretle",
  "Notification Center: Empty": "Bilgileriniz güncel!",
  "Notification Center: Individual Delete": "Bu bildirimi silmek istediğinizden emin misiniz? Silinen bildirimler, saklama süreleri solana kadar Bildirim Merkezindeki Silinenler bölümüne taşınır.",
  "Notification Center: Delete All": "Tüm bildirimleri silmek istediğinizden emin misiniz? Bu bildirimler, saklama süreleri dolana kadar Bildirim Merkezindeki Silinenler bölümüne taşınacak.",
  "Notification Center: Mark All As Read": "Tümünü Okundu Olarak İşaretle",
  "Notification Center: Restore All": "Tümünü Geri Yükle",
  "Notification Center: Delete Confirmation": "Sil",
  "Warning Message Text: Remove Job Function or AWR": "İş Fonksiyonu veya Ek İş Sorumluluğu (AWR) kaldırılsın mı?\r\nGöndererek, %{count} Öğrenciden %{Function and/or AWR} kaldırmak üzeresiniz.",
  "Warning Message Text: Add Job Function or AWR": "İş Fonksiyonu veya Ek İş Sorumluluğu (AWR) kaldırılsın mı?\r\nGöndererek, %{count} Öğrenciye %{Function and/or AWR} eklemek üzeresiniz.",
  "Button": "Gönder",
  "Success Message Text: Add Job Function and/or AWR": "Başarılı\r\n%{location} konumundaki talep edilen çalışan(lara) %{Job Function and/or AWR} eklediniz. \r\n\r\n%{location} için ek İş Fonksiyonlarını veya Ek İş Sorumluluklarını düzenlemek istiyor musunuz?",
  "Message Text:AWR": "Başarılı\r\n%{ __} konumundaki talep edilen çalışanlara Ek İş Sorumluluklarını %{ __} eklediniz.\r\n\r\n%{location, property, learner(s)} için ek İş Fonksiyonlarını veya Ek İş Sorumluluklarını düzenlemek istiyor musunuz?",
  "Success Message Text: Remove Job Function": "Başarılı\r\n%{location} konumundaki talep edilen çalışan(lar)dan  %{Job Function and/or AWR} kaldırdınız. \r\n\r\n%{location} için ek İş Fonksiyonlarını veya Ek İş Sorumluluklarını düzenlemek istiyor musunuz?",
  "Success Message Text: Remove AWR": "Başarılı\r\nEk İş Sorumluluğunu %{ __}, %{ __}  konumundaki talep eden çalışan(lar)dan kaldırdınız.\r\n\r\n%{location, property, learner(s)} için ek İş Fonksiyonlarını veya Ek İş sorumluluklarını düzenlemek istiyor musunuz?",
  "Tool Tip - Managed Change Email": "E-posta adresinizi değiştirmek için, mHUB > HR Direct > Ben > Herkese Açık Bilgilerim seçeneğine gidin. İletişim bilgilerinizi güncellemek için talimatları izleyin. Değiştirilen e-postanın görünmesi 1-2 iş günü sürebilir.",
  "Tool Tip - Franchised  Change Email": "E-posta adresinizi değiştirmek için Yöneticiniz ve/veya Franchise EID Tool Yöneticisi ile iletişime geçin. Değiştirilen e-postanın görünmesi 1-2 iş günü sürebilir.",
  "User Profile: Email Opt-in": "Hayır, teşekkürler",
  "User Profile: Email Frequency": "Haftalık Özet",
  "Generic Notification Email Subject Line:": "DLZ platformunda yeni bildirimleriniz var",
  "Generic Body Text (<150 Characters)": "Ayrıntıları görmek için lütfen DLZ platformuna gidin.",
  "All Emails: Signature": "Digital Learning Zone - Öğrenmenin kolay yolu. Sizinle başlar.",
  "All Emails: Footer": "İstediğiniz zaman e-posta bildirimlerini kapatabilir/abonelikten çıkabilirsiniz. Tercihlerinizi değiştirmek için Digital Learning Zone/Profil bölümüne gidin.",
  "Overdue Emails: Subject Line: ": "DLZ Platformunda Sizi Bekleyen Ödevler Var",
  "Overdue Emails: Body Test": "Süresi Dolmuş Ödevleriniz var. Ödevlerinizi görmek için lütfen DLZ platformuna giriş yapın.",
  "exploreLearning": {
    "sharpenYourSkillsTitle": "Becerilerinizi Geliştirin",
    "sharpenYourSkillsSubTitle": "Bugün ve gelecekte kariyerinizde ilerlemek için bilgi ve becerilerinizi geliştirin.",
    "growYourCareerTitle": "Kariyerinizde İlerleyin",
    "growYourCareerSubTitle": "Belirli bir uzmanlık alanına ait becerileri veya size özgü ilgi alanlarınızı ve hedeflerinizi destekleyecek çapraz eğitimlerle bir sonraki hamlenizi planlayın.",
    "additionalResourcesTitle": "Ek Kaynaklar",
    "additionalResourcesSubTitle": "getAbstract® ve CultureWizard™’i Keşfedin. DLZ’nin gelişmenize nasıl yardımcı olmaya devam edebileceğini görün.",
    "tourHeader": "Güncellenen DLZ’ye Hoş Geldiniz. Artık ihtiyacınız olduğunda, ihtiyacınız olan şeyi bulmak için arama yapabilirsiniz. Ayrıca, güncellenmiş olan Öğrenimi Keşfet ve daha kişiselleştirilmiş Planım bölümleri sizi bekliyor. Tura katılın!",
    "tourBtnSkip": "Reddet",
    "tourBtnNext": "Kapat (6/6)",
    "tourSearchBar": "Yeni ve daha güçlü bir Arama özelliği ile ihtiyacınız olan şeyi, ihtiyacınız olduğunda bulun.",
    "tourBtnBack": "Geri",
    "tourMyPlan": "Eğitim yolculuğunuzu tam olarak sahiplenebilmeniz için Planım bölümünde artık Arama ve Keşfet bölümünden eklediğiniz yeni öğeler de mevcut.",
    "tourExploreLearningLink": "Öğrenimi Keşfet bölümüne halen buradan ulaşabilirsiniz!",
    "tourExploreLearningProfile": "Planım bölümünü güncel tutmak için, başta iş fonksiyonları olmak üzere profilinizi düzenli olarak kontrol edin ve eğitim deneyiminizden en iyi şekilde yararlanın.",
    "mhubTraining": "mHUB Eğitim",
    "growthSkills": "Gelişim Becerileri",
    "leadershipSkills": "Liderlik Becerileri",
    "virtualTeamResources": "Sanal Ekip Kaynakları",
    "jobFunctions": "İş Fonksiyonları",
    "brands": "Markalar",
    "languageLearning": "Dil Öğrenimi",
    "become": "Ol",
    "emeaMentoringProgram": "EMEA Mentörlük Programı",
    "learningPathways": "Öğrenme Yolları",
    "mda": "MDA",
    "takeCareGrow": "TakeCare GROW",
    "getAbstract": "getAbstract®",
    "cultureWizard": "CultureWizard™",
    "takeCareCommunityHub": "TakeCare Topluluk Merkezi",
    "mhubTrainingDesc": "Pozisyon bazlı öğrenim yollarına ve mHUB’ın nasıl kullanılacağı ile ilgili konu bazlı kurslara ulaşın.",
    "growthSkillsDesc": "Çeşitli fonksiyon ve konular genelinde pratik bilgi edinin.",
    "leadershipSkillsDesc": "Bir ekibin nasıl yönetileceğini ve bir ekibe nasıl koçluk yapılacağı ve nasıl daha etkili bir lider olunabileceğini keşfedin.",
    "virtualTeamResourcesDesc": "Sanal ekipler içerisinde işbirliğine dayalı ve kapsayıcı bir kültürü teşvik etme tekniklerini öğrenin.",
    "jobFunctionsDesc": "Mevcut iş fonksiyonunuz hakkındaki bilgilerinizi artırın ve diğer iş fonksiyonlarını öğrenin.",
    "brandsDesc": "Marriott International’ın çeşitli markaları hakkında bilgi edinin.",
    "languageLearningDesc": "Küresel bir kitle ile etkili şekilde iletişim kurun.",
    "becomeDesc": "Kariyer gelişimine bütüncül yaklaşımı keşfedin: değerlendir, planla, harekete geç, son rötuşları yap.",
    "emeaMentoringProgramDesc": "EMEA Mentörlük Programı ile potansiyelinizi açığa çıkarın.",
    "learningPathwaysDesc": "Liderlik seviyenize uyarlanmış özel eğitim fırsatlarını keşfedin.",
    "mdaDesc": "Marriott Development Academy ile liderlik becerileri ve fonksiyonel becerilerinizi geliştirin.",
    "takeCareGrowDesc": "Kapsayıcı ve ilham verici bir topluluk inşa etmek için tasarlanan kaynakları belirleyin.",
    "getAbstractDesc": "Daha iyi karar almanıza yardımcı olacak kitap özetleri ile her gün yeni bir şey öğrenin.",
    "cultureWizardDesc": "Farklı kültürler arasında etkili bir şekilde iletişim kurmak için değerli bilgiler toplayın.",
    "takeCareCommunityHubDesc": "TakeCare ekip oluşturma faaliyetlerini, eğitim programlarını ve araçlarını keşfedin.",
    "searchAllLearning": "Tüm Eğitimleri Ara",
    "searchBarPlaceHolder": "Daima öğrenecek yeni şeyler var.",
    "clearFilters": "Filtreleri Temizle",
    "noResults": "Sonuç Yok",
    "hasBeenUnpinned": "{Variable-tile_Name} için işaret kaldırıldı.",
    "hasBeenPinned": "{Variable-tile_Name} işaretlendi.",
    "lpRemovedFromPlan": "Öğrenme Yolu planınızdan çıkarıldı.",
    "courseRemovedFromPlan": "Kurs planınızdan çıkarıldı.",
    "lpAddedToPlan": "Öğrenme Yolu planınıza eklendi.",
    "courseAddedToPlan": "Kurs planınıza eklendi.",
    "clearSelection": "Seçimi temizle",
    "removeFromMyPlan": "Planımdan Çıkar",
    "addToMyPlan": "Planıma Ekle",
    "loadingResults": "Yükleniyor",
    "viewDetails": "Detayları Görüntüle",
    "clearAll": "Tümünü Temizle",
    "noOptions": "Mevcut seçenek yok",
    "viewLess": "Daha Az Göster",
    "viewMore": "Daha Fazla Göster",
    "recentSearch": "Son Aramalar",
    "exploreLearningTitle": "Öğrenimi Keşfet",
    "exploreLearningHelpText": "Ne aradığınızı bilmiyor musunuz?",
    "exploreMore": "Daha Fazlasını Keşfedin.",
    "viewAllResults": "Tüm <#> Sonuçlarını Görüntüle",
    "searchSubTitle": "Anahtar sözcük, başlık veya içerik numarası kullanarak kurs arayın.",
    "pinnedLearningTitle": "İşaretlediğim Eğitimler",
    "landingSubTitle": "Öğrenim yolculuğunuzu sahiplenin. İlgi alanlarınızı keşfedin — her zaman eklenen yeni konular ve kurslar var.",
    "columnTitle": "Başlık",
    "columnDesc": "Tanım",
    "columnType": "Tür",
    "columnAddedToPlan": "Plana Ek(le)ndi",
    "columnComplete": "Tamamlandı",
    "columnDetails": "Ayrıntılar",
    "additionalSearchFilters": "Ek Arama Filtreleri",
    "tourBtnClose": "Kapat",
    "tourBtnLast": "Kapat",
    "resultsFor": " için Sonuçlar",
    "resultsFortheFilter": "{Filter Name} filtresi için sonuçlar",
    "no": "Hayır",
    "results": "Sonuçlar",
    "courseTooltipTitle": "Bir veya birden fazla aktiviteyi içerebilecek münferit bir eğitim öğesi.<br />Bir kurs çevrimiçi olabilir - örneğin bir video veya e-Eğitim - veya bir sınıf ortamında gerçekleşebilir.",
    "gatewayToolTipTitle": "Yeni işe başlayanlara, yönetimde yeni olanlara ve markalarında yeni olan GM’lere yönelik dijital kaynaklar.",
    "speciallizationOrPathwayTooltipTitle": "Belirli bir konuya yönelik bir dizi kurs.",
    "taskTooltipTitle": "Bir aktiviteyi tamamlamaya (genelde iş üstünde) ve ardından bu aktiviteyi tamamladığınızı işaretlemeye ilişkin talimatlar.",
    "webSiteTooltipTitle": "Marriott siteleri ve harici web siteleri de dâhil herhangi bir web sitesi."
  },
  "dontShowAgain": "Bunu bir daha gösterme",
  "deletionWarningEnabled": "Silme uyarısı etkinleştirildi",
  "deletionSelected": "Seçtiğinizde, bir bildirimi her sildiğinizde bir uyarı görürsünüz.",
  "manageEmailNotifications": "E-Posta Bildirimlerini Yönet",
  "seeHowDLZContinue": "DLZ’nin gelişmenize nasıl yardımcı olmaya devam edebileceğini görün.",
  "Notification Center:Overdue": "{Variable date ddmmyy} tarihine kadar tamamlanması gereken bir yeni eğitiminiz var",
  "accesDenied": {
    "title": "Eğitim Yolculuğunuza erişiminiz hazırlanıyor! ",
    "subtitle": "Erişiminiz oluşturuluyor ve EID’niz aktif olduktan sonra 1-2 gün içerisinde kullanılabilir olacaktır. 2 günden daha uzun sürerse lütfen mHub Hizmet Merkezi ile iletişime geçin."
  },
  "jfTour": {
    "title": "Artık doğrudan Planım bölümü üzerinden seçtiğiniz iş fonksiyonlarını ve ek iş sorumluluklarını (EİS) görebilir ve bunları düzenleyebilirsiniz."
  },
  "onboardingPlan": {
    "requiredDescription": "Planım’da seçmiş olduğunuz zaman aralığında tamamlanması gereken zorunlu kurslar aşağıdadır. Yeni iş pozisyonunuzda ilerlerken almanız gereken zorunlu eğitimleri tamamlamak için zaman planlamasını yöneticinizle birlikte yapın. Çeşitli konularda yeni bilgiler öğreneceksiniz, bu süreçte sorularınız olduğunda yöneticinize ve iş arkadaşlarınıza danışmayı unutmayın.   ",
    "basicsDescription": "Öğrenmeniz gereken çok şey olduğunun farkındayız ve işinizdeki ilk günleriniz için birkaç temel bilgi seçtik. Bunlar; oryantasyonlarınızı, marka oryantasyonunu ve franchise yönetim şirketinizden aldığınız bilgileri tamamlayıcı bilgiler olabilir. Bunlar, kariyer yolculuğunuz boyunca size destek olacak mükemmel kaynaklardır ve ekibe yeni üyeler katıldıkça da yardımcı olacaktır.",
    "day1": "14 güne kadar süresi dolanlar",
    "day2": "30 güne kadar süresi dolanlar",
    "day3": "60 güne kadar süresi dolanlar",
    "day4": "90 güne kadar süresi dolanlar",
    "day5": "90 günden sonra süresi dolanlar ",
    "recommendedDescription": "İlk 90 gününüzün ardından, yöneticiniz tarafından önerilen ek kurslar da bulacaksınız. Kendi başınıza da ilginizi çeken konuları keşfedebilirsiniz. Şimdilik, temel eğitiminize odaklanın. "
  },
  "onboardingLanding": {
    "gettingStartedDescription": "Digital Learning Zone, profesyonel eğitim ve gelişim için başlangıç noktanızdır. Başlamanız için size yardımcı olacak içerikleri aşağıda bulabilirsiniz. Tüm eğitimleri her zaman Planım sekmesi altında bulabilirsiniz.  ",
    "overallLearningProgress": "Eğitimdeki Genel İlerleme",
    "learningProgressLabel": "Eğitimdeki İlerleme",
    "learningProgressDescription": "Zorunlu eğitimlerinizdeki ilerlemenizi burada bulabilirsiniz. Bu ilerleme çubuğu, tüm zorunlu eğitimlerinizin durumunu da gösterir. Aynen devam!"
  },
  "poc": {
    "dlz": "Bu eğitimin tamamlanmış olması için Yöneticilerin veya Eğitim Koordinatörlerinin bu eğitimi mHub'da tamamlandı olarak işaretlemesi gerekmektedir. Bunu nasıl yapabileceğinizle ilgili bilgileri Uygulama Kılavuzunda bulabilirsiniz.",
    "mhub": "İmtiyaz sahibi/Franchise alan:  Kendi temel temizlik programınızı kullanmayı seçerseniz, tamamlandı olarak işaretlerken lütfen açıklamalar bölümünde söz konusu eğitim programın adını ve ayrıntılarını belirtin"
  },
  "Recommended Courses ": "İlk 90 gününüzün ardından, yöneticiniz tarafından önerilen ek kurslar da bulacaksınız. Kendi başınıza da ilginizi çeken konuları keşfedebilirsiniz. Şimdilik, temel eğitiminize odaklanın. ",
  "survey": {
    "v2": {
      "lbiIntro": "",
      "lbiDescription": `<p></p>
      <ul>
          <li>
              <p></p>
          </li>
          <li>
              <p></p>
          </li>
          <li>
              <p></p>
          </li>
      </ul>
      <p><br></p>
      <p></p>
      <p></p>
      `,
      "checkin": {
        q1Title: "",
        q2Title: "",
        q3Title: "",
        q4Title: "",
        q5Title: "",
        q6Title: "",
        q1R1Title: "",
        q1R2Title: "",
        q1R3Title: "",
        q1R4Title: "",
        q2R1Title: "",
        q2R2Title: "",
        q2R3Title: "",
        q2R4Title: "",
        q3R1Title: "",
        q3R2Title: "",
        q3R3Title: "",
        q3R4Title: "",
        q4R1Title: "",
        q4R2Title: "",
        q4R3Title: "",
        q4R4Title: "",
        q5R1Title: "",
        q5R2Title: "",
        q5R3Title: "",
        q5R4Title: "",
        q6R1Title: "",
        q6R2Title: "",
        q6R3Title: "",
        q6R4Title: ""
      }
    }
  },
  "survey": {
    "v2": {
      "weValueButtonText": "Làm khảo sát",
      "lbiIntro": "",
      "lbiDescription": `<p></p>
      <ul>
          <li>
              <p></p>
          </li>
          <li>
              <p></p>
          </li>
          <li>
              <p></p>
          </li>
      </ul>
      <p><br></p>
      <p></p>
      <p></p>
      `,
      "checkin": {
        q1Title: "",
        q2Title: "",
        q3Title: "",
        q4Title: "",
        q5Title: "",
        q6Title: "",
        q1R1Title: "",
        q1R2Title: "",
        q1R3Title: "",
        q1R4Title: "",
        q2R1Title: "",
        q2R2Title: "",
        q2R3Title: "",
        q2R4Title: "",
        q3R1Title: "",
        q3R2Title: "",
        q3R3Title: "",
        q3R4Title: "",
        q4R1Title: "",
        q4R2Title: "",
        q4R3Title: "",
        q4R4Title: "",
        q5R1Title: "",
        q5R2Title: "",
        q5R3Title: "",
        q5R4Title: "",
        q6R1Title: "",
        q6R2Title: "",
        q6R3Title: "",
        q6R4Title: ""
      }
    }
  },
};